import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    ErrorModel,
    PATH,
} from '@cityair/modules/posts-and-devices/components/post-create/post-create.component';
import { PostsAndDevicesFacade } from '@cityair/modules/posts-and-devices/posts-and-devices.facade';
import type { CheckboxItem } from '@libs/common/types/checkbox-item';
import { City_model } from '@cityair/namespace';
import { ActivatedRoute } from '@angular/router';
import { CreateOM, NETWORK_PAGES } from '@cityair/modules/posts-and-devices/namespace';

import { Store } from '@ngrx/store';
import { selectCurrentMo, selectGroupCities } from '@cityair/modules/core/store/selectors';
import { filter, take } from 'rxjs/operators';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import PostActions from "@cityair/modules/posts-and-devices/actions";
@Component({
    selector: 'post-edit',
    templateUrl: './post-edit.component.html',
    styleUrls: ['./post-edit.component.less'],
    animations: ANIMATION_OPACITY,
})
export class PostEditComponent implements OnInit, OnDestroy {
    TEXTS = TEXTS;
    PostEdit: UntypedFormGroup;
    errorObj: ErrorModel = {};
    pages = NETWORK_PAGES;
    createMo = new CreateOM();
    navigationLink: PATH[] = [];
    isShowDropdownForCity = false;
    isShowDeletePopup = false;
    selectedStation = '';
    nameMO = '';
    isLoader = false;
    getCities: CheckboxItem[] = [];
    selectedCity: CheckboxItem = {
        id: '',
        label: '',
    };

    @ViewChild('postEdit', { static: true }) postEdit: TemplateRef<HTMLDivElement>;

    constructor(
        private fb: UntypedFormBuilder,
        public popupProvider: OffPanelPopupService,
        public store: Store<any>,
        public actions: PostActions,
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        const idRouter = this.activatedRoute.snapshot.params.id;
        const isCheckExists = this.postsAndDevicesFacade.checkExistsPage(idRouter);
        // this.nameMO = this.PostEdit.get('name').value;
        if (isCheckExists) {
            this.getDataForPostEdit();
        } else {
            this.postsAndDevicesFacade.openPage(this.pages.networks + '/' + this.pages.postsList);
        }
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
    }

    getDataForPostEdit() {
        this.PostEdit = this.fb.group({
            name: ['', [Validators.required]],
            description: ['', [Validators.required]],
            geoLatitude: ['', [Validators.required]],
            geoLongitude: ['', [Validators.required]],
        });

        this.store
            .select(selectCurrentMo)
            .pipe(take(1))
            .subscribe((scm) => {
                Object.assign(this.createMo, scm);
                this.nameMO = scm.name;
                this.PostEdit.patchValue({
                    name: scm.name,
                    description: scm.description,
                    geoLatitude: scm.geoLatitude,
                    geoLongitude: scm.geoLongitude,
                });
                this.createBreadCrumbs();
            });

        this.store
            .select(selectGroupCities)
            .pipe(filter((sgc) => !!sgc.length))
            .subscribe((sgc) => {
                sgc.forEach((s) => {
                    this.getCities.push({
                        id: s.locationId,
                        idNumber: s.locationId,
                        label: s.name,
                        selected: s.locationId == this.createMo.locationId,
                    });
                    if (s.locationId == this.createMo.locationId) {
                        this.getSelectedLocation(s);
                    }
                });
            });
        this.getCities.sort(this.byField('label'));
    }

    byField(field) {
        return (a, b) => (a[field] > b[field] ? 1 : -1);
    }

    getSelectedLocation(s: City_model) {
        this.selectedCity = {
            label: s.name,
            idNumber: s.locationId,
            id: s.id,
        };
    }

    createBreadCrumbs() {
        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.postAndDevices,
            path: this.pages.networks + '/' + this.activatedRoute.snapshot.url[0],
        });
        if (this.activatedRoute.snapshot.url[1].path == this.pages.details) {
            this.navigationLink.push({
                name: this.createMo.name,
                path:
                    this.pages.networks +
                    '/' +
                    this.activatedRoute.snapshot.url[0] +
                    '/' +
                    this.activatedRoute.snapshot.url[1] +
                    '/' +
                    this.activatedRoute.snapshot.params.id,
            });
        }
        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.editMo,
            path: null,
        });
    }

    backToPrevPage(navigationLink: PATH) {
        if (navigationLink.path) this.postsAndDevicesFacade.openPage(navigationLink.path);
    }

    get name() {
        return this.PostEdit.get('name');
    }

    get description() {
        return this.PostEdit.get('description');
    }

    get geoLatitude() {
        return this.PostEdit.get('geoLatitude');
    }

    get geoLongitude() {
        return this.PostEdit.get('geoLongitude');
    }

    getSelectedCity(city: CheckboxItem[]) {
        this.selectedCity = city.find((c) => c.selected == true);
    }

    openPage(pageName: string) {
        this.postsAndDevicesFacade.openPage(pageName);
    }

    showDeletePopup() {
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.postEdit, () => (this.isShowDeletePopup = true));
        this.isShowDeletePopup = true;
    }

    deleteAccept() {
        this.isShowDeletePopup = false;
        this.actions.deleteMo();
        this.postsAndDevicesFacade.openPage(this.navigationLink[0].path);
    }
    deleteCancel() {
        this.isShowDeletePopup = false;
    }

    onSubmit = async () => {
        this.isLoader = true;
        this.createMo.name = this.name.value;
        this.createMo.locationId = this.selectedCity.idNumber;
        this.createMo.description = this.description.value;
        this.createMo.geoLatitude = this.geoLatitude.value;
        this.createMo.geoLongitude = this.geoLongitude.value;
        this.actions.saveChangeParamMo(this.createMo);
        setTimeout(() => {
            this.isLoader = false;
            this.openPage(this.navigationLink[this.navigationLink.length - 2].path);
        }, 3000);
    };
}

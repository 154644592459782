<switch-item
    class="analytic__switch"
    [currentTab]="currentTabsSeasons"
    [tabs]="tabsSeasons"
    (action)="selectSeason($event)"
></switch-item>

<div *ngIf="noDataForSelectPeriod" class="no_data">
    <div class="no_data__title">{{ TEXTS.CITY_CARD.noData }}</div>
    <div class="no_data__img"></div>
</div>

<div *ngIf="!noDataForSelectPeriod" class="analytic__chart">
    <cs-doughnut-chart
        [isDemo]="isDemo"
        [data]="chartCount"
        [tooltipDescription]="tooltipDescription"
        [measure]="TEXTS.NAMES[measure]"
    ></cs-doughnut-chart>
    <cityscreen-bar-chart
        [isDemo]="isDemo"
        [data]="chartHourAvg"
        [chartName]="CHART_BAR_NAME.dayHour"
        [tooltipDescription]="tooltipDescription"
        [measure]="TEXTS.NAMES[measure]"
    ></cityscreen-bar-chart>
    <cityscreen-bar-chart
        [isDemo]="isDemo"
        [data]="chartDayOfWeek"
        [chartName]="CHART_BAR_NAME.weekDay"
        [tooltipDescription]="tooltipDescription"
        [measure]="TEXTS.NAMES[measure]"
    ></cityscreen-bar-chart>
</div>

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import {
    selectGlobalMeasurement,
    selectIsCityMode,
    selectLocationValue,
} from '@cityair/modules/core/store/selectors';
import { intervalUpdate, openInstantAqiFaq } from '@cityair/modules/core/store/actions';
import { AqiType } from '@libs/common/enums/aqi.type';
import { isRU } from '@libs/common/texts/texts';
import { selectCurrentLocationId } from '@cityair/modules/core/store/current-city/current-city.feature';

@Component({
    selector: 'cityscreen-mobile-header',
    template: `
        <cityscreen-mobile-profile></cityscreen-mobile-profile>
        <calendar-m (updateDateRange)="updateDateRange($event)"></calendar-m>

        <ng-container
            *ngIf="
                canShowAqiIndicator(
                    store.select(selectGlobalMeasurement) | async,
                    isCityMode$ | async
                )
            "
        >
            <cs-aqi-indicator-simple
                class="aqi-indicator"
                [value]="store.select(selectLocationValue(currentLocationId$ | async)) | async"
                (click)="showAqiLegend = !showAqiLegend"
            ></cs-aqi-indicator-simple>

            <ng-container *ngIf="showAqiLegend">
                <!-- Show anything other than CityairAqi only in RU version -->
                <ng-container *ngIf="isRU; else nonRU">
                    <ng-container [ngSwitch]="store.select(selectGlobalMeasurement) | async">
                        <cs-aqi-legend-mobile
                            class="aqi-legend"
                            *ngSwitchCase="AqiType.cityair"
                            [aqi]="
                                store.select(selectLocationValue(currentLocationId$ | async))
                                    | async
                            "
                            (closeLegend)="showAqiLegend = false"
                        ></cs-aqi-legend-mobile>

                        <cs-instant-aqi-legend-mobile
                            class="aqi-legend"
                            *ngSwitchCase="AqiType.instant"
                            [aqi]="
                                store.select(selectLocationValue(currentLocationId$ | async))
                                    | async
                            "
                            (openAqiFaqLink)="openAqiFaqLink()"
                            (closeLegend)="showAqiLegend = false"
                        ></cs-instant-aqi-legend-mobile>

                        <capi-aqi-legend-mobile
                            class="aqi-legend"
                            *ngSwitchCase="AqiType.CAPI"
                            [aqi]="
                                store.select(selectLocationValue(currentLocationId$ | async))
                                    | async
                            "
                            (closeLegend)="showAqiLegend = false"
                        ></capi-aqi-legend-mobile>
                    </ng-container>
                </ng-container>
                <ng-template #nonRU>
                    <cs-aqi-legend-mobile
                        class="aqi-legend"
                        [aqi]="
                            store.select(selectLocationValue(currentLocationId$ | async)) | async
                        "
                        (closeLegend)="showAqiLegend = false"
                    ></cs-aqi-legend-mobile>
                </ng-template>
            </ng-container>
        </ng-container>
    `,
    styleUrls: ['./mobile-header.component.less'],
})
export class MobileHeaderComponent {
    selectGlobalMeasurement = selectGlobalMeasurement;
    selectLocationValue = selectLocationValue;

    isRU = isRU;
    AqiType = AqiType;

    showAqiLegend = false;

    isCityMode$ = this.store.select(selectIsCityMode);
    currentLocationId$ = this.store.select(selectCurrentLocationId);

    constructor(readonly store: Store) {}

    updateDateRange(data: { begin: number; end: number }) {
        this.store.dispatch(intervalUpdate(data));
    }

    openAqiFaqLink() {
        this.store.dispatch(openInstantAqiFaq());
    }

    canShowAqiIndicator = (mmt, isCityMode: boolean) =>
        isCityMode && Object.values(AqiType).includes(mmt);
}

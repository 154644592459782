<div class="plumes_control_points_card" [ngClass]="{ active: isActive }">
    <div class="plumes_control_points_card__content" [ngClass]="{ active: isActive }">
        <div class="plumes_control_points_card__name">{{ point.name }}</div>
    </div>

    <div class="plumes_control_points_card__value">
        <div class="plumes_control_points_card__value_number">
            {{ isFalseNumber(currentPointValues) ? '-' : roundValue(currentPointValues) }}
        </div>
        <div class="plumes_control_points_card__value_description" [innerHTML]="measureUnit"></div>
    </div>
    <kebab-menu (clickActions)="openActions($event)"></kebab-menu>
</div>

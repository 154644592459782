<div class="wrapper-dropdown">
    <div
        class="calculation_results"
        [ngClass]="{ active: isShowList }"
        (clickOutside)="clickOutside()"
        (click)="toggleList()"
    >
        <div class="calculation_results__main" [ngClass]="{ 'no-run': !currentRun }">
            <div class="calculation_results__label">{{ textPlumes.nowOnMap }}</div>
            <div *ngIf="!currentRun">{{ textPlumes.noRunSelected }}</div>
            <div class="plumes-run-summary" *ngIf="currentRun">
                <div class="plumes-run-summary__evaluated">
                    <div class="plumes-run-summary__subtitle">
                        {{ textPlumes.runResults }}
                    </div>
                    <div class="plumes-run-summary__item" *ngIf="runFinished">
                        {{ textPlumes.resultsAt }} {{ formatDate(runFinished) }}
                    </div>
                </div>
                <div class="plumes-run-summary__forecast">
                    <div class="plumes-run-summary__subtitle">
                        {{ textPlumes.forecastOn }}
                    </div>
                    <div
                        class="plumes-run-summary__item plumes-run-summary__item-bold"
                        *ngIf="dateStart && dateEnd"
                    >
                        {{
                            dateStart.isSame(dateEnd, 'day')
                                ? formatTime(dateStart)
                                : formatDate(dateStart)
                        }}
                        – {{ formatDate(dateEnd) }}
                    </div>
                </div>
                <div class="arrow-icon" *ngIf="runs?.length" [ngClass]="{ up: isShowList }"></div>
            </div>
        </div>
        <ng-container *ngIf="isShowList">
            <div class="calculation_results__dropdown_list scroll">
                <ng-container *ngFor="let run of runs" class="calculation_results__dropdown-item">
                    <div class="annotation-wrapper">
                        <div class="annotation" *ngIf="run.annotation">
                            {{ run.annotation }}
                        </div>
                    </div>
                    <div class="item-wrapper">
                        <div class="card_wrapper" (click)="selectRun(run.item)">
                            <plumes-run-summary
                                class="wrapper_run_summary"
                                [run]="run.item"
                            ></plumes-run-summary>
                            <div class="check-icon-wrapper">
                                <span
                                    *ngIf="run.item.id === currentRun.id"
                                    class="active-run"
                                ></span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>

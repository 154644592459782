import { createAction, props } from '@ngrx/store';

import {
    GetCSTimelineInfoRequest,
    InfoMessage,
    IMapClick,
    IntervalEnum,
    PostDate,
    TimelineResponse,
    City_model,
    StationForMapPage_model,
    Marker_model,
} from '@cityair/namespace';
import { Group } from '@libs/common/types/group';
import {
    GroupInfo,
    NotificationSubscription,
} from '@cityair/libs/common/api/adminPanel/dataTransformer';
import { PacketsValueTypeItem } from '@cityair/modules/login/services/harvester-api/models';
import { CityairMapDataTransformerResponse } from '@cityair/libs/common/api/mapProvider/cityairMapModels';
import { StoreSettingsPart } from '@cityair/modules/core/store/reducers';
import { DataMarker, DataQuality, DataQualityInfo } from '@libs/common/models/dataQuality';

export enum CommonActions {
    Noop = '[CommonAction] Do nothing',
    GroupInfoLoad = '[CommonActions] GroupInfoLoad',
    GroupInfoLoaded = '[CommonActions] GroupInfoLoaded',
    SaveSettings = '[CommonActions] SaveSettings',
    GroupListLoaded = '[CommonActions] GroupListLoaded',
    ModifyNotification = '[CommonActions] ModifyNotification',
    TimelineInfoLoad = '[CommonActions] TimelineInfoLoad',
    SelectDevice = '[CommonActions] SelectDevice',
    SelectMo = '[CommonActions] SelectMo',
    TimelineInfoLoaded = '[CommonActions] TimelineInfoLoaded',
    ToggleSidebar = '[CommonActions] toggleSidebar',
    OpenSidebar = '[CommonActions] openSidebar',
    CloseSidebar = '[CommonActions] CloseSidebar',
    MapLoaded = '[CommonActions] MapLoaded',
    SetGlobalMeasurement = '[CommonActions] SetGlobalMeasurement',
    OpenInstantAqiFaq = '[CommonActions] OpenInstantAqiFaq',
    CloseInstantAqiFaq = '[CommonActions] CloseInstantAqiFaq',
    PinsValuesLoaded = '[CommonActions] PinsValuesLoaded',
    CurrentTimeUpdate = '[CommonActions] CurrentTimeUpdate',
    IntervalUpdate = '[CommonActions] IntervalUpdate',
    UpdateCurrentTimeWithNearestData = '[CommonActions] UpdateCurrentTimeWithNearestData',
    SetTimelineDateTimes = '[CommonActions] SetTimelineDateTimes',
    SetCityMode = '[CommonActions] SetCityMode (Legacy)',
    SetWorldMode = '[CommonActions] SetWorldMode (Legacy)',
    CityModeOff = '[CommonActions] CityModeOff (Legacy)',
    SetTzOffset = '[CommonActions] SetTzOffset',
    ResetTzOffset = '[CommonActions] ResetTzOffset',
    ShowSourcesInTimeline = '[CommonActions] ShowSourcesInTimeline',
    UpdatePlumesControlPointChart = '[CommonActions] UpdatePlumesControlPointChart',
    SetErrorMessage = '[CommonAction] SetErrorMessage',
    ClearErrorMessage = '[CommonAction] ClearErrorMessage',
    RefreshVangaToken = '[CommonAction] RefreshVangaToken',
    VangaTokenUpdated = '[CommonAction] VangaTokenUpdated',
    SetComparisonMode = '[CommonAction] SetComparisonMode',
    UpdateTimeRangeData = '[CommonAction] UpdateTimeRangeData',
    SetTypeInterval = '[CommonAction] SetTypeInterval',
    UpdateChartDataByDestroyModule = '[CommonAction] UpdateChartDataByDestroyModule',
    RemoveFromComparison = '[CommonAction] RemoveFromComparison',
    UpdatePostData = '[CommonAction] UpdatePostData',
    VangaTokenUpdateError = '[CommonAction] VangaTokenUpdateError',
    SetInfoMessage = '[CommonAction] SetInfoMessage',
    InitMainMap = '[CommonAction] InitMainMap',
    DestroyMainMap = '[CommonAction] DestroyMainMap',
    ReloadGroupInfo = '[CommonAction] ReloadGroupInfo',
    SetMapClickState = '[CommonAction] SetMapClickState',
    SetCities = '[CommonActions] SetCities',
    SetDefaultGroup = '[CommonActions] SetDefaultGroup',
    SetCurrentGroup = '[CommonActions] SetCurrentGroup',
    UpdateGroupInfo = '[CommonActions] UpdateGroupInfo',
    LoadStationData = '[CommonActions] LoadStationData',
    UpdateStationData = '[CommonActions] UpdateStationData',
    updateCompareStations = '[CommonActions] UpdateCompareStations',
    loadMonitoringData = '[CommonActions] LoadMonitoringData',
    updateCitiesChart = '[CommonActions] updateCitiesChart',
    openCityCard = '[CommonActions] openCityCard',
    openCity = '[CommonActions] openCity',
    selectCity = '[CommonActions] selectCity',
    loadCity = '[CommonActions] loadCity',
    clickFromApToMo = '[CommonActions] clickFromApToMo',
    mapMarkerClick = '[CommonActions] mapMarkerClick',
    clickOnCityMarker = '[CommonActions] clickOnCityMarker',
    setAvailableModule = '[CommonActions] setAvailableModule',
    changeQualityDataMode = '[CommonActions] toggleQualityDataMode',
    toggleShowQualityDataInfo = '[CommonActions] toggleShowQualityDataInfo',
    setQualityDataMarkers = '[CommonActions] setQualityDataMarkers',
    loadQualityData = '[CommonActions] loadQualityData',
    setQualityData = '[CommonActions] setQualityData',
    setQualityDataTimeline = '[CommonActions] setQualityDataTimeline',
}

export const doNothing = createAction(CommonActions.Noop);

export const setErrorMessage = createAction(
    CommonActions.SetErrorMessage,
    props<{ msg: string }>()
);

export const clearErrorMessage = createAction(CommonActions.ClearErrorMessage);

export const initMainMap = createAction(CommonActions.InitMainMap);

export const destroyMainMap = createAction(CommonActions.DestroyMainMap);

export const groupListLoaded = createAction(
    CommonActions.GroupListLoaded,
    props<{ groups: Group[]; packetValueTypes: PacketsValueTypeItem[] }>()
);

export const setDefaultGroup = createAction(CommonActions.SetDefaultGroup);

export const setCurrentGroup = createAction(
    CommonActions.SetCurrentGroup,
    props<{ group: Group | null }>()
);

export const updateGroupInfo = createAction(CommonActions.UpdateGroupInfo);

export const groupInfoLoad = createAction(
    CommonActions.GroupInfoLoad,
    props<{ groupId: number }>()
);
export const groupInfoLoaded = createAction(
    CommonActions.GroupInfoLoaded,
    props<{ groupInfo: GroupInfo }>()
);

export const saveSettings = createAction(CommonActions.SaveSettings, props<StoreSettingsPart>());

export const timelineInfoLoad = createAction(
    CommonActions.TimelineInfoLoad,
    props<GetCSTimelineInfoRequest>()
);
export const timelineInfoLoaded = createAction(
    CommonActions.TimelineInfoLoaded,
    props<CityairMapDataTransformerResponse>()
);

export const selectDevice = createAction(
    CommonActions.SelectDevice,
    props<{ serialNumber: string }>()
);

export const selectMo = createAction(CommonActions.SelectMo, props<{ id: number }>());
export const modifyNotification = createAction(
    CommonActions.ModifyNotification,
    props<{ id: number; props: Partial<NotificationSubscription> }>()
);

export const openSidebar = createAction(CommonActions.OpenSidebar);
export const closeSidebar = createAction(CommonActions.CloseSidebar);
export const toggleSidebar = createAction(CommonActions.ToggleSidebar);

export const mapLoaded = createAction(CommonActions.MapLoaded);

export const setGlobalMeasurement = createAction(
    CommonActions.SetGlobalMeasurement,
    props<{ mmt: string }>()
);

export const openInstantAqiFaq = createAction(CommonActions.OpenInstantAqiFaq);
export const closeInstantAqiFaq = createAction(CommonActions.CloseInstantAqiFaq);

export const pinsValuesLoaded = createAction(
    CommonActions.PinsValuesLoaded,
    props<{ data: TimelineResponse }>()
);

export const setTimelineDateTimes = createAction(
    CommonActions.SetTimelineDateTimes,
    props<{ dateTimes: string[] }>()
);

export const setCityMode = createAction(CommonActions.SetCityMode);

export const setWorldMode = createAction(CommonActions.SetWorldMode);
export const cityModeOff = createAction(CommonActions.CityModeOff);

export const setTzMinutesOffset = createAction(CommonActions.SetTzOffset, props<{ tz: number }>());
export const resetTzMinutesOffset = createAction(CommonActions.ResetTzOffset);

export const showSourcesInTimeline = createAction(
    CommonActions.ShowSourcesInTimeline,
    props<{ ids: string[] }>()
);

export const setComparisonMode = createAction(
    CommonActions.SetComparisonMode,
    props<{ payload: boolean }>()
);

// ----------------------- TIME ---------------------------

export const currentTimeUpdate = createAction(
    CommonActions.CurrentTimeUpdate,
    props<{ time: number }>()
);

export const intervalUpdate = createAction(
    CommonActions.IntervalUpdate,
    props<{ begin: number; end: number }>()
);

export const updateCurrentTimeWithNearestData = createAction(
    CommonActions.UpdateCurrentTimeWithNearestData
);

export const updateTimeRangeData = createAction(CommonActions.UpdateTimeRangeData);

// Plumes

export const updatePlumesControlPointChart = createAction(
    CommonActions.UpdatePlumesControlPointChart
);

export const refreshVangaToken = createAction(CommonActions.RefreshVangaToken);

export const vangaTokenUpdated = createAction(CommonActions.VangaTokenUpdated);

export const setTypeInterval = createAction(
    CommonActions.SetTypeInterval,
    props<{ payload: IntervalEnum }>()
);
export const vangaTokenUpdateError = createAction(CommonActions.VangaTokenUpdateError);

export const removeFromComparison = createAction(
    CommonActions.RemoveFromComparison,
    props<{ uuid: number }>()
);

export const updatePostData = createAction(
    CommonActions.UpdatePostData,
    props<{ payload: PostDate }>()
);
export const showInfo = createAction(CommonActions.SetInfoMessage, props<InfoMessage>());
export const reloadGroupInfo = createAction(CommonActions.ReloadGroupInfo);
export const setMapClickState = createAction(CommonActions.SetMapClickState, props<IMapClick>());

export const loadStationData = createAction(
    CommonActions.LoadStationData,
    props<{ stations: StationForMapPage_model[] }>()
);

export const updateComparedStations = createAction(CommonActions.updateCompareStations);

export const loadMonitoringData = createAction(
    CommonActions.loadMonitoringData,
    props<{ markerId: number }>()
);

export const updateCitiesChart = createAction(CommonActions.updateCitiesChart);

export const openCityCard = createAction(CommonActions.openCityCard);

export const openCity = createAction(CommonActions.openCity, props<{ cityId: string }>());

export const selectCity = createAction(
    CommonActions.selectCity,
    props<{ cityId: string; centringMap: boolean }>()
);

export const loadCity = createAction(
    CommonActions.loadCity,
    props<{ cityId: string; centringMap: boolean }>()
);

export const clickFromApToMo = createAction(
    CommonActions.clickFromApToMo,
    props<{ moObjId: number }>()
);

export const mapMarkerClick = createAction(
    CommonActions.mapMarkerClick,
    props<{ markerId: number }>()
);

export const clickOnCityMarker = createAction(
    CommonActions.clickOnCityMarker,
    props<{ cityMarker: Marker_model }>()
);
export const changeQualityDataMode = createAction(
    CommonActions.changeQualityDataMode,
    props<{ payload: number }>()
);
export const toggleShowQualityDataInfo = createAction(
    CommonActions.toggleShowQualityDataInfo,
    props<{ payload: DataQualityInfo }>()
);
export const setQualityDataMarkers = createAction(
    CommonActions.setQualityDataMarkers,
    props<{ payload: DataMarker[] }>()
);
export const loadQualityData = createAction(CommonActions.loadQualityData, props<{ id: number }>());
export const setQualityData = createAction(
    CommonActions.setQualityData,
    props<{ payload: DataQuality[] }>()
);
// used in tests
export const setCities = createAction(CommonActions.SetCities, props<{ cities: City_model[] }>());
export const setAvailableModule = createAction(
    CommonActions.setAvailableModule,
    props<{ cities: City_model[]; groupInfo: GroupInfo }>()
);

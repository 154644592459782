import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgLocalization } from '@angular/common';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

import { MoItemsDataToExcelRequestProps } from '@cityair/namespace';
import { TabModel } from '@libs/common/types/tab-model';
import type { CheckboxItem } from '@libs/common/types/checkbox-item';
import { saveDataToDisk } from '@cityair/utils/utils';
import { AdminPanelApi } from '@cityair/libs/common/api/adminPanel/api';
import { ErrorTransformer_model } from '@cityair/libs/common/api/dataProvider/DataProvider';
import { LOAD_HISTORY_DEFAULT } from '@cityair/libs/shared/utils/config';
import { selectMos } from '@cityair/modules/core/store/selectors';
import { postsListLabels } from '@cityair/modules/notifications/notifications.utils';
import { PostsAndDevicesFacade } from '../../posts-and-devices.facade';
import { PATH } from '../post-create/post-create.component';
import { NETWORK_PAGES } from '@cityair/modules/posts-and-devices/namespace';
import { TEXTS, isRU, LANGUAGE } from '@libs/common/texts/texts';

@Component({
    selector: 'post-report',
    templateUrl: './post-report.component.html',
    styleUrls: ['./post-report.component.less'],
})
export class PostReportComponent {
    TEXTS = TEXTS;
    isRU = isRU;
    isDisabledPdk = false;
    navigationLink: PATH[] = [];
    isActiveLoader = false;
    targetMo = '';
    errorDownloadMsg = '';
    objectForReportMo: MoItemsDataToExcelRequestProps = {
        timeBegin: null,
        timeEnd: null,
        moIds: [],
        interval: 2,
        type: 1,
        unitsType: 1,
        insertPdk: false,
    };

    isShowDropdownForStations = false;

    listStations: CheckboxItem[] = [];

    filterTabsInterval: TabModel[] = [
        {
            title: this.TEXTS.TIME_NAMES.min5,
            type: 1,
        },
        {
            title: this.TEXTS.TIME_NAMES.min20,
            type: 2,
        },
        {
            title: this.TEXTS.TIME_NAMES.hour1,
            type: 3,
        },
        {
            title: this.TEXTS.TIME_NAMES.day1,
            type: 4,
        },
    ];

    currentFilterTabsInterval: TabModel = this.filterTabsInterval[1];

    unitsMeasurementsList: TabModel[] = [
        {
            title: this.TEXTS.ugM,
            type: 1,
        },
        {
            title: this.TEXTS.mgM,
            type: 2,
        },
    ];

    currentUnitsMeasurements: TabModel = this.unitsMeasurementsList[0];

    constructor(
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        private activatedRoute: ActivatedRoute,
        public adminPanelApi: AdminPanelApi,
        private ngLocalization: NgLocalization,
        public store: Store<any>
    ) {
        this.getDataForPostReport();
    }

    getDataForPostReport() {
        this.objectForReportMo.timeBegin = this.getStndTimeBegin();
        this.objectForReportMo.timeEnd = this.getStndTimeEnd();
        const isAllMo = this.activatedRoute.snapshot.params.id == 'all';
        this.store
            .select(selectMos)
            .pipe(
                filter((mos) => !!mos.length),
                take(1)
            )
            .subscribe((mos) => {
                this.listStations = mos.map((s) => {
                    const isCurrentMo = `${s.id}` === this.activatedRoute.snapshot.params.id;

                    if (isCurrentMo) {
                        this.targetMo = s.name;
                    }

                    return {
                        id: s.id,
                        label: s.name,
                        selected: isCurrentMo || isAllMo,
                    };
                });
            });

        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.postAndDevices,
            path: NETWORK_PAGES.networks + '/' + this.activatedRoute.snapshot.url[0],
        });

        if (this.activatedRoute.snapshot.url[1].path == NETWORK_PAGES.details) {
            this.navigationLink.push({
                // TODO Дима нужен удобный сервис чтоб урлы везде вручную не собирать. В этом сервесе автоматом должны работать такие вещи как name: this.targetMo
                name: this.targetMo,
                path:
                    NETWORK_PAGES.networks +
                    '/' +
                    this.activatedRoute.snapshot.url[0] +
                    '/' +
                    this.activatedRoute.snapshot.url[1] +
                    '/' +
                    this.activatedRoute.snapshot.params.id,
            });
        }

        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.dataFromPost,
            path: null,
        });
    }

    removeFromList(index: number) {
        const label = this.getTags()[index];
        this.listStations.find((s) => {
            if (s.label === label) {
                s.selected = false;
                return true;
            }
        });

        this.listStations = [...this.listStations];
    }

    getTags = () => this.listStations.filter((s) => s.selected).map((s) => s.label);

    postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    backToPrevPage(navigationLink: PATH) {
        if (navigationLink.path) this.postsAndDevicesFacade.openPage(navigationLink.path);
    }

    getDateBeginRangeFromCalendar(time) {
        this.objectForReportMo.timeBegin = time;
    }

    getDateEndRangeFromCalendar(time) {
        this.objectForReportMo.timeEnd = time;
    }

    getStndTimeBegin: () => number = () =>
        moment().startOf('hour').subtract(LOAD_HISTORY_DEFAULT, 'days').valueOf();

    getStndTimeEnd: () => number = () => {
        const m = moment();
        m.startOf('minutes');
        m.subtract(m.get('minutes') % 20, 'minutes'); // floor of 20 minutes
        return m.valueOf();
    };

    setTabsInterval(filterTabsInterval: TabModel) {
        if (filterTabsInterval.type == 1 || filterTabsInterval.type == 3) {
            this.isDisabledPdk = true;
            this.objectForReportMo.insertPdk = false;
        } else {
            this.isDisabledPdk = false;
        }
        this.currentFilterTabsInterval = filterTabsInterval;
        this.objectForReportMo.interval = this.currentFilterTabsInterval.type;
    }

    getUnitMeasurement(unitsMeasurementsList: TabModel) {
        this.currentUnitsMeasurements = unitsMeasurementsList;
        this.objectForReportMo.unitsType = unitsMeasurementsList.type;
    }

    selectedCheckboxText(num: number = 0) {
        const { selected, post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);

        return [selected[category], num, post[category]].join(' ');
    }

    downloadReportMo = () => {
        this.isActiveLoader = true;
        this.objectForReportMo.moIds = this.listStations
            .filter((s) => s.selected)
            .map((s) => Number(s.id));

        const filename =
            this.TEXTS.EDIT_STATION.downloadXLSData + moment().format('_YYYYMMDD_HHmm') + '.xlsx';
        this.adminPanelApi
            .SaveMoDataToExcel(this.objectForReportMo)
            .then((data: string) => {
                saveDataToDisk(data, filename);
                this.offActiveLoader();
            })
            .catch((errorDownloadMsg: ErrorTransformer_model) => {
                this.errorDownloadMsg = errorDownloadMsg.message;
                this.offActiveLoader();
            });
    };

    offActiveLoader() {
        setTimeout(() => {
            this.isActiveLoader = false;
        }, 1000);
    }

    toggleInsertPdk() {
        if (this.isDisabledPdk === false) {
            this.objectForReportMo.insertPdk = !this.objectForReportMo.insertPdk;
        }
    }
}

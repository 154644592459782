import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TEXTS } from '@libs/common/texts/texts';
import { AuthService } from '../../services/auth/auth.service';
import { HarvesterApiService } from '../../services/harvester-api/harvester-api.service';
import {
    ClientConfig,
    ClientConfigService,
} from '../../../core/services/client-config/client-config.service';
import { LoginPage } from '../../login.settings';
import { environment } from 'environments/environment';

@Component({
    selector: 'ca-login-form',
    templateUrl: 'login-form.component.html',
    styleUrls: ['login-form.component.less'],
})
export class LoginFormComponent implements OnInit {
    @Input() subtitle: string;

    TEXTS = TEXTS;

    showPassword = false;

    hasErrors = false;

    isLoading = false;

    loginForm: UntypedFormGroup;

    clientConfig: ClientConfig;

    loginPage = LoginPage;

    isShowMarketLinks = environment.is_mobile_app;

    constructor(
        private router: Router,
        private authService: AuthService,
        readonly harvester: HarvesterApiService,
        private fb: UntypedFormBuilder,
        clientConfigService: ClientConfigService
    ) {
        this.clientConfig = clientConfigService.getConfig();
    }

    ngOnInit() {
        this.loginForm = this.fb.group({
            login: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    async onSubmit() {
        if (this.isLoading) {
            return;
        }

        this.hasErrors = false;

        if (this.loginForm.invalid) {
            return (this.hasErrors = true);
        }

        const { login, password } = this.loginForm.value;

        this.isLoading = true;

        this.authService.login(login.trim(), password).subscribe(
            () => {
                this.isLoading = false;
                this.router.navigate(['/']);
            },
            () => {
                this.isLoading = false;
                this.hasErrors = true;
            }
        );
    }
}

import * as moment from 'moment';

import { MeasureScheme } from '@libs/common/enums/measure-scheme';

import {
    AdminDevice,
    City_model,
    DeviceStatus,
    IntervalEnum,
    IntervalType,
    IntervalV2Type,
    MarkerType,
    WindowGlobalVars,
} from '@cityair/namespace';

import { TEXTS } from '@libs/common/texts/texts';
import { environment } from 'environments/environment';
import {
    AQI,
    CH2O,
    CO,
    CO2,
    H2S,
    HUM,
    NH3,
    NO,
    NO2,
    O3,
    PM10,
    PM25,
    PRES,
    SO2,
    SUM,
    TEMP,
} from '@libs/common/consts/substance.consts';
import { AVAILABLE_AQIS } from '@libs/common/consts/avaliable-aqi.const';
import { detectMobile } from '@libs/common/utils/detect-mobile';

declare let window: WindowGlobalVars;

export const MIN_PASSWORD_LENGTH = 7;

export const CITYAIR_DEVICE_ID = 3;

export const NO_ACCESS_ID = 0;
export const ADMIN_ID = 1;
export const OPERATOR_ID = 2;
export const OBSERVER_ID = 3;
export const SERVICE_ID = 4;

export const RESIZE_TIMEOUT = 200;

export const MAIN_CHART_HEIGHT = 150;
export const MAIN_CHART_HEIGHT_M = 95;

export const STND_CITY_ZOOM = 8;
export const CITY_ZOOM_SHOW_HEXAGON = 10;
export const STND_CITY_MAX_ZOOM = 16;
export const STND_GLOBAL_MIN_ZOOM = 1.5;
export const GLOBAL_ZOOM_LEVEL = 8;

export const DEFAULT_QUALITY_DATA_PERCENT = 50;
export const MO_AREA_RADIUS_IN_METERS = 500;
export const MARKER_AREA_OPACITY = 0.3;
export const AVAILABLE_DATA_IN_MONTH = 12;
export const EMPTY_CITY: City_model = {
    id: null,
    locationId: null,
    name: '',
    countryName: '',
    lat: undefined,
    lng: undefined,
    tzOffset: moment().utcOffset(),
    msOffset: moment().utcOffset() * 60000,
    bounds: null,
    aqiHistory: null,
    originChartData: null,
    distributionSummary: null,
    zIndex: null,
    isSmallCity: null,
    lastData: {},
};

export const ALERT_COLORS = [
    '#B8BFCC',
    '#8CB917',
    '#A2C617',
    '#BEC617',
    '#FFCC33',
    '#FFA33B',
    '#FF7344',
    '#FF494B',
    '#D63B50',
    '#AD2D55',
    '#821E5A',
];

export enum MAP_PROVIDERS {
    // GoogleMaps = 'google-maps',
    MapBox = 'mapbox-gl',
}

export function getOmStatus(arrObjDevice: AdminDevice[]): { text: string; paramPoint: number } {
    let paramPoint = DeviceStatus.OFFLINE;
    let text = TEXTS.LIST_OM.notWorked;
    // TODO: specify requirements

    if (arrObjDevice) {
        if (arrObjDevice.find((d) => d.offline === false)) {
            paramPoint = DeviceStatus.ONLINE;
            text = TEXTS.LIST_OM.worked;
        }

        if (arrObjDevice.find((d) => d.v220 === false)) {
            paramPoint = DeviceStatus.POWERED_BY_BATTERY;
            text = TEXTS.LIST_OM.batWorked;
        }

        if (arrObjDevice.find((d) => d.battery === false)) {
            paramPoint = DeviceStatus.LOW_BATTERY_CHARGE;
            text = TEXTS.LIST_OM.batLow;
        }

        if (arrObjDevice.every((d) => d.offline === true)) {
            paramPoint = DeviceStatus.OFFLINE;
            text = TEXTS.LIST_OM.notWorked;
        }

        if (arrObjDevice.find((d) => d.battery === null)) {
            paramPoint = DeviceStatus.NO_SIGNAL;
            text = TEXTS.LIST_OM.noData;
        }
    }

    return {
        text,
        paramPoint,
    };
}

export function getStationType(dataProviderId: number, accessGranted: boolean): MarkerType {
    if (dataProviderId === 2) {
        return MarkerType.OpenAQ;
    }

    if (accessGranted) {
        return MarkerType.myMo;
    }

    return MarkerType.mo;
}

export function getDataMinimumInterval(
    type: MarkerType,
    timeBegin: number,
    timeEnd: number
): IntervalType {
    let interval_1;

    if (type === 'mo') interval_1 = 2;
    if (type === 'myMo') interval_1 = 1;
    if (type === 'city') interval_1 = 3;
    if (type === 'OpenAQ') interval_1 = 3;

    const days = (timeEnd - timeBegin) / (1000 * 60 * 60 * 24);
    let interval_2 = 1;
    if (type !== 'myMo' && days > 7) interval_2 = 3;
    if (type !== 'myMo' && days > 100) interval_2 = 4;

    return <IntervalType>Math.max(interval_1, interval_2);
}

export const MMT_WITH_PDK_SORTED = [PM25, PM10, CO, CO2, NO2, NO, SO2, O3, H2S, NH3, CH2O, SUM];

export function getColorIndex(zone: number[], value: number) {
    if (value >= zone[zone.length - 1]) {
        return zone.length;
    }

    return zone.findIndex((v) => value < v);
}

export const MMT_FOR_PINS = [...AVAILABLE_AQIS, ...MMT_WITH_PDK_SORTED];

// TODO delete
export const MEASURE_SORT_ALL = [AQI, PM25, PM10, CO, CO2, NO, NO2, SO2, O3, TEMP, PRES, HUM];

export const PACKET_VALUE_TYPE_ID = {
    0: AQI,
    1: TEMP,
    2: PRES,
    3: HUM,
    4: PM25,
    5: PM10,
    6: CO,
    7: CO2,
    8: NO,
    9: NO2,
    10: SO2,
    11: O3,
    12: H2S,
    20: CH2O,
};

export const CHART_PADDING_LR = detectMobile() ? [18, 18] : [50, 50]; // less @chartMobileLeftMargin, @mapPageLRpadding

export const formatDayMonth = (m: moment.Moment): string =>
    m.format('D') + ' ' + m.format('MMM').substring(0, 3);
export const formatDayMonthLowerCase = (m: moment.Moment): string =>
    m.format('D') + ' ' + m.format('MMM').substring(0, 3).toLowerCase();
export const formatDayMonthYear = (m: moment.Moment) =>
    `${m.format('D')} ${m.format('MMM').substring(0, 3)} ${m.format('YYYY')}`;

export const getShiftMapCityCard = (): [number, number] => [
    -250,
    (document.documentElement.clientHeight - 110) /* меню+таймлайн */ / 2 - 150,
];
export const getShiftMapMobile = (): [number, number] => [
    0,
    421 /* таймлайн+шторка */ - document.documentElement.clientHeight / 2,
];

export const MIN_PCF = 0.1;
export const MAX_PCF = 3;

export type ModelProps = {
    measure: string;
    contours: boolean;
    overlay: boolean;
};

export const MEASURES_FOR_FORECAST = [PM25, PM10, NO2, SO2 /* O3 */];

export function getApiV2Interval(timeBegin: number, timeEnd: number): IntervalV2Type {
    const days = Math.abs(moment(timeBegin).diff(moment(timeEnd), 'days'));

    if (days <= 3) return '5m';

    if (days <= 15) return '20m';

    if (days <= 45) return '1h';

    return '1d';
}

export const STND_INTERVAL = (
    detectMobile() ? IntervalEnum.hour : IntervalEnum.min20
) as IntervalEnum;

export const STND_INTERVALS = [
    {
        interval: IntervalEnum.min5,
        days: 1.5,
    },
    {
        interval: IntervalEnum.min20,
        days: 5,
    },
    {
        interval: IntervalEnum.hour,
        days: 28,
    },
];

export const AVAILABLE_INTERVALS = [
    {
        interval: IntervalEnum.min5,
        days: 3.3,
    },
    {
        interval: IntervalEnum.min20,
        days: 12,
    },
    {
        interval: IntervalEnum.hour,
        days: 36,
    },
    {
        interval: IntervalEnum.day,
        days: Infinity,
    },
];

export const STND_MOBILE_INTERVALS = [
    {
        interval: IntervalEnum.min5,
        days: 0,
    },
    {
        interval: IntervalEnum.min20,
        days: 0,
    },
    {
        interval: IntervalEnum.hour,
        days: 7,
    },
];

export const TIMELINE_LOW_DETAILS_DAYS = 28;

export function getAssetPath(asset: string) {
    return asset ? `${environment.deploy_url}assets/${asset}` : null;
}

export function getClientAssetPath(asset: string) {
    return asset ? `${environment.deploy_url}client_assets/${asset}` : null;
}

const NUMBER_AFTER_DOT: {
    [key in MeasureScheme]: number;
} = {
    [MeasureScheme.default]: 1,
    [MeasureScheme.usa_default]: 1,
    [MeasureScheme.c_mmhg_mg]: 3,
    [MeasureScheme.mpc]: 3,
};

export const getDigitsAfterDot = (ms: MeasureScheme, mmt: string) => {
    if (NUMBER_AFTER_DOT[ms] && MMT_WITH_PDK_SORTED.includes(mmt)) return NUMBER_AFTER_DOT[ms];

    return 1;
};

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, filter, firstValueFrom, lastValueFrom, Subject, take } from 'rxjs';

import { AqiType } from '@libs/common/enums/aqi.type';
import { InfoMessage } from '@cityair/namespace';
import { isOldBrowser } from '@cityair/utils/utils';
import { isRU, TEXTS } from '@libs/common/texts/texts';
import { detectMobile } from '@libs/common/utils/detect-mobile';
import { MapAdapterService } from '@cityair/modules/map/services/map-adapter.service';
import {
    getWorldAqiHistory,
    selectAvailableModule,
    selectCities,
    selectCitiesMarker,
    selectErrorMessage,
    selectGroupInfo,
    selectGroupList,
    selectInfoMessage,
    selectIsInstantAqiFaqOpen,
    selectIsShowQualityDataInfo,
    selectQualityDataMarkers,
    selectIsSidebarOpen,
    selectLoadingTimeline,
    selectTimeRange,
} from './modules/core/store/selectors';
import {
    changeQualityDataMode,
    clearErrorMessage,
    clickOnCityMarker,
    closeInstantAqiFaq,
    closeSidebar,
    loadMonitoringData,
    loadQualityData,
    openSidebar,
    selectCity,
    setComparisonMode,
    setTimelineDateTimes,
    showInfo,
    toggleShowQualityDataInfo,
    toggleSidebar,
    updateCitiesChart,
    updateComparedStations,
    updateTimeRangeData,
} from './modules/core/store/actions';
import { RoutingService } from './modules/core/routing.service';
import { environment } from '../../../environments/environment';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { getModulePageConfig } from './modules/core/store/module-page-config/module-page-config.feature';
import {
    GroupExtConfigName,
    GroupFeaturesService,
} from './modules/core/services/group-features/group-features.service';
import MapboxActions from './modules/map/components/mapbox/mapboxActions';
import { selectComparedItems } from './modules/core/store/compared-list/compared-list.selectors';
import { getClientAssetPath, getShiftMapCityCard, getShiftMapMobile } from './config';
import { selectCurrentCity } from './modules/core/store/current-city/current-city.feature';
import { NavigationEnd, Router } from '@angular/router';
import { ClientConfigService } from '@cityair/modules/core/services/client-config/client-config.service';
import { ChangeGroupHelperService } from '@cityair/modules/core/services/change-group-helper.service';
import { FeedbackApiService } from '@cityair/modules/core/services/api/feedback-api';
import { FormErrors } from '@libs/common/models/formErrors';
import { MessageAPIResponseService } from '@libs/shared-ui/components/little-components/message-api-response/message-api-response.service';
import { currentSavedLang, setSavedLang } from '@libs/common/utils/local-storage';

const templateNotSupported = `
    <div class="browser_not_support">{{TEXTS.COMMON.browserNotSupport}}</div>
`;

const templateDesktop = `
    <ca-tooltip-outlet></ca-tooltip-outlet>
    <ca-popup-outlet></ca-popup-outlet>

    <mapbox-map [ngStyle]="{visibility: (moduleConfig$ | async).enableMap ? 'visible' : 'hidden'}"
        [postPins]="mapAdapter.postPins"
        [zoom]="mapAdapter.zoom"
        [center]="mapAdapter.center"

        [notificationSelectedPins]="mapAdapter.notificationsSelectedPosts"
        [cityPins]="mapAdapter.cityPins"
        [controlPointPins]="mapAdapter.controlPointPins"

        [groupFeaturesLayer]="mapAdapter.groupFeaturesLayer"
        [pinsAreaData]="mapAdapter.pinsAreaData"

        (mapDragEnd)="mapAdapter.onMapDragEnd?.($event)"
        (zoomChanged)="mapAdapter.onMapZoomChanged?.($event)"
    ></mapbox-map>

    <ng-container [ngSwitch]="routingService.pageChange$ | async">
        <forecast-map *ngSwitchCase="MAIN_PAGES.forecast"></forecast-map>
        <notifications-map *ngSwitchCase="MAIN_PAGES.notifications"></notifications-map>
        <plumes-map *ngSwitchCase="MAIN_PAGES.plumes"></plumes-map>
        <default-map *ngSwitchDefault></default-map>
    </ng-container>

    <div class="admin_panel">
        <div class="sidebar">
            <shared-ui-sidebar-menu
                [groupList]="store.select(selectGroupList) | async"
                [currentGroupId]="(store.select(selectGroupInfo) | async)?.groupId"
                [assets]="assets"
                [availableModules]="availableModules"
                [activeModule]="activeModule"
                (changeGroup)="changeGroup($event)"
                (selectModule)="selectModule($event)"
                (showFeedback)="showFeedback()"
            ></shared-ui-sidebar-menu>
            <div
                class="sidebar_container"
                [ngClass]="{
                    'sidebar_container-active': store.select(selectIsSidebarOpen) | async,
                    'sidebar_container-full_width': activeModule === MAIN_PAGES.indoor }"
            >
                <ca-sidebar-toggle-button
                    *ngIf="routeIsNotEmpty() && !routingService.isActiveRoute(MAIN_PAGES.indoor)"
                    [isActive]="store.select(selectIsSidebarOpen) | async"
                    (toggle)="store.dispatch(toggleSidebar())"
                ></ca-sidebar-toggle-button>

                <router-outlet></router-outlet>
            </div>
            <cs-feedback-form
                class="sidebar__feedback-form"
                *ngIf="showFeedbackForm"
                [success]="feedbackForm.success$"
                [errors]="feedbackForm.errors$"
                (submitForm)="submitFeedback($event)"
                (closeForm)="showFeedbackForm = false"
            ></cs-feedback-form>

            <message-api-response></message-api-response>
        </div>
    </div>

    <loader_map *showDirective="store.select(selectLoadingTimeline) | async"></loader_map>
    <stnd-error-popup *ngIf="store.select(selectErrorMessage) | async"
        [errorMsg]="store.select(selectErrorMessage) | async"
        [close]="closeErrorPopup"
    ></stnd-error-popup>
    <download-popups-wrapper></download-popups-wrapper>
    <instant-aqi-faq *ngIf="store.select(selectIsInstantAqiFaqOpen) | async" (closeModal)="closeInstantAqiFaq()"></instant-aqi-faq>
<!--    <cs-onboarding *ngIf="canShowPopup && isRu" featureName="added-reports-02-2023"></cs-onboarding>-->
    <info-message *ngIf="infoMessage?.messageKey||infoMessage?.message" [data]="infoMessage" (hide)="hideInfoMessage()"></info-message>
    <cityair-data-quality
         *ngIf="store.select(selectIsShowQualityDataInfo) | async as data"
         [data]="data"
         [qualityDataPercent]="qualityDataMode"
         [dataMarkers]="store.select(selectQualityDataMarkers) | async"
         (closeModal)="closeDataQualityInfo()"
     ></cityair-data-quality>
`;

const templateMobile = `
    <ca-tooltip-outlet></ca-tooltip-outlet>
    <ca-popup-outlet></ca-popup-outlet>

    <mapbox-map [ngStyle]="{display: (moduleConfig$ | async).enableMap ? '' : 'none'}"
        [postPins]="mapAdapter.postPins"
        [zoom]="mapAdapter.zoom"
        [center]="mapAdapter.center"

        [notificationSelectedPins]="mapAdapter.notificationsSelectedPosts"
        [cityPins]="mapAdapter.cityPins"
        [controlPointPins]="mapAdapter.controlPointPins"

        [groupFeaturesLayer]="mapAdapter.groupFeaturesLayer"
        [pinsAreaData]="mapAdapter.pinsAreaData"

        (mapDragEnd)="mapAdapter.onMapDragEnd?.($event)"
        (zoomChanged)="mapAdapter.onMapZoomChanged?.($event)"
    ></mapbox-map>

    <ng-container [ngSwitch]="routingService.pageChange$ | async">
        <indoor-main-mobile *ngSwitchCase="MAIN_PAGES.indoor"></indoor-main-mobile>
        <plumes-map-mobile *ngSwitchCase="MAIN_PAGES.plumes"></plumes-map-mobile>
        <indoor-widget *ngSwitchCase="MAIN_PAGES.indoorWidget"></indoor-widget>
        <cityair-analytics-map-mobile *ngSwitchCase="MAIN_PAGES.analytics"></cityair-analytics-map-mobile>
        <default-map-mobile *ngSwitchDefault></default-map-mobile>
    </ng-container>

    <loader_map *showDirective="store.select(selectLoadingTimeline) | async"></loader_map>
    <stnd-error-popup *ngIf="store.select(selectErrorMessage) | async"
        [errorMsg]="store.select(selectErrorMessage) | async"
        [close]="closeErrorPopup"
    ></stnd-error-popup>
    <download-popups-wrapper></download-popups-wrapper>
    <instant-aqi-faq *ngIf="store.select(selectIsInstantAqiFaqOpen) | async" (closeModal)="closeInstantAqiFaq()"></instant-aqi-faq>
    <info-message *ngIf="infoMessage?.messageKey||infoMessage?.message" [data]="infoMessage" (hide)="hideInfoMessage()"></info-message>
`;

enum Views {
    NOT_SUPPORTED = 'not-supported',
    MOBILE = 'mobile',
    MAP = 'map',
}

@Component({
    selector: 'map-page',
    styleUrls: ['mapPage.less'],
    template: `
        <ng-container [ngSwitch]="currentView">
            <ng-container *ngSwitchDefault>${templateDesktop}</ng-container>

            <ng-container *ngSwitchCase="views.MOBILE">${templateMobile}</ng-container>

            <ng-container *ngSwitchCase="views.NOT_SUPPORTED">${templateNotSupported}</ng-container>
        </ng-container>
    `,
})
export class CityScreenPage implements OnInit {
    TEXTS = TEXTS;
    isRu = isRU;
    views = Views;
    currentView: string;

    AqiType = AqiType;

    selectIsInstantAqiFaqOpen = selectIsInstantAqiFaqOpen;
    selectLoadingTimeline = selectLoadingTimeline;
    selectErrorMessage = selectErrorMessage;
    selectGroupInfo = selectGroupInfo;
    selectIsSidebarOpen = selectIsSidebarOpen;
    selectGroupList = selectGroupList;
    toggleSidebar = toggleSidebar;
    selectIsShowQualityDataInfo = selectIsShowQualityDataInfo;
    selectQualityDataMarkers = selectQualityDataMarkers;

    MAIN_PAGES = MAIN_PAGES;
    assets: Record<string, string> = {};
    availableModules: MAIN_PAGES[];
    activeModule: MAIN_PAGES;
    showToggleButton = true;
    showFeedbackForm = false;
    moduleConfig$ = this.store.select(getModulePageConfig);
    feedbackForm = {
        success$: new Subject<void>(),
        errors$: new Subject<FormErrors>(),
    };
    currentGroupId: number;
    currentUserId: number; // remove after test quality
    canShowPopup = false;
    public infoMessage: InfoMessage;
    public qualityDataMode = null;

    private loadedGroupInfo$ = this.store.select(selectGroupInfo).pipe(
        filter((groupInfo) => !!groupInfo?.groupId),
        take(1)
    );

    private loadedTimelineInfo$ = this.store.select(selectLoadingTimeline).pipe(
        filter((val) => val === false),
        take(1)
    );

    public firstLoad$ = combineLatest([this.loadedGroupInfo$, this.loadedTimelineInfo$]).pipe(
        take(1)
    );

    constructor(
        public routingService: RoutingService,
        readonly store: Store,
        readonly mapAdapter: MapAdapterService,
        private groupFeaturesService: GroupFeaturesService,
        private mapActions: MapboxActions,
        private router: Router,
        readonly clientConfigService: ClientConfigService,
        private changeGroupHelper: ChangeGroupHelperService,
        private msgService: MessageAPIResponseService,
        readonly feedbackApiService: FeedbackApiService
    ) {
        this.currentView = isOldBrowser()
            ? Views.NOT_SUPPORTED
            : detectMobile()
            ? Views.MOBILE
            : Views.MAP;

        store
            .select(selectGroupInfo)
            .pipe(filter((g) => !!g))
            .subscribe((g) => {
                this.canShowPopup =
                    !environment.isAWS &&
                    ![66, 67].includes(g.groupId) &&
                    location?.href.indexOf('eco.city.online') === -1;
                this.currentGroupId = g.groupId;
                this.currentUserId = g.iAm?.userId;
            });
        store.select(selectInfoMessage).subscribe((info) => (this.infoMessage = info));

        store.pipe(selectTimeRange).subscribe(async () => {
            this.store.dispatch(updateTimeRangeData());
            this.store.dispatch(updateComparedStations());
        });

        this.store
            .select(selectCities)
            .pipe(filter((cities) => !!cities && !!cities.length))
            .subscribe(async (cities) => {
                const comparedItems = await firstValueFrom(this.store.select(selectComparedItems));

                if (comparedItems.some((s) => s.type === 'city')) {
                    this.store.dispatch(updateCitiesChart());
                }

                const currentCity = await firstValueFrom(this.store.select(selectCurrentCity));
                const city = cities.find((el) => el.id === currentCity?.id) ?? null;

                const data = city
                    ? city.aqiHistory ?? []
                    : await firstValueFrom(this.store.select(getWorldAqiHistory));

                this.store.dispatch(
                    setTimelineDateTimes({
                        dateTimes: data.map(({ time }) => new Date(time).toISOString()),
                    })
                );
            });
        this.store
            .select(selectAvailableModule)
            .pipe(filter((v) => !!v.length))
            .subscribe((modules) => {
                this.availableModules = modules;
                const page = this.routingService.getActivePage() as MAIN_PAGES;
                if (page && modules.length && modules.indexOf(page) === -1) {
                    this.routingService.goToPage(MAIN_PAGES.default);
                    this.store.dispatch(closeSidebar());
                }
            });

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.activeModule = this.router.url.split('/')[1] as MAIN_PAGES;
                this.showToggleButton =
                    !!this.activeModule || this.activeModule !== MAIN_PAGES.indoor;
            }
        });
        this.initAssets();
    }

    ngOnInit() {
        this.firstLoad$.subscribe(async () => {
            await this.initDefaultLang();
            await this.initQualityData();
            await this.initDefaultMo();
        });

        this.feedbackApiService.errorMessage$
            .pipe(filter((message) => !!message))
            .subscribe((message) => {
                this.feedbackForm.errors$.next(null);
                this.msgService.setMsg({ type: 'error', message });
            });
    }

    private async initDefaultMo() {
        const defaultMo = this.groupFeaturesService.getDefaultMo();

        if (defaultMo) {
            if (defaultMo.length > 1) {
                this.store.dispatch(setComparisonMode({ payload: true }));
            } else {
                this.store.dispatch(setComparisonMode({ payload: false }));
            }

            const markers = await lastValueFrom(
                this.store.select(selectCitiesMarker).pipe(take(1))
            );

            defaultMo.forEach((id) => {
                setTimeout(() => {
                    // city
                    if (id < 0) {
                        const marker = markers.find((c) => c.id === -id);

                        if (marker) {
                            this.store.dispatch(clickOnCityMarker({ cityMarker: marker }));
                        }
                    } else {
                        this.store.dispatch(loadMonitoringData({ markerId: id }));
                        if (defaultMo.length === 1 && this.qualityDataMode) {
                            this.store.dispatch(loadQualityData({ id: id }));
                        }
                    }
                }, 2000);
            });

            // передвигаем карту
            const marker = (await firstValueFrom(this.store.select(selectComparedItems)))[0];

            if (marker) {
                setTimeout(() => {
                    this.mapActions.centringOnMarker(
                        marker.lat,
                        marker.lng,
                        true,
                        detectMobile() ? getShiftMapMobile() : getShiftMapCityCard()
                    );
                });
            }
        }
    }

    private async initDefaultLang() {
        const defaultLang = this.groupFeaturesService.getConfig(GroupExtConfigName.defaultLang);
        if (defaultLang && defaultLang !== currentSavedLang) {
            setSavedLang(defaultLang);
            location.reload();
        }
    }

    private async initQualityData() {
        const qualityMode = this.groupFeaturesService.getConfig(
            GroupExtConfigName.dataQualityPercent
        );

        if (qualityMode && !environment.isAWS) {
            this.qualityDataMode = qualityMode;
            this.store.dispatch(changeQualityDataMode({ payload: this.qualityDataMode }));
        }
    }

    closeInstantAqiFaq() {
        this.store.dispatch(closeInstantAqiFaq());
    }

    closeDataQualityInfo() {
        this.store.dispatch(toggleShowQualityDataInfo({ payload: null }));
    }

    closeErrorPopup = () => this.store.dispatch(clearErrorMessage());

    hideInfoMessage() {
        this.store.dispatch(showInfo(null));
    }

    public routeIsNotEmpty = () => !!this.router.url.substring(1);

    public selectModule($event) {
        this.router.navigate([$event], {});
        this.store.dispatch(openSidebar());
    }

    public changeGroup(groupId) {
        this.changeGroupHelper.changeGroup(groupId);
    }

    public showFeedback() {
        this.showFeedbackForm = true;
    }

    public submitFeedback(message: string) {
        this.feedbackApiService.sendFeedback(message, this.currentGroupId).subscribe(() => {
            this.feedbackForm.success$.next();
            this.msgService.setMsg({
                type: 'success',
                message: TEXTS.FEEDBACK.successTitle,
                description: TEXTS.FEEDBACK.successMessage,
            });
        });
    }

    private initAssets() {
        const clientConfig = this.clientConfigService.getConfig();

        const logoSize = clientConfig.sidebar?.logoWidth;
        const logo = clientConfig.sidebar?.logo;

        this.assets = {
            styleBackgroundSize: logoSize > 0 && `${logoSize}px auto`,
            logo: logo && 'url(' + getClientAssetPath(logo) + ')',
        };
    }
}

import { CO, H2S, NO2, O3, SO2 } from '@libs/common/consts/substance.consts';
const ACCURACY = [
    ['±20', null],
    [null, '±20'],
];
export const METROLOGICAL_DATA = [
    {
        mmt: NO2,
        indicationRange: '0 – 5,00',
        measurementRanges: ['0 – 0,10', '0,10 – 4,00'],
        accuracy: ACCURACY,
    },
    {
        mmt: O3,
        indicationRange: '0 – 4,00',
        measurementRanges: ['0 – 0,10', '0,10 – 3,00'],
        accuracy: ACCURACY,
    },
    {
        mmt: CO,
        indicationRange: '0 – 55,00',
        measurementRanges: ['0 – 1,00', '1,00 – 50,00'],
        accuracy: ACCURACY,
    },
    {
        mmt: H2S,
        indicationRange: '0 – 2,000',
        measurementRanges: ['0 – 0,008', '0,008 – 0,600'],
        accuracy: ACCURACY,
    },
    {
        mmt: SO2,
        indicationRange: '0 – 8,00',
        measurementRanges: ['0 – 0,05', '0,05 – 4,00'],
        accuracy: ACCURACY,
    },
];
export const LINK_RSI = {
    cityair: 'https://fgis.gost.ru/fundmetrology/registry/4/items/1384827',
    gModule: 'https://fgis.gost.ru/fundmetrology/registry/4/items/1228914',
    dust: 'https://fgis.gost.ru/fundmetrology/registry/4/items/789707',
};

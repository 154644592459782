import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiRequest,
    ErrorNumberType,
    ErrorResult,
    WebResponse,
} from '@cityair/modules/login/services/harvester-api/models';
import { GROUP_ID, WindowGlobalVars } from '@cityair/namespace';
import { environment } from 'environments/environment';

declare const window: WindowGlobalVars;

// TODO: Replace promise with observable
@Injectable({ providedIn: 'root' })
export class DataProvider3 {
    constructor(private readonly http: HttpClient) {}

    execHttp = (
        controllerName: string,
        funcName: string,
        requestData: ApiRequest,
        execOk: (arg: any) => void,
        execFail: (errorResult: ErrorResult) => void
    ) => {
        this.execHttpEx(controllerName, funcName, requestData, true, true, execOk, execFail);
    };

    execHttpEx = (
        controllerName: string,
        funcName: string,
        requestData: any,
        showBusyDialog: boolean,
        showErrorDialog: boolean,
        execOk: (arg: any) => void,
        execFail: (errorResult: ErrorResult) => void
    ) => {
        requestData.Token = window.JS_CP_TOKEN;

        const url = environment.harvester_v1_api_url + '/' + controllerName + '/' + funcName;

        window.sentryAddBreadcrumb?.({
            data: {
                online: window.navigator?.onLine,
                url,
                user: window.JS_CP_LOGIN,
                group: localStorage?.getItem(GROUP_ID),
            },
            timestamp: new Date().getTime(),
        });

        this.http.post(url, requestData).subscribe(
            (resultAny: any) => {
                const result = resultAny as WebResponse<any>;
                if (!result) {
                    this.execFailInternal(
                        url,
                        null,
                        'DataProvider3 result is undefined',
                        undefined,
                        execFail,
                        requestData
                    );
                } else if (result.Message !== undefined) {
                    this.execFailInternal(
                        url,
                        result.ErrorNumber,
                        result.Message,
                        result.ErrorMessageDetails,
                        execFail,
                        requestData
                    );
                } else if (result.IsError) {
                    this.execFailInternal(
                        url,
                        result.ErrorNumber,
                        result.ErrorMessage,
                        result.ErrorMessageDetails,
                        execFail,
                        requestData
                    );
                } else if (typeof result === 'string') {
                    this.execFailInternal(
                        url,
                        undefined,
                        result + '',
                        undefined,
                        execFail,
                        requestData
                    );
                } else {
                    this.execOkInternal(result.Result, execOk);
                }
            },
            (error: any) => {
                let errorMessage = 'Unknown Error';
                let errorNumber = ErrorNumberType.NotSpecified;
                if (error instanceof HttpErrorResponse) {
                    const errResp = error as HttpErrorResponse;
                    const result = errResp.error as WebResponse<any>;
                    if (result?.IsError) {
                        errorMessage = result?.ErrorMessage;
                        errorNumber = result?.ErrorNumber;
                    } else {
                        errorMessage = errResp?.message;
                    }
                }

                this.execFailInternal(url, errorNumber, errorMessage, null, execFail, requestData);
            }
        );
    };

    private execOkInternal = (result: any, execOk: (arg: any) => void) => {
        if (execOk != null) execOk(result);
    };

    private execFailInternal = (
        url: string,
        errorNumber: ErrorNumberType,
        errorMessage: string,
        errorMessageDetails: string,
        execFail: (errorResult: ErrorResult) => void,
        requestData: any
    ) => {
        if (window.navigator?.onLine === false || errorNumber === 0) {
            return;
        }

        if (window.sentryCaptureException && errorNumber === ErrorNumberType.InfoException) {
            const errorResult = new ErrorResult(errorNumber, errorMessage);
            const error = new Error();
            error.name = errorResult.getErrorText();
            error.message = errorResult.getErrorText();
            if (errorMessageDetails) {
                error.message += ` (details: ${errorMessageDetails})`;
            }
            window.sentryCaptureException(error);
        }

        if (execFail != null) execFail(new ErrorResult(errorNumber, errorMessage));
    };
}

export class ErrorTransformer_model {
    message: string;
    errorType: ErrorNumberType;
}

export const errorTransformer = (errorResult: ErrorResult): ErrorTransformer_model => ({
    message: errorResult.getErrorText(),
    errorType: errorResult.ErrorNumber,
});

import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { NgLocalization } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import type { CheckboxItem } from '@libs/common/types/checkbox-item';
import { isRU, TEXTS } from '@libs/common/texts/texts';
import { NotificationSubscription } from '@cityair/libs/common/api/adminPanel/dataTransformer';
import { MessageAPIResponseService } from '@libs/shared-ui/components/little-components/message-api-response/message-api-response.service';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { NotificationsStateService } from '../../notifications-state.service';
import { postsListLabels, selectedPostsText } from '../../notifications.utils';
import { MAX_INLINE_MOS } from '../../notifications.settings';

import {
    deduplicate,
    EmailsListData,
    haveDifferentValues,
    mapEmails,
    stringToList,
    updateFormControls,
} from '../../components/settings/settings.utils';

import { selectMeasures, selectMos } from '@cityair/modules/core/store/selectors';
import {
    GroupExtConfigName,
    GroupFeaturesService,
} from '@cityair/modules/core/services/group-features/group-features.service';
import { selectNotifiablePostIds } from '../../store/selectors';
import { updateNotifiablePosts } from '../../store/actions';
import { TSP } from '@libs/common/consts/substance.consts';
import { TooltipsService } from '@libs/shared-ui/components/tooltips/tooltips.service';
import { MonitoringObject } from '@cityair/namespace';

@Component({
    selector: 'settings-measurements',
    templateUrl: 'settings-measurements.component.html',
    styleUrls: ['settings-measurements.component.less'],
})
export class SettingsMeasurements implements OnInit, OnDestroy {
    @Input() subscription: NotificationSubscription;

    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    workingSubscription: NotificationSubscription;
    mosList: MonitoringObject[];
    allMos: number[];
    allSubstances: number[];
    moCheckboxes: CheckboxItem[] = [];

    textsNotification = TEXTS.NOTIFICATIONS;
    textsPopup = TEXTS.POPUP_THREE_QUESTIONS;

    showPdkTooltip = isRU;

    TEXTS = TEXTS;

    settingsForm: UntypedFormGroup;

    emailsListData: EmailsListData;
    emailsListReportsData: EmailsListData;

    saving = false;
    formDataHasChanged = false;
    showMosSelection = false;
    showConfirmationPopup = false;

    maxInlineMos = MAX_INLINE_MOS;

    allowTSP = false;
    minPDK = 0.5;
    maxPDK = 10;

    subscriptions: Subscription[] = [];

    constructor(
        private element: ElementRef,
        private stateService: NotificationsStateService,
        private ngLocalization: NgLocalization,
        private fb: UntypedFormBuilder,
        private popupProvider: OffPanelPopupService,
        private msgService: MessageAPIResponseService,
        readonly tooltipsService: TooltipsService,
        groupFeaturesService: GroupFeaturesService,
        private store: Store
    ) {
        this.allowTSP = groupFeaturesService.getConfig(GroupExtConfigName.enableTSP) as boolean;

        const substancesSub = store.select(selectMeasures).subscribe((packetsValueTypes) => {
            this.allSubstances = packetsValueTypes
                .map((p) => p.ValueType)
                .filter((type) => {
                    const name = this.getValueName(type);

                    if (this.allowTSP || name !== TEXTS.NAMES[TSP]) {
                        return name;
                    }
                });
        });

        this.subscriptions.push(substancesSub);

        const monitoringObjectsSub = store
            .select(selectMos)
            .pipe(filter((v) => !!v.length))
            .subscribe((mos) => {
                this.mosList = mos;
                this.allMos = mos.map((v) => v.id);
            });

        this.subscriptions.push(monitoringObjectsSub);

        const maxPDK = Math.round(
            groupFeaturesService.getConfig(GroupExtConfigName.commonMaxPDK) as number
        );

        if (maxPDK >= 10 && maxPDK <= 30) {
            this.maxPDK = maxPDK;
        }
    }

    selectedPostsText = selectedPostsText.bind(null, this.ngLocalization);
    getMoNameHandler = this.getMoName.bind(this);

    ngOnInit() {
        this.workingSubscription = NotificationSubscription.clone(this.subscription);

        this.emailsListData = mapEmails(this.workingSubscription.emailsList);
        this.emailsListReportsData = mapEmails(this.workingSubscription.emailsListReports);

        if (this.workingSubscription.isForAllMos) {
            this.workingSubscription.moItems = this.allMos.slice();
        }

        // TODO: server should probably update this list upon station removal
        this.workingSubscription.moItems = this.workingSubscription.moItems.filter((id) =>
            this.getMoName(id)
        );

        this.moCheckboxes = this.toCheckboxItems(this.allMos, this.workingSubscription.moItems);

        this.setNotifiableMos();

        this.settingsForm = this.fb.group({
            excessLevel: [this.workingSubscription.excessLevel, [Validators.required]],
            filterMosText: [''],
            title: [this.workingSubscription.title, [Validators.required, Validators.minLength(1)]],
            isForAllMos: [this.workingSubscription.isForAllMos],
            moItems: this.fb.group(this.getUpdatedCheckboxData(this.workingSubscription.moItems)),
            emailsList: [this.workingSubscription.emailsList, [Validators.minLength(1)]],
            emailsListProxy: this.fb.group(
                this.emailsListData.reduce(
                    (acc, { name, value }) => ({
                        ...acc,
                        [name]: [value, [Validators.email]],
                    }),
                    {}
                )
            ),
            emailsListReports: [
                this.workingSubscription.emailsListReports,
                [Validators.minLength(1)],
            ],
            emailsListReportsProxy: this.fb.group(
                this.emailsListReportsData.reduce(
                    (acc, { name, value }) => ({
                        ...acc,
                        [name]: [value, [Validators.email]],
                    }),
                    {}
                )
            ),
            isPeriodic: [this.workingSubscription.isPeriodic],
        });

        const title$ = this.title.valueChanges.pipe(debounceTime(200), distinctUntilChanged());
        const emailsList$ = this.emailsList.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        );
        const emailsListReports$ = this.emailsListReports.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        );
        const moItems$ = this.moItems.valueChanges.pipe(distinctUntilChanged());

        const titleSub = title$.subscribe((value) => {
            this.workingSubscription.title = value;
        });

        const excessLevelSub = this.excessLevel.valueChanges
            .pipe(debounceTime(600), distinctUntilChanged())
            .subscribe((value) => {
                // fix value on the fly instead of using validators

                let fixedValue = Math.max(Math.min(value, this.maxPDK), this.minPDK);

                if (isNaN(fixedValue)) {
                    fixedValue = this.minPDK;
                }

                if (value !== fixedValue) {
                    this.excessLevel.patchValue(fixedValue);
                } else {
                    this.workingSubscription.excessLevel = fixedValue;
                }
            });

        const emailsListSub = emailsList$.subscribe((value) => {
            this.updateList(value.toString());
            const emails = Object.values(this.emailsListProxy.value as string[]);
            this.workingSubscription.emailsList = deduplicate(emails);
        });

        const emailsListReportsSub = emailsListReports$.subscribe((value) => {
            this.updateListReports(value.toString());
            const emails = Object.values(this.emailsListReportsProxy.value as string[]);
            this.workingSubscription.emailsListReports = deduplicate(emails);
        });

        const isPeriodicSub = this.isPeriodic.valueChanges.subscribe((value) => {
            this.workingSubscription.isPeriodic = value;
        });

        const isForAllMosSub = this.isForAllMos.valueChanges.subscribe((value) => {
            const updated = this.getUpdatedMos(this.moItems.value, value);
            const moItems = Object.keys(updated)
                .filter((k) => updated[k])
                .map(Number);
            this.workingSubscription.moItems = moItems;
            this.setNotifiableMos();
        });

        const moItemsSub = moItems$.subscribe((value) => {
            const sub = this.workingSubscription;
            const moItems = Object.keys(value)
                .filter((k) => value[k])
                .map(Number);

            if (haveDifferentValues(moItems, sub.moItems)) {
                sub.isForAllMos = false;
                this.isForAllMos.patchValue(false, {
                    emitEvent: false,
                });
            }

            sub.moItems = moItems;

            this.setNotifiableMos();

            this.moCheckboxes = this.toCheckboxItems(this.allMos, moItems);
        });

        const checkChangesSub = merge(
            title$,
            moItems$,
            emailsList$,
            emailsListReports$,
            this.isForAllMos.valueChanges,
            this.emailsListProxy.valueChanges,
            this.emailsListReportsProxy.valueChanges,
            this.isPeriodic.valueChanges,
            this.excessLevel.valueChanges
        ).subscribe(() => {
            this.changeCheck();
        });

        // react on map clicks
        const notifiableMosSub = this.store.select(selectNotifiablePostIds).subscribe((postIds) => {
            const { moItems } = this.workingSubscription;
            if (postIds.length && haveDifferentValues(moItems, postIds)) {
                Object.assign(this.workingSubscription, {
                    isForAllMos: false,
                    moItems: postIds,
                });
                this.isForAllMos.patchValue(false, {
                    emitEvent: false,
                });
                this.moItems.patchValue(this.getUpdatedCheckboxData(postIds));
            }
        });

        this.subscriptions.push(
            titleSub,
            excessLevelSub,
            emailsListSub,
            emailsListReportsSub,
            isPeriodicSub,
            isForAllMosSub,
            moItemsSub,
            checkChangesSub,
            notifiableMosSub
        );

        this.popupProvider.setTemplate(this.popupOutlet, () => {
            const changed = this.settingsForm.invalid || this.formDataHasChanged;
            if (changed) this.openPopup();
            return changed;
        });
    }

    get emailsListProxy() {
        return this.settingsForm.get('emailsListProxy') as UntypedFormGroup;
    }

    get emailsListReportsProxy() {
        return this.settingsForm.get('emailsListReportsProxy') as UntypedFormGroup;
    }

    get moItems() {
        return this.settingsForm.get('moItems') as UntypedFormGroup;
    }

    ngOnDestroy() {
        this.msgService.clearMsg();
        this.popupProvider.clear();

        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    private toCheckboxItems(ids: number[], selected: number[]): CheckboxItem[] {
        return ids.map((id) => ({
            id,
            label: this.getMoName(id),
            selected: selected.includes(id),
        }));
    }

    updateMoCheckboxes(items: CheckboxItem[]) {
        this.moItems.patchValue(
            this.getUpdatedCheckboxData(items.filter((c) => c.selected).map((c) => +c.id))
        );
    }

    private getUpdatedCheckboxData(moItems: number[], resetValue?: boolean) {
        return this.allMos.reduce(
            (acc, mo) => ({
                ...acc,
                [mo]: resetValue ?? moItems.includes(mo),
            }),
            {}
        );
    }

    private normalizeMos(subscription: NotificationSubscription) {
        const moItems = subscription.isForAllMos ? [] : subscription.moItems;
        return Object.assign(NotificationSubscription.clone(subscription), subscription, {
            moItems,
        });
    }

    private getUpdatedMos(moItems: any, isForAllMos: boolean) {
        this.workingSubscription.isForAllMos = isForAllMos;

        moItems = this.getUpdatedCheckboxData(this.workingSubscription.moItems, isForAllMos);

        this.moItems.patchValue(moItems, {
            emitEvent: false,
        });

        return moItems;
    }

    setNotifiableMos() {
        this.store.dispatch(
            updateNotifiablePosts({ postIds: this.workingSubscription.moItems.slice() })
        );
    }

    toggleMosSelection() {
        this.showMosSelection = !this.showMosSelection;
    }

    toggleMosSelectionWithinContainer(e: Event) {
        if (this.element.nativeElement.contains(e.target as HTMLElement)) {
            this.toggleMosSelection();
        }
    }

    updateList(value: string) {
        this.emailsListData = mapEmails(stringToList(value));
        updateFormControls(this.emailsListProxy as UntypedFormGroup, this.emailsListData);
    }

    updateListReports(value: string) {
        this.emailsListReportsData = mapEmails(stringToList(value));
        updateFormControls(
            this.emailsListReportsProxy as UntypedFormGroup,
            this.emailsListReportsData
        );
    }

    getMoName(id: number) {
        if (!this.mosList) {
            return;
        }
        return this.mosList.find((v) => v.id === id)?.name;
    }

    removePostFromList(index: number) {
        const moItems = this.workingSubscription.moItems.filter((_, i) => i !== index);
        this.moItems.patchValue(this.getUpdatedCheckboxData(moItems));
    }

    get title() {
        return this.settingsForm.get('title');
    }

    get emailsList() {
        return this.settingsForm.get('emailsList');
    }

    get emailsListReports() {
        return this.settingsForm.get('emailsListReports');
    }

    get isPeriodic() {
        return this.settingsForm.get('isPeriodic');
    }

    get isForAllMos() {
        return this.settingsForm.get('isForAllMos');
    }

    get filterMosText() {
        return this.settingsForm.get('filterMosText');
    }

    get excessLevel() {
        return this.settingsForm.get('excessLevel');
    }

    getValueName(value: number) {
        return this.stateService.getValueName(value);
    }

    toggleValueItem(value: number) {
        this.workingSubscription.isForAllValues = false;

        const { valueItems } = this.workingSubscription;

        const idx = valueItems.indexOf(value);
        if (idx !== -1) {
            valueItems.splice(idx, 1);
        } else {
            valueItems.push(value);
        }

        if (!valueItems.length) {
            this.selectAllValues();
        } else {
            this.changeCheck();
        }
    }

    selectAllValues() {
        this.workingSubscription.isForAllValues = true;
        this.workingSubscription.valueItems.splice(0);
        this.changeCheck();
    }

    changeCheck() {
        this.formDataHasChanged = haveDifferentValues(
            this.subscription,
            this.normalizeMos(this.workingSubscription)
        );
    }

    isValueItemSelected(value: number) {
        return this.workingSubscription.valueItems.includes(value);
    }

    updateSliderValue(value: number) {
        this.excessLevel.patchValue(value);
    }

    postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    cancel = () => {
        this.stateService.openPrevious();
    };

    save() {
        if (!this.settingsForm.disabled && !this.settingsForm.invalid && this.formDataHasChanged) {
            this.onSubmit();
        }
    }

    openPopup() {
        this.showConfirmationPopup = true;
    }

    onSubmit() {
        this.settingsForm.disable({
            emitEvent: false,
        });

        this.saving = true;

        const subscription = this.normalizeMos(this.workingSubscription);

        this.stateService
            .addOrActivateSubscription(subscription)
            .then(() => {
                if (this.popupProvider.deferred()) {
                    this.stateService.openSubscriptions();
                }
            })
            .finally(() => {
                this.saving = false;
                this.settingsForm.enable();
                this.onClosePopup();
            });
    }

    onClosePopup = () => {
        this.showConfirmationPopup = false;
    };

    onCancelAndNavigation() {
        this.onClosePopup();
        this.popupProvider.deferred();
    }

    onSavePopup = () => {
        this.save();
    };

    onCancelPopup = () => {
        this.cancel();
    };
}

import { Injectable } from '@angular/core';
import { UserItems, WindowGlobalVars } from '@cityair/namespace';
import { AdminPanelApi } from '@cityair/libs/common/api/adminPanel/api';
import { Store } from '@ngrx/store';
import { CpUserItem } from '@cityair/modules/login/services/harvester-api/adminApiModels';
import { groupInfoLoad } from '@cityair/modules/core/store/actions';
import { selectGroupId } from '@cityair/modules/core/store/selectors';

declare const window: WindowGlobalVars;

export enum Pages {
    USERS_LIST = 'users-list',
    EDIT_USER = 'edit-user',
}

@Injectable()
export class UsersService {
    private currentGroupId: number;
    currentPage: Pages;
    currentUser: UserItems = null;
    isShowDeleteUserPopup = false;
    saving = false;
    userToDelete: number;

    constructor(private adminPanelApi: AdminPanelApi, private store: Store) {
        this.store.select(selectGroupId).subscribe((groupId) => (this.currentGroupId = groupId));
    }

    openUsersList = () => {
        this.currentPage = Pages.USERS_LIST;
    };

    openEditUser = (user: UserItems) => {
        this.currentPage = Pages.EDIT_USER;
        this.currentUser = user;
    };

    openCreateUser = () => {
        this.currentUser = null;
        this.currentPage = Pages.EDIT_USER;
    };

    setDefaultState = () => {
        this.currentPage = Pages.USERS_LIST;
    };

    updateGroupInfo() {
        this.store.dispatch(groupInfoLoad({ groupId: this.currentGroupId }));
    }

    applyUserUpdates = async (
        user: UserItems,
        isEditMode: boolean,
        isUpdateRole: boolean,
        isUpdateMos: boolean
    ): Promise<boolean> => {
        try {
            if (!isEditMode && !user.userId) {
                const cpUserItem: CpUserItem = await this.adminPanelApi.createObserverUserItem(
                    user
                );

                if (cpUserItem) user.userId = cpUserItem.UserId;
                else
                    return !window.sentryCaptureException(
                        new Error('createObserverUserItem не вернул юзера ' + user.email)
                    );

                await this.adminPanelApi.sendWelcomeMail(cpUserItem);
            }

            if (!isEditMode) await this.adminPanelApi.addUser(user.userId);

            if (isUpdateRole && user.roleId)
                await this.adminPanelApi.editUser(user.userId, user.roleId);

            if (isUpdateMos)
                await this.adminPanelApi.editUsersMO(
                    user.userId,
                    user.linksToMo.map((m) => m.id)
                );

            this.updateGroupInfo();

            return true;
        } catch (e) {
            return false;
        }
    };

    selectUserToDelete = (user: UserItems) => {
        this.userToDelete = user.userId;
    };

    deleteUser = () => {
        this.saving = true;

        this.adminPanelApi
            .removeUser(this.userToDelete)
            .then(async () => {
                this.updateGroupInfo();
                this.saving = false;
            })
            .catch((error) => {
                this.saving = false;
            });
    };
}

import type { DomainConfigType } from '@cityair/modules/map/components/mapbox/domain-tiles-player/domain-config.type';
import { Substance } from '@cityair/modules/map/components/mapbox/domain-tiles-player/substance.enum';

export const DOMAINS_FORECASTS: Record<string, DomainConfigType> = {
    moscow: {
        slug: 'ru_moscow_5km',
        substances: [Substance.PM25, Substance.PM10, Substance.NO2, Substance.SO2],
        coordinates: [
            [35.482452392578125, 56.42679977416992],
            [39.207977294921875, 56.42679977416992],
            [39.207977294921875, 54.11769485473633],
            [35.482452392578125, 54.11769485473633],
        ],
    },
    spb: {
        slug: 'ru_s-petersburg_5km',
        substances: [Substance.PM25, Substance.PM10, Substance.NO2, Substance.SO2],
        coordinates: [
            [25.987335205078125, 62.16099166870117],
            [34.646026611328125, 62.16099166870117],
            [34.646026611328125, 57.58010482788086],
            [25.987335205078125, 57.58010482788086],
        ],
    },
    'yuzno-sakhalinsk': {
        slug: 'ru_yuzno-sakhalinsk_2km',
        substances: [Substance.PM25, Substance.PM10, Substance.NO2, Substance.SO2],
        coordinates: [
            [141.5856, 47.82266],
            [143.7688, 47.82266],
            [143.7688, 46.31364],
            [141.5856, 46.31364],
        ],
    },
    us_seattle: {
        slug: 'us_seattle_2km',
        substances: [Substance.PM25, Substance.PM10, Substance.NO2, Substance.SO2],
        coordinates: [
            [-123.8, 48.6],
            [-121.2, 48.6],
            [-121.2, 46.9],
            [-123.8, 46.9],
        ],
    },
    delhi: {
        slug: 'in_delhi_1km',
        substances: [Substance.PM25, Substance.PM10, Substance.NO2, Substance.SO2],
        coordinates: [
            [76.729706, 28.588726],
            [77.40201, 28.588726],
            [77.40201, 27.99532],
            [76.729706, 27.99532],
        ],
    },
    novosibirsk: {
        slug: 'ru_novosibirsk_2km',
        substances: [Substance.PM25, Substance.PM10, Substance.NO2, Substance.SO2],
        coordinates: [
            [81.52185, 55.9842],
            [84.5556, 55.9842],
            [84.5556, 54.06135],
            [81.52185, 54.06135],
        ],
    },
};

import { CommonModule } from '@angular/common';
import { MobileHeaderComponent } from './mobile-header.component';
import { NgModule } from '@angular/core';
import { AqiLegendMobileComponent } from '@cityair/components/aqi-legend-mobile/aqi-legend-mobile.component';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { SharedComponentsModule } from '@cityair/libs/shared/components/shared-components.module';
import { MobileProfileComponent } from '@cityair/components/mobile-profile/mobile-profile.component';
import { UserCardComponent } from '@cityair/components/user-card/user-card.component';
import { LoginModule } from '@cityair/modules/login/login.module';
import { GroupListItemMobileModule } from '@cityair/components/group-list-item-mobile/group-list-item-m.module';
import { FeedbackModule } from '@libs/shared-ui/components/feedback/feedback.module';
import { InstantAqiLegendMobileModule } from '../instant-aqi-legend-mobile/instant-aqi-legend-mobile.module';
import { AqiIndicatorSimpleModule } from '../aqi-indicator-simple/aqi-indicator-simple.module';
import { CapiAqiLegendMobileModule } from '../capi-aqi-legend-mobile/instant-aqi-legend-mobile.module';
import { ButtonModule } from '@libs/shared-ui/components/button/button.module';
import { ButtonsSaveCancelModule } from '@libs/shared-ui/components/buttons-save-cancel/buttons-save-cancel.module';
import { BackButtonModule } from '@libs/shared-ui/components/back-button/back-button.module';

@NgModule({
    imports: [
        CommonModule,
        CalendarModule,
        SharedComponentsModule,
        DirectivesModule,
        LoginModule,
        GroupListItemMobileModule,
        FeedbackModule,
        BackButtonModule,
        InstantAqiLegendMobileModule,
        AqiIndicatorSimpleModule,
        CapiAqiLegendMobileModule,
        ButtonModule,
        ButtonsSaveCancelModule,
    ],
    exports: [MobileHeaderComponent, MobileProfileComponent],
    declarations: [
        MobileHeaderComponent,
        AqiLegendMobileComponent,
        MobileProfileComponent,
        UserCardComponent,
    ],
})
export class MobileHeaderModule {}

import { Injectable } from '@angular/core';
import { StorageService } from '@cityair/modules/login/services/auth/storage.service';
import { GROUP_ID } from '@cityair/namespace';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { selectGroupInfo, selectGroupList } from '../store/selectors';
import { ClientConfigService } from './client-config/client-config.service';

@Injectable({ providedIn: 'root' })
export class ChangeGroupHelperService {
    constructor(
        private store: Store,
        private storageService: StorageService,
        private clientConfigService: ClientConfigService
    ) {}

    public changeGroup = async (groupId: number) => {
        const list = await firstValueFrom(this.store.select(selectGroupList));
        const groupInfo = await firstValueFrom(this.store.select(selectGroupInfo));

        const found = list.find((g) => g.id === groupId);

        if (found && found.id !== groupInfo?.groupId) {
            this.storageService.set(GROUP_ID, groupId.toString());

            const whiteLabelName = this.clientConfigService.getName();
            location.replace(location.origin + (whiteLabelName ? `/${whiteLabelName}/` : ''));
        }
    };
}

import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { UsersService } from '@cityair/modules/users/users.service';
import { UserItems } from '@cityair/namespace';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'user-record',
    templateUrl: 'user-record.component.html',
    styleUrls: ['user-record.component.less'],
})
export class UserRecord {
    @Input() objUser: UserItems;

    popupActive = false;
    popupPositionTop = '';

    TEXTS = TEXTS;

    @ViewChild('popupOpener', { static: true }) popupOpener: ElementRef<HTMLDivElement>;
    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    constructor(public popupProvider: OffPanelPopupService, public usersService: UsersService) {}

    showDeleteUserPopup = () => (this.usersService.isShowDeleteUserPopup = true);
    hideDeleteUserPopup = () => (this.usersService.isShowDeleteUserPopup = false);

    openPopup = () => {
        const { nativeElement } = this.popupOpener;
        this.popupProvider.setTemplate(this.popupOutlet);
        this.popupPositionTop = `${
            nativeElement.getBoundingClientRect().top - nativeElement.offsetTop
        }px`;
        this.popupActive = true;
    };

    closePopup(e: Event) {
        if (this.popupActive && this.popupOpener.nativeElement !== e.target) {
            this.popupActive = false;
        }
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';

import { TabModel } from '@libs/common/types/tab-model';
import { TEXTS } from '@libs/common/texts/texts';

import { NotificationsStateService, Pages } from '../../notifications-state.service';

@Component({
    selector: 'notifications',
    templateUrl: 'notifications.component.html',
})
export class Notifications implements OnInit, OnDestroy {
    pages = Pages;

    TEXTS = TEXTS;

    pageTabs: TabModel[] = [
        {
            title: TEXTS.NOTIFICATIONS.eventList,
            type: Pages.EVENT_FEED,
        },
        {
            title: TEXTS.NOTIFICATIONS.settingNotification,
            type: Pages.SUBSCRIPTIONS,
        },
    ];

    selectedTab = this.pageTabs[0];

    constructor(readonly stateService: NotificationsStateService) {}

    ngOnInit() {
        this.stateService.currentPage$.subscribe((page) => {
            this.selectedTab = this.pageTabs.find((t) => t.type === page);
        });
    }

    ngOnDestroy() {
        this.stateService.setDefaultState();
        this.stateService.resetData();
    }

    showSelectedTab(tab: TabModel) {
        this.selectedTab = this.pageTabs.find((t) => t === tab);
        switch (tab.type) {
            case Pages.EVENT_FEED:
                this.stateService.openEventFeed();
                break;
            case Pages.SUBSCRIPTIONS:
                this.stateService.openSubscriptions();
                break;
        }
    }
}

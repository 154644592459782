<svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="info-icon"
    [class.info-icon--active]="active"
>
    <circle cx="10" cy="10" r="10" class="info-icon-bg" />
    <path
        d="M10 15.0001C10.5523 15.0001 11 14.5523 11 14.0001V10.0001C11 9.44778 10.5523 9.00006 10 9.00006C9.44772 9.00006 9 9.44778 9 10.0001V14.0001C9 14.5523 9.44772 15.0001 10 15.0001Z"
        fill="white"
    />
    <path
        d="M10 7.00006C10.5523 7.00006 11 6.55235 11 6.00006C11 5.44778 10.5523 5.00006 10 5.00006C9.44772 5.00006 9 5.44778 9 6.00006C9 6.55235 9.44772 7.00006 10 7.00006Z"
        fill="white"
    />
</svg>

<div class="event_body">
    <div class="event_body__wrapper_str">
        <div
            [ngClass]="{
                event_body__status: true,
                'event_body__status-red': notification.EventType !== eventTypes.End
            }"
        >
            <ng-container *ngIf="isPdkType">
                {{ textsNotification.notificationAlertLabel.pdk[notification.EventType] }}
            </ng-container>
            <ng-container *ngIf="isServiceType">
                <ng-container
                    *ngIf="notification.ServiceExcessType === serviceExcessTypes.DeviceNoData"
                >
                    {{ textsNotification.notificationAlertLabel.nodata[notification.EventType] }}
                </ng-container>
                <ng-container
                    *ngIf="notification.ServiceExcessType === serviceExcessTypes.DeviceNoPs220"
                >
                    {{ textsNotification.notificationAlertLabel.nopower[notification.EventType] }}
                </ng-container>
            </ng-container>
        </div>
        <div class="event_body__started" *ngIf="isPdkType">
            <span
                *ngIf="
                    notification.EventType === eventTypes.Begin && !notification.UpdateTime;
                    else other
                "
            >
                {{ beginFormatted }}
            </span>
            <ng-template #other>
                <span>{{ updateFormatted }}</span>
            </ng-template>
        </div>
    </div>

    <div class="event_body__wrapper_str">
        <div
            class="event_body__mo_name"
            *ngIf="isPdkType"
            [ngClass]="{'event_body__mo_name--clickable': !disableNavigation }"
            (click)="!disableNavigation && showInTimeline(notification.PdkMoContent.MoInfo)"
        >
            {{ notification.PdkMoContent.MoInfo.Name }}
        </div>
        <ng-container *ngIf="isServiceType">
            <div
                class="event_body__mo_name"
                *ngIf="notification.ServiceContent.MoInfo; else notFound"
                [ngClass]="{'event_body__mo_name--clickable': !disableNavigation }"
                (click)="!disableNavigation && showInTimeline(notification.ServiceContent.MoInfo)"
            >
                {{ notification.ServiceContent.MoInfo.Name }}
            </div>
            <ng-template #notFound>
                <div class="event_body__mo_name event_body__mo_name--notfound">
                    {{ textsNotification.moNotFoundOrDeleted }}
                </div>
            </ng-template>
        </ng-container>
    </div>

    <div class="event_body__wrapper_str" *ngIf="isServiceType">
        <div class="event_body__mo_device">
            <div class="event_body__mo_device__type">
                {{ notification.ServiceContent.DeviceInfo.DeviceSource }}
            </div>
            <div class="event_body__mo_device__name">
                {{ notification.ServiceContent.DeviceInfo.SerialNumber }}
            </div>
        </div>
    </div>

    <div class="event_body__wrapper_str event_body__wrapper_str-content_left" *ngIf="isPdkType">
        <ca-substances-list [items]="[valueName]"></ca-substances-list>
        <div class="event_body__description">
            {{ textsNotification.statusTexts.pdk[hasEnded ? 1 : 0] }}
            {{
                hasEnded
                    ? ''
                    : [
                          notification.PdkMoContent.PdkMultiplier,
                          textsNotification.excessLevelUnits
                      ].join(' ')
            }}
        </div>
    </div>

    <div
        class="event_body__wrapper_str event_body__wrapper_str-content_left"
        *ngIf="isServiceType && hasEnded"
    >
        <div class="event_body__description" [ngSwitch]="notification.ServiceExcessType">
            <ng-container *ngSwitchCase="serviceExcessTypes.DeviceNoData">
                {{ textsNotification.statusTexts.nodata[1] }}
            </ng-container>
            <ng-container *ngSwitchCase="serviceExcessTypes.DeviceNoPs220">
                {{ textsNotification.statusTexts.nopower[1] }}
            </ng-container>
        </div>
    </div>

    <div class="event_body__qm" *ngIf="isPdkType && !included" [innerHTML]="pdkValues"></div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventBody } from './event-body.component';
import { SubstancesListModule } from '@libs/shared-ui/components/little-components/substances-list/substances-list.module';

@NgModule({
    imports: [CommonModule, SubstancesListModule],
    exports: [EventBody],
    declarations: [EventBody],
})
export class EventBodyModule {}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UserItems } from '@cityair/namespace';
import { TEXTS } from '@libs/common/texts/texts';
import { ADMIN_ID, OBSERVER_ID, OPERATOR_ID } from '@cityair/config';

@Component({
    selector: 'cityscreen-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.less'],
})
export class UserCardComponent implements OnChanges {
    @Input() user: UserItems;
    @Output() logout = new EventEmitter<void>();

    userType: string;
    TEXTS = TEXTS;

    ngOnChanges() {
        this.userType = {
            [ADMIN_ID]: 'admin',
            [OPERATOR_ID]: 'operator',
            [OBSERVER_ID]: 'observer',
        }[this.user.roleId];
    }
}

import { ErrorResult } from '@cityair/modules/login/services/harvester-api/models';
import * as Models from '@cityair/modules/login/services/harvester-api/devicesApiModels';
import { DataProvider3 } from '@cityair/libs/common/api/dataProvider/DataProvider';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DevicesApiProvider {
    cpController = 'DevicesApi2';

    constructor(private readonly dataProvider: DataProvider3) {}

    getDevices(
        request: Models.DataApiDevicesRequest,
        execOk: (response: Models.DataApiDevicesResponse) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) {
        this.dataProvider.execHttp(this.cpController, 'GetDevices', request, execOk, execFail);
    }

    exportDataToBase64 = (
        request: Models.ExportDeviceDataRequest,
        execOk: (base64: string) => void,
        execFail: (errRes: ErrorResult) => void
    ) => {
        this.dataProvider.execHttp(
            this.cpController,
            'ExportDataToBase64',
            request,
            execOk,
            execFail
        );
    };
}

<cs-analytic-chart-title
    [title]="TEXTS.CITY_CARD.charts[0]"
    [tooltip1]="tooltip"
    [tooltip2]="tooltipDescription"
></cs-analytic-chart-title>

<div class="doughnut__wrapper">
    <div class="doughnut-chart">
        <canvas
            baseChart
            type="doughnut"
            [datasets]="datasets"
            [labels]="labels"
            [options]="options"
        ></canvas>
    </div>

    <div class="doughnut__items_wrapper" [ngClass]="{'aqi-in': isDemo }">
        <div *ngFor="let val of data; index as i">
            <div *ngIf="val" class="doughnut__item">
                <span class="doughnut__circle" [style]="{ background: backgroundColor[i] }"></span>
                <span class="doughnut__point" [ngClass]="{'aqi-in': isDemo }">{{ labels[i] }}</span>
                <span class="doughnut__percent">{{ val }}%</span>
            </div>
        </div>
    </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { formatDayMonthYear } from '@cityair/config';
import { CalendarOutput } from '@cityair/namespace';
import * as moment from 'moment';
import { getIntervalText } from './get-interval-text.function';

@Pipe({
    name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
    transform(time: CalendarOutput, tzOffset: number): string {
        return getIntervalText(time, (time) =>
            formatDayMonthYear(moment(time).utcOffset(tzOffset))
        );
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Subscription, take } from 'rxjs';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { TEXTS } from '@libs/common/texts/texts';
import { MapAdapterService } from '@cityair/modules/map/services/map-adapter.service';

import { IntervalEnum } from '@cityair/namespace';
import {
    selectGlobalMeasurement,
    selectGroupInfo,
    selectIsCityMode,
    isCompareMode,
    selectIsSidebarOpen,
    selectMeasureScheme,
    selectPdkForChart,
    selectSourcesAsFeatures,
    selectTime,
    selectTimelineDateTimes,
} from '@cityair/modules/core/store/selectors';
import {
    currentTimeUpdate,
    destroyMainMap,
    initMainMap,
    loadCity,
} from '@cityair/modules/core/store/actions';
import { getDigitsAfterDot } from '@cityair/config';
import { aqiDataProviderFactory } from '@cityair/modules/core/utils';
import { getModulePageConfig } from '@cityair/modules/core/store/module-page-config/module-page-config.feature';

@Component({
    selector: 'default-map-mobile',
    templateUrl: './default-map-mobile.component.html',
    styleUrls: ['./default-map-mobile.component.less'],
})
export class DefaultMapMobileComponent implements OnInit, OnDestroy {
    selectMeasureScheme = selectMeasureScheme;
    IntervalEnum = IntervalEnum;
    selectIsSidebarOpen = selectIsSidebarOpen;
    selectSourcesAsFeatures = selectSourcesAsFeatures;
    selectTimelineDateTimes = selectTimelineDateTimes;
    selectIsComparisonModeActive = isCompareMode;
    selectPdkForChart = selectPdkForChart;
    currentTimeUpdate = currentTimeUpdate;
    selectTime = selectTime;
    selectGlobalMeasurement = selectGlobalMeasurement;
    selectIsCityMode = selectIsCityMode;

    aqiDataProvider = aqiDataProviderFactory(this.store);

    moduleConfig$ = this.store.select(getModulePageConfig);

    private subscriptions: Subscription[] = [];

    TEXTS = TEXTS;
    isCompare = false;

    constructor(public store: Store, private mapAdapterService: MapAdapterService) {}

    ngOnInit() {
        this.store.dispatch(initMainMap());

        this.store
            .select(selectGroupInfo)
            .pipe(
                filter((g) => !!g?.groupId),
                take(1)
            )
            .subscribe(async (g) => {
                this.mapAdapterService.setDefaultMap();
            });
    }

    ngOnDestroy() {
        this.store.dispatch(destroyMainMap());

        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    getDigits = (measureScheme: MeasureScheme) => (mmt) => getDigitsAfterDot(measureScheme, mmt);

    public goToCity(cityId: string) {
        this.store.dispatch(loadCity({ cityId, centringMap: true }));
    }
}

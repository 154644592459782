<div class="wrap-download" *ngIf="currentKind && !loading">
    <div class="context">
        <div class="item">
            <div class="title">
                {{ isRu ? currentKind.name : currentKind.name_eng }}
            </div>
        </div>
    </div>
    <div class="form-wrapper">
        <div class="name-wrapper post-report__input-date-range">
            <ca-input
                type="text"
                controlName="name"
                [form]="ReportDownLoadForm"
                [valueForm]="formName.value"
                [label]="TEXTS.REPORTS.formCreate.name"
                [textError]=""
            ></ca-input>
        </div>
        <report-date-range
            class="post-report__input-date-range"
            [timeBegin]="objectForReportMo.timeBegin"
            [timeEnd]="objectForReportMo.timeEnd"
            [displayMonth]="1"
            [labelText]="TEXTS.REPORTS.formCreate.calendar"
            [offsetTz]="true"
            (timeChange)="changeCalendar($event)"
        ></report-date-range>
        <input-dropdown
            class="post-report__input-dropdown"
            [inputText]="selectedCheckboxText(getTags().length)"
            [label]="TEXTS.POSTS_AND_DEVICES.postsMonitoring"
            [(showDropdown)]="isShowDropdownForStations"
            (clickOutside)="isShowDropdownForStations = false"
            [textError]="getTags().length === 0 ? TEXTS.REPORTS.formCreate.postMoError : '' "
        >
            <cs-selectbox
                [listItems]="listStations"
                (listItemsChange)="listStations = $event"
                [searchPlaceholderText]="TEXTS.NOTIFICATIONS.searchMoPlaceholderText"
                [selectAllText]="TEXTS.NOTIFICATIONS.selectAll"
            ></cs-selectbox>
        </input-dropdown>

        <tags-list
            class="post-report__tags-list"
            [tags]="getTags()"
            [textNodesFns]="postsListLabels()"
            [truncateTo]="4"
            [editable]="true"
            (removeTag)="removeFromList($event)"
        ></tags-list>

        <div class="post-report__title">{{ TEXTS.POSTS_AND_DEVICES.intervalData }}</div>
        <switch-item
            class="post-report__switch-item"
            [currentTab]="currentFilterTabsInterval"
            [tabs]="filterTabsInterval"
            (action)="setTabsInterval($event)"
        ></switch-item>
        <div class="post-report__title">{{ TEXTS.POSTS_AND_DEVICES.units }}</div>
        <div class="post-report__end-block">
            <switch-item
                [currentTab]="currentUnitsMeasurements"
                [tabs]="unitsMeasurementsList"
                (action)="getUnitMeasurement($event)"
            ></switch-item>
            <div
                *ngIf="isRu"
                class="post-report__label"
                [class.post-report__label--disabled]="isDisabledPdk"
                (click)="toggleInsertPdk()"
            >
                <cs-checkbox
                    [checked]="objectForReportMo.insertPdk"
                    [disabled]="isDisabledPdk"
                ></cs-checkbox>
                <span class="post-report__label-text">
                    {{ TEXTS.POSTS_AND_DEVICES.pightsOfPDK }}
                </span>
            </div>
        </div>
        <div class="button-wrapper">
            <btn-cancel class="cancel-button" (click)="close()">
                {{ TEXTS.COMMON.cancel }}
            </btn-cancel>
            <btn-accept class="submit-button"
                [disabled]="getTags().length === 0 || isActiveLoader"
                [isLoading]="isActiveLoader"
                (cbClick)="downloadReportMo()"
            >
                {{ TEXTS.DOWNLOAD_POPUP.download }}
            </btn-accept>
        </div>
    </div>
</div>
<div class="loading-wrapper" *ngIf="loading">
     <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
</div>

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { filter, first, map, switchMap, take } from 'rxjs/operators';
import { LngLatLike } from 'mapbox-gl';

import { getDigitsAfterDot, GLOBAL_ZOOM_LEVEL } from '@cityair/config';
import {
    IntervalEnum,
    MapCenterAndZoom,
    MapControlPins,
    MapPins,
    Marker_model,
} from '@cityair/namespace';
import {
    canClickOnMarker,
    selectCities,
    selectCitiesMarker,
    selectIsCityMode,
    selectLocationValue,
    selectMarkers,
    selectMarkersWithoutLocation,
    selectPostValue,
    selectSchemeAndMeasure,
    selectTimeRangeWithoutDistinct,
    selectZone,
} from '@cityair/modules/core/store/selectors';
import {
    GroupExtConfigName,
    GroupFeaturesService,
} from '@cityair/modules/core/services/group-features/group-features.service';
import { findClosestCity } from '../components/mapbox/mapAPI';
import {
    cityModeOff,
    clickOnCityMarker,
    loadCity,
    mapMarkerClick,
    setTypeInterval,
} from '@cityair/modules/core/store/actions';
import { getColorFromZone, isTimelineAqiLowDetail } from '@cityair/utils/utils';
import { comparedItemsIds } from '@cityair/modules/core/store/compared-list/compared-list.selectors';
import { selectCurrentCity } from '@cityair/modules/core/store/current-city/current-city.feature';
import MapboxActions from '../components/mapbox/mapboxActions';

class MapProperies {
    zoom?: number = null;
    center?: LngLatLike = null;

    postPins?: MapPins = null;
    cityPins?: MapPins = null;
    notificationsSelectedPosts?: MapPins = null;
    controlPointPins?: MapControlPins = null;

    groupFeaturesLayer?: GeoJSON.FeatureCollection<GeoJSON.Geometry> = null;
    onMapDragEnd?: (props: MapCenterAndZoom) => void = null;
    onMapZoomChanged?: (zoom: number) => void = null;
}

@Injectable({
    providedIn: 'root',
})
export class MapAdapterService {
    private _empty$ = new BehaviorSubject([] as Marker_model[]).asObservable();
    private _markers$: Observable<Marker_model[]>;
    private _cityMarkers$: Observable<Marker_model[]>;

    public zoom: number = null;
    public center: LngLatLike = null;

    public notificationsSelectedPosts: MapPins = null;
    public postPins: MapPins = null;
    public cityPins: MapPins = null;
    public controlPointPins: MapControlPins = null;

    public groupFeaturesLayer: GeoJSON.FeatureCollection<GeoJSON.Geometry> = null;
    public pinsAreaData: Observable<GeoJSON.FeatureCollection<GeoJSON.LineString>> = null;

    public onMapDragEnd: (props: MapCenterAndZoom) => void = null;
    public onMapZoomChanged: (zoom: number) => void = null;

    constructor(
        private store: Store,
        private groupFeaturesService: GroupFeaturesService,
        private mapActions: MapboxActions
    ) {
        this._markers$ = this.store
            .select(selectIsCityMode)
            .pipe(
                switchMap((isCityMode) =>
                    this.store.select(isCityMode ? selectMarkers : selectMarkersWithoutLocation)
                )
            );

        this._cityMarkers$ = this.store
            .select(selectIsCityMode)
            .pipe(
                switchMap((isCityMode) =>
                    isCityMode ? this._empty$ : this.store.select(selectCitiesMarker)
                )
            );
    }

    set(data: MapProperies) {
        Object.assign(this, new MapProperies(), data);
    }

    public getDefaultCityPins(): MapPins {
        const cityPins: MapPins = {
            getPins: null,
            selectDigitsAfterDot: this.store
                .select(selectSchemeAndMeasure)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: this.store.select(comparedItemsIds),
            getValue: (pin) => this.store.select(selectLocationValue(pin.id)),
            getColor: (pin) =>
                combineLatest([
                    this.store.select(selectZone),
                    this.store.select(selectLocationValue(pin.id)),
                ]).pipe(map(([zone, value]) => getColorFromZone(zone, value))),

            clickCb: async (pin) => {
                const canClickMarker = await firstValueFrom(
                    this.store.select(canClickOnMarker(pin.id))
                );

                if (canClickMarker) {
                    this.store.dispatch(clickOnCityMarker({ cityMarker: pin as Marker_model }));

                    const { begin, end } = await lastValueFrom(
                        this.store.select(selectTimeRangeWithoutDistinct).pipe(take(1))
                    );

                    this.store.dispatch(
                        setTypeInterval({
                            payload: isTimelineAqiLowDetail(begin, end)
                                ? IntervalEnum.day
                                : IntervalEnum.hour,
                        })
                    );
                }
            },
        };

        Object.defineProperty(cityPins, 'getPins', {
            get: () => this._cityMarkers$,
        });

        return cityPins;
    }

    public getDefaultPostPins(): MapPins {
        const postPins: MapPins = {
            getPins: null,
            selectDigitsAfterDot: this.store
                .select(selectSchemeAndMeasure)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: this.store.select(comparedItemsIds),
            getValue: (pin) => this.store.select(selectPostValue(pin.id)),
            getColor: (pin) =>
                combineLatest([
                    this.store.select(selectZone),
                    this.store.select(selectPostValue(pin.id)),
                ]).pipe(map(([zone, value]) => getColorFromZone(zone, value))),

            clickCb: async (pin) =>
                (await firstValueFrom(this.store.select(canClickOnMarker(pin.id)))) &&
                this.store.dispatch(mapMarkerClick({ markerId: pin.id })),
        };

        Object.defineProperty(postPins, 'getPins', {
            get: () => this._markers$,
        });

        return postPins;
    }

    public async getDefaultGroupFeaturesLayer() {
        await lastValueFrom(
            this.groupFeaturesService.readyBehavior$.pipe(
                filter((v) => v),
                take(1)
            )
        );
        return this.groupFeaturesService.getConfig(GroupExtConfigName.featuresLayer);
    }

    private defaultMapDragEnd = async (props: MapCenterAndZoom) => {
        if (props.isCityMode || props.zoom >= GLOBAL_ZOOM_LEVEL - 1) {
            const locations = await firstValueFrom(this.store.select(selectCities));

            const city = findClosestCity(locations, props.center);
            const currentCity = await firstValueFrom(this.store.select(selectCurrentCity));

            if (city && currentCity && city.id !== currentCity.id) {
                this.store.dispatch(loadCity({ cityId: city.id, centringMap: false }));
            }
        }
    };

    public defaultMapZoomChanged = (zoom: number) => this.zoomChanges(zoom);

    public zoomChanges = (zoom: number) => {
        if (this.mapActions.preventZooming) {
            return;
        }

        this.mapActions.currentZoom = zoom;

        this.store
            .select(selectIsCityMode)
            .pipe(first())
            .subscribe(async (isCityMode) => {
                if (zoom < GLOBAL_ZOOM_LEVEL - 1 && isCityMode) {
                    this.store.dispatch(cityModeOff());
                }

                if (!isCityMode && zoom >= GLOBAL_ZOOM_LEVEL - 1) {
                    this.loadNearestLocation();
                }
            });
    };

    private async loadNearestLocation() {
        const locations = await firstValueFrom(this.store.select(selectCities));

        const city = findClosestCity(locations, this.mapActions.getCenter());

        if (city) {
            this.stopLoadingOnZoom();
            this.store.dispatch(loadCity({ cityId: city.id, centringMap: false }));
        }
    }

    private stopLoadingOnZoom = () => {
        this.mapActions.preventZooming = true;

        setTimeout(() => {
            this.store
                .select(selectIsCityMode)
                .pipe(first())
                .subscribe((isCityMode) => {
                    if (isCityMode) {
                        this.mapActions.preventZooming = false;
                    } else {
                        this.stopLoadingOnZoom();
                    }
                });
        }, 1000);
    };

    public async setDefaultMap() {
        this.set({
            cityPins: this.getDefaultCityPins(),
            postPins: this.getDefaultPostPins(),
            groupFeaturesLayer: await this.getDefaultGroupFeaturesLayer(),
            onMapDragEnd: this.defaultMapDragEnd,
            onMapZoomChanged: this.defaultMapZoomChanged,
        });
    }
}

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import * as moment from 'moment';

import { TEXTS } from '@libs/common/texts/texts';
import { Feature } from '../models/core';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    public unitsMeasures = TEXTS.MEASURES;

    constructor(@Inject(LOCALE_ID) public locale: string) {}

    public translate(obj: Feature): string {
        const langPropName = this.getCityNameProp();
        return obj.properties[langPropName];
    }

    public getCurrentFeatureName(obj: Feature) {
        if (!obj) {
            return '';
        }
        return this.translate(obj);
    }

    public getCurrentFeatureType(obj: Feature) {
        if (!obj) {
            return '';
        }

        if (
            obj.properties.source_type &&
            TEXTS.STATION_BLOCK.sourceTypeName.hasOwnProperty(obj.properties.source_type)
        ) {
            return TEXTS.STATION_BLOCK.sourceTypeName[obj.properties.source_type];
        } else {
            return TEXTS.STATION_BLOCK.type[obj.properties.obj] || '';
        }
    }

    getCityNameProp() {
        if (this.locale == 'en') {
            return 'name';
        } else {
            return 'name_' + this.locale;
        }
    }

    getWeekShort() {
        const result: string[] = [];

        for (let i = 1; i <= 7; i++) {
            const value = moment.weekdaysMin(i);
            if (this.locale === 'ru') {
                result.push(value.charAt(0).toUpperCase() + value.slice(1));
            } else {
                result.push(value);
            }
        }

        return result;
    }
}

import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {
    DataMarker,
    DataQuality,
    IDataMarkerResponse,
    IDataQualityTimelineResponse,
    IQualityDataParams,
} from '@libs/common/models/dataQuality';

const MARKERS_END_POINTS = 'DataQuality/markers';
const TIMELINE_END_POINTS = 'timeline';
const CITYAIR_API_BASIC_URL = '/api/basic/v1';
const API_URL = `${environment.api_url}${CITYAIR_API_BASIC_URL}`;

@Injectable({
    providedIn: 'root',
})
export class DataQualityApi {
    private accessToken = '';
    constructor(private http: HttpClient) {}

    getQualityDataMarkers(): Observable<DataMarker[]> {
        const token = this.getAccessToken();
        const httpOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `${API_URL}/${MARKERS_END_POINTS}`;
        return this.http
            .get(url, httpOptions)
            .pipe(
                map((response: IDataMarkerResponse[]) =>
                    response.map((marker) => new DataMarker(marker))
                )
            );
    }

    getQualityDataTimeline(params: IQualityDataParams): Observable<DataQuality[]> {
        const token = this.getAccessToken();
        const httpOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: params,
        };
        const url = `${API_URL}/Post/${params.post_id}/${TIMELINE_END_POINTS}/markers`;
        return this.http.get(url, httpOptions).pipe(
            map((response: IDataQualityTimelineResponse) => {
                if (
                    response?.data &&
                    response.data.length &&
                    response?.data[0].data?.data_quality
                ) {
                    return response?.data[0].data.data_quality.map((data) =>
                        data === null ? null : new DataQuality(data, params.interval)
                    );
                }
                return [];
            })
        );
    }

    private getAccessToken() {
        return this.accessToken || (this.accessToken = localStorage.getItem('token'));
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

import { TimeTrackView } from '../models/core';
import { TIMELINE_TRACK_COLOR, TIMELINE_TRACK_QUALITY_DATA_COLOR } from '../constants';
import { TEXTS } from '@libs/common/texts/texts';
import { DataQualityTimeline } from '@libs/common/models/dataQuality';

@Component({
    selector: 'ca-track-panel',
    templateUrl: './track-panel.component.html',
    styleUrls: ['./track-panel.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackPanelComponent implements OnChanges, OnInit {
    @Input() date: TimeTrackView[];
    @Input() AQI: number[];
    @Input() index: number;
    @Input() hasDataByIndex: boolean[] = [];
    @Input() chartEnabled: boolean;
    @Input() showNow: boolean;
    @Input() qualityDataMode: number;
    @Input() dataQuality: DataQualityTimeline[] = [];
    @Input() sidebarIsOpened: boolean;
    @Output() setPosition = new EventEmitter<number>();
    @Output() showQualityDataInfo = new EventEmitter<number>();

    @ViewChild('trackPanel', { read: ElementRef }) trackPanel: ElementRef<HTMLElement>;

    public backColors = TIMELINE_TRACK_COLOR;
    public qualityBackground = TIMELINE_TRACK_QUALITY_DATA_COLOR;
    public nowText = TEXTS.COMMON.now;
    public textsQuality = TEXTS.QUALITY_DATA;
    private textsMmtNames = TEXTS.NAMES;
    public innerWidth: any;
    public revertIndexTooltipPosition: number;
    ngOnInit() {
        this.innerWidth = window.innerWidth;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        setTimeout(() => this.getRevertTooltipPositionIndex(), 100);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (this.qualityDataMode && changes.sidebarIsOpened?.currentValue) ||
            changes.date?.currentValue
        ) {
            setTimeout(() => this.getRevertTooltipPositionIndex(), 100);
        }
    }

    public setIndex(i: number): void {
        this.setPosition.emit(i);
    }

    public openDetailsQuality(index) {
        this.showQualityDataInfo.emit(index);
    }

    public getBackground(i) {
        if (this.hasDataByIndex[i]) {
            if (!this.qualityDataMode) {
                return this.AQI[i] ? this.backColors[this.AQI[i]] : this.backColors[1];
            } else if (this.qualityDataMode && this.chartEnabled && this.qualityBackground) {
                return this.dataQuality[i] ? this.qualityBackground : this.backColors[1];
            } else {
                return this.backColors[1];
            }
        }
    }

    public getTooltipText(i) {
        if (this.hasDataByIndex[i] && this.dataQuality[i]) {
            const header = this.textsQuality.tooltipHeader.warning;
            const currentText = [];
            this.dataQuality[i].tooltipData?.forEach((data) => {
                if (this.textsQuality.tooltip[data.key]) {
                    const mmtValues = data.values.map((v) => this.textsMmtNames[v] ?? v);
                    const value = mmtValues.join(', ');
                    currentText.push(this.textsQuality.tooltip[data.key](value));
                }
            });
            const text = currentText.join('<br/>');
            return {
                text: `<div class="header"><b>${header}</b></div><br/>${text}`,
                link: true,
            };
        } else {
            return {
                text: this.textsQuality.tooltip.noData,
                link: false,
            };
        }
    }

    private getRevertTooltipPositionIndex() {
        const { width } = this.trackPanel?.nativeElement.getBoundingClientRect();
        const availableWidth = this.sidebarIsOpened ? innerWidth - 800 : innerWidth - 300;
        if (availableWidth > 0 && width) {
            this.revertIndexTooltipPosition = Math.round(
                (availableWidth * this.date.length) / width
            );
        }
    }
}

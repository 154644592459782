import { Component, Input, OnInit } from '@angular/core';
import { ActionHistory } from '@cityair/modules/posts-and-devices/posts-and-devices.collection';
import { PostsAndDevicesFacade } from '@cityair/modules/posts-and-devices/posts-and-devices.facade';
import { ActivatedRoute } from '@angular/router';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { TEXTS } from '@libs/common/texts/texts';
import { NETWORK_PAGES } from '@cityair/modules/posts-and-devices/namespace';
import { AdminDevice, CITYAIR_STND_DEVICE } from '@cityair/namespace';
import { selectDevice } from '@cityair/modules/core/store/actions';
import { Store } from '@ngrx/store';

@Component({
    selector: 'device-details',
    templateUrl: './device-details.component.html',
    styleUrls: ['./device-details.component.less'],
})
export class DeviceDetailsComponent implements OnInit {
    @Input() device: AdminDevice;

    CITYAIR_STND_DEVICE = CITYAIR_STND_DEVICE;

    isShowActionHistory = false;
    actionHistory: ActionHistory[] = null;
    TEXTS = TEXTS;
    pages = NETWORK_PAGES;
    path = '';

    constructor(
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        public activatedRoute: ActivatedRoute,
        public store: Store<any>,
        public popupProvider: OffPanelPopupService
    ) {}

    ngOnInit() {
        this.path =
            this.pages.networks +
            '/' +
            this.activatedRoute.snapshot.url[0] +
            '/' +
            this.activatedRoute.snapshot.url[1];
        const serialNumber = this.device.serialNumber;
        this.store.dispatch(selectDevice({ serialNumber }));
    }

    openPage(pageName: string) {
        this.postsAndDevicesFacade.openPage(pageName);
    }
    getTextOfPower() {
        return this.device.v220
            ? this.TEXTS.NOTIFICATIONS.alertTag.onNetworks
            : this.device.battery
            ? this.TEXTS.NOTIFICATIONS.alertTag.onBattery
            : this.TEXTS.NOTIFICATIONS.alertTag.thereIsNoData;
    }

    getTypeOfPower() {
        return this.device.v220 ? 'green' : this.device.battery ? 'yellow' : 'gray';
    }
}

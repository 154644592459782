import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { NgLocalization } from '@angular/common';

import { TEXTS } from '@libs/common/texts/texts';
import { NotificationSubscription } from '@cityair/libs/common/api/adminPanel/dataTransformer';
import { NotificationsStateService } from '../../notifications-state.service';
import { showMoreText } from '../../notifications.utils';
import { MAX_INLINE_MOS } from '@cityair/modules/notifications/notifications.settings';
import { MonitoringObject } from '@cityair/namespace';
import { Store } from '@ngrx/store';
import { selectMos } from '@cityair/modules/core/store/selectors';
import { postsListLabels } from '../../notifications.utils';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'subscription-card-service',
    templateUrl: './subscription-card-service.component.html',
    styleUrls: ['subscription-card-service.component.less'],
})
export class SubscriptionCardService implements OnInit, OnDestroy {
    @Input() subscription: NotificationSubscription;
    textsNotification = TEXTS.NOTIFICATIONS;
    saving = false;
    currentMos: string[];
    allMos: number[];
    maxInlineMos = MAX_INLINE_MOS;
    monitoringObjects: MonitoringObject[];
    subscriptions: Subscription[] = [];
    stubTags = [this.postsListLabels().all()];
    constructor(
        public stateService: NotificationsStateService,
        private ngLocalization: NgLocalization,
        private store: Store
    ) {
        const monitoringObjectsSub = store
            .select(selectMos)
            .pipe(filter((v) => !!v.length))
            .subscribe((mos) => {
                this.monitoringObjects = mos;
                this.allMos = mos.map((v) => v.id);
            });

        this.subscriptions.push(monitoringObjectsSub);
    }

    ngOnInit() {
        this.currentMos = this.initialMosList()
            .map((mo) => this.getMoName(mo))
            .filter((v) => v);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    showMoreText = showMoreText.bind(null, this.ngLocalization);

    initialMosList() {
        return this.subscription.serviceIsForAllMos
            ? this.allMos
            : this.subscription.serviceMoItems;
    }

    getMoName(id: number) {
        return this.monitoringObjects.find((v) => v.id === id)?.name;
    }

    getEmails() {
        return this.subscription.emailsList.join(', ');
    }

    editSubscription() {
        this.stateService.openSettings(this.subscription);
    }

    subscribe() {
        this.stateService.addSubscription(this.subscription).finally(() => {
            this.saving = false;
        });
    }

    postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }
}

<plumes-map-elements></plumes-map-elements>
<div class="timeline-temp-wrapper">
    <div class="timeline__wrapper timeline__wrapper-displayFlex">
        <div
            class="timeline__top"
            [class.timeline-panel]="store.select(selectIsSidebarOpen) | async"
        >
            <cs-switch-button *ngIf="store.select(isWindLayerAvailable) | async"
                    class="switch-button"
                    [text]="textWindLayer"
                    [active]="showWindOnMap"
                    [disable]="store.select(selectIsDisableWindButton) | async"
                    (activeChange)="toggleWindLayer()"
                    caTooltip
                    caTooltipPlacement="top-left"
                    [caTooltipAllowHover]="true"
                    [caTooltipTemplate]="tooltipWind"
                >
            </cs-switch-button>

            <color-legend
                class="legend-timeline"
                *ngIf="store.select(selectPlumesSchemaZones) | async as val"
                [unit]="textScheme[val.scheme][val.mmt]"
                [measure]="textNames[val.mmt]"
                [colors]="(store.select(selectPlumesZone) | async)?.color"
                [values]="(store.select(selectPlumesZone) | async)?.zone"
                [digitsAfterDot]="getDigitsAfterDot(val.scheme, val.mmt)"
            ></color-legend>
            <timeline-panel
                [aqiDataProvider]="aqiDataProvider"
                [isCityMode]="store.select(selectIsCityMode) | async"
                [sidebarIsOpened]="store.select(selectIsSidebarOpen) | async"
                [currentTime]="store.select(selectPlumeTime) | async"
                [dates]="store.select(selectDatesPlumes) | async"
                [features]="store.select(selectChartData) | async"
                [initSelectMeasurement]="store.select(currentPlumesMmt) | async"
                [labelMode]="labelMode"
                [getDigitsAfterDot]="getDigits(store.select(selectMeasureScheme) | async)"
                [showNow]="true"
                [pdk]="store.select(selectPdkForChart) | async"
                [aqiTooltipTemplate]="aqiTooltipTemplate"
                [chartMinMax]="chartMinMax"
                [measureScheme]="store.select(selectMeasureScheme) | async"
                (goToCity)="goToCity($event)"
                (removeFromComparison)="store.dispatch(removeFromComparison({ uuid: $event }))"
                (changeTimeIndex)="store.dispatch(updateTimeIndex({ payload: $event }))"
                (setPlayingState)="getPlayingState($event)"
            >
            </timeline-panel>
            <ng-template #aqiTooltipTemplate>
                <cs-aqi-details-tooltip
                    *ngIf="
                        isRU && (store.select(selectGlobalMeasurement) | async) === AqiType.instant
                    "
                    (openDetailsLink)="openAqiFaqLink(AqiType.instant)"
                ></cs-aqi-details-tooltip>
            </ng-template>
        </div>
    </div>
    <ng-template #tooltipWind>
            <ng-container
                *ngIf="isPlayerTimeline else windTooltipTemplate"
            >
               <ca-tooltip class="tooltip-wind">
                   <div  class="text-tooltip" [innerHtml]="windTooltipTexts.byPlayer"></div>
               </ca-tooltip>

            </ng-container>
            <ng-template #windTooltipTemplate>
                <ng-container
                    *ngIf="store.select(selectPlumesHeights) | async as heights"
                >
                    <ng-container *ngIf="heights.length > 1">
                        <ca-tooltip class="tooltip-wind">
                           <div class="text-tooltip" [innerHtml]="windTooltipTexts.byHeight"></div>
                       </ca-tooltip>
                    </ng-container>
                </ng-container>
            </ng-template>
    </ng-template>
</div>

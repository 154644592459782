<div class="color-legend">
    <div class="color-legend__measure" [innerHTML]="measure"></div>
    <div
        class="color-legend__grades"
        [ngStyle]="{
            background: getGradient()
        }"
    >
        <span
            class="color-legend__grade"
            [ngStyle]="{
                'min-width': !isLimit ? minWidth + 'px' : '8px'
            }"
        >
            {{ min }}
        </span>
        <span
            class="color-legend__grade"
            [ngStyle]="{
                'min-width': !isLimit ? minWidth + 'px' : 'none'
            }"
            *ngFor="let value of values"
        >
            {{ getDisplayedValue(value) }}
        </span>
        <span
            *ngIf="!isLimit"
            class="color-legend__grade"
            [ngStyle]="{
                'min-width': minWidth + 'px'
            }"
        ></span>
    </div>
    <div class="color-legend__unit" [innerHTML]="unit"></div>
</div>

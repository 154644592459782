<div class="posts-list-view">
    <div class="posts-list-view__search-line">
        <search-input-embedded
            [textPlaceholder]="TEXTS.POSTS_AND_DEVICES.searchPost"
            [(text)]="searchQuery"
            [debounceTime]="400"
        ></search-input-embedded>
    </div>

    <div [@inOutAnimation] class="posts-list-view__main_block scroll">
        <button-add
            class="posts-list-view__button-add"
            *ngIf="
                (store.select(selectGroupInfo) | async)?.myRole?.viewDeviceData &&
                (store.select(selectMyDevices) | async)?.length > 0
            "
            [text]="TEXTS.POSTS_AND_DEVICES.addPost"
            (action)="openPage($event, path + pages.postCreate)"
        ></button-add>

        <div class="posts-list-view__organizing-buttons">
            <shared-sort-list-item
                class="posts-list-view__organizing-buttons--item"
                [sortingDirection]="postsAndDevicesFacade.sortingDirection.sortPostName"
                [text]="TEXTS.POSTS_AND_DEVICES.post"
                (click)="
                    postsAndDevicesFacade.makePostSorting(
                        postsAndDevicesFacade.sortPostName,
                        'sortPostName'
                    )
                "
            ></shared-sort-list-item>
            <switch-v2
                class="posts-list-view__organizing-buttons--item"
                *ngIf="(store.select(selectGroupInfo) | async)?.myRole?.viewDeviceData"
                [text]="TEXTS.CITY_CARD.dataSources"
                [active]="postsAndDevicesFacade.isSwitchDataSources"
                (activeChange)="postsAndDevicesFacade.showDataSources($event)"
            ></switch-v2>
            <shared-sort-list-item
                class="posts-list-view__organizing-buttons--item posts-list-view__organizing-buttons--item-aqi"
                caTooltip
                caTooltipPlacement="top"
                [caTooltipAllowHover]="true"
                [caTooltipTemplate]="aqiTooltip"
                [sortingDirection]="postsAndDevicesFacade.sortingDirection.sortPostAqi"
                [text]="globalMmtName"
                (click)="
                    postsAndDevicesFacade.makePostSorting(
                        postsAndDevicesFacade.sortPostAqi,
                        'sortPostAqi'
                    )
                "
            ></shared-sort-list-item>

            <ng-template #aqiTooltip>
                <cs-aqi-details-tooltip
                    *ngIf="globalMmt === AqiType.instant && isRU"
                    (openDetailsLink)="openAqiFaqLink(AqiType.instant)"
                ></cs-aqi-details-tooltip>
            </ng-template>
        </div>

        <ng-container
            *ngIf="
                cardMo
                    | multipleSearchfilter
                        : ['name', 'city', 'serialNumber']
                        : searchQuery as result
            "
        >
            <div
                *ngFor="
                    let cm of result
                        | asyncSorting
                            : postsAndDevicesFacade.sortingPost
                            : postsAndDevicesFacade.sortingPostDirectionMain
                        | async
                "
            >
                <monitoring-object-plate
                    #MOCard
                    class="posts-list-view__monitoring-object-plate"
                    [idMo]="cm.id"
                    [name]="cm.name"
                    [city]="cm.city"
                    [value]="store.select(selectPostValue(cm.id)) | async"
                    [valueColor]="
                        getColorFromZone(
                            store.select(selectZone) | async,
                            store.select(selectPostValue(cm.id)) | async
                        )
                    "
                    [isActive]="!!(getComparedListObject(cm.id) | async)"
                    [markerState]="getMarkerState(cm.id) | async"
                    [isComplexMode]="postsAndDevicesFacade.isSwitchDataSources"
                    [arrDeviceName]="cm.lastConnectedDevice ? [cm.lastConnectedDevice] : []"
                    (openActionsPopup)="clickMenu($event, cm.id)"
                    (cbClickForStaticTagsDevice)="showDetails($event)"
                    (cbClickForCard)="clickToMarker(cm)"
                    [isShowKebabMenu]="
                        (store.select(selectGroupInfo) | async)?.myRole?.viewDeviceData
                    "
                    [isNotConnectedDevices]="cm.isConnectedDevices"
                    [isNoDataSources]="cm.isNoDataSources"
                ></monitoring-object-plate>
            </div>
            <search-result-placeholder
                class="screen-placeholders"
                *ngIf="!isShowNotDate && isShowResultPlaceholder && result.length === 0"
            ></search-result-placeholder>

            <non-data-screen
                class="screen-placeholders"
                *ngIf="isShowNotDate"
                [text]="TEXTS.POSTS_AND_DEVICES.noMonitoringPosts"
            ></non-data-screen>
        </ng-container>
    </div>

    <button-download-data
        class="posts-list-view__button-download-data"
        *ngIf="!isShowNotDate"
        [text]="TEXTS.POSTS_AND_DEVICES.downloadDataFromPosts"
        (click)="openPage($event, path + pages.postReport + '/all')"
    ></button-download-data>
</div>

<ng-template #cardMenu>
    <popup-ask
        class="posts-list-view__popup-ask"
        *ngIf="isShowDeletePopup"
        [@inOutAnimation]
        (cbAccept)="deleteAccept()"
        (cbClose)="deleteCancel()"
        [title]="TEXTS.POPUP_DELETE.title"
        [description]="TEXTS.POPUP_DELETE.body"
        [textAccept]="TEXTS.POPUP_DELETE.accept"
        [textCancel]="TEXTS.POPUP_DELETE.cancelNew"
    >
        <static-tags
            class="posts-list-view__static-tags"
            typeTag="mo"
            [text]="nameTargetCard"
        ></static-tags>
    </popup-ask>

    <card-actions-list
        class="posts-list-view__card-actions-list"
        *ngIf="isShowMenu"
        [@inOutAnimation]
        [position]="menuPositionTop"
        (clickOutside)="closePopup($event)"
    >
        <card-actions-item
            data-cy="action-rename"
            [text]="TEXTS.POSTS_AND_DEVICES.viewInformation"
            (click)="openPage($event, path + pages.details + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <card-actions-item
            data-cy="action-close"
            [text]="TEXTS.POSTS_AND_DEVICES.downloadDataFromPost"
            (click)="openPage($event, path + pages.postReport + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-item
            *ngIf="isRU"
            data-cy="action-close"
            [text]="TEXTS.POSTS_AND_DEVICES.downloadTZA4Report"
            (click)="openPage($event, path + pages.postReportTza + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <card-actions-item
            data-cy="action-close"
            [text]="TEXTS.POSTS_AND_DEVICES.edit"
            (click)="openPage($event, path + pages.postEdit + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-item
            data-cy="action-close"
            type="danger"
            [text]="TEXTS.POSTS_AND_DEVICES.deletePost"
            (click)="showPopup($event, 'close')"
        ></card-actions-item>
    </card-actions-list>
</ng-template>

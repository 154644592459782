<div class="subscription_card_content">
    <div class="subscription_card_content__title subscription_card_content__title-no_margin">
        {{ textsNotification.accommodationObjects }}
    </div>
    <div class="subscription_card_content__container subscription_card_content__container-mo">
        <tags-list
            *ngIf="currentMos.length !== 0; else showStubTag"
            [tags]="currentMos"
            [truncateTo]="maxInlineMos"
            [textNodesFns]="postsListLabels()"
        ></tags-list>
        <ng-template #showStubTag>
            <tags-list *ngIf="subscription.serviceIsForAllMos" [tags]="stubTags"></tags-list>
        </ng-template>
    </div>
    <div class="subscription_card_content__title">
        {{ textsNotification.notificationsFromEquipment }}
    </div>
    <div
        class="subscription_card_content__container subscription_card_content__container-service_notification"
    >
        <div
            class="subscription_card_content__item subscription_card_content__item-service_notification"
        >
            {{ textsNotification.lackOfData }}
            {{ subscription.serviceNoData }}
            {{ subscription.serviceNoData | i18nPlural : textsNotification.timeIntervalHours }}
        </div>
        <div
            class="subscription_card_content__item subscription_card_content__item-service_notification"
        >
            {{ textsNotification.lackOfPower }}
            {{ subscription.serviceNoPower }}
            {{ subscription.serviceNoPower | i18nPlural : textsNotification.timeIntervalHours }}
        </div>
        <div
            class="subscription_card_content__item subscription_card_content__item-service_notification"
        >
            {{ textsNotification.verificationExpires }}
            {{ subscription.serviceVerification }}
            {{ subscription.serviceVerification | i18nPlural : textsNotification.timeIntervalDays }}
        </div>
    </div>

    <div class="subscription_card_content__title">{{ textsNotification.recipients }}</div>
    <div class="subscription_card_content__container">
        <div class="subscription_card_content__item">
            {{ getEmails() || textsNotification.emailsNotSpecified }}
        </div>
    </div>
</div>

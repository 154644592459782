import * as moment from 'moment';
import 'moment/locale/ru';

import * as text_ru from './texts_ru';
import * as text_en from './texts_en';
import { currentSavedLang } from '../utils/local-storage';
import { environment } from 'environments/environment';

declare const window: any;

export let isRU = !!~(currentSavedLang || navigator.language).indexOf('ru');

if (environment.isAWS) isRU = false;

window.JS_CP_SITE_LANG = isRU ? 'RU' : 'EN';

export const TEXTS = isRU ? text_ru : text_en;

export const LANGUAGE = isRU ? 'ru' : 'en';

moment.locale(LANGUAGE);

import * as Color from 'color';
import { MEASUREMENTS_ORDER } from '../consts/measurements-order.const';

export function lightenColor(color: string) {
    return Color(color).alpha(0.8).rgb().string();
}

export const isFalseNumber = (a: number) => isNaN(a) || a === null || a === undefined;

export function sortMeasurements(a: string, b: string) {
    const indexA = MEASUREMENTS_ORDER.indexOf(a);
    const indexB = MEASUREMENTS_ORDER.indexOf(b);

    if (indexA === -1 && indexB === -1) {
        return 0;
    }

    if (indexA === -1) {
        return 1;
    }

    if (indexB === -1) {
        return -1;
    }

    return indexA - indexB;
}

export function copyObj(original) {
    if (!original) return original; // возвратит примитив, undefined, null
    // объект должен быть без функций, сложных объектов, вроде Date и циклических ссылок
    return JSON.parse(JSON.stringify(original));
}
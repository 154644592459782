import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LayerButtonIconType } from '@cityair/libs/shared/modules/overlay/components/layer-button/layer-button.component';
import { AQI } from '@libs/common/consts/substance.consts';
import { AqiType } from '@libs/common/enums/aqi.type';
import { TEXTS } from '@libs/common/texts/texts';

const AQIs = [AQI, ...Object.values(AqiType)];

@Component({
    selector: 'mmt-map-selector',
    templateUrl: './mmt-map-selector.component.html',
    styleUrls: ['./mmt-map-selector.component.less'],
})
export class MmtMapSelectorComponent {
    @Input() mmts: string[];
    @Input() activeMmt: string;
    @Output() selectMmt = new EventEmitter<string>();

    LayerButtonIconType = LayerButtonIconType;
    TEXTS = TEXTS;

    AQIs = AQIs;
    AqiType = AqiType;

    isActive = false;

    click = (mmt: string) => this.selectMmt.emit(mmt);
}

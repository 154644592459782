import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AQI } from '@libs/common/consts/substance.consts';
import { measureZones } from '@libs/common/helpers/measure-zones';
import { TEXTS } from '@libs/common/texts/texts';
import * as moment from 'moment';

@Component({
    selector: 'cs-aqi-indicator',
    templateUrl: './aqi-indicator.component.html',
    styleUrls: ['./aqi-indicator.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AqiIndicatorComponent implements OnChanges {
    @Input() value: number;
    @Input() dateTime?: number;
    @Input() tzOffset?: number;
    @Input() mmt: string;

    date: string;
    time: string;

    measureZones = measureZones;
    AQI = AQI;
    TEXTS = TEXTS;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dateTime || changes.tzOffset) {
            const { dateTime, tzOffset } = this;

            if (dateTime) {
                let d = moment(dateTime);

                if (!isNaN(tzOffset)) {
                    d = d.utcOffset(tzOffset);
                }

                this.time = d.format('HH:mm');
                this.date = `${d.format('D')} ${d.format('MMM').substring(0, 3)}`;
            }
        }
    }
}

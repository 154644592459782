<div class="plumes-source">
    <div class="plumes-source__description">
        <div class="plumes-source__title" [class.plumes-source__title--disabled]="isActive">
            {{ source.name }}
        </div>
        <div class="plumes-source__info">
            <div class="plumes-source__main">
                <div class="plumes-source__point">
                    <div class="plumes-source__point-coord">
                        {{ source.centroid.coordinates[1].toFixed(3) }}
                    </div>
                    <div class="plumes-source__point-coord">
                        {{ source.centroid.coordinates[0].toFixed(3) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="plumes-source__substances" *ngIf="list?.length">
        <ca-substances-list [items]="list"></ca-substances-list>
    </div>
</div>

<div class="logout" (clickOutside)="isOpenSelectLang = false">
    <div class="logout__user-name" data-cy="logout1" (click)="isOpenSelectLang = !isOpenSelectLang">
        {{ getUser() }}
    </div>
    <div
        [ngClass]="{
            logout__dropdown: true,
            'logout__dropdown-open': isOpenSelectLang
        }"
    >
        <div class="logout__user-info" *ngIf="(store.select(selectGroupInfo) | async)?.iAm as user">
            <div class="logout__user-login">{{ user.login }}</div>
            <div class="logout__user-email">{{ user.email }}</div>
            <div class="logout__user-role">{{ TEXTS.USER_ACCESS_TYPE[user.roleId] }}</div>
            <div class="logout__btn" data-cy="logout2" (click)="authService.logOut()">
                {{ TEXTS.LOGIN_WINDOWS.logout }}
            </div>
        </div>
    </div>
</div>

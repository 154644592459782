import { Group } from '@libs/common/types/group';
import { CityscreenMapApi } from '@cityair/libs/common/api/mapProvider/cityscreenMapApi';
import { createOriginChartData } from '@cityair/libs/common/api/mapProvider/create-origin-chart-data';
import { IntervalEnum, StationForMapPage_model } from '@cityair/namespace';
import { checkIsShowInfoError } from '@cityair/utils/check-is-show-info-error';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, forkJoin, from, map } from 'rxjs';
import { reloadGroupInfo, removeFromComparison } from './actions';

export const loadStationDataFromBackend = (
    {
        stations,
        time,
        interval,
        currentGroup,
        measureScheme,
        tzOffset,
        isCompareMode,
    }: {
        stations: StationForMapPage_model[];
        time: {
            begin: number;
            end: number;
        };
        interval: IntervalEnum;
        currentGroup: Group;
        measureScheme: MeasureScheme;
        tzOffset: number;
        isCompareMode: boolean;
    },
    api: CityscreenMapApi,
    store: Store
) =>
    forkJoin(
        stations.map((station) =>
            from(
                api.getMoPackets(
                    {
                        id: station.id,
                        timeBegin: time.begin,
                        timeEnd: time.end,
                        interval,
                        groupId: currentGroup?.id,
                    },
                    measureScheme
                )
            ).pipe(
                map((data) => ({
                    packets: data.packets,
                    station: {
                        ...station,
                        originChartData: createOriginChartData(
                            data.packets,
                            station.tzOffset && station.isOurStation ? station.tzOffset : tzOffset,
                            time.begin,
                            time.end,
                            interval,
                            data.measuresInfo
                        ),
                        lastPacketID: data.lastPacketID,
                    },
                    isCompareMode,
                })),
                catchError((error) => {
                    if (checkIsShowInfoError(error)) {
                        store.dispatch(removeFromComparison({ uuid: station.id }));
                        store.dispatch(reloadGroupInfo());
                    }

                    return EMPTY;
                })
            )
        )
    );

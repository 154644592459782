<div class="chart-wrapper" *ngIf="chartOptions.chart as chart">
    <cs-analytic-chart-title
        [title]="chartOptions.title"
        [tooltip1]="chartOptions.tooltip"
        [tooltip2]="chartOptions.description"
    ></cs-analytic-chart-title>

    <div class="chart-content">
        <canvas
            baseChart
            type="bar"
            [datasets]="chart.datasets"
            [labels]="chart.labels"
            [options]="chart.options"
            [plugins]="chart.plugins"
        ></canvas>
    </div>
</div>

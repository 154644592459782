import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ModelProps } from '@cityair/config';
import { TEXTS } from '@libs/common/texts/texts';

import { LayerButtonIconType } from '@cityair/libs/shared/modules/overlay/components/layer-button/layer-button.component';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import { setForecastMeasurement, toggleLayerOnMap, updateDateRange } from '../../store/actions';
import {
    currentForecastMmt,
    initCalendarData,
    selectDateRange,
    selectSpeciesList,
    showLayerOnMap,
} from '../../store/selectors';
import { DateRange } from '../../models';
import { AuthService } from '@cityair/modules/login/services/auth/auth.service';
import { selectTzMinutesOffset } from '@cityair/modules/core/store/selectors';

@Component({
    selector: 'forecast-top-elements',
    templateUrl: 'forecast-top-elements.html',
    styleUrls: ['forecast-top-elements.less'],
    animations: ANIMATION_OPACITY,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastTopElements implements OnInit, OnDestroy {
    NAMES = TEXTS.NAMES;
    onDestroy$ = new Subject<void>();

    LayerButtonIconType = LayerButtonIconType;
    currentForecastMmt = currentForecastMmt;
    selectDateRange = selectDateRange;
    selectSpeciesList = selectSpeciesList;
    isActiveForecast: boolean;
    dateRange: DateRange;
    minDate: any;
    maxDate: any;

    tzOffset$ = this.store.select(selectTzMinutesOffset);

    constructor(
        readonly authService: AuthService,
        readonly store: Store,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.store
            .select(showLayerOnMap)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((isActive) => {
                this.isActiveForecast = isActive;
                this._changeDetectorRef.markForCheck();
            });
        // todo now get minDate from group config
        this.store
            .select(initCalendarData)
            .pipe(
                filter((data) => !!data?.dateRange),
                take(1)
            )
            .subscribe((data) => {
                this.dateRange = data.dateRange;
                this.minDate = data.minDate;
                this.maxDate = data.maxDate;
                this._changeDetectorRef.markForCheck();
            });

        // todo get dateRange and maxDate and MinDate from api
        /* this.store.select(selectDateRange).pipe(
            filter(data => !!data),
            take(1)
        ).subscribe((data) => {
             this.dateRange = data.dateRange;
             this._changeDetectorRef.markForCheck();
        });
        this.store.select(forecastsAvailableInterval).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe((data) => {
            if (data) {
                 this.minDate: data.min;
                 this.maxDate: data.max
                 this._changeDetectorRef.markForCheck();
            }
        }); */
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    updateDateRange({ begin, end }) {
        this.store.dispatch(updateDateRange({ startDate: begin, finishDate: end }));
    }

    toggleForecast() {
        this.store.dispatch(toggleLayerOnMap({ payload: !this.isActiveForecast }));
    }

    updatePropsMmt(data: ModelProps) {
        this.store.dispatch(setForecastMeasurement({ payload: data.measure }));
    }
}

<div class="track-panel-wrapper" #trackPanel>
    <div class="track-data">
        <div
            class="block-wrapper"
            *ngFor="let item of date; index as i"
            [class.block-wrapper--has-data]="hasDataByIndex[i]"
        >
            <div
                class="block"
                [style.backgroundColor]="getBackground(i)"
                [ngClass]="{ active: i === index && !item.isNow, 'is-now': item.isNow }"
                (click)="setIndex(i)"
                caTooltip
                [caTooltipPlacement]="revertIndexTooltipPosition > i ? 'top-left' : 'top-right'"
                [caTooltipAllowHover]="true"
                [hoverDetachTime]="1000"
                [caTooltipTemplate]="tooltip"
            >
                <ng-container *ngIf="item.label">
                    <div class="label" [innerHTML]="item.label | safeHtml"></div>
                    <div class="tick"></div>
                </ng-container>
                <ng-container *ngIf="item.isNow">
                    <div
                        class="now-wrapper"
                        [ngClass]="{ 'chart-open': chartEnabled, 'with-label': item.label }"
                    >
                        <div class="label-now">{{ nowText }}</div>
                        <div class="now-border"></div>
                    </div>
                </ng-container>
                <ng-template #tooltip>
                    <ng-container *ngIf="qualityDataMode&&chartEnabled&&dataQuality[i]&&hasDataByIndex[i]">
                        <ca-tooltip [pointerColor]="'#404655'" class="quality-data-tooltip-wrapper">
                            <div class="tooltip-wrapper" *ngIf="getTooltipText(i) as tooltip">
                                <div class="quality-data-tooltip" [innerHTML]="tooltip?.text"></div>
                                <a class="quality-data-link"
                                   *ngIf="tooltip?.link" (click)="openDetailsQuality(i)">
                                     {{textsQuality.tooltipLink}}
                                </a>
                            </div>
                        </ca-tooltip>
                    </ng-container>
                    <ng-container *ngIf="!hasDataByIndex[i]">
                        <ca-tooltip [pointerColor]="'#404655'" class="quality-data-tooltip-wrapper">
                            <div class="tooltip-wrapper">
                                <div class="quality-data-tooltip" [innerHTML]="textsQuality.tooltip.noData"></div>
                            </div>
                        </ca-tooltip>
                    </ng-container>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="track-label-quality-data" *ngIf="qualityDataMode&&chartEnabled&&dataQuality.length">{{textsQuality.timelineLabel}}</div>
</div>


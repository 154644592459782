import { StationForMapPage_model } from '@cityair/namespace';
import { createAction, props } from '@ngrx/store';

export enum ComparedListCommonActions {
    UpdateComparedItems = '[CommonAction] UpdateComparedItems',
    CompareListAddUnique = '[CommonAction] CompareListAddUnique',
    CompareListRemove = '[CommonAction] CompareListRemove',
    CompareListRemoveForIndex = '[CommonAction] CompareListRemoveForIndex',
    ClearCompareList = '[CommonAction] ClearCompareList',
    ClearCompareListWithoutFirst = '[CommonAction] ClearCompareListWithoutFirst',
}

export const updateComparedItems = createAction(
    ComparedListCommonActions.UpdateComparedItems,
    props<{ items: StationForMapPage_model[] }>()
);

export const compareListAddUnique = createAction(
    ComparedListCommonActions.CompareListAddUnique,
    props<{ objects: StationForMapPage_model[] }>()
);

export const compareListRemove = createAction(
    ComparedListCommonActions.CompareListRemove,
    props<{ id: number }>()
);

export const clearCompareList = createAction(ComparedListCommonActions.ClearCompareList);

export const clearCompareListWithoutFirst = createAction(
    ComparedListCommonActions.ClearCompareListWithoutFirst
);

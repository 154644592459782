<div>
    <ng-container
        *ngIf="
            (groupInfo$ | async)?.allowForecast ||
            (moduleConfig$ | async).enablePlumeButton
        "
    >
        <ca-layer-button
            class="layer_button"
            *ngIf="(routingService.pageChange$ | async) === 'plumes'; else forecastButton"
            [type]="LayerButtonIconType.PLUME"
            [active]="showPlumesLayer"
            (clickOnIcon)="togglePlumesLayer()"
        >
            <ca-layer-button-item
                [label]="TEXTS.NAMES[currentMeasure] || currentMeasure"
                [active]="true"
            ></ca-layer-button-item>
        </ca-layer-button>

        <ng-template #forecastButton>
            <ca-layer-button
                class="layer_button"
                *ngIf="
                    (isCityMode$ | async) &&
                    (routingService.pageChange$ | async) === 'forecast'
                "
                [type]="LayerButtonIconType.FORECAST"
                [active]="isActiveModelling"
                (clickOnIcon)="
                    isActiveModelling ? disableModelling() : enableModelling()
                "
            >
                <ca-layer-button-item
                    *ngFor="let opt of MEASURES_FOR_FORECAST"
                    [label]="TEXTS.NAMES[opt]"
                    [active]="(forecastControlService.props$ | async)?.measure === opt"
                    (click)="
                        forecastControlService.updateProps({
                            measure: opt,
                            contours: false,
                            overlay: true
                        })
                    "
                ></ca-layer-button-item>
            </ca-layer-button>
        </ng-template>
    </ng-container>
</div>

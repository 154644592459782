import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { getDigitsAfterDot } from '@cityair/config';
import { MapControlPins, MapPins } from '@cityair/namespace';
import { TabModel } from '@libs/common/types/tab-model';
import { MapAdapterService } from '@cityair/modules/map/services/map-adapter.service';
import {
    getCurrentGroup,
    selectIsCityMode,
    selectIsSidebarOpen,
    selectMeasureScheme,
    selectSourcesAsFeatures,
} from '@cityair/modules/core/store/selectors';
import { ControlPoint } from '../../services/control-point/models';
import {
    setActivePlumesControlPoint,
    setActiveStation,
    updateTimeIndex,
} from '../../store/actions';
import {
    currentPlumesMmt,
    getControlPointValues,
    getStations,
    getStationsValues,
    selectActiveControlPointAsList,
    selectActiveStationAsList,
    selectControlPointReports,
    selectDatesPlumes,
    selectPlumesSchema,
    selectPlumesZone,
    selectPlumeTime,
} from '../../store/selectors';
import { PlumesState } from '../../store/reducers';
import { getColorFromZone } from '@cityair/utils/utils';
import { Station } from '@cityair/modules/plumes/services/station/models';
import { aqiDataProviderFactory } from '@cityair/modules/core/utils';
import { ZONES } from '@libs/common/consts/zone.const';
import { TEXTS } from '@libs/common/texts/texts';
import { getModulePageConfig } from '@cityair/modules/core/store/module-page-config/module-page-config.feature';
import { loadCity } from '@cityair/modules/core/store/actions';

@Component({
    selector: 'plumes-map-mobile',
    templateUrl: './plumes-map-mobile.component.html',
    styleUrls: ['./plumes-map-mobile.component.less'],
})
export class PlumesMapMobileComponent implements OnInit, OnDestroy {
    tabsInterval: TabModel[] = [
        {
            title: TEXTS.TIME_NAMES.min5,
            type: 1,
        },
        {
            title: TEXTS.TIME_NAMES.min20,
            type: 2,
        },
        {
            title: TEXTS.TIME_NAMES.hour1,
            type: 3,
        },
    ];

    private subscriptions: Subscription[] = [];

    moduleConfig$ = this.store.select(getModulePageConfig);

    currentPlumesMmt = currentPlumesMmt;
    MeasureScheme = MeasureScheme;
    selectIsSidebarOpen = selectIsSidebarOpen;
    selectSourcesAsFeatures = selectSourcesAsFeatures;
    selectDatesPlumes = selectDatesPlumes;
    selectMeasureScheme = selectMeasureScheme;
    updateTimeIndex = updateTimeIndex;
    selectPlumeTime = selectPlumeTime;
    selectIsCityMode = selectIsCityMode;

    currentGroup$ = this.store.select(getCurrentGroup);

    aqiDataProvider = aqiDataProviderFactory(this.store);

    constructor(
        readonly store: Store,
        readonly storePlumes: Store<PlumesState>,
        private mapAdapterService: MapAdapterService
    ) {}

    async ngOnInit() {
        const controlPointPins: MapControlPins = {
            getPins: this.storePlumes.select(selectControlPointReports),
            getSelectedPinIds: this.storePlumes.select(selectActiveControlPointAsList),
            getValue: (pin) => this.storePlumes.select(getControlPointValues(pin)),
            getColor: (pin) =>
                combineLatest([
                    this.storePlumes.select(getControlPointValues(pin)),
                    this.store.select(currentPlumesMmt),
                ]).pipe(
                    map(([value, mmt]) =>
                        getColorFromZone(ZONES[MeasureScheme.default][mmt], value)
                    )
                ),
            clickCb: (pin) => {
                const point = pin as ControlPoint;
                this.store.dispatch(setActivePlumesControlPoint({ payload: point }));
            },
            isDraggable: (pin) => of(true),
            dragEnd: ($event) => console.log('drag end plumes mobile', $event),
        };

        const postPins: MapPins = {
            getPins: this.store.select(getStations),
            selectDigitsAfterDot: this.store
                .select(selectPlumesSchema)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: this.storePlumes.select(selectActiveStationAsList),
            getValue: (pin) => this.store.select(getStationsValues(pin as Station)),
            getColor: (pin) =>
                combineLatest([
                    this.store.select(selectPlumesZone),
                    this.store.select(getStationsValues(pin as Station)),
                ]).pipe(map(([zone, value]) => getColorFromZone(zone, value))),

            clickCb: (pin) => {
                const station = pin as Station;
                if (station?.data?.measurements) {
                    this.store.dispatch(setActiveStation({ payload: station }));
                }
            },
        };

        this.mapAdapterService.set({
            controlPointPins,
            postPins,
            cityPins: null,
            groupFeaturesLayer: await this.mapAdapterService.getDefaultGroupFeaturesLayer(),
            onMapZoomChanged: this.mapAdapterService.defaultMapZoomChanged,
        });
    }

    ngOnDestroy() {
        this.mapAdapterService.setDefaultMap();
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    getDigits = (measureScheme: MeasureScheme) => (mmt) => getDigitsAfterDot(measureScheme, mmt);

    public goToCity(cityId: string) {
        this.store.dispatch(loadCity({ cityId, centringMap: true }));
    }
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'ca-input',
    templateUrl: 'input.component.html',
    styleUrls: ['input.component.less'],
})
export class InputComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() valueForm;
    @Input() type = 'text';
    @Input() textInfo = '';
    @Input() disabled = false;
    @Input() textError = '';
    @Input() prompt = '';
    @Input() autofocus = false;
    @Input() step = 0.1;

    @ViewChild('inputText', { static: true }) inputText: ElementRef<HTMLInputElement>;
    @ViewChild('promptText', { static: true }) promptText: ElementRef<HTMLInputElement>;

    ngOnInit() {
        if (this.prompt != '') {
            const elInputText = this.inputText.nativeElement;
            const elPromptText = this.promptText.nativeElement;

            elPromptText.innerHTML = this.prompt;
            elInputText.style.paddingRight = elPromptText.offsetWidth + 'px';
        }
        if (this.autofocus && this.inputText) {
            this.inputText.nativeElement.focus();
        }
    }
}

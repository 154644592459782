import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { PercentChartComponent } from '@cityair/modules/indoor/components/percent-chart/percent-chart.component';

@NgModule({
    imports: [CommonModule, NgChartsModule],
    declarations: [PercentChartComponent],
    exports: [PercentChartComponent],
})
export class PercentChartModule {}

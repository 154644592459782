<div class="label-wrapper">
    <label
        class="label"
        [ngClass]="{
            'selected-item': isSelected
        }"
        caTooltip
        caTooltipPlacement="top"
        [caTooltipAllowHover]="true"
        [caTooltipTemplate]="tooltip1"
    >
        <div
            class="wrap-checkbox"
            [attr.title]="TEXTS.STATION_BLOCK.add"
            (click)="toggleMmt.emit()"
        >
            <cs-checkbox [checked]="isSelected"></cs-checkbox>
        </div>
        <span
            [attr.title]="TEXTS.STATION_BLOCK.select"
            (click)="selectMmt.emit()"
            [innerHtml]="TEXTS.NAMES[mmt] || mmt"
        ></span>
        <ca-info-icon
            *ngIf="mmtInfoIcon?.name === mmt"
            data-cy="timeline-info-mmt"
            style="margin-left: 6px"
            (click)="mmtInfoIcon.cb()"
        ></ca-info-icon>
    </label>
    <div class="label-mmt" *ngIf="unit" [innerHTML]="unit | safeHtml"></div>
</div>

<ng-template #tooltip1>
    <ng-container *ngIf="groupTooltip?.[mmt]; else mmtTooltipTemplate">
        <ca-tooltip>
            <div class="aqi_tooltip" [innerHTML]="groupTooltip[mmt]"></div>
        </ca-tooltip>
    </ng-container>
    <ng-template #mmtTooltipTemplate>
        <ng-container *ngIf="mmtWithTooltip.indexOf(mmt) >= 0">
            <ca-tooltip>
                <div class="aqi_tooltip" [innerHTML]="TEXTS.MMT_LONG_NAMES[mmt]"></div>
            </ca-tooltip>
        </ng-container>
    </ng-template>
</ng-template>

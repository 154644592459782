import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';

import * as moment from 'moment';
import { Observable, Subject, takeUntil } from 'rxjs';

import { RunPlume, RunPlumeAnnotation } from '@cityair/modules/plumes/services/run/models';
import { TEXTS } from '@libs/common/texts/texts';
import { Store } from '@ngrx/store';
import { setActiveRun, setRunsLoadError, updateDateRangeRun } from '../../store/actions';
import { RunService } from '../../services/run/run.service';
import { selectActiveRun, selectDateRangeRuns, selectRunLoading } from '../../store/selectors';
import { dateRangeText } from '@libs/common/utils/date-formats';

@Component({
    selector: 'plumes-calculation-results',
    templateUrl: 'plumes-calculation-results.component.html',
    styleUrls: ['plumes-calculation-results.component.less'],
})
export class PlumesCalculationResults implements OnDestroy {
    @Input() plumesVisible: boolean;
    @Input() loadError: boolean;
    @Input() runs: RunPlumeAnnotation[];
    @ViewChild('scrollable') scrollable: ElementRef<HTMLDivElement>;

    public loaded$: Observable<boolean>;
    public loading$: Observable<boolean>;
    public isLoadingRun: boolean;
    isScrolledToTop = true;
    textNoRunsAvailable = TEXTS.PLUMES.selectedPeriodNoRunsAvailable;
    errorListLoadText = TEXTS.FORECAST.errorListLoad;
    textWind = TEXTS.PLUMES.quickWindButton;

    tzOffset = moment().utcOffset();
    currentRunId: number;
    dateRangeFilterText: string;
    calendarIsOpened = false;
    public dateTimeBegin: number;
    public dateTimeEnd: number;
    public ngDestroyed$ = new Subject<void>();

    constructor(private store: Store, private runService: RunService) {
        this.loading$ = runService.loading$;
        this.loaded$ = runService.loaded$;
        this.store.select(selectDateRangeRuns).subscribe((dateRange) => {
            this.dateRangeFilterText = dateRangeText(
                new Date(dateRange.startDate),
                new Date(dateRange.finishDate)
            );
            this.dateTimeBegin = dateRange.startDate;
            this.dateTimeEnd = dateRange.finishDate;
        });
        this.store
            .select(selectActiveRun)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((activeRun) => (this.currentRunId = activeRun?.id ?? null));
        this.store
            .select(selectRunLoading)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isLoading) => (this.isLoadingRun = isLoading));
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    scrollTop() {
        this.scrollable.nativeElement.scrollTo(0, 0);
    }

    onScroll() {
        this.isScrolledToTop = !this.scrollable.nativeElement.scrollTop;
    }

    updateDateRange = (begin: number, end: number) => {
        this.calendarIsOpened = false;
        // fix start date after calendar
        begin = moment(begin).startOf('day').valueOf();

        const dateTimeBegin = new Date(begin);
        const dateTimeEnd = new Date(end);
        this.store.dispatch(setRunsLoadError({ payload: false }));
        this.store.dispatch(
            updateDateRangeRun({
                payload: {
                    startDate: moment(dateTimeBegin).valueOf(),
                    finishDate: moment(dateTimeEnd).valueOf(),
                },
            })
        );
        this.runService.clearCache();
    };

    selectRun(run: RunPlume) {
        if (this.currentRunId && this.currentRunId != run.id) {
            this.store.dispatch(setActiveRun({ payload: run }));
        }
    }
}

<div class="top_right_panel">
    <ca-layer-button
        *ngIf="currentMeasure"
        class="layer_button"
        [type]="LayerButtonIconType.FORECAST"
        [active]="showPlumesLayer$ | async"
        (clickOnIcon)="togglePlumesLayer()"
    >
        <ca-layer-button-item
            *ngFor="let opt of speciesList"
            [label]="textNames[opt]"
            [active]="currentMeasure === opt"
            [isLoading]="isLoading"
            (click)="!isLoading && changeMmt(opt)"
        ></ca-layer-button-item>
    </ca-layer-button>
    <ng-container *ngIf="store.select(selectPlumesHeights) | async as heights">
        <ca-plumes-heights-selector
            class="heights-selector"
            *ngIf="heights.length > 1"
            [heights]="heights"
            [value]="store.select(selectPlumesCurrentHeight) | async"
            [isLoading]="isLoading"
            [ngStyle]="{ top: topHeightSelector + 'px' }"
            (changeHeight)="changeHeight($event)"
        ></ca-plumes-heights-selector>
    </ng-container>

    <run-summary *ngIf="run" [run]="run"></run-summary>
    <logout (handleLogout)="logOut()"></logout>
</div>

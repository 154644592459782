import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { EntitySelectorsFactory, QueryParams } from '@ngrx/data';
import * as moment from 'moment';

import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import {
    selectGroupInfo,
    selectLoadingTimeline,
    selectMeasureScheme,
    selectMeasuresZones,
} from '@cityair/modules/core/store/selectors';
import { ControlPointForecast, ForecastConfig, getVangaScheme, Station } from '../models';
import { GET_FORECAST_INTERVAL_TIME_BY_API, NEW_CONTROL_POINT_OBJ_TYPE } from '../constants';
import { ForecastState, IForecastState } from './reducers';
import { pipe } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ColorZone } from '@libs/common/types/color-zone';
import { isFalseNumber } from '@libs/common/utils/utils';

export const forecastsSelectors = new EntitySelectorsFactory().create<ForecastConfig>('Forecasts');
export const selectForecasts = createSelector(
    forecastsSelectors.selectEntities,
    (entities) => entities
);
export const forecastsAvailableInterval = createSelector(selectForecasts, (entities) => {
    let max = null;
    let min = null;
    if (entities.length) {
        entities.forEach((item) => {
            if (!max) {
                max = item.end;
            }
            if (!min) {
                min = item.start;
            }
            max = new Date(item.end) > new Date(max) ? item.end : max;
            min = new Date(item.start) < new Date(min) ? item.start : min;
        });

        return {
            minDate: min,
            maxDate: max,
        };
    }
});
export const selectSpeciesList = createSelector(selectForecasts, (entities) => {
    if (entities.length) {
        return entities[0].species_list;
    }
});
export const stationSelectors = new EntitySelectorsFactory().create<Station>('Station');
export const getStations = createSelector(stationSelectors.selectEntities, (entities) => entities);
export const stationLoaded = createSelector(stationSelectors.selectLoaded, (entities) => entities);

export const getStationsValues = (post: Station) =>
    createSelector(currentForecastMmt, selectCurrentIndexForecast, (mmt, index) => {
        if (!isFalseNumber(index) && mmt && post) {
            const arr = post.data?.measurements?.[mmt]?.values;
            return arr ? arr[index] : null;
        }
    });

export const controlPointSelectors = new EntitySelectorsFactory().create<ControlPointForecast>(
    'ControlPoint'
);
export const getControlPoints = createSelector(
    controlPointSelectors.selectEntityMap,
    (entities) => entities
);
export const selectControlPointsForecast = createSelector(
    controlPointSelectors.selectEntities,
    (entities) => entities
);
export const loadedControlPoints = createSelector(
    controlPointSelectors.selectLoaded,
    (value) => value
);

export const selectControlPointById = (id: number) =>
    createSelector(getControlPoints, loadedControlPoints, (entities, isLoaded) => {
        if (isLoaded) {
            return entities[id] ? entities[id] : null;
        }
    });

export const getControlPointValues = (point: ControlPointForecast) =>
    createSelector(currentForecastMmt, selectCurrentIndexForecast, (mmt, index) => {
        if (!isFalseNumber(index) && mmt && point) {
            const arr = point.timeseries?.values[mmt];
            return arr ? arr[index] : null;
        }
    });

export const forecastState = createFeatureSelector<IForecastState>('forecast');
export const selectForecastCore = createSelector(
    forecastState,
    (state: IForecastState): ForecastState => state?.core
);
export const selectDateRange = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.dateRange
);
export const currentForecastMmt = createSelector(
    selectForecastCore,
    (state: ForecastState) => state?.currentMmt
);
export const isActiveForecast = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.isActiveModule
);
export const showLayerOnMap = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.showLayerOnMap
);
export const isValidToken = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.isValidToken
);
export const isReloadControlPoint = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.updateControlPoint
);
export const selectControlPointError = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.controlPointsError
);
export const selectActivePoint = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.activePoint
);
export const selectListActive = createSelector(selectForecastCore, (state: ForecastState) => [
    state.activePoint?.id,
    state.activeStation?.id,
]);
export const selectErrorLoadList = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.errorLoadList
);
export const initQueryConfig = createSelector(
    selectGroupInfo,
    isValidToken,
    (groupInfo, isValidToken) => {
        const isAvailable = groupInfo?.extConfig?.showForecastModule ? true : false;
        if (groupInfo && isValidToken && isAvailable) {
            return groupInfo.groupId.toString();
        }
    }
);
export const selectForecastConfig = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.forecastConfig
);

export const selectDatesForecast = createSelector(
    selectForecastCore,
    (state: ForecastState) => state?.dates
);
export const selectCurrentIndexForecast = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.currentTimeIndex
);
export const selectForecastTime = createSelector(
    selectDatesForecast,
    selectCurrentIndexForecast,
    (dates, index) => new Date(dates[index]).getTime()
);
export const selectForecastCurrentTime = pipe(
    select(selectForecastTime),
    distinctUntilChanged((prev, current) => prev === current)
);
export const selectForecastTimeRangeWithoutDistinct = createSelector(
    selectDatesForecast,
    (dates) => ({
        begin: new Date(dates[0]).getTime(),
        end: new Date(dates[dates.length - 1]).getTime(),
    })
);
export const selectForecastTimeRange = pipe(
    select(selectForecastTimeRangeWithoutDistinct),
    distinctUntilChanged(
        (prev, current) => prev.begin === current.begin && prev.end === current.end
    )
);
export const selectCurrentTimeForecast = createSelector(
    selectForecastCore,
    (state: ForecastState) => new Date(state?.dates[state.currentTimeIndex]).getTime()
);
export const selectActiveStation = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.activeStation
);
export const selectNewCoordinates = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.coordinates
);
export const selectNewControlPoints = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.newControlPoint
);
export const selectEditControlPointId = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.editControlPointId
);
export const selectChartData = createSelector(
    selectForecastCore,
    (state: ForecastState) => state.chartData
);
export const getControlPointsForMap = createSelector(
    selectControlPointsForecast,
    selectNewControlPoints,
    (controlPoints, newControlPoint) => {
        const result = [...controlPoints];
        if (newControlPoint) {
            result.push(newControlPoint);
        }

        return result;
    }
);
export const isDraggableControlPoint = (point: ControlPointForecast) =>
    createSelector(getControlPointsForMap, selectEditControlPointId, (controlPoints, editId) => {
        if (editId && editId === point.id) {
            return true;
        }
        const newControlPoint = controlPoints.find(
            (item) => item.obj === NEW_CONTROL_POINT_OBJ_TYPE
        );
        if (newControlPoint && point.id === null) {
            return true;
        }
        return false;
    });
export const selectForecastMapSettings = createSelector(
    selectGroupInfo,
    selectLoadingTimeline,
    (groupInfo, loadingTimeline) => {
        const forecastMapSettings = groupInfo?.extConfig?.forecastMapSettings;
        if (forecastMapSettings && !loadingTimeline) {
            return forecastMapSettings;
        }
    }
);
export const selectForecastZone = createSelector(
    selectMeasureScheme,
    selectMeasuresZones,
    currentForecastMmt,
    (scheme, zones, currentMmt) => zones[scheme][currentMmt] as ColorZone
);
export const selectForecastSchema = createSelector(
    selectMeasureScheme,
    currentForecastMmt,
    (scheme, currentMmt) => ({
        scheme: scheme,
        mmt: currentMmt,
    })
);
export const selectForecastSchemaZones = createSelector(
    selectMeasureScheme,
    currentForecastMmt,
    selectMeasuresZones,
    (scheme, currentMmt, zones) => {
        if (zones && scheme && currentMmt && zones[scheme][currentMmt]) {
            return {
                scheme: scheme,
                mmt: currentMmt,
                zone: zones[scheme][currentMmt] as ColorZone,
            };
        }
        return null;
    }
);
export const initCalendarData = createSelector(
    forecastsAvailableInterval,
    selectGroupInfo,
    selectDateRange,
    (interval, groupInfo, dateRange) => {
        if (groupInfo && dateRange) {
            let minDate = interval?.minDate;
            let maxDate = interval?.maxDate;
            const forecastConfig = groupInfo?.extConfig?.forecastConfig;
            if (forecastConfig && !GET_FORECAST_INTERVAL_TIME_BY_API) {
                if (forecastConfig.calendarMinDate) {
                    minDate = forecastConfig.calendarMinDate;
                }
                if (forecastConfig.numberOfFutureDays) {
                    maxDate = moment()
                        .startOf('day')
                        .add(forecastConfig.numberOfFutureDays, 'days')
                        .toISOString();
                }
            }
            return {
                dateRange: dateRange,
                minDate: minDate,
                maxDate: maxDate,
            };
        }
    }
);
export const getParamsControlPoint = createSelector(
    selectGroupInfo,
    selectDateRange,
    currentForecastMmt,
    selectMeasureScheme,
    (groupInfo, dateRange, currentMmt, scheme) => {
        if (groupInfo && groupInfo.groupId && dateRange && currentMmt && scheme) {
            const params: QueryParams = {
                group_id: groupInfo.groupId.toString(),
                start: new Date(dateRange.startDate).toISOString(),
                end: new Date(dateRange.finishDate).toISOString(),
                species: currentMmt,
                h: '0',
                measure_scheme: getVangaScheme(scheme),
            };
            return params;
        }
    }
);
export const getParamsStation = createSelector(
    selectGroupInfo,
    selectDateRange,
    currentForecastMmt,
    selectMeasureScheme,
    (groupInfo, dateRange, currentMmt, scheme) => {
        if (groupInfo && groupInfo.groupId && dateRange && currentMmt && scheme) {
            const params: QueryParams = {
                group_id: groupInfo.groupId.toString(),
                date__gt: new Date(dateRange.startDate).toISOString(),
                date__lt: new Date(dateRange.finishDate).toISOString(),
                packet_type: currentMmt,
                measure_scheme: scheme !== MeasureScheme.mpc ? scheme : MeasureScheme.default,
                concentration_in_mpc: (scheme === MeasureScheme.mpc).toString(),
                obj_filter: 'outdoor_post',
            };
            return params;
        }

        return null;
    }
);

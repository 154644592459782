export const environment = {
    production: true,
    sentry: true,
    yandex_metrika: {
        id: 66985123,
        webvisor: true,
    },
    is_mobile_app: false,
    deploy_url: 'map-build/',
    api_url: 'https://api.cityair.global',
    harvester_v1_api_url: 'https://api.cityair.global',
    vanga_api_url: 'https://vanga.cityair.io/api',
    tile_server_url: 'https://tiles.cityair.io',
    isAWS: true,
};

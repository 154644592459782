import { WindowGlobalVars } from '@cityair/namespace';

declare const window: WindowGlobalVars;

export class CheckLongPending {
    start: number;
    interval;

    constructor() {
        this.start = new Date().getTime();

        this.interval = setInterval(() => {
            if (new Date().getTime() - this.start > 35000) {
                clearInterval(this.interval);

                window.sentryAddBreadcrumb?.({
                    message: 'GetTimelineInfo дольше 35 секунд',
                });
            }
        }, 100);
    }

    end = () => clearInterval(this.interval);
}

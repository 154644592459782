import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TEXTS } from '@libs/common/texts/texts';
import { MonitoringObject } from '@cityair/namespace';
import { TabModel } from '@libs/common/types/tab-model';
import { REPORT_INIT_PAGE, REPORTS_PAGES, UserData } from '@cityair/modules/reports/models';
import { Store } from '@ngrx/store';
import { selectGroupInfo } from '@cityair/modules/core/store/selectors';
import { toggleReportModule } from '../../store/actions';
import { AuthService } from '@cityair/modules/login/services/auth/auth.service';

@Component({
    selector: 'cityscreen-reports-main',
    templateUrl: './reports-main.component.html',
    styleUrls: ['./reports-main.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsMainComponent implements OnInit, OnDestroy {
    public translateText = TEXTS.REPORTS;
    public translateAccessType = TEXTS.USER_ACCESS_TYPE;
    public exitText = TEXTS.LOGIN_WINDOWS.logout;
    public pages = REPORTS_PAGES;
    private initActive = REPORT_INIT_PAGE;
    readonly mainComponentUrl = `/${REPORTS_PAGES.reports}/`;
    public userData: UserData;
    public monitoringObjects: MonitoringObject[];
    public showLogout = false;
    public ngDestroyed$ = new Subject<void>();
    constructor(private router: Router, public store: Store, public authService: AuthService) {
        this.store
            .select(selectGroupInfo)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                if (data) {
                    if (data.hasOwnProperty('iAm')) {
                        this.userData = {
                            userId: data.iAm.userId ?? null,
                            login: data.iAm.login ?? null,
                            email: data.iAm.email ?? null,
                            roleId: data.iAm.roleId ?? null,
                            roleName: data.iAm.roleId
                                ? this.translateAccessType[data.iAm.roleId]
                                : '',
                            shortName: data.iAm.login
                                ? data.iAm.login.substring(0, 2).toUpperCase()
                                : '',
                        };
                    }
                    if (data.hasOwnProperty('monitoringObjects')) {
                        this.monitoringObjects = data.monitoringObjects;
                    }
                }
            });
    }
    ngOnInit(): void {
        this.store.dispatch(toggleReportModule({ payload: true }));
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
        this.store.dispatch(toggleReportModule({ payload: false }));
    }

    tabs: TabModel[] = [
        {
            isDisabled: true,
            type: 1,
            id: REPORTS_PAGES.analytics,
            title: this.translateText.analiticTitle,
        },
        {
            isDisabled: false,
            type: 2,
            id: REPORTS_PAGES.reportsList,
            title: this.translateText.reportsListTitle,
        },
        {
            isDisabled: true,
            type: 2,
            id: REPORTS_PAGES.compare,
            title: this.translateText.compareTitle,
        },
    ];
    selectedTab: TabModel = this.tabs.find((item) => item.id === this.initActive);

    getActiveTab() {
        const tabId = this.router.url
            ? this.router.url.replace(this.mainComponentUrl, '')
            : this.selectedTab;
        return this.tabs.find((item) => item.id === tabId);
    }

    openPage(event) {
        this.router.navigate([this.mainComponentUrl + event.id]);
    }
}

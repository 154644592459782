import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import * as moment from 'moment';
import { formatDayMonth, formatDayMonthLowerCase, formatDayMonthYear } from '../../../../config';

@Component({
    selector: 'indoor-calendar-mobile',
    templateUrl: './indoor-calendar-mobile.component.html',
    styleUrls: ['./indoor-calendar-mobile.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndoorCalendarMobileComponent implements OnChanges {
    @Input() timeBegin: Date;
    @Input() timeEnd: Date;
    @Output() updateDateRange = new EventEmitter<{ begin: number; end: number }>();

    show = false;
    tzOffset = moment().utcOffset();
    intervalText: string;
    begin: number;
    end: number;
    ngOnChanges() {
        this.begin = this.timeBegin.getTime();
        this.end = this.timeEnd.getTime() - 1;
        this.intervalText = this.getIntervalText();
    }

    updateTimeRange = (begin: number, end: number) => {
        this.updateDateRange.emit({ begin: begin, end: end });
        this.show = false;
    };

    handleUpdateDays: (tzOffset: number, numberOfFutureDays?: number, minDate?: string) => void;

    toggleShow = () => {
        this.show = !this.show;
    };

    getIntervalText() {
        const begin = moment(this.begin).utcOffset(this.tzOffset);
        const end = moment(this.end).utcOffset(this.tzOffset);
        const textStart = formatDayMonthLowerCase(begin);
        const textEnd = formatDayMonthLowerCase(end);
        const year = end.format('YYYY');
        return `${textStart} - ${textEnd} ${year}`;
    }

    updateTime = (begin: number, end: number) => {
        this.updateDateRange.emit({ begin, end });
        this.show = false;
    };

    updateDays = (fn: (tzOffset: number, numberOfFutureDays?: number, minDate?: string) => void) =>
        (this.handleUpdateDays = fn);
}

import { Component, OnDestroy } from '@angular/core';

import { TEXTS } from '@libs/common/texts/texts';
import {
    NotificationsStateService,
    SubscriptionTypeFilter,
} from '../../notifications-state.service';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styleUrls: ['settings.component.less'],
})
export class Settings implements OnDestroy {
    textsNotification = TEXTS.NOTIFICATIONS;
    filterTypes = SubscriptionTypeFilter;

    constructor(public stateService: NotificationsStateService) {}

    ngOnDestroy() {
        this.stateService.resetData();
    }
}

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgLocalization } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TEXTS } from '@libs/common/texts/texts';
import { NotificationSubscription } from '@cityair/libs/common/api/adminPanel/dataTransformer';
import { PacketValueType } from '@cityair/modules/login/services/harvester-api/models';
import { NotificationsStateService } from '../../notifications-state.service';
import { MAX_INLINE_MOS } from '../../notifications.settings';
import { postsListLabels } from '../../notifications.utils';
import { MonitoringObject } from '@cityair/namespace';
import { selectMos } from '@cityair/modules/core/store/selectors';

@Component({
    selector: 'subscription-card-measurements',
    templateUrl: './subscription-card-measurements.component.html',
    styleUrls: ['subscription-card-measurements.component.less'],
})
export class SubscriptionCardMeasurements implements OnInit, OnDestroy {
    @Input() subscription: NotificationSubscription;

    currentMos: string[];
    allMos: number[];
    allValues: number[];
    saving = false;
    maxInlineMos = MAX_INLINE_MOS;
    monitoringObjects: MonitoringObject[];

    textsNotification = TEXTS.NOTIFICATIONS;

    stubTags = [this.postsListLabels().all()];
    subscriptions: Subscription[] = [];
    constructor(
        public stateService: NotificationsStateService,
        private ngLocalization: NgLocalization,
        private store: Store
    ) {
        const monitoringObjectsSub = store
            .select(selectMos)
            .pipe(filter((v) => !!v.length))
            .subscribe((mos) => {
                this.monitoringObjects = mos;
                this.allMos = mos.map((v) => v.id);
            });

        this.subscriptions.push(monitoringObjectsSub);

        const { PM2, PM10, NO2, SO2, O3, CO, H2S } = PacketValueType;

        this.allValues = [PM2, PM10, NO2, SO2, O3, CO, H2S];
    }

    ngOnInit() {
        this.currentMos = this.initialMosList()
            .map((mo) => this.getMoName(mo))
            .filter((v) => v);
    }

    ngOnDestroy() {
        this.stateService.resetNotifiablePosts();
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    initialMosList() {
        return this.subscription.isForAllMos ? this.allMos : this.subscription.moItems;
    }

    removeMo(id: number) {
        this.subscription.moItems = this.subscription.moItems.filter((v) => v !== id);
    }

    getValueName(value: number) {
        return this.stateService.getValueName(value);
    }

    getMoName(id: number) {
        return this.monitoringObjects.find((v) => v.id === id)?.name;
    }

    getEmails() {
        return this.subscription.emailsList.join(', ');
    }

    editSubscription() {
        this.stateService.openSettings(this.subscription);
    }

    postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    addSubscription() {
        this.saving = true;
        this.stateService.addSubscription(this.subscription).finally(() => {
            this.saving = false;
        });
    }
}

import { errorTransformer, ErrorTransformer_model } from './dataProvider/DataProvider';
import { ErrorNumberType, ErrorResult } from '@cityair/modules/login/services/harvester-api/models';

export class ApiModels {
    private _errorCb: (data: ErrorTransformer_model) => void;
    private _errorTokenCb: () => void;

    constructor(errorTokenCb: () => void, errorCb: (data: ErrorTransformer_model) => void) {
        this._errorTokenCb = errorTokenCb;
        this._errorCb = errorCb;
    }

    errorHandler = (errorResult: ErrorResult): ErrorTransformer_model => {
        const error = errorTransformer(errorResult);

        if (error.errorType === ErrorNumberType.TokenFailed) {
            this._errorTokenCb?.();
        }

        this._errorCb?.(error);

        return error;
    };
}

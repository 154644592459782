import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorLegendComponent } from './color-legend.component';

@NgModule({
    exports: [ColorLegendComponent],
    declarations: [ColorLegendComponent],
    imports: [CommonModule],
})
export class ColorLegendModule {}

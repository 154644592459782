import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { ErrorResult, MoItem, PacketsValueTypeItem, } from '@cityair/modules/login/services/harvester-api/models';
import { StationData } from '@cityair/namespace';
import { isTimelineAqiLowDetail } from '@cityair/utils/utils';
import { GetObserverPacketsResponce } from '../adminPanel/models';
import {
    CityairMapDataTransformerResponse,
    CityScreenTimelineRequest,
    CityScreenTimelineResponse,
    GetMoPacketsApiRequest,
    MoPacketsRequest,
} from './cityairMapModels';
import { objectMonitorDataTransformer } from './cityairMapDataTransformerResponse';
import { csMapDataTransformer } from './cityscreenDataTransformer';
import { DEFAULT_MEASURE_SCHEME } from '@libs/common/consts/default-measure-scheme.const';
import { WindowGlobalVars } from '@cityair/libs/common/api/adminPanel/api';
import { TEMP, WDA, WVA, WVVA } from '@libs/common/consts/substance.consts';
import { AuthService } from '@cityair/modules/login/services/auth/auth.service';
import { ErrorTransformer_model } from '../dataProvider/DataProvider';
import { Store } from '@ngrx/store';
import { setErrorMessage, showInfo } from '@cityair/modules/core/store/actions';
import { checkIsShowInfoError } from '@cityair/utils/check-is-show-info-error';
import { copyObj } from '@libs/common/utils/utils';
import { ApiModels } from '../api-models';
import { CityairMapProvider } from './cityairMapProvider';
import { CheckLongPending } from './check-long-pending';
import { AqiType } from '@libs/common/enums/aqi.type';

declare const window: WindowGlobalVars;

@Injectable()
export class CityscreenMapApi extends ApiModels {
    _packetsValueTypes: PacketsValueTypeItem[];
    _moItems: MoItem[];

    constructor(
        private authService: AuthService,
        private store: Store,
        private cityairMapProvider: CityairMapProvider
    ) {
        super(
            () => {
                this.authService.logOut(false);
            },
            (error: ErrorTransformer_model) => {
                if (checkIsShowInfoError(error)) {
                    this.store.dispatch(
                        showInfo({
                            messageKey: 'notFoundPost',
                            positionX: 'left',
                            positionY: 'bottom',
                            iconClass: 'error',
                            duration: 10000,
                            showCloseIcon: true,
                            size: 'lg',
                        })
                    );
                } else {
                    this.store.dispatch(setErrorMessage({ msg: error.message }));
                }
            }
        );
    }

    getMoPackets = (
        prop: GetMoPacketsApiRequest,
        measureScheme?: MeasureScheme
    ): Promise<StationData> =>
        new Promise((resolve, reject) => {
            // TODO delete after refactor backend measureScheme
            const isMpc = measureScheme === MeasureScheme.mpc;

            this.cityairMapProvider.GetMapPacketsCityScreen(
                <MoPacketsRequest>{
                    MoId: prop.id,
                    TimeBegin: prop.timeBegin ? moment(prop.timeBegin) : undefined,
                    TimeEnd: prop.timeEnd ? moment(prop.timeEnd) : undefined,
                    IntervalType: prop.interval,
                    LastTimestamp: prop.lastPacketId || null,
                    // TODO refactor when MPC need to EN
                    MeasureScheme: isMpc ? MeasureScheme.default : measureScheme,
                    СoncentrationInMPC: isMpc,
                    GroupID: prop.groupId,
                },
                (data: GetObserverPacketsResponce) => {
                    const readyData = objectMonitorDataTransformer(data, this._packetsValueTypes);
                    // TODO incorrect packets crutch
                    let needCorrect = false;

                    window.rawGroupInfo.MonitoringObjects.find((mo) => {
                        if (window.packetsCrutch.isTargetGroup && mo.MoId === prop.id) {
                            mo.DeviceLinks.find((d) => {
                                if (window.packetsCrutch.ids.includes(d.DeviceId)) {
                                    needCorrect = true;
                                    return true;
                                }
                            });

                            return true;
                        }
                    });

                    if (needCorrect) {
                        const temp = Object.keys(readyData.measuresInfo).find(
                            (key) => readyData.measuresInfo[key].type === TEMP
                        );
                        const vWind = Object.keys(readyData.measuresInfo).find(
                            (key) => readyData.measuresInfo[key].type === WVVA
                        );
                        const wda = Object.keys(readyData.measuresInfo).find(
                            (key) => readyData.measuresInfo[key].type === WDA
                        );
                        const wva = Object.keys(readyData.measuresInfo).find(
                            (key) => readyData.measuresInfo[key].type === WVA
                        );

                        readyData.packets.forEach((p) => {
                            if (p.time > window.packetsCrutch.endTime)
                                return;

                            const isIncorrectPacket =
                                p.values[temp] > window.packetsCrutch.maxTemp ||
                                Math.abs(p.values[vWind]) > window.packetsCrutch.maxWindModule;

                            if (isIncorrectPacket) {
                                p.values[temp] = null;
                                p.values[vWind] = null;
                                p.values[wda] = null;
                                p.values[wva] = null;
                            }
                        });
                    }

                    // TODO for demo indore(atmos)
                    if (window.packetsCrutch.isTargetIndianGroup) {
                        readyData.packets.forEach((p) => {delete p.values[AqiType.instant]});
                    }

                    resolve(readyData);
                },
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        });

    getCSTimelineInfo = (
        timeBegin: number,
        timeEnd: number,
        GroupId: number,
        measureScheme?: MeasureScheme
    ): Promise<CityairMapDataTransformerResponse> => {
        const checkLongPending = new CheckLongPending();

        return new Promise((resolve, reject) => {
            // TODO delete after refactor backend measureScheme
            const isMpc = measureScheme === MeasureScheme.mpc;

            this.cityairMapProvider.GetCityScreenTimeline(
                <CityScreenTimelineRequest>{
                    TimeInterval: isTimelineAqiLowDetail(timeBegin, timeEnd) ? 2 : 1,
                    // TODO убрать subtract когда сервак будет правильно время отдавать
                    TimeBegin: moment(timeBegin).subtract(1, 'hour'),
                    TimeEnd: moment(timeEnd),
                    GroupId,
                    MeasureScheme: isMpc ? DEFAULT_MEASURE_SCHEME : measureScheme,
                    СoncentrationInMPC: isMpc,
                },
                (data: CityScreenTimelineResponse) => {
                    this._moItems = copyObj(data.MoTimelines);
                    this._packetsValueTypes = copyObj(data.PacketValueTypes);

                    checkLongPending.end();

                    // TODO incorrect packets crutch
                    if (window.packetsCrutch.isTargetGroup) {
                        const tempVT = 1;
                        const vWindVT = 89;
                        const wdaVT = 91;
                        const wvaVT = 94;

                        const arrVT = [tempVT, vWindVT, wdaVT, wvaVT];

                        data.LocationTimelines.forEach((location) => {
                            location.Timeline.forEach((t) => {
                                if (new Date(t.Time).getTime() > window.packetsCrutch.endTime)
                                    return;

                                const tempVal = t.Data.find((d) => d.VT === tempVT)?.V;
                                const windVal = t.Data.find((d) => d.VT === vWindVT)?.V;
                                const isIncorrectPacket =
                                    tempVal > window.packetsCrutch.maxTemp ||
                                    Math.abs(windVal) > window.packetsCrutch.maxWindModule;

                                if (isIncorrectPacket) {
                                    t.Data.forEach((d) => {
                                        if (arrVT.includes(d.VT)) d.V = null;
                                    });
                                }
                            });
                        });
                    }

                    // TODO for demo indore(atmos)
                    if (window.packetsCrutch.isTargetIndianGroup) {
                        data.LocationTimelines.forEach((location) => {
                            location.Timeline.forEach((t) => {
                                delete t.InstantAqi;
                            });
                        });

                        data.MoTimelines.forEach((mo) => {
                            mo.Timeline.forEach((t) => {
                                delete t.InstantAqi;
                            });
                        });
                    }

                    resolve(csMapDataTransformer(data, timeBegin, timeEnd));
                },
                (errorResult: ErrorResult) => {
                    checkLongPending.end();
                    reject(this.errorHandler(errorResult));
                }
            );
        });
    };
}

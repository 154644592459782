import { Routes } from '@angular/router';

import { NETWORK_PAGES } from './namespace';

import { PostsListComponent } from './components/posts-list/posts-list.component';
import { PostEditComponent } from './components/post-edit/post-edit.component';
import { PostCreateComponent } from './components/post-create/post-create.component';
import { PostReportComponent } from './components/post-report/post-report.component';
import { PostReportTzaComponent } from './components/post-report-tza/post-report-tza.component';
import { DeviceReportComponent } from './components/device-report/device-report.component';
import { PostsAndDevicesHeaderComponent } from './components/posts-and-devices-header/posts-and-devices-header.component';
import { DevicesListComponent } from './components/devices-list/devices-list.component';
import { DetailsHeaderComponent } from './components/details-header/details-header.component';

const postRoutes: Routes = [
    {
        path: '',
        redirectTo: NETWORK_PAGES.postsList,
        pathMatch: 'full',
    },
    {
        path: NETWORK_PAGES.postsList,
        component: PostsListComponent,
    },
    {
        path: NETWORK_PAGES.devicesList,
        component: DevicesListComponent,
    },
];

export const networksRoutes: Routes = [
    {
        path: '',
        component: PostsAndDevicesHeaderComponent,
        children: postRoutes,
    },
    {
        path: NETWORK_PAGES.postsList + '/' + NETWORK_PAGES.postCreate,
        component: PostCreateComponent,
    },
    {
        path: NETWORK_PAGES.devicesList + '/' + NETWORK_PAGES.details + '/:id',
        component: DetailsHeaderComponent,
    },
    {
        path: NETWORK_PAGES.postsList + '/' + NETWORK_PAGES.details + '/:id',
        component: DetailsHeaderComponent,
    },
    {
        path: NETWORK_PAGES.postsList + '/' + NETWORK_PAGES.postReportTza + '/:id',
        component: PostReportTzaComponent,
    },
    {
        path:
            NETWORK_PAGES.devicesList +
            '/' +
            NETWORK_PAGES.details +
            '/' +
            NETWORK_PAGES.postReportTza +
            '/:id',
        component: PostReportTzaComponent,
    },
    {
        path:
            NETWORK_PAGES.postsList +
            '/' +
            NETWORK_PAGES.details +
            '/' +
            NETWORK_PAGES.postReportTza +
            '/:id',
        component: PostReportTzaComponent,
    },
    {
        path: NETWORK_PAGES.postsList + '/' + NETWORK_PAGES.postEdit + '/:id',
        component: PostEditComponent,
    },
    {
        path:
            NETWORK_PAGES.devicesList +
            '/' +
            NETWORK_PAGES.details +
            '/' +
            NETWORK_PAGES.postEdit +
            '/:id',
        component: PostEditComponent,
    },
    {
        path:
            NETWORK_PAGES.postsList +
            '/' +
            NETWORK_PAGES.details +
            '/' +
            NETWORK_PAGES.postEdit +
            '/:id',
        component: PostEditComponent,
    },
    {
        path: NETWORK_PAGES.postsList + '/' + NETWORK_PAGES.postReport + '/:id',
        component: PostReportComponent,
    },
    {
        path:
            NETWORK_PAGES.devicesList +
            '/' +
            NETWORK_PAGES.details +
            '/' +
            NETWORK_PAGES.postReport +
            '/:id',
        component: PostReportComponent,
    },
    {
        path:
            NETWORK_PAGES.postsList +
            '/' +
            NETWORK_PAGES.details +
            '/' +
            NETWORK_PAGES.postReport +
            '/:id',
        component: PostReportComponent,
    },
    {
        path: NETWORK_PAGES.devicesList + '/' + NETWORK_PAGES.deviceReport + '/:id',
        component: DeviceReportComponent,
    },
    {
        path:
            NETWORK_PAGES.devicesList +
            '/' +
            NETWORK_PAGES.details +
            '/' +
            NETWORK_PAGES.deviceReport +
            '/:id',
        component: DeviceReportComponent,
    },
    {
        path:
            NETWORK_PAGES.postsList +
            '/' +
            NETWORK_PAGES.details +
            '/' +
            NETWORK_PAGES.deviceReport +
            '/:id',
        component: DeviceReportComponent,
    },
];

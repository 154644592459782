import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { IReportKind, REPORTS_PAGES } from '@cityair/modules/reports/models';
import { CheckboxItem } from '@libs/common/types/checkbox-item';
import { isRU, LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import { MoItemsDataToExcelRequestProps, MonitoringObject } from '@cityair/namespace';
import { TabModel } from '@libs/common/types/tab-model';
import { postsListLabels } from '@cityair/modules/notifications/notifications.utils';
import { ActivatedRoute, Router } from '@angular/router';
import { NgLocalization } from '@angular/common';
import * as moment from 'moment';
import { saveDataToDisk } from '@cityair/utils/utils';
import { ErrorTransformer_model } from '@cityair/libs/common/api/dataProvider/DataProvider';
import { AdminPanelApi } from '@cityair/libs/common/api/adminPanel/api';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FINISH_DATE_REPORTS, START_DATE_REPORTS } from '@cityair/modules/reports/constant';
import { Store } from '@ngrx/store';
import { selectMos } from '@cityair/modules/core/store/selectors';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'cityair-download-report',
    templateUrl: './download-report.component.html',
    styleUrls: ['./download-report.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadReportComponent implements OnInit {
    @Input() currentKind: IReportKind;

    public isRu = isRU;
    public TEXTS = TEXTS;
    isDisabledPdk = false;
    public moObj: MonitoringObject[] = [];
    private selectedPost: string;
    isActiveLoader = false;
    targetMo = '';
    errorDownloadMsg = '';

    objectForReportMo: MoItemsDataToExcelRequestProps = {
        timeBegin: new Date(START_DATE_REPORTS).getTime(),
        timeEnd: new Date(FINISH_DATE_REPORTS).getTime(),
        moIds: [],
        interval: 2,
        type: 1,
        unitsType: 1,
        insertPdk: false,
    };

    isShowDropdownForStations = false;
    filterTabsInterval: TabModel[] = [
        {
            title: this.TEXTS.TIME_NAMES.min5,
            type: 1,
        },
        {
            title: this.TEXTS.TIME_NAMES.min20,
            type: 2,
        },
        {
            title: this.TEXTS.TIME_NAMES.hour1,
            type: 3,
        },
        {
            title: this.TEXTS.TIME_NAMES.day1,
            type: 4,
        },
    ];

    currentFilterTabsInterval: TabModel = this.filterTabsInterval[1];

    unitsMeasurementsList: TabModel[] = [
        {
            title: this.TEXTS.ugM,
            type: 1,
        },
        {
            title: this.TEXTS.mgM,
            type: 2,
        },
    ];

    currentUnitsMeasurements: TabModel = this.unitsMeasurementsList[0];
    ReportDownLoadForm: UntypedFormGroup;
    listStations: CheckboxItem[] = [];
    loading = true;
    constructor(
        private fb: UntypedFormBuilder,
        private ngLocalization: NgLocalization,
        public adminPanelApi: AdminPanelApi,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.store
            .select(selectMos)
            .pipe(
                filter((mos) => !!mos.length),
                take(1)
            )
            .subscribe((mos) => {
                this.moObj = mos;
                this.loading = false;
                this.updateList();
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnInit() {
        const name = this.autoGenerateReportName();
        this.ReportDownLoadForm = this.fb.group({
            name: [name],
        });

        this.route.queryParams.pipe(filter((params) => params.post_id)).subscribe((params) => {
            this.selectedPost = params.post_id;
            this.updateList();
        });
    }

    get formName() {
        return this.ReportDownLoadForm.get('name');
    }

    removeFromList(index: number) {
        const label = this.getTags()[index];
        this.listStations.find((s) => {
            if (s.label === label) {
                s.selected = false;
                return true;
            }
        });

        this.listStations = [...this.listStations];
    }

    getTags = () => this.listStations.filter((s) => s.selected).map((s) => s.label);

    postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    setTabsInterval(filterTabsInterval: TabModel) {
        if (filterTabsInterval.type == 1 || filterTabsInterval.type == 3) {
            this.isDisabledPdk = true;
            this.objectForReportMo.insertPdk = false;
        } else {
            this.isDisabledPdk = false;
        }
        this.currentFilterTabsInterval = filterTabsInterval;
        this.objectForReportMo.interval = this.currentFilterTabsInterval.type;
    }

    getUnitMeasurement(unitsMeasurementsList: TabModel) {
        this.currentUnitsMeasurements = unitsMeasurementsList;
        this.objectForReportMo.unitsType = unitsMeasurementsList.type;
    }

    selectedCheckboxText(num: number = 0) {
        const { selected, post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);

        return [selected[category], num, post[category]].join(' ');
    }

    public changeCalendar(time) {
        this.objectForReportMo.timeBegin = time.begin;
        this.objectForReportMo.timeEnd = time.end;
        if (!this.ReportDownLoadForm.controls.name.touched) {
            const newName = this.autoGenerateReportName();
            this.ReportDownLoadForm.controls.name.setValue(newName);
        }
    }

    downloadReportMo = () => {
        this.isActiveLoader = true;
        this.objectForReportMo.moIds = this.listStations
            .filter((s) => s.selected)
            .map((s) => Number(s.id));
        const fileName =
            this.ReportDownLoadForm.get('name')?.value !== ''
                ? this.ReportDownLoadForm.get('name')?.value
                : this.autoGenerateReportName();
        const filenameWithTime = fileName + '_' + moment().format('_YYYYMMDD_HHmm') + '.xlsx';

        this.adminPanelApi
            .SaveMoDataToExcel(this.objectForReportMo)
            .then((data: string) => {
                saveDataToDisk(data, filenameWithTime);
                this.offActiveLoader();
            })
            .catch((errorDownloadMsg: ErrorTransformer_model) => {
                this.errorDownloadMsg = errorDownloadMsg.message;
                this.offActiveLoader();
            });
    };

    private offActiveLoader() {
        setTimeout(() => {
            this.isActiveLoader = false;
            this._changeDetectorRef.markForCheck();
        }, 1000);
    }

    public toggleInsertPdk() {
        if (this.isDisabledPdk === false) {
            this.objectForReportMo.insertPdk = !this.objectForReportMo.insertPdk;
        }
    }

    public close() {
        this.router.navigate([`/${REPORTS_PAGES.reports}/${REPORTS_PAGES.reportsList}`]);
    }

    private autoGenerateReportName() {
        if (this.currentKind) {
            const start = moment(this.objectForReportMo.timeBegin).format('DD.MM.YY');
            const end = moment(this.objectForReportMo.timeEnd).format('DD.MM.YY');
            const name = this.TEXTS.REPORTS.download.fileNameDefault;
            return `${name}_${start}-${end}`;
        }
        return `${this.currentKind?.name}`;
    }

    private updateList() {
        this.listStations = this.moObj.map((s) => ({
            id: s.id,
            label: s.name,
            selected: this.selectedPost ? s.id === +this.selectedPost : true,
        }));
        this._changeDetectorRef.markForCheck();
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { markerState } from '@libs/common/enums/marker-state.enum';
import { AdminDevice } from '@cityair/namespace';
import { TEXTS } from '@libs/common/texts/texts';
import { isFalseNumber } from '@libs/common/utils/utils';

@Component({
    selector: 'monitoring-object-plate',
    templateUrl: 'monitoring-object-plate.component.html',
    styleUrls: ['monitoring-object-plate.component.less'],
})
export class MonitoringObjectPlateComponent {
    @Input() name = '';
    @Input() idMo = null;
    @Input() description?: string;
    @Input() city?: string;
    @Input() value: number = null;
    @Input() valueColor: string;
    @Input() arrDeviceName: AdminDevice[] = [];
    @Input() isActive = false;
    @Input() markerState: markerState;
    @Input() isComplexMode = false;
    @Input() isShowKebabMenu = true;
    @Input() isNotConnectedDevices = true;
    @Input() isNoDataSources = true;

    @Output() cbClickForStaticTagsDevice = new EventEmitter<{ type: string; id: any }>();
    @Output() cbClickForCard = new EventEmitter<void>();
    @Output() openActionsPopup = new EventEmitter<{ target: HTMLElement; positionTop: number }>();

    TEXTS = TEXTS;
    isFalseNumber = isFalseNumber;

    clickForStaticTagsPost(idMO: number) {
        this.cbClickForStaticTagsDevice.emit({ type: 'MO', id: idMO });
    }

    clickForCard() {
        this.cbClickForCard.emit();
    }

    clickForStaticTagsDevice(serialNumber: string) {
        const typeDevice = this.arrDeviceName.find(
            (adn) => adn.serialNumber === serialNumber
        ).sourceId;

        if (typeDevice === 3) {
            this.cbClickForStaticTagsDevice.emit({ type: 'device', id: serialNumber });
        }
    }

    openActions({ target, positionTop }) {
        this.openActionsPopup.emit({
            target,
            positionTop,
        });
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { IReportKind } from '../../../models';
import { TEXTS, isRU } from '@libs/common/texts/texts';
import { Observable } from 'rxjs';
import {
    DOWNLOAD_REPORT_KIND,
    DOWNLOAD_SHOW_GROUP_IDS,
    MARKETING_REPORT_KIND,
    MARKETING_SHOW_GROUP_IDS,
} from '@cityair/modules/reports/constant';

@Component({
    selector: 'report-kind-view',
    templateUrl: './report-kind-view.component.html',
    styleUrls: ['./report-kind-view.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportKindViewComponent implements OnChanges {
    @Input() loadingKind: Observable<boolean>;
    @Input() reportsKind: Observable<IReportKind[]>;
    @Input() groupId: number;

    @Output() addReport = new EventEmitter<IReportKind>();

    public titleText = TEXTS.REPORTS.createReportTitle;
    public buttonText = TEXTS.REPORTS.generateReport;
    public showAll = false;
    public displayCount = 4;
    public isRu = isRU;
    public marketingReportKind: IReportKind = MARKETING_REPORT_KIND;
    public downloadReportKind: IReportKind = DOWNLOAD_REPORT_KIND;
    public showMarketing = false;
    public showDownload = false;
    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.groupId) {
            if (this.groupId && MARKETING_SHOW_GROUP_IDS.indexOf(+this.groupId) >= 0) {
                this.showMarketing = true;
                this.displayCount--;
            }
            if (this.groupId && DOWNLOAD_SHOW_GROUP_IDS.indexOf(+this.groupId) >= 0) {
                this.showDownload = true;
                this.displayCount--;
            }
        }
    }

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        this.showAll = false;
    }

    add(kind: IReportKind) {
        this.addReport.emit(kind);
    }

    toggleShowMore() {
        this.showAll = !this.showAll;
    }
}

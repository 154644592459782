import { Component } from '@angular/core';

import { MessageAPIResponseService } from './message-api-response.service';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'message-api-response',
    template: `
        <div
            *showDirective="!!srv.message"
            [ngClass]="{
                'api-message': true,
                'api-message--error': srv.type === 'error',
                'api-message--success': srv.type === 'success'
            }"
        >
            <div class="api-message__close" (click)="close()"></div>
            <div class="api-message__content">
                <div class="api-message__title" *ngIf="srv.type === 'error'">
                    {{ texts.error }}
                </div>
                <div class="api-message__message">{{ srv.message }}</div>
                <div class="api-message__description" *ngIf="srv.description">
                    {{ srv.description }}
                </div>
            </div>
        </div>
    `,
    styleUrls: ['message-api-response.component.less'],
})
export class MessageAPIResponse {
    texts = TEXTS.NOTIFICATIONS;

    constructor(readonly srv: MessageAPIResponseService) {}

    close() {
        this.srv.clearMsg();
    }
}

<div class="system-settings scroll">
    <div *ngIf="!isAWS" class="system-settings__item system-settings__item_type-select">
        <input-dropdown
            [ngClass]="{
                'dropdown-lang-icon': true,
                'dropdown-lang-icon__flag-ru': lang === 'ru',
                'dropdown-lang-icon__flag-en': lang === 'en'}"
            data-cy="settings-langauge"
            [inputText]="inputTextLanguage"
            [label]="labelLanguage"
            [(showDropdown)]="showDropdownLanguage"
            (clickOutside)="showDropdownLanguage = false"
        >
            <selectbox-radio-lang
                [(listItems)]="LanguageItems"
                (listItemsChange)="updateLanguage($event)"
            ></selectbox-radio-lang>
        </input-dropdown>
    </div>

    <div class="system-settings__item system-settings__item_type-tabs">
        <div class="system-settings__item-title">{{TEXTS.CONFIG.units}}</div>
        <switch-item
            *ngIf="!isAWS; else aws"
            [currentTab]="findSelected()"
            [tabs]="tabs"
            (action)="onSelectScheme($event)"
        ></switch-item>

        <ng-template #aws>
            <div class="fahrenheit_block">
                <switch-item
                    [currentTab]="findSelectedAWS()"
                    [tabs]="tabsAWS"
                    (action)="findSelectedAWS() !== $event ? onSelectScheme($event) : ''"
                ></switch-item>

                <span class="fahrenheit_block__checkbox" (click)="selectFahrenheit()">
                    <cs-checkbox
                        [checked]="scheme === MeasureScheme.usa_default"
                        [disabled]="scheme !== MeasureScheme.usa_default && scheme !== MeasureScheme.default"
                    ></cs-checkbox>
                    <span
                        [ngClass]="{
                        'fahrenheit_block__text': true,
                        'fahrenheit_block__text-disabled': scheme !== MeasureScheme.usa_default && scheme !== MeasureScheme.default
                    }"
                        >{{TEXTS.MEASURES_USA[TEMP]}}</span
                    >
                </span>
            </div>
        </ng-template>
    </div>

    <div *ngIf="isAWS" class="system-settings__info-wrapper">
        <div class="system-settings__info">
            <svg
                class="system-settings__info-icon"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="20"
                    y="20"
                    width="20"
                    height="20"
                    rx="10"
                    transform="rotate(-180 20 20)"
                    fill="url(#paint0_linear_9546_68457)"
                />
                <path
                    d="M10 15C10.5523 15 11 14.5523 11 14V10C11 9.44772 10.5523 9 10 9C9.44772 9 9 9.44772 9 10V14C9 14.5523 9.44772 15 10 15Z"
                    fill="white"
                />
                <path
                    d="M10 7C10.5523 7 11 6.55228 11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44772 7 10 7Z"
                    fill="white"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_9546_68457"
                        x1="40"
                        y1="20"
                        x2="22.0624"
                        y2="41.7045"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#6795ED" />
                        <stop offset="0.368408" stop-color="#7387E6" />
                        <stop offset="1" stop-color="#9369D7" />
                    </linearGradient>
                </defs>
            </svg>
            <div
                class="system-settings__info-description"
                [innerHTML]="TEXTS.CONFIG.measureSchemeDescriptionAWS"
            ></div>
        </div>
    </div>

    <!--    <div class="system-settings__item system-settings__item_type-select">-->
    <!--        <input-dropdown-->
    <!--            [inputText]="inputTextTimeZone"-->
    <!--            [label]="labelTimeZone"-->
    <!--            [(showDropdown)]="showDropdownTimeZone"-->
    <!--            (clickOutside)="showDropdownTimeZone = false">-->
    <!--            <selectbox-radio [(listItems)]="TimeZoneItems" (listItemsChange)="updateTimeZone($event)"></selectbox-radio>-->
    <!--        </input-dropdown>-->
    <!--    </div>-->
</div>

<div class="settings-footer">
    <div class="settings-footer__description" [innerHTML]="TEXTS.CONFIG.saveDescription"></div>
    <div class="settings-footer__btn-cancel">
        <btn-cancel [disabled]="false" (click)="back.emit(backEmit)"
            >{{TEXTS.COMMON.cancel}}</btn-cancel
        >
    </div>
    <div class="settings-footer__btn-accept" data-cy="settings-footer-btn-accept">
        <btn-accept
            [disabled]="_lang === lang && (store.select(selectMeasureScheme) | async) === scheme"
            [isLoading]="false"
            (cbClick)="saveSystemSettings()"
            >{{TEXTS.COMMON.save}}</btn-accept
        >
    </div>
</div>

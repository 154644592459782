<div
    [ngClass]="{
        input_for_dropdown__block: true,
        'input_for_dropdown__block-active': isOpenDropdown,
        'input_for_dropdown__block-error': isError,
        'input_for_dropdown__block-disable': isDisable
    }"
    (click)="toggleShow()"
>
    <div class="input_for_dropdown__text">
        <span *ngIf="inputValue; else noSelect" [innerHTML]="inputValue"></span>
        <ng-template #noSelect>
            {{ texts.LIST_USERS.noSelect }}
        </ng-template>
    </div>
    <div class="input_for_dropdown__title" [innerHTML]="label"></div>
    <ca-arrow
        class="input_for_dropdown__wrapper_arrow"
        size="small"
        [direction]="isOpenDropdown ? 'up' : 'down'"
    ></ca-arrow>
</div>

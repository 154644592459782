<div class="plumes-run-summary">
    <div class="plumes-run-summary__evaluated">
        <div class="plumes-run-summary__subtitle">
            {{ textPlumes.runResults }}
        </div>
        <div class="plumes-run-summary__item" *ngIf="runFinished">
            {{ textPlumes.resultsAt }} {{ formatDate(runFinished) }}
        </div>
    </div>
    <div class="plumes-run-summary__forecast">
        <div class="plumes-run-summary__subtitle">
            {{ textPlumes.forecastOn }}
        </div>
        <div
            class="plumes-run-summary__item plumes-run-summary__item-bold"
            *ngIf="dateStart && dateEnd"
        >
            {{ dateStart.isSame(dateEnd, 'day') ? formatTime(dateStart) : formatDate(dateStart) }} –
            {{ formatDate(dateEnd) }}
        </div>
    </div>
</div>

import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PlumesPage } from '@cityair/modules/plumes/components/plumes-page/plumes-page.component';
import { Notifications } from '@cityair/modules/notifications/components/notifications/notifications.component';
import { PostsAndDevicesPageComponent } from '@cityair/modules/posts-and-devices/components/posts-and-devices-page/posts-and-devices-page.component';
import { networksRoutes } from '@cityair/modules/posts-and-devices/posts-and-devices.route';
import { IndoorMainComponent } from '@cityair/modules/indoor/components/indoor-main/indoor-main.component';
import { reportsRoutes } from '@cityair/modules/reports/reports-routing';
import { forecastRoutes } from '@cityair/modules/forecast/forecast-routing';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { GlobalMapComponent } from './components/global-map/global-map.component';
import { IndoorWidgetComponent } from '@cityair/modules/indoor-widget/indoor-widget.component';
import { UsersPage } from '@cityair/modules/users/components/users-page/users-page.component';
import { ConfigPanelComponent } from '@cityair/modules/config-menu/config.component';
import { ResetPanelStateGuard } from './guards/reset-panel-state.guard';
import { AnalyticsPage } from '@cityair/modules/analytics/components/analytics-page/analytics-page.component';

export const cityScreenPageRoutes: Routes = [
    {
        path: MAIN_PAGES.users,
        component: UsersPage,
    },
    {
        path: MAIN_PAGES.analytics,
        component: AnalyticsPage,
    },
    {
        path: MAIN_PAGES.plumes,
        canActivate: [ResetPanelStateGuard],
        component: PlumesPage,
    },
    {
        path: MAIN_PAGES.indoor,
        component: IndoorMainComponent,
    },
    {
        path: MAIN_PAGES.notifications,
        component: Notifications,
    },
    {
        path: MAIN_PAGES.settings,
        component: ConfigPanelComponent,
    },
    {
        path: MAIN_PAGES.networks,
        component: PostsAndDevicesPageComponent,
        children: networksRoutes,
    },
    {
        path: MAIN_PAGES.reports,
        children: reportsRoutes,
    },
    {
        path: MAIN_PAGES.forecast,
        children: forecastRoutes,
    },
    {
        path: MAIN_PAGES.global,
        component: GlobalMapComponent,
    },
    {
        path: MAIN_PAGES.indoorWidget,
        component: IndoorWidgetComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(cityScreenPageRoutes)],
    exports: [RouterModule],
})
export class CityscreenPageRoutingModule {}

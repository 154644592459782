<div class="notifications_container notifications_measurements scroll" (scroll)="onScroll($event)">
    <ca-list-header
        [text]="textsNotification.activeSubscriptions"
        [itemsCount]="(stateService.lists.active$ | async).length"
    >
        <button-add
            [text]="textsNotification.addSubscription"
            (action)="addSubscription()"
        ></button-add>
    </ca-list-header>

    <div
        class="notifications__empty"
        *ngIf="(stateService.lists.selected$ | async).length === 0; else subscriptionsList"
    >
        <div class="notifications_tips">
            <div class="notifications_tips__img notifications_tips__img-measurements"></div>
            <div class="notifications_tips__text">
                <div
                    class="notifications_tips__text-bold"
                    [innerHTML]="textsNotification.measuresDescription1"
                ></div>
                <div
                    class="notifications_tips__text-small"
                    [innerHTML]="textsNotification.addSubscriptionHint"
                ></div>
            </div>
        </div>
    </div>
    <ng-template #subscriptionsList>
        <div class="notifications__subscriptions-list">
            <subscription-card
                *ngFor="let sub of stateService.lists.active$ | async"
                [subscription]="sub"
            >
                <subscription-card-measurements
                    [subscription]="sub"
                ></subscription-card-measurements>
            </subscription-card>

            <subscription-card
                *ngFor="let sub of stateService.lists.deactivated$ | async"
                [subscription]="sub"
            >
                <subscription-card-measurements
                    [subscription]="sub"
                ></subscription-card-measurements>
            </subscription-card>
        </div>
    </ng-template>
</div>

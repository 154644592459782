import { Component, EventEmitter, Output } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { WindowGlobalVars } from '@cityair/namespace';

declare const window: WindowGlobalVars;

@Component({
    selector: 'logout',
    templateUrl: 'logout.component.html',
    styleUrls: ['logout.component.less'],
})
export class Logout {
    @Output() handleLogout = new EventEmitter<void>();

    getUser = () => (window.JS_CP_LOGIN ? window.JS_CP_LOGIN.substring(0, 2) : '');

    TEXTS = TEXTS;
    isOpenSelectLang = false;

    logout() {
        this.handleLogout.emit();
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgLocalization } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { formatDayMonth, formatDayMonthYear } from '@cityair/config';
import {
    IReport,
    IReportKind,
    REPORT_STATUS,
    Reports,
    REPORTS_PAGES,
} from '@cityair/modules/reports/models';
import { SearchInputBasicComponent } from '@libs/shared-ui/components/search-input-basic/search-input-basic.component';
import { ReportsService } from '@cityair/modules/reports/services/reports.service';
import { ReportKindService } from '@cityair/modules/reports/services/report-kind.service';
import { FINISH_DATE_REPORTS, START_DATE_REPORTS } from '@cityair/modules/reports/constant';
import {
    addStatusMessage,
    downloadReport,
    downloadSuccess,
    setCurrentKind,
    setSortingReportList,
    updateDateRange,
    updateReportError,
    updateReportSuccess,
} from '@cityair/modules/reports/store/actions';
import { Store } from '@ngrx/store';
import { UpdaterService } from '../../services/updater.service';
import {
    needReloadData,
    renameReportError,
    renameReportSuccess,
    selectDownLoadLink,
    selectLastSorting,
    selectParamsReport,
} from '../../store/selectors';
import { take, takeUntil } from 'rxjs/operators';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import { TEXTS, LANGUAGE } from '@libs/common/texts/texts';
@Component({
    selector: 'cityscreen-reports-list',
    templateUrl: './reports-list.component.html',
    styleUrls: ['./reports-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: ANIMATION_OPACITY,
})
export class ReportsListComponent implements OnInit, OnDestroy {
    public groupLoading = true;
    public isLoaded = false;
    public loading = true;
    public reports: IReport[];
    public reportsKind$: Observable<IReportKind[]>;
    public loadingKind$: Observable<boolean>;
    public translateText = TEXTS.REPORTS;
    public searchQuery = '';
    public sortDirection = -1;
    public sortField: string;
    public begin: number;
    public end: number;
    public offsetTz = true;
    public initIntervalText: string;
    public intervalText: string;
    public timeIsInit = true;
    public groupId: number;
    public postList: { [key: number]: string };

    private popupOpenerElement: HTMLElement;
    public isShowMenu = false;
    public isShowDeletePopup = false;
    public isShowRenamePopup = false;
    public isShowDeleteCommentPopup = false;
    public isLoadingRename = false;
    public menuPositionTop = 0;
    public menuPositionLeft = 0;
    public idTargetReport: string;
    public currentReport: Reports;
    public editMode: string;

    public ngDestroyed$ = new Subject<void>();
    @ViewChild('searchInput') searchInputComponent: SearchInputBasicComponent;
    @ViewChild('cardMenu', { static: true }) cardMenu: TemplateRef<HTMLDivElement>;

    constructor(
        private router: Router,
        private reportService: ReportsService,
        private reportKindService: ReportKindService,
        private store: Store,
        private ngLocalization: NgLocalization,
        private _changeDetectorRef: ChangeDetectorRef,
        private _updaterService: UpdaterService,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        public popupProvider: OffPanelPopupService
    ) {
        this.reportService.setFilter({ name: null });
        this.reportService.filteredEntities$
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.reports = data;
                this._changeDetectorRef.markForCheck();
            });
        this.reportService.loaded$.pipe(takeUntil(this.ngDestroyed$)).subscribe((data) => {
            this.isLoaded = data;
            this._changeDetectorRef.markForCheck();
        });
        this.reportsKind$ = reportKindService.entities$;
        this.loadingKind$ = this.reportKindService.loading$;

        this.setInitTime();
        this.initState();
        this.initIntervalText = this.getIntervalText(true);
    }

    ngOnInit(): void {
        this.reportService.clearCache();
    }

    ngOnDestroy(): void {
        this._updaterService.stop();
        this.ngDestroyed$.next();
    }

    public setSortingCb(sortCb: string): void {
        this.store.dispatch(setSortingReportList({ payload: sortCb }));
        if (this.sortField === sortCb) {
            this.sortDirection *= -1;
        } else {
            this.sortField = sortCb;
        }
    }

    public identifyReport(index, item) {
        return item.id;
    }

    public addReport(reportKind: IReportKind) {
        this.store.dispatch(setCurrentKind(reportKind));
        this.router.navigate([`/${REPORTS_PAGES.reports}/${REPORTS_PAGES.add}`, reportKind.id]);
    }

    private getReports() {
        this.loading = true;
        const params = this.getParams();
        if (params) {
            this.reportService
                .getWithQuery(params)
                .pipe(takeUntil(this.ngDestroyed$))
                .subscribe((data) => {
                    this.loading = false;
                    this._changeDetectorRef.markForCheck();
                });
        }
    }

    private getReportKinds() {
        this.reportKindService.getWithQuery({ group_id: this.groupId.toString() });
    }

    public textChangeIn($event) {
        this.searchQuery = $event;
        const query = this.searchQuery ? this.searchQuery.toLowerCase().trim() : null;
        this.reportService.setFilter({ name: query });
    }

    public getIntervalText(isInit?: boolean) {
        let begin = moment(this.begin);
        let end = moment(this.end).endOf('day');
        if (isInit) {
            begin = moment(START_DATE_REPORTS);
            end = moment(FINISH_DATE_REPORTS);
        }
        const textStart = formatDayMonth(begin);
        const textEnd = formatDayMonthYear(end);

        return `${textStart} - ${textEnd}`;
    }

    public clearFilter() {
        if (this.isDisabledFilter()) {
            return;
        }

        if (this.searchQuery) {
            this.searchInputComponent?.form.reset();
            this.searchQuery = null;
            this.reportService.setFilter({ name: null });
        }

        if (!this.timeIsInit) {
            this.setInitTime();
            this.timeIsInit = true;
            this.getReports();
        }
    }

    public isDisabledFilter() {
        return (this.searchQuery === null || this.searchQuery === '') && this.timeIsInit;
    }

    public isSearchEmpty() {
        return this.searchQuery === null || this.searchQuery === '';
    }

    public getSearchText(num: number) {
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        return `${this.translateText.countReportText(num)} ${this.translateText.reports[category]}`;
    }

    public changeCalendar(time) {
        this.begin = time.begin;
        this.end = time.end;
        this.intervalText = this.getIntervalText();
        this.store.dispatch(
            updateDateRange({
                payload: {
                    startDate: new Date(this.begin).toISOString(),
                    finishDate: new Date(this.end).toISOString(),
                },
            })
        );
        this.timeIsInit = this.isInitTime();
        this.reportService.clearCache();
        this.getReports();
    }

    download(obj: Reports) {
        this.store.dispatch(downloadReport(obj));
    }

    save(obj) {
        this.isLoadingRename = true;
        const report: IReport = {
            id: obj.id,
            name: obj.name,
            comment: obj.comment,
        };
        this.reportService.update(report);
    }

    editComment(obj: Reports) {
        this.currentReport = obj;
        this.isShowRenamePopup = true;
        this.editMode = 'comment';
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.cardMenu, () => (this.isShowMenu = false));
    }

    deleteComment(obj: Reports) {
        this.currentReport = obj;
        this.isShowDeleteCommentPopup = true;
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.cardMenu, () => (this.isShowMenu = false));
    }

    private getParams() {
        if (!this.begin || !this.end) {
            return null;
        }
        return {
            start_date: new Date(this.begin).toISOString(),
            finish_date: new Date(this.end).toISOString(),
            group_id: this.groupId.toString(),
        };
    }

    private setInitTime(time?): void {
        if (!time || !time.startDate || !time.finishDate) {
            this.begin = new Date(START_DATE_REPORTS).getTime();
            this.end = new Date(FINISH_DATE_REPORTS).getTime();
        } else {
            this.begin = new Date(time.startDate).getTime();
            this.end = new Date(time.finishDate).getTime();
        }
        this.intervalText = this.getIntervalText();
        this.timeIsInit = this.isInitTime();
    }

    private isInitTime(): boolean {
        return this.initIntervalText === this.intervalText;
    }

    @HostListener('window:blur', ['$event'])
    onBlur(event): void {
        this._updaterService.stop();
    }

    @HostListener('window:focus', ['$event'])
    onFocus(event): void {
        this._updaterService.init();
    }

    private downloadByLink(obj) {
        const link = this.renderer.createElement('a');
        this.renderer.setAttribute(link, 'download', obj.report.name + '.' + obj.report.format);
        this.renderer.setAttribute(link, 'href', obj.url);
        this.renderer.appendChild(this.elementRef.nativeElement, link);
        link.click();
        this.renderer.removeChild(this.elementRef.nativeElement, link);
        this.store.dispatch(downloadSuccess({ payload: null }));
    }

    clickMenu({ target, positionTop, positionLeft, report }) {
        this.currentReport = report;
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.cardMenu, () => (this.isShowMenu = false));
        this.popupOpenerElement = target;
        this.menuPositionTop = positionTop - 45;
        this.menuPositionLeft = positionLeft - 240;
        this.isShowMenu = true;
    }

    closeMenu(e: Event) {
        if (this.isShowMenu && this.popupOpenerElement !== e.target) {
            this.isShowMenu = false;
        }
    }

    closePopup(e: any) {
        if (e.target.id !== 'menuBtn') {
            this.closeMenu(e);
        }
    }

    showPopup(target, type) {
        this.closePopup(target);
        this.isShowDeletePopup = true;
    }

    showRenamePopup(target) {
        this.closePopup(target);
        this.isShowRenamePopup = true;
        this.editMode = 'name';
    }

    deleteAccept() {
        if (!this.currentReport) {
            return;
        }

        this.isShowDeletePopup = false;
        this.reportService.delete(this.currentReport.id).subscribe((id) => {
            if (id) {
                this.store.dispatch(
                    addStatusMessage({
                        payload: {
                            id: +id,
                            status: REPORT_STATUS.READY,
                            title: this.translateText.messageDeleteSuccess,
                        },
                    })
                );
            }
        });
    }

    deleteCommentAccept() {
        if (!this.currentReport) {
            return;
        }

        this.isShowDeleteCommentPopup = false;
        const report = {
            id: this.currentReport.id,
            name: this.currentReport.name,
            comment: '',
        };
        this.save(report);
    }

    deleteCancel() {
        this.isShowDeletePopup = false;
        this.isShowDeleteCommentPopup = false;
    }

    private initState() {
        this.store
            .select(selectParamsReport)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                if (data && data.groupId) {
                    this.groupLoading = false;
                    this.groupId = data.groupId;
                    this.setInitTime(data);
                    this.getReports();
                    this.getReportKinds();
                    this.postList = data.postList;
                    this._updaterService.init();
                    this._changeDetectorRef.markForCheck();
                }
            });
        this.store
            .select(needReloadData)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                if (data && this.groupId) {
                    this.getReports();
                    this.getReportKinds();
                    this._changeDetectorRef.markForCheck();
                }
            });
        this.store
            .select(selectLastSorting)
            .pipe(take(1))
            .subscribe((data) => {
                if (data) {
                    this.setSortingCb(data);
                    this._changeDetectorRef.markForCheck();
                }
            });
        this.store
            .select(selectDownLoadLink)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((obj) => {
                if (obj) {
                    this.downloadByLink(obj);
                }
            });
        this.store
            .select(renameReportError)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.isLoadingRename = false;
                if (data && data.error !== null && data.report !== null) {
                    this.store.dispatch(
                        addStatusMessage({
                            payload: {
                                status: REPORT_STATUS.ERROR,
                                id: data.report.id,
                                title: this.translateText.messagesEdit[REPORT_STATUS.ERROR].title,
                                text: this.translateText.messagesEdit[REPORT_STATUS.ERROR].text(
                                    data.report.name
                                ),
                            },
                        })
                    );
                    this.isShowRenamePopup = false;
                    this.store.dispatch(updateReportError({ payload: null }));
                    this._changeDetectorRef.markForCheck();
                }
            });
        this.store
            .select(renameReportSuccess)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.isLoadingRename = false;
                if (data && data.id) {
                    this.store.dispatch(
                        addStatusMessage({
                            payload: {
                                status: REPORT_STATUS.READY,
                                id: data.id,
                                title: this.translateText.messagesEdit[REPORT_STATUS.READY].title,
                            },
                        })
                    );
                    this.isShowRenamePopup = false;
                    this.store.dispatch(updateReportSuccess({ payload: null }));
                    this._changeDetectorRef.markForCheck();
                }
            });
    }
}

<div class="page-content__wrapper">
    <div class="admin_panel__fixed_block" *ngIf="(city$ | async) === null">
        <div class="admin_panel__title">
            <header-page [textObject]="{ titlePage: textsAdminPanel.cityCard }"></header-page>
        </div>
        <div class="search_list_wrapper">
            <search-input
                [textPlaceholder]="textsAnalytics.searchPlaceholder"
                [(text)]="searchQuery"
            ></search-input>
        </div>
    </div>

    <div class="page-content">
        <div class="city_title__wrapper" *ngIf="(city$ | async) as city">
            <header-page
                [cbBack]="city ? backToCityChange : null"
                [textObject]="{
                    titlePage: city.name,
                    btnBack: textsCityCard.backBtnCityScreen
                }"
            ></header-page>
        </div>

        <div *ngIf="(city$ | async) === null" class="admin_panel__relative_block-listPosts">
            <station-city-list
                [markersData]="store.select(selectCitiesMarker) | async"
                [cities]="locations$ | async"
                [compareMod]="store.select(selectIsComparisonModeActive) | async"
                [comparedStations]="comparedItems$ | async"
                [cbClickForName]="clickFromApToCity"
                [filter]="searchQuery"
                [sortListStation]="sortListStation"
            ></station-city-list>
        </div>

        <div class="admin_panel__relative_block-cityCard" *ngIf="city$ | async">
            <city-info
                [testData]="testData"
                [cityCard]="cityCard$ | async"
                [comparedStations]="comparedItems$ | async"
                [isComparing]="store.select(selectIsComparisonModeActive) | async"
                [isCityMode]="isCityMode$ | async"
            ></city-info>
        </div>
    </div>
</div>

import { CO2, HUM, PM10, PM25, PRES, TEMP } from '@libs/common/consts/substance.consts';
import { ChartConfiguration } from 'chart.js';

export const orderOfMeasures = [CO2, TEMP, HUM, PRES, PM25, PM10];

export interface BarChartData {
    title: string;
    tooltip: string;
    description: string;
    chart?: AllChartOptions;
}

export interface AllChartOptions {
    options?: ChartConfiguration['options'];
    type?: ChartConfiguration['type'];
    legend?: boolean;
    plugins?: ChartConfiguration['plugins'];
    labels?: ChartConfiguration['data']['labels'];
    datasets?: ChartConfiguration['data']['datasets'];
}

export enum CHART_BAR_NAME {
    allHistory = 'AllHistory',
    dayHour = 'DayHour',
    weekDay = 'WeekDay',
}

import * as moment from 'moment';

import {
    Country,
    DistributionSummaryItems,
    HistogramAqi10Response,
    Location,
} from './cityairMapModels';
import {
    City_model,
    DistributionSummary,
    Marker_model,
    MarkerType,
    MeasuresForTime,
    MeasuresInfo_model,
    MeasuresInfoFormat,
    SeasonsName,
    StationData,
    WindowGlobalVars,
} from '@cityair/namespace';
import {
    ALERT_COLORS,
    getStationType,
    MEASURE_SORT_ALL,
    PACKET_VALUE_TYPE_ID,
} from '@cityair/config';
import { declOfNum, getCityIdForCoords, isTimelineAqiLowDetail } from '@cityair/utils/utils';
import { isRU, TEXTS } from '@libs/common/texts/texts';
import { MapMoTimeline, MoItem } from './cityairMapDtos';
import { GetObserverPacketsResponce } from '../adminPanel/models';
import { PacketsValueTypeItem } from '@cityair/modules/login/services/harvester-api/models';
import { getValueByType } from '@cityair/libs/common/api/mapProvider/cityscreenDataTransformer';
import { AVAILABLE_AQIS } from '@libs/common/consts/avaliable-aqi.const';
import { TEMP, HUM, PRES } from '@libs/common/consts/substance.consts';
import { AqiType } from '@libs/common/enums/aqi.type';
import { nowDateTimeLong } from '@libs/common/utils/date-formats';
import { isFalseNumber } from '@libs/common/utils/utils';
import { DEFAULT_AQI_TYPE } from '@libs/common/consts/default-aqi-type.const';
import { createOriginChartData } from './create-origin-chart-data';

declare let window: WindowGlobalVars;

export function createStatisticSeason(data: DistributionSummaryItems): DistributionSummary {
    const summary = {};

    Object.keys(data).forEach((key: SeasonsName) => {
        const _d = data[key];

        summary[key] = {
            firstPacketDate: _d.FirstPacketDate,
            distributionAqi: _d.DistributionAqi.map((a) => ({ aqi: a.Aqi, percent: a.Percent })),
            distributionDayHour: _d.DistributionDayHour.map((a) => ({
                aqi: a.Aqi,
                hourOfDay: a.HourOfDay,
            })),
            distributionWeekDay: _d.DistributionWeekDay.map((a) => ({
                aqi: a.Aqi,
                dayOfWeek: a.DayOfWeek,
            })),
        };
    });

    return summary as DistributionSummary;
}

export function getLastCityData(
    city: City_model,
    measureInfo: MeasuresInfoFormat
): [number, string] {
    if (!measureInfo) {
        return [null, ''];
    }

    const origin = city.originChartData[measureInfo.name];

    if (!origin || !origin.data || !origin.data.length) {
        return [null, ''];
    }

    const val = origin.data[origin.data.length - 1][1];
    let measure = '';

    if (measureInfo.type !== AqiType.instant) {
        measure = measureInfo.unit;
    } else {
        measure = declOfNum(val, TEXTS.AQI_DECL);
    }

    return [Math.round(val), measure];
}

export function calcMeasures(
    measuresIds: (number | string)[],
    packetValueType: PacketsValueTypeItem[],
    deleteArray?
): MeasuresInfo_model {
    const measuresInfo: MeasuresInfo_model = {};

    measuresIds.forEach((id) => {
        if (AVAILABLE_AQIS.includes(id as AqiType)) {
            measuresInfo[id] = {
                type: id as string,
                name: TEXTS.NAMES[id],
                isHidden: false,
                order: 0,
                unit: TEXTS.MEASURES[id],
            };

            return;
        }

        let name: string;
        const vType: PacketsValueTypeItem = packetValueType.find((t) => t.ValueType === id);

        if (!vType) {
            return window.sentryCaptureException
                ? window.sentryCaptureException(new Error(`не нашел ValueType = ${id}`))
                : null;
        }

        name = isRU ? vType.TypeNameRu || vType.TypeName : vType.TypeName || vType.TypeNameRu;

        if (deleteArray && vType.IsHidden) {
            deleteArray.forEach((d) => delete d.values[id]);
            return;
        }

        const _type = PACKET_VALUE_TYPE_ID[vType.ValueType] || vType.TypeName;

        if (TEXTS.TOOLTIPS[_type] === name) {
            // для сокращенного названия T P RH
            name = TEXTS.NAMES[_type];
        }

        measuresInfo[id] = {
            type: _type,
            name,
            isHidden: vType.IsHidden,
            order: vType.TypeOrder,
            unit: vType.Measurement,
        };
    });

    return measuresInfo;
}

export function createStndMarker(timeline: MapMoTimeline[], mo: MoItem, cities: City_model[]) {
    const cityByLocationId = mo.LocationId
        ? cities.find((c) => c.locationId === mo.LocationId)
        : null;

    const cityId =
        cityByLocationId?.id ||
        getCityIdForCoords(cities, mo.DotItem.Latitude, mo.DotItem.Longitude);
    const city = cityByLocationId || cities.find((c) => c.id === cityId);
    // provide any available name prioritized by language (used in public map)
    const pubName = isRU ? mo.PublishNameRu || mo.PublishName : mo.PublishName || mo.PublishNameRu;

    const marker = <Marker_model>{
        type: getStationType(mo.DataProviderId, mo.AccessGranted),
        id: mo.MoId,
        geometry: {
            type: 'Point',
            coordinates: [mo.DotItem.Longitude, mo.DotItem.Latitude],
        },
        name: pubName,
        isOurStation: mo.AccessGranted,
        tzOffset: mo.GmtOffsetSeconds / 60,
        city,
        cityId,
        location_id: mo.LocationId,
    };

    return marker;
}

export function convertLocation(
    loc: Location,
    Countries: Country[]
): { cityMarker: Marker_model; city: City_model } {
    const id = loc.LocationUrl;
    const lat = loc.Latitude;
    const lng = loc.Longitude;
    const tzOffset = loc.GmtOffsetSeconds / 60;
    const msOffset = loc.GmtOffsetSeconds * 1000;
    const name = isRU ? loc.NameRu : loc.Name;
    const countryName: string = (() => {
        if (!Countries) return '';

        const find = Countries.find(
            (country) => loc.CountryId && loc.CountryId === country.CountryId
        );

        if (!find) return '';

        return isRU ? find.NameRu : find.Name;
    })();

    const cityMarker = new Marker_model({
        type: MarkerType.city,
        id: loc.LocationId,
        cityId: id,
        name,
        tzOffset,
        geometry: {
            type: 'Point',
            coordinates: [lng, lat],
        },
    });

    const city = new City_model({
        id: loc.LocationUrl,
        locationId: loc.LocationId,
        name,
        countryName,
        bounds: {
            east: loc.BounceEast,
            north: loc.BounceNorth,
            south: loc.BounceSouth,
            west: loc.BounceWest,
        },
        tzOffset,
        msOffset,
        lat,
        lng,

        zIndex: loc.SortRank,
        isSmallCity: loc.IsSmall,
    });

    return { cityMarker, city };
}

export function createAqiInCity(
    city: City_model,
    cityMarker: Marker_model,
    timeline: MapMoTimeline[],
    loc: Location,
    PacketValueTypes: PacketsValueTypeItem[],
    timeBegin: number,
    timeEnd: number,
    DistributionSummaryItems: DistributionSummaryItems
) {
    const valuesChart = timeline.map((t) => {
        const val = {};

        AVAILABLE_AQIS.forEach((aqiName) => {
            const aqi = getValueByType(t, aqiName);
            if (!isFalseNumber(aqi)) {
                val[aqiName] = aqi;
            }
        });

        t.Data?.forEach((d) => {
            val[d.VT] = d.V;
        });

        return {
            time: new Date(t.Time).getTime(),
            values: val,
        };
    });

    const measuresInfo: MeasuresInfo_model = calcMeasures(
        [...PacketValueTypes.map((p) => p.ValueType), ...AVAILABLE_AQIS],
        PacketValueTypes,
        valuesChart
    );

    city.aqiHistory = timeline.map((t) => ({
        aqi: getValueByType(t, DEFAULT_AQI_TYPE) || 1,
        time: new Date(t.Time).getTime(),
    }));

    city.originChartData = createOriginChartData(
        valuesChart,
        moment().utcOffset(),
        timeBegin,
        timeEnd,
        isTimelineAqiLowDetail(timeBegin, timeEnd) ? 4 : 3,
        measuresInfo
    );

    city.distributionSummary = DistributionSummaryItems
        ? createStatisticSeason(DistributionSummaryItems)
        : null;

    [AqiType.instant, TEMP, HUM, PRES].forEach((type) => {
        const index = Object.keys(measuresInfo).find((key) => measuresInfo[key].type === type);
        city.lastData[type] = getLastCityData(city, measuresInfo[index]);
    });

    city.lastData.time = nowDateTimeLong(
        moment(timeline[timeline.length - 1]?.Time).toDate(),
        loc.GmtOffsetSeconds / 3600
    );

    city.lastData.prevAqi = timeline[timeline.length - 2]?.Aqi;
}

export function histogramTransformer(data: HistogramAqi10Response) {
    const _data = data.HistogramMap;
    delete _data['0'];

    const count = Object.keys(_data).reduce((y, key) => y + _data[key], 0);

    return Object.keys(_data).map((key) => ({
        x: parseInt(key, 10),
        y: (_data[key] / count) * 100,
        color: ALERT_COLORS[key],
    }));
}

export function transformMeasures(data: { [measure: string]: number }): {
    [measure: string]: number;
} {
    const resp = {};

    MEASURE_SORT_ALL.forEach((m) => {
        if (!isFalseNumber(data[m])) {
            resp[m] = data[m];
        }
    });

    return resp;
}

export function objectMonitorDataTransformer(
    _data: GetObserverPacketsResponce,
    packetValueType: PacketsValueTypeItem[]
): StationData {
    let lastPacket = 0;
    let lastPacketTime = 0;

    const data: MeasuresForTime[] = _data.Packets.map((item) => {
        if (item.Timestamp > lastPacket) {
            lastPacket = item.Timestamp;
            lastPacketTime = new Date(item.SendDate).getTime();
        }

        const mObj = {
            time: new Date(item.SendDate).getTime(),
            details: null,
            values: {},
        };

        item.Data.forEach((a) => {
            mObj.values[a.VT] = a.V;
        });

        AVAILABLE_AQIS.forEach((aqiName) => {
            const aqi = getValueByType(item, aqiName);
            if (!isFalseNumber(aqi)) {
                mObj.values[aqiName] = aqi;
            }
        });

        mObj.details = item.AqiItems?.reduce((obj, item) => {
            obj[item.Type] = item.Details;
            return obj;
        }, {});

        return mObj;
    });

    const measuresInfo = calcMeasures(
        [...packetValueType.map((p) => p.ValueType), ...AVAILABLE_AQIS],
        packetValueType,
        data
    );

    return {
        lastPacketID: lastPacket,
        packets: data,
        measuresInfo,
        lastPacketTime,
    };
}

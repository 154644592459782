import { CityairMapDataTransformerResponse, CityScreenTimelineResponse } from './cityairMapModels';
import { City_model, Marker_model, TimelineResponse } from '@cityair/namespace';
import {
    calcMeasures,
    convertLocation,
    createAqiInCity,
    createStndMarker,
} from './cityairMapDataTransformerResponse';
import { GetObserverPacketsResponce } from '@cityair/libs/common/api/adminPanel/models';
import { MapMoTimeline } from '@cityair/libs/common/api/mapProvider/cityairMapDtos';
import { AqiType } from '@libs/common/enums/aqi.type';
import { AVAILABLE_AQIS } from '@libs/common/consts/avaliable-aqi.const';

export function getValueByType(
    data: GetObserverPacketsResponce['Packets'][number] | MapMoTimeline,
    valueType: AqiType | number
) {
    switch (valueType) {
        case AqiType.instant:
            return data.InstantAqi?.Value10;
        case AqiType.epa:
            return data.VtAqi?.EpaAqi;
        case AqiType.cityair:
            return data.VtAqi?.CityairAqi;
        case AqiType.R:
            return data.AqiItems?.find((item) => item.Type === AqiType.R)?.Value;
        case AqiType.EU:
            return data.AqiItems?.find((item) => item.Type === AqiType.EU)?.Value;
        case AqiType.CAPI:
            return data.AqiItems?.find((item) => item.Type === AqiType.CAPI)?.Value;
        case AqiType.aqiIn:
            return data.AqiItems?.find((item) => item.Type === AqiType.aqiIn)?.Value;
        case AqiType.aqiPm:
            return data.AqiItems?.find((item) => item.Type === AqiType.aqiPm)?.Value;

        default:
            return data.Data.find((d) => d.VT === valueType)?.V;
    }
}

function calcValues(measure, rowData) {
    const obj = {};

    measure.ids.forEach((key) => {
        obj[measure.info[key].type] = getValueByType(rowData, key as number | AqiType);
    });

    return {
        date: rowData.Time,
        details: rowData.AqiItems?.reduce((obj, item) => {
            obj[item.Type] = item.Details;
            return obj;
        }, {}),
        values: obj,
    };
}

export function csMapDataTransformer(
    data: CityScreenTimelineResponse,
    timeBegin: number,
    timeEnd: number
): CityairMapDataTransformerResponse {
    let citiesMarkers: Marker_model[] = [];
    let cities: City_model[] = [];

    data.Locations.slice()
        .sort((a, b) => a.SortRank - b.SortRank)
        .forEach((loc) => {
            const { cityMarker, city } = convertLocation(loc, data.Countries);

            const _timeline = data.LocationTimelines.find((lt) => lt.LocationId === loc.LocationId);

            if (!_timeline || !_timeline.Timeline) return;

            createAqiInCity(
                city,
                cityMarker,
                _timeline.Timeline,
                loc,
                data.PacketValueTypes,
                timeBegin,
                timeEnd,
                _timeline.LocationSummary?.DistributionSummaryItems
            );

            cities.push(city);

            cityMarker.city = city;
            citiesMarkers.push(cityMarker);
        });

    const myMarkers: Marker_model[] = data.MoTimelines.map((mo) => {
        mo.AccessGranted = true; // TODO убрать. Используется для определения приватного МО
        return createStndMarker(mo.Timeline, mo, cities);
    });

    cities.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
    });

    const cityLocations = myMarkers.filter((my) => my.city).map((my) => my.city.locationId);
    const uniqIds = new Set(cityLocations);
    cities = cities.filter((c) => uniqIds.has(c.locationId));
    citiesMarkers = citiesMarkers.filter((cm) => uniqIds.has(cm.id));

    const pins: TimelineResponse = {
        locations: [],
        posts: [],
    };

    // TODO refactor all measuresInfo
    const measureIds = [...data.PacketValueTypes.map((p) => p.ValueType), ...AVAILABLE_AQIS];
    const measure = {
        ids: measureIds,
        info: calcMeasures(measureIds, data.PacketValueTypes),
    };

    pins.posts = data.MoTimelines.map((mo) => ({
        id: mo.MoId,
        timeseries: mo.Timeline.map((t) => calcValues(measure, t)),
    }));

    pins.locations = data.LocationTimelines.map((loc) => ({
        id: loc.LocationId,
        timeseries: loc.Timeline.map((t) => calcValues(measure, t)),
    }));

    return {
        cities,
        citiesMarkers,
        myMarkers,
        pins,
    };
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'buttons-save-cancel',
    templateUrl: 'buttons-save-cancel.component.html',
    styleUrls: ['buttons-save-cancel.component.less'],
})
export class ButtonsSaveCancelComponent {
    @Input() saving: boolean;
    @Input() disabled?: boolean;

    @Input() textCancel?: string;
    @Input() textSave?: string;

    @Output() save = new EventEmitter<void>();
    @Output() cancel = new EventEmitter<void>();

    _save() {
        this.save.emit();
    }

    _cancel() {
        this.cancel.emit();
    }

    TEXTS = TEXTS;
}

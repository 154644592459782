import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventFeedItem } from './event-feed-item.component';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { EventBodyModule } from '../event-body/event-body.module';

@NgModule({
    imports: [CommonModule, TooltipsModule, EventBodyModule],
    exports: [EventFeedItem],
    declarations: [EventFeedItem],
})
export class EventFeedItemModule {}

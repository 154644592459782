import { DistributionSummary } from '@cityair/namespace';
import { declOfNum } from '@cityair/utils/utils';
import { TEXTS } from '@libs/common/texts/texts';

export const AQI_IN_ANALYTICS_GROUP_IDS = [106, 192, 193, 197];
export const ANALYTIC_INDORE_DATA: DistributionSummary = {
    Year: {
        firstPacketDate: new Date('2021-11-01T10:00:00'),
        distributionAqi: [
            {
                aqi: 50,
                percent: 19,
            },
            {
                aqi: 100,
                percent: 32,
            },
            {
                aqi: 200,
                percent: 32,
            },
            {
                aqi: 300,
                percent: 16,
            },
            {
                aqi: 400,
                percent: 2,
            },
            {
                aqi: 500,
                percent: 0,
            },
        ],
        distributionDayHour: [
            {
                aqi: 120,
                hourOfDay: 0,
            },
            {
                aqi: 120,
                hourOfDay: 1,
            },
            {
                aqi: 120,
                hourOfDay: 2,
            },
            {
                aqi: 120,
                hourOfDay: 3,
            },
            {
                aqi: 120,
                hourOfDay: 4,
            },
            {
                aqi: 120,
                hourOfDay: 5,
            },
            {
                aqi: 120,
                hourOfDay: 6,
            },
            {
                aqi: 120,
                hourOfDay: 7,
            },
            {
                aqi: 120,
                hourOfDay: 8,
            },
            {
                aqi: 119,
                hourOfDay: 9,
            },
            {
                aqi: 119,
                hourOfDay: 10,
            },
            {
                aqi: 119,
                hourOfDay: 11,
            },
            {
                aqi: 119,
                hourOfDay: 12,
            },
            {
                aqi: 119,
                hourOfDay: 13,
            },
            {
                aqi: 120,
                hourOfDay: 14,
            },
            {
                aqi: 119,
                hourOfDay: 15,
            },
            {
                aqi: 118,
                hourOfDay: 16,
            },
            {
                aqi: 118,
                hourOfDay: 17,
            },
            {
                aqi: 118,
                hourOfDay: 18,
            },
            {
                aqi: 119,
                hourOfDay: 19,
            },
            {
                aqi: 119,
                hourOfDay: 20,
            },
            {
                aqi: 120,
                hourOfDay: 21,
            },
            {
                aqi: 120,
                hourOfDay: 22,
            },
            {
                aqi: 120,
                hourOfDay: 23,
            },
        ],
        distributionWeekDay: [
            {
                aqi: 118,
                dayOfWeek: 0,
            },
            {
                aqi: 129,
                dayOfWeek: 1,
            },
            {
                aqi: 125,
                dayOfWeek: 2,
            },
            {
                aqi: 116,
                dayOfWeek: 3,
            },
            {
                aqi: 117,
                dayOfWeek: 4,
            },
            {
                aqi: 115,
                dayOfWeek: 5,
            },
            {
                aqi: 117,
                dayOfWeek: 6,
            },
        ],
    },
    Winter: {
        firstPacketDate: new Date('2021-11-01T10:00:00'),
        distributionAqi: [
            {
                aqi: 100,
                percent: 22,
            },
            {
                aqi: 200,
                percent: 55,
            },
            {
                aqi: 300,
                percent: 22,
            },
            {
                aqi: 400,
                percent: 1,
            },
        ],
        distributionDayHour: [
            {
                aqi: 155,
                hourOfDay: 0,
            },
            {
                aqi: 155,
                hourOfDay: 1,
            },
            {
                aqi: 155,
                hourOfDay: 2,
            },
            {
                aqi: 155,
                hourOfDay: 3,
            },
            {
                aqi: 155,
                hourOfDay: 4,
            },
            {
                aqi: 155,
                hourOfDay: 5,
            },
            {
                aqi: 155,
                hourOfDay: 6,
            },
            {
                aqi: 155,
                hourOfDay: 7,
            },
            {
                aqi: 155,
                hourOfDay: 8,
            },
            {
                aqi: 155,
                hourOfDay: 9,
            },
            {
                aqi: 154,
                hourOfDay: 10,
            },
            {
                aqi: 154,
                hourOfDay: 11,
            },
            {
                aqi: 154,
                hourOfDay: 12,
            },
            {
                aqi: 154,
                hourOfDay: 13,
            },
            {
                aqi: 154,
                hourOfDay: 14,
            },
            {
                aqi: 153,
                hourOfDay: 15,
            },
            {
                aqi: 153,
                hourOfDay: 16,
            },
            {
                aqi: 153,
                hourOfDay: 17,
            },
            {
                aqi: 153,
                hourOfDay: 18,
            },
            {
                aqi: 153,
                hourOfDay: 19,
            },
            {
                aqi: 154,
                hourOfDay: 20,
            },
            {
                aqi: 154,
                hourOfDay: 21,
            },
            {
                aqi: 155,
                hourOfDay: 22,
            },
            {
                aqi: 155,
                hourOfDay: 23,
            },
        ],
        distributionWeekDay: [
            {
                aqi: 153,
                dayOfWeek: 0,
            },
            {
                aqi: 169,
                dayOfWeek: 1,
            },
            {
                aqi: 159,
                dayOfWeek: 2,
            },
            {
                aqi: 149,
                dayOfWeek: 3,
            },
            {
                aqi: 147,
                dayOfWeek: 4,
            },
            {
                aqi: 151,
                dayOfWeek: 5,
            },
            {
                aqi: 155,
                dayOfWeek: 6,
            },
        ],
    },
    Spring: {
        firstPacketDate: new Date('2021-11-01T10:00:00'),
        distributionAqi: [
            {
                aqi: 50,
                percent: 13,
            },
            {
                aqi: 100,
                percent: 58,
            },
            {
                aqi: 200,
                percent: 14,
            },
            {
                aqi: 300,
                percent: 11,
            },
            {
                aqi: 400,
                percent: 4,
            },
            {
                aqi: 500,
                percent: 0,
            },
        ],
        distributionDayHour: [
            {
                aqi: 103,
                hourOfDay: 0,
            },
            {
                aqi: 103,
                hourOfDay: 1,
            },
            {
                aqi: 103,
                hourOfDay: 2,
            },
            {
                aqi: 103,
                hourOfDay: 3,
            },
            {
                aqi: 103,
                hourOfDay: 4,
            },
            {
                aqi: 103,
                hourOfDay: 5,
            },
            {
                aqi: 104,
                hourOfDay: 6,
            },
            {
                aqi: 104,
                hourOfDay: 7,
            },
            {
                aqi: 104,
                hourOfDay: 8,
            },
            {
                aqi: 104,
                hourOfDay: 9,
            },
            {
                aqi: 105,
                hourOfDay: 10,
            },
            {
                aqi: 105,
                hourOfDay: 11,
            },
            {
                aqi: 106,
                hourOfDay: 12,
            },
            {
                aqi: 107,
                hourOfDay: 13,
            },
            {
                aqi: 107,
                hourOfDay: 14,
            },
            {
                aqi: 107,
                hourOfDay: 15,
            },
            {
                aqi: 105,
                hourOfDay: 16,
            },
            {
                aqi: 104,
                hourOfDay: 17,
            },
            {
                aqi: 104,
                hourOfDay: 18,
            },
            {
                aqi: 104,
                hourOfDay: 19,
            },
            {
                aqi: 104,
                hourOfDay: 20,
            },
            {
                aqi: 104,
                hourOfDay: 21,
            },
            {
                aqi: 105,
                hourOfDay: 22,
            },
            {
                aqi: 105,
                hourOfDay: 23,
            },
        ],
        distributionWeekDay: [
            {
                aqi: 103,
                dayOfWeek: 0,
            },
            {
                aqi: 104,
                dayOfWeek: 1,
            },
            {
                aqi: 104,
                dayOfWeek: 2,
            },
            {
                aqi: 102,
                dayOfWeek: 3,
            },
            {
                aqi: 109,
                dayOfWeek: 4,
            },
            {
                aqi: 107,
                dayOfWeek: 5,
            },
            {
                aqi: 102,
                dayOfWeek: 6,
            },
        ],
    },
    Summer: {
        firstPacketDate: new Date('2021-11-01T10:00:00'),
        distributionAqi: [
            {
                aqi: 50,
                percent: 77,
            },
            {
                aqi: 100,
                percent: 18,
            },
            {
                aqi: 200,
                percent: 1,
            },
            {
                aqi: 300,
                percent: 5,
            },
        ],
        distributionDayHour: [
            {
                aqi: 49,
                hourOfDay: 0,
            },
            {
                aqi: 49,
                hourOfDay: 1,
            },
            {
                aqi: 49,
                hourOfDay: 2,
            },
            {
                aqi: 49,
                hourOfDay: 3,
            },
            {
                aqi: 49,
                hourOfDay: 4,
            },
            {
                aqi: 46,
                hourOfDay: 5,
            },
            {
                aqi: 46,
                hourOfDay: 6,
            },
            {
                aqi: 46,
                hourOfDay: 7,
            },
            {
                aqi: 46,
                hourOfDay: 8,
            },
            {
                aqi: 45,
                hourOfDay: 9,
            },
            {
                aqi: 46,
                hourOfDay: 10,
            },
            {
                aqi: 46,
                hourOfDay: 11,
            },
            {
                aqi: 45,
                hourOfDay: 12,
            },
            {
                aqi: 46,
                hourOfDay: 13,
            },
            {
                aqi: 47,
                hourOfDay: 14,
            },
            {
                aqi: 46,
                hourOfDay: 15,
            },
            {
                aqi: 46,
                hourOfDay: 16,
            },
            {
                aqi: 45,
                hourOfDay: 17,
            },
            {
                aqi: 47,
                hourOfDay: 18,
            },
            {
                aqi: 47,
                hourOfDay: 19,
            },
            {
                aqi: 48,
                hourOfDay: 20,
            },
            {
                aqi: 47,
                hourOfDay: 21,
            },
            {
                aqi: 47,
                hourOfDay: 22,
            },
            {
                aqi: 47,
                hourOfDay: 23,
            },
        ],
        distributionWeekDay: [
            {
                aqi: 41,
                dayOfWeek: 0,
            },
            {
                aqi: 42,
                dayOfWeek: 1,
            },
            {
                aqi: 55,
                dayOfWeek: 2,
            },
            {
                aqi: 52,
                dayOfWeek: 3,
            },
            {
                aqi: 52,
                dayOfWeek: 4,
            },
            {
                aqi: 47,
                dayOfWeek: 5,
            },
            {
                aqi: 39,
                dayOfWeek: 6,
            },
        ],
    },
    Autumn: {
        firstPacketDate: new Date('2021-11-01T10:00:00'),
        distributionAqi: [
            {
                aqi: 50,
                percent: 8,
            },
            {
                aqi: 100,
                percent: 34,
            },
            {
                aqi: 200,
                percent: 35,
            },
            {
                aqi: 300,
                percent: 20,
            },
            {
                aqi: 400,
                percent: 2,
            },
            {
                aqi: 500,
                percent: 0,
            },
        ],
        distributionDayHour: [
            {
                aqi: 136,
                hourOfDay: 0,
            },
            {
                aqi: 136,
                hourOfDay: 1,
            },
            {
                aqi: 136,
                hourOfDay: 2,
            },
            {
                aqi: 136,
                hourOfDay: 3,
            },
            {
                aqi: 136,
                hourOfDay: 4,
            },
            {
                aqi: 136,
                hourOfDay: 5,
            },
            {
                aqi: 136,
                hourOfDay: 6,
            },
            {
                aqi: 136,
                hourOfDay: 7,
            },
            {
                aqi: 135,
                hourOfDay: 8,
            },
            {
                aqi: 134,
                hourOfDay: 9,
            },
            {
                aqi: 134,
                hourOfDay: 10,
            },
            {
                aqi: 135,
                hourOfDay: 11,
            },
            {
                aqi: 135,
                hourOfDay: 12,
            },
            {
                aqi: 134,
                hourOfDay: 13,
            },
            {
                aqi: 134,
                hourOfDay: 14,
            },
            {
                aqi: 133,
                hourOfDay: 15,
            },
            {
                aqi: 133,
                hourOfDay: 16,
            },
            {
                aqi: 133,
                hourOfDay: 17,
            },
            {
                aqi: 134,
                hourOfDay: 18,
            },
            {
                aqi: 134,
                hourOfDay: 19,
            },
            {
                aqi: 135,
                hourOfDay: 20,
            },
            {
                aqi: 135,
                hourOfDay: 21,
            },
            {
                aqi: 136,
                hourOfDay: 22,
            },
            {
                aqi: 137,
                hourOfDay: 23,
            },
        ],
        distributionWeekDay: [
            {
                aqi: 138,
                dayOfWeek: 0,
            },
            {
                aqi: 157,
                dayOfWeek: 1,
            },
            {
                aqi: 146,
                dayOfWeek: 2,
            },
            {
                aqi: 123,
                dayOfWeek: 3,
            },
            {
                aqi: 128,
                dayOfWeek: 4,
            },
            {
                aqi: 120,
                dayOfWeek: 5,
            },
            {
                aqi: 132,
                dayOfWeek: 6,
            },
        ],
    },
};
export const AQI_IN_COLORS = ['#008000', '#99cc00', '#ffff00', '#ffcc00', '#ff0000', '#993300'];
export const LABELS_AQI_IN = [
    `0-50 ${declOfNum(100, TEXTS.AQI_DECL2)}`,
    `51-100 ${declOfNum(100, TEXTS.AQI_DECL2)}`,
    `101-200 ${declOfNum(100, TEXTS.AQI_DECL2)}`,
    `201-300 ${declOfNum(100, TEXTS.AQI_DECL2)}`,
    `301-400 ${declOfNum(100, TEXTS.AQI_DECL2)}`,
    `401-500 ${declOfNum(100, TEXTS.AQI_DECL2)}`,
];

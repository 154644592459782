<ng-container *ngIf="bbox || coordinates">
    <mgl-geojson-source
        [id]="'domain-boundary-data' + dataId"
        [data]="feature"
    ></mgl-geojson-source>
    <mgl-layer
        [id]="'domain-boundary-polyline' + dataId"
        type="line"
        [source]="'domain-boundary-data' + dataId"
        [paint]="{
            'line-color': '#95A0B3',
            'line-width': 1,
            'line-dasharray': [3, 3]
        }"
    ></mgl-layer>
    <ng-container *ngIf="centerCoordinates as center">
        <mgl-marker [lngLat]="[center.lng, northLat]">
            <boundary-marker shift="north">{{ TEXTS.MODELS.border }}</boundary-marker>
        </mgl-marker>
        <mgl-marker [lngLat]="[center.lng, southLat]">
            <boundary-marker shift="south">{{ TEXTS.MODELS.border }}</boundary-marker>
        </mgl-marker>
    </ng-container>
</ng-container>

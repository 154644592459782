<div *ngIf="PostEdit" class="post-edit">
    <div class="post-edit__form scroll">
        <header-of-panel
            class="post-edit__header-of-panel"
            [path]="navigationLink"
            (outputPath)="backToPrevPage($event)"
            [title]="TEXTS.POSTS_AND_DEVICES.editMo"
        ></header-of-panel>

        <static-tags
            class="post-edit__header-static-tags"
            [text]="nameMO"
            [typeTag]="'mo'"
        ></static-tags>
        <form (ngSubmit)="onSubmit()" [formGroup]="PostEdit" novalidate>
            <div class="post-edit__wrapper-content">
                <ca-input
                    class="post-edit__input-wrapper"
                    type="text"
                    controlName="name"
                    [form]="PostEdit"
                    [valueForm]="name.value"
                    [label]="TEXTS.POSTS_AND_DEVICES.name"
                    [textError]="errorObj.name"
                ></ca-input>
                <ca-textarea
                    type="text"
                    controlName="description"
                    [form]="PostEdit"
                    [valueForm]="description.value"
                    [label]="TEXTS.POSTS_AND_DEVICES.description"
                    [textError]="errorObj.name"
                ></ca-textarea>
            </div>
            <section-line [text]="TEXTS.POSTS_AND_DEVICES.locationOnTheMap"></section-line>
            <div class="post-edit__wrapper-content">
                <div class="post-edit__wrapper-coordinate">
                    <input-for-coordinate
                        type="text"
                        controlName="geoLatitude"
                        [form]="PostEdit"
                        [valueForm]="geoLatitude.value"
                        [label]="TEXTS.PLUMES.latitude"
                        [textError]="errorObj.name"
                        [positioning]="'left'"
                    ></input-for-coordinate>
                    <input-for-coordinate
                        type="text"
                        controlName="geoLongitude"
                        [form]="PostEdit"
                        [valueForm]="geoLongitude.value"
                        [label]="TEXTS.PLUMES.longitude"
                        [textError]="errorObj.name"
                        [positioning]="'right'"
                    ></input-for-coordinate>
                </div>
                <input-dropdown
                    class="post-edit__input-dropdown"
                    [inputText]="selectedCity.label"
                    [label]="TEXTS.POSTS_AND_DEVICES.location"
                    [(showDropdown)]="isShowDropdownForCity"
                    (clickOutside)="isShowDropdownForCity = false"
                >
                    <selectbox-radio
                        [listItems]="getCities"
                        (listItemsChange)="getSelectedCity($event); isShowDropdownForCity = false"
                    ></selectbox-radio>
                </input-dropdown>
            </div>
        </form>
    </div>
    <div class="post-edit__btn">
        <btn-extra (click)="showDeletePopup()">
            {{ TEXTS.COMMON.delete }}
        </btn-extra>
        <btn-cancel (click)="openPage(navigationLink[navigationLink.length - 2].path)">
            {{ TEXTS.COMMON.cancel }}
        </btn-cancel>
        <btn-accept [disabled]="name.value === ''" [isLoading]="isLoader" (cbClick)="onSubmit()">
            {{ TEXTS.COMMON.save }}
        </btn-accept>
    </div>
</div>
<ng-template #postEdit>
    <popup-ask
        class="post-edit__popup-ask"
        *ngIf="isShowDeletePopup"
        [@inOutAnimation]
        (cbAccept)="deleteAccept()"
        (cbClose)="deleteCancel()"
        [title]="TEXTS.POPUP_DELETE.title"
        [description]="TEXTS.POPUP_DELETE.body"
        [textAccept]="TEXTS.POPUP_DELETE.accept"
        [textCancel]="TEXTS.POPUP_DELETE.cancelNew"
    >
        <static-tags [typeTag]="'mo'" [text]="name.value"></static-tags>
    </popup-ask>
</ng-template>

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NotificationsState } from './reducers';
import { selectMarkers } from '@cityair/modules/core/store/selectors';

export const selectNotificationsState = createFeatureSelector<NotificationsState>('notifications');

export const selectNotifiablePostIds = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.notifiablePosts
);

export const selectNotifiablePosts = createSelector(
    selectMarkers,
    selectNotifiablePostIds,
    (markers, postIds) => markers.filter((m) => postIds.includes(m.id))
);

export const selectNotNotifiablePosts = createSelector(
    selectMarkers,
    selectNotifiablePostIds,
    (markers, postIds) => markers.filter((m) => !postIds.includes(m.id))
);

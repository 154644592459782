import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import { CalendarOutput } from '@cityair/namespace';
import { formatDayMonthYear } from '@cityair/config';
import { DateRange } from '../../../models';

@Component({
    selector: 'forecast-calendar',
    templateUrl: 'forecast-calendar.component.html',
    styleUrls: ['forecast-calendar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastCalendarComponent implements OnInit, OnChanges {
    @Input() dateRange: DateRange;
    @Input() minDate: string = null;
    @Input() maxDate: string = null;
    @Input() tzOffset: number;

    @Output() updateDateRange = new EventEmitter<CalendarOutput>();

    showCalendar = false;
    begin: number;
    end: number;
    monthsVisible: number;
    columns: number;
    numberOfMonth: number;
    numberOfFutureDays = 0;
    public intervalText = '';
    handleUpdateDays: (tzOffset: number, numberOfFutureDays?: number, minDate?: string) => void;

    ngOnInit() {
        this.setCalendarConfig();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dateRange && changes.dateRange.currentValue) {
            this.begin = this.dateRange.startDate;
            this.end = this.dateRange.finishDate;
            this.intervalText = this.getIntervalText();
        }
    }

    public getIntervalText() {
        const begin = moment(this.begin);
        const end = moment(this.end);

        const textStart = formatDayMonthYear(begin);
        const textEnd = formatDayMonthYear(end);

        return `${textStart} - ${textEnd}`;
    }

    updateTime = (begin: number, end: number) => {
        this.begin = begin;
        this.end = end;
        this.intervalText = this.getIntervalText();
        this.updateDateRange.emit({ begin, end });
        this.showCalendar = false;
    };

    toggleShow = () => {
        this.showCalendar = !this.showCalendar;
        if (this.showCalendar) {
            const calendarConf: { calendarMinDate?: string; numberOfFutureDays?: number } = {
                calendarMinDate: this.minDate,
                numberOfFutureDays: this.numberOfFutureDays,
            };
            this.handleUpdateDays(
                this.tzOffset,
                calendarConf?.numberOfFutureDays,
                calendarConf?.calendarMinDate
            );
        }
    };

    updateDays = (fn: (tzOffset: number) => void) => (this.handleUpdateDays = fn);

    close = () => {
        this.showCalendar = false;
    };

    private setCalendarConfig() {
        if (this.minDate && this.maxDate) {
            const startDate = new Date(this.minDate);
            const endDate = new Date(this.maxDate);
            const increment = endDate.getMonth() === startDate.getMonth() ? 2 : 1;
            const diff = moment(endDate).diff(moment(startDate), 'months', true);
            const monthDifference = Math.ceil(diff) + increment;
            this.monthsVisible = monthDifference >= 2 ? 2 : 1;
            this.columns = monthDifference >= 2 ? 2 : 1;
            this.numberOfMonth = monthDifference >= 12 ? 12 : monthDifference;
            const diffDays = moment(endDate).diff(moment(), 'days', true);
            this.numberOfFutureDays = Math.ceil(diffDays) > 0 ? Math.ceil(diffDays) : 0;
        } else {
            this.monthsVisible = 2;
            this.columns = 2;
            this.numberOfMonth = 12;
        }
    }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { RunPlume, RunPlumeAnnotation } from '@cityair/modules/plumes/services/run/models';
import { TEXTS } from '@libs/common/texts/texts';

import { formatDayMonth } from '@cityair/config';
import { setActiveRun } from '@cityair/modules/plumes/store/actions';
import { Store } from '@ngrx/store';
import { selectRunLoading } from '@cityair/modules/plumes/store/selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'ca-plumes-dropdown-list',
    templateUrl: './plumes-dropdown-list.component.html',
    styleUrls: ['./plumes-dropdown-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlumesDropdownListComponent implements OnChanges, OnDestroy {
    @Input() currentRun: RunPlume;
    @Input() runs: RunPlumeAnnotation[];

    textPlumes = TEXTS.PLUMES;
    isLoadingRun: boolean;
    public ngDestroyed$ = new Subject<void>();

    constructor(public store: Store) {
        this.store
            .select(selectRunLoading)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isLoading) => (this.isLoadingRun = isLoading));
    }

    runFinished: moment.Moment;
    dateStart: moment.Moment;
    dateEnd: moment.Moment;

    isShowList = false;
    ngOnChanges() {
        if (this.currentRun) {
            this.runFinished = moment(this.currentRun.finished_at || 0);
            this.dateStart = moment(this.currentRun.evaluation_time || 0);
            this.dateEnd = moment(this.dateStart).add(this.currentRun.duration_minutes, 'minutes');
        }
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    formatTime(m: moment.Moment) {
        return m.format('HH:mm');
    }

    formatDate(m: moment.Moment) {
        return `${this.formatTime(m)} ${formatDayMonth(m)}`;
    }

    toggleList() {
        this.isShowList = !this.isShowList;
    }

    selectRun(item: RunPlume) {
        if (this.currentRun && this.currentRun.id != item.id) {
            this.store.dispatch(setActiveRun({ payload: item }));
            this.toggleList();
        }
    }

    clickOutside() {
        this.isShowList = false;
    }
}

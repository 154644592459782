import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, take } from 'rxjs';
import { TEXTS } from '@libs/common/texts/texts';
import {
    LayerButtonIconType
} from '@cityair/libs/shared/modules/overlay/components/layer-button/layer-button.component';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import { changeCurrentMmt, setHeight, toggleLayerOnMap } from '../../store/actions';
import {
    currentPlumesMmt,
    selectActiveRun,
    selectLoadingAllData,
    selectPlumesCurrentHeight,
    selectPlumesHeights,
    selectPlumesSpeciesList,
    showLayerOnMap,
} from '../../store/selectors';
import { takeUntil } from 'rxjs/operators';
import { RunPlume } from '../../services/run/models';
import { AuthService } from '@cityair/modules/login/services/auth/auth.service';

@Component({
    selector: 'plumes-map-elements',
    templateUrl: 'plumes-map-elements.component.html',
    styleUrls: ['plumes-map-elements.component.less'],
    animations: ANIMATION_OPACITY,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlumesMapElementsComponent implements OnInit, OnDestroy {
    textNames = TEXTS.NAMES;
    LayerButtonIconType = LayerButtonIconType;
    currentMeasure;
    isLoading;
    run: RunPlume;
    showPlumesLayer$: Observable<boolean>;
    selectLoadingAllData = selectLoadingAllData;
    selectPlumesHeights = selectPlumesHeights;
    selectPlumesCurrentHeight = selectPlumesCurrentHeight;
    public speciesList = [];
    public topHeightSelector = 180;
    public ngDestroyed$ = new Subject<void>();

    constructor(
        public store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private authService: AuthService
    ) {
        store
            .select(selectActiveRun)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.run = data;
                this._changeDetectorRef.markForCheck();
            });
        store
            .select(selectPlumesSpeciesList)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                if (data?.length) {
                    this.speciesList = data;
                    this.topHeightSelector = 32 * data?.length + 140;
                }
                this._changeDetectorRef.markForCheck();
            });
        store
            .select(currentPlumesMmt)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.currentMeasure = data;
                this._changeDetectorRef.markForCheck();
            });
        store
            .select(selectLoadingAllData)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.isLoading = data;
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnInit() {
        this.showPlumesLayer$ = this.store.select(showLayerOnMap);
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    togglePlumesLayer() {
        this.showPlumesLayer$.pipe(take(1)).subscribe((isActive) => {
            this.store.dispatch(toggleLayerOnMap({ payload: !isActive }));
        });
    }

    changeMmt(mmt) {
        if (this.currentMeasure !== mmt) {
            this.store.dispatch(changeCurrentMmt({ payload: mmt }));
        }
    }

    changeHeight(height) {
        this.store.dispatch(setHeight({ payload: height }));
    }

    logOut() {
        this.authService.logOut();
    }
}

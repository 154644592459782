import { createSelector, select, Store } from '@ngrx/store';
import { lastValueFrom, pipe } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { ICoreState, pinsValuesAdapter } from './reducers';
import { getIntervalV1, isTimelineAqiLowDetail } from '@cityair/utils/utils';
import { AVAILABLE_INTERVALS, MMT_FOR_PINS } from '@cityair/config';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import {
    DATA_INTERVAL_TYPES,
    DAY_MS,
    HOUR_MS,
    IntervalEnum,
    MarkerType,
    StationForMapPage_model,
} from '@cityair/namespace';
import { isRU } from '@libs/common/texts/texts';
import { PDK_SS, PDK_MR } from '@libs/common/consts/pdk.consts';
import { ColorZone } from '@libs/common/types/color-zone';
import { PdkType } from '@libs/common/types/pdk-type';
import {
    getComparedListObject,
    isComparedListLimited,
    selectComparedItems,
} from './compared-list/compared-list.selectors';
import { markerState } from '@libs/common/enums/marker-state.enum';
import { Timeseries } from '@libs/shared-ui/components/timeline-panel/models/core';
import * as moment from 'moment';
import { QueryParams } from '@ngrx/data';

// TODO: selectors should use memoization
export interface AppState {
    core: ICoreState;
}

export const coreSelector = (state: AppState) => state.core;

export const selectGroupList = createSelector(
    coreSelector,
    (state: ICoreState) => state?.collections.groups
);

export const getCurrentGroup = createSelector(
    coreSelector,
    (state: ICoreState) => state?.currentGroup
);

export const selectGroupInfo = createSelector(
    coreSelector,
    (state: ICoreState) => state?.collections.groupInfo
);
export const selectUserRole = createSelector(
    coreSelector,
    (state: ICoreState) => state?.collections?.groupInfo?.iAm?.roleId
);
export const selectMyRole = createSelector(
    coreSelector,
    (state: ICoreState) => state?.collections?.groupInfo?.myRole
);
export const selectRegionalCoefficient = createSelector(
    coreSelector,
    (state: ICoreState) => state?.collections?.groupInfo?.regionalCoefs
);
export const selectUserId = createSelector(
    coreSelector,
    (state: ICoreState) => state?.collections?.groupInfo?.iAm?.userId
);
export const selectGroupId = createSelector(
    coreSelector,
    (state: ICoreState) => state?.collections.groupInfo?.groupId
);

export const selectMeasures = createSelector(
    coreSelector,
    (state: ICoreState) => state?.collections.packetsValueTypes
);

export const selectNotifications = createSelector(
    coreSelector,
    (state: ICoreState) => state?.collections.groupInfo?.notificationSubscriptions
);

export const selectUsers = createSelector(
    coreSelector,
    (state: ICoreState) => state?.collections.groupInfo?.users
);

export const selectMos = createSelector(coreSelector, (state: ICoreState) =>
    state?.collections.groupInfo ? state.collections.groupInfo.monitoringObjects : []
);

export const selectCurrentMo = createSelector(coreSelector, (state: ICoreState) =>
    state?.collections.groupInfo?.monitoringObjects.find((mo) => mo.id === state.selectedMoId)
);

export const selectMyDevices = createSelector(
    coreSelector,
    (state: ICoreState) => state?.collections.groupInfo?.myDevices
);

export const selectCurrentSerialNumberDevice = createSelector(
    coreSelector,
    (state: ICoreState) => state.selectedDeviceSerialNumber
);

export const selectCities = createSelector(
    coreSelector,
    (state: ICoreState) => state?.collections.cities
);

export const getCityById = (cityId: string) =>
    createSelector(
        coreSelector,
        selectCities,
        (state, cities) => cities?.find((c) => c.id === cityId) || null
    );

export const selectCitiesFull = createSelector(coreSelector, (state: ICoreState) =>
    state.map.citiesMarkers?.map((m) => m.city)
);

export const selectGroupCities = createSelector(coreSelector, (state: ICoreState) =>
    state?.collections.cities.filter((c) =>
        state.collections.groupInfo?.groupLocationsIds?.includes(c.locationId)
    )
);

export const selectLoadingTimeline = createSelector(
    coreSelector,
    (state: ICoreState) => state?.isTimelineLoading
);

export const selectMarkers = createSelector(coreSelector, (state: ICoreState) => state.map.markers);

export const selectMarkersWithoutLocation = createSelector(coreSelector, (state: ICoreState) =>
    state.map.markers.filter((m) => !m.location_id)
);

export const selectMarker = (id: number) =>
    createSelector(coreSelector, (state: ICoreState) => state.map.markers.find((m) => m.id === id));

export const selectCitiesMarker = createSelector(coreSelector, (state: ICoreState) =>
    state.map ? state.map.citiesMarkers : []
);

export const selectMapLoaded = createSelector(
    coreSelector,
    (state: ICoreState) => !!state.map?.loaded
);

export const selectIsSidebarOpen = createSelector(
    coreSelector,
    (state: ICoreState) => state?.isSidebarOpen
);

export const selectMeasurementsForPins = createSelector(coreSelector, (state: ICoreState) =>
    state?.allMeasurements.filter((name) => MMT_FOR_PINS.includes(name))
);

export const selectGlobalMeasurement = createSelector(
    coreSelector,
    (state: ICoreState) => state?.currentMeasurement
);

export const selectMeasureScheme = createSelector(
    coreSelector,
    (state: ICoreState) => state?.settings.currentMeasureScheme
);

export const selectSchemeAndMeasure = createSelector(coreSelector, (state: ICoreState) => ({
    scheme: state?.settings.currentMeasureScheme,
    mmt: state?.currentMeasurement,
}));

export const selectIsInstantAqiFaqOpen = createSelector(
    coreSelector,
    (state: ICoreState) => state.isInstantAqiFaqOpen
);

export const selectLocationsValues = createSelector(coreSelector, (state: ICoreState) =>
    pinsValuesAdapter.getSelectors().selectAll(state.locationPinValues)
);

// time -----------------------

export const selectTzMinutesOffset = createSelector(
    coreSelector,
    (state: ICoreState) => state?.time.tzMinutesOffset
);

export const selectTimeRangeWithoutDistinct = createSelector(coreSelector, (state: ICoreState) => ({
    begin: state?.time.begin,
    end: state?.time.end,
}));

export const selectTimeRange = pipe(
    select(selectTimeRangeWithoutDistinct),
    distinctUntilChanged(
        (prev, current) => prev.begin === current.begin && prev.end === current.end
    )
);

export const selectTime = createSelector(coreSelector, (state: ICoreState) => state?.time.current);

const _selectCurrentTime = createSelector(coreSelector, (state: ICoreState) => ({
    current: state?.time.current,
}));

export const selectCurrentTime = pipe(
    select(_selectCurrentTime),
    distinctUntilChanged((prev, current) => prev.current === current.current)
);

export const selectRoundedCurrentTime = createSelector(coreSelector, (state: ICoreState) =>
    roundTime(state?.time.current, state?.time.begin, state?.time.end)
);

function formatTimeForNgrxPins(time: string) {
    return time.substring(0, time.length - 5) + 'Z';
}

function roundTime(time: number, timeBegin: number, timeEnd: number) {
    const m = moment(time).utcOffset(0);
    const mRound = isTimelineAqiLowDetail(timeBegin, timeEnd) ? m.startOf('day') : m.startOf('hour');

    return formatTimeForNgrxPins(mRound.toISOString());
}

export const selectPostValue = (id: number) =>
    createSelector(coreSelector, (state: ICoreState) => {
        const roundedTime = roundTime(state?.time.current, state?.time.begin, state?.time.end);
        return pinsValuesAdapter.getSelectors().selectEntities(state.postPinValues)[
            `${id}_${roundedTime}`
        ]?.values[state.currentMeasurement];
    });

export const selectZone = createSelector(coreSelector, (state: ICoreState) => {
    const scheme = state.settings.currentMeasureScheme;
    const mmt = state.currentMeasurement;

    return state.settings.measuresZones[scheme][mmt] as ColorZone;
});
export const selectMeasuresZones = createSelector(
    coreSelector,
    (state: ICoreState) => state.settings.measuresZones
);

export const selectLocationValue = (id: number) =>
    createSelector(coreSelector, (state: ICoreState) => {
        const roundedTime = roundTime(state?.time.current, state?.time.begin, state?.time.end);
        return pinsValuesAdapter.getSelectors().selectEntities(state.locationPinValues)[
            `${id}_${roundedTime}`
        ]?.values[state.currentMeasurement];
    });

export const selectAqiDetailData = (time, mmt: string) =>
    createSelector(coreSelector, (state: ICoreState) => {
        let sourceData;
        const id = state?.comparedItems[0]?.id;

        if (!id || !state?.comparedItems[0].type) {
            return {};
        }

        if (state?.comparedItems[0].type === MarkerType.city) {
            sourceData = state.locationPinValues;
            time = formatTimeForNgrxPins(new Date(time).toISOString());
        } else {
            sourceData = state.postsData;
        }

        const data = pinsValuesAdapter.getSelectors().selectEntities(sourceData)[`${id}_${time}`];

        return {
            val: data?.values[mmt],
            details: data?.details[mmt],
        };
    });

// helpers for synchronous requests
export const getPostPromiseValue = async (store: Store, markerId: number) =>
    lastValueFrom(store.select(selectPostValue(markerId)).pipe(take(1)));

export const getLocationPromiseValue = async (store: Store, markerId: number) =>
    lastValueFrom(store.select(selectLocationValue(markerId)).pipe(take(1)));

export const selectTimelineDateTimes = createSelector(
    coreSelector,
    (state: ICoreState) => state?.timelineDateTimes
);

export const selectIsCityMode = createSelector(
    coreSelector,
    (state: ICoreState) => state?.isCityMode
);

export const selectSourcesAsFeatures = createSelector(
    selectComparedItems,
    selectTzMinutesOffset,
    selectMos,
    // TODO: minimaze recalculate call
    (stations: StationForMapPage_model[], offset: number, mos) => {
        const result = stations.map((station) => {
            const chartValues = Object.values(station.originChartData);
            const currentMo = mos.find((mo) => mo.id === station.id);
            // compensate for the tzOffset usage in createOriginChartData
            const tzOffset = station.tzOffset && station.isOurStation ? station.tzOffset : offset;

            const date =
                chartValues[0]?.data.map((d) => new Date(d[0] - tzOffset * 60000).toISOString()) ||
                [];
            return {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [station.lng, station.lat] as [number, number],
                },
                properties: {
                    uuid: station.id,
                    city_id: `${station.cityId}`,
                    name: station.name,
                    name_ru: station.name,
                    timeseries: chartValues.reduce(
                        (acc, v) => ({
                            ...acc,
                            [v.type]: v.data.map((d) => d[1]),
                        }),
                        {
                            date,
                        } as Timeseries
                    ),
                    obj: station.type,
                    source_type: currentMo?.lastConnectedDevice?.sourceId ?? null,
                    has_any_timeseries: !!chartValues.length,
                },
            };
        });

        return result;
    }
);

export const getWorldAqiHistory = createSelector(
    selectTimeRangeWithoutDistinct,
    ({ begin, end }) => {
        const intervalMin = DATA_INTERVAL_TYPES[getIntervalV1(begin, end)];

        const aqiHistory = [];
        const time = moment(begin);
        while (time <= moment(end)) {
            aqiHistory.push({
                aqi: 0,
                time: time.valueOf(),
            });
            time.add(intervalMin, 'minute');
        }

        return aqiHistory;
    }
);

export const selectErrorMessage = createSelector(
    coreSelector,
    (state: ICoreState) => state.errorMessage
);

export const selectVangaTokenStatus = createSelector(
    coreSelector,
    (state: ICoreState) => state.isVangaTokenLoading
);

export const isCompareMode = createSelector(
    coreSelector,
    (state: ICoreState) => state.isCompareMode
);

export const getMarkerState = (id: number) =>
    createSelector(
        coreSelector,
        isCompareMode,
        isComparedListLimited,
        getComparedListObject(id),
        (state: ICoreState, isCompareMode, isComparedListLimited, comparedListObject) =>
            isCompareMode && (!isComparedListLimited || comparedListObject)
                ? markerState.add
                : markerState.default
    );

export const canClickOnMarker = (id: number) =>
    createSelector(
        coreSelector,
        getComparedListObject(id),
        getMarkerState(id),
        (state, comparedListObject, markerCurrentState) =>
            !(comparedListObject && markerCurrentState === markerState.default)
    );

export const selectTypeInterval = createSelector(
    coreSelector,
    (state: ICoreState) => state?.currentTypeInterval
);

export const selectPdkForChart = createSelector(coreSelector, (state: ICoreState) => {
    if (state.settings.currentMeasureScheme === MeasureScheme.mpc || !isRU) return null;

    const isDay = state.currentTypeInterval === IntervalEnum.day;
    const pdks = (isDay ? PDK_SS : PDK_MR)[state.settings.currentMeasureScheme];

    return {
        type: isDay ? PdkType.ss : PdkType.mr,
        pdks,
    };
});

export const selectInfoMessage = createSelector(
    coreSelector,
    (state: ICoreState) => state?.infoMessage
);

export const selectAllowUpdate = createSelector(coreSelector, (state: ICoreState) => {
    const isToday = new Date().getTime() - state.time.end <= HOUR_MS;

    const isNotMaxTime = state.time.end - state.time.begin <= AVAILABLE_INTERVALS[0].days * DAY_MS;

    return isToday && isNotMaxTime;
});

export const getNewBeginEndTime = createSelector(coreSelector, (state: ICoreState) => {
    const delta = new Date().getTime() - state.time.end;

    return {
        begin: state.time.begin + delta,
        end: new Date().getTime(),
    };
});

export const getIntervalUpdateData = createSelector(
    selectAllowUpdate,
    getNewBeginEndTime,
    (isAllowUpdate, newTimeRange) => ({ isAllowUpdate, newTimeRange })
);
export const selectMapClickState = createSelector(
    coreSelector,
    (state: ICoreState) => state?.mapClickState
);
export const selectAvailableModule = createSelector(
    coreSelector,
    (state: ICoreState) => state?.availableModule
);
export const selectQualityDataMode = createSelector(
    coreSelector,
    (state: ICoreState) => state?.qualityDataMode
);
export const selectIsShowQualityDataInfo = createSelector(
    coreSelector,
    (state: ICoreState) => state?.isShowQualityDataInfo
);
export const selectQualityDataMarkers = createSelector(
    coreSelector,
    (state: ICoreState) => state?.qualityDataMarkers
);
export const selectQualityDataTimeline = createSelector(
    coreSelector,
    (state: ICoreState) => state?.qualityDataTimeline
);
export const getQualityDataParams = createSelector(
    selectTimeRangeWithoutDistinct,
    selectTypeInterval,
    selectGroupId,
    (time, interval, groupId) => {
        if (time && interval && groupId) {
            const params: QueryParams = {
                group_id: groupId.toString(),
                date__gt: new Date(time.begin).toISOString(),
                date__lt: new Date(time.end).toISOString(),
                interval: interval.toString(),
            };

            return params;
        }

        return null;
    }
);

<div class="reports-list-wrapper" *ngIf="isLoaded; else groupLoader">
    <!--<div class="setting-row">
        <div class="setting-wrapper">
            <span class="icon"></span>
            <span class="text">{{translateText.settingsAutoText}}</span>
        </div>
    </div>-->
    <report-kind-view
        [loadingKind]="loadingKind$"
        [reportsKind]="reportsKind$"
        [groupId]="groupId"
        (addReport)="addReport($event)"
    ></report-kind-view>
    <div class="reports-list">
        <div class="history-title-row">
            <div class="history-title">
                {{ translateText.historyListTitle }}
            </div>
        </div>
        <div class="history-settings">
            <div class="control-block">
                <div class="reports-search-wrapper">
                    <search-input-basic
                        #searchInput
                        [textPlaceholderInner]="translateText.searchText"
                        [textInner]="searchQuery"
                        [debounceTimeInner]="300"
                        (textChangeInner)="textChangeIn($event)"
                    ></search-input-basic>
                </div>
                <div class="reports-list-date-range-wrapper">
                    <report-date-range
                        class="input-date-range"
                        [timeBegin]="begin"
                        [timeEnd]="end"
                        [displayMonth]="2"
                        [offsetTz]="offsetTz"
                        [labelText]="translateText.filterPeriod"
                        (timeChange)="changeCalendar($event)"
                    ></report-date-range>
                </div>
                <div
                    class="clear-filter-wrap"
                    [ngClass]="{ disabled: isDisabledFilter() }"
                    (click)="clearFilter()"
                >
                    {{ translateText.clearFilter }}
                </div>
            </div>
            <div class="counter-wrapper" *ngIf="reports">
                <reports-status-counter [data]="reports"></reports-status-counter>
            </div>
            <!--<div class="check-as-read">{{translateText.checkAsReadTitle}}</div>
            <div class="filter-wrap">
                <div class="filter-icon"></div>
                <div class="filter-text">
                    {{translateText.filter}}
                </div>
            </div>-->
        </div>
        <div class="control-message" *ngIf="reports">
            <div *ngIf="loading; else reportsEmpty"></div>
            <ng-template #reportsEmpty>
                <div *ngIf="!isSearchEmpty()" class="empty-reports">
                    <span *ngIf="!reports.length; else countFindReport" class="red">
                        {{ translateText.emptyReport }}
                    </span>
                    <ng-template #countFindReport>
                        {{ getSearchText(reports.length) }}
                    </ng-template>
                </div>
            </ng-template>
        </div>
        <div class="history-list-wrapper">
            <div class="table-header">
                <div class="name-format-wrap">
                    <div class="name-header">
                        <shared-sort-list-item
                            class="point_list__sort_name"
                            (click)="setSortingCb('name')"
                            [sortingDirection]="sortField === 'name' ? sortDirection : 0"
                            [text]="translateText.hTableHeaderName"
                        ></shared-sort-list-item>
                    </div>
                </div>
                <div class="create-header">
                    <shared-sort-list-item
                        class="point_list__sort_name"
                        (click)="setSortingCb('date')"
                        [sortingDirection]="sortField === 'date' ? sortDirection : 0"
                        [text]="translateText.hTableHeaderCreate"
                    ></shared-sort-list-item>
                </div>
                <div class="period-header">
                    <shared-sort-list-item
                        class="point_list__sort_name"
                        (click)="setSortingCb('startDate')"
                        [sortingDirection]="sortField === 'startDate' ? sortDirection : 0"
                        [text]="translateText.formCreate.calendar"
                    ></shared-sort-list-item>
                </div>
                <div class="user-header">
                    <shared-sort-list-item
                        class="point_list__sort_name"
                        (click)="setSortingCb('user.email')"
                        [sortingDirection]="sortField === 'user.email' ? sortDirection : 0"
                        [text]="translateText.hTableHeaderUser"
                    ></shared-sort-list-item>
                </div>
                <div class="comment-header">
                    {{ translateText.hTableHeaderComment }}
                </div>
                <div class="status-header">
                    <shared-sort-list-item
                        class="point_list__sort_name"
                        (click)="setSortingCb('status')"
                        [sortingDirection]="sortField === 'status' ? sortDirection : 0"
                        [text]="translateText.hTableHeaderStatus"
                    ></shared-sort-list-item>
                </div>
                <div class="action-header"></div>
            </div>
            <div class="spinner-wrapper" *ngIf="loading; else reportsTemp">
                <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
            </div>
            <ng-template #reportsTemp>
                <div class="data-wrap scroll" *ngIf="reports">
                    <div *ngIf="reports.length; else emptyReports">
                        <div
                            class="data-reports"
                            *ngFor="
                                let report of reports | orderBy : sortField : sortDirection;
                                trackBy: identifyReport
                            "
                        >
                            <report-detail
                                [posts]="postList"
                                [report]="report"
                                (download)="download($event)"
                                (clickMenu)="clickMenu($event)"
                                (editComment)="editComment($event)"
                                (deleteComment)="deleteComment($event)"
                            ></report-detail>
                        </div>
                    </div>
                    <ng-template #emptyReports>
                        <div class="no-data-wrapper" *ngIf="!isSearchEmpty(); else emptyByPeriod">
                            <search-result-placeholder></search-result-placeholder>
                        </div>
                        <ng-template #emptyByPeriod>
                            <div class="no-data-wrapper period">
                                <non-data-screen
                                    [text]="translateText.noReportByPeriod"
                                ></non-data-screen>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
            </ng-template>
        </div>
    </div>
    <report-alert></report-alert>
</div>
<ng-template #groupLoader>
    <div class="big-loader spinner-wrapper">
        <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
    </div>
</ng-template>
<ng-template #cardMenu>
    <popup-ask
        class="posts-list-view__popup-ask"
        *ngIf="isShowDeletePopup"
        [@inOutAnimation]
        [isDeleteAction]="true"
        (cbAccept)="deleteAccept()"
        (cbClose)="deleteCancel()"
        [title]="translateText.titleDeletePopup(currentReport.name)"
        [description]="translateText.bodyDeletePopup"
        [textAccept]="translateText.accept"
        [textCancel]="translateText.cancel"
        [showCloseIcon]="false"
    >
    </popup-ask>
    <popup-ask
        class="posts-list-view__popup-ask"
        *ngIf="isShowDeleteCommentPopup"
        [@inOutAnimation]
        [isDeleteAction]="true"
        (cbAccept)="deleteCommentAccept()"
        (cbClose)="deleteCancel()"
        [title]="translateText.titleDeleteCommentPopup(currentReport.name)"
        [description]="translateText.bodyDeletePopup"
        [textAccept]="translateText.accept"
        [textCancel]="translateText.cancel"
        [showCloseIcon]="false"
    >
    </popup-ask>
    <update-report-popup
        class="posts-list-view__popup-ask"
        *ngIf="isShowRenamePopup"
        [@inOutAnimation]
        (cbAccept)="save($event)"
        (cbClose)="isShowRenamePopup = false"
        [report]="currentReport"
        [textAccept]="translateText.save"
        [textCancel]="translateText.cancel"
        [isLoading]="isLoadingRename"
        [mode]="editMode"
        [showCloseIcon]="false"
    ></update-report-popup>
    <card-actions-list
        class="card-actions-list"
        *ngIf="isShowMenu"
        [@inOutAnimation]
        [position]="menuPositionTop"
        [positionLeft]="menuPositionLeft"
        (clickOutside)="closePopup($event)"
    >
        <card-actions-item
            data-cy="action-rename"
            type="default"
            [text]="translateText.rename"
            (click)="showRenamePopup($event)"
        ></card-actions-item>
        <card-actions-item
            data-cy="action-close"
            type="danger"
            [text]="translateText.accept"
            (click)="showPopup($event, 'close')"
        ></card-actions-item>
    </card-actions-list>
</ng-template>

import { Component, OnInit, Input } from '@angular/core';
import { first, Observable } from 'rxjs';

import { ADMIN_ID, OPERATOR_ID } from '@cityair/config';
import { TEXTS } from '@libs/common/texts/texts';
import { NotificationSubscription } from '@cityair/libs/common/api/adminPanel/dataTransformer';
import { NotificationSubscribeType } from '@cityair/modules/login/services/harvester-api/adminApiModels';
import { NOTIFIACTIONS_CONFIG } from '../../notifications.settings';
import { NotificationsStateService } from '../../notifications-state.service';
import { ClosePopupsHelper } from '../subscription-card/subscription-card.component';
import { Store } from '@ngrx/store';
import { getCurrentGroup, selectGroupInfo } from '@cityair/modules/core/store/selectors';
import { UserItems}  from '@cityair/namespace';
import { filter } from 'rxjs/operators';

const DEFAULT_CONFIG = Object.assign({}, NOTIFIACTIONS_CONFIG, {
    type: NotificationSubscribeType.ServiceDevice,
});

const SUGGESTED_CONFIG = Object.assign({}, DEFAULT_CONFIG, {
    title: TEXTS.NOTIFICATIONS.serviceNotifications,
    serviceNoData: 3,
    serviceNoPower: 3,
    serviceVerification: 14,
});

@Component({
    selector: 'subscriptions-service',
    templateUrl: 'subscriptions-service.component.html',
})
export class SubscriptionsService extends ClosePopupsHelper implements OnInit {
    @Input() scrolled: Observable<Event>;

    suggestedSubscription: NotificationSubscription;

    textsNotification = TEXTS.NOTIFICATIONS;
    emailsList: string[] = [];

    popupPositionTop = 0;
    popupActive = false;
    private iAm: UserItems;
    private users: UserItems[];

    constructor(
        public stateService: NotificationsStateService,
        private store: Store
    ) {
        super();
        this.store.select(selectGroupInfo)
            .pipe(
                first(),
                filter((v) => !!v),
            )
            .subscribe((groupInfo) => {
                this.iAm = groupInfo.iAm;
                this.users = groupInfo.users;
            });
    }

    ngOnInit() {
        this.store
            .select(getCurrentGroup)
            .pipe(first())
            .subscribe((currentGroup) => {
                const { email, userId } = this.iAm;
                const adminsAndOperators = this.users
                    .filter((u) => u.roleId === ADMIN_ID || u.roleId === OPERATOR_ID)
                    .map((u) => u.userId);
                const userDefaults = {
                    groupId: currentGroup.id,
                    emailsList: [email],
                    forUserIds: [userId, ...adminsAndOperators],
                };

                Object.assign(DEFAULT_CONFIG, userDefaults);
                Object.assign(SUGGESTED_CONFIG, userDefaults);
            });

        this.stateService.lists.selected$.subscribe((ss) => {
            if (!ss.length) {
                this.makeSuggestion();
            }
        });

        this.scrolled.subscribe((e) => this.onScroll(e));
    }

    makeSuggestion() {
        this.suggestedSubscription = new NotificationSubscription();
        Object.assign(this.suggestedSubscription, SUGGESTED_CONFIG);
        this.suggestedSubscription.setSuggested();
    }

    addSubscription() {
        const subscription = new NotificationSubscription();
        Object.assign(subscription, DEFAULT_CONFIG);
        this.editSubscription(subscription);
    }

    editSubscription(subscription: NotificationSubscription) {
        this.stateService.openSettings(subscription);
    }
}

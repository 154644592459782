import { parseUrl } from '@cityair/utils/utils';
import { AqiDataProviderType } from '@libs/shared-ui/components/timeline-panel/types/capi-data-provider.type';
import { Store } from '@ngrx/store';
import { lastValueFrom, take } from 'rxjs';
import { selectAqiDetailData } from './store/selectors';

export function checkInviteLink(): { email: string; inviteCode: string } | null {
    const saved = parseUrl();

    const props = <ReturnType<typeof checkInviteLink>>{};

    if (saved.action === 'invite') {
        props.email = saved.email;
        props.inviteCode = saved.hash;

        return {
            email: saved.email,
            inviteCode: saved.hash,
        };
    }

    return null;
}

export function aqiDataProviderFactory(store: Store): AqiDataProviderType {
    return async (timestamp: unknown, value: string) =>
        await lastValueFrom(store.select(selectAqiDetailData(timestamp, value)).pipe(take(1)));
}

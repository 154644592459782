import { Component } from '@angular/core';
import { TabModel } from '@libs/common/types/tab-model';
import { PostsAndDevicesFacade } from '../../posts-and-devices.facade';
import { Router } from '@angular/router';
import { TEXTS } from '@libs/common/texts/texts';
import { Store } from '@ngrx/store';
import { selectGroupInfo } from '@cityair/modules/core/store/selectors';
import { NETWORK_PAGES } from '@cityair/modules/posts-and-devices/namespace';

@Component({
    selector: 'posts-and-devices-header',
    templateUrl: './posts-and-devices-header.component.html',
    styleUrls: [
        './posts-and-devices-header.component.less',
        '../../posts-and-devices.common-styles.less',
    ],
})
export class PostsAndDevicesHeaderComponent {
    TEXTS = TEXTS;
    pages = NETWORK_PAGES;

    tabs: TabModel[] = [
        {
            isDisabled: false,
            type: 1,
            id: NETWORK_PAGES.postsList,
            title: this.TEXTS.ADMIN_PANEL.OM,
        },
        {
            isDisabled: false,
            type: 2,
            id: NETWORK_PAGES.devicesList,
            title: this.TEXTS.SERVICE_PAGE.devices,
        },
    ];
    selectedTab: TabModel = this.tabs[0];
    subscription;

    selectGroupInfo = selectGroupInfo;

    constructor(
        public postsAndDevicesFacade: PostsAndDevicesFacade,
        private router: Router,
        public store: Store
    ) {}

    getActiveTab() {
        return this.router.url.includes(this.pages.devicesList) ? this.tabs[1] : this.tabs[0];
    }

    openPage(tab: TabModel) {
        this.postsAndDevicesFacade.openPage(NETWORK_PAGES.networks + '/' + tab.id);
    }
}

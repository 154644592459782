import { City_model } from '@cityair/namespace';
import {
    createAction,
    createFeature,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
} from '@ngrx/store';

export const setCurrentCity = createAction(
    '[CurrentCityActions] SetCurrentCity',
    props<{ city: City_model | null }>()
);

export const clearCurrentCity = createAction('[CurrentCityActions] ClearCurrentCity');

type CurrentCityState = { currentCity: City_model | null };

const initialState: CurrentCityState = { currentCity: null };

export const currentCityFeature = createFeature({
    name: 'currentCity',
    reducer: createReducer(
        initialState,

        on(setCurrentCity, (state, { city }) => ({
            currentCity: city,
        })),

        on(clearCurrentCity, (state) => ({
            currentCity: null,
        }))
    ),
});

const featureSelector = createFeatureSelector<CurrentCityState>('currentCity');

export const selectCurrentCity = createSelector(featureSelector, (state) => state.currentCity);
export const selectCurrentLocationId = createSelector(
    featureSelector,
    (state) => state.currentCity?.locationId ?? null
);

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TEXTS } from '@libs/common/texts/texts';
import { CityCard, DistributionSummary, StationForMapPage_model } from '@cityair/namespace';
import { AQI } from '@libs/common/consts/substance.consts';

@Component({
    selector: 'city-info',
    templateUrl: './city-info.component.html',
    styleUrls: ['./city-info.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CityInfo {
    @Input() testData: DistributionSummary;
    @Input() cityCard: CityCard;
    @Input() comparedStations: StationForMapPage_model[];
    @Input() isComparing: boolean;
    @Input() isCityMode: boolean;

    textsCityCard = TEXTS.CITY_CARD;
    AQI = AQI;

    constructor() {}
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { TEXTS } from '@libs/common/texts/texts';
import { declOfNum } from '@cityair/utils/utils';

@Component({
    selector: 'plumes-calculation',
    templateUrl: 'plumes-calculation.component.html',
    styleUrls: ['plumes-calculation.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlumesCalculation implements OnChanges {
    @Input() minutes: number;
    @Input() valueTypes: string[];

    textsPlumes = TEXTS.PLUMES;
    textNames = TEXTS.NAMES;
    valText: string;

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.valueTypes?.currentValue) {
            this.valueTypes = changes.valueTypes.currentValue.map(
                (item) => this.textNames[item] ?? item
            );
        }
        if (changes?.minutes?.currentValue) {
            const hours = changes.minutes.currentValue / 60;
            this.valText = `${hours} ${declOfNum(hours, TEXTS.PLUMES.timeIntervalHours)}`;
        }
    }
}

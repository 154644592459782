<div (clickOutside)="close()" class="calendar">
    <div class="calendar-button" (click)="toggleShow()" data-test="calendar-button">
        <div class="calendar-button__time_interval">
            {{ intervalText }}
        </div>
    </div>
    <calendar-months
        setHeight
        [show]="showCalendar"
        [timeBegin]="begin"
        [timeEnd]="end"
        [tzOffset]="tzOffset"
        [applyCb]="updateTime"
        [updateDays]="updateDays"
        [monthsVisible]="monthsVisible"
        [columns]="columns"
        [numberOfMonths]="numberOfMonth"
    ></calendar-months>
</div>

import { Component } from '@angular/core';

import {
    ClientConfigService,
    ClientConfig,
} from '../../modules/core/services/client-config/client-config.service';
import { detectMobile } from '@libs/common/utils/detect-mobile';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'ca-client-login-page',
    templateUrl: 'client-login-page.component.html',
    styleUrls: ['client-login-page.component.less'],
})
export class ClientLoginPageComponent {
    clientConfig: ClientConfig;
    isMobile: boolean;

    inviteData: {
        email: string;
        code: string;
    };

    constructor(private route: ActivatedRoute, clientConfigService: ClientConfigService) {
        const email = this.route.snapshot.queryParamMap.get('email');
        const inviteCode = this.route.snapshot.queryParamMap.get('inviteCode');

        if (email && inviteCode)
            this.inviteData = {
                email,
                code: inviteCode,
            };

        this.clientConfig = clientConfigService.getConfig();
        this.isMobile = detectMobile();
    }
}

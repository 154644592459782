<div [className]="'wrapper y' + data.positionY">
    <div [className]="'show-component-wrap x' + data.positionX">
        <div class="content">
            <div [className]="'icon ' + data.iconClass">
                <svg
                    *ngIf="data.iconClass === 'info'"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="-6.10352e-05"
                        width="20"
                        height="20"
                        rx="10"
                        fill="url(#paint0_linear_7426_55838)"
                    />
                    <path
                        d="M9.99994 5C9.44765 5 8.99994 5.44772 8.99994 6L8.99994 10C8.99994 10.5523 9.44765 11 9.99994 11C10.5522 11 10.9999 10.5523 10.9999 10L10.9999 6C10.9999 5.44772 10.5522 5 9.99994 5Z"
                        fill="white"
                    />
                    <path
                        d="M9.99994 13C9.44765 13 8.99994 13.4477 8.99994 14C8.99994 14.5523 9.44765 15 9.99994 15C10.5522 15 10.9999 14.5523 10.9999 14C10.9999 13.4477 10.5522 13 9.99994 13Z"
                        fill="white"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_7426_55838"
                            x1="-6.10352e-05"
                            y1="10"
                            x2="19.9999"
                            y2="10"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#4776E6" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg
                    *ngIf="data.iconClass !== 'info'"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        *ngIf="data.iconClass === 'warning'"
                        d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z"
                        fill="#FF7344"
                    />
                    <path
                        *ngIf="data.iconClass === 'error'"
                        d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z"
                        fill="#FD4B50"
                    />
                    <path
                        d="M9.99994 5C9.44765 5 8.99994 5.44772 8.99994 6L8.99994 10C8.99994 10.5523 9.44765 11 9.99994 11C10.5522 11 10.9999 10.5523 10.9999 10L10.9999 6C10.9999 5.44772 10.5522 5 9.99994 5Z"
                        fill="white"
                    />
                    <path
                        d="M9.99994 13C9.44765 13 8.99994 13.4477 8.99994 14C8.99994 14.5523 9.44765 15 9.99994 15C10.5522 15 10.9999 14.5523 10.9999 14C10.9999 13.4477 10.5522 13 9.99994 13Z"
                        fill="white"
                    />
                </svg>
            </div>
            <div class="content-message">
                <div *ngIf="data.iconClass === 'error' && data.size === 'lg'" class="title">
                    {{ errorTextTitle }}
                </div>
                <div [className]="'messages ' + data.size + ' ' + data.iconClass">
                    {{ message }}
                </div>
            </div>
            <div *ngIf="data.size === 'md'" class="close" (click)="close()">
                <svg
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        *ngIf="data.showCloseIcon"
                        d="M1.03961 1.03962L6.96043 6.96044"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        *ngIf="data.showCloseIcon"
                        d="M1.03959 6.96051L6.96041 1.03969"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
            <div class="close lg" *ngIf="data.size === 'lg'" (click)="close()">
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        *ngIf="data.showCloseIcon"
                        d="M1.33866 1.33907L14.6605 14.6609"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        *ngIf="data.showCloseIcon"
                        d="M1.33907 14.6609L14.6609 1.339"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
        </div>
    </div>
</div>

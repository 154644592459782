import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as commonActions from './actions';

import { MeasureScheme } from '@libs/common/enums/measure-scheme';

import {
    City_model,
    IMapClick,
    InfoMessage,
    IntervalEnum,
    IntervalType,
    Marker_model,
    MarkerType,
    PinValue,
    PostDate,
    TimelineItem,
    StationForMapPage_model,
} from '@cityair/namespace';
import { Group } from '@libs/common/types/group';
import {
    GroupInfo,
    NotificationSubscription,
} from '@cityair/libs/common/api/adminPanel/dataTransformer';
import { PacketsValueTypeItem } from '@cityair/modules/login/services/harvester-api/models';
import { getStndTimeBegin, getStndTimeEnd } from '@cityair/libs/shared/utils/config';
import * as moment from 'moment';
import { ADMIN_ID, OPERATOR_ID, STND_INTERVAL } from '@cityair/config';
import { calcDays, findMinInterval, isTimelineAqiLowDetail } from '@cityair/utils/utils';
import { CityairMapDataTransformerResponse } from '@cityair/libs/common/api/mapProvider/cityairMapModels';
import { DEFAULT_AQI_TYPE } from '@libs/common/consts/default-aqi-type.const';
import { DEFAULT_MEASURE_SCHEME } from '@libs/common/consts/default-measure-scheme.const';
import { ZONES } from '@libs/common/consts/zone.const';
import { MeasuresZones } from '@libs/common/types/measures-zones';
import { savedMeasureScheme } from '@libs/common/utils/local-storage';
import { sortMeasurements } from '@libs/common/utils/utils';
import { comparedListReducers } from './compared-list/compared-list.reducers';
import { findNearestTime } from '@cityair/utils/find-nearest-time';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { GroupExtConfigName } from '@cityair/modules/core/services/group-features/group-features.service';
import { DataMarker, DataQualityTimeline } from '@libs/common/models/dataQuality';

export const pinsValuesAdapter: EntityAdapter<PinValue> = createEntityAdapter<PinValue>({
    selectId: (pin) => `${pin.id}_${pin.time}`,
});

function transformToPin(items: TimelineItem[]) {
    const pins: PinValue[] = [];
    items.forEach((item) =>
        item.timeseries.forEach((t) => {
            pins.push({
                id: item.id,
                time: t.date,
                details: t.details,
                values: t.values,
            });
        })
    );

    return pins;
}

function transformToPostData(post: PostDate) {
    const pins: PinValue[] = [];
    post?.timeseries.forEach((t) => {
        pins.push({
            id: post.id,
            time: t.time,
            details: t.details,
            values: t.values,
        });
    });

    return pins;
}

export interface MapState {
    loaded: boolean;
    markers: Marker_model[];
    citiesMarkers: Marker_model[];
}

export type StoreSettings = {
    measuresZones: { [key in MeasureScheme]: MeasuresZones };
    currentMeasureScheme: MeasureScheme;
};

export type StoreSettingsPart = {
    measuresZones?: { [key in MeasureScheme]: MeasuresZones };
};

export interface ICoreState {
    map: MapState;
    postsData: EntityState<PinValue>;
    postPinValues: EntityState<PinValue>;
    locationPinValues: EntityState<PinValue>;
    time: TimeObject;
    isTimelineLoading: boolean;
    isSidebarOpen: boolean;
    selectedMoId: number;
    selectedDeviceSerialNumber: string;
    collections: ICollections;
    allMeasurements: string[];
    currentMeasurement: string;
    isInstantAqiFaqOpen: boolean;
    timelineDateTimes: string[];
    isCityMode: boolean;
    errorMessage: string;
    isVangaTokenLoading: boolean;
    isCompareMode: boolean;
    comparedItems: StationForMapPage_model[];
    currentTypeInterval: IntervalEnum;
    currentGroup: Group | null;
    settings: StoreSettings;
    infoMessage: InfoMessage;
    mapClickState: IMapClick;
    availableModule: MAIN_PAGES[];
    qualityDataMode: number;
    isShowQualityDataInfo: any;
    qualityDataMarkers: DataMarker[];
    qualityDataTimeline: DataQualityTimeline[];
}

export interface ICollections {
    cities: City_model[];
    groups: Group[];
    groupInfo: GroupInfo;
    packetsValueTypes: PacketsValueTypeItem[];
}

export interface TimeObject {
    current: number;
    begin: number;
    end: number;
    tzMinutesOffset: number;
}

export type DataSeriesType = {
    name: string;
    data: {
        x: string;
        y: number;
    }[];
};

const stndBeginTime = getStndTimeBegin();
const stndEndTime = getStndTimeEnd();

export const initialState: ICoreState = {
    isTimelineLoading: true, // должно быть true, по нец следится первая загрузка
    map: {
        loaded: false,
        markers: [],
        citiesMarkers: [],
    },
    postsData: pinsValuesAdapter.getInitialState(),
    postPinValues: pinsValuesAdapter.getInitialState(),
    locationPinValues: pinsValuesAdapter.getInitialState(),

    time: {
        current: findNearestTime(null, stndBeginTime, stndEndTime),
        begin: stndBeginTime,
        end: stndEndTime,
        tzMinutesOffset: getDefaultTzMinutesOffset(),
    },

    isSidebarOpen: false,
    selectedMoId: null,
    selectedDeviceSerialNumber: null,

    collections: {
        cities: [],
        groups: [],
        groupInfo: null,
        packetsValueTypes: null,
    },

    allMeasurements: [],
    currentMeasurement: DEFAULT_AQI_TYPE,
    isInstantAqiFaqOpen: false,
    timelineDateTimes: [],
    isCityMode: false,
    errorMessage: null,
    isVangaTokenLoading: false,
    isCompareMode: false,
    comparedItems: [],
    currentTypeInterval: STND_INTERVAL,
    currentGroup: null,
    settings: {
        currentMeasureScheme: savedMeasureScheme || DEFAULT_MEASURE_SCHEME,
        measuresZones: ZONES,
    },
    infoMessage: null,
    mapClickState: null,
    availableModule: [],
    qualityDataMode: null,
    isShowQualityDataInfo: null,
    qualityDataMarkers: [],
    qualityDataTimeline: [],
};

const _commonReducer = createReducer(
    initialState,

    on(commonActions.setCurrentGroup, (state: ICoreState, { group }: { group: Group | null }) => ({
        ...state,
        currentGroup: group,
    })),

    on(
        commonActions.groupListLoaded,
        (
            state: ICoreState,
            data: { groups: Group[]; packetValueTypes: PacketsValueTypeItem[] }
        ) => ({
            ...state,
            collections: {
                ...state.collections,
                groups: [...data.groups].sort((a, b) =>
                    a.name > b.name ? 1 : a.name < b.name ? -1 : 0
                ),
                packetsValueTypes: data.packetValueTypes,
            },
        })
    ),

    on(commonActions.groupInfoLoaded, (state: ICoreState, data: { groupInfo: GroupInfo }) => ({
        ...state,
        collections: {
            ...state.collections,
            groupInfo: data.groupInfo,
        },
    })),

    on(commonActions.saveSettings, (state: ICoreState, settings: StoreSettingsPart) => ({
        ...state,
        settings: {
            ...state.settings,
            measuresZones: settings.measuresZones || state.settings.measuresZones,
        },
    })),

    on(commonActions.setErrorMessage, (state: ICoreState, data) => ({
        ...state,
        errorMessage: data.msg,
    })),

    on(commonActions.clearErrorMessage, (state: ICoreState, data) => ({
        ...state,
        errorMessage: null,
    })),

    on(commonActions.timelineInfoLoad, (state: ICoreState) => ({
        ...state,
        isTimelineLoading: true,
    })),

    on(
        commonActions.timelineInfoLoaded,
        (
            state: ICoreState,
            { cities, citiesMarkers, myMarkers }: CityairMapDataTransformerResponse
        ) => {
            const set = new Set();
            citiesMarkers.forEach((m) => {
                Object.keys(m.city.originChartData).forEach((key) => {
                    set.add(m.city.originChartData[key].type);
                });
            });

            return {
                ...state,
                isTimelineLoading: false,
                allMeasurements: Array.from(set).sort(sortMeasurements) as string[],
                collections: {
                    ...state.collections,
                    cities: cities,
                },
                map: {
                    ...state.map,
                    markers: myMarkers,
                    citiesMarkers,
                },
            };
        }
    ),

    on(commonActions.selectMo, (state: ICoreState, data: { id: number }) => ({
        ...state,
        selectedMoId: data.id,
    })),

    on(commonActions.selectDevice, (state: ICoreState, data: { serialNumber: string }) => ({
        ...state,
        selectedDeviceSerialNumber: data.serialNumber,
    })),

    on(
        commonActions.modifyNotification,
        (state: ICoreState, data: { id: number; props: Partial<NotificationSubscription> }) => {
            const newSubscriptions = state.collections.groupInfo.notificationSubscriptions.map(
                (sub) => {
                    if (sub.id === data.id) {
                        // copy class instance. Object.create needed for disable protected props in sub
                        return Object.assign(
                            Object.create(Object.getPrototypeOf(sub)),
                            sub,
                            data.props
                        );
                    } else {
                        return sub;
                    }
                }
            );

            return {
                ...state,
                collections: {
                    ...state.collections,
                    groupInfo: {
                        ...state.collections.groupInfo,
                        notificationSubscriptions: newSubscriptions,
                    },
                },
            };
        }
    ),

    on(commonActions.mapLoaded, (state: ICoreState) => ({
        ...state,
        map: {
            ...state.map,
            loaded: true,
        },
    })),

    on(commonActions.openSidebar, (state: ICoreState) => ({
        ...state,
        isSidebarOpen: true,
    })),

    on(commonActions.closeSidebar, (state: ICoreState) => ({
        ...state,
        isSidebarOpen: false,
    })),

    on(commonActions.toggleSidebar, (state: ICoreState) => ({
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
    })),

    on(commonActions.setGlobalMeasurement, (state: ICoreState, { mmt }) => ({
        ...state,
        currentMeasurement: mmt,
    })),

    on(commonActions.openInstantAqiFaq, (state: ICoreState) => ({
        ...state,
        isInstantAqiFaqOpen: true,
    })),

    on(commonActions.closeInstantAqiFaq, (state: ICoreState) => ({
        ...state,
        isInstantAqiFaqOpen: false,
    })),

    on(commonActions.pinsValuesLoaded, (state: ICoreState, props) => ({
        ...state,
        postPinValues: pinsValuesAdapter.setAll(
            transformToPin(props.data.posts),
            state.postPinValues
        ),
        locationPinValues: pinsValuesAdapter.setAll(
            transformToPin(props.data.locations),
            state.locationPinValues
        ),
    })),

    on(commonActions.updatePostData, (state: ICoreState, props) => ({
        ...state,
        postsData: pinsValuesAdapter.setMany(transformToPostData(props.payload), state.postsData),
    })),

    // ------------------- TIME

    on(commonActions.currentTimeUpdate, (state: ICoreState, props) => {
        if (!props.time) return state;

        return {
            ...state,
            time: {
                ...state.time,
                current: props.time,
            },
        };
    }),

    on(commonActions.intervalUpdate, (state: ICoreState, props) => {
        let minInterval;
        if (state.comparedItems.find((m) => m.type === MarkerType.city))
            minInterval = isTimelineAqiLowDetail(props.begin, props.end)
                ? IntervalEnum.day
                : IntervalEnum.hour;
        else
            minInterval = findMinInterval(calcDays(props.begin, props.end))
                ?.interval as IntervalType;

        return {
            ...state,
            time: {
                ...state.time,
                ...props,
            },
            currentTypeInterval:
                state.currentTypeInterval < minInterval ? minInterval : state.currentTypeInterval,
        };
    }),

    on(commonActions.setTzMinutesOffset, (state: ICoreState, props) => ({
        ...state,
        time: {
            ...state.time,
            tzMinutesOffset: props.tz,
        },
    })),

    on(commonActions.resetTzMinutesOffset, (state: ICoreState) => ({
        ...state,
        time: {
            ...state.time,
            tzMinutesOffset: getDefaultTzMinutesOffset(),
        },
    })),

    //
    on(commonActions.setTimelineDateTimes, (state: ICoreState, data: { dateTimes: string[] }) => ({
        ...state,
        timelineDateTimes: data.dateTimes,
    })),

    on(commonActions.setCityMode, (state: ICoreState) => ({
        ...state,
        isCityMode: true,
    })),

    on(commonActions.setWorldMode, (state: ICoreState) => ({
        ...state,
        isCityMode: false,
    })),

    on(commonActions.refreshVangaToken, (state: ICoreState) => ({
        ...state,
        isVangaTokenLoading: true,
    })),

    on(commonActions.vangaTokenUpdated, (state: ICoreState) => ({
        ...state,
        isVangaTokenLoading: false,
    })),

    on(commonActions.setComparisonMode, (state: ICoreState, props) => ({
        ...state,
        isCompareMode: props.payload,
    })),
    on(commonActions.setTypeInterval, (state: ICoreState, props) => ({
        ...state,
        currentTypeInterval: props.payload,
    })),

    on(commonActions.showInfo, (state: ICoreState, data) => ({
        ...state,
        infoMessage: data,
    })),
    on(commonActions.setMapClickState, (state: ICoreState, data) => ({
        ...state,
        mapClickState: data,
    })),

    on(commonActions.setCities, (state: ICoreState, { cities }) => ({
        ...state,
        collections: {
            ...state.collections,
            cities,
        },
    })),
    on(commonActions.setAvailableModule, (state: ICoreState, { cities, groupInfo }) => {
        const result = getAvailableModules(cities, groupInfo);
        return {
            ...state,
            availableModule: result,
        };
    }),
    on(commonActions.changeQualityDataMode, (state: ICoreState, { payload }) => ({
        ...state,
        qualityDataMode: payload,
    })),
    on(commonActions.toggleShowQualityDataInfo, (state: ICoreState, { payload }) => ({
        ...state,
        isShowQualityDataInfo: payload,
    })),

    on(commonActions.setQualityDataMarkers, (state: ICoreState, { payload }) => ({
        ...state,
        qualityDataMarkers: payload,
    })),
    on(commonActions.setQualityData, (state: ICoreState, { payload }) => {
        const result: DataQualityTimeline[] = [];
        const qualityDataMarkers = state?.qualityDataMarkers ?? [];
        payload.forEach((v) => {
            if (v === null) {
                result.push(null);
            } else {
                const percentGroup = state?.qualityDataMode;
                if (v.isShow(percentGroup) && v.markerCodes.length) {
                    const markers = v.markerCodes.map((v) =>
                        qualityDataMarkers.find((marker) => marker.code === v)
                    );
                    result.push(new DataQualityTimeline(markers));
                } else {
                    result.push(null);
                }
            }
        });

        return {
            ...state,
            qualityDataTimeline: result,
        };
    }),

    ...comparedListReducers
);

function getDefaultTzMinutesOffset(): number {
    return moment().utcOffset();
}

export function commonReducers(state, action) {
    return _commonReducer(state, action);
}
function getAvailableModules(cities, groupInfo) {
    const pages = Object.values(MAIN_PAGES);
    const result = [];
    pages.forEach((page) => {
        if (page === MAIN_PAGES.analytics && !groupInfo?.allowIndoor && cities.length > 0) {
            result.push(page);
        }
        if (page === MAIN_PAGES.plumes && groupInfo.allowPlumes) {
            result.push(page);
        }

        if (
            page === MAIN_PAGES.notifications &&
            !groupInfo?.allowIndoor &&
            groupInfo.myRole?.editMo &&
            !!(groupInfo?.myDevices.length || groupInfo.monitoringObjects.length)
        ) {
            result.push(page);
        }
        if (page === MAIN_PAGES.users && groupInfo.myRole?.editUser) {
            result.push(page);
        }
        if (
            page === MAIN_PAGES.networks &&
            (groupInfo.monitoringObjects?.length || groupInfo.myDevices?.length) > 0
        ) {
            result.push(page);
        }

        if (page === MAIN_PAGES.settings) {
            result.push(page);
        }
        if (
            (page === MAIN_PAGES.indoor ||
                page === MAIN_PAGES.global ||
                page === MAIN_PAGES.indoorWidget) &&
            groupInfo.allowIndoor
        ) {
            result.push(page);
        }
        if (
            page === MAIN_PAGES.reports &&
            (groupInfo.iAm?.roleId === ADMIN_ID || groupInfo.iAm?.roleId === OPERATOR_ID) &&
            groupInfo.extConfig[GroupExtConfigName.showNewAnalityc]
        ) {
            result.push(page);
        }
        if (
            page === MAIN_PAGES.forecast &&
            groupInfo.extConfig[GroupExtConfigName.showForecastModule]
        ) {
            result.push(page);
        }
    });

    return result;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';

import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { AnalyticChartTitleModule } from '../analytic-chart-title/analytic-chart-title.module';
import { BarChartModule } from '../bar-chart/bar-chart.module';
import { DoughnutChartModule } from '../doughnut-chart/doughnut-chart.module';
import { AnalyticComponent } from '../analytic-main/analytic-main.component';

@NgModule({
    imports: [
        CommonModule,
        NgChartsModule,
        AnalyticChartTitleModule,
        BarChartModule,
        DoughnutChartModule,
        SwitchItemModule,
    ],
    declarations: [AnalyticComponent],
    exports: [AnalyticComponent],
})
export class AnalyticModule {}

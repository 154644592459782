import {
    Component,
    Input,
    OnInit,
    ViewChild,
    TemplateRef,
    OnDestroy,
    ElementRef,
} from '@angular/core';

import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

import { merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';

import { TEXTS } from '@libs/common/texts/texts';
import { NotificationSubscription } from '@cityair/libs/common/api/adminPanel/dataTransformer';
import { NotificationsStateService } from '../../notifications-state.service';
import { postsListLabels, selectedPostsText } from '../../notifications.utils';
import {
    deduplicate,
    haveDifferentValues,
    mapEmails,
    stringToList,
    updateFormControls,
    EmailsListData,
} from '@cityair/modules/notifications/components/settings/settings.utils';

import { MessageAPIResponseService } from '@libs/shared-ui/components/little-components/message-api-response/message-api-response.service';
import { MonitoringObject } from '@cityair/namespace';
import { CheckboxItem } from '@libs/common/types/checkbox-item';
import { MAX_INLINE_MOS } from '@cityair/modules/notifications/notifications.settings';
import { NgLocalization } from '@angular/common';
import { Store } from '@ngrx/store';
import { selectMos } from '@cityair/modules/core/store/selectors';
import { updateNotifiablePosts } from '@cityair/modules/notifications/store/actions';

const VALUE_PATTERN = /^[-\+]?[1-9][0-9]*$/;

@Component({
    selector: 'settings-service',
    templateUrl: './settings-service.component.html',
    styleUrls: ['settings-service.component.less'],
})
export class SettingsService implements OnInit, OnDestroy {
    @Input() subscription: NotificationSubscription;

    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    workingSubscription: NotificationSubscription;
    mosList: MonitoringObject[];
    allMos: number[];
    moCheckboxes: CheckboxItem[] = [];

    textsNotification = TEXTS.NOTIFICATIONS;
    textsPopup = TEXTS.POPUP_THREE_QUESTIONS;
    TEXTS = TEXTS;

    settingsForm: UntypedFormGroup;

    emailsListData: EmailsListData;
    emailsListReportsData: EmailsListData;

    saving = false;
    formDataHasChanged = false;
    showConfirmationPopup = false;

    maxInlineMos = MAX_INLINE_MOS;
    showMosSelection = false;
    subscriptions: Subscription[] = [];
    selectedPostsText = selectedPostsText.bind(null, this.ngLocalization);
    getMoNameHandler = this.getMoName.bind(this);
    constructor(
        private element: ElementRef,
        private stateService: NotificationsStateService,
        private ngLocalization: NgLocalization,
        private fb: UntypedFormBuilder,
        private popupProvider: OffPanelPopupService,
        private msgService: MessageAPIResponseService,
        private store: Store
    ) {
        const monitoringObjectsSub = store
            .select(selectMos)
            .pipe(filter((v) => !!v.length))
            .subscribe((mos) => {
                this.mosList = mos;
                this.allMos = mos.map((v) => v.id);
            });

        this.subscriptions.push(monitoringObjectsSub);
    }

    ngOnInit() {
        this.workingSubscription = NotificationSubscription.clone(this.subscription);

        this.emailsListData = mapEmails(this.workingSubscription.emailsList);
        this.emailsListReportsData = mapEmails(this.workingSubscription.emailsListReports);

        if (this.workingSubscription.serviceIsForAllMos) {
            this.workingSubscription.serviceMoItems = this.allMos.slice();
        }

        this.workingSubscription.serviceMoItems = this.workingSubscription.serviceMoItems.filter(
            (id) => this.getMoName(id)
        );

        this.moCheckboxes = this.toCheckboxItems(
            this.allMos,
            this.workingSubscription.serviceMoItems
        );

        this.setNotifiableMos();

        this.settingsForm = this.fb.group({
            title: [this.workingSubscription.title, [Validators.required, Validators.minLength(1)]],
            serviceIsForAllMos: [this.workingSubscription.serviceIsForAllMos],
            serviceMoItems: this.fb.group(
                this.getUpdatedCheckboxData(this.workingSubscription.serviceMoItems)
            ),
            filterMosText: [''],
            serviceSettings: this.fb.group({
                nodata: [
                    this.workingSubscription.serviceNoData,
                    [Validators.required, Validators.pattern(VALUE_PATTERN), Validators.min(1)],
                ],
                nopower: [
                    this.workingSubscription.serviceNoPower,
                    [Validators.required, Validators.pattern(VALUE_PATTERN), Validators.min(1)],
                ],
                verification: [
                    this.workingSubscription.serviceVerification,
                    [Validators.required, Validators.pattern(VALUE_PATTERN), Validators.min(1)],
                ],
            }),
            emailsList: [this.workingSubscription.emailsList, [Validators.minLength(1)]],
            emailsListReports: [
                this.workingSubscription.emailsListReports,
                [Validators.minLength(1)],
            ],
            emailsListProxy: this.fb.group(
                this.emailsListData.reduce(
                    (acc, { name, value }) => ({
                        ...acc,
                        [name]: [value, [Validators.email]],
                    }),
                    {}
                )
            ),
            emailsListReportsProxy: this.fb.group(
                this.emailsListReportsData.reduce(
                    (acc, { name, value }) => ({
                        ...acc,
                        [name]: [value, [Validators.email]],
                    }),
                    {}
                )
            ),
        });

        const title$ = this.title.valueChanges.pipe(debounceTime(200), distinctUntilChanged());
        const serviceSettings$ = this.serviceSettings.valueChanges.pipe(debounceTime(200));
        const emailsList$ = this.emailsList.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        );
        const emailsListReports$ = this.emailsListReports.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        );

        const titleSub = title$.subscribe((value) => {
            this.workingSubscription.title = value;
        });

        const serviceSettingsSub = serviceSettings$.subscribe((value) => {
            Object.assign(this.workingSubscription, {
                serviceNoData: value.nodata,
                serviceNoPower: value.nopower,
                serviceVerification: value.verification,
            });
        });

        const emailListSub = emailsList$.subscribe((value) => {
            this.updateList(value.toString());
            const emails = Object.values(this.emailsListProxy.value as string[]);
            this.workingSubscription.emailsList = deduplicate(emails);
        });

        const emailListReportsSub = emailsListReports$.subscribe((value) => {
            this.updateListReports(value.toString());
            const emails = Object.values(this.emailsListReportsProxy.value as string[]);
            this.workingSubscription.emailsListReports = deduplicate(emails);
        });
        const moItems$ = this.moItems.valueChanges.pipe(debounceTime(200), distinctUntilChanged());
        const moItemsSub = moItems$.subscribe((value) => {
            const sub = this.workingSubscription;
            const moItems = Object.keys(value)
                .filter((k) => value[k])
                .map(Number);

            if (haveDifferentValues(moItems, sub.serviceMoItems)) {
                sub.serviceIsForAllMos = false;
                this.isForAllMos.patchValue(false, {
                    emitEvent: false,
                });
            }

            sub.serviceMoItems = moItems;

            this.setNotifiableMos();

            this.moCheckboxes = this.toCheckboxItems(this.allMos, moItems);
        });
        const isForAllSub = this.isForAllMos.valueChanges.subscribe((value) => {
            const updated = this.getUpdatedMos(this.moItems.value, value);
            const moItems = Object.keys(updated)
                .filter((k) => updated[k])
                .map(Number);
            this.workingSubscription.serviceMoItems = moItems;
            this.setNotifiableMos();
        });
        this.subscriptions.push(
            titleSub,
            moItemsSub,
            moItemsSub,
            isForAllSub,
            emailListSub,
            emailListReportsSub,
            serviceSettingsSub
        );
        merge(title$, moItems$, serviceSettings$, emailsList$, emailsListReports$).subscribe(() => {
            this.changeCheck();
        });

        this.popupProvider.setTemplate(this.popupOutlet, () => {
            const changed = this.settingsForm.invalid || this.formDataHasChanged;
            if (changed) this.openPopup();
            return changed;
        });
    }

    ngOnDestroy() {
        this.msgService.clearMsg();
        this.popupProvider.clear();
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    private toCheckboxItems(ids: number[], selected: number[]): CheckboxItem[] {
        return ids.map((id) => ({
            id,
            label: this.getMoName(id),
            selected: selected.includes(id),
        }));
    }

    updateMoCheckboxes(items: CheckboxItem[]) {
        this.moItems.patchValue(
            this.getUpdatedCheckboxData(items.filter((c) => c.selected).map((c) => +c.id))
        );
    }

    private getUpdatedCheckboxData(moItems: number[], resetValue?: boolean) {
        return this.allMos.reduce(
            (acc, mo) => ({
                ...acc,
                [mo]: resetValue ?? moItems.includes(mo),
            }),
            {}
        );
    }

    private normalizeMos(subscription: NotificationSubscription) {
        const serviceMoItems = subscription.serviceIsForAllMos ? [] : subscription.serviceMoItems;
        return Object.assign(NotificationSubscription.clone(subscription), subscription, {
            serviceMoItems,
        });
    }

    private getUpdatedMos(moItems: any, isForAllMos: boolean) {
        this.workingSubscription.serviceIsForAllMos = isForAllMos;

        moItems = this.getUpdatedCheckboxData(this.workingSubscription.serviceMoItems, isForAllMos);

        this.moItems.patchValue(moItems, {
            emitEvent: false,
        });

        return moItems;
    }

    setNotifiableMos() {
        this.store.dispatch(
            updateNotifiablePosts({ postIds: this.workingSubscription.serviceMoItems.slice() })
        );
    }

    toggleMosSelection() {
        this.showMosSelection = !this.showMosSelection;
    }

    toggleMosSelectionWithinContainer(e: Event) {
        if (this.element.nativeElement.contains(e.target as HTMLElement)) {
            this.toggleMosSelection();
        }
    }
    updateList(value: string) {
        this.emailsListData = mapEmails(stringToList(value));
        updateFormControls(this.emailsListProxy as UntypedFormGroup, this.emailsListData);
    }

    updateListReports(value: string) {
        this.emailsListReportsData = mapEmails(stringToList(value));
        updateFormControls(
            this.emailsListReportsProxy as UntypedFormGroup,
            this.emailsListReportsData
        );
    }

    getMoName(id: number) {
        if (!this.mosList) {
            return;
        }
        return this.mosList.find((v) => v.id === id)?.name;
    }

    removePostFromList(index: number) {
        const moItems = this.workingSubscription.serviceMoItems.filter((_, i) => i !== index);
        this.moItems.patchValue(this.getUpdatedCheckboxData(moItems));
    }

    changeCheck() {
        this.formDataHasChanged = haveDifferentValues(this.subscription, this.workingSubscription);
    }

    get title() {
        return this.settingsForm.get('title');
    }

    get serviceSettings() {
        return this.settingsForm.get('serviceSettings');
    }

    get isForAllMos() {
        return this.settingsForm.get('serviceIsForAllMos');
    }
    get filterMosText() {
        return this.settingsForm.get('filterMosText');
    }

    get moItems() {
        return this.settingsForm.get('serviceMoItems') as UntypedFormGroup;
    }

    get nodata() {
        return this.settingsForm.get('serviceSettings').get('nodata');
    }

    get nopower() {
        return this.settingsForm.get('serviceSettings').get('nopower');
    }

    get verification() {
        return this.settingsForm.get('serviceSettings').get('verification');
    }

    get emailsList() {
        return this.settingsForm.get('emailsList');
    }

    get emailsListReports() {
        return this.settingsForm.get('emailsListReports');
    }

    get emailsListProxy() {
        return this.settingsForm.get('emailsListProxy') as UntypedFormGroup;
    }

    get emailsListReportsProxy() {
        return this.settingsForm.get('emailsListReportsProxy') as UntypedFormGroup;
    }

    postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    cancel = () => {
        this.stateService.openSubscriptions();
    };

    save() {
        if (!this.settingsForm.disabled && !this.settingsForm.invalid && this.formDataHasChanged) {
            this.onSubmit();
        } else {
            this.onClosePopup();
        }
    }

    openPopup() {
        this.showConfirmationPopup = true;
    }

    onSubmit() {
        this.settingsForm.disable({
            emitEvent: false,
        });

        this.saving = true;

        const subscription = this.normalizeMos(this.workingSubscription);

        this.stateService
            .addOrActivateSubscription(subscription)
            .then(() => {
                if (this.popupProvider.deferred()) {
                    this.stateService.openSubscriptions();
                }
            })
            .finally(() => {
                this.saving = false;
                this.settingsForm.enable();
                this.onClosePopup();
            });
    }

    onCancelAndNavigation() {
        this.onClosePopup();
        this.popupProvider.deferred();
    }

    onClosePopup = () => {
        this.showConfirmationPopup = false;
    };

    onSavePopup = () => {
        this.save();
    };

    onCancelPopup = () => {
        this.cancel();
    };
}

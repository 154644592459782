<div class="user_record">
    <div class="block">
        <div>
            <div class="block__login">{{ objUser.login }}</div>
            <div class="block__email">{{ objUser.email }}</div>
        </div>
        <div class="block__role_wrapper">
            <div class="block__img block__img-{{ objUser.roleId }}"></div>
            <div class="block__role">{{ TEXTS.USER_ACCESS_TYPE[objUser.roleId] }}</div>
        </div>
        <div class="more" #popupOpener (click)="openPopup()"></div>
    </div>
</div>
<ng-template #popupOutlet>
    <div
        class="user_popup"
        *ngIf="popupActive"
        (clickOutside)="closePopup($event)"
        [style.top]="popupPositionTop"
    >
        <div
            class="card_popup__item"
            (click)="usersService.openEditUser(objUser); closePopup($event)"
        >
            {{ TEXTS.NOTIFICATIONS.edit }}
        </div>
        <div class="card_popup__separator"></div>
        <div
            class="card_popup__item card_popup__item--danger"
            #popupOpener
            (click)="showDeleteUserPopup(); usersService.selectUserToDelete(objUser)"
        >
            {{ TEXTS.LIST_USERS.deleteUser }}
        </div>
    </div>
    <stnd-ask-popup
        *ngIf="usersService.isShowDeleteUserPopup"
        [accept]="usersService.deleteUser"
        [close]="hideDeleteUserPopup"
        [texts]="TEXTS.POPUP_DELETE_USER"
    >
    </stnd-ask-popup>
</ng-template>

<div class="heights-wrapper"
     (mousemove)="showTooltip($event)"
     (mouseout)="hideTooltip()"
>
    <div class="bg" (click)="clickBg($event)"></div>
    <div #slider class="slider" (click)="setValue($event)">
        <div class="handler">
            <div class="step"
                 *ngFor="let i of heights; first as isFirst"
                 [ngStyle]="{ 'height': step + 'px' }"
                 [ngClass]="{ 'first': isFirst }"
            ></div>
            <div
                #handle
                class="handle"
                (mousedown)="startHandle($event)"
                (touchstart)="startTouchHandle($event)"
                (click)="$event.stopPropagation()"
            >
            </div>
            <div #flag class="flag-wrapper"
                (mousedown)="startHandle($event)"
                (touchstart)="startTouchHandle($event)"
                (click)="$event.stopPropagation()"
            >
                <div class="flag-container">
                    <div class="flag-content">
                        <div class="text">{{hintText}} {{textShortMetr}}</div>
                        <div
                            class="arrows-icon"
                            [ngClass]="{'hidden': currentHeight === heights[0]}"
                            (click)="setFirstValue()"
                        ></div>
                    </div>
                    <div class="end-icon"></div>
                </div>
            </div>


        </div>
        <div #stub class="stub"></div>
    </div>
    <div #tooltip class="tooltip-wrapper">
        <div class="tooltip-container">
            <div class="content">
                <div class="text">{{tooltipHeight}} {{textShortMetr}}</div>
            </div>
            <div class="end-icon"></div>
        </div>
    </div>
</div>

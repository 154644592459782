import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NgLocalization } from '@angular/common';
import { debounceTime } from 'rxjs/operators';

import { UsersService } from '../../users.service';
import { TEXTS } from '@libs/common/texts/texts';
import { MonitoringObject, UserItems } from '@cityair/namespace';
import { AdminPanelApi } from '@cityair/libs/common/api/adminPanel/api';
import { differentObject } from '@cityair/utils/utils';
import { copyObj } from '@libs/common/utils/utils';
import { selectedPostsText } from '@cityair/modules/notifications/notifications.utils';
import { MessageAPIResponseService } from '@libs/shared-ui/components/little-components/message-api-response/message-api-response.service';

@Component({
    selector: 'edit-user',
    templateUrl: 'edit-user.component.html',
    styleUrls: ['edit-user.component.less'],
})
export class EditUser implements OnInit {
    @Input() users: UserItems[];
    @Input() mos: MonitoringObject[];

    TEXTS = TEXTS;
    usersControl: {
        login: UntypedFormControl;
        email: UntypedFormControl;
    };

    notShowTooltip = true;
    showUsersRole = false;
    saving = false;

    isEditMode = false;
    isUserFound = null;
    newUserProps: UserItems;

    constructor(
        private adminPanelApi: AdminPanelApi,
        private ngLocalization: NgLocalization,
        public stateService: UsersService,
        private msgService: MessageAPIResponseService,
        private usersService: UsersService
    ) {}

    selectedPostsText = selectedPostsText.bind(null, this.ngLocalization);

    ngOnInit() {
        if (this.stateService.currentUser) {
            this.newUserProps = copyObj(this.stateService.currentUser);
            this.isEditMode = true;
        }

        this.usersControl = {
            login: new UntypedFormControl(
                {
                    value: this.stateService.currentUser?.login,
                    disabled: true,
                },
                [this.usernameValidator, Validators.minLength(1)]
            ),
            email: new UntypedFormControl(
                {
                    value: this.stateService.currentUser?.email,
                    disabled: !!this.stateService.currentUser,
                },
                [this.emailInGroupValidator, Validators.minLength(5), Validators.email]
            ),
        };

        this.usersControl.email.valueChanges.pipe(debounceTime(300)).subscribe(async (value) => {
            if (value && this.usersControl.email.valid) {
                const user = await this.adminPanelApi.haveTheUser(value);

                if (user) {
                    this.newUserProps = {
                        ...new UserItems(),
                        ...user,
                        roleId: 2,
                    };
                    this.usersControl.login.setValue(user.login);
                    this.usersControl.login.disable();

                    this.isUserFound = true;
                } else {
                    this.newUserProps = new UserItems();
                    this.newUserProps.email = value;
                    this.newUserProps.login = value.substring(0, value.indexOf('@'));
                    this.newUserProps.roleId = 2;
                    this.usersControl.login.setValue(this.newUserProps.login);
                    this.usersControl.login.enable();

                    this.isUserFound = false;
                }
            }
        });

        this.usersControl.login.valueChanges.subscribe((value) => {
            if (value && this.usersControl.login.valid) {
                this.newUserProps.login = value;
            }
        });
    }

    emailInGroupValidator = (formControl: UntypedFormControl) =>
        this.users.find((u) => u.email === formControl.value)
            ? { emailInGroupValidator: { message: 'user in group' } }
            : null;

    usernameValidator = (formControl: UntypedFormControl) =>
        formControl.value.match(/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~\.-]*$/)
            ? null
            : { usernameValidator: { message: 'error usernameValidator' } };

    updateUsersMO = (mos: MonitoringObject[]) => {
        this.newUserProps.linksToMo = mos;
    };

    toggleUsersRole(e: MouseEvent) {
        e.stopPropagation();
        this.showUsersRole = !this.showUsersRole;
    }

    hideUserRoles() {
        this.showUsersRole = false;
    }

    stopPropagation(e: MouseEvent) {
        e.stopPropagation();
    }

    checkChangeRole = () => this.newUserProps?.roleId !== this.stateService.currentUser?.roleId;

    checkChangeMos = () =>
        differentObject(
            this.newUserProps?.linksToMo,
            this.stateService.currentUser?.linksToMo,
            true
        );

    apply = async () => {
        this.saving = true;

        const respSuccess = await this.usersService.applyUserUpdates(
            this.newUserProps,
            this.isEditMode,
            this.checkChangeRole(),
            this.checkChangeMos()
        );

        this.saving = false;

        if (respSuccess) {
            this.stateService.setDefaultState();

            this.msgService.setMsg({
                type: 'success',
                message: this.isEditMode
                    ? TEXTS.LIST_USERS.whenSaving
                    : this.isUserFound
                    ? TEXTS.LIST_USERS.whenAdd
                    : TEXTS.LIST_USERS.whenCreate,
            });
        }
    };

    isDisabled = () => {
        if (this.saving) {
            return true;
        }

        if (this.isEditMode) {
            return !(this.checkChangeRole() || this.checkChangeMos());
        } else {
            const { login, email } = this.usersControl;
            return !(
                ((login.valid && login.value) || login.disabled) &&
                email.valid &&
                email.value
            );
        }
    };
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    FilterData,
    MultipleSearchfilterPipe,
    MultipleSearchFnsPipe,
    SafeHtmlPipe,
    SortingPipe,
    FilterByPipe,
    AsyncSortingPipe,
    OrderByPipe,
} from './pipes';

@NgModule({
    imports: [CommonModule],
    exports: [
        FilterData,
        MultipleSearchfilterPipe,
        MultipleSearchFnsPipe,
        SafeHtmlPipe,
        SortingPipe,
        AsyncSortingPipe,
        FilterByPipe,
        OrderByPipe,
    ],
    declarations: [
        FilterData,
        MultipleSearchfilterPipe,
        MultipleSearchFnsPipe,
        SafeHtmlPipe,
        SortingPipe,
        AsyncSortingPipe,
        FilterByPipe,
        OrderByPipe,
    ],
})
export class PipesModule {}

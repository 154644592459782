import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Source } from '@cityair/modules/plumes/services/run/models';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'plumes-source',
    templateUrl: 'plumes-source.component.html',
    styleUrls: ['plumes-source.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlumesSource implements OnChanges {
    @Input() source: Source;
    @Input() isActive = true;
    textNames = TEXTS.NAMES;
    list = [];

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.source?.currentValue) {
            const name = changes.source.currentValue.species;
            if (name) this.list.push(this.textNames[name] ?? name);
        }
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { DailyChartComponent } from '@cityair/modules/indoor/components/daily-chart/daily-chart.component';

@NgModule({
    imports: [CommonModule, NgChartsModule],
    declarations: [DailyChartComponent],
    exports: [DailyChartComponent],
})
export class DailyChartModule {}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

import { selectTimeRange, selectTzMinutesOffset } from '@cityair/modules/core/store/selectors';
import { CalendarOutput } from '@cityair/namespace';
import {
    GroupExtConfigName,
    GroupFeaturesService,
} from '@cityair/modules/core/services/group-features/group-features.service';
import { RoutingService } from '@cityair/modules/core/routing.service';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';

@Component({
    selector: 'calendar',
    template: `
        <div class="calendar" (clickOutside)="close()">
            <ng-container *ngIf="getTimeRange$ | async as dateRange">
                <div class="calendar__date" (click)="toggleShow()">
                    {{ dateRange | formatDate: (tzOffset$ | async) }}
                </div>

                <calendar-months
                    [show]="showCalendar"
                    [timeBegin]="dateRange?.begin"
                    [timeEnd]="dateRange?.end"
                    [tzOffset]="tzOffset$ | async"
                    [applyCb]="updateTime"
                    [updateDays]="updateDays"
                    [monthsVisible]="monthsVisible"
                    [columns]="columns > 4 ? 4 : columns"
                    [numberOfMonths]="12"
                ></calendar-months>
            </ng-container>
        </div>
    `,
    styleUrls: ['calendar.less'],
})
export class Calendar {
    @Input() monthsVisible: number;
    @Input() columns: number;
    showCalendar = false;

    @Output() updateDateRange = new EventEmitter<CalendarOutput>();

    getTimeRange$ = this.store.pipe(selectTimeRange);
    tzOffset$ = this.store.select(selectTzMinutesOffset);

    handleUpdateDays: (tzOffset: number, numberOfFutureDays?: number, minDate?: string) => void;

    constructor(
        public store: Store,
        private groupFeaturesService: GroupFeaturesService,
        private routingService: RoutingService
    ) {}

    updateTime = (begin: number, end: number) => {
        this.updateDateRange.emit({ begin, end });
        this.showCalendar = false;
    };

    updateDays = (fn: (tzOffset: number, numberOfFutureDays?: number, minDate?: string) => void) =>
        (this.handleUpdateDays = fn);

    toggleShow = () => {
        this.showCalendar = !this.showCalendar;

        if (this.showCalendar) {
            let calendarConf: { calendarMinDate?: string; numberOfFutureDays?: number } = {};
            if (this.routingService.getActivePage() === MAIN_PAGES.forecast) {
                calendarConf = this.groupFeaturesService.getConfig(
                    GroupExtConfigName.forecastConfig
                );
            }

            this.store
                .select(selectTzMinutesOffset)
                .pipe(first())
                .subscribe((tzOffset) => {
                    this.handleUpdateDays(
                        tzOffset,
                        calendarConf?.numberOfFutureDays,
                        calendarConf?.calendarMinDate
                    );
                });
        }
    };

    close() {
        this.showCalendar = false;
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AqiType } from '@libs/common/enums/aqi.type';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { MapControlPins, MapPins } from '@cityair/namespace';
import { isRU, TEXTS } from '@libs/common/texts/texts';
import {
    selectGlobalMeasurement,
    selectIsCityMode,
    selectIsSidebarOpen,
    selectMeasureScheme,
    selectPdkForChart,
} from '@cityair/modules/core/store/selectors';
import { MapAdapterService } from '@cityair/modules/map/services/map-adapter.service';
import {
    GroupExtConfigName,
    GroupFeaturesService,
} from '@cityair/modules/core/services/group-features/group-features.service';
import {
    currentForecastMmt,
    getControlPointsForMap,
    getControlPointValues,
    getStations,
    getStationsValues,
    isDraggableControlPoint,
    selectChartData,
    selectCurrentTimeForecast,
    selectDatesForecast,
    selectForecastSchema,
    selectForecastSchemaZones,
    selectForecastZone,
    selectListActive,
    stationLoaded,
} from '../../store/selectors';
import { ControlPointForecast, Station } from '../../models';
import { ForecastState } from '../../store/reducers';
import {
    setActivePoint,
    setActiveStation,
    setCoordinates,
    updateTimeIndex,
} from '../../store/actions';
import { getDigitsAfterDot } from '@cityair/config';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { getColorFromZone } from '@cityair/utils/utils';
import { NEW_CONTROL_POINT_OBJ_TYPE, NUMBER_ROUND_COORDINATES } from '../../constants';
import { GroupChartConfig } from '@libs/common/models/group-chart-config.model';
import { aqiDataProviderFactory } from '@cityair/modules/core/utils';
import { loadCity, openInstantAqiFaq } from '@cityair/modules/core/store/actions';

@Component({
    selector: 'forecast-map',
    templateUrl: './forecast-map.component.html',
    styleUrls: ['./forecast-map.component.less'],
})
export class ForecastMapComponent implements OnInit, OnDestroy {
    TEXTS = TEXTS;
    isRU = isRU;
    AqiType = AqiType;

    selectIsSidebarOpen = selectIsSidebarOpen;
    currentForecastMmt = currentForecastMmt;
    selectDatesForecast = selectDatesForecast;
    stationLoaded = stationLoaded;
    selectGlobalMeasurement = selectGlobalMeasurement;
    chartMinMax: GroupChartConfig;
    MeasureScheme = MeasureScheme;
    selectMeasureScheme = selectMeasureScheme;
    updateTimeIndex = updateTimeIndex;
    selectCurrentTimeForecast = selectCurrentTimeForecast;
    selectForecastSchemaZones = selectForecastSchemaZones;
    selectForecastSchema = selectForecastSchema;
    selectForecastZone = selectForecastZone;
    selectPdkForChart = selectPdkForChart;
    selectChartData = selectChartData;
    selectIsCityMode = selectIsCityMode;

    aqiDataProvider = aqiDataProviderFactory(this.store);

    constructor(
        readonly store: Store,
        readonly storeForecast: Store<ForecastState>,
        private mapAdapterService: MapAdapterService,
        private groupFeaturesService: GroupFeaturesService
    ) {}

    ngOnInit() {
        this.chartMinMax = this.groupFeaturesService.getGroupChartConfig();

        const postPins: MapPins = {
            getPins: this.storeForecast.select(getStations),
            selectDigitsAfterDot: this.store
                .select(selectForecastSchema)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: this.storeForecast.select(selectListActive),
            getValue: (pin) => this.storeForecast.select(getStationsValues(pin as Station)),
            getColor: (pin) =>
                combineLatest([
                    this.storeForecast.select(getStationsValues(pin as Station)),
                    this.storeForecast.select(selectForecastZone),
                ]).pipe(map(([value, zone]) => getColorFromZone(zone, value))),
            clickCb: (pin) => {
                const station = pin as Station;
                if (station?.data?.measurements) {
                    this.store.dispatch(setActiveStation({ payload: station }));
                }
            },
        };

        const controlPointPins: MapControlPins = {
            getPins: this.storeForecast.select(getControlPointsForMap),
            selectDigitsAfterDot: this.store
                .select(selectForecastSchema)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: this.storeForecast.select(selectListActive),
            getValue: (pin) => this.storeForecast.select(getControlPointValues(pin)),
            getColor: (pin) =>
                combineLatest([
                    this.storeForecast.select(getControlPointValues(pin)),
                    this.storeForecast.select(selectForecastZone),
                ]).pipe(map(([value, zone]) => getColorFromZone(zone, value))),
            clickCb: (pin) => {
                const cp = pin as ControlPointForecast;
                if (cp?.obj === NEW_CONTROL_POINT_OBJ_TYPE) {
                    return;
                }
                if (cp.timeseries) {
                    this.store.dispatch(setActivePoint({ payload: cp }));
                }
            },
            isDraggable: (pin: ControlPointForecast) =>
                this.storeForecast.select(isDraggableControlPoint(pin)),
            dragEnd: ($event, pin: ControlPointForecast) => {
                this.store.dispatch(
                    setCoordinates({
                        payload: {
                            lat: parseFloat($event?._lngLat.lat.toFixed(NUMBER_ROUND_COORDINATES)),
                            lon: parseFloat($event?._lngLat.lng.toFixed(NUMBER_ROUND_COORDINATES)),
                        },
                    })
                );
            },
        };

        const forecastSettings = this.groupFeaturesService.getConfig(
            GroupExtConfigName.forecastMapSettings
        );

        this.mapAdapterService.set({
            zoom: forecastSettings?.zoom || undefined,
            center: forecastSettings?.center || undefined,
            postPins,
            controlPointPins,
        });
    }

    ngOnDestroy() {
        this.mapAdapterService.setDefaultMap();
    }

    getDigits = (measureScheme: MeasureScheme) => (mmt) => getDigitsAfterDot(measureScheme, mmt);

    openAqiFaqLink(aqiType: AqiType) {
        if (aqiType === AqiType.instant) {
            this.store.dispatch(openInstantAqiFaq());
        }
    }

    public goToCity(cityId: string) {
        this.store.dispatch(loadCity({ cityId, centringMap: true }));
    }
}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import * as moment from 'moment';

import { PACKET_VALUE_TYPE_ID } from '@cityair/config';
import { TEXTS } from '@libs/common/texts/texts';

import {
    MoExcessInfo,
    NotificationEventType,
    NotificationFeedItem,
    NotificationSubscribeType,
    ServiceExcessType,
} from '@cityair/modules/login/services/harvester-api/adminApiModels';
import { shortDateFormatWithToday, toShortISOString } from '@libs/common/utils/date-formats';

const enum DurationMap {
    STARTED = 'begin',
    CONTINUING = 'continue',
}

@Component({
    selector: 'event-body',
    templateUrl: 'event-body.component.html',
    styleUrls: ['event-body.component.less'],
})
export class EventBody implements OnChanges {
    @Input() notification: NotificationFeedItem;
    @Input() included = false;
    @Input() todayDate: Date;
    @Input() disableNavigation: boolean;

    @Output() gotoMonitoringObject = new EventEmitter<{
        moId: number;
        dateTime: string;
        mmt?: string;
    }>();

    durationType: DurationMap;
    textsNotification = TEXTS.NOTIFICATIONS;
    eventTypes = NotificationEventType;
    serviceExcessTypes = ServiceExcessType;
    beginFormatted = '';
    updateFormatted = '';
    pdkValues = '';
    valueName = '';

    ngOnChanges() {
        this.durationType = this.hasEnded ? DurationMap.CONTINUING : DurationMap.STARTED;

        if (this.isPdkType) {
            this.beginFormatted = shortDateFormatWithToday(
                this.todayDate,
                this.notification.BeginTime
            );
            this.updateFormatted = shortDateFormatWithToday(
                this.todayDate,
                this.notification.UpdateTime
            );
            this.valueName = this.getValueName(this.notification.PdkValueType);

            if (!this.included) {
                this.pdkValues = this.getPdkValues();
            }
        }
    }

    get hasEnded() {
        return this.notification.EventType === this.eventTypes.End;
    }

    get isPdkType() {
        return this.notification.SubscribeType === NotificationSubscribeType.PdkExcess;
    }

    get isServiceType() {
        return this.notification.SubscribeType === NotificationSubscribeType.ServiceDevice;
    }

    getValueName(value: number) {
        const name = PACKET_VALUE_TYPE_ID[value];
        return TEXTS.NAMES[name];
    }

    getPdkValues() {
        const { MaxValue, PdkValue } = this.notification.PdkMoContent;
        return [
            this.valueName,
            `${this.textsNotification.qmax}:`,
            (MaxValue / PdkValue).toFixed(1),
            this.textsNotification.excessLevelUnits,
        ].join(' ');
    }

    showInTimeline(moInfo: MoExcessInfo) {
        const { Begin, Continue, End } = this.eventTypes;
        const { BeginTime, UpdateTime, EndTime } = this.notification;
        let dateTime: string = {
            [Begin]: BeginTime,
            [Continue]: UpdateTime,
            [End]: EndTime,
        }[this.notification.EventType];

        const { MoId } = moInfo;

        // remove fine details for the charts
        dateTime = toShortISOString(moment(dateTime).seconds(0).milliseconds(0).toDate());

        this.gotoMonitoringObject.emit({
            moId: MoId,
            dateTime,
            mmt: this.valueName,
        });
    }
}

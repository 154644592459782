import { DataProvider3 } from '../dataProvider/DataProvider';
import * as Models from './cityairMapModels';

import { GetObserverPacketsResponce } from '../adminPanel/models';
import { WindowGlobalVars } from '@cityair/namespace';

import { ErrorResult } from '@cityair/modules/login/services/harvester-api/models';
import { CityScreenTimelineRequest, CityScreenTimelineResponse } from './cityairMapModels';
import { Injectable } from '@angular/core';

declare const window: WindowGlobalVars;

function generateRequestId() {
    return '?requestId=' + Math.random().toString().substring(2);
}

@Injectable({ providedIn: 'root' })
export class CityairMapProvider {
    cpController = 'CityairMap';

    setToken = (request: any) => {
        request.Token = window.JS_CP_TOKEN;
        return request;
    };

    constructor(private readonly dataProvider: DataProvider3) {}

    GetCityScreenTimeline(
        request: CityScreenTimelineRequest,
        execOk: (responce: CityScreenTimelineResponse) => void,
        execFail: (errorResult: ErrorResult) => void
    ) {
        this.dataProvider.execHttp(
            this.cpController,
            'GetCityScreenTimeline' + generateRequestId(),
            this.setToken(request),
            execOk,
            execFail
        );
    }

    GetMapPackets(
        request: Models.MoPacketsRequest,
        execOk: (responce: GetObserverPacketsResponce) => void,
        execFail: (errorResult: ErrorResult) => void
    ) {
        this.dataProvider.execHttp(
            this.cpController,
            'GetMapPackets' + generateRequestId(),
            this.setToken(request),
            execOk,
            execFail
        );
    }

    GetMapPacketsCityScreen(
        request: Models.MoPacketsRequest,
        execOk: (responce: GetObserverPacketsResponce) => void,
        execFail: (errorResult: ErrorResult) => void
    ) {
        this.dataProvider.execHttp(
            this.cpController,
            'GetMapPacketsCityScreen' + generateRequestId(),
            this.setToken(request),
            execOk,
            execFail
        );
    }
}

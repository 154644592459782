<div class="measure-wrapper">
    <div class="label">
        {{ labelText }}
    </div>
    <div class="list" [ngClass]="{ 'edit-mode': mode === 'edit' }">
        <div
            class="item"
            [ngClass]="{ active: isAll }"
            *ngIf="mode === 'edit' && multiSelect"
            (click)="selectAllValues()"
        >
            {{ allText }}
        </div>
        <div
            class="item"
            [ngClass]="{ active: isSelected(value), disabled: list.length === 1 }"
            *ngFor="let value of list"
            [innerHTML]="NAMES[value] ?? value"
            (click)="toggleValue(value)"
        ></div>
    </div>
</div>

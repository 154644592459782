import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import * as reportsActions from './actions';

import { DateRange, IReportKind, Reports, statusMessage, UserData } from './../models';
import { FINISH_DATE_REPORTS, START_DATE_REPORTS } from './../constant';

export interface ReportsState {
    initDateRange: DateRange;
    currentKind: IReportKind;
    currentKindId: number;
    currentKindNotFound: boolean;
    userData: UserData;
    createReportError: any;
    statusMessages: statusMessage[];
    reportsUpdateStatus: {
        [key in number]: statusMessage;
    };
    needReloadData: boolean;
    isActive: boolean;
    sortingList: string;
    downloadLink: {
        url: string;
        report: Reports;
    };
    renameReportSuccess: { id: number; name: string };
    renameReportError: any;
}

export const initialReportState: ReportsState = {
    initDateRange: {
        startDate: new Date(START_DATE_REPORTS).toISOString(),
        finishDate: new Date(FINISH_DATE_REPORTS).toISOString(),
    },
    currentKind: null,
    currentKindId: null,
    currentKindNotFound: false,
    userData: null,
    createReportError: null,
    statusMessages: [],
    reportsUpdateStatus: null,
    needReloadData: false,
    isActive: false,
    sortingList: 'date',
    downloadLink: null,
    renameReportSuccess: null,
    renameReportError: { error: null, report: null },
};

const _reportsReducer = createReducer(
    initialReportState,
    on(reportsActions.setUserData, (state: ReportsState, props) => ({
        ...state,
    })),
    on(reportsActions.toggleReportModule, (state: ReportsState, props) => ({
        ...state,
        isActive: props.payload,
    })),
    on(reportsActions.setCurrentKind, (state: ReportsState, props) => ({
        ...state,
        currentKind: props,
    })),
    on(reportsActions.setCurrentKindId, (state: ReportsState, props) => ({
        ...state,
        currentKindId: props.id,
    })),
    on(reportsActions.updateCurrentReportKind, (state: ReportsState, props) => ({
        ...state,
        currentKind: props.payload,
    })),
    on(reportsActions.setKindNotFound, (state: ReportsState, props) => ({
        ...state,
        currentKindNotFound: props.payload,
    })),
    on(reportsActions.addReportError, (state: ReportsState, props) => ({
        ...state,
        createReportError: props.payload,
    })),
    on(reportsActions.updateDateRange, (state: ReportsState, props) => ({
        ...state,
        initDateRange: props.payload,
    })),
    on(reportsActions.setLastReportsStatus, (state: ReportsState, props) => ({
        ...state,
        reportsUpdateStatus: props.payload,
    })),
    on(reportsActions.reloadData, (state: ReportsState, props) => ({
        ...state,
        needReloadData: props.payload,
    })),
    on(reportsActions.addStatusMessage, (state: ReportsState, props) => ({
        ...state,
        statusMessages: state.statusMessages.concat(props.payload),
    })),
    on(reportsActions.removeStatusMessage, (state: ReportsState, props) => {
        const current = [...state.statusMessages].filter((item) => item.id !== props.payload.id);
        return {
            ...state,
            statusMessages: current,
        };
    }),
    on(reportsActions.setSortingReportList, (state: ReportsState, props) => ({
        ...state,
        sortingList: props.payload,
    })),
    on(reportsActions.downloadSuccess, (state: ReportsState, props) => ({
        ...state,
        downloadLink: props.payload,
    })),
    on(reportsActions.updateReportError, (state: ReportsState, props) => ({
        ...state,
        renameReportError: props.payload,
    })),
    on(reportsActions.updateReportSuccess, (state: ReportsState, props) => ({
        ...state,
        renameReportSuccess: props.payload,
    }))
);

export interface IReportsState {
    core: ReportsState;
}

export function reportReducer(state, action): any {
    return _reportsReducer(state, action);
}

export const reportsReducers: ActionReducerMap<IReportsState> = {
    core: reportReducer,
};

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AQI } from '@libs/common/consts/substance.consts';
import { measureZones } from '@libs/common/helpers/measure-zones';

@Component({
    selector: 'cs-aqi-indicator-simple',
    templateUrl: './aqi-indicator-simple.component.html',
    styleUrls: ['./aqi-indicator-simple.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AqiIndicatorSimpleComponent {
    @Input() value: number;

    measureZones = measureZones;
    AQI = AQI;
}

<div class="control-point-create scroll" *ngIf="!loading">
    <div class="form scroll">
        <header-of-panel
            class="header-of-panel"
            [path]="navigationLink"
            (outputPath)="back()"
            [title]="isEditMode ? translateText.editControlPoint : translateText.newControlPoint"
        ></header-of-panel>
        <div class="name-wrapper" *ngIf="isEditMode && currentPoint">
            <static-tags
                class="post-edit__header-static-tags"
                typeTag="mo"
                [text]="currentPoint?.name"
            ></static-tags>
        </div>
        <form [formGroup]="ControlPointCreate" novalidate>
            <div class="wrapper-content">
                <ca-input
                    class="input-wrapper"
                    type="text"
                    controlName="name"
                    [form]="ControlPointCreate"
                    [valueForm]="name.value"
                    [label]="translateText.nameLabel"
                    [textError]="getError('name')"
                ></ca-input>
            </div>
            <section-line [text]="locationOnTheMap"></section-line>
            <div class="info-wrapper">
                <div class="info">
                    <svg
                        class="info-icon"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            x="20"
                            y="20"
                            width="20"
                            height="20"
                            rx="10"
                            transform="rotate(-180 20 20)"
                            fill="url(#paint0_linear_9546_68457)"
                        />
                        <path
                            d="M10 15C10.5523 15 11 14.5523 11 14V10C11 9.44772 10.5523 9 10 9C9.44772 9 9 9.44772 9 10V14C9 14.5523 9.44772 15 10 15Z"
                            fill="white"
                        />
                        <path
                            d="M10 7C10.5523 7 11 6.55228 11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44772 7 10 7Z"
                            fill="white"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_9546_68457"
                                x1="40"
                                y1="20"
                                x2="22.0624"
                                y2="41.7045"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#6795ED" />
                                <stop offset="0.368408" stop-color="#7387E6" />
                                <stop offset="1" stop-color="#9369D7" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <div class="info-description" [innerHTML]="infoText"></div>
                </div>
            </div>
            <div class="wrapper-content">
                <div class="wrapper-coordinate">
                    <input-for-coordinate
                        type="text"
                        controlName="lat"
                        [form]="ControlPointCreate"
                        [valueForm]="lat.value"
                        [label]="translateText.latLabel"
                        [textError]="getError('lat')"
                        [positioning]="'left'"
                    ></input-for-coordinate>
                    <input-for-coordinate
                        type="text"
                        controlName="lon"
                        [form]="ControlPointCreate"
                        [valueForm]="lon.value"
                        [label]="translateText.lngLabel"
                        [textError]="getError('lon')"
                        [positioning]="'right'"
                    ></input-for-coordinate>
                </div>
            </div>
        </form>
    </div>
    <div class="btn-wrapper" [ngClass]="{ 'edit-mode': isEditMode }">
        <btn-extra (click)="showDeletePopup()" *ngIf="isEditMode">
            {{ deleteText }}
        </btn-extra>
        <btn-cancel (click)="back()">
            {{ cancelText }}
        </btn-cancel>
        <btn-accept
            [disabled]="ControlPointCreate.pristine || ControlPointCreate.invalid"
            (cbClick)="onSubmit()"
            [isLoading]="loadingResponse"
        >
            <span *ngIf="isEditMode; else addText">{{ saveText }}</span>
            <ng-template #addText>{{ acceptText }}</ng-template>
        </btn-accept>
    </div>
    <div class="error-response-wrapper" *ngIf="errorResponse">
        <div class="title-status">{{ translateText.errorTitle }}</div>
        {{ errorResponse }}.&nbsp;{{ translateText.tryAgain }}.
    </div>
</div>
<div class="loading-wrapper" *ngIf="loading">
    <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
</div>
<ng-template #deleteControl>
    <popup-ask
        class="popup-ask"
        *ngIf="isShowDeletePopup"
        [@inOutAnimation]
        (cbAccept)="deleteAccept()"
        (cbClose)="deleteCancel()"
        [title]="translateText.titleDeletePopup(currentPoint.name)"
        [description]="translateText.bodyDeletePopup"
        [textAccept]="translateText.accept"
        [textCancel]="translateText.cancel"
        [showCloseIcon]="false"
    >
    </popup-ask>
</ng-template>

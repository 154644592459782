import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { Pages, UsersService } from '@cityair/modules/users/users.service';
import { Store } from '@ngrx/store';
import { selectMos, selectUsers } from '@cityair/modules/core/store/selectors';
import { ADMIN_ID } from '@cityair/config';
import { WindowGlobalVars } from '@cityair/namespace';
import { filter, map } from 'rxjs/operators';

declare const window: WindowGlobalVars;

@Component({
    selector: 'users-page',
    templateUrl: 'users-page.component.html',
})
export class UsersPage {
    selectUsers = selectUsers;
    selectMos = selectMos;

    usersFiltred$ = this.store.select(selectUsers).pipe(
        filter((users) => !!users?.length),
        map((users) => users.filter((u) => u.login !== window.JS_CP_LOGIN && u.roleId !== ADMIN_ID))
    );

    constructor(public usersService: UsersService, public store: Store) {}

    TEXTS = TEXTS;

    notShowTooltip = false;

    pages = Pages;

    @ViewChild('popupOpener', { static: true }) popupOpener: ElementRef<HTMLDivElement>;
    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    hideDeleteUserPopup = () => (this.usersService.isShowDeleteUserPopup = false);
}

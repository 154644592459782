import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgLocalization } from '@angular/common';

import type {
    ModelDownloadPopup,
    MoItemsDataToExcelRequestProps,
} from '@cityair/namespace';
import { TabModel } from '@libs/common/types/tab-model';
import type { CheckboxItem } from '@libs/common/types/checkbox-item';
import { MoExportType } from '@cityair/namespace';
import { TEXTS, LANGUAGE } from '@libs/common/texts/texts';

@Component({
    selector: 'download-popup-mo',
    templateUrl: 'download-popup-mo.component.html',
    styleUrls: ['download-popup-mo.component.less'],
})
export class DownloadPopupMoComponent implements OnInit {
    @Input() selectedIds: number[];
    @Input() downloadPopupObj: ModelDownloadPopup;
    @Input() monitoringObjects: { id: number; name: string }[] = [];
    @Input() errorDownloadMsg = '';
    @Input() currentTabInterval?;

    @Output() getSaveBtnDisabled = new EventEmitter<boolean>();
    @Output() objectForReport = new EventEmitter<MoItemsDataToExcelRequestProps>();

    posts: { id: number; name: string }[] = [];

    texts = TEXTS;
    language = LANGUAGE;
    MoExportType = MoExportType;
    objDropdownList: CheckboxItem[] = [];
    isDisabledBtnPdk = false;
    maxInlineMos = 3;
    isShareOfMPC = false;
    currentMos: string[] = [];
    objectForReportEmit: MoItemsDataToExcelRequestProps;
    arrMoOd: number[] = [];

    filterTabsInterval: TabModel[] = [
        {
            title: this.texts.TIME_NAMES.min5,
            type: 1,
        },
        {
            title: this.texts.TIME_NAMES.min20,
            type: 2,
        },
        {
            title: this.texts.TIME_NAMES.hour1,
            type: 3,
        },
        {
            title: this.texts.TIME_NAMES.day1,
            type: 4,
        },
    ];

    currentFilterTabs: TabModel;

    unitsMeasurementsList: TabModel[] = [
        {
            title: this.texts.ugM,
            type: 1,
        },
        {
            title: this.texts.mgM,
            type: 2,
        },
    ];

    currentUnitsMeasurements: TabModel = this.unitsMeasurementsList[0];

    constructor(private ngLocalization: NgLocalization) {}

    ngOnInit() {
        this.posts = [...this.monitoringObjects];

        this.posts.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
        });

        this.createObjDropdownList();

        const interval = this.currentTabInterval || 2;
        this.setTabsInterval(this.filterTabsInterval.find((f) => f.type === interval));
    }

    createObjDropdownList() {
        this.arrMoOd = this.selectedIds;

        this.posts.forEach((mo) => {
            const isChecked = this.arrMoOd.includes(mo.id);

            if (isChecked) {
                this.currentMos.push(mo.name);
            }

            this.objDropdownList.push({
                id: mo.id,
                label: mo.name,
                selected: isChecked,
            });
        });
    }

    getObjectForReport = (items: CheckboxItem[]) => {
        this.objDropdownList = items;
        this.currentMos = [];
        this.arrMoOd = [];

        this.posts.forEach((mo) => {
            this.objDropdownList.forEach((off) => {
                if (off.selected && mo.id === off.id) {
                    this.arrMoOd.push(mo.id);
                    this.currentMos.push(mo.name);
                }
            });
        });

        this.emitObject();
        this.getSaveBtnDisabled.emit(!this.currentMos.length);
    };

    getUnitMeasurement = (newtUnitMeasurement: TabModel) => {
        this.currentUnitsMeasurements = newtUnitMeasurement;
        this.emitObject();
    };

    setTabsInterval = (filterTab: TabModel) => {
        this.currentFilterTabs = filterTab;
        this.isDisabledBtnPdk = false;
        this.isDisabledBtnPdk = filterTab.type === 1 || filterTab.type === 3;

        if (this.isDisabledBtnPdk) {
            this.isShareOfMPC = false;
        }

        this.emitObject();
    };

    getPDK = () => {
        this.isShareOfMPC = !this.isShareOfMPC;
        this.emitObject();
    };

    emitObject = () => {
        this.objectForReportEmit = {
            timeBegin: this.downloadPopupObj.timeBegin,
            timeEnd: this.downloadPopupObj.timeEnd,
            moIds: this.arrMoOd,
            interval: this.currentFilterTabs.type,
            type: this.downloadPopupObj.downloadType,
            unitsType: this.currentUnitsMeasurements.type,
            insertPdk: this.isShareOfMPC,
        };

        this.objectForReport.emit(this.objectForReportEmit);
    };

    postsListLabels() {
        return this.getPostsListLabels(true, this.ngLocalization);
    }

    removePostFromList(index: number) {
        const idStation = this.currentMos[index];
        this.currentMos = this.currentMos.filter((_, i) => i !== index);

        this.objDropdownList.find((off) => {
            if (off.label === idStation) {
                off.selected = false;
                return true;
            }
        });

        this.getObjectForReport([...this.objDropdownList]);
        this.postsListLabels();
    }

    getPostsListLabels(showMore?: boolean, ngLocalization?: NgLocalization) {
        const { allPost, collapse } = TEXTS.NOTIFICATIONS;
        const empty = () => '...';
        return {
            all: () => allPost,
            expand:
                showMore && ngLocalization ? this.showMoreText.bind(null, ngLocalization) : empty,
            collapse: () => collapse,
        };
    }

    showMoreText(ngLocalization: NgLocalization, num: number = 0) {
        const { showMore, post } = TEXTS.NOTIFICATIONS;
        const category = ngLocalization.getPluralCategory(num, LANGUAGE);
        const words = {
            ru: [showMore, num],
        }[LANGUAGE] || [num, showMore];

        words.push(post[category]);
        return words.join(' ');
    }

    moDropdownOpened = false;

    selectedCheckboxText(num: number = 0) {
        const { selected, post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);

        return [selected[category], num, post[category]].join(' ');
    }
}

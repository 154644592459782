import { Component, TemplateRef, ViewChild } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import * as moment from 'moment';
import { saveDataToDisk } from '@cityair/utils/utils';
import { ErrorTransformer_model } from '@cityair/libs/common/api/dataProvider/DataProvider';
import { AdminPanelApi } from '@cityair/libs/common/api/adminPanel/api';
import {
    DownloadPopupData,
    ModelDownloadPopup,
    MoExportType,
    MoItemsDataToExcelRequestProps,
    StationDataToExcelRequestProps,
} from '@cityair/namespace';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { selectTimeRange } from '@cityair/modules/core/store/selectors';
import { DownloadPopupsService } from './download-popups.service';

@Component({
    selector: 'download-popups-wrapper',
    templateUrl: './download-popups-wrapper.component.html',
})
export class DownloadPopupsWrapperComponent {
    @ViewChild('popupDownload', { static: true }) popupDownload: TemplateRef<HTMLDivElement>;

    MoExportType = MoExportType;
    downloadType: MoExportType;
    title: string;
    ids: number[];
    mos: { id: number; name: string }[];
    devices: { id: number; serialNumber: string }[];
    currentTabInterval?: number;

    TEXTS = TEXTS;

    isSaveBtnDisabled = false;
    isActiveLoader = false;

    objectForReportMo: MoItemsDataToExcelRequestProps;
    objectForReportStation: StationDataToExcelRequestProps;
    downloadPopupObj: ModelDownloadPopup;

    errorDownloadMsg: string;

    constructor(
        private popupProvider: OffPanelPopupService,
        private adminPanelApi: AdminPanelApi,
        private store: Store,
        private downloadPopupsService: DownloadPopupsService
    ) {
        this.downloadPopupsService.openDownloadPopup$.asObservable().subscribe((data) => {
            this.showDownloadPopup(data);
        });
    }

    closeDownloadPopup = () => {
        this.popupProvider.clear();
    };

    getSaveBtnDisabled = (event: boolean) => {
        this.isSaveBtnDisabled = event;
    };

    getObjectForReportMO = (event) => {
        this.objectForReportMo = event;
    };

    private showDownloadPopup = (data: DownloadPopupData) => {
        this.store
            .pipe(selectTimeRange)
            .pipe(first())
            .subscribe((time) => {
                this.isSaveBtnDisabled = !data.ids?.length;
                this.downloadType = data.type;
                this.title = data.title;
                this.ids = data.ids;
                this.devices = data.devices;
                this.mos = data.mos;
                this.currentTabInterval = data.currentTabInterval;

                this.errorDownloadMsg = '';
                this.downloadPopupObj = {
                    timeBegin: time.begin,
                    timeEnd: time.end,
                    downloadType: data.type,
                    downloadTitle: data.title,
                };

                this.objectForReportMo = {
                    timeBegin: time.begin,
                    timeEnd: time.end,
                    moIds: this.ids,
                    interval: 2,
                    type: data.type,
                    unitsType: 1,
                    insertPdk: false,
                };

                this.popupProvider.setTemplate(this.popupDownload);
            });
    };

    downloadReportMo = () => {
        this.isActiveLoader = true;

        const filename =
            this.downloadPopupObj.downloadTitle + moment().format('_YYYYMMDD_HHmm') + '.xlsx';
        this.adminPanelApi
            .SaveMoDataToExcel(this.objectForReportMo)
            .then((data: string) => {
                saveDataToDisk(data, filename);
                this.closeDownloadPopup();
                this.isActiveLoader = false;
            })
            .catch((errorDownloadMsg: ErrorTransformer_model) => {
                this.errorDownloadMsg = errorDownloadMsg.message;
                this.isActiveLoader = false;
            });
    };

    downloadReportStation = () => {
        const fileExtension = this.objectForReportStation.type == 1 ? '.xlsx' : '.csv';
        const filename =
            TEXTS.DOWNLOAD_POPUP.filenameDataDevice +
            moment().format('_YYYYMMDD_HHmm') +
            fileExtension;
        this.isActiveLoader = true;
        this.adminPanelApi
            .SaveDeviceDataToExcel(this.objectForReportStation)
            .then((data: string) => {
                saveDataToDisk(data, filename);
                this.closeDownloadPopup();
                this.isActiveLoader = false;
            })
            .catch((errorDownloadMsg: ErrorTransformer_model) => {
                this.errorDownloadMsg = errorDownloadMsg.message;
                this.isActiveLoader = false;
            });
    };
}

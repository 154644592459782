<div class="plumes-sources-list scroll">
    <div class="plumes-sources-list__content">
        <div *ngIf="loadError">
            <div class="load-error-data">
                <span>{{ errorListLoadText }}</span>
            </div>
        </div>
        <div class="loading-config" *ngIf="loadingRunConfig$ | async; else runConfigTemplate">
            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
        </div>
        <ng-template #runConfigTemplate>
            <ca-card
                class="plumes-sources-list-run-config__item"
                *ngFor="let card of runConfig$ | async"
                [title]="card.name"
                [isActive]="card.is_active"
                [statusText]="textsPlumes.runConfigurationStatus"
                [actionsActive]="popupActive"
                [actionsTooltipText]="textAction"
                [hasActions]="!isOperator"
                (openActionsPopup)="openActionsPopup($event, card)"
            >
                <plumes-calculation
                    [minutes]="card.schedule_period"
                    [valueTypes]="card.species_list"
                ></plumes-calculation>
            </ca-card>
        </ng-template>

        <div class="plumes-sources-list__title" *ngIf="!loadError">
            {{ textsPlumes.sourcesListTitle }}
        </div>

        <div class="loading-config" *ngIf="loadingRunConfig$ | async; else sourcesTemplate">
            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
        </div>
        <ng-template #sourcesTemplate>
            <div class="plumes-sources-list__item" *ngFor="let source of sources$ | async">
                <plumes-source [source]="source"></plumes-source>
            </div>
        </ng-template>
    </div>
</div>

<ng-template #actionsOutlet>
    <card-actions-list [position]="popupPositionTop" (clickOutside)="closeActionsPopup($event)">
        <card-actions-item
            [text]="textsPlumes.editRunConfigurationAction"
            (click)="editRunConfiguration($event, selectedCard)"
        ></card-actions-item>

        <ng-container *ngIf="selectedCard.is_active; else notActive">
            <card-actions-item
                [text]="textsPlumes.stopRunConfigurationAction"
                (click)="stopRuns($event, selectedCard)"
            ></card-actions-item>
        </ng-container>

        <ng-template #notActive>
            <card-actions-item
                [text]="textsPlumes.startRunConfigurationAction"
                (click)="stopRuns($event, selectedCard)"
            ></card-actions-item>
        </ng-template>
    </card-actions-list>
</ng-template>

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
} from '@angular/core';
import { NgLocalization } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import {
    IReport,
    IReportKind,
    REPORTS_PAGES,
    REPORT_TEMPLATE_PARAMS_KEY,
    EXCLUDE_CREATE_FORM_KEY,
    IControl,
} from '@cityair/modules/reports/models';
import { Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { ReportKindService } from '@cityair/modules/reports/services/report-kind.service';
import { MonitoringObject } from '@cityair/namespace';
import type { CheckboxItem } from '@libs/common/types/checkbox-item';
import {
    selectCurrentKindNotFound,
    dataForCreateReport,
    selectReportCreateError,
} from '@cityair/modules/reports/store/selectors';
import {
    setCurrentKindId,
    setCurrentKind,
    setKindNotFound,
    addReportError,
} from '@cityair/modules/reports/store/actions';
import {
    COMMENT_MAX_LEN,
    COUNTRIES_FOR_TIMEZONE_SELECT,
    FINISH_DATE_REPORTS,
    FILE_NAME_MAX_LEN,
    START_DATE_REPORTS,
    TIMEOUT_SHOW_ALERT_MSG,
    THRESHOLD_DEFAULT_VALUE,
    THRESHOLD_MAX_VALUE,
    THRESHOLD_MIN_VALUE,
    THRESHOLD_STEP_VALUE,
    MARKETING_REPORT_KIND,
    DOWNLOAD_REPORT_KIND,
} from '@cityair/modules/reports/constant';
import { postsListLabels } from '@cityair/modules/notifications/notifications.utils';
import { ReportsService } from '@cityair/modules/reports/services/reports.service';
import * as moment from 'moment-timezone';
import { TEXTS, LANGUAGE, isRU } from '@libs/common/texts/texts';

@Component({
    selector: 'cityscreen-add-report',
    templateUrl: './add-report.component.html',
    styleUrls: ['./add-report.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddReportComponent implements OnDestroy {
    public id: number;
    public currentKind: IReportKind;
    public noFoundTemplate: boolean;
    public moObj: MonitoringObject[] = [];
    private groupId: number;
    public loadingResponse = false;
    public isShowPostSelect = false;

    public translateText = TEXTS.REPORTS;
    public cancelText = TEXTS.COMMON.cancel;
    public postsMonitoringText = TEXTS.POSTS_AND_DEVICES.postsMonitoring;
    public postMonitoringSearchText = TEXTS.NOTIFICATIONS.searchMoPlaceholderText;
    public selectAllText = TEXTS.NOTIFICATIONS.selectAll;
    public selectedAllText = TEXTS.NOTIFICATIONS.allMonitoringPostsSelected;
    public noSelectText = TEXTS.LIST_USERS.noSelect;
    public labelTexts = TEXTS.REPORTS.formCreate;
    public textsNotification = TEXTS.NOTIFICATIONS;
    public thresholdSlider: number;

    public ngDestroyed$ = new Subject<void>();
    public params = {
        timeBegin: new Date(START_DATE_REPORTS).getTime(),
        timeEnd: new Date(FINISH_DATE_REPORTS).getTime(),
        moIds: [],
    };
    PARAMS_KEY = REPORT_TEMPLATE_PARAMS_KEY;
    editControlPoint: UntypedFormGroup;
    isShowDropdownForStations = false;
    isShowDropdownFormat = false;
    listStations: CheckboxItem[] = [];
    marketingReportId = MARKETING_REPORT_KIND.id;
    downloadReportId = DOWNLOAD_REPORT_KIND.id;
    public ReportCreateForm: UntypedFormGroup;
    public showDateRange = false;
    public errorResponse: string;
    public lastKeyError: string[] = [];
    public tzList: string[];
    public tzValue = moment.tz.guess();
    public threshold_min_value = THRESHOLD_MIN_VALUE;
    public threshold_max_value = THRESHOLD_MAX_VALUE;
    public threshold_step_value = THRESHOLD_STEP_VALUE;
    public isRu = isRU;
    constructor(
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private reportKindService: ReportKindService,
        private reportService: ReportsService,
        private store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private ngLocalization: NgLocalization
    ) {
        this.id = +this.route.snapshot.paramMap.get('id');
        if (this.id === MARKETING_REPORT_KIND.id) {
            this.currentKind = MARKETING_REPORT_KIND;
        } else if (this.id === DOWNLOAD_REPORT_KIND.id) {
            this.currentKind = DOWNLOAD_REPORT_KIND;
        } else if (this.id) {
            this.store.dispatch(setCurrentKindId({ id: this.id }));
        } else {
            this.store.dispatch(setKindNotFound({ payload: true }));
        }

        this.initState();
    }

    get formName() {
        return this.ReportCreateForm.get('name');
    }

    get formThreshold() {
        return this.ReportCreateForm.get('threshold');
    }

    get formComment() {
        return this.ReportCreateForm.get('comment');
    }

    @HostListener('window:keydown.enter', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (this.ReportCreateForm.valid && !this.loadingResponse) {
            this.onSubmit();
        }
    }

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        this.close();
    }

    ngOnDestroy(): void {
        this.store.dispatch(setCurrentKind(null));
        this.store.dispatch(setCurrentKindId(null));
        this.ngDestroyed$.next();
    }

    public onSubmit() {
        this.loadingResponse = true;
        const formData = this.ReportCreateForm.getRawValue();
        const newReportObj: IReport = {
            name: `${formData.name}`,
            template_id: this.currentKind.id,
            params: this.generateParams(formData),
        };

        if (formData.comment) {
            newReportObj.comment = formData.comment;
        }

        this.reportService.add(newReportObj).subscribe((data) => {
            this.loadingResponse = false;
            this.close();
        });
    }

    public close(): void {
        this.router.navigate([`/${REPORTS_PAGES.reports}/${REPORTS_PAGES.reportsList}`]);
    }

    public changeCalendar(time) {
        this.params.timeBegin = time.begin;
        this.params.timeEnd = time.end;
        if (!this.ReportCreateForm.controls.name.touched) {
            const newName = this.autoGenerateReportName();
            this.ReportCreateForm.controls.name.setValue(newName);
        }
    }

    public selectedCheckboxText(num: number = 0) {
        const { selected, post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        if (num === 0) {
            return this.noSelectText;
        } else if (num === this.listStations.length) {
            return this.selectedAllText;
        }

        return [selected[category], num, post[category]].join(' ');
    }

    public getError(field: string): string {
        if (
            this.ReportCreateForm &&
            this.ReportCreateForm.controls[field].invalid &&
            (this.ReportCreateForm.controls[field].dirty ||
                this.ReportCreateForm.controls[field].touched)
        ) {
            if (this.ReportCreateForm.controls[field].errors.required) {
                return field === 'name' ? this.labelTexts.nameError : this.labelTexts.postMoError;
            }

            if (this.ReportCreateForm.controls[field].errors.maxlength) {
                return this.labelTexts.maxLength(
                    field,
                    this.ReportCreateForm.controls[field].errors.maxlength.requiredLength
                );
            }
            if (this.ReportCreateForm.controls[field].errors.max) {
                return this.labelTexts.max(
                    field,
                    this.ReportCreateForm.controls[field].errors.max.max
                );
            }
            if (this.ReportCreateForm.controls[field].errors.min) {
                return this.labelTexts.min(
                    field,
                    this.ReportCreateForm.controls[field].errors.min.min
                );
            }
        }

        return '';
    }

    public prepareListMo(): CheckboxItem[] {
        const result = this.moObj.map((s) => ({
            id: s.id,
            label: s.name,
            selected: true,
        }));

        return result;
    }

    public removeFromList(index: number) {
        const label = this.getTags()[index];
        this.listStations.find((s) => {
            if (s.label === label) {
                s.selected = false;
                return true;
            }
        });

        this.listStations = [...this.listStations];
    }

    public getTags = () => this.listStations.filter((s) => s.selected).map((s) => s.label);

    public postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    public updateSliderValue(event) {
        this.ReportCreateForm.controls.threshold.setValue(event);
    }

    private getMoIds() {
        return this.listStations.filter((s) => s.selected).map((s) => s.id);
    }

    private createForm(data: IReportKind) {
        const name = this.autoGenerateReportName();
        this.ReportCreateForm = this.fb.group({
            name: [name, [Validators.required, Validators.maxLength(FILE_NAME_MAX_LEN)]],
            comment: ['', [Validators.maxLength(COMMENT_MAX_LEN)]],
            threshold: [
                THRESHOLD_DEFAULT_VALUE,
                [
                    Validators.required,
                    Validators.min(THRESHOLD_MIN_VALUE),
                    Validators.max(THRESHOLD_MAX_VALUE),
                ],
            ],
        });
        if (data.params) {
            Object.values(REPORT_TEMPLATE_PARAMS_KEY).forEach((field) => {
                // add control by params expect date range
                if (
                    data.params.hasOwnProperty(field) &&
                    EXCLUDE_CREATE_FORM_KEY.indexOf(field) === -1
                ) {
                    const validator = data.params[field].required ? Validators.required : [];
                    const value = this.getInitFormValue(field, data.params[field]);
                    this.ReportCreateForm.addControl(
                        field,
                        new UntypedFormControl(value, validator)
                    );
                }
            });
            if (
                data.params.hasOwnProperty(REPORT_TEMPLATE_PARAMS_KEY.startDate) &&
                data.params.hasOwnProperty(REPORT_TEMPLATE_PARAMS_KEY.finishDate)
            ) {
                this.showDateRange = true;
            }
        }
    }

    private initState() {
        this.store
            .select(dataForCreateReport)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                if (data) {
                    this.moObj = data.moObj;
                    this.groupId = data.groupId;
                    this.isShowPostSelect = this.moObj.length ? true : false;
                    this.params.moIds = this.moObj.map((item) => item.id);
                    this.listStations = this.prepareListMo();
                    this.currentKind = data.template;
                    this.tzList = this.getTZList(this.tzValue);
                    this.createForm(data.template);
                    this._changeDetectorRef.markForCheck();
                }
            });
        this.store
            .select(selectCurrentKindNotFound)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.noFoundTemplate = data;
                this._changeDetectorRef.markForCheck();
                if (data) {
                    setTimeout(() => {
                        this.close();
                        this.store.dispatch(setKindNotFound({ payload: false }));
                    }, TIMEOUT_SHOW_ALERT_MSG);
                }
            });
        this.store
            .select(selectReportCreateError)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((value) => {
                if (value) {
                    const data = value.error?.error;
                    for (const key in data) {
                        if (data.hasOwnProperty(key) && this.ReportCreateForm.controls[key]) {
                            this.ReportCreateForm.controls[key].setErrors({
                                incorrect: true,
                                message: data[key].join(', '),
                            });
                            this.lastKeyError.push(key);
                        } else if (data.hasOwnProperty('detail')) {
                            this.errorResponse = data.detail;
                            this.loadingResponse = false;
                            this.clearError();
                        }
                    }

                    if (value.error?.status === 403) {
                        this.errorResponse = this.translateText.error403;
                        this.loadingResponse = false;
                        this.clearError();
                    }
                }
                this.loadingResponse = false;
                this._changeDetectorRef.markForCheck();
            });
    }

    private getInitFormValue(field: REPORT_TEMPLATE_PARAMS_KEY, obj: IControl) {
        switch (field) {
            case REPORT_TEMPLATE_PARAMS_KEY.postIds: {
                return this.params.moIds;
                break;
            }
            case REPORT_TEMPLATE_PARAMS_KEY.availableFormats: {
                return obj?.default ?? '';
                break;
            }
            case REPORT_TEMPLATE_PARAMS_KEY.measure: {
                const values = obj?.values ?? [];
                return obj?.default === 'all' ? values : [obj.default];
                break;
            }
            case REPORT_TEMPLATE_PARAMS_KEY.timezone: {
                return this.tzValue;
                break;
            }
            case REPORT_TEMPLATE_PARAMS_KEY.threshold: {
                return obj?.default;
            }
            case REPORT_TEMPLATE_PARAMS_KEY.year: {
                return obj?.default;
            }
            default: {
                return '';
            }
        }
    }

    private clearError() {
        setTimeout(() => {
            this.errorResponse = null;
            this.store.dispatch(addReportError({ payload: null }));
            this._changeDetectorRef.markForCheck();
        }, TIMEOUT_SHOW_ALERT_MSG);
    }

    private autoGenerateReportName() {
        if (
            this.currentKind &&
            this.currentKind.params &&
            this.currentKind?.params[this.PARAMS_KEY.startDate] &&
            this.currentKind?.params[this.PARAMS_KEY.finishDate]
        ) {
            const start = moment(this.params.timeBegin).format('DD.MM.YY');
            const end = moment(this.params.timeEnd).format('DD.MM.YY');
            const name = this.isRu ? this.currentKind.name : this.currentKind.name_eng;
            return `${name}_${start}-${end}`;
        }
        return `${this.currentKind.name}`;
    }

    public setValueDropDown($event) {
        if ($event.key === this.PARAMS_KEY.timezone) {
            this.tzValue = $event.value;
        } else {
            this.ReportCreateForm.controls[$event.key]?.setValue($event.value);
        }
    }

    public setMeasure($event) {
        this.ReportCreateForm.controls.measure?.setValue($event);
    }

    private getTZList(current) {
        let timeZones = [{ name: 'Etc/UTC', offset: 0 }];
        COUNTRIES_FOR_TIMEZONE_SELECT.forEach((v) => {
            const zones = moment.tz.zonesForCountry(v, true);
            timeZones = timeZones.concat(zones);
        });
        const currentTZ = timeZones.find((v) => v.name === current);
        if (!currentTZ) {
            return [current].concat(timeZones.map((item) => item.name));
        }
        timeZones.sort((a, b) => b.offset - a.offset);
        return timeZones.map((item) => item.name);
    }

    private generateParams(formData): any {
        const defaultParams = {
            group_id: this.groupId,
        };
        const params = new Object();
        Object.values(REPORT_TEMPLATE_PARAMS_KEY).forEach((field) => {
            // add control by params expect date range
            if (formData.hasOwnProperty(field) && EXCLUDE_CREATE_FORM_KEY.indexOf(field) === -1) {
                params[field] = formData[field];
            }
        });

        if (this.currentKind && this.currentKind.params) {
            if (this.currentKind?.params[this.PARAMS_KEY.postIds]) {
                const key = 'moIds';
                const moIds = this.getMoIds().map((item) => item.toString());
                params[key] = moIds;
            }
            if (
                this.currentKind?.params[this.PARAMS_KEY.startDate] &&
                this.currentKind?.params[this.PARAMS_KEY.finishDate]
            ) {
                const start = 'timeBegin';
                const finish = 'timeEnd';
                const timezone = 'timezone';
                params[timezone] = this.tzValue;
                params[start] = moment(this.params.timeBegin).format('YYYY-MM-DD');
                params[finish] = moment(this.params.timeEnd).format('YYYY-MM-DD');
            }
        }

        return { ...defaultParams, ...params };
    }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

export enum LayerButtonIconType {
    FORECAST = 'FORECAST',
    HOTSPOTS = 'HOTSPOTS',
    PLUME = 'PLUME',
    POST = 'POST',
}

@Component({
    selector: 'ca-layer-button',
    templateUrl: 'layer-button.component.html',
    styleUrls: ['layer-button.component.less'],
})
export class LayerButtonComponent {
    @Input() type;
    @Input() active;

    @Output() clickOnIcon = new EventEmitter<void>();
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntitySelectorsFactory } from '@ngrx/data';
import { IReport, IReportKind, STATUS_NEED_UPDATE } from './../models';
import { IReportsState, ReportsState } from './reducers';
import { selectGroupInfo } from '@cityair/modules/core/store/selectors';

export const reportKindSelectors = new EntitySelectorsFactory().create<IReportKind>('ReportKind');
export const reportsSelectors = new EntitySelectorsFactory().create<IReport>('Report');
export const reportState = createFeatureSelector<IReportsState>('reports');
export const selectReports = createSelector(
    reportsSelectors.selectEntities,
    (entities) => entities
);
export const selectReport = createSelector(
    reportState,
    (state: IReportsState): ReportsState => state.core
);
export const selectCurrentKind = createSelector(
    selectReport,
    (state: ReportsState) => state.currentKind
);
export const selectCurrentKindId = createSelector(
    selectReport,
    (state: ReportsState) => state.currentKindId
);
export const selectCurrentKindNotFound = createSelector(
    selectReport,
    (state: ReportsState) => state.currentKindNotFound
);
export const dataForCreateReport = createSelector(
    selectGroupInfo,
    selectCurrentKind,
    (groupInfo, template) => {
        if (groupInfo && template) {
            let groupId = null;
            let moObj = null;
            if (groupInfo.groupId) {
                groupId = groupInfo.groupId;
            }
            if (groupInfo.hasOwnProperty('monitoringObjects')) {
                moObj = groupInfo.monitoringObjects;
            }

            return {
                groupId,
                moObj,
                template,
            };
        }
    }
);
export const selectReportCreateError = createSelector(
    selectReport,
    (state: ReportsState) => state.createReportError
);
export const selectReportDateRange = createSelector(
    selectReport,
    (state: ReportsState) => state.initDateRange
);
export const selectParamsReport = createSelector(
    selectGroupInfo,
    selectReportDateRange,
    (groupInfo, date) => {
        if (groupInfo && groupInfo.groupId) {
            let groupId = null;
            if (groupInfo.groupId) {
                groupId = groupInfo.groupId;
            }
            let mObj = {};
            if (groupInfo.monitoringObjects) {
                mObj = Object.assign(
                    {},
                    ...groupInfo.monitoringObjects.map((item) => ({ [item.id]: item.name }))
                );
            }

            return {
                startDate: date.startDate,
                finishDate: date.finishDate,
                groupId: groupId.toString(),
                postList: mObj,
            };
        }
        return null;
    }
);
export const selectReportsForUpdate = createSelector(selectReports, (reports) =>
    reports.filter((report) => (STATUS_NEED_UPDATE.indexOf(report.status) >= 0 ? true : false))
);
export const selectLastReportStatus = createSelector(
    selectReport,
    (state: ReportsState) => state.reportsUpdateStatus
);
export const needReloadData = createSelector(
    selectReport,
    (state: ReportsState) => state.needReloadData
);
export const selectStatusMessages = createSelector(
    selectReport,
    (state: ReportsState) => state.statusMessages
);
export const isActiveReport = createSelector(selectReport, (state: ReportsState) => state.isActive);
export const selectLastSorting = createSelector(
    selectReport,
    (state: ReportsState) => state.sortingList
);
export const selectDownLoadLink = createSelector(
    selectReport,
    (state: ReportsState) => state.downloadLink
);
export const renameReportError = createSelector(
    selectReport,
    (state: ReportsState) => state.renameReportError
);
export const renameReportSuccess = createSelector(
    selectReport,
    (state: ReportsState) => state.renameReportSuccess
);

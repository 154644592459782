import { CityCard, City_model } from '@cityair/namespace';
import {
    createAction,
    createFeature,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
} from '@ngrx/store';

export const setCityCard = createAction(
    '[CityCardActions] SetCityCard',
    props<{ city: City_model }>()
);

interface CityCardState {
    cityCard: CityCard;
}

const initialState: CityCardState = {
    cityCard: {
        city: null,
    },
};

export const cityCardFeature = createFeature({
    name: 'cityCard',
    reducer: createReducer(
        initialState,
        on(setCityCard, (state, { city }) => ({
            cityCard: {
                city,
            },
        }))
    ),
});

const featureSelector = createFeatureSelector<CityCardState>('cityCard');

export const selectCityCard = createSelector(featureSelector, (state) => state.cityCard);

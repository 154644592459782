import { REPORTS_COLOR } from './constant';
import * as moment from 'moment-timezone';
import { formatDayMonthYear } from '@cityair/config';
export enum REPORTS_PAGES {
    reports = 'reports',
    analytics = 'analytics',
    reportsList = 'list',
    compare = 'compare',
    add = 'add',
}
export const REPORT_INIT_PAGE = REPORTS_PAGES.reportsList;
export enum REPORT_TEMPLATE_PARAMS_KEY {
    postIds = 'post_ids',
    startDate = 'start_date',
    finishDate = 'finish_date',
    availableFormats = 'available_formats',
    measure = 'measure',
    timezone = 'timezone',
    threshold = 'threshold',
    year = 'year',
}
export const EXCLUDE_CREATE_FORM_KEY = [
    REPORT_TEMPLATE_PARAMS_KEY.startDate,
    REPORT_TEMPLATE_PARAMS_KEY.finishDate,
    REPORT_TEMPLATE_PARAMS_KEY.timezone,
];
export interface DateRange {
    startDate: string;
    finishDate: string;
}
export interface IControl {
    required: boolean;
    default: string;
    values?: string[];
    control?: string;
}
export type ParamsControl = {
    [key in REPORT_TEMPLATE_PARAMS_KEY]: IControl;
};
export interface IReportKindParams {
    user_id: number;
}
export interface ICreateParam {
    kind: string;
    post_ids: (string | number)[];
    start_date: string;
    finish_date: string;
    name: string;
    comment: string;
    group_id: string;
}
export interface IReportParams {
    comment?: string;
    name: string;
    params: ParamsControl;
}
export interface reportsTemplate {
    id: string;
    name: string;
    name_ru: string;
    short_description: string;
    short_description_ru: string;
    description: string;
    description_ru: string;
    defaultFormat: string;
    availableFormat?: string[];
    params?: any;
}

export interface IReportKind {
    id: number;
    name: string;
    name_eng?: string;
    slug: string;
    short_description: string;
    short_description_eng: string;
    description: string;
    description_eng: string;
    default_format: string;
    available_format?: string[];
    params: any;
}
export interface ReportKind {
    id: string;
    name: string;
    name_eng: string;
    short_description: string;
    short_description_eng: string;
    description: string;
    description_eng: string;
    default_format: string;
    available_format?: string[];
    params: ParamsControl;
}
export type ReportFormat = 'docx' | 'pdf' | 'xlsx' | 'csv';
export enum REPORT_STATUS {
    CREATED = 'CREATED',
    QUEUED = 'QUEUED',
    IN_PROGRESS = 'IN_PROGRESS',
    ERROR = 'ERROR',
    READY = 'READY',
}
export const STATUS_NEED_UPDATE = [
    REPORT_STATUS.CREATED,
    REPORT_STATUS.QUEUED,
    REPORT_STATUS.IN_PROGRESS,
    REPORT_STATUS.ERROR,
];
export interface UserData {
    userId: number;
    email: string;
    login: string;
    roleId: number;
    roleName?: string;
    shortName?: string;
}
export interface UserReport {
    harvester_id: number;
    username: string;
    email: string;
    charAt?: string;
    avatarClass?: REPORTS_COLOR[keyof REPORTS_COLOR];
}
export interface IReport {
    id?: string;
    template_id?: number;
    file?: string;
    user?: UserReport;
    status?: REPORT_STATUS;
    create_date?: string;
    date?: number;
    update_date?: string;
    format?: string;
    name?: string;
    comment?: string;
    params?: any;
    kind?: IReportKind;
    group_id?: number;
}
export interface IKeyValue {
    key: REPORT_STATUS;
    value: number;
}
export interface IReportResponse {
    reports: IReport[];
    counter: IKeyValue[];
}
export interface ReportParams {
    postIds?: number[];
    measure?: string[];
    format?: string;
}
export class Reports implements IReport {
    readonly id;
    public name;
    public file;
    readonly template_id;
    readonly user: UserReport;
    readonly status: REPORT_STATUS;
    readonly createDate: string;
    public updateDate: string;
    public date: number;
    public format: string;
    public isDisableDownload: boolean;
    public kind: IReportKind;
    public comment: string;
    public startDate: number;
    public period: string;
    public params: ReportParams = {};
    public hasDetail: boolean;
    public initParams: any;
    public timezoneOffset: string;

    constructor(report: IReport) {
        this.id = report.id;
        this.user = this.prepareUser(report.user);
        this.status = report.status;
        this.createDate = this.formatCreateDate(report.create_date);
        this.date = Date.parse(report.create_date);
        this.updateDate = report.update_date;
        this.file = report.file;
        this.format = this.getFormat(this.file);
        this.name = report.name;
        this.kind = report.kind;
        this.comment = report.comment;
        this.isDisableDownload = this.status !== REPORT_STATUS.READY;
        this.startDate = report.params.start_date ? moment(report.params.start_date).valueOf() : 0;
        this.period = this.getPeriod(report.params.start_date, report.params.finish_date);
        this.timezoneOffset = report.params.timezone ? timeZoneOffset(report.params.timezone) : '';
        this.initParams = report.params;
        if (report.params.post_ids) {
            this.params.postIds = report.params.post_ids;
        }
        if (report.params.measure) {
            this.params.measure = report.params.measure;
        }
        this.hasDetail = report.params.post_ids && report.params.measure ? true : false;
    }

    private prepareUser(user: UserReport): UserReport {
        if (!user) {
            return user;
        }
        user.charAt = user.email.charAt(0).toUpperCase();
        user.avatarClass = this.colorIconClass(user.harvester_id);
        return user;
    }

    private colorIconClass(id: number): REPORTS_COLOR[keyof REPORTS_COLOR] {
        let enumKeys = Object.keys(REPORTS_COLOR);
        enumKeys = enumKeys.filter((item) => item !== 'green'); // remove green
        const index = id % enumKeys.length;

        return enumKeys[index];
    }

    private getFormat(fileName: string): string {
        if (!fileName) {
            return '';
        }
        return fileName.split('.').pop();
    }

    private getPeriod(begin, end) {
        if (!begin || !end) {
            return null;
        }
        const textStart = formatDayMonthYear(moment(begin));
        const textEnd = formatDayMonthYear(moment(end));

        return `${textStart} - ${textEnd}`;
    }

    private formatCreateDate(date: string) {
        const m = moment(date);
        return `${m.format('D')} ${m.format('MMM').substring(0, 3)} ${m.format('YYYY HH:mm')}`;
    }
    private getFileName(fileName: string): string {
        return fileName.split('.').slice(0, -1).join('.') || fileName + '';
    }
}
export interface statusMessage {
    id: number;
    status: REPORT_STATUS;
    title?: string;
    text?: string;
    value?: number;
}
export interface ITimezone {
    name: string;
    offset: number;
    label?: string;
}
export function convertLabelTimezone(item: string): string {
    const name = item.split('/')[1];
    return `${name} <i>(GMT ${moment.tz(item).format('Z')})</i>`;
}
export function timeZoneOffset(tz: string): string {
    return `<i>(GMT ${moment.tz(tz).format('Z')})</i>`;
}

<div
    [ngClass]="{
        'value-circle': true,
        'value-circle_state-selected': state === markerState.default && selected,
        'value-circle_state-add': state === markerState.add && !selected,
        'value-circle_state-added': state === markerState.add && selected,
        'value-circle_state-disable': hasNoData
    }"
    [style]="{ backgroundColor: color }"
>
    <span class="value-circle__grade">{{ displayValue }}</span>
    <span class="value-circle__sign"></span>
</div>

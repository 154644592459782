import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { AqiType } from '@libs/common/enums/aqi.type';
import { IntervalEnum, MapPins } from '@cityair/namespace';
import { TabModel } from '@libs/common/types/tab-model';
import { MapAdapterService } from '@cityair/modules/map/services/map-adapter.service';
import {
    GroupExtConfigName,
    GroupFeaturesService,
} from '@cityair/modules/core/services/group-features/group-features.service';
import {
    selectGlobalMeasurement,
    selectGroupInfo,
    selectIsCityMode,
    isCompareMode,
    selectIsSidebarOpen,
    selectMeasurementsForPins,
    selectMeasureScheme,
    selectPdkForChart,
    selectPostValue,
    selectSchemeAndMeasure,
    selectSourcesAsFeatures,
    selectTime,
    selectTimelineDateTimes,
    selectTypeInterval,
    selectZone,
    selectQualityDataMode,
    selectQualityDataTimeline,
} from '@cityair/modules/core/store/selectors';
import {
    currentTimeUpdate,
    loadCity,
    openInstantAqiFaq,
    removeFromComparison,
    setComparisonMode,
    setGlobalMeasurement,
    setTypeInterval,
    updateComparedStations,
    toggleShowQualityDataInfo,
} from '@cityair/modules/core/store/actions';
import {
    NotificationsStateService,
    Pages,
    SubscriptionTypeFilter,
} from '../../notifications-state.service';
import { addNotifiablePost, removeNotifiablePost } from '../../store/actions';
import { NotificationsState } from '../../store/reducers';
import { selectNotifiablePosts, selectNotNotifiablePosts } from '../../store/selectors';
import {
    getColorFromZone,
    getDaysByInterval,
    isDisabledTabByIntervalSwitcher,
} from '@cityair/utils/utils';
import { getDigitsAfterDot } from '@cityair/config';
import { isRU, TEXTS } from '@libs/common/texts/texts';
import { GroupChartConfig } from '@libs/common/models/group-chart-config.model';
import { aqiDataProviderFactory } from '@cityair/modules/core/utils';
import { selectCanShowIntervalButtons } from '@cityair/modules/core/store/compared-list/compared-list.selectors';
import { getModulePageConfig } from '@cityair/modules/core/store/module-page-config/module-page-config.feature';
import { clearCompareList } from '@cityair/modules/core/store/compared-list/compared-list.actions';
import { GroupTooltipsMmt } from '@libs/common/types/group-tooltips-mmt';
import { AQI_IN_ANALYTICS_GROUP_IDS } from '@cityair/modules/analytics/constants';

@Component({
    selector: 'notifications-map',
    templateUrl: './notifications-map.component.html',
    styleUrls: ['./notifications-map.component.less'],
})
export class NotificationsMapComponent implements OnInit, OnDestroy {
    private isNotificationsPins = false;
    private subscriptions: Subscription[] = [];

    selectGlobalMeasurement = selectGlobalMeasurement;
    selectMeasureScheme = selectMeasureScheme;
    IntervalEnum = IntervalEnum;
    selectIsSidebarOpen = selectIsSidebarOpen;
    selectSourcesAsFeatures = selectSourcesAsFeatures;
    selectTimelineDateTimes = selectTimelineDateTimes;
    selectIsComparisonModeActive = isCompareMode;
    selectPdkForChart = selectPdkForChart;
    selectCanShowIntervalButtons = selectCanShowIntervalButtons;
    removeFromComparison = removeFromComparison;
    selectSchemeAndMeasure = selectSchemeAndMeasure;
    selectZone = selectZone;
    selectMeasurementsForPins = selectMeasurementsForPins;
    setGlobalMeasurement = setGlobalMeasurement;
    currentTimeUpdate = currentTimeUpdate;
    selectTime = selectTime;
    selectIsCityMode = selectIsCityMode;
    selectQualityDataMode = selectQualityDataMode;
    toggleShowQualityDataInfo = toggleShowQualityDataInfo;
    selectQualityDataTimeline = selectQualityDataTimeline;
    moduleConfig$ = this.store.select(getModulePageConfig);

    aqiDataProvider = aqiDataProviderFactory(this.store);

    TEXTS = TEXTS;
    AqiType = AqiType;
    isCompare = false;

    isRU = isRU;

    tabsInterval: TabModel[] = [
        {
            title: TEXTS.TIME_NAMES.min5,
            type: 1,
            tooltip: TEXTS.TIME_NAMES_TOOLTIP.min5,
        },
        {
            title: TEXTS.TIME_NAMES.min20,
            type: 2,
            tooltip: TEXTS.TIME_NAMES_TOOLTIP.min20,
        },
        {
            title: TEXTS.TIME_NAMES.hour1,
            type: 3,
            tooltip: TEXTS.TIME_NAMES_TOOLTIP.hour1,
        },
        {
            title: TEXTS.TIME_NAMES.day1,
            type: 4,
        },
    ];

    selectedTabInterval = null;

    chartSingleSelect = false;
    chartMinMax: GroupChartConfig;
    groupTooltip: GroupTooltipsMmt;

    constructor(
        private notificationStore: Store<NotificationsState>,
        private groupFeaturesService: GroupFeaturesService,
        public store: Store,
        private mapAdapterService: MapAdapterService,
        private nsStateService: NotificationsStateService
    ) {}

    ngOnInit(): void {
        const subscriptionSettingsSub = this.nsStateService.currentPage$.subscribe((page) => {
            if (page === Pages.SETTINGS && !this.isNotificationsPins) {
                this.setNotificationsPosts();
            } else {
                this.setDefaultPosts();
            }
        });

        this.subscriptions.push(subscriptionSettingsSub);

        this.groupFeaturesService.readyBehavior$
            .pipe(
                filter((val) => val),
                take(1)
            )
            .subscribe(() => {
                this.chartSingleSelect = this.groupFeaturesService.getConfig(
                    GroupExtConfigName.chartSingleSelect
                );
                this.chartMinMax = this.groupFeaturesService.getGroupChartConfig();
                this.groupTooltip = this.groupFeaturesService.getConfig(
                    GroupExtConfigName.chartTooltipByMeasurements
                );
            });

        this.store
            .select(selectGroupInfo)
            .pipe(
                filter((g) => !!g?.groupId),
                take(1)
            )
            .subscribe(async (g) => {
                this.mapAdapterService.setDefaultMap();
                const isDemoAtmos =
                    AQI_IN_ANALYTICS_GROUP_IDS.indexOf(g.groupId) >= 0 ? true : false;
                const datesUpdate = this.store
                    .select(selectTimelineDateTimes)
                    .subscribe((dates) => {
                        if (dates.length) {
                            this.tabsInterval = this.correctTabInterval(
                                dates[0],
                                dates[dates.length - 1],
                                isDemoAtmos
                            );
                        }
                    });
                this.subscriptions.push(datesUpdate);
                const typeIntervalSub = this.store.select(selectTypeInterval).subscribe((type) => {
                    this.selectedTabInterval = this.tabsInterval.find((t) => t.type === type);
                });
                this.subscriptions.push(typeIntervalSub);
            });
    }

    ngOnDestroy() {
        this.setDefaultPosts();

        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    getDigits = (measureScheme: MeasureScheme) => (mmt) => getDigitsAfterDot(measureScheme, mmt);

    setDefaultPosts() {
        this.isNotificationsPins = false;
        this.mapAdapterService.setDefaultMap();
    }

    setNotificationsPosts() {
        this.isNotificationsPins = true;

        const notificationsSelectedPosts: MapPins = {
            getPins: this.notificationStore.select(selectNotifiablePosts),
            getSelectedPinIds: new BehaviorSubject([]),
            getValue: (pin) => this.store.select(selectPostValue(pin.id)),
            clickCb: (pin) =>
                this.notificationStore.dispatch(removeNotifiablePost({ postId: pin.id })),
            getColor: (pin) =>
                combineLatest([
                    this.store.select(selectZone),
                    this.store.select(selectPostValue(pin.id)),
                ]).pipe(map(([zone, value]) => getColorFromZone(zone, value))),
        };

        const postPins: MapPins = {
            getPins: this.notificationStore.select(selectNotNotifiablePosts),
            selectDigitsAfterDot: this.store
                .select(selectSchemeAndMeasure)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: new BehaviorSubject([]),
            getValue: (pin) => this.store.select(selectPostValue(pin.id)),
            clickCb: (pin) =>
                this.notificationStore.dispatch(addNotifiablePost({ postId: pin.id })),
            getColor: (pin) =>
                combineLatest([
                    this.store.select(selectZone),
                    this.store.select(selectPostValue(pin.id)),
                ]).pipe(map(([zone, value]) => getColorFromZone(zone, value))),
        };

        this.mapAdapterService.set({
            notificationsSelectedPosts,
            postPins,
        });
    }

    setDataInterval(tab: TabModel) {
        this.store.dispatch(setTypeInterval({ payload: tab.type }));
        this.store.dispatch(updateComparedStations());
    }

    toggleComparisonMode(isActive: boolean) {
        if (!isActive) {
            this.store.dispatch(clearCompareList());
        }

        this.store.dispatch(setComparisonMode({ payload: isActive }));
    }

    openAqiFaqLink(aqiType: AqiType) {
        if (aqiType === AqiType.instant) {
            this.store.dispatch(openInstantAqiFaq());
        }
    }

    public goToCity(cityId: string) {
        this.store.dispatch(loadCity({ cityId, centringMap: true }));
    }

    onCompare() {
        this.store.dispatch(setComparisonMode({ payload: true }));
    }

    private correctTabInterval(start: string, end: string, isDemo: boolean): TabModel[] {
        const duration = getDaysByInterval(start, end);
        this.tabsInterval.map((tab) => {
            tab.isDisabled = isDisabledTabByIntervalSwitcher(tab.type, duration);
        });
        // TODO for demo indore(atmos)
        if (isDemo) {
            this.tabsInterval = this.tabsInterval.filter(
                (tab) => tab.type !== IntervalEnum.min5 && tab.type !== IntervalEnum.min20
            );
        }

        return this.tabsInterval;
    }
}

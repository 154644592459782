import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { MEASURES_FOR_FORECAST } from '@cityair/config';
import { City_model } from '@cityair/namespace';
import { TEXTS } from '@libs/common/texts/texts';

import { LayerButtonIconType } from '@cityair/libs/shared/modules/overlay/components/layer-button/layer-button.component';
import { ForecastControlService } from '@cityair/modules/map/services/forecast-control.service';
import { RoutingService } from '@cityair/modules/core/routing.service';
import { intervalUpdate } from '@cityair/modules/core/store/actions';
import { toggleLayerOnMap } from '@cityair/modules/plumes/store/actions';
import { currentPlumesMmt, showLayerOnMap } from '@cityair/modules/plumes/store/selectors';
import { takeUntil } from 'rxjs/operators';
import { PM10, TEMP, PRES, HUM } from '@libs/common/consts/substance.consts';
import { selectGroupInfo, selectIsCityMode } from '@cityair/modules/core/store/selectors';
import { getModulePageConfig } from '@cityair/modules/core/store/module-page-config/module-page-config.feature';
import {
    disableModelling,
    enableModelling,
} from '@cityair/libs/shared/utils/modeling-helper.functions';

@Component({
    selector: 'cs-top-right-elements-m',
    styleUrls: ['top-right-elements-m.component.less'],
    templateUrl: 'top-right-elements-m.component.html',
})
export class TopRightElementsMobile implements OnInit, OnDestroy {
    TEXTS = TEXTS;

    onDestroy$ = new Subject<void>();

    isCityMode$ = this.store.select(selectIsCityMode);
    moduleConfig$ = this.store.select(getModulePageConfig);
    groupInfo$ = this.store.select(selectGroupInfo);

    LayerButtonIconType = LayerButtonIconType;
    currentMeasure = PM10;
    MEASURES_FOR_FORECAST = MEASURES_FOR_FORECAST;

    showPlumesLayer: boolean;

    isActiveModelling = false;

    constructor(
        readonly forecastControlService: ForecastControlService,
        readonly routingService: RoutingService,
        private store: Store
    ) {
        this.store
            .select(currentPlumesMmt)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => (this.currentMeasure = data));
        this.store
            .select(showLayerOnMap)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => (this.showPlumesLayer = data));
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    getMeasurementItems(data: City_model['lastData']) {
        return [TEMP, PRES, HUM].map((name) => ({
            name,
            value: data[name][0],
            units: TEXTS.MEASURES[name],
        }));
    }

    togglePlumesLayer() {
        this.store.dispatch(toggleLayerOnMap({ payload: !this.showPlumesLayer }));
    }

    offPlume() {
        this.store.dispatch(toggleLayerOnMap({ payload: false }));
    }

    updateDateRange({ begin, end }) {
        this.store.dispatch(intervalUpdate({ begin, end }));
    }

    enableModelling() {
        this.isActiveModelling = true;
        enableModelling(this.forecastControlService, this.store);
    }

    disableModelling() {
        this.isActiveModelling = false;
        disableModelling(this.forecastControlService, this.store);
    }
}

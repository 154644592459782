import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}

    transform(value: string) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@Pipe({
    name: 'multipleSearchfilter',
})
export class MultipleSearchfilterPipe implements PipeTransform {
    transform(items: any[], fields: string[], value: string): any {
        if (!items) {
            return [];
        }
        if (value === undefined || value === null || value === '') {
            return items;
        }
        return items.filter((it) => {
            const itsStr = fields.map((f) => it[f]) + '';
            return itsStr.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        });
    }
}

@Pipe({
    name: 'multipleSearchFns',
})
export class MultipleSearchFnsPipe implements PipeTransform {
    transform(items: any[], fields: ((item: any) => string)[], value: string): any {
        if (!items) {
            return [];
        }
        if (value === undefined || value === null || value === '') {
            return items;
        }
        return items.filter(
            (it) =>
                fields
                    .map((fn) => fn(it))
                    .toString()
                    .toLowerCase()
                    .indexOf(value.toLowerCase()) !== -1
        );
    }
}

@Pipe({
    name: 'filterData',
})
export class FilterData implements PipeTransform {
    transform(items: any[], fields: string[], value: any[]): any {
        if (!items) {
            return [];
        }
        if (value === undefined || value === null) {
            console.error('FilterData value == undefined || value == null');
            return items;
        }
        if (fields.length !== value.length) {
            console.error('FilterData fields.length !== value.length');
            return items;
        }
        return items.filter((item) => fields.every((field, i) => item[field] === value[i]));
    }
}

@Pipe({
    name: 'sorting',
})
export class SortingPipe implements PipeTransform {
    transform(
        items: any[],
        column: (item: any) => string | number | boolean,
        direction = 1
    ): any[] {
        if (!items) {
            return [];
        }

        if (!column) {
            return items;
        }

        return [...items].sort((a, b) => {
            const aValue = column(a);
            const bValue = column(b);

            const p1 = typeof aValue === 'number' ? aValue : aValue?.toString().toLowerCase();
            const p2 = typeof bValue === 'number' ? bValue : bValue?.toString().toLowerCase();

            if ((!p1 && p1 !== 0 && p1 !== '') || p1 > p2) {
                return Math.sign(direction);
            }

            if ((!p2 && p2 !== 0 && p1 !== '') || p1 < p2) {
                return -Math.sign(direction);
            }

            return 0;
        });
    }
}

@Pipe({
    name: 'asyncSorting',
})
export class AsyncSortingPipe implements PipeTransform {
    transform = async (
        items: any[],
        column: (item: any) => Promise<string | number | boolean>,
        direction = 1
    ): Promise<any[]> => {
        if (!items) {
            return [];
        }

        if (!column) {
            return items;
        }

        const arr = [...items];

        // bubble sorting
        for (let i = 0, endI = arr.length - 1; i < endI; i++) {
            let wasSwap = false;

            for (let j = 0, endJ = endI - i; j < endJ; j++) {
                let jValue = await column(arr[j]);
                let j1Value = await column(arr[j + 1]);

                jValue = typeof jValue === 'number' ? jValue : jValue?.toString().toLowerCase();
                j1Value = typeof j1Value === 'number' ? j1Value : j1Value?.toString().toLowerCase();

                if ((!jValue && jValue !== 0 && jValue !== '') || jValue < j1Value) {
                    [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
                    wasSwap = true;
                }
            }

            if (!wasSwap) break;
        }

        if (direction === -1) arr.reverse();

        return arr;
    };
}

@Pipe({
    name: 'filterBy',
})
export class FilterByPipe<T> implements PipeTransform {
    transform(items: T[], predicate: (item: T) => boolean): T[] {
        return items?.length ? items.filter(predicate) : items;
    }
}

@Pipe({
    name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
    transform(items: any[], propName: string, direction = 1) {
        const arr = [...items];
        return arr.sort((a, b) => {
            if (propName.indexOf('.') >= 0) {
                const value = propName.split('.');
                const key = value[0];
                const subKey = value[1];
                a = a[key][subKey];
                b = b[key][subKey];
            } else {
                a = a[propName];
                b = b[propName];
            }

            if (a < b) {
                return -1 * direction;
            } else if (a === b) {
                return 0;
            } else if (a > b) {
                return 1 * direction;
            }
        });
    }
}

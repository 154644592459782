import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { PostInfo, StatInfo } from '@cityair/modules/indoor/services/api';
import { ChartDataset } from 'chart.js';
import {} from '@cityair/utils/utils';
import { getIndoorColor, indoorZones, MMT_INDOOR } from '@cityair/modules/indoor/config';
import * as moment from 'moment';
import { ComfortConditionSettings } from '@cityair/modules/core/services/group-features/group-features.service';
import { TEXTS } from '@libs/common/texts/texts';
import { isFalseNumber } from '@libs/common/utils/utils';

@Component({
    selector: 'stat-info',
    templateUrl: './stat-info.component.html',
    styleUrls: ['./stat-info.component.less'],
})
export class StatInfoComponent implements OnChanges {
    @Input() data: StatInfo;
    @Input() config: ComfortConditionSettings;
    @Output() showPopup = new EventEmitter<void>();
    @Output() showConfig = new EventEmitter<void>();

    TEXTS = TEXTS;
    MMT_INDOOR = MMT_INDOOR;

    toDay = moment().format('DD MMMM');

    getIndoorColor = getIndoorColor;
    indoorZones = indoorZones;
    MeasureScheme = MeasureScheme;

    percentData: ChartDataset<'line', number[]>[];
    dailyData: ChartDataset<'bar', number[]>[];
    dailyLabels: Date[];

    showComfortPopup = false;
    showSafetyPopup = false;
    isEmptyData = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data?.currentValue) {
            this.isEmptyData = this.checkIsEmpty(changes.data.currentValue);
            if (!this.isEmptyData) {
                const _filtered = this.data.PercentInDays.filter(
                    (d) => !isFalseNumber(d.Percent) || !isFalseNumber(d.IndoorAqi)
                );
                _filtered.sort((a, b) => {
                    const _a = new Date(a.Day).getTime();
                    const _b = new Date(b.Day).getTime();

                    if (_a < _b) {
                        return -1;
                    }
                    if (_a > _b) {
                        return 1;
                    }
                    return 0;
                });

                this.percentData = [
                    { data: _filtered.map((a) => a.Percent).filter((v) => !isFalseNumber(v)) },
                ];
                this.dailyData = [
                    { data: _filtered.map((a) => a.IndoorAqi).filter((v) => !isFalseNumber(v)) },
                ];
                this.dailyLabels = _filtered.map((a) => a.Day);
            }
        }
    }

    private checkIsEmpty(data: PostInfo) {
        if (data && data.PercentInDays.length === 0) {
            return true;
        }
        return false;
    }
}

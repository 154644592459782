import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartConfiguration, ChartDataset } from 'chart.js';
import { TEXTS } from '@libs/common/texts/texts';
import { ALERT_COLORS } from '@cityair/config';
import { BarChartData } from './namespace';
import * as moment from 'moment';
import { chartDateFormater } from '@cityair/modules/indoor/config';

@Component({
    selector: 'daily-chart',
    templateUrl: './daily-chart.component.html',
    styleUrls: ['./daily-chart.component.less'],
})
export class DailyChartComponent implements OnInit, OnChanges {
    @Input() data: ChartDataset<'bar', number[]>[];
    @Input() labels: Date[];

    TEXTS = TEXTS;

    chartOptions: BarChartData = null;

    constructor() {}

    ngOnInit(): void {
        if (this.data) {
            this.chartOptions = this.getOptionsByName(this.data);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data?.currentValue) {
            this.chartOptions = this.getOptionsByName(this.data);
        }
    }

    getOptionsByName(data: ChartDataset<'bar', number[]>[]): BarChartData {
        return {
            chart: {
                datasets: data.map((d) => {
                    const backgroundColor = this.getChartColor(data);
                    return {
                        ...d,
                        backgroundColor,
                        hoverBackgroundColor: backgroundColor,
                        borderRadius: 2,
                        borderColor: 'transparent',
                    };
                }),
                labels: this.labels,
                options: this.getChartOptions(),
                type: 'bar',
                plugins: [],
            },
        };
    }

    private getChartColor(data: ChartDataset<'bar', number[]>[]): string[] {
        return data?.[0].data.map((value) => {
            const index = value ? Math.round(value) : 0;
            return ALERT_COLORS[index];
        });
    }

    private getChartOptions(): ChartConfiguration['options'] {
        const _chartDateFormat = chartDateFormater();

        return {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    ticks: {
                        color: '#95A0B3',
                        font: {
                            family: 'Inter',
                            weight: '500',
                            size: 11,
                        },
                        maxRotation: 0,
                        callback: (val) => _chartDateFormat(moment(this.labels[val])),
                    },
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                },
                y: {
                    max: 10,
                    beginAtZero: true,
                    ticks: {
                        color: '#95A0B3',
                        font: {
                            family: 'Inter',
                            weight: '600',
                            size: 10,
                        },
                        precision: 0,
                    },
                    grid: {
                        display: true,
                        color: '#E6ECF2',
                        borderDash: [3],
                        drawBorder: false,
                    },
                },
            },
            plugins: {
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    intersect: false,
                    borderWidth: 1,
                    displayColors: false,
                    xAlign: 'center',
                    yAlign: 'bottom',
                    callbacks: {
                        title: (tooltipItems) => moment(tooltipItems[0].label).format('DD MMM'),
                        label: (tooltipItem) => `${tooltipItem.formattedValue}`,
                    },
                },
            },
        };
    }
}

<div class="wrapper">
    <ca-button type="cancel" (clickAction)="_cancel()">
        {{ textCancel || TEXTS.COMMON.cancel }}
    </ca-button>
    <ca-button type="primary" (clickAction)="_save()" [disabled]="disabled">
        <ca-spinner *ngIf="saving; else showContent"></ca-spinner>
        <ng-template #showContent>
            {{ textSave || TEXTS.COMMON.save }}
        </ng-template>
    </ca-button>
</div>

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { PacketValueType } from '@cityair/modules/login/services/harvester-api/models';
import {
    FeedItemsExportRequest,
    GroupNotificationChangeRequest,
    GroupNotificationChangeType,
    GroupNotificationSetting,
    NotificationFeedItem,
    NotificationSubscribeType,
    UserFeedItemRequest,
} from '@cityair/modules/login/services/harvester-api/adminApiModels';
import { WindowGlobalVars } from '@cityair/namespace';
import { TEXTS } from '@libs/common/texts/texts';
import { NotificationSubscription } from '@cityair/libs/common/api/adminPanel/dataTransformer';
import { modifyNotification, updateGroupInfo } from '@cityair/modules/core/store/actions';
import {
    selectGroupId,
    selectMyRole,
    selectNotifications,
    selectUserId,
} from '@cityair/modules/core/store/selectors';
import { updateNotifiablePosts } from '@cityair/modules/notifications/store/actions';
import { PM10, PM25 } from '@libs/common/consts/substance.consts';
import { AdminPanelApi } from '@cityair/libs/common/api/adminPanel/api';
import * as moment from 'moment';

export enum Pages {
    EVENT_FEED = 'event-feed',
    SUBSCRIPTIONS = 'subscriptions',
    SETTINGS = 'settings',
}

export enum SubscriptionTypeFilter {
    ALL = 'all',
    MEASUREMENTS = 'measurements',
    SERVICE = 'service',
    FORECAST = 'forecast',
    PLUMES = 'plumes',
}

export const TYPE_MAP = {
    [NotificationSubscribeType.PdkExcess]: SubscriptionTypeFilter.MEASUREMENTS,
    [NotificationSubscribeType.ServiceDevice]: SubscriptionTypeFilter.SERVICE,
};

declare const window: WindowGlobalVars;

export class NotificationState {
    typeFilter: SubscriptionTypeFilter = SubscriptionTypeFilter.MEASUREMENTS;

    constructor(config?: Partial<NotificationState>) {
        if (config) {
            Object.assign(this, config);
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class NotificationsStateService {
    currentPage: Pages;
    private previousPage: Pages;
    typeFilter: SubscriptionTypeFilter;
    private isUserCanEdit: boolean;
    private userId: number;
    private groupId: number;
    private subscriptions$ = new BehaviorSubject<NotificationSubscription[]>([]);
    private _currentPage$ = new BehaviorSubject<Pages>(Pages.EVENT_FEED);
    currentPage$ = this._currentPage$.asObservable();

    lists: {
        all$?: Observable<NotificationSubscription[]>;
        selected$?: Observable<NotificationSubscription[]>;
        active$?: Observable<NotificationSubscription[]>;
        deactivated$?: Observable<NotificationSubscription[]>;
    } = {};

    currentSubscription: NotificationSubscription;

    constructor(private adminPanelApi: AdminPanelApi, private store: Store) {
        this._currentPage$.subscribe((page) => {
            this.currentPage = page;
        });
        this.setDefaultState();

        this.store.select(selectNotifications).subscribe((notificationSubscriptions) => {
            this.setSubscriptions(notificationSubscriptions);
        });
        this.store.select(selectMyRole).subscribe((data) => (this.isUserCanEdit = data?.editGroup));
        this.store.select(selectUserId).subscribe((id) => (this.userId = id));
        this.store.select(selectGroupId).subscribe((id) => (this.groupId = id));
    }

    setDefaultState() {
        this._currentPage$.next(Pages.EVENT_FEED);
        this.typeFilter = SubscriptionTypeFilter.MEASUREMENTS;
    }

    resetData() {
        this.previousPage = null;
        this.resetNotifiablePosts();
        this.currentSubscription = null;
    }

    resetNotifiablePosts() {
        this.store.dispatch(updateNotifiablePosts({ postIds: [] }));
    }

    setSubscriptions(subscriptions: NotificationSubscription[] = []) {
        const { lists } = this;

        this.subscriptions$.next(subscriptions);

        lists.all$ = this.subscriptions$.asObservable();

        lists.selected$ = lists.all$.pipe(
            map((ss) => ss.filter((s) => this.hasFilterType(s.type)))
        );

        lists.active$ = lists.selected$.pipe(map((ss) => ss.filter((s) => s.isActive)));

        lists.deactivated$ = lists.selected$.pipe(map((ss) => ss.filter((s) => !s.isActive)));
    }

    openSettings(subscription: NotificationSubscription) {
        this.resetData();
        this.previousPage = this.currentPage;
        this.currentSubscription = subscription;
        this._currentPage$.next(Pages.SETTINGS);
        this.typeFilter = TYPE_MAP[subscription.type];
    }

    openSubscriptions(typeFilter?: SubscriptionTypeFilter) {
        this.resetData();
        this._currentPage$.next(Pages.SUBSCRIPTIONS);
        if (typeFilter) {
            this.getState(Pages.SUBSCRIPTIONS).typeFilter = typeFilter;
        }
    }

    openEventFeed() {
        this.resetData();
        this._currentPage$.next(Pages.EVENT_FEED);
    }

    openPrevious() {
        const page = this.previousPage;
        this.resetData();
        this._currentPage$.next(page);
    }

    private _updateSubscription(
        action: GroupNotificationChangeType,
        item: GroupNotificationSetting
    ): Promise<string> {
        const request = new GroupNotificationChangeRequest(action, item);
        return this.adminPanelApi.changeNotificationSettings(request);
    }

    async addSubscription(subscription: NotificationSubscription) {
        const item: GroupNotificationSetting = subscription.toRequestItem();
        item.NotificationId = 0;
        item.IsActive = true;

        return this._updateSubscription(GroupNotificationChangeType.Add, item).then(() => {
            this.store.dispatch(updateGroupInfo());
        });
    }

    async removeSubscription(subscription: NotificationSubscription) {
        const item: GroupNotificationSetting = subscription.toRequestItem();

        return this._updateSubscription(GroupNotificationChangeType.Delete, item).then(() => {
            this.store.dispatch(updateGroupInfo());
        });
    }

    async activateSubscription(subscription: NotificationSubscription) {
        const item: GroupNotificationSetting = subscription.toRequestItem();
        item.IsActive = true;

        return this._updateSubscription(GroupNotificationChangeType.Edit, item).then(() => {
            this.store.dispatch(
                modifyNotification({
                    id: subscription.id,
                    props: {
                        ...subscription,
                        isActive: true,
                    },
                })
            );
        });
    }

    addOrActivateSubscription(subscription: NotificationSubscription) {
        return subscription.isSuggested() || subscription.isNew()
            ? this.addSubscription(subscription)
            : this.activateSubscription(subscription);
    }

    async deactivateSubscription(subscription: NotificationSubscription) {
        const item: GroupNotificationSetting = subscription.toRequestItem();
        item.IsActive = false;

        return this._updateSubscription(GroupNotificationChangeType.Edit, item).then(() => {
            this.store.dispatch(
                modifyNotification({ id: subscription.id, props: { isActive: false } })
            );
        });
    }

    async updateEventFeedUserIds(subscription: NotificationSubscription, newUserIds: number[]) {
        const item: GroupNotificationSetting = subscription.toRequestItem();
        item.NotifySetting.NotificationFeedUserIds = newUserIds;

        return this._updateSubscription(GroupNotificationChangeType.Edit, item).then(() => {
            this.store.dispatch(
                modifyNotification({ id: subscription.id, props: { forUserIds: newUserIds } })
            );
        });
    }

    async subscribeForServiceNotifications(
        subscription: NotificationSubscription,
        newEmailsList: string[],
        newUserIds: number[]
    ) {
        const item: GroupNotificationSetting = subscription.toRequestItem();
        item.NotifySetting.NotificationEmails = newEmailsList;
        item.NotifySetting.NotificationFeedUserIds = newUserIds;

        return this._updateSubscription(GroupNotificationChangeType.Edit, item).then(() => {
            this.store.dispatch(
                modifyNotification({
                    id: subscription.id,
                    props: { emailsList: newEmailsList, forUserIds: newUserIds },
                })
            );
        });
    }

    async downloadFeedHistory(
        subscription: NotificationSubscription,
        dateBegin: number,
        dateEnd: number
    ) {
        const request = new FeedItemsExportRequest();

        Object.assign(request, {
            NotificationId: subscription.id,
            TimeBegin: moment(dateBegin - new Date().getTimezoneOffset() * 60000)
                .utcOffset(0)
                .startOf('day'),
            TimeEnd: moment(dateEnd - new Date().getTimezoneOffset() * 60000)
                .utcOffset(0)
                .endOf('day'),
            TimeZone: new Date().getTimezoneOffset() / -60,
            Lang: window.JS_CP_SITE_LANG,
        });

        return this.adminPanelApi.downloadFeedHistory(request);
    }

    async getUsersEventFeed(userId: number, groupId: number) {
        const request = new UserFeedItemRequest(userId, groupId, null);
        return this.adminPanelApi.getUsersEventFeed(request);
    }

    async markNotificationAsVisited(item: NotificationFeedItem) {
        const userId = this.userId;
        const groupId = this.groupId;
        item.IsViewed = true;
        const request = new UserFeedItemRequest(userId, groupId, item);

        return this.adminPanelApi.markNotificationFeedViewed(request);
    }

    updateLists(transform: (ss: NotificationSubscription[]) => NotificationSubscription[]) {
        this.subscriptions$.pipe(take(1)).subscribe((subscriptions) => {
            this.subscriptions$.next(transform(subscriptions));
        });
    }

    hasFilterType(type: NotificationSubscribeType) {
        return TYPE_MAP[type] === this.typeFilter || this.typeFilter === SubscriptionTypeFilter.ALL;
    }

    getValueName(value: number) {
        let type = PacketValueType[value];
        if (value === PacketValueType.PM2) {
            type = PM25;
        } else if (value === PacketValueType.PM10) {
            type = PM10;
        }
        return TEXTS.NAMES[type];
    }

    uiStates: {
        [key in Pages]?: NotificationState;
    } = {};

    getState(page: Pages) {
        return this.uiStates[page] || (this.uiStates[page] = new NotificationState());
    }

    canEdit() {
        return this.isUserCanEdit;
    }
}

<div class="post-report">
    <div class="post-report__main-block scroll">
        <header-of-panel
            class="post-report__header-of-panel"
            [path]="navigationLink"
            (outputPath)="backToPrevPage($event)"
            [title]="TEXTS.POSTS_AND_DEVICES.dataFromPost"
        >
        </header-of-panel>
        <input-date-range
            class="post-report__input-date-range"
            [timeBegin]="objectForReportMo.timeBegin"
            [timeEnd]="objectForReportMo.timeEnd"
            (timeBeginChange)="getDateBeginRangeFromCalendar($event)"
            (timeEndChange)="getDateEndRangeFromCalendar($event)"
        ></input-date-range>

        <input-dropdown
            class="post-report__input-dropdown"
            [inputText]="selectedCheckboxText(getTags().length)"
            [label]="TEXTS.POSTS_AND_DEVICES.postsMonitoring"
            [(showDropdown)]="isShowDropdownForStations"
            (clickOutside)="isShowDropdownForStations = false"
        >
            <cs-selectbox
                [listItems]="listStations"
                (listItemsChange)="listStations = $event"
                [searchPlaceholderText]="TEXTS.NOTIFICATIONS.searchMoPlaceholderText"
                [selectAllText]="TEXTS.NOTIFICATIONS.selectAll"
            ></cs-selectbox>
        </input-dropdown>

        <tags-list
            class="post-report__tags-list"
            [tags]="getTags()"
            [textNodesFns]="postsListLabels()"
            [truncateTo]="4"
            [editable]="true"
            (removeTag)="removeFromList($event)"
        ></tags-list>

        <div class="post-report__title">{{ TEXTS.POSTS_AND_DEVICES.intervalData }}</div>
        <switch-item
            class="post-report__switch-item"
            [currentTab]="currentFilterTabsInterval"
            [tabs]="filterTabsInterval"
            (action)="setTabsInterval($event)"
        ></switch-item>
        <div class="post-report__title">{{ TEXTS.POSTS_AND_DEVICES.units }}</div>
        <div class="post-report__end-block">
            <switch-item
                [currentTab]="currentUnitsMeasurements"
                [tabs]="unitsMeasurementsList"
                (action)="getUnitMeasurement($event)"
            ></switch-item>
            <div
                *ngIf="isRU"
                class="post-report__label"
                [class.post-report__label--disabled]="isDisabledPdk"
                (click)="toggleInsertPdk()"
            >
                <cs-checkbox
                    [checked]="objectForReportMo.insertPdk"
                    [disabled]="isDisabledPdk"
                ></cs-checkbox>
                <span class="post-report__label-text">
                    {{ TEXTS.POSTS_AND_DEVICES.pightsOfPDK }}
                </span>
            </div>
        </div>
    </div>
    <div class="post-report__btn">
        <btn-cancel (click)="backToPrevPage(navigationLink[navigationLink.length - 2])">
            {{ TEXTS.COMMON.cancel }}
        </btn-cancel>
        <btn-accept
            [disabled]="getTags().length === 0 || isActiveLoader"
            [isLoading]="isActiveLoader"
            (cbClick)="downloadReportMo()"
        >
            {{ TEXTS.DOWNLOAD_POPUP.download }}
        </btn-accept>
    </div>
</div>

import { Component, EventEmitter, Output } from '@angular/core';

import { TEXTS } from '@libs/common/texts/texts';
import { ConfigPage } from '../config.types';
import { selectMyRole, selectRegionalCoefficient } from '@cityair/modules/core/store/selectors';
import { Store } from '@ngrx/store';

@Component({
    selector: 'config-menu',
    template: `
        <div class="config_menu">
            <div
                class="config_menu__item"
                (click)="select(configPage.SETTINGS)"
                data-cy="settings-system"
            >
                {{ TEXTS.CONFIG.settings }}
            </div>

            <div
                *ngIf="
                    (store.select(selectRegionalCoefficient) | async)?.currentPcf &&
                    (store.select(selectMyRole) | async)?.editGroup
                "
                class="config_menu__item"
                (click)="select(configPage.COEFFICIENT)"
                data-cy="settings-coefficient"
            >
                {{ TEXTS.CONFIG.coeff }}
            </div>

            <div
                class="config_menu__item"
                (click)="select(configPage.API_TOKENS)"
                data-cy="settings-api-tokens"
            >
                {{ TEXTS.CONFIG.token }}
            </div>

            <div
                class="config_menu__item"
                (click)="select(configPage.ACTIVITY_HISTORY)"
                data-cy="settings-activity-history"
            >
                {{ TEXTS.CONFIG.history }}
            </div>
        </div>
    `,
    styleUrls: ['config-menu.components.less'],
})
export class ConfigMenuComponent {
    @Output() selectPage = new EventEmitter<ConfigPage>();

    configPage = ConfigPage;
    TEXTS = TEXTS;
    selectRegionalCoefficient = selectRegionalCoefficient;
    selectMyRole = selectMyRole;

    constructor(public store: Store) {}

    select = (page: ConfigPage) => {
        this.selectPage.emit(page);
    };
}

import { createReducer, on } from '@ngrx/store';
import { addNotifiablePost, removeNotifiablePost, updateNotifiablePosts } from './actions';

export interface NotificationsState {
    notifiablePosts: number[];
}

const initialState: NotificationsState = {
    notifiablePosts: [],
};

export const notificationsReducer = createReducer(
    initialState,
    on(updateNotifiablePosts, (state: NotificationsState, { postIds }) => ({
        ...state,
        notifiablePosts: postIds,
    })),
    on(addNotifiablePost, (state: NotificationsState, { postId }) => ({
        ...state,
        notifiablePosts: [...state.notifiablePosts, postId],
    })),
    on(removeNotifiablePost, (state: NotificationsState, { postId }) => ({
        ...state,
        notifiablePosts: state.notifiablePosts.filter((p) => p !== postId),
    }))
);

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isFalseNumber } from '@libs/common/utils/utils';
import { markerState } from '@libs/common/enums/marker-state.enum';

@Component({
    selector: 'value-circle',
    templateUrl: './value-circle.component.html',
    styleUrls: ['./value-circle.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueCircleComponent implements OnChanges {
    markerState = markerState;

    @Input() value: number;
    @Input() numberAfterDot?: number = 0;
    @Input() color: string;
    @Input() selected = false;
    @Input() state: markerState = markerState.default;

    displayValue: number;
    hasNoData: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            const { value, numberAfterDot } = this;
            const hasNoData = isFalseNumber(value);
            this.displayValue = hasNoData ? 0 : Number(value.toFixed(numberAfterDot));
            this.hasNoData = hasNoData;
        }
    }
}

import { Injectable } from '@angular/core';
import { AdminPanelApi } from '@cityair/libs/common/api/adminPanel/api';
import { GetUserPermissionModel } from '@cityair/libs/common/api/adminPanel/dataTransformer';
import { Store } from '@ngrx/store';
import { selectUserId } from '@cityair/modules/core/store/selectors';

@Injectable()
export class TokensService {
    private userId: number;
    private tokens: GetUserPermissionModel;

    constructor(private adminPanelApi: AdminPanelApi, private store: Store) {
        this.store.select(selectUserId).subscribe((id) => (this.userId = id));
    }

    getToken = () => this.tokens;

    updateTokens = async () =>
        (this.tokens = await this.adminPanelApi.getUserPermission(this.userId));

    closeToken = async (id: number) => await this.adminPanelApi.deleteUserToken(id);

    editToken = async (id: number, title: string) =>
        await this.adminPanelApi.editUserToken(id, title);

    createToken = async (title: string) => this.adminPanelApi.createUserToken(this.userId, title);

    getUserTokenValue = async (id: number) => await this.adminPanelApi.getUserTokenValue(id);
}

<div class="chart" *ngIf="chartEnabled && data?.length" [@inOutAnimation]>
    <div class="chart__close-btn" data-cy="close-chart" (click)="closeChart()">
        {{ TEXTS.COMMON.closeChart }}
        <cross-button size="small"></cross-button>
    </div>
    <div class="data-wrapper">
        <div class="name-wrapper column-wrapper">
            <div class="header">
                <div class="title">{{ TEXTS.STATION_BLOCK.dataOnPollutants }}</div>
            </div>
            <div class="container-content">
                <div *ngFor="let city of data" class="column-name">
                    <div class="city-name-wrapper">
                        <div
                            class="city-name ellipsis"
                            data-test="timeline-current-city-name"
                            [attr.title]="getCurrentFeatureName(city)"
                        >
                            {{ getCurrentFeatureName(city) }}
                        </div>
                        <div class="city-sub-name">
                            <div class="city-sub-name">
                                <span *ngIf="city.properties.obj === 'city'; else current_name">
                                    {{ TEXTS.STATION_BLOCK.averageCity }}
                                    <a
                                        *ngIf="!isCityMode"
                                        class="link-go-to-city"
                                        data-cy="link-go-to-city"
                                        (click)="goCity(city)"
                                    >
                                        {{ TEXTS.CITY_CARD.goOverCity }}
                                    </a>
                                </span>
                                <ng-template #current_name>
                                    <div class="current-city-name">{{ getCityName(city) }}</div>
                                    <div class="station-text">
                                        {{ getFeatureType(city) }}
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column-pdk" *ngIf="pdk"></div>
            </div>
        </div>
        <div class="spacer-wrapper column-wrapper">
            <div class="header"></div>
            <div class="container-content">
                <div *ngFor="let city of data; count as len">
                    <div
                        class="wrapper_chart_line"
                        [ngClass]="{ station: city.properties.obj !== 'city' }"
                    >
                        <svg width="20" height="2" *ngIf="len > 1">
                            <line
                                x1="0"
                                y1="0"
                                x2="20"
                                y2="0"
                                stroke="#b3b4b3"
                                stroke-width="2"
                                [attr.stroke-dasharray]="this.dashLinesMap[city.properties.uuid]"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="spacer-pdk" *ngIf="pdk"></div>
        </div>
        <div
            class="measurements-wrapper column-wrapper scroll"
            id="data-scrollable"
            #scrollable
            [ngClass]="{
                'container-min-width': availableMeasurements.length > 1,
                scrollable: hasScroll()
            }"
            (mousedown)="startDragging($event, false, scrollable)"
            (mouseup)="stopDragging()"
            (mouseleave)="stopDragging()"
            (mousemove)="moveEvent($event, scrollable)"
        >
            <div
                class="container-scroll"
                (wheel)="onWheel($event)"
                [ngStyle]="{ 'min-width': availableMeasurements.length * 82 + 'px' }"
            >
                <div class="header">
                    <div class="measurement" [ngClass]="{ 'with-checkbox': !labelMode }">
                        <div
                            *ngFor="let mmt of availableMeasurements; let i = index"
                            [ngClass]="{
                                'measurement-item': true,
                                'last-aqi-border':
                                    AQIs.includes(mmt) &&
                                    !AQIs.includes(availableMeasurements[i + 1])
                            }"
                        >
                            <chart-checkbox-button
                                *ngIf="labelMode"
                                [mmt]="mmt"
                                [unit]="units[mmt] || units[mmt | uppercase]"
                                [isSelected]="selectedMeasurements.includes(mmt)"
                                [aqiTooltip]="aqiTooltipTemplate"
                                [mmtWithTooltip]="mmtWithTooltip"
                                (selectMmt)="toggleMeasurementOne(mmt)"
                            ></chart-checkbox-button>

                            <chart-checkbox
                                *ngIf="!labelMode && !AQIs.includes(mmt)"
                                [mmt]="mmt"
                                [unit]="units[mmt] || units[mmt | uppercase]"
                                [isSelected]="selectedMeasurements.includes(mmt)"
                                [mmtInfoIcon]="mmtInfoIcon"
                                [mmtWithTooltip]="mmtWithTooltip"
                                [groupTooltip]="groupTooltip"
                                (selectMmt)="toggleMeasurementOne(mmt)"
                                (toggleMmt)="toggleMeasurementWithCheck(mmt)"
                            ></chart-checkbox>

                            <chart-checkbox-aqi
                                *ngIf="!labelMode && AQIs.includes(mmt)"
                                [mmt]="mmt"
                                [unit]="units[mmt] || units[mmt | uppercase]"
                                [isSelected]="selectedMeasurements.includes(mmt)"
                                [aqiTooltip]="aqiTooltipTemplate"
                                [mmtInfoIcon]="mmtInfoIcon"
                                (selectMmt)="toggleMeasurementOne(mmt)"
                                (toggleMmt)="toggleMeasurementAqi(mmt)"
                            ></chart-checkbox-aqi>
                        </div>
                    </div>
                </div>
                <div class="container-content">
                    <div
                        *ngFor="let feature of data; count as len"
                        class="values-data"
                        [ngClass]="{ 'one-measurements': availableMeasurements.length === 1 }"
                    >
                        <div
                            *ngFor="let mmt of availableMeasurements; index as i"
                            [ngClass]="{
                                measurement__item: true,
                                'last-aqi-border':
                                    AQIs.includes(mmt) &&
                                    !AQIs.includes(availableMeasurements[i + 1])
                            }"
                        >
                            <div *ngIf="AQIs.includes(mmt); else value_content">
                                <div class="wrapper_points">
                                    <div class="wrapper_circle">
                                        <value-circle
                                            [value]="feature.properties.timeseries?.[mmt]?.[this.timeIndex]"
                                            [color]="measureZones.getColor(feature.properties.timeseries?.[mmt]?.[this.timeIndex], mmt)"
                                            [numberAfterDot]="1"
                                        ></value-circle>
                                    </div>
                                </div>
                            </div>
                            <ng-template #value_content>
                                <span class="value" [innerHTML]="getValue(feature, mmt)"></span>
                                <ng-container
                                    *ngIf="feature.properties.timeseries[mmt]?.[timeIndex] as value"
                                >
                                    <span
                                        class="custom-indicator custom-indicator--{{ mmt }}"
                                        *ngIf="mmt === WDA && (value | isNumber)"
                                        [style.transform]="'rotate(' + value + 'deg)'"
                                    ></span>
                                    <span
                                        class="custom-indicator__pdk"
                                        *ngIf="pdk && (value | exceedsPdkValue : pdk.pdks : mmt)"
                                    ></span>
                                </ng-container>
                            </ng-template>
                        </div>
                    </div>
                    <div
                        *ngIf="pdk"
                        [ngClass]="{
                            'values-data pdk': true,
                            'pdk-margin_left': availableMeasurements.length === 1
                        }"
                    >
                        <div
                            *ngFor="let mmt of availableMeasurements; index as i; count as len"
                            [ngClass]="{
                                pdk_item: true,
                                'pdk_item-empty': !pdk.pdks[mmt],
                                'pdk_item-first':
                                    !pdk.pdks[availableMeasurements[i - 1]] && pdk.pdks[mmt],
                                'pdk_item-last':
                                    !pdk.pdks[availableMeasurements[i + 1]] && pdk.pdks[mmt]
                            }"
                            [attr.pdk-title]="
                                pdk.type === PdkType.mr ? TEXTS.COMMON.pdkMr : TEXTS.COMMON.pdkSs
                            "
                        >
                            {{ pdk.pdks[mmt] }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="action-wrapper column-wrapper" *ngIf="showCompare">
            <div class="header"></div>
            <div class="container-content action">
                <div class="action-item" *ngFor="let feature of data">
                    <div
                        *ngIf="isCompare; else actionTemplate"
                        (click)="removeFromComparison.emit(feature.properties.uuid)"
                        class="remove-feature"
                        data-cy="remove-from-compare"
                        caTooltip
                        caTooltipPlacement="top-right"
                        [caTooltipAllowHover]="true"
                        [caTooltipTemplate]="temp1"
                    >
                        <cross-button size="medium"></cross-button>

                        <ng-template #temp1>
                            <ca-tooltip>
                                <span class="compare-tooltip">{{
                                    TEXTS.STATION_BLOCK.removeCompare
                                }}</span>
                            </ca-tooltip>
                        </ng-template>
                    </div>
                    <ng-template #actionTemplate>
                        <div
                            class="plus-button"
                            (click)="onCompare()"
                            caTooltip
                            caTooltipPlacement="top-right"
                            [caTooltipAllowHover]="true"
                            [caTooltipTemplate]="temp2"
                        >
                            <div class="plus-button-icon"></div>
                        </div>
                        <ng-template #temp2>
                            <ca-tooltip>
                                <span class="compare-tooltip">{{
                                    TEXTS.STATION_BLOCK.addCompare
                                }}</span>
                            </ca-tooltip>
                        </ng-template>
                    </ng-template>
                </div>
                <div class="action-pdk" *ngIf="pdk"></div>
            </div>
        </div>
    </div>
    <div class="data-container">
        <!-- Project legacy buttons -->
        <ng-content></ng-content>
    </div>
    <div class="mobile-measurements_data_wrapper">
        <div class="controls">
            <div
                class="chart__close-btn chart__close-btn--mobile"
                data-cy="close-chart-mobile"
                (click)="closeChart()"
            >
                <cross-button size="medium"></cross-button>
            </div>
        </div>
        <div
            class="mobile-measurements_data"
            *ngFor="let feature of data; trackBy: featureIdentify; index as i"
        >
            <div class="feature-name-wrapper" *ngIf="i === 0">
                <div class="feature-name">
                    <div
                        class="city-name link-to-city"
                        (click)="goCity(feature)"
                        *ngIf="feature.properties.obj === 'city'; else station_name"
                    >
                        {{ getCurrentFeatureName(feature) }}
                    </div>
                    <ng-template #station_name>
                        <div class="city-name">{{ getCurrentFeatureName(feature) }}</div>
                        <div class="station-text">
                            {{ getFeatureType(feature) }}
                        </div>
                    </ng-template>
                    <div class="city-sub-name">
                        <span *ngIf="feature.properties.obj === 'city'">
                            {{ TEXTS.STATION_BLOCK.averageCity }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="mobile-measurements_tabs_wrapper" *ngIf="availableMeasurements.length">
                <div class="mobile-measurements_items">
                    <div class="scroll-wrapper scroll-gray-mmt">
                        <div class="mobile-measurements_items__container">
                            <div
                                class="mobile-measurements_items__item"
                                [ngClass]="{
                                    selected_mmt: selectedMeasurements.includes(mmt),
                                    temp: mmt === 'T'
                                }"
                                (click)="toggleMeasurementOne(mmt)"
                                *ngFor="let mmt of availableMeasurements"
                            >
                                <div *ngIf="AQIs.includes(mmt); else content">
                                    <span class="mmt-name" style="padding-left: 5px"
                                        >{{ TEXTS.NAMES[mmt] || mmt }}
                                    </span>
                                    <div class="wrapper_points">
                                        <div class="wrapper_circle">
                                            <value-circle
                                                [value]="feature.properties.timeseries?.[mmt]?.[this.timeIndex]"
                                                [color]="measureZones.getColor(feature.properties.timeseries?.[mmt]?.[this.timeIndex], mmt)"
                                            ></value-circle>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #content>
                                    <span
                                        class="mmt-name"
                                        *ngIf="mmtNameLongArray.indexOf(mmt) >= 0; else mmt_label"
                                        >{{ mmtNamesLong[mmt] }}
                                    </span>
                                    <ng-template #mmt_label>
                                        <span
                                            class="mmt-name"
                                            [innerHtml]="TEXTS.NAMES[mmt] || mmt"
                                        ></span>
                                    </ng-template>
                                    <span class="value" [innerHTML]="getValue(feature, mmt)"></span>
                                    <span
                                        class="label"
                                        *ngIf="units[mmt | uppercase] as unit"
                                        [innerHTML]="unit | safeHtml"
                                    ></span>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="timeline-loader">
        <div class="wrapper-loader" [class.has_content]="(isEmptyData || !data.length) && (chartLoading$ | async) === false">
            <div class="wrapper-no-data" *ngIf="isEmptyData && (chartLoading$ | async) === false">
                {{ TEXTS.STATION_BLOCK.noData }}
            </div>
        </div>
    </div>
    <div class="timeline-loader" *ngIf="chartLoading$ | async">
        <div class="wrapper-loader spinner">
            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
        </div>
    </div>
    <div
        class="chart-content"
        [class.hidden]="isEmptyData || (chartLoading$ | async)"
        (mouseleave)="hoverLineVisible = false"
    >
        <canvas
            #chart
            baseChart
            [type]="chartType"
            [legend]="false"
            [datasets]="chartData"
            [options]="chartOptions"
            [plugins]="chartPlugins"
            (chartClick)="chartClick($event)"
        ></canvas>
        <div
            class="chart-hover-line"
            [style.visibility]="hoverLineVisible ? 'visible' : 'hidden'"
            [style.left.px]="hoverLinePosition"
        ></div>
    </div>
</div>

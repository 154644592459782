import { Component, Input, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { UserItems } from '@cityair/namespace';
import { TEXTS } from '@libs/common/texts/texts';
import { UsersService } from '@cityair/modules/users/users.service';

@Component({
    selector: 'users-list',
    templateUrl: 'users-list.component.html',
    styleUrls: ['users-list.component.less'],
})
export class UsersList {
    @Input() stateUsers: UserItems[];

    @ViewChild('feed') feed: CdkVirtualScrollViewport;

    TEXTS = TEXTS;
    searchUsers = '';
    sortingUser;
    sortingDirection = 1;

    sortUsersLogin = (stateUsers: UserItems) => stateUsers.login;
    sortUsersId = (stateUsers: UserItems) => stateUsers.roleId;

    constructor(public stateService: UsersService) {}

    sortingOfUesr = (sortItem) => {
        if (this.sortingUser === sortItem) {
            this.sortingDirection *= -1;
        } else {
            this.sortingUser = sortItem;
        }
    };
}

<div
    class="mo_plate"
    [class.mo_plate-active]="isActive"
    [class.mo_plate-complex]="isComplexMode"
    (click)="clickForCard()"
>
    <div class="mo_plate__main_info">
        <div class="mo_plate__text_block">
            <div
                *ngIf="name"
                class="mo_plate__name"
                [class.mo_plate__name-disable]="!isShowKebabMenu"
                (click)="clickForStaticTagsPost(idMo)"
            >
                {{ name }}
            </div>
            <div *ngIf="city" class="mo_plate__description">{{ city }}</div>
            <div *ngIf="description" class="mo_plate__description mo_plate__description-city">
                {{ description }}
            </div>
        </div>
        <value-circle
            [value]="value"
            [numberAfterDot]="1"
            [color]="valueColor"
            [selected]="isActive"
            [state]="markerState"
        ></value-circle>
        <kebab-menu *ngIf="isShowKebabMenu" (clickActions)="openActions($event)"></kebab-menu>
    </div>
    <div
        class="mo_plate__separation {{ isComplexMode ? 'mo_plate__separation--active' : '' }}"
    ></div>
    <div class="mo_plate__data_sources {{ isComplexMode ? 'mo_plate__data_sources--active' : '' }}">
        <div class="mo_plate__data_sources--static-tags scroll">
            <div class="mo_plate__title">
                {{ TEXTS.CITY_CARD.dataSources }}
            </div>
            <div
                *ngIf="!isNoDataSources && !isNotConnectedDevices"
                class="mo_plate__tag_devices_block"
            >
                <static-tags
                    class="mo_plate__tag_device"
                    *ngFor="let atd of arrDeviceName"
                    typeTag="device"
                    [isActiveElement]="atd.sourceId === 3"
                    [text]="atd.serialNumber"
                    [description]="atd.model"
                    [isOffline]="atd.offline"
                    (click)="clickForStaticTagsDevice(atd.serialNumber)"
                ></static-tags>
            </div>
            <div *ngIf="isNoDataSources && isNotConnectedDevices" class="mo_plate__not_devices">
                {{ TEXTS.CITY_CARD.noDataSources }}
            </div>
            <div
                *ngIf="!isNoDataSources && isNotConnectedDevices"
                class="mo_plate__not_devices mo_plate__not_devices--connect"
            >
                <static-tags
                    typeTag="device"
                    [description]="TEXTS.STATION_BLOCK.type.mo"
                ></static-tags>
            </div>
        </div>
        <div *ngIf="isFalseNumber(value)" class="mo_plate__not_date">
            {{ TEXTS.CITY_CARD.noData }}
        </div>
    </div>
</div>

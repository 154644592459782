import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    ViewChild,
    TemplateRef,
    ViewChildren,
    QueryList,
    Directive,
} from '@angular/core';

import { TEXTS } from '@libs/common/texts/texts';
import { saveDataToDisk } from '@cityair/utils/utils';
import { NotificationSubscription } from '@cityair/libs/common/api/adminPanel/dataTransformer';

import { NotificationSubscribeType } from '@cityair/modules/login/services/harvester-api/adminApiModels';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { NotificationsStateService } from '../../notifications-state.service';
import { first, tap } from 'rxjs';
import { selectTimeRange, selectUserId } from '@cityair/modules/core/store/selectors';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'subscription-card',
    templateUrl: 'subscription-card.component.html',
    styleUrls: ['subscription-card.component.less'],
})
export class SubscriptionCard implements OnInit, OnDestroy {
    @Input() subscription: NotificationSubscription;

    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    private popupOpenerElement: HTMLElement;

    isDeletePopupOpen = false;
    isDownloadPopupOpen = false;
    TEXTS = TEXTS;
    textsNotification = TEXTS.NOTIFICATIONS;
    textsDownloadPopup = TEXTS.DOWNLOAD_POPUP;

    subscriptionStatus: string;
    popupActive = false;
    popupPositionTop = 0;
    subscriptionTypes = NotificationSubscribeType;

    timeBegin: number;
    timeEnd: number;

    isShownInEventFeed = false;
    userId: number;

    constructor(
        public stateService: NotificationsStateService,
        public popupProvider: OffPanelPopupService,
        private store: Store
    ) {
        this.store.select(selectUserId)
            .pipe(
                first(),
                filter((v) => !!v),
            )
            .subscribe((id) => this.userId = id);
    }

    ngOnInit() {
        this.updateTime()
            .pipe(first())
            .subscribe(() => {
                this.isShownInEventFeed = this.subscription.forUserIds.includes(
                    this.userId
                );
            });
    }

    includeInEventFeed(e: Event, include: boolean) {
        this.closePopup(e);

        const userId = this.userId;
        const { forUserIds } = this.subscription;

        const newUserIds = include
            ? forUserIds.concat(userId)
            : forUserIds.filter((id) => id !== userId);

        this.stateService.updateEventFeedUserIds(this.subscription, newUserIds).then(() => {
            this.isShownInEventFeed = include;
        });
    }

    editSubscription() {
        this.stateService.openSettings(this.subscription);
    }

    activateSubscription(e: Event) {
        this.closePopup(e);

        if (this.subscription.isNew() || this.subscription.isSuggested()) {
            this.stateService.addSubscription(this.subscription);
        } else {
            this.stateService.activateSubscription(this.subscription);
        }
    }

    deactivateSubscription(e: Event) {
        this.closePopup(e);
        this.stateService.deactivateSubscription(this.subscription);
    }

    deleteSubscriptionDialog(e: Event) {
        this.closePopup(e);
        this.isDeletePopupOpen = true;
    }

    deleteSubscription() {
        this.stateService.currentSubscription = null;
        this.stateService.removeSubscription(this.subscription);
    }

    downloadFeedHistoryDialog(e: Event) {
        this.closePopup(e);
        this.isDownloadPopupOpen = true;
    }

    deleteSubscriptionAccept = () => {
        this.isDeletePopupOpen = false;
        this.deleteSubscription();
    };

    deleteSubscriptionCancel = () => {
        this.isDeletePopupOpen = false;
    };

    downloadFeedHistoryAccept = () => {
        this.isDownloadPopupOpen = false;
        this.downloadFeedHistory();
    };

    downloadFeedHistoryCancel = () => {
        this.updateTime()
            .pipe(first())
            .subscribe(() => {
                this.isDownloadPopupOpen = false;
            });
    };

    openPopup({ target, positionTop }) {
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.popupOutlet, () => (this.popupActive = false));
        this.popupOpenerElement = target;
        this.popupPositionTop = positionTop;
        this.popupActive = true;
    }

    closePopup(e: Event) {
        if (this.popupActive && this.popupOpenerElement !== e.target) {
            this.popupActive = false;
        }
    }

    private updateTime = () =>
        this.store.pipe(selectTimeRange).pipe(
            tap((time) => {
                this.timeBegin = time.begin;
                this.timeEnd = time.end;
            })
        );

    downloadFeedHistory() {
        const filename = 'feed-data.xlsx';

        this.stateService
            .downloadFeedHistory(this.subscription, this.timeBegin, this.timeEnd)
            .then((response) => {
                saveDataToDisk(response, filename);
            });
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
    }
}

@Directive()
export class ClosePopupsHelper {
    @ViewChildren(SubscriptionCard)
    popupHolders: QueryList<SubscriptionCard>;

    onScroll(e: Event) {
        this.popupHolders.find((c) => c.popupActive)?.closePopup(e);
    }
}

import { Map, LngLat, LngLatLike, PointLike, MercatorCoordinate, Sources, Layer } from 'mapbox-gl';

import { NgZone } from '@angular/core';

import { MO_AREA_RADIUS_IN_METERS } from '@cityair/config';

import { City_model, Marker_model } from '@cityair/namespace';
import { LineString } from 'geojson';

const DISTANCE_TO_CITY_IN_METERS = 100_000;

export function calculateGeometryForMarker(_coordinates: number[]): LineString {
    const coordinates = [];
    const degToRad = Math.PI / 180;
    const lat = _coordinates[1];
    const lng = _coordinates[0];

    const m0 = MercatorCoordinate.fromLngLat([lng, lat]);
    const c = MO_AREA_RADIUS_IN_METERS * m0.meterInMercatorCoordinateUnits();

    for (let angle = 0; angle < 360; angle += 60) {
        const m = MercatorCoordinate.fromLngLat([lng, lat]);
        const rad = angle * degToRad;
        m.x += Math.sin(rad) * c;
        m.y += Math.cos(rad) * c;
        coordinates.push(m.toLngLat().toArray());
    }
    coordinates.push(coordinates[0]);

    return {
        type: 'LineString',
        coordinates,
    };
}

export function findNearestMarker(
    markers: Marker_model[],
    location: { lat: number; lng: number }
): Marker_model {
    const userLatLng = new LngLat(location.lng, location.lat);

    if (markers.length && location.lat) {
        return markers.reduce((prev: Marker_model, curr: Marker_model) => {
            const cpos = userLatLng.distanceTo(
                new LngLat(curr.geometry.coordinates[0], curr.geometry.coordinates[1])
            );
            const ppos = userLatLng.distanceTo(
                new LngLat(curr.geometry.coordinates[0], curr.geometry.coordinates[1])
            );
            return cpos < ppos ? curr : prev;
        });
    }

    return null;
}

export function findClosestCity(cities: City_model[], center: LngLat): City_model {
    if (!cities || !cities.length) {
        return null;
    }

    let closest: { distance: number; city: City_model };

    cities.forEach((city) => {
        const distance = new LngLat(city.lng, city.lat).distanceTo(center);

        if (!closest || distance <= closest.distance) {
            closest = { distance, city };
        }
    });

    if (closest && closest.distance < DISTANCE_TO_CITY_IN_METERS) {
        return closest.city;
    }

    return null;
}

// TODO: extract as a service
export class MapObject {
    cache: {
        layers: Layer[];
        sources: Sources;
    };

    constructor(private readonly map: Map, private zone: NgZone) {
        const { layers, sources } = map.getStyle();
        this.cache = { layers, sources };
    }

    getCenter() {
        return this.map.getCenter().wrap();
    }

    centerTo(center: LngLatLike, zoom: number) {
        this.zone.runOutsideAngular(() => {
            this.map.jumpTo({
                center,
                zoom,
            });
        });
    }

    zoomTo(zoom: number) {
        this.zone.runOutsideAngular(() => {
            this.map.zoomTo(zoom);
        });
    }

    moveTo(center: LngLatLike, zoom?: number, offset?: PointLike) {
        this.zone.runOutsideAngular(() => {
            if (isNaN(zoom)) {
                return this.panTo(center, offset);
            }

            const options: any = {
                center,
                zoom,
                duration: 1000,
                easing: (t: number) => t,
            };

            if (offset) {
                options.offset = offset;
            }

            this.map.easeTo(options);
        });
    }

    panTo(coords: LngLatLike, offset?: PointLike) {
        this.zone.runOutsideAngular(() => {
            this.map.panTo(coords, offset ? { offset } : undefined);
        });
    }

    panBy(coords: PointLike) {
        this.zone.runOutsideAngular(() => {
            this.map.panBy(coords);
        });
    }
}

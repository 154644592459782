import { Component } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectMos, selectTypeInterval } from '@cityair/modules/core/store/selectors';
import { selectComparedItems } from '@cityair/modules/core/store/compared-list/compared-list.selectors';
import { DownloadPopupsService } from '@cityair/components/download-popups-wrapper/download-popups.service';

@Component({
    selector: 'download-btn',
    templateUrl: './download-btn.component.html',
    styleUrls: ['./download-btn.component.less'],
})
export class DownloadBtnComponent {
    TEXTS = TEXTS;

    constructor(
        private store: Store,
        private downloadPopupsService: DownloadPopupsService
    ) {}

    show = async () => {
        const ids = await firstValueFrom(
            this.store.select(selectComparedItems).pipe(map((mo) => mo.map((el) => el.id)))
        );

        this.downloadPopupsService.openDownloadPopup$.next({
            type: 1,
            title: TEXTS.EDIT_STATION.downloadXLSData,
            ids,
            mos: await firstValueFrom(this.store.select(selectMos)),
            devices: [],
            currentTabInterval: await firstValueFrom(this.store.select(selectTypeInterval)),
        });
    };
}

import { Component, OnInit } from '@angular/core';
import { ConfigPage } from './config.types';
import { Store } from '@ngrx/store';
import { selectGroupInfo } from '@cityair/modules/core/store/selectors';
import { filter, take } from 'rxjs/operators';
import { TokensService } from '@cityair/modules/core/services/api/tokens';

@Component({
    selector: 'config-panel',
    template: `
        <config-title [currentPage]="currentPage" (selectPage)="selectPage($event)"></config-title>

        <div class="config_panel__content">
            <ng-container [ngSwitch]="currentPage">
                <config-menu *ngSwitchDefault (selectPage)="selectPage($event)"></config-menu>
                <coefficient-view
                    *ngSwitchCase="configPage.COEFFICIENT"
                    (selectPage)="selectPage($event)"
                ></coefficient-view>
                <system-settings
                    *ngSwitchCase="configPage.SETTINGS"
                    (back)="selectPage($event)"
                ></system-settings>
                <browser-tokens *ngSwitchCase="configPage.ACTIVITY_HISTORY"></browser-tokens>
                <api-tokens *ngSwitchCase="configPage.API_TOKENS"></api-tokens>
            </ng-container>
        </div>
    `,
    styleUrls: ['config.component.less'],
})
export class ConfigPanelComponent implements OnInit {
    configPage = ConfigPage;
    currentPage = ConfigPage.HOME;

    constructor(private store: Store, private tokensService: TokensService) {}

    ngOnInit() {
        this.store
            .select(selectGroupInfo)
            .pipe(
                filter((g) => !!g),
                take(1)
            )
            .subscribe(() => this.tokensService.updateTokens());
    }

    selectPage = (page: ConfigPage) => (this.currentPage = page);
}

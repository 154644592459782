import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
    selector: 'ca-login-button',
    templateUrl: 'login-button.component.html',
    styleUrls: ['login-button.component.less'],
})
export class LoginButtonComponent {
    @Input() disabled?: boolean;

    @Input() isLoading?: boolean;

    @Output() clickAction = new EventEmitter<Event>();

    @HostListener('click', ['$event'])
    click(e: Event) {
        if (!this.disabled) {
            this.clickAction.emit(e);
        }
    }
}

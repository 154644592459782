<div class="point">
    <div
        class="content"
        (click)="showDetail = !showDetail"
        [ngClass]="{ 'with-detail': report.hasDetail }"
    >
        <div class="wrap-name">
            <div class="name">{{ report.name }}</div>
        </div>
        <div class="created">
            {{ report.createDate }}
        </div>
        <div class="period">
            {{ report.period }}
            <span class="timezone" [innerHTML]="report.timezoneOffset"></span>
        </div>
        <div class="user">
            <div *ngIf="report.user; else autoGenerate" class="user-name">
                <div [className]="'name-logo ' + report.user.avatarClass">
                    <span class="logo-text">{{ report.user.charAt }}</span>
                </div>
                <div class="email">{{ report.user.email }}</div>
            </div>
            <ng-template #autoGenerate>
                <div class="auto-generate">
                    <div class="auto-generate-label"></div>
                    <div class="auto-generate-text">
                        {{ translateText.autoGenerate }}
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="comment" #comment>
            <div
                *ngIf="report.comment; else showEditIcon"
                class="comment-icon"
                (click)="showComment($event)"
            ></div>
            <ng-template #showEditIcon>
                <span class="add-comment" (click)="editReportFromComment($event)">{{
                    translateText.addComment
                }}</span>
            </ng-template>
        </div>
        <div class="status">
            <div [className]="'status-text ' + report.status">
                <span class="dot"
                    ><status-dot-svg [color]="colors[report.status]" [width]="8"></status-dot-svg
                ></span>
                {{ translateText[report.status] }}
            </div>
        </div>
        <div class="actions">
            <div
                class="download"
                [ngClass]="{ disabled: report.isDisableDownload }"
                (click)="downloadReport($event)"
            ></div>
            <div class="report-kebab-menu">
                <kebab-menu (clickActions)="menuClick($event)"></kebab-menu>
            </div>
        </div>
    </div>
    <div class="info-content" *ngIf="showDetail && report.hasDetail">
        <div class="mo-detail">
            <div *ngIf="report.params.postIds as list">
                <div class="header-info">
                    <span>{{ postsMonitoringText }}</span>
                </div>
                <div class="content-detail">
                    <div class="post-list-wrapper">
                        <div class="post-list">
                            <div
                                *ngFor="let mo of list | slice : 0 : maxVisiblePost; index as i"
                                class="post-name"
                            >
                                {{ posts[mo] }}
                            </div>
                            <div
                                class="post-name more"
                                *ngIf="list.length > maxVisiblePost"
                                (click)="showAllPosts()"
                            >
                                {{ getMoreText(list.length - maxVisiblePost) }}
                            </div>
                            <div *ngIf="hideMorePost" class="post-name more" (click)="hidePosts()">
                                {{ notifyText.collapse }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="measure-detail" *ngIf="report.params.measure">
            <report-measure-select-list
                [required]="true"
                [mode]="'display'"
                [list]="report.params.measure"
                [selected]="report.params.measure"
                [multiSelect]="false"
            ></report-measure-select-list>
        </div>
        <div class="comment-info">
            <div class="header-info" *ngIf="report.comment">
                {{ translateText.hTableHeaderComment }}
            </div>
            <div class="comment-content">
                {{ report.comment }}
            </div>
        </div>
    </div>
</div>
<ng-template #commentTooltip>
    <div
        class="comment-tooltip scroll"
        [ngStyle]="{ top: commentPositionTop + 'px', left: commentPositionLeft + 'px' }"
        (clickOutside)="showComment($event)"
    >
        <div class="title">{{ translateText.formCreate.commentPlaceholder }}</div>
        <div class="content-tooltip">{{ report.comment }}</div>
        <div class="button-block">
            <div class="button delete" (click)="deleteComments($event)">
                {{ translateText.accept }}
            </div>
            <div class="button edit" (click)="editReportFromComment($event)">
                {{ translateText.edit }}
            </div>
        </div>
    </div>
</ng-template>

<div
    [ngClass]="{
        'post-pin': true,
        'post-pin_state-selected': state === markerState.default && selected,
        'post-pin_state-add': state === markerState.add && !selected,
        'post-pin_state-added': state === markerState.add && selected,
        'post-pin_state-check': state === markerState.selectPost,
        'post-pin_state-disable': hasNoData
    }"
    [style]="{ backgroundColor: color }"
    data-cy="post-marker"
>
    <div class="post-pin__grade" [style]="{ color: color }">{{ displayValue }}</div>
    <div class="post-pin__sign">
        <span class="post-pin__minus" [style]="{ backgroundColor: color }"></span>
        <span class="post-pin__plus" [style]="{ backgroundColor: color }"></span>
    </div>
    <svg
        class="post-pin__end"
        attr.fill="{{ color }}"
        viewBox="0 0 18 9"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.9701 6.58119C4.25581 4.39418 2.8206 0.947368 0 0H8.98007H18C15.1794 0.947368 13.7442 4.39418 12.0299 6.58119L10.7741 8.16349C10.5748 8.41545 10.3156 8.6271 9.99668 8.7682C9.69767 8.91937 9.34884 9 9 9C8.65116 9 8.30233 8.91937 7.99336 8.77828C7.68439 8.63718 7.41528 8.42553 7.21595 8.16349L5.9701 6.58119Z"
        />
    </svg>
</div>

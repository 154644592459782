import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, pluck, switchMapTo, take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import { AVAILABLE_DATA_IN_MONTH, PACKET_VALUE_TYPE_ID } from '@cityair/config';
import { WindowGlobalVars } from '@cityair/namespace';
import { TabModel } from '@libs/common/types/tab-model';
import { TEXTS } from '@libs/common/texts/texts';

import {
    NotificationEventType,
    NotificationFeedItem,
    NotificationSubscribeType,
} from '@cityair/modules/login/services/harvester-api/adminApiModels';

import { PacketsValueTypeItem } from '@cityair/modules/login/services/harvester-api/models';
import {
    selectCurrentTime,
    selectGroupInfo,
    selectMarker,
    selectMos,
} from '@cityair/modules/core/store/selectors';
import {
    clickFromApToMo,
    currentTimeUpdate,
    intervalUpdate,
} from '@cityair/modules/core/store/actions';

import {
    NotificationsStateService,
    NotificationState,
    Pages,
    SubscriptionTypeFilter,
} from '../../notifications-state.service';
import { annotateWithDates, shortDateFormatWithClosest } from '@libs/common/utils/date-formats';
import { MultipleSearchFnsPipe } from '@libs/shared-ui/components/pipes/pipes';

function pastDateOrNow(dateTime: number) {
    return Math.min(dateTime, Date.now());
}

declare const window: WindowGlobalVars;

type MoNavigationProps = { moId: number; dateTime: string; mmt?: string };

@Component({
    selector: 'event-feed',
    templateUrl: 'event-feed.component.html',
    styleUrls: ['event-feed.component.less'],
})
export class EventFeed implements OnInit, OnDestroy {
    @ViewChild('feed') feed: CdkVirtualScrollViewport;

    textsNotification = TEXTS.NOTIFICATIONS;
    todayDate = new Date();
    settingsForm: UntypedFormGroup;
    filterText = '';
    disableNavigation = false;
    onDestroy$ = new Subject<void>();

    filterText$ = new BehaviorSubject<string>(this.filterText);

    feedItems$ = new BehaviorSubject<NotificationFeedItem[]>([]);

    lists: {
        all$?: Observable<NotificationFeedItem[]>;
        selected$?: Observable<NotificationFeedItem[]>;
        filtered$?: Observable<NotificationFeedItem[]>;
        annotated$?: Observable<
            {
                item: NotificationFeedItem;
                annotation: string;
            }[]
        >;
    } = {};

    packetValueTypes: PacketsValueTypeItem[];

    filterTypes = SubscriptionTypeFilter;

    filterTabs = [
        {
            title: this.textsNotification.measures,
            type: SubscriptionTypeFilter.MEASUREMENTS,
        },
        {
            title: this.textsNotification.service,
            type: SubscriptionTypeFilter.SERVICE,
        },
    ];

    selectedTab: TabModel = this.filterTabs[0];

    loading = true;
    initialScrollPosition = true;
    selectedCount = 0;
    uiState: NotificationState;
    mosIds: number[] = [];

    private dateAnnotator = annotateWithDates<NotificationFeedItem>(
        (item) => this.getRelevantTime(item),
        shortDateFormatWithClosest
    );

    constructor(
        private fb: UntypedFormBuilder,
        readonly stateService: NotificationsStateService,
        private msfPipe: MultipleSearchFnsPipe,
        private router: Router,
        private store: Store
    ) {}

    ngOnInit() {
        this.resetFilter();

        const { filterText$, feedItems$, lists, searchFilters } = this;

        feedItems$.next([]);

        lists.all$ = feedItems$.asObservable();

        lists.selected$ = lists.all$.pipe(
            switchMapTo(this.stateService.lists.selected$, (es, ss) =>
                es.filter(
                    (e) =>
                        ss.find((s) => s.id === e.NotificationId) &&
                        this.stateService.hasFilterType(e.SubscribeType)
                )
            )
        );

        lists.filtered$ = lists.selected$.pipe(
            switchMapTo(filterText$, (es, text) => this.msfPipe.transform(es, searchFilters, text))
        );

        lists.annotated$ = lists.filtered$.pipe(map(this.dateAnnotator));

        this.settingsForm = this.fb.group({
            text: [''],
        });

        this.loading = true;

        this.store
            .select(selectGroupInfo)
            .pipe(
                filter((g) => !!g),
                take(1)
            )
            .subscribe((groupInfo) => {
                this.stateService
                    .getUsersEventFeed(groupInfo.iAm.userId, groupInfo?.groupId)
                    .then((response) => {
                        this.packetValueTypes = response.PacketValueTypes;
                        this.updateLists(() =>
                            response.FeedItems.sort((a, b) => {
                                let cmp = 0;
                                if (a.UpdateTime && b.UpdateTime) {
                                    cmp =
                                        new Date(a.UpdateTime).getTime() -
                                        new Date(b.UpdateTime).getTime();
                                } else if (a.UpdateTime) {
                                    cmp = -1;
                                } else if (b.UpdateTime) {
                                    cmp = 1;
                                } else {
                                    cmp = a.FeedItemId - b.FeedItemId;
                                }
                                return cmp;
                            }).reverse()
                        );
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        this.store
            .select(selectMos)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((mos) => {
                this.mosIds = mos.map((v) => v.id);
            });

        this.lists.selected$.pipe(takeUntil(this.onDestroy$), pluck('length')).subscribe((len) => {
            this.selectedCount = len;
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    scrollTop() {
        this.feed.scrollToIndex(0);
    }

    filterUpdate(text: string) {
        this.filterText$.next(text);
    }

    resetFilter() {
        this.uiState = this.stateService.getState(Pages.EVENT_FEED);
        this.showSelectedTab(this.filterTabs.find((t) => t.type === this.uiState.typeFilter));
    }

    updateLists(transform: (ss: NotificationFeedItem[]) => NotificationFeedItem[]) {
        this.feedItems$.pipe(take(1)).subscribe((list) => {
            this.feedItems$.next(transform(list));
        });
    }

    getRelevantTime(event: NotificationFeedItem) {
        switch (event.EventType) {
            case NotificationEventType.End:
                return event.EndTime;
            case NotificationEventType.Continue:
                return event.UpdateTime;
            default:
                return event.BeginTime;
        }
    }

    openSubscriptions = () => {
        this.stateService.openSubscriptions(this.uiState.typeFilter);
    };

    isCurrentFilter(filter: SubscriptionTypeFilter) {
        return filter === this.stateService.typeFilter;
    }

    applyFilter(filter: SubscriptionTypeFilter) {
        if (!this.isCurrentFilter(filter)) {
            this.stateService.typeFilter = this.uiState.typeFilter = filter;
            this.stateService.updateLists((v) => v);
            this.updateLists((v) => v);
        }
    }

    showSelectedTab(tab: TabModel) {
        this.selectedTab = tab;
        this.applyFilter(tab.type);
    }

    getRelatedMarker(notification: NotificationFeedItem) {
        const moId = {
            [NotificationSubscribeType.PdkExcess]: notification.PdkMoContent,
            [NotificationSubscribeType.ServiceDevice]: notification.ServiceContent,
        }[notification.SubscribeType].MoInfo?.MoId;

        return this.store.select(selectMarker(moId));
    }

    gotoMonitoringObject({ moId, dateTime, mmt }: MoNavigationProps) {
        this.store
            .select(selectMarker(moId))
            .pipe(take(1))
            .subscribe((marker) => {
                if (marker) {
                    this.store.pipe(selectCurrentTime, take(1)).subscribe(() => {
                        this.showAfterChartUpdates(dateTime);
                    });

                    this.store.dispatch(
                        intervalUpdate({
                            ...this.getEventWindow(dateTime),
                        })
                    );

                    this.store.dispatch(clickFromApToMo({ moObjId: moId }));
                }
            });
    }

    checkNotifyClickable(item: NotificationFeedItem) {
        const today = moment();
        const begin = moment(item.BeginTime);
        const diffMonth = today.diff(begin, 'months', true);
        let moId;
        if (item.SubscribeType === NotificationSubscribeType.PdkExcess) {
            moId = item.PdkMoId;
        } else if (item.SubscribeType === NotificationSubscribeType.ServiceDevice) {
            moId = item.ServiceContent?.MoInfo?.MoId;
        }

        if (moId && this.mosIds.indexOf(moId) >= 0 && diffMonth <= AVAILABLE_DATA_IN_MONTH) {
            return false;
        }

        return true;
    }

    private showAfterChartUpdates(dateTime: string) {
        const time = moment(dateTime).valueOf();
        // TODO отрефакторить все вызовы updateCurrentTime которые перебивают этот
        setTimeout(() => this.store.dispatch(currentTimeUpdate({ time })), 1000);
    }

    private getEventWindow(dateTime: string) {
        // use +/- 1 day, since there is TZ-shift in timeline after calling updateTimeDiapason
        const timePaddingInMs = 24 * 60 * 60 * 1000;
        const eventTime = moment(dateTime).valueOf();

        return {
            begin: eventTime - timePaddingInMs,
            end: pastDateOrNow(eventTime + timePaddingInMs),
        };
    }

    searchFilters = [
        (item: NotificationFeedItem) => item.NotificationTitle,
        (item: NotificationFeedItem) => item.ServiceContent?.DeviceInfo?.SerialNumber,
        (item: NotificationFeedItem) => item.ServiceContent?.MoInfo?.Name,
        (item: NotificationFeedItem) => item.PdkMoContent?.MoInfo?.Name,
        (item: NotificationFeedItem) => PACKET_VALUE_TYPE_ID[item.PdkValueType],
    ];

    openSettingsFor(notification: NotificationFeedItem) {
        this.stateService.lists.selected$
            .pipe(
                take(1),
                map((ss) => ss.find((s) => s.id === notification.NotificationId))
            )
            .subscribe((subscription) => {
                if (subscription) {
                    this.stateService.openSettings(subscription);
                }
            });
    }
}

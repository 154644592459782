<div class="label-wrapper">
    <label
        class="label"
        [ngClass]="{
            'selected-item': isSelected
        }"
    >
        <div
            class="wrap-checkbox"
            [attr.title]="TEXTS.STATION_BLOCK.add"
            caTooltip
            caTooltipPlacement="top"
            [caTooltipAllowHover]="true"
            [caTooltipTemplate]="tooltip1"
            (click)="toggleMmt.emit()"
        >
            <input-radio-square [check]="isSelected"></input-radio-square>
        </div>
        <span
            [attr.title]="TEXTS.STATION_BLOCK.select"
            (click)="selectMmt.emit()"
            [innerHtml]="TEXTS.NAMES[mmt] || mmt"
            caTooltip
            caTooltipPlacement="top"
            [caTooltipAllowHover]="true"
            [caTooltipTemplate]="tooltip2"
        ></span>
        <ca-info-icon
            *ngIf="mmtInfoIcon?.name === mmt"
            data-cy="timeline-info-mmt"
            style="margin-left: 6px"
            (click)="mmtInfoIcon.cb()"
        ></ca-info-icon>
    </label>
    <div class="label-mmt" *ngIf="unit" [innerHTML]="unit | safeHtml"></div>
</div>

<ng-template #tooltip1>
    <ca-tooltip>
        <div class="aqi_tooltip" [innerHTML]="TEXTS.AQI_DETAILED_INFO.selectAqi"></div>
    </ca-tooltip>
</ng-template>

<ng-template #tooltip2>
    <ng-container
        *ngIf="mmt === AQI || mmt === AqiType.instant"
        [ngTemplateOutlet]="aqiTooltip"
    ></ng-container>
</ng-template>

import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as moment from 'moment';

import {
    NotificationSubscribeType,
    NotificationFeedItem,
    ServiceExcessType,
    NotificationEventType,
} from '@cityair/modules/login/services/harvester-api/adminApiModels';
import { TEXTS, LANGUAGE } from '@libs/common/texts/texts';
import { shortDateFormat, shortDateFormatWithToday } from '@libs/common/utils/date-formats';

const enum DurationMap {
    STARTED = 'begin',
    CONTINUING = 'continue',
}

const DurationsAbbr = {
    ru: {
        years: 'г',
        months: 'мес',
        days: 'дн',
        hours: 'ч',
        minutes: 'мин',
    },
    en: {
        years: 'yr',
        months: 'mo',
        days: 'd',
        hours: 'h',
        minutes: 'min',
    },
};

function dateRangeText(begin: Date, end: Date) {
    return [shortDateFormat(begin), '—', shortDateFormat(end)].join(' ');
}

@Component({
    selector: 'event-feed-item',
    templateUrl: 'event-feed-item.component.html',
    styleUrls: ['event-feed-item.component.less'],
})
export class EventFeedItem implements OnChanges {
    @Input() notification: NotificationFeedItem;
    @Input() todayDate: Date;
    @Input() disableNavigation: boolean;
    @Input() configurable: boolean;

    @Output() gotoMonitoringObject = new EventEmitter<{
        moId: number;
        dateTime: string;
        mmt?: string;
    }>();

    @Output() openSubscriptionSettings = new EventEmitter<void>();

    @Output() markNotificationAsVisited = new EventEmitter<void>();

    tooltipIconText: string;
    firstNotification: NotificationFeedItem;
    durationType: DurationMap;
    textsNotification = TEXTS.NOTIFICATIONS;
    eventTypes = NotificationEventType;
    serviceExcessTypes = ServiceExcessType;
    firstEventVisible = false;
    durationTooltipText = '';
    headerDateTimeText = '';
    longNameHeaderDuration = '';

    constructor() {}

    ngOnChanges() {
        const { BeginTime, EndTime, SubscribeType } = this.notification;

        this.durationType = this.hasEnded ? DurationMap.CONTINUING : DurationMap.STARTED;

        this.tooltipIconText =
            {
                [NotificationSubscribeType.PdkExcess]:
                    this.textsNotification.tooltipEventIcon.measurements,
                [NotificationSubscribeType.ServiceDevice]:
                    this.textsNotification.tooltipEventIcon.service,
            }[SubscribeType] || '';

        this.durationTooltipText = this.getDurationTooltipText();

        this.headerDateTimeText = this.hasEnded
            ? this.durationFormat(BeginTime, EndTime)
            : shortDateFormatWithToday(this.todayDate, BeginTime);

        this.firstNotification = this.hasEnded
            ? Object.assign(new NotificationFeedItem(), this.notification, {
                  UpdateTime: null,
                  EventType: this.eventTypes.Begin,
              })
            : null;
    }

    getDurationTooltipText() {
        const { BeginTime, EndTime, UpdateTime } = this.notification;

        let result = '';

        if (this.hasEnded) {
            result = dateRangeText(BeginTime, EndTime);
        } else {
            const end = UpdateTime === BeginTime ? new Date() : UpdateTime;
            result = this.durationFormat(BeginTime, end);
        }

        return result;
    }

    get hasEnded() {
        return this.notification.EventType === this.eventTypes.End;
    }

    get isPdkType() {
        return this.notification.SubscribeType === NotificationSubscribeType.PdkExcess;
    }

    get isServiceType() {
        return this.notification.SubscribeType === NotificationSubscribeType.ServiceDevice;
    }

    openSettings() {
        this.openSubscriptionSettings.emit();
    }

    toggleFirstEvent(e: Event) {
        e.stopPropagation();

        this.firstEventVisible = !this.firstEventVisible;
    }

    durationFormat(begin: Date, end: Date) {
        const abbr = DurationsAbbr[LANGUAGE] || DurationsAbbr.en;

        const duration = moment.duration(moment(end).diff(begin));

        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();

        const d = [];

        if (years) {
            d.push(`${years} ${abbr.years}`);
        }

        if (months) {
            d.push(`${months} ${abbr.months}`);
        }

        if (days) {
            d.push(`${days} ${abbr.days}`);
        }

        if (hours) {
            d.push(`${hours} ${abbr.hours}`);
        }

        if (minutes) {
            d.push(`${minutes} ${abbr.minutes}`);
        }

        if (d.length > 2) {
            this.longNameHeaderDuration = 'long-duration';
        }

        return d.length ? d.join(' ') : `0 ${abbr.minutes}`;
    }

    markAsVisited() {
        if (!this.notification.IsViewed) {
            this.markNotificationAsVisited.emit();
        }
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputDateRangeComponent } from './input-date-range.component';
import { InputForDropdownModule } from '@cityair/components/input-for-dropdown/input-for-dropdown.module';
import { CalendarMonthsModule } from '@cityair/libs/common/calendar/calendar-months/calendar-months.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';

@NgModule({
    exports: [InputDateRangeComponent],
    declarations: [InputDateRangeComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InputForDropdownModule,
        CalendarMonthsModule,
        DirectivesModule,
    ],
})
export class InputDateRangeModule {}

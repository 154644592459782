<div class="coefficient_view scroll">
    <div class="coefficient_view__padding">
        <div
            class="coefficient_view__content"
            *ngFor="let text of TEXTS.COEFFICIENT_VIEW.content"
            [innerHTML]="text"
        ></div>
    </div>

    <div class="coefficient_view__current">
        {{TEXTS.COEFFICIENT_VIEW.current}}
        <b>{{(store.select(selectRegionalCoefficient) | async)?.currentPcf}}</b>
    </div>
    <div class="coefficient_view__padding">
        <div class="coefficient_view__new_des">{{TEXTS.COEFFICIENT_VIEW.new[0]}}</div>
        <div class="coefficient_view__input_wrap">
            <div class="coefficient_view__input_title">{{TEXTS.COEFFICIENT_VIEW.new[1]}}</div>
            <input
                class="coefficient_view__input"
                type="number"
                step="0.1"
                min="{{min}}"
                max="{{max}}"
                [placeholder]="TEXTS.COEFFICIENT_VIEW.new[2]"
                [(ngModel)]="newCoef"
            />
            <div *ngIf="isVrongCoeff()" class="coefficient_view__input_error">
                {{TEXTS.COEFFICIENT_VIEW.errorPcf}}
            </div>
        </div>
    </div>

    <div class="coefficient_view__current">
        <span [outerHTML]="TEXTS.COEFFICIENT_VIEW.scf + ' '"></span>
        <b>{{(store.select(selectRegionalCoefficient) | async)?.currentScf}}</b>
    </div>
    <div class="coefficient_view__padding">
        <div class="coefficient_view__new_des">{{TEXTS.COEFFICIENT_VIEW.scfComment}}</div>
    </div>

    <div class="coefficient_view__description_wrap">
        <div class="coefficient_view__padding coefficient_view__description">
            {{TEXTS.COEFFICIENT_VIEW.description}}
        </div>
    </div>
</div>

<buttons-save-cancel
    class="coefficient_view__btn"
    (cancel)="clearCoef(); back();"
    [disabled]="!newCoef || isVrongCoeff()"
    (save)="showPopup = true"
    [textSave]="TEXTS.COMMON.apply"
></buttons-save-cancel>

<ng-template #coeffPopup>
    <stnd-ask-popup2
        class="popup_splash_fixed"
        *ngIf="showPopup"
        (accept)="regionalCoefficient.setCoefficient(newCoef); showPopup = false"
        (cancel)="showPopup = false"
        [texts]="{title: TEXTS.COEFFICIENT_VIEW.title}"
    >
        <div class="coefficient_view__content">{{TEXTS.COEFFICIENT_VIEW.popup[0]}}</div>
        <div class="coefficient_view__content">
            {{TEXTS.COEFFICIENT_VIEW.current}} <b>{{(store.select(selectRegionalCoefficient) | async)?.currentPcf}}</b>
        </div>
        <div class="coefficient_view__content">
            {{TEXTS.COEFFICIENT_VIEW.popup[1]}} <b>{{newCoef}}</b>
        </div>
    </stnd-ask-popup2>
</ng-template>

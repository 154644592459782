import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Group } from '@libs/common/types/group';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';

@Component({
    selector: 'shared-ui-sidebar-menu',
    templateUrl: './sidebar-menu.component.html',
    styleUrls: ['./sidebar-menu.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMenuComponent {
    @Input() groupList: Group[];
    @Input() currentGroupId: number;
    @Input() availableModules: MAIN_PAGES[];
    @Input() activeModule: MAIN_PAGES;
    @Input() assets: any;

    @Output() selectModule = new EventEmitter<any>();
    @Output() changeGroup = new EventEmitter<any>();
    @Output() showFeedback = new EventEmitter<boolean>();

    public MAIN_PAGE = MAIN_PAGES;
    public selectGroup(groupId) {
        if (this.currentGroupId !== groupId) {
            this.changeGroup.emit(groupId);
        }
    }

    public select(page: MAIN_PAGES) {
        this.selectModule.emit(page);
    }

    public isEnabled(page: MAIN_PAGES): boolean {
        return this.availableModules && this.availableModules.indexOf(page) >= 0;
    }

    public showFeedbackForm() {
        this.showFeedback.emit(true);
    }
}

<div
    class="language-selector language-selector-{{ isDropdownOpened ? 'show' : '' }}"
    (click)="isDropdownOpened = !isDropdownOpened"
    (clickOutside)="isDropdownOpened = false"
>
    <div class="language-selector-item language-selector-item--active">
        <ca-language-item-m
            [code]="lang.code"
            [name]="lang.name"
            [showFlag]="showFlags"
        ></ca-language-item-m>

        <ca-triangle-chevron
            class="language-selector__dropdown-icon"
            [direction]="isDropdownOpened ? 'up' : 'down'"
        ></ca-triangle-chevron>
    </div>

    <div class="">
        <ca-language-item-m
            class="language-selector-item"
            *ngFor="let lang of langItems$ | async"
            (click)="setLang(lang)"
            [code]="lang.code"
            [name]="lang.name"
            [showFlag]="showFlags"
        ></ca-language-item-m>
    </div>
</div>

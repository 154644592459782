<ng-container [ngSwitch]="usersService.currentPage">
    <users-list *ngSwitchDefault [stateUsers]="usersFiltred$ | async"></users-list>
    <edit-user *ngSwitchCase="pages.EDIT_USER"
               [users]="store.select(selectUsers) | async"
               [mos]="store.select(selectMos) | async"
    ></edit-user>
</ng-container>

<ng-template #popupOutlet>
    <stnd-ask-popup
            *ngIf="usersService.isShowDeleteUserPopup"
            [accept]="usersService.deleteUser"
            [close]="hideDeleteUserPopup"
            [texts]="TEXTS.POPUP_DELETE_USER"
    >
    </stnd-ask-popup>
</ng-template>

import { HUM, TEMP } from '../consts/substance.consts';
import { DATA_INTERVAL_TYPES } from '../types/data-interval';
import { IntervalEnum } from '../enums/interval.enum';
import { QueryParams } from '@ngrx/data';
import { Feature } from './feature';
import * as moment from 'moment';

export const DEVICE_TERMS_VALUES = [TEMP, HUM];
export const DEFAULT_DATA_QUALITY_PERCENT = 50;
export enum DataQualityStatus {
    noData = 'noData',
    tempMaxValue = 'tempMaxValue',
    tempMinValue = 'tempMinValue',
    tempGradient = 'tempGradient',
    phMaxValue = 'phMaxValue',
    phMinValue = 'phMinValue',
    phGradient = 'phGradient',
    mmtMaxValue = 'mmtMaxValue',
    mmtMinValue = 'mmtMinValue',
    mmtMinMaxValue = 'mmtMinMaxValue',
}
export interface ITooltipData {
    key: DataQualityStatus;
    values?: number[] | string[];
}
export enum MarkerSeverityType {
    critical = 'Critical',
    low = 'Low',
}

export interface IDataMarkerResponse {
    code: string;
    type: string;
    value: number;
    value_type: string;
    severity: 'Critical' | 'Low';
}
export interface IDataMarker {
    type: string;
    value: number;
    valueType: string;
    code: string;
    severity: MarkerSeverityType;
}
export class DataMarker implements IDataMarker {
    type: string;
    value: number;
    valueType: string;
    code: string;
    severity: MarkerSeverityType;

    constructor(marker: IDataMarkerResponse) {
        this.type = marker.type;
        this.value = marker.value;
        this.valueType = marker.value_type;
        this.code = marker.code;
        this.severity =
            marker.severity === 'Critical' ? MarkerSeverityType.critical : MarkerSeverityType.low;
    }
}
export interface IDataQuality {
    marked_packets: number;
    marker_codes: string[];
}

export class DataQuality {
    markedPackets: number;
    markerCodes: string[];
    interval: IntervalEnum;
    isShow(percent: number): boolean {
        if (!percent) return false;
        const allPackagesNumber = DATA_INTERVAL_TYPES[this.interval];
        return (this.markedPackets * 100) / allPackagesNumber >= percent;
    }

    constructor(data: IDataQuality, interval: string) {
        this.markedPackets = data.marked_packets;
        this.markerCodes = data.marker_codes;
        this.interval = +interval as IntervalEnum;
    }
}

export interface DataQualityResponse {
    data_quality?: IDataQuality[];
}

interface GeoPoint {
    type: string;
    coordinates: [number, number];
}

export interface IDataQualityObjectResponse {
    id: number;
    name: string;
    geometry: GeoPoint;
    gmt_offset_seconds: number;
    data: DataQualityResponse;
    group_id?: number;
    obj: string;
}
export interface MetaExtraDataQuality {
    dates: string[];
    markers: IDataMarkerResponse[];
}
export interface MetaDataQualityResponse {
    extra: MetaExtraDataQuality;
    status: string;
    type: string;
    error: any;
}
export interface IDataQualityTimelineResponse {
    data: IDataQualityObjectResponse[];
    meta: MetaDataQualityResponse;
}
export interface IDataQualityTimeline {
    tooltipData: ITooltipData[];
    showLink: boolean;
    dataMarkers: DataMarker[];
    isCritical: boolean;
}
export interface IQualityDataParams extends QueryParams {
    group_id: string;
    date__gt: string;
    date__lt: string;
    interval: string;
    post_id?: string;
    packet_type?: string;
}
export enum DataQualityMarkerType {
    MinValue = 'MinValue',
    MaxValue = 'MaxValue',
}
export class DataQualityTimeline implements IDataQualityTimeline {
    tooltipData: ITooltipData[] = [];
    showLink: boolean;
    dataMarkers: DataMarker[];
    isCritical: boolean;
    withMmt = false;
    constructor(data: DataMarker[]) {
        this.dataMarkers = data;
        this.init(data);
        const criticalMarkers = this.dataMarkers.find(
            (marker) => marker.severity === MarkerSeverityType.critical
        );
        this.isCritical = criticalMarkers ? true : false;
    }

    private init(markers: DataMarker[]) {
        let mmtsMax = [];
        let mmtsMin = [];
        const tempValues = markers.filter(
            (marker) =>
                marker.valueType === TEMP &&
                (marker.type === DataQualityMarkerType.MinValue ||
                    marker.type === DataQualityMarkerType.MaxValue)
        );
        const tempArray = [];
        markers.forEach((marker) => {
            if (DEVICE_TERMS_VALUES.indexOf(marker.valueType) >= 0) {
                if (marker.valueType === TEMP && tempArray.length === 0) {
                    if (tempValues.length === 2) {
                        tempArray.push(TEMP);
                        this.tooltipData.push({
                            key: DataQualityStatus.mmtMinMaxValue,
                            values: [marker.valueType],
                        });
                    } else {
                        this.tooltipData.push({
                            key:
                                marker.type === DataQualityMarkerType.MinValue
                                    ? DataQualityStatus.tempMinValue
                                    : DataQualityStatus.tempMaxValue,
                            values: [marker.value],
                        });
                    }
                }
                if (marker.valueType === HUM) {
                    this.tooltipData.push({
                        key:
                            marker.type === DataQualityMarkerType.MinValue
                                ? DataQualityStatus.phMinValue
                                : DataQualityStatus.phMaxValue,
                        values: [marker.value],
                    });
                }
            } else {
                if (marker.type === DataQualityMarkerType.MinValue) {
                    mmtsMin.push(marker.valueType);
                } else if (marker.type === DataQualityMarkerType.MaxValue) {
                    mmtsMax.push(marker.valueType);
                }
            }
        });
        const intersection = mmtsMax.filter((x) => mmtsMin.includes(x));
        mmtsMax = mmtsMax.filter((v) => !intersection.includes(v));
        mmtsMin = mmtsMin.filter((v) => !intersection.includes(v));
        if (mmtsMax.length) {
            this.tooltipData.push({
                key: DataQualityStatus.mmtMaxValue,
                values: mmtsMax,
            });
            this.withMmt = true;
        }
        if (mmtsMin.length) {
            this.tooltipData.push({
                key: DataQualityStatus.mmtMinValue,
                values: mmtsMin,
            });
            this.withMmt = true;
        }
        if (intersection.length) {
            this.tooltipData.push({
                key: DataQualityStatus.mmtMinMaxValue,
                values: intersection,
            });
            this.withMmt = true;
        }
    }
}
export interface ITimeSeriesDataQualityInfo {
    key: string;
    value: number | string;
}
export interface IDataQualityInfo {
    id: number;
    name: string;
    dataMarkers: DataMarker[];
    currentDate: string;
    dataInfo: {
        deviceUse?: ITimeSeriesDataQualityInfo[];
        mmtLimit?: ITimeSeriesDataQualityInfo[];
    };
    markerInfo: {
        deviceUse?: ITimeSeriesDataQualityInfo[];
        mmtLimit?: ITimeSeriesDataQualityInfo[];
    };
}
export class DataQualityInfo implements IDataQualityInfo {
    id: number;
    name: string;
    dataInfo;
    markerInfo;
    dataMarkers: DataMarker[];
    currentDate: string;
    constructor(markers: DataMarker[], feature: Feature, index) {
        this.id = feature.properties.uuid;
        this.name = feature.properties.name;
        this.dataMarkers = markers;
        this.currentDate = moment(feature.properties.timeseries.date[index]).format(
            'YYYY-MM-DD HH:mm'
        );
        const data = this.prepareData(markers, feature, index);
        this.dataInfo = { deviceUse: data.deviceInfo };
    }

    private prepareData(markers: DataMarker[], feature: Feature, index: number) {
        const deviceInfo: ITimeSeriesDataQualityInfo[] = [];
        const mmtLimit: ITimeSeriesDataQualityInfo[] = [];
        const mmtValues = [];
        markers.forEach((marker) => {
            const mmt = marker.valueType;
            if (DEVICE_TERMS_VALUES.indexOf(mmt) >= 0) {
                if (
                    feature.properties.timeseries[mmt] &&
                    feature.properties.timeseries[mmt][index]
                ) {
                    deviceInfo.push({ key: mmt, value: feature.properties.timeseries[mmt][index] });
                } else {
                    deviceInfo.push({ key: mmt, value: null });
                }
            } else {
                if (
                    feature.properties.timeseries[mmt] &&
                    feature.properties.timeseries[mmt][index]
                ) {
                    mmtValues.push({ key: mmt, value: feature.properties.timeseries[mmt][index] });
                }
            }
        });
        return {
            deviceInfo,
            mmtLimit,
        };
    }
}

<div class="aqi-legend scroll-gray">
    <div class="aqi-legend__header">
        <div class="aqi-legend__navigation aqi-legend__navigation">
            <div class="aqi-legend__close-btn" (click)="close()">{{ TEXTS.COMMON.close }}</div>
        </div>
        <div class="aqi-legend__title">Air Quality Index</div>
        <div class="aqi-legend__description" [innerHtml]="TEXTS.TEXT_DESCRIPTION.airQuality"></div>
    </div>
    <div class="aqi-legend__content">
        <div
            class="aqi-legend__item"
            [class.aqi-legend__item--highlight]="i === currentAqiDangerLevel"
            *ngFor="let description of TEXTS.AQI_THREE_TEXTS; index as i"
        >
            <div class="aqi-legend__item-icon aqi-legend__item-icon--{{ i }}"></div>
            <div class="aqi-legend__item-description">{{ description }}</div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { Actions as NgRxActions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { defer, interval, of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';

import { CityscreenMapApi } from '@cityair/libs/common/api/mapProvider/cityscreenMapApi';
import { AdminPanelApi } from '@cityair/libs/common/api/adminPanel/api';
import { GROUP_ID, IntervalEnum, MarkerType, StationForMapPage_model } from '@cityair/namespace';
import { GetCSTimelineInfoRequest, MINUTE5_MS } from '@cityair/namespace';

import {
    changeQualityDataMode,
    cityModeOff,
    clickFromApToMo,
    clickOnCityMarker,
    currentTimeUpdate,
    destroyMainMap,
    doNothing,
    groupInfoLoad,
    groupInfoLoaded,
    groupListLoaded,
    initMainMap,
    intervalUpdate,
    loadCity,
    loadMonitoringData,
    loadQualityData,
    loadStationData,
    mapLoaded,
    mapMarkerClick,
    openCity,
    openCityCard,
    pinsValuesLoaded,
    refreshVangaToken,
    reloadGroupInfo,
    removeFromComparison,
    resetTzMinutesOffset,
    saveSettings,
    selectCity,
    setAvailableModule,
    setCityMode,
    setComparisonMode,
    setCurrentGroup,
    setDefaultGroup,
    setErrorMessage,
    setGlobalMeasurement,
    setQualityData,
    setQualityDataMarkers,
    setTypeInterval,
    setTzMinutesOffset,
    setWorldMode,
    showInfo,
    timelineInfoLoad,
    timelineInfoLoaded,
    updateCitiesChart,
    updateComparedStations,
    updateCurrentTimeWithNearestData,
    updateGroupInfo,
    updatePostData,
    updateTimeRangeData,
    vangaTokenUpdated,
    vangaTokenUpdateError,
} from './actions';
import {
    AppState,
    getCurrentGroup,
    getIntervalUpdateData,
    getQualityDataParams,
    isCompareMode,
    selectCities,
    selectGroupId,
    selectGroupInfo,
    selectGroupList,
    selectIsCityMode,
    selectMarkers,
    selectMeasureScheme,
    selectMos,
    selectQualityDataMode,
    selectTime,
    selectTimeRange,
    selectTimeRangeWithoutDistinct,
    selectTypeInterval,
    selectTzMinutesOffset,
} from './selectors';
import { GroupExtConfigName } from '../services/group-features/group-features.service';
import * as Sentry from '@sentry/browser';

import { VangaAuthService } from '@cityair/modules/core/services/vanga-auth/vanga-auth.service';
import { copyObj } from '@libs/common/utils/utils';
import { StoreSettingsPart } from '@cityair/modules/core/store/reducers';
import { ZONES } from '@libs/common/consts/zone.const';
import { measureZones } from '@libs/common/helpers/measure-zones';
import { savedMeasureScheme } from '@libs/common/utils/local-storage';
import {
    onIsEnabledChart,
    setMainChartChartLoading,
} from '@libs/shared-ui/components/timeline-panel/store/core.actions';
import {
    clearCompareList,
    compareListRemove,
    compareListAddUnique,
    updateComparedItems,
} from './compared-list/compared-list.actions';
import {
    isComparedListLimited,
    selectComparedItems,
} from './compared-list/compared-list.selectors';
import { hideHtmlLoader } from '@cityair/utils/utils';
import { StorageService } from '@cityair/modules/login/services/auth/storage.service';
import { SeverityLevel } from '@sentry/browser';
import { TEXTS } from '@libs/common/texts/texts';
import {
    clearCurrentCity,
    selectCurrentCity,
    setCurrentCity,
} from './current-city/current-city.feature';
import { findNearestTime } from '@cityair/utils/find-nearest-time';
import { selectCityCard, setCityCard } from './citycard/citycard.feature';
import { detectMobile } from '@libs/common/utils/detect-mobile';
import { RoutingService } from '../routing.service';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import MapboxActions from '@cityair/modules/map/components/mapbox/mapboxActions';
import { actionsBeforeCompare } from './helpers/actions-before-compare';
import { getShiftMapCityCard, getShiftMapMobile } from '@cityair/config';
import { loadStationDataFromBackend } from './load-station-data-effect.builder';
import { DataQualityApi } from '@cityair/modules/core/services/api/data-quality-api';
import { HttpErrorResponse } from '@angular/common/http';
import { IQualityDataParams } from '@libs/common/models/dataQuality';
import {
    ANALYTIC_INDORE_DATA,
    AQI_IN_ANALYTICS_GROUP_IDS,
} from '@cityair/modules/analytics/constants';

@Injectable()
export class CommonEffects {
    constructor(
        private actions$: NgRxActions,
        private cityscreenMapApi: CityscreenMapApi,
        private adminPanelApi: AdminPanelApi,
        private dataQualityApi: DataQualityApi,
        private storageService: StorageService,
        private store: Store<AppState>,
        private cityairMapApi: CityscreenMapApi,
        private vangaAuthService: VangaAuthService,
        private routingService: RoutingService,
        private mapActions: MapboxActions
    ) {}

    _startInterval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initMainMap),
            switchMap(() =>
                interval(MINUTE5_MS).pipe(
                    takeUntil(
                        this.actions$.pipe(
                            ofType(destroyMainMap),
                            tap(() => console.log('destroy main map'))
                        )
                    )
                )
            ),
            switchMap(() => this.store.select(getIntervalUpdateData).pipe(take(1))),
            switchMap(({ isAllowUpdate, newTimeRange }) => {
                if (isAllowUpdate)
                    return [
                        intervalUpdate({
                            begin: newTimeRange.begin,
                            end: newTimeRange.end,
                        }),
                        currentTimeUpdate({ time: new Date().getTime() }),
                    ];

                return [doNothing()];
            })
        )
    );

    loadGroupList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(mapLoaded),
            tap(() => hideHtmlLoader()),
            switchMap(() => this.adminPanelApi.getGroupsList()),
            switchMap((data) => [
                groupListLoaded({ groups: data.groups, packetValueTypes: data.packetValueTypes }),
                setDefaultGroup(),
            ])
        )
    );

    setDefaultGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setDefaultGroup),
            withLatestFrom(this.store.select(selectGroupList), this.store.select(getCurrentGroup)),
            switchMap(([actionData, groupList, currentGroup]) => {
                const actions = [];

                Sentry.addBreadcrumb({
                    category: 'selectDefaultGroup',
                    message: `[GroupId = ${currentGroup?.id}][GroupsList = ${groupList.map(
                        (g) => g.name
                    )}]`,
                    level: 'info' as SeverityLevel,
                });

                if (!currentGroup?.id) {
                    if (groupList.length) {
                        const saved = +this.storageService.get(GROUP_ID);
                        const group = groupList?.find((g) => g.id === saved) ?? groupList[0];

                        actions.push(setCurrentGroup({ group }));
                    }
                }

                return [...actions, updateGroupInfo(), updateTimeRangeData()];
            })
        )
    );

    updateGroupInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateGroupInfo),
            withLatestFrom(this.store.select(getCurrentGroup)),
            switchMap(([actionData, currentGroup]) => [
                groupInfoLoad({ groupId: currentGroup?.id }),
            ])
        )
    );

    loadGroupInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(groupInfoLoad),
            switchMap((props) => this.adminPanelApi.getGroupInfo(props.groupId)),
            map((data) => groupInfoLoaded({ groupInfo: data }))
        )
    );

    prepareSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(groupInfoLoaded),
            switchMap((data) => {
                const extConfig = data.groupInfo.extConfig;
                const actions = [];
                const obj: StoreSettingsPart = {};

                // TODO for demo indore(atmos)
                const aqi = extConfig[GroupExtConfigName.defaultAqi];
                if (aqi) {
                    actions.push(setGlobalMeasurement({mmt: aqi}));
                }

                const pinsDataFormat = extConfig[GroupExtConfigName.pinsDataFormat];

                if (pinsDataFormat) {
                    const zones = copyObj(ZONES);

                    zones[pinsDataFormat.measureScheme][pinsDataFormat.type] = {
                        color: pinsDataFormat.colors,
                        zone: pinsDataFormat.zones,
                    };

                    obj.measuresZones = zones;

                    actions.push(setGlobalMeasurement({ mmt: pinsDataFormat.type }));
                }
                // TODO for demo indore(atmos)
                if (
                    data?.groupInfo?.groupId &&
                    AQI_IN_ANALYTICS_GROUP_IDS.indexOf(data.groupInfo.groupId) >= 0
                ) {
                    actions.push(setTypeInterval({ payload: IntervalEnum.hour }));
                }
                // TODO избавиться от measureZones
                if (savedMeasureScheme) {
                    measureZones.setScheme(savedMeasureScheme);
                }

                if (!data.groupInfo.myRole) {
                    actions.push(setErrorMessage({ msg: TEXTS.COMMON.noGroupPermission }));
                }

                return [...actions, saveSettings(obj)];
            })
        )
    );

    loadTimelineInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(timelineInfoLoad),
            withLatestFrom(this.store.select(selectMeasureScheme)),
            switchMap(([props, scheme]) =>
                this.cityscreenMapApi.getCSTimelineInfo(
                    props.timeBegin,
                    props.timeEnd,
                    props.groupId,
                    scheme
                )
            ),
            switchMap((data) => {
                const actions = [];

                if (data && data.pins) {
                    actions.push(pinsValuesLoaded({ data: data.pins }));
                }

                actions.push(timelineInfoLoaded(data));

                return actions;
            })
        )
    );

    checkGroupInfoCollections = createEffect(() =>
        this.actions$.pipe(
            ofType(pinsValuesLoaded),
            withLatestFrom(this.store.select(selectMos)),
            switchMap(([response, mos]) => {
                const actions = [];
                const responseIds = response?.data.posts.map((post) => post.id);
                const isChange = mos.some((obj) => responseIds.indexOf(obj.id) === -1);
                if (isChange) {
                    actions.push(reloadGroupInfo());
                }
                return actions;
            })
        )
    );

    reloadGroupInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(reloadGroupInfo),
            withLatestFrom(this.store.select(selectGroupId)),
            filter(([_, groupId]) => !!groupId),
            map(([_, groupId]) => groupInfoLoad({ groupId }))
        )
    );

    refreshToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(refreshVangaToken),
            switchMap((action) =>
                this.vangaAuthService.getVangaToken().pipe(
                    map((result) => {
                        if (result?.access) {
                            this.vangaAuthService.setAccessToken(result.access);
                            return vangaTokenUpdated();
                        }
                    }),
                    catchError((error) => of(vangaTokenUpdateError()))
                )
            )
        )
    );

    isEnabledChartOff$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onIsEnabledChart),
            filter(({ payload }) => !payload),
            switchMap((_) => [clearCompareList()])
        )
    );

    onRemoveFromComparison = createEffect(() =>
        this.actions$.pipe(
            ofType(removeFromComparison),
            withLatestFrom(
                this.store.select(selectComparedItems),
                this.store.select(selectQualityDataMode)
            ),
            switchMap(([{ uuid }, comparedItems, dataQualityMode]) => {
                const actions = [];

                const newSelected = comparedItems.filter((item) => item.id != uuid);
                if (!newSelected.length) {
                    actions.push(setComparisonMode({ payload: false }));
                    actions.push(onIsEnabledChart({ payload: false }));
                } else if (
                    dataQualityMode &&
                    newSelected.length === 1 &&
                    newSelected[0].type === 'myMo'
                ) {
                    actions.push(loadQualityData({ id: newSelected[0].id }));
                }

                return [...actions, compareListRemove({ id: uuid })];
            })
        )
    );

    updateTimeRange = createEffect(() =>
        this.actions$.pipe(
            ofType(updateTimeRangeData),
            withLatestFrom(this.store),
            filter(([_, store]) => !!store.core.currentGroup?.id),
            switchMap(([action, store]) => [
                updateCurrentTimeWithNearestData(),
                timelineInfoLoad({
                    timeBegin: store.core.time.begin,
                    timeEnd: store.core.time.end,
                    groupId: store.core.currentGroup.id,
                    measureScheme: store.core.settings.currentMeasureScheme,
                } as GetCSTimelineInfoRequest),
            ])
        )
    );

    updateCurrentTimeWithNearestData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateCurrentTimeWithNearestData),
            withLatestFrom(
                this.store.select(selectTime),
                this.store.select(selectTimeRangeWithoutDistinct)
            ),
            switchMap(([actionData, current, { begin, end }]) => [
                currentTimeUpdate({ time: findNearestTime(current, begin, end) }),
            ])
        )
    );

    cityModeOff$ = createEffect(() =>
        this.actions$.pipe(
            ofType(cityModeOff),
            switchMap((action) => [
                clearCurrentCity(),
                resetTzMinutesOffset(),
                setComparisonMode({ payload: false }),
                updateCurrentTimeWithNearestData(),
                setWorldMode(),
            ])
        )
    );

    loadStationData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadStationData),
            withLatestFrom(
                this.store.pipe(selectTimeRange),
                this.store.select(selectTypeInterval),
                this.store.select(getCurrentGroup),
                this.store.select(selectMeasureScheme),
                this.store.select(selectTzMinutesOffset),
                this.store.select(isCompareMode)
            ),
            map(
                ([
                    { stations },
                    time,
                    interval,
                    currentGroup,
                    measureScheme,
                    tzOffset,
                    isCompareMode,
                ]) => ({
                    stations,
                    time,
                    interval,
                    currentGroup,
                    measureScheme,
                    tzOffset,
                    isCompareMode,
                })
            ),
            concatMap((args) =>
                defer(() =>
                    args.isCompareMode
                        ? loadStationDataFromBackend(args, this.cityairMapApi, this.store).pipe(
                              map((result) => ({ result, args }))
                          )
                        : of({ result: null, args })
                )
            ),
            switchMap((prevResult) =>
                prevResult.result
                    ? of(prevResult.result)
                    : loadStationDataFromBackend(prevResult.args, this.cityairMapApi, this.store)
            ),
            concatMap((updatedStations) => {
                const actions = [
                    ...updatedStations.map(({ station, packets }) =>
                        updatePostData({
                            payload: {
                                id: station.id,
                                timeseries: packets,
                            },
                        })
                    ),
                    compareListAddUnique({
                        objects: updatedStations.map(({ station }) => station),
                    }),
                    setMainChartChartLoading({ payload: false }),
                    onIsEnabledChart({ payload: true }),
                ];
                return actions;
            })
        )
    );

    updateComparedStations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateComparedStations),
            withLatestFrom(
                this.store.select(selectComparedItems),
                this.store.select(selectQualityDataMode)
            ),
            switchMap(([_, comparedStations, qualityDataMode]) => {
                const actions = [];

                const stationsForUpdate = comparedStations.filter(
                    (s) => s.type !== MarkerType.city
                );

                if (stationsForUpdate.length) {
                    actions.push(setMainChartChartLoading({ payload: true }));
                    actions.push(loadStationData({ stations: stationsForUpdate }));
                    if (qualityDataMode) {
                        if (stationsForUpdate.length === 1) {
                            actions.push(loadQualityData({ id: stationsForUpdate[0].id }));
                        } else {
                            actions.push(setQualityData({ payload: [] }));
                        }
                    }
                }

                return actions;
            })
        )
    );

    loadMonitoringData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadMonitoringData),
            withLatestFrom(
                this.store.select(isCompareMode),
                this.store.select(selectMarkers),
                this.store.select(selectGroupInfo)
            ),
            switchMap(([{ markerId }, isComparedMode, markers, groupInfo]) => {
                const actions = [];

                let marker = markers.find((el) => el.id === markerId);

                if (!marker) {
                    const mo = groupInfo.monitoringObjects.find((mo) => mo.id === markerId);

                    if (!mo) {
                        return actions;
                    }

                    marker = {
                        type: MarkerType.myMo,
                        id: markerId,
                        geometry: {
                            type: 'Point',
                            coordinates: [+mo.geoLongitude, +mo.geoLatitude],
                        },
                        name: mo.name,
                        tzOffset: mo.tzOffset,
                    };
                }

                if (!isComparedMode) {
                    actions.push(clearCompareList());
                }

                actions.push(
                    setMainChartChartLoading({ payload: true }),
                    loadStationData({ stations: [new StationForMapPage_model(marker)] })
                );

                return actions;
            })
        )
    );

    updateCitiesChart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateCitiesChart),
            withLatestFrom(this.store.select(selectComparedItems), this.store.select(selectCities)),
            switchMap(([_, comparedStations, cities]) => {
                const upatedComparedList = comparedStations
                    .filter((el) => el.type === 'city')
                    .map((comparedStation) => {
                        const city = cities.find((c) => c.locationId === comparedStation.id);

                        if (!city) {
                            return;
                        }

                        return {
                            ...comparedStation,
                            originChartData: city.originChartData,
                        };
                    });

                return [updateComparedItems({ items: upatedComparedList })];
            })
        )
    );

    openCityCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(openCityCard),
            withLatestFrom(
                this.store.select(selectCityCard),
                this.store.select(selectCities),
                this.store.select(selectIsCityMode),
                this.store.select(selectCurrentCity)
            ),
            switchMap(([_, citycard, locations, isCityMode, currentCity]) => {
                const actions = [];

                if (!locations?.length) {
                    return actions;
                }

                if (!citycard.city && isCityMode) {
                    actions.push(setCityCard({ city: currentCity }));
                }

                this.routingService.goToPage(MAIN_PAGES.analytics);

                return actions;
            })
        )
    );

    openCity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(openCity),
            withLatestFrom(this.store.select(selectCityCard), this.store.select(selectCities)),
            switchMap(([{ cityId }, citycard, cities]) => {
                const actions = [];

                if (!cityId) {
                    return actions;
                }

                const city = cities.find((c) => c.id === cityId);

                if (citycard.city?.id !== city?.id) {
                    actions.push(setCityCard({ city }));

                    // не открыта админ панель
                    if (!this.routingService.getActivePage()) {
                        actions.push(openCityCard());
                    }
                }

                return actions;
            })
        )
    );

    selectCity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCity),
            withLatestFrom(this.store.select(selectCities)),
            switchMap(([{ cityId, centringMap }, cities]) => {
                const actions = [];

                const city = cities.find((c) => c.id === cityId);
                actions.push(setCityMode());

                if (city) {
                    actions.push(setCurrentCity({ city }));
                    actions.push(setTzMinutesOffset({ tz: city.tzOffset }));

                    if (centringMap) {
                        this.mapActions.centringMap(city, true);
                    }

                    actions.push(openCity({ cityId }));
                }

                return actions;
            })
        )
    );

    loadCity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCity),
            switchMap(({ cityId, centringMap }) => [
                updateCurrentTimeWithNearestData(),
                selectCity({ cityId, centringMap }),
            ])
        )
    );

    clickFromApToMo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clickFromApToMo),
            withLatestFrom(this.store.select(selectIsCityMode), this.store.select(selectMarkers)),
            switchMap(([{ moObjId }, isCityMode, markers]) => {
                const actions = [];

                const marker = markers.find((el) => el.id === moObjId);

                if (marker) {
                    if (!isCityMode) {
                        actions.push(loadCity({ cityId: marker.cityId, centringMap: true }));
                    }

                    actions.push(mapMarkerClick({ markerId: marker.id }));

                    this.mapActions.centringOnMarker(
                        marker.geometry.coordinates[1],
                        marker.geometry.coordinates[0],
                        true,
                        getShiftMapCityCard()
                    );
                }

                return actions;
            })
        )
    );

    mapMarkerClick$ = createEffect(() =>
        this.actions$.pipe(
            ofType(mapMarkerClick),
            withLatestFrom(
                this.store.select(selectMarkers),
                this.store.select(selectComparedItems),
                this.store.select(isComparedListLimited),
                this.store.select(isCompareMode),
                this.store.select(selectQualityDataMode)
            ),
            switchMap(
                ([
                    { markerId },
                    markers,
                    comparedItems,
                    isLimitd,
                    isComparedMode,
                    qualityDataMode,
                ]) => {
                    const actions = [];

                    const marker = markers.find((el) => el.id === markerId);
                    const compareActions = actionsBeforeCompare(
                        marker,
                        comparedItems,
                        isLimitd,
                        isComparedMode
                    );
                    if (compareActions.delete) {
                        actions.push(removeFromComparison({ uuid: markerId }));
                    }

                    if (compareActions.clearBefore) {
                        actions.push(clearCompareList());
                    }

                    if (!compareActions.add) {
                        return actions;
                    }

                    if (marker.type === 'myMo') {
                        if (qualityDataMode) {
                            if (!isComparedMode) {
                                actions.push(loadQualityData({ id: marker.id }));
                            } else if (comparedItems.length === 0) {
                                actions.push(loadQualityData({ id: marker.id }));
                            } else {
                                actions.push(setQualityData({ payload: [] }));
                            }
                        }
                        actions.push(loadMonitoringData({ markerId: marker.id }));
                    } else {
                        actions.push(
                            loadStationData({ stations: [new StationForMapPage_model(marker)] })
                        );
                    }

                    if (detectMobile()) {
                        this.mapActions.centringOnMarker(
                            marker.geometry.coordinates[1],
                            marker.geometry.coordinates[0],
                            true,
                            getShiftMapMobile()
                        );
                    }

                    return actions;
                }
            )
        )
    );

    clickOnCityMarker$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clickOnCityMarker),
            withLatestFrom(
                this.store.select(selectCities),
                this.store.select(selectComparedItems),
                this.store.select(isComparedListLimited),
                this.store.select(isCompareMode)
            ),
            switchMap(([{ cityMarker }, cities, comparedItems, isLimitd, isComparedMode]) => {
                const actions = [];

                const city = cities.find((c) => c.id === cityMarker.cityId);

                if (!city) {
                    return actions;
                }

                const station = new StationForMapPage_model(cityMarker, city.originChartData);

                const compareActions = actionsBeforeCompare(
                    station,
                    comparedItems,
                    isLimitd,
                    isComparedMode
                );

                if (compareActions.delete) {
                    actions.push(removeFromComparison({ uuid: cityMarker.id }));
                    return actions;
                }

                if (compareActions.clearBefore) {
                    actions.push(clearCompareList());
                }

                if (compareActions.add) {
                    actions.push(compareListAddUnique({ objects: [station] }));
                    actions.push(onIsEnabledChart({ payload: true }));
                }

                if (compareActions.clearBefore && compareActions.add) {
                    actions.push(openCity({ cityId: cityMarker.cityId }));
                }

                if (compareActions.add && detectMobile()) {
                    this.mapActions.centringOnMarker(
                        cityMarker.geometry.coordinates[1],
                        cityMarker.geometry.coordinates[0],
                        true,
                        getShiftMapMobile()
                    );
                }

                actions.push(updateCitiesChart());

                return actions;
            })
        )
    );

    setAvailableModule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(timelineInfoLoaded),
            take(1),
            switchMap((response) =>
                this.store.select(selectGroupInfo).pipe(
                    filter((v) => !!v),
                    take(1),
                    map((data) => setAvailableModule({ cities: response.cities, groupInfo: data }))
                )
            )
        )
    );

    getQualityDataMarkers = createEffect(() =>
        this.actions$.pipe(
            ofType(changeQualityDataMode),
            switchMap((response) =>
                this.dataQualityApi.getQualityDataMarkers().pipe(
                    switchMap((response) => [setQualityDataMarkers({ payload: response })]),
                    catchError((error: HttpErrorResponse) => {
                        console.log('get markers error', error);
                        return of(doNothing());
                    })
                )
            )
        )
    );

    loadQualityDataByPost = createEffect(() =>
        this.actions$.pipe(
            ofType(loadQualityData),
            withLatestFrom(this.store.select(getQualityDataParams)),
            filter(([action, params]) => action?.id > 0 && params !== null),
            tap(() => this.store.dispatch(setQualityData({ payload: [] }))),
            switchMap(([action, params]) =>
                this.dataQualityApi
                    .getQualityDataTimeline(
                        Object.assign(
                            { post_id: action.id.toString() },
                            params
                        ) as IQualityDataParams
                    )
                    .pipe(
                        switchMap((response) => [setQualityData({ payload: response })]),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                setQualityData({ payload: [] }),
                                showInfo({
                                    messageKey: 'errorQualityDataTimeline',
                                    positionX: 'left',
                                    positionY: 'bottom',
                                    iconClass: 'error',
                                    duration: 10000,
                                    showCloseIcon: true,
                                    size: 'lg',
                                })
                            )
                        )
                    )
            )
        )
    );
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MAX_PCF, MIN_PCF } from '@cityair/config';
import { AdminPanelApi } from '@cityair/libs/common/api/adminPanel/api';
import { groupInfoLoad } from '@cityair/modules/core/store/actions';
import { selectGroupId } from '@cityair/modules/core/store/selectors';

@Injectable()
export class RegionalCoefficientService {
    private currentGroupId: number;
    constructor(private store: Store, private adminPanelApi: AdminPanelApi) {
        this.store.select(selectGroupId).subscribe((groupId) => (this.currentGroupId = groupId));
    }

    setCoefficient = (val: number) => {
        if (val < MIN_PCF || val > MAX_PCF) {
            return;
        }

        this.adminPanelApi
            .setRegionCoefsItem(val)
            .then(async () => {
                this.store.dispatch(groupInfoLoad({ groupId: this.currentGroupId }));
            })
            .catch((error) => {});
    };
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { Store } from '@ngrx/store';
import { ComfortConditionSettings } from '@cityair/modules/core/services/group-features/group-features.service';

@Component({
    selector: 'analityc-header',
    templateUrl: './analityc-header.component.html',
    styleUrls: ['./analityc-header.component.less'],
})
export class AnalitycHeaderComponent implements OnChanges {
    @Input() config: ComfortConditionSettings;
    public mainPages = MAIN_PAGES;

    headerClass = 'hidden';
    constructor(public store: Store) {}

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.config?.currentValue?.logo?.className ||
            changes.config?.currentValue?.showGlobalLink
        ) {
            this.headerClass = 'show';
        }
    }
}

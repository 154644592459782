import { createAction, props } from '@ngrx/store';

export enum NotificationsActions {
    UpdateNotifiablePosts = '[Notifications] Update Notifiable Posts',
    RemoveNotifiablePosts = '[Notifications] Remove Notifiable Post',
    AddNotifiablePosts = '[Notifications] Add Notifiable Post',
}

export const updateNotifiablePosts = createAction(
    NotificationsActions.UpdateNotifiablePosts,
    props<{ postIds: number[] }>()
);

export const removeNotifiablePost = createAction(
    NotificationsActions.RemoveNotifiablePosts,
    props<{ postId: number }>()
);

export const addNotifiablePost = createAction(
    NotificationsActions.AddNotifiablePosts,
    props<{ postId: number }>()
);

import { Component, Input, OnDestroy } from '@angular/core';
import {
    City_model,
    Marker_model,
    MonitoringObject,
    StationForMapPage_model,
} from '@cityair/namespace';
import { TEXTS } from '@libs/common/texts/texts';
import { SortingColumn } from '@libs/common/models/sorting-column';
import { declOfNum, getColorFromZone } from '@cityair/utils/utils';

import {
    canClickOnMarker,
    getMarkerState,
    selectLocationValue,
    selectMos,
    selectSchemeAndMeasure,
    selectZone,
} from '@cityair/modules/core/store/selectors';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { getComparedListObject } from '@cityair/modules/core/store/compared-list/compared-list.selectors';

@Component({
    selector: 'station-city-list',
    templateUrl: 'station-city-list.component.html',
    styleUrls: ['station-city-list.component.less'],
})
export class StationCityListComponent implements OnDestroy {
    @Input() compareMod: boolean;
    @Input() comparedStations: Readonly<StationForMapPage_model[]>;
    @Input() markersData: Marker_model[];
    @Input() cities?: City_model[];
    @Input() cbClickForName: (marker: Marker_model) => void;
    @Input() monitoringObjects?: MonitoringObject[];
    @Input() filter?: string;
    @Input() sortListStation: SortingColumn[];
    @Input() showCity?: boolean;

    sortingStation;
    sortingDirection = 1;

    getColorFromZone = getColorFromZone;

    markerState = (id: number) => this.store.select(getMarkerState(id));

    selectZone = selectZone;

    selectLocationValue = selectLocationValue;
    public unit: string;
    public ngDestroyed$ = new Subject<void>();

    constructor(public store: Store) {
        store
            .select(selectSchemeAndMeasure)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.unit = TEXTS.MEASURES_SCHEME[data.scheme][data.mmt];
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    searchFilters = [
        (marker: Marker_model) => marker.name,
        (marker: Marker_model) => marker.cityId,
        (marker: Marker_model) => marker.city?.name,
    ];

    TEXTS = TEXTS;

    declOfNum = (val: number) => declOfNum(val, TEXTS.AQI_DECL);

    sortingOfStation = (sortItem) => {
        if (this.sortingStation === sortItem) this.sortingDirection *= -1;
        else this.sortingStation = sortItem;
    };

    getText(marker: Marker_model): string {
        let text = '';
        this.store
            .select(selectMos)
            .pipe(filter((mos) => !!mos.length))
            .subscribe((mos) => {
                if (this.cities) {
                    const city = this.cities.find((city) => city.id === marker.cityId);
                    if (city) {
                        text = city.countryName;
                    } else {
                        text = '';
                    }
                } else {
                    const mo = mos.find((m) => m.id == marker.id);
                    mo.devicesObj.forEach((d) => {
                        if (d.sourceId == 3) {
                            text = TEXTS.STATION_BLOCK.type[marker.type];
                        } else {
                            text = '';
                        }
                    });
                }
            });
        return text;
    }

    isSelected(id: number): Observable<boolean> {
        return this.store.select(getComparedListObject(id)).pipe(map((obj) => !!obj));
    }

    clickOnMarker(marker) {
        this.store
            .select(canClickOnMarker(marker.id))
            .pipe(first())
            .subscribe((canClickOnMarker) => {
                if (canClickOnMarker) {
                    this.cbClickForName(marker);
                }
            });
    }
}

<div class="reports-wrapper">
    <div class="header">
        <div class="title-row">
            <div class="title">{{ translateText.mainTitle }}</div>
            <div class="tabs-wrapper" *ngIf="false">
                <ca-page-tabs
                    class="page__page-tabs"
                    [tabs]="tabs"
                    [selectedTab]="getActiveTab()"
                    (showTab)="openPage($event)"
                    [isFullHeight]="true"
                ></ca-page-tabs>
            </div>
            <div class="top-elements">
                <div class="notify-wrapper">
                    <div class="notify"></div>
                </div>
                <div class="logout-wrapper" *ngIf="userData" (clickOutside)="showLogout = false">
                    <div class="user-profile-icon" (click)="showLogout = !showLogout">
                        <div class="icon-text">
                            {{ userData.shortName }}
                        </div>
                    </div>
                    <div class="user-data-wrapper" *ngIf="showLogout">
                        <div class="user-profile-data">
                            <div class="login">{{ userData.login }}</div>
                            <div class="email">{{ userData.email }}</div>
                            <div class="role">{{ userData.roleName }}</div>
                            <div class="logout-button" (click)="authService.logOut()">
                                {{ exitText }}
                            </div>
                        </div>
                        <div class="user-profile-icon" (click)="showLogout = !showLogout">
                            <div class="icon-text">
                                {{ userData.shortName }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="empty-row"></div>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
</div>

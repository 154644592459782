import { Component, Input } from '@angular/core';

@Component({
    selector: 'ca-substances-list',
    templateUrl: 'substances-list.component.html',
    styleUrls: ['substances-list.component.less'],
})
export class SubstancesListComponent {
    @Input() items: string[];
}

<div class="browser_tokens scroll">
    <div *ngFor="let token of tokensService.getToken()?.browserTokens" class="browser_tokens__item">
        <div class="browser_tokens__part">
            <div class="browser_tokens__name">{{text.browserTokenItems[0]}}</div>
            <div class="browser_tokens__value">{{token.lastDate}}</div>
        </div>
        <div class="browser_tokens__hr"></div>
        <div class="browser_tokens__part">
            <div class="browser_tokens__name">{{text.browserTokenItems[1]}}</div>
            <div class="browser_tokens__value">{{token.createDate}}</div>
        </div>
        <div class="browser_tokens__hr"></div>
        <div class="browser_tokens__part">
            <div class="browser_tokens__name">{{text.browserTokenItems[2]}}</div>
            <div class="browser_tokens__value">{{token.execCount}}</div>
        </div>

        <div
            class="browser_tokens__close"
            *ngIf="!token.isActive"
            caTooltip
            caTooltipPlacement="left"
            [caTooltipTemplate]="closeTooltip"
            (click)="closeToken(token.tokenId)"
        ></div>
        <ng-template #closeTooltip>
            <ca-tooltip [text]="text.closeToken"></ca-tooltip>
        </ng-template>

        <div
            class="browser_tokens__active"
            *ngIf="token.isActive"
            caTooltip
            caTooltipPlacement="left"
            [caTooltipTemplate]="activeTooltip"
        ></div>
        <ng-template #activeTooltip>
            <ca-tooltip [text]="text.activeToken"></ca-tooltip>
        </ng-template>
    </div>
</div>

<div class="settings_footer">
    <div
        [ngClass]="{
            'action_button action_button__outline': true,
            'action_button__outline-disabled': nothingClose()
        }"
        (click)="closeAllTokens()"
    >
        {{text.closeAll}}
    </div>
</div>

import { RegionCoefsItem, ErrorResult } from '@cityair/modules/login/services/harvester-api/models';
import * as Models from '@cityair/modules/login/services/harvester-api/adminApiModels';
import { DataProvider3 } from '@cityair/libs/common/api/dataProvider/DataProvider';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AdminApiProvider {
    cpController = 'AdminApi2';

    constructor(private readonly dataProvider: DataProvider3) {}

    getGroupsList = (
        request: Models.AdminApiRequest,
        execOk: (response: Models.GroupsListResponse) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(this.cpController, 'GetGroupsList', request, execOk, execFail);
    };
    getGroupInfo = (
        request: Models.GroupRequest,
        execOk: (response: Models.GroupInfoResponse) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(this.cpController, 'GetGroupInfo', request, execOk, execFail);
    };

    editGroup = (
        request: Models.GroupRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(this.cpController, 'EditGroup', request, execOk, execFail);
    };

    changeGroupNotificationSetting = (
        request: Models.GroupNotificationChangeRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(
            this.cpController,
            'ChangeGroupNotificationSetting',
            request,
            execOk,
            execFail
        );
    };

    editGroupUserMoPermissions = (
        request: Models.GroupRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(
            this.cpController,
            'EditGroupUserMoPermissions',
            request,
            execOk,
            execFail
        );
    };

    addNewMemberToGroup = (
        request: Models.GroupRoleMemberRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(
            this.cpController,
            'AddNewMemberToGroup',
            request,
            execOk,
            execFail
        );
    };
    editMemberRoleInGroup = (
        request: Models.GroupRoleMemberRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(
            this.cpController,
            'EditMemberRoleInGroup',
            request,
            execOk,
            execFail
        );
    };
    removeMemberFromGroup = (
        request: Models.GroupRoleMemberRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(
            this.cpController,
            'RemoveMemberFromGroup',
            request,
            execOk,
            execFail
        );
    };

    getUserPermission = (
        request: Models.UserItemRequest,
        execOk: (response: Models.UserPermissionResponse) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(
            this.cpController,
            'GetUserPermission',
            request,
            execOk,
            execFail
        );
    };
    upsertUserToken = (
        request: Models.UserTokenRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(this.cpController, 'UpsertUserToken', request, execOk, execFail);
    };
    deleteUserToken = (
        request: Models.UserTokenRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(this.cpController, 'DeleteUserToken', request, execOk, execFail);
    };

    getUserTokenValue = (
        request: Models.UserTokenRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(
            this.cpController,
            'GetUserTokenValue',
            request,
            execOk,
            execFail
        );
    };

    markNotificationFeedViewed = (
        request: Models.UserFeedItemRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttpEx(
            this.cpController,
            'MarkNotificationFeedViewed',
            request,
            false,
            true,
            execOk,
            execFail
        );
    };
    exportFeedItemsToExcel = (
        request: Models.FeedItemsExportRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttpEx(
            this.cpController,
            'ExportFeedItemsToExcel',
            request,
            false,
            true,
            execOk,
            execFail
        );
    };

    userItemCreate = (
        request: Models.UserItemRequest,
        execOk: (response: Models.CpUserItem) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(this.cpController, 'UserItemCreate', request, execOk, execFail);
    };

    userItemSendWelcome = (
        request: Models.UserItemRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(
            this.cpController,
            'UserItemSendWelcome',
            request,
            execOk,
            execFail
        );
    };

    setRegionCoefsItem = (
        request: Models.SetRegionCoefsItemRequest,
        execOk: (response: RegionCoefsItem) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(
            this.cpController,
            'SetRegionCoefsItem',
            request,
            execOk,
            execFail
        );
    };

    getUserNotificationFeeds = (
        request: Models.UserFeedItemRequest,
        execOk: (response: Models.UserFeedResponse) => void,
        execFail: (errRes: ErrorResult) => void = null
    ) => {
        this.dataProvider.execHttp(
            this.cpController,
            'GetUserNotificationFeeds',
            request,
            execOk,
            execFail
        );
    };
}

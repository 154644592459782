<cityscreen-mobile-profile></cityscreen-mobile-profile>
<calendar-m (updateDateRange)="updateDateRange($event)"></calendar-m>

<div class="analytic-button-wrapper">
    <div class="analytic-button">
        <div class="analytic-button__icon" (click)="toggleAnalyticPage()"></div>
    </div>
</div>
<ng-container *ngIf="showAnalytic">
    <div class="analytic-wrapper">
        <cross-button class="close-btn" (click)="toggleAnalyticPage()"></cross-button>
        <analytics-page></analytics-page>
    </div>
</ng-container>

export enum AqiType {
    indoor = 'indoorAqi',
    cityair = 'cityair',
    instant = 'instant',
    epa = 'epa',
    CAPI = 'capi',
    R = 'aqi_r',
    EU = 'aqi_eu',
    aqiIn = 'aqi_in',
    aqiPm = 'aqi_pm',
}

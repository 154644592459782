import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MEASURES_FOR_FORECAST } from '@cityair/config';
import { City_model } from '@cityair/namespace';
import { isRU, TEXTS } from '@libs/common/texts/texts';

import { LayerButtonIconType } from '@cityair/libs/shared/modules/overlay/components/layer-button/layer-button.component';
import { ForecastControlService } from '@cityair/modules/map/services/forecast-control.service';

import {
    getCityById,
    selectGlobalMeasurement,
    selectGroupInfo,
    selectIsCityMode,
    selectLocationValue,
    selectTime,
    selectTzMinutesOffset,
} from '@cityair/modules/core/store/selectors';
import { intervalUpdate, openInstantAqiFaq } from '@cityair/modules/core/store/actions';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import { PM10, TEMP, PRES, HUM } from '@libs/common/consts/substance.consts';
import { AqiType } from '@libs/common/enums/aqi.type';
import { AuthService } from '@cityair/modules/login/services/auth/auth.service';
import { selectCurrentLocationId } from '@cityair/modules/core/store/current-city/current-city.feature';
import { getModulePageConfig } from '@cityair/modules/core/store/module-page-config/module-page-config.feature';
import {
    disableModelling,
    enableModelling,
} from '@cityair/libs/shared/utils/modeling-helper.functions';

@Component({
    selector: 'cs-top-right-elements',
    templateUrl: 'top-right-elements.component.html',
    styleUrls: ['top-right-elements.component.less'],
    animations: ANIMATION_OPACITY,
})
export class CsTopRightElements implements OnDestroy {
    TEXTS = TEXTS;
    isRU = isRU;
    AqiType = AqiType;

    isCityMode$ = this.store.select(selectIsCityMode);
    tzOffset$ = this.store.select(selectTzMinutesOffset);
    currentLocationId$ = this.store.select(selectCurrentLocationId);
    moduleConfig$ = this.store.select(getModulePageConfig);
    groupInfo$ = this.store.select(selectGroupInfo);

    LayerButtonIconType = LayerButtonIconType;
    currentMeasure = PM10;
    MEASURES_FOR_FORECAST = MEASURES_FOR_FORECAST;

    showAqiLegend = false;
    isActiveModelling = false;

    selectTime = selectTime;
    selectLocationValue = selectLocationValue;
    selectGlobalMeasurement = selectGlobalMeasurement;

    constructor(
        readonly forecastControlService: ForecastControlService,
        readonly store: Store,
        readonly authService: AuthService
    ) {}

    getCityById(id: string): Observable<City_model> {
        return this.store.select(getCityById(id));
    }

    ngOnDestroy() {
        this.forecastControlService.disable();
    }

    updateDateRange({ begin, end }) {
        this.store.dispatch(intervalUpdate({ begin, end }));
    }

    enableModelling() {
        this.isActiveModelling = true;
        enableModelling(this.forecastControlService, this.store);
    }

    disableModelling() {
        this.isActiveModelling = false;
        disableModelling(this.forecastControlService, this.store);
    }

    openDetailsLink() {
        this.store.dispatch(openInstantAqiFaq());
    }

    canShowAqiIndicator = (mmt, isCityMode: boolean) =>
        isCityMode && Object.values(AqiType).includes(mmt);
}

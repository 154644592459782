import { COMPARE_LIMIT } from '@cityair/libs/shared/utils/other-utils';
import { MarkerType } from '@cityair/namespace';
import { createSelector } from '@ngrx/store';
import { ICoreState } from '../reducers';
import { AppState } from '../selectors';

export const coreSelector = (state: AppState) => state.core;

export const selectComparedItems = createSelector(
    coreSelector,
    (state: ICoreState) => state?.comparedItems
);

export const comparedItemsIds = createSelector(coreSelector, (state: ICoreState) =>
    state?.comparedItems.map((el) => el.id)
);

export const selectCanShowIntervalButtons = createSelector(
    coreSelector,
    (state: ICoreState) => !state?.comparedItems.find((item) => item.type !== MarkerType.myMo)
);

export const getComparedListObjects = createSelector(
    coreSelector,
    (state: ICoreState) =>
        !state?.comparedItems.map((c) => ({
            id: c.id,
            type: c.type,
        }))
);

export const isComparedListEmpty = createSelector(
    coreSelector,
    (state: ICoreState) => !state.comparedItems.length
);

export const isComparedListNotEmpty = createSelector(
    coreSelector,
    (state: ICoreState) => !!state.comparedItems.length
);

export const isComparedListLimited = createSelector(
    coreSelector,
    (state: ICoreState) => state.comparedItems.length >= COMPARE_LIMIT
);

export const getComparedListObject = (id: number) =>
    createSelector(coreSelector, (state: ICoreState) =>
        state.comparedItems.find((s) => s.id === id)
    );

export const findIndexInComparedList = (id: number) =>
    createSelector(coreSelector, (state: ICoreState) =>
        state.comparedItems.findIndex((s) => s.id === id)
    );

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { TEXTS } from '@libs/common/texts/texts';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { ControlPointForecast, FORECAST_PAGES } from '../../models';
import { ControlPointService } from '../../services/control-point.service';
import {
    currentForecastMmt,
    selectActivePoint,
    selectControlPointsForecast,
    selectCurrentIndexForecast,
    selectErrorLoadList,
} from '../../store/selectors';
import { setActivePoint } from '../../store/actions';
import { selectMeasureScheme } from '@cityair/modules/core/store/selectors';
import { getDigitsAfterDot } from '@cityair/config';

@Component({
    selector: 'cityscreen-control-points',
    templateUrl: './control-points.component.html',
    styleUrls: ['./control-points.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: ANIMATION_OPACITY,
})
export class ControlPointsComponent implements OnInit, OnDestroy {
    public currentPoint: ControlPointForecast;
    public loaded$: Observable<boolean>;
    public loading$: Observable<boolean>;
    public controlPoints: ControlPointForecast[];
    public translateText = TEXTS.FORECAST;
    public textPopUp = TEXTS.POPUP_DELETE;
    public currentMmt: string;
    public sortDirection: number;
    public sortField: string;
    public mmtNames = TEXTS.NAMES;
    public unitsMmt = TEXTS.MEASURES_SCHEME;
    private popupOpenerElement: HTMLElement;
    public isShowMenu = false;
    public isShowDeletePopup = false;
    public menuPositionTop = 0;
    public ngDestroyed$ = new Subject<void>();
    public timeIndex = 0;
    public activePoint: ControlPointForecast;
    public sortingPoint;
    public errorLoadlist: any;
    selectMeasureScheme = selectMeasureScheme;
    getDigitsAfterDot = getDigitsAfterDot;
    numberAfterDot = 1;

    @ViewChild('cardMenu', { static: true }) cardMenu: TemplateRef<HTMLDivElement>;
    constructor(
        private router: Router,
        private controlPointService: ControlPointService,
        public store: Store,
        public popupProvider: OffPanelPopupService,
        _changeDetectorRef: ChangeDetectorRef
    ) {
        this.loading$ = controlPointService.loading$;
        this.loaded$ = controlPointService.loaded$;
        this.store
            .select(selectControlPointsForecast)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.controlPoints = data;
                _changeDetectorRef.markForCheck();
            });
        this.store
            .select(currentForecastMmt)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.currentMmt = data;
                _changeDetectorRef.markForCheck();
            });
        this.store
            .select(selectActivePoint)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.activePoint = data;
                _changeDetectorRef.markForCheck();
            });
        this.store
            .select(selectErrorLoadList)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.errorLoadlist = data;
                _changeDetectorRef.markForCheck();
            });
        this.store
            .select(selectCurrentIndexForecast)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.timeIndex = data;
                _changeDetectorRef.markForCheck();
            });
    }

    ngOnInit(): void {
        this.sortDirection = -1;
        this.onSortingPoint(this.sortingPointValue, 'value');
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
    }

    public addControlPoints() {
        this.router.navigate([
            `/${FORECAST_PAGES.forecast}/${FORECAST_PAGES.points}/${FORECAST_PAGES.create}`,
        ]);
    }

    public sortingPointName = (item: ControlPointForecast) => item.name;

    public sortingPointValue = (item: ControlPointForecast) => {
        if (item.timeseries?.values[this.currentMmt]) {
            return item.timeseries?.values[this.currentMmt][this.timeIndex];
        }

        return null;
    };

    public setSortingCb(sortCb: string): void {
        if (this.sortField === sortCb) {
            this.sortDirection *= -1;
        } else {
            this.sortField = sortCb;
        }
    }

    public onSortingPoint = (sortItem, sortCol: 'value' | 'name') => {
        if (this.sortingPoint === sortItem) {
            this.setSortingCb(sortCol);
        } else {
            this.sortingPoint = sortItem;
            this.setSortingCb(sortCol);
        }
    };

    public isActive(id: number): boolean {
        return false;
    }

    public setActive(point: ControlPointForecast) {
        if (this.activePoint && this.activePoint.id === point.id) {
            return;
        }
        this.store.dispatch(setActivePoint({ payload: point }));
    }

    clickMenu({ target, positionTop }, current: ControlPointForecast) {
        this.currentPoint = current;
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.cardMenu, () => (this.isShowMenu = false));
        this.popupOpenerElement = target;
        const fixedHeight =
            window.innerHeight < positionTop + 93 ? positionTop + 93 - window.innerHeight : 0;
        this.menuPositionTop = positionTop - fixedHeight;
        this.isShowMenu = true;
    }

    closeMenu(e: Event) {
        if (this.isShowMenu && this.popupOpenerElement !== e.target) {
            this.isShowMenu = false;
        }
    }

    closePopup(e: any) {
        if (e.target.id !== 'menuBtn') {
            this.closeMenu(e);
        }
    }

    showPopup(target, type) {
        this.closePopup(target);
        this.isShowDeletePopup = true;
    }

    deleteAccept() {
        this.isShowDeletePopup = false;
        this.controlPointService.delete(this.currentPoint.id);
    }

    deleteCancel() {
        this.isShowDeletePopup = false;
    }

    editPoint(e: Event, id: number) {
        this.closePopup(e);
        this.router.navigate([
            `/${FORECAST_PAGES.forecast}/${FORECAST_PAGES.points}/${FORECAST_PAGES.edit}`,
            id,
        ]);
    }

    getValue(point: ControlPointForecast) {
        const data = point.timeseries?.values?.[this.currentMmt] || null;
        const index = this.timeIndex;
        return data[index];
    }

    onScroll() {
        this.isShowMenu = false;
    }
}

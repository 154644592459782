import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { networksRoutes } from './posts-and-devices.route';

import { LittleComponentsModule } from '@libs/shared-ui/components/little-components/little-components.module';

import { PostsAndDevicesPageComponent } from './components/posts-and-devices-page/posts-and-devices-page.component';
import { PostsListComponent } from './components/posts-list/posts-list.component';
import { DevicesListComponent } from './components/devices-list/devices-list.component';
import { PostDetailsComponent } from './components/post-details/post-details.component';
import { PostEditComponent } from './components/post-edit/post-edit.component';
import { PostCreateComponent } from './components/post-create/post-create.component';
import { PostReportComponent } from './components/post-report/post-report.component';
import { PostReportTzaComponent } from './components/post-report-tza/post-report-tza.component';
import { DeviceDetailsComponent } from './components/device-details/device-details.component';
import { PostsAndDevicesHeaderComponent } from './components/posts-and-devices-header/posts-and-devices-header.component';
import { DeviceReportComponent } from './components/device-report/device-report.component';
import { DetailsHeaderComponent } from './components/details-header/details-header.component';
import { HeaderOfPanelModule } from '@libs/shared-ui/components/header-of-panel/header-of-panel.module';
import { SharedComponentsModule } from '@cityair/libs/shared/components/shared-components.module';
import { ButtonAddModule } from '@libs/shared-ui/components/button-add/button-add.module';
import { SwitchV2Module } from '../../components/switch-v2/switch-v2.module';
import { MonitoringObjectPlateModule } from '../../components/monitoring-object-plate/monitoring-object-plate.module';
import { DeviceCardModule } from '../../components/device-card/device-card.module';
import { SortListItemModule } from '@cityair/libs/shared/components/sort-list-item/sort-list-item.module';
import { PageTabsModule } from '@libs/shared-ui/components/page-tabs/page-tabs.module';
import { SearchInputModule } from '@libs/shared-ui/components/search-input/search-input.module';
import { SearchInputEmbeddedModule } from '@libs/shared-ui/components/search-input-embedded/search-input-embedded.module';
import { CardActionsModule } from '@cityair/components/card-actions/card-actions.module';
import { ButtonDownloadDataModule } from '@libs/shared-ui/components/button-download-data/button-download-data.module';
import { SearchResultPlaceholderModule } from '@cityair/components/search-result-placeholder/search-result-placeholder.module';
import { NonDataScreenModule } from '@cityair/components/non-data-screen/non-data-screen.module';
import { KebabMenuModule } from '@cityair/libs/shared/components/kebab-menu/kebab-menu.module';
import { InformationColumnModule } from '@cityair/components/information-column/information-column.module';
import { SectionLineModule } from '@cityair/components/section-line/section-line.module';
import { ArrowModule } from '@libs/shared-ui/components/icons/components/arrow/arrow.module';
import { StaticTagsModule } from '@libs/shared-ui/components/static-tags/static-tags.module';
import { InformationStringModule } from '@cityair/components/information-string/information-string.module';
import { LinkOnDocumentModule } from '@cityair/components/link-on-document/link-on-document.module';
import { AlertTagsModule } from '@libs/shared-ui/components/alert-tags/alert-tags.module';
import { InputModule } from '@libs/shared-ui/components/input/input.module';
import { InputForCoordinateModule } from '@cityair/components/input-for-coordinate/input-for-coordinate.module';
import { TextareaModule } from '@cityair/components/textarea/textarea.module';
import { InputDropdownModule } from '@cityair/components/input-dropdown/input-dropdown.module';
import { SelectboxModule } from '@libs/shared-ui/components/selectbox/selectbox.module';
import { SelectboxRadioModule } from '@cityair/components/selectbox-radio/selectbox-radio.module';
import { InputDateRangeModule } from '@cityair/libs/common/calendar/input-date-range/input-date-range.module';
import { GrayBackgroundModule } from '@cityair/components/gray-background/gray-background.module';
import { BtnAcceptModule } from '@libs/shared-ui/components/btn-accept/btn-accept.module';
import { BtnCancelModule } from '@libs/shared-ui/components/btn-cancel/btn-cancel.module';
import { BtnExtraModule } from '@libs/shared-ui/components/btn-extra/btn-extra.module';
import { TagsListModule } from '@libs/shared-ui/components/tags-list/tags-list.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { CheckboxModule } from '@libs/shared-ui/components/checkbox/checkbox.module';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { PopupAskModule } from '@cityair/components/popup-ask/popup-ask.module';
import { BtnAccordionModule } from '@cityair/components/btn-accordion/btn-accordion.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { AqiDetailsTooltipModule } from '@libs/shared-ui/components/aqi-details-tooltip/aqi-details-tooltip.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { ButtonModule } from '@libs/shared-ui/components/button/button.module';
import { ButtonsSaveCancelModule } from '@libs/shared-ui/components/buttons-save-cancel/buttons-save-cancel.module';

@NgModule({
    imports: [
        RouterModule.forRoot(networksRoutes),
        CommonModule,
        FormsModule,
        ScrollingModule,
        ReactiveFormsModule,
        LittleComponentsModule,
        HeaderOfPanelModule,
        SearchInputEmbeddedModule,
        SharedComponentsModule,
        SearchInputModule,
        ButtonAddModule,
        SwitchV2Module,
        MonitoringObjectPlateModule,
        SortListItemModule,
        DeviceCardModule,
        PageTabsModule,
        CardActionsModule,
        DirectivesModule,
        ButtonDownloadDataModule,
        SearchResultPlaceholderModule,
        NonDataScreenModule,
        KebabMenuModule,
        InformationColumnModule,
        SectionLineModule,
        ArrowModule,
        StaticTagsModule,
        InformationStringModule,
        LinkOnDocumentModule,
        AlertTagsModule,
        InputModule,
        TextareaModule,
        InputForCoordinateModule,
        InputDropdownModule,
        SelectboxModule,
        SelectboxRadioModule,
        InputDateRangeModule,
        GrayBackgroundModule,
        BtnAcceptModule,
        BtnCancelModule,
        BtnExtraModule,
        TagsListModule,
        SwitchItemModule,
        CheckboxModule,
        PipesModule,
        PopupAskModule,
        BtnAccordionModule,
        TooltipsModule,
        AqiDetailsTooltipModule,
        ButtonModule,
        ButtonsSaveCancelModule,
    ],
    exports: [
        PostsAndDevicesPageComponent,
        PostsListComponent,
        DevicesListComponent,
        PostDetailsComponent,
        PostEditComponent,
        PostCreateComponent,
        PostReportComponent,
        PostReportTzaComponent,
        DeviceDetailsComponent,
        DeviceReportComponent,
        PostsAndDevicesHeaderComponent,
    ],
    declarations: [
        PostsAndDevicesPageComponent,
        PostsListComponent,
        DevicesListComponent,
        PostDetailsComponent,
        PostEditComponent,
        PostCreateComponent,
        PostReportComponent,
        PostReportTzaComponent,
        DeviceDetailsComponent,
        DeviceReportComponent,
        PostsAndDevicesHeaderComponent,
        DetailsHeaderComponent,
    ],
})
export class PostsAndDevicesModule {}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MessageAPIResponseService {
    message: string;
    type: 'error' | 'success';
    description: string;

    setMsg(options: Partial<MessageAPIResponseService>) {
        Object.assign(this, options);

        if (options.type === 'success') {
            setTimeout(() => this.clearMsg(), 5000);
        }
    }

    clearMsg() {
        delete this.message;
        delete this.type;
        delete this.description;
    }
}

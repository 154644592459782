import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { InfoMessage } from '@cityair/namespace';
import { TEXTS } from '@libs/common/texts/texts';

export const INFO_MESSAGE_KEY_OFFLINE = 'internet_offline';
@Component({
    selector: 'info-message',
    templateUrl: './info-message.component.html',
    styleUrls: ['./info-message.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoMessageComponent implements OnInit {
    @Input() data: InfoMessage;
    @Output() hide = new EventEmitter();

    // key for translate
    private textArray = {
        [INFO_MESSAGE_KEY_OFFLINE]: 'internetOff',
    };
    public errorTextTitle = TEXTS.INFO_MESSAGE.title;
    public message: string;
    constructor() {}

    ngOnInit(): void {
        const key = this.textArray[this.data.messageKey] ?? this.data.messageKey;
        this.message = TEXTS.INFO_MESSAGE[key] ?? this.data.message ?? null;
        const duration = this.data?.duration ?? 2500;
        setTimeout(() => this.close(), duration);
    }

    public close() {
        this.hide.emit();
    }
}

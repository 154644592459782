<ng-template #markerTooltip>
    <ca-info-tooltip [text]="pinTooltipText"></ca-info-tooltip>
</ng-template>

<mgl-map
    class="mapbox-map"
    movingMethod="jumpTo"
    [style.visibility]="showMap ? 'visible' : 'hidden'"
    [style]="style"
    [zoom]="[zoom || mapSettings.zoom]"
    [minZoom]="mapSettings.minzoom"
    [maxZoom]="mapSettings.maxzoom"
    [center]="center || mapSettings.center"
    [maxBounds]="mapSettings.bounds"
    [transformRequest]="authorizeTileRequest"
    [pitchWithRotate]="false"
    [dragRotate]="false"
    [renderWorldCopies]="true"
    [attributionControl]="false"
    (mapLoad)="mapboxLoad($event)"
    (zoomEvt)="onZoom($event.target.getZoom())"
    (moveStart)="moveStart()"
    (moveEnd)="moveEnd()"
    (mapDragEnd)="mapDragEndHandler($event)"
    (mapClick)="clickedMap($event)"
>
    <mgl-control mglNavigation [showZoom]="true" [showCompass]="false"></mgl-control>

    <mgl-control mglAttribution [compact]="true" [customAttribution]="'© CityAir'"></mgl-control>

    <!-- Forecast (public) -->
    <ng-container
        *ngIf="
            isForecastLayerVisible(isCityMode$ | async, groupInfo$ | async) &&
                (forecastControlService.isEnabled$ | async) &&
                domainTilesPlayer.imageUrl$ | async as url
        "
    >
        <ng-container *ngIf="domainTilesPlayer.config as cfg">
            <mgl-image-source
                [id]="cfg.domain.slug"
                [url]="url"
                [coordinates]="cfg.domain.coordinates"
            ></mgl-image-source>
            <mgl-layer
                type="raster"
                [id]="cfg.domain.slug"
                [source]="cfg.domain.slug"
                [minzoom]="cfg.minzoom"
                [maxzoom]="cfg.maxzoom"
                [paint]="{
                    'raster-opacity': cfg.opacity,
                    'raster-fade-duration': 0
                }"
            ></mgl-layer>
            <domain-border *ngIf="url" [coordinates]="cfg.domain.coordinates"></domain-border>
        </ng-container>
    </ng-container>

    <!-- Custom layers -->
    <ng-container *ngFor="let layer of enabledExtraLayers">
        <mgl-raster-source
            [id]="layer.id"
            [tiles]="layer.source.tiles"
            [tileSize]="layer.source.tileSize"
            [minzoom]="mapSettings.minzoom"
            [maxzoom]="mapSettings.maxzoom"
        ></mgl-raster-source>
        <mgl-layer [id]="layer.id" [type]="layer.source.type" [source]="layer.id"></mgl-layer>
    </ng-container>

    <ng-container *ngFor="let layer of tilePlayers.default?.layersWindow?.window; index as i">
        <ng-container *ngIf="isGreaterThanMinZoom(layer.source.minzoom)">
            <mgl-raster-source
                [id]="layer.id"
                [tiles]="layer.source.tiles"
                [tileSize]="layer.source.tileSize"
                [bounds]="layer.source.bounds"
                [minzoom]="layer.source.minzoom"
                [maxzoom]="layer.source.maxzoom"
            ></mgl-raster-source>
            <mgl-layer
                [id]="layer.id"
                [type]="layer.source.type"
                [source]="layer.id"
                [paint]="{
                    'raster-opacity': layer.opacity,
                    'raster-fade-duration': timeStep
                }"
            ></mgl-layer>

            <domain-border
                *ngIf="i === 0 && (tilePlayers.default.layers | slice : -1)[0] as lastLayer"
                [bbox]="lastLayer?.source.bounds"
            ></domain-border>
        </ng-container>
    </ng-container>

    <!-- Run Sources -->
    <ng-container *ngIf="plumesAvailable | async">
        <mgl-marker
            *ngFor="let source of runSources$ | async"
            [className]="
                [
                    'mapboxgl-marker',
                    'mapboxgl-marker-anchor-center',
                    'map__mapbox-marker--global'
                ].join(' ')
            "
            [lngLat]="[source.centroid?.coordinates[0], source.centroid?.coordinates[1]]"
        >
            <plume-source-marker [name]="source.name"></plume-source-marker>
        </mgl-marker>
    </ng-container>

    <!-- Forecast (private) -->
    <ng-container
        *ngIf="(showForecastLayer$ | async) && domainTilesForecastPlayer?.imageUrl$ | async as url"
    >
        <ng-container *ngIf="domainTilesForecastPlayer.config as cfg">
            <mgl-image-source
                id="forecast-data"
                [url]="url"
                [coordinates]="cfg.domain.coordinates"
            ></mgl-image-source>
            <mgl-layer
                type="raster"
                id="forecast-layer"
                source="forecast-data"
                [paint]="{
                    'raster-opacity': cfg.opacity,
                    'raster-fade-duration': 0
                }"
            ></mgl-layer>
            <domain-border *ngIf="url" [coordinates]="cfg.domain.coordinates"></domain-border>
        </ng-container>
    </ng-container>
    <!-- Plumes -->
    <ng-container
        *ngIf="(showPlumesLayer$ | async) && domainTilesPlumesPlayer?.imageUrl$ | async as url"
    >
        <ng-container *ngIf="domainTilesPlumesPlayer.config as cfg">
            <mgl-image-source
                id="plumes-data"
                [url]="url"
                [coordinates]="cfg.domain.coordinates"
            ></mgl-image-source>
            <mgl-layer
                type="raster"
                id="plumes-layer"
                source="plumes-data"
                [paint]="{
                    'raster-opacity': cfg.opacity,
                    'raster-fade-duration': 0
                }"
            ></mgl-layer>
            <domain-border *ngIf="url" [coordinates]="cfg.domain.coordinates"></domain-border>
        </ng-container>
    </ng-container>

    <!-- NEW  --------------------------------------------------------------------------------------------------------------- -->
    <ng-container
        *ngIf="(showPlumesLayer$ | async) && plumesTilesPlayer?.imageUrl$ | async as url"
    >
        <ng-container *ngIf="plumesTilesPlayer.config as cfg">
            <mgl-image-source
                id="plumes-tiles-data"
                [url]="url"
                [coordinates]="cfg.domain.coordinates"
            ></mgl-image-source>
            <mgl-layer
                type="raster"
                id="plumes-layer-data"
                source="plumes-tiles-data"
                [paint]="{
                    'raster-opacity': cfg.opacity,
                    'raster-fade-duration': 0
                }"
            ></mgl-layer>
            <domain-border *ngIf="url" [coordinates]="cfg.domain.coordinates"></domain-border>
        </ng-container>
    </ng-container>
    <!-- Group layers -->
    <ng-container *ngIf="groupFeaturesLayer">
        <mgl-geojson-source
            id="group-features-json"
            [data]="groupFeaturesLayer"
        ></mgl-geojson-source>
        <mgl-layer
            id="group-features-layer-line"
            type="line"
            source="group-features-json"
            [paint]="{
                'line-color': ['get', 'color'],
                'line-opacity': ['get', 'opacity'],
                'line-width': 2
            }"
        ></mgl-layer>
    </ng-container>

    <!-- Marker area -->
    <ng-container *ngIf="pinsAreaData && showMarkersArea">
        <!-- Maximum buffer size fixes overlapping artifacts at the maximum zoom level (mostly). -->
        <mgl-geojson-source
            id="markers-area-data"
            [data]="pinsAreaData | async"
            [buffer]="512"
        ></mgl-geojson-source>
        <mgl-layer
            id="markers-area"
            type="fill"
            source="markers-area-data"
            [paint]="{
                'fill-color': ['get', 'color'],
                'fill-opacity': ['get', 'opacity'],
                'fill-outline-color': 'transparent'
            }"
        >
        </mgl-layer>
    </ng-container>

    <!-- Regular markers -->
    <ng-container *ngIf="postPins?.getSelectedPinIds | async as selected">
        <mgl-marker
            *ngFor="let pin of postPins?.getPins | async"
            [className]="
                [
                    'mapboxgl-marker',
                    'mapboxgl-marker-anchor-center',
                    'map__mapbox-marker--global',
                    'map__mapbox-marker--global-pin',
                    selected.includes(pin.id) ? 'map__mapbox-marker--global-selected' : ''
                ].join(' ')
            "
            [lngLat]="[pin.geometry?.coordinates[0], pin.geometry?.coordinates[1]]"
        >
            <post-pin
                *ngIf="{ a: postPins.getValue(pin) | async } as val"
                [value]="val.a"
                [numberAfterDot]="(postPins.selectDigitsAfterDot | async) ?? 1"
                [color]="postPins.getColor(pin) | async"
                [selected]="selected.includes(pin.id)"
                [state]="getMarkerState(pin.id) | async"
                (click)="postPins.clickCb(pin)"
                (mouseenter)="pinTooltipText = isTouchDevice ? '' : pin.name"
                caTooltip
                caTooltipPlacement="left"
                [caTooltipFollowPointer]="true"
                [caTooltipTemplate]="markerTooltip"
            ></post-pin>
        </mgl-marker>
    </ng-container>

    <!-- City markers -->
    <ng-container *ngIf="cityPins?.getSelectedPinIds | async as selected">
        <mgl-marker
            *ngFor="let pin of cityPins?.getPins | async"
            [className]="
                [
                    'mapboxgl-marker',
                    'mapboxgl-marker-anchor-center',
                    'map__mapbox-marker--global',
                    'map__mapbox-marker--global-city',
                    selected.includes(pin.id) ? 'map__mapbox-marker--global-selected' : ''
                ].join(' ')
            "
            [lngLat]="[pin.geometry.coordinates[0], pin.geometry.coordinates[1]]"
        >
            <city-pin
                *ngIf="{ a: cityPins.getValue(pin) | async } as val"
                [city]="pin.name"
                [value]="val.a"
                [numberAfterDot]="(cityPins.selectDigitsAfterDot | async) ?? 1"
                [color]="cityPins.getColor(pin) | async"
                [selected]="selected.includes(pin.id)"
                [state]="getMarkerState(pin.id) | async"
                (click)="cityPins.clickCb(pin)"
            ></city-pin>
        </mgl-marker>
    </ng-container>

    <!-- Markers selected for notifications  -->
    <mgl-marker
        *ngFor="let pin of notificationSelectedPins?.getPins | async"
        [className]="
            [
                'mapboxgl-marker',
                'mapboxgl-marker-anchor-center',
                'map__mapbox-marker--global',
                'map__mapbox-marker--global-pin',
                'map__mapbox-marker--global-selected'
            ].join(' ')
        "
        [lngLat]="[pin.geometry.coordinates[0], pin.geometry.coordinates[1]]"
    >
        <post-pin
            *ngIf="{ a: notificationSelectedPins.getValue(pin) | async } as val"
            [value]="val.a"
            [numberAfterDot]="(notificationSelectedPins.selectDigitsAfterDot | async) ?? 1"
            [color]="postPins.getColor(pin) | async"
            [selected]="true"
            [state]="markerState.selectPost"
            (click)="notificationSelectedPins.clickCb(pin)"
            (mouseenter)="pinTooltipText = isTouchDevice ? '' : pin.name"
            caTooltip
            caTooltipPlacement="left"
            [caTooltipFollowPointer]="true"
            [caTooltipTemplate]="markerTooltip"
        ></post-pin>
    </mgl-marker>

    <!-- Control points -->
    <ng-container *ngIf="controlPointPins?.getSelectedPinIds | async as selected">
        <mgl-marker
            *ngFor="let point of controlPointPins?.getPins | async"
            [className]="
                [
                    'mapboxgl-marker',
                    'map__mapbox-marker--global',
                    selected.includes(point.id)
                        ? 'map__mapbox-marker--global-selected_without_top'
                        : ''
                ].join(' ')
            "
            [lngLat]="[point.lon, point.lat]"
            [draggable]="controlPointPins.isDraggable(point) | async"
            (markerDragEnd)="controlPointPins.dragEnd($event, point)"
            [anchor]="'bottom'"
        >
            <control-point-pin
                *ngIf="{ a: controlPointPins.getValue(point) | async } as val"
                [value]="val.a"
                [color]="controlPointPins.getColor(point) | async"
                [numberAfterDot]="(controlPointPins.selectDigitsAfterDot | async) ?? 1"
                [decimalFormat]="false"
                [name]="point?.name"
                [selected]="selected.includes(point.id)"
                [draggable]="controlPointPins.isDraggable(point) | async"
                (click)="controlPointPins.clickCb(point)"
                (mouseenter)="pinTooltipText = isTouchDevice ? '' : point.name"
                caTooltip
                caTooltipPlacement="left"
                [caTooltipHidden]="controlPointPins.isDraggable(point) | async"
                [caTooltipFollowPointer]="true"
                [caTooltipTemplate]="markerTooltip"
            ></control-point-pin>
        </mgl-marker>
    </ng-container>
</mgl-map>

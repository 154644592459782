import { Injectable } from '@angular/core';
import { LngLatBoundsLike, LngLatLike, RasterSource } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';

import {
    DEFAULT_QUALITY_DATA_PERCENT,
    STND_CITY_MAX_ZOOM,
    STND_GLOBAL_MIN_ZOOM,
} from '@cityair/config';
import { PinsDataFormat } from '@cityair/namespace';
import { GroupChartConfig } from '@libs/common/models/group-chart-config.model';
import { PM10 } from '@libs/common/consts/substance.consts';
import { GroupTooltipsMmt } from '@libs/common/types/group-tooltips-mmt';

export const DEFAULT_MAP_STYLE = 'mapbox://styles/mapbox/light-v10';

export const CUSTOM_MAP_STYLE = 'mapbox://styles/cityair/cl521x0og003x14moz3e6g3je';

export const MAP_STYLE = CUSTOM_MAP_STYLE;

const LOCAL_MAP_STYLE = localStorage.getItem('local_map_style');

export enum GroupExtConfigName {
    startModule = 'startModule',
    defaultMo = 'defaultMo',
    mapSettings = 'mapSettings',
    featuresLayer = 'featuresLayer',
    plumeMeasure = 'plumeMeasure',
    chartMinMax = 'chartMinMax',
    chartSingleSelect = 'chartSingleSelect',
    tilePlayerSettings = 'tilePlayerSettings',
    showQRCode = 'showQRCode',
    pinsDataFormat = 'pinsDataFormat',
    // TODO for demo indore(atmos)
    defaultAqi = 'defaultAqi',
    enableTSP = 'enableTSP',
    commonMaxPDK = 'commonMaxPDK',
    disablePlumesRunNavigation = 'disablePlumesRunNavigation', // possibly a temporary setting
    showNewAnalityc = 'showNewAnalityc',
    showForecastModule = 'showForecastModule',
    forecastMapSettings = 'forecastMapSettings',
    forecastConfig = 'forecastConfig',
    indoorConfig = 'indoorConfig',
    ComfortConditionSettings = 'ComfortConditionSettings',
    plumesStationShowFullChartData = 'plumesStationShowFullChartData',
    chartTooltipByMeasurements = 'chartTooltipByMeasurements',
    defaultLang = 'defaultLang',
    dataQualityPercent = 'dataQualityPercent',
}

export interface GroupMapSettings {
    accessToken?: string;
    tiles?: string[];
    tileSize?: number;
    center?: LngLatLike;
    bounds?: LngLatBoundsLike;
    zoom?: number;
    minzoom?: number;
    maxzoom?: number;
    style?: string;
    showMarkersArea?: boolean;
}
export interface ILogoIndoor {
    imgPath?: string;
    className?: string;
}
export interface IFloorPin {
    label: string;
    coordinates?: [number, number];
}
export interface IOutdoorPin {
    label: string;
    color?: string;
    value: number;
    coordinates?: [number, number];
}
export interface IOutdoorPinConfig {
    id: number;
    coordinates?: [number, number];
}
export interface IndoorConfig {
    imgSize: [number, number];
    zoom: number;
    minZoom?: number;
    maxZoom?: number;
    imgBackgroundColor: string;
    pinsCoordinatesInPixels: {
        [pinId: number]: [number, number];
    };
    imgSourcePath?: string;
    floorPin?: IFloorPin;
    maxBounds?: [number, number, number, number];
    outdoorPinsConfig?: IOutdoorPinConfig[];
}

export interface ComfortConditionSettings {
    WorkDays: boolean[];
    WorkHoursBegin: number;
    WorkHoursEnd: number;
    TemperatureMin: number;
    TemperatureMax: number;
    HumidityMin: number;
    HumidityMax: number;
    Co2Max: number;
    logo?: ILogoIndoor;
    showGlobalLink?: boolean;
}

export interface GroupTilePlayerSettings {
    datesRange: string[];
    timeStep: number;
    layerId?: string;
    opacity?: number;
    dateFormat?: string;
    layer: RasterSource;
}

export interface ForecastConfig {
    calendarMinDate: string;
    numberOfFutureDays: number;
    domain: string;
}
export interface FeaturesConfigType {
    [GroupExtConfigName.startModule]?: string; // example: plume
    [GroupExtConfigName.defaultMo]?: number;
    [GroupExtConfigName.mapSettings]?: GroupMapSettings;
    [GroupExtConfigName.chartMinMax]?: GroupChartConfig;
    [GroupExtConfigName.chartSingleSelect]?: boolean;
    [GroupExtConfigName.tilePlayerSettings]?: GroupTilePlayerSettings;
    [GroupExtConfigName.plumeMeasure]?: string;
    [GroupExtConfigName.showQRCode]?: boolean;
    [GroupExtConfigName.pinsDataFormat]?: PinsDataFormat;
    [GroupExtConfigName.disablePlumesRunNavigation]?: boolean;
    [GroupExtConfigName.enableTSP]?: boolean;
    [GroupExtConfigName.commonMaxPDK]?: number;
    [GroupExtConfigName.showNewAnalityc]?: boolean;
    [GroupExtConfigName.showForecastModule]?: boolean;
    [GroupExtConfigName.forecastMapSettings]?: GroupMapSettings;
    [GroupExtConfigName.forecastConfig]?: ForecastConfig;
    [GroupExtConfigName.indoorConfig]?: IndoorConfig;
    [GroupExtConfigName.ComfortConditionSettings]?: ComfortConditionSettings;
    [GroupExtConfigName.plumesStationShowFullChartData]?: boolean;
    [GroupExtConfigName.chartTooltipByMeasurements]?: GroupTooltipsMmt;
    [GroupExtConfigName.defaultLang]?: 'en' | 'ru';
    [GroupExtConfigName.dataQualityPercent]?: number;
}

export const DEFAULT_CONFIG: FeaturesConfigType = {
    startModule: null,
    defaultMo: null,
    plumeMeasure: PM10,
    mapSettings: {
        style: LOCAL_MAP_STYLE || MAP_STYLE,
        center: {
            lat: 57,
            lng: 57,
        },
        bounds: [
            {
                lng: -360,
                lat: -84,
            },
            {
                lng: 360,
                lat: 84,
            },
        ],
        zoom: 2,
        minzoom: STND_GLOBAL_MIN_ZOOM,
        maxzoom: STND_CITY_MAX_ZOOM - 1.1,
        showMarkersArea: false,
    },
    pinsDataFormat: null,
    chartTooltipByMeasurements: {},
    dataQualityPercent: DEFAULT_QUALITY_DATA_PERCENT,
};

// TODO: move to store ??
@Injectable({
    providedIn: 'root',
})
export class GroupFeaturesService {
    private features: FeaturesConfigType = DEFAULT_CONFIG;

    private _readyBehavior$ = new BehaviorSubject<boolean>(false);
    readyBehavior$ = this._readyBehavior$.asObservable();

    setFeatures(features: FeaturesConfigType) {
        try {
            features = JSON.parse(JSON.stringify(features));
        } catch (e) {}

        this.features = {
            ...DEFAULT_CONFIG,
            ...(features || {}),
            mapSettings: {
                ...DEFAULT_CONFIG.mapSettings,
                ...(features?.mapSettings || {}),
            },
        };

        this._readyBehavior$.next(true);
    }

    getConfig(name: GroupExtConfigName) {
        return this.features[name] || DEFAULT_CONFIG[name];
    }

    getGroupChartConfig() {
        return this.getConfig(GroupExtConfigName.chartMinMax) as GroupChartConfig;
    }

    getDefaultMo() {
        const defaultMo = this.getConfig(GroupExtConfigName.defaultMo);
        return defaultMo && Array.isArray(defaultMo) ? defaultMo : [defaultMo];
    }
}

import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, distinctUntilChanged, fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged, filter, first, throttleTime } from 'rxjs/operators';
import * as moment from 'moment';

import { TimelineState } from './store/index';
import { playerLoadingData, playerReady, playerStart, playerStop } from './store/core.actions';
import {
    selectChartEnabled,
    selectMainChartIsLoading,
    selectPlayer,
} from './store/selectors/core.selectors';

import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { DEFAULT_AQI_TYPE } from '@libs/common/consts/default-aqi-type.const';
import { PdkType } from '@libs/common/types/pdk-type';
import { detectMobile } from '@libs/common/utils/detect-mobile';
import { toShortISOString } from '@libs/common/utils/date-formats';
import { GroupChartConfig } from '@libs/common/models/group-chart-config.model';
import { Feature, FlagDirection, TimeTrackView } from './models/core';
import { DAY_OR_HOUR_BOUND, STEP_DURATION } from './constants';
import { TimeRunnerComponent } from './time-runner/time-runner.component';
import { DragAndDrop } from './dnd';
import { AqiDataProviderType } from './types/capi-data-provider.type';
import { GroupTooltipsMmt } from '@libs/common/types/group-tooltips-mmt';
import { DataQualityInfo, DataQualityTimeline } from '@libs/common/models/dataQuality';

const MAX_LABEL_WIDTH = 75; // max width + padding in px

@Component({
    selector: 'timeline-panel',
    templateUrl: './timeline-panel.component.html',
    styleUrls: ['./timeline-panel.component.less'],
})
export class TimelinePanelComponent implements OnInit, OnDestroy, OnChanges {
    @Input() aqiDataProvider: AqiDataProviderType | null = null;
    @Input() isCityMode = false;
    @Input() sidebarIsOpened: boolean;
    @Input() currentTime: number;
    @Input() dates: string[];
    @Input() features: Feature[];
    @Input() dateTime: string;
    @Input() aqiName = DEFAULT_AQI_TYPE;
    @Input() initSelectMeasurement?: string;
    @Input() showPlayButton = true;
    @Input() labelMode = false;
    @Input() getDigitsAfterDot: (id: string) => number = (id) => 0;
    @Input() showNow = false;
    @Input() pdk?: { type: PdkType; pdks: { [key in string]: number } };
    @Input() aqiTooltipTemplate: TemplateRef<any>;
    @Input() comparisonEnabled: boolean;
    @Input() isComparisonModeActive: boolean;
    @Input() mmtInfoIcon?: {
        name: string;
        cb: () => void;
    };
    @Input() chartMinMax: GroupChartConfig;
    @Input() measureScheme: MeasureScheme;
    @Input() groupTooltip: GroupTooltipsMmt;
    @Input() qualityDataMode: number;
    @Input() dataQualityTimeline: DataQualityTimeline[] = [];

    @Output() changeTimeIndex = new EventEmitter<number>();
    @Output() changeTime = new EventEmitter<number>();
    @Output() goToCity = new EventEmitter<string>();
    @Output() removeFromComparison = new EventEmitter<number>();
    @Output() setCompareMode = new EventEmitter<void>();
    @Output() setPlayingState = new EventEmitter<boolean>();
    @Output() showQualityDataInfo = new EventEmitter<DataQualityInfo>();

    // if it will be simple vars will be error Expression has changed after it was checked.
    charSize$ = new BehaviorSubject<{ left: number; width: number }>(null);
    charSizeSub$ = this.charSize$.asObservable().pipe(distinctUntilChanged(), debounceTime(3));

    isMobile = detectMobile();

    public city: Feature;
    private chartEnabled: boolean;
    public dateArray: string[] = [];
    public timeLineDate: TimeTrackView[] = [];
    public timeLineAQI: number[] = [];
    hasDataByIndex: boolean[] = [];
    public pointSeriesData: Feature[];
    playerState$: Observable<{
        playing: boolean;
        loading: boolean;
        loaded: boolean;
        progress: number;
    }>;
    flagDirection: FlagDirection = 'left';
    positionPercent = 0;
    currentTimeStrs: string[] = null;
    timeIndex: number;
    private tm: NodeJS.Timeout;
    subscriptions: Subscription[] = [];
    public currentWidthTimeline: number;

    @ViewChild(TimeRunnerComponent) timelineRunnerComponent: TimeRunnerComponent;
    @ViewChild('timelineRunner', { read: ElementRef }) timelineRunner: ElementRef<HTMLElement>;
    @ViewChild('timelineTrack', { read: ElementRef }) timelineTrack: ElementRef<HTMLElement>;

    constructor(private store: Store<TimelineState>, private el: ElementRef<HTMLElement>) {
        this.playerState$ = this.store.select(selectPlayer);

        const playerStateSub = this.playerState$.subscribe((playerState) => {
            if (playerState.playing && playerState.loaded) {
                this.startPlayingOnReady();
            }
        });

        this.subscriptions.push(playerStateSub);
        const playerStateSubOutput = this.playerState$
            .pipe(distinctUntilKeyChanged('playing'))
            .subscribe((playerState) => {
                this.setPlayingState.emit(playerState.playing);
            });

        this.subscriptions.push(playerStateSubOutput);

        this.setInit();
    }

    public getChartEnabled(): boolean {
        return this.chartEnabled && !!this.pointSeriesData?.length;
    }

    ngOnInit() {
        this.store
            .select(selectPlayer)
            .pipe(
                distinctUntilKeyChanged('loading'),
                filter((player) => player.loading && !player.managed)
            )
            .subscribe(() => {
                this.store.dispatch(playerReady());
            });

        this.updateTimelineTrack();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.sidebarIsOpened) {
            setTimeout(() => this.updateTimelineTrack(), 500);
        }

        if (changes.dateTime && !changes.dateTime.firstChange && this.currentTimeStrs === null) {
            this.currentTimeStrs = this.formatCurrentTime(changes.dateTime.currentValue);
            this.flagDirection = 'right';
        }

        if (changes.currentTime) {
            this.updateTime();
        }

        if (changes.dates || changes.sidebarIsOpened || changes.features) {
            this.store
                .select(selectMainChartIsLoading)
                .pipe(first())
                .subscribe((mainChartLoading) => {
                    if (!mainChartLoading) {
                        this.updateDates();
                        this.updateTime();
                    }
                });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    private updateTimeline() {
        const dates = this.dates || [];
        const data = this.features || [];

        this.pointSeriesData = [...data];
        this.dateArray = this.getDates();
        this.timeLineDate = this.prepareTimelineData(dates);

        if (data.length === 1) {
            const { properties } = data[0];

            if (properties.timeseries.date?.length) {
                this.timeLineDate = this.prepareTimelineData(properties.timeseries.date);
            }

            const aqiSeries = properties.timeseries[this.aqiName];

            if (aqiSeries) {
                this.timeLineAQI =
                    properties.obj !== 'city' ? aqiSeries.map(Math.round) : aqiSeries.map((_) => 0);
            } else {
                this.timeLineAQI = Array(this.timeLineDate.length).fill(0);
            }

            const usableDates = properties.timeseries.date ?? dates;

            const featureHasDataByIndex = data.map((f) => {
                const { timeseries } = f.properties;
                const keys = Object.keys(timeseries).filter((k) => k !== 'date');
                const data = keys.map((k) => timeseries[k]) as number[][];

                return this.indicateData(usableDates.length, data);
            });

            this.hasDataByIndex = this.indicateData(usableDates.length, featureHasDataByIndex).map(
                (v) => !!v
            );
        } else {
            this.clearDataAQI();
        }
    }

    private setInit() {
        const chartEnabledSub = this.store.select(selectChartEnabled).subscribe((data) => {
            this.chartEnabled = data;
            // clear AQI data
            if (!this.chartEnabled) {
                this.clearDataAQI();
            }
        });

        const resizeSub = fromEvent(window, 'resize')
            .pipe(throttleTime(500), debounceTime(500))
            .subscribe(() => {
                this.updateTimelineTrack();
            });

        this.subscriptions.push(chartEnabledSub, resizeSub);
    }

    private getDates() {
        const fetaureDates = this.features?.[0]?.properties.timeseries.date || [];
        return fetaureDates.length ? fetaureDates : this.dates;
    }

    private updateTime() {
        const dates = this.getDates();

        this.timeIndex = dates.length - 1;
        dates.find((d, index) => {
            const time = new Date(d).getTime();
            const timeNext = new Date(dates[index + 1]).getTime();

            if (
                time === this.currentTime ||
                // TODO убрать после синхронизации интервалов постов и getTimelineInfo
                (this.currentTime > time && this.currentTime < timeNext)
            ) {
                this.timeIndex = index;
                return true;
            }
        });

        this.currentTimeStrs = this.formatCurrentTime(dates[this.timeIndex]);
        this.setRunnerPosition(this.timeIndex);
    }

    private updateDates() {
        const dates = this.getDates();

        if (dates?.length) {
            this.updateTimeline();

            this.dateArray = dates;
        }
    }

    private updateTimelineTrack() {
        this.currentWidthTimeline = this.el.nativeElement.getBoundingClientRect().width;
        this.timeLineDate = this.prepareTimelineData(this.dateArray);
    }

    private indicateData(len: number, data: number[][]): number[] {
        const init: number[] = Array(len).fill(null);
        return data.reduce((acc, v) => acc.map((a, i) => (a || v?.[i] !== null ? 1 : null)), init);
    }

    onTrackClick(index: number) {
        this.stopPlaying();
        this.setTime(index);
        this.onRunnerChange(index);
    }

    onRunnerChange(x: number): void {
        if (this.timelineTrack && this.timelineRunnerComponent) {
            const { width } = this.timelineTrack.nativeElement.getBoundingClientRect();
            const flagWidth = this.timelineRunnerComponent.getWidth();
            const currentWidth = (width / this.dateArray.length) * x;
            this.flagDirection = currentWidth + flagWidth > width ? 'left' : 'right';
        }
    }

    public goCity(cityId: string) {
        this.goToCity.emit(cityId);
    }

    dragAndDrop: DragAndDrop;

    dragStart(e: MouseEvent | TouchEvent) {
        this.stopPlaying();
        this.dragAndDrop = new DragAndDrop(this.timelineRunner.nativeElement, (x: number) => {
            const position = this.timelineTrack.nativeElement.getBoundingClientRect();
            const relPos = Math.round(
                (x - position.left) / (position.width / this.dateArray.length)
            );
            if (relPos >= 0 && relPos < this.dateArray.length) {
                this.setTime(relPos);
            }
        });

        const pageX = e instanceof MouseEvent ? e.pageX : e.touches[0].pageX;
        this.dragAndDrop.dragStart(pageX);
    }

    @HostListener('window:mouseup')
    @HostListener('window:touchend')
    dragEnd() {
        if (this.dragAndDrop) {
            this.dragAndDrop = null;
        }
    }

    setRunnerPosition(index: number) {
        const len = this.dateArray.length;

        if (len === 0 && index === 0) {
            this.positionPercent = 0;
            this.flagDirection = 'right';
        } else {
            // align runner to the middle of the time step
            const halfInterval = (100 * 0.5) / len;
            this.positionPercent = (100 * index) / len + halfInterval;
        }

        this.onRunnerChange(index);
    }

    playPause(isPlaying: boolean, isLoading: boolean) {
        if (isPlaying || isLoading) {
            this.stopPlaying();
        } else {
            this.startPlaying();
        }
    }

    public goToEndTime() {
        this.setTime(this.dateArray.length - 1);
    }

    public showGoTOEnd = () => !this.isMobile && this.timeIndex !== this.dateArray.length - 1;

    public setShowQualityDataInfo($event) {
        this.showQualityDataInfo.emit(
            new DataQualityInfo(
                this.dataQualityTimeline[$event].dataMarkers,
                this.features[0],
                $event
            )
        );
    }

    private startPlaying() {
        this.store.dispatch(playerLoadingData());
    }

    private startPlayingOnReady() {
        let index = this.timeIndex;
        const date = this.dateArray;

        if (index === -1 || index === date.length - 1) {
            index = 0;
        }

        if (this.tm) {
            clearInterval(this.tm);
        }

        this.tm = setInterval(() => {
            if (++index > date.length - 1) {
                this.stopPlaying();
            } else {
                this.setTime(index);
            }
        }, STEP_DURATION);

        this.store.dispatch(playerStart());
    }

    private stopPlaying() {
        if (this.tm) {
            clearInterval(this.tm);
        }

        this.store.dispatch(playerStop());
    }

    private setTime(index: number) {
        if (index >= 0) {
            if (index === this.timeIndex) return;

            this.changeTimeIndex.emit(index);
            this.changeTime.emit(new Date(this.dateArray[index]).getTime());
        } else {
            this.stopPlaying();
        }
    }

    private prepareTimelineData(data: string[] = []): TimeTrackView[] {
        const result: TimeTrackView[] = [];
        const numberLabel = Math.round(this.currentWidthTimeline / MAX_LABEL_WIDTH);

        if (data.length && numberLabel) {
            const hours = this.getHourByRange(data[0], data[data.length - 1]);
            const hoursInterval = this.getStepIntervalHours(data[0], data[1]);
            const stepDataInterval = this.getStepIntervalMinutes(data[0], data[1]);

            const items = stepDataInterval === DAY_OR_HOUR_BOUND ? data.length : hours;
            const isShowDayFormat = this.showDayFormat(hoursInterval);

            let currentDisplayIndex = 0;

            const m = moment(data[currentDisplayIndex]).add(1, 'day').startOf('day');
            const nextDay = m.milliseconds(0).toDate();

            // TODO: unify date formats
            let ndIndex = data.findIndex((d) => d === toShortISOString(nextDay));
            if (ndIndex === -1) {
                ndIndex = data.findIndex((d) => d === nextDay.toISOString());
            }

            const stepSize = this.getLabelsStep(items, numberLabel, stepDataInterval);

            if (ndIndex !== -1) {
                currentDisplayIndex = ndIndex % stepSize;
            }

            // TODO: unify date formats again
            const datesHaveMicroseconds = data[0].indexOf('.000') !== -1 ? '.000' : '';
            const nowTimeUTC = this.getNowTimeByTypeInterval(
                stepDataInterval,
                datesHaveMicroseconds
            );
            let indexNow = data.indexOf(nowTimeUTC) === -1 ? null : data.indexOf(nowTimeUTC) + 1;
            if (indexNow && indexNow === data.length) {
                indexNow = indexNow - 1;
            }
            data.forEach((value, index) => {
                const hasLabel = index === currentDisplayIndex;
                const label = hasLabel ? this.getDisplayValue(value, isShowDayFormat) : '';
                const isNow = this.showNow && indexNow === index;
                result.push({ index, value, label, isNow });

                if (hasLabel) {
                    currentDisplayIndex = stepSize + currentDisplayIndex;
                }
            });
        }

        return result;
    }

    private getLabelsStep(itemsCount: number, maxLabelsCount: number, stepSizeMinutes: number) {
        const roundInterval = this.getRoundInterval(Math.ceil(itemsCount / maxLabelsCount));

        const coefficientTime =
            {
                20: 3,
                5: 8,
            }[stepSizeMinutes] || 1;

        return roundInterval * coefficientTime;
    }

    private getDisplayValue(value: string, isShowDayFormat: boolean): string {
        const m = moment.utc(value).local();
        const startDay = moment.utc(value).local().startOf('day');

        if (m.valueOf() === startDay.valueOf() || isShowDayFormat) {
            const month = m.format('MMM').slice(0, 3);
            return `<span class="time-line-track-date">${m.format('D')} ${month}</span>`;
        } else {
            return `<span class="time-line-track-time">${m.format('HH:mm')}</span>`;
        }
    }

    private getStepIntervalHours(start: string, end: string): number {
        const startTime = moment(start);
        const endTime = moment(end);
        const duration = moment.duration(endTime.diff(startTime));
        return duration.asHours();
    }

    private showDayFormat(hours: number) {
        return hours >= 24;
    }

    private formatCurrentTime(value: string): string[] {
        const hoursInterval = this.getStepIntervalHours(this.dateArray[0], this.dateArray[1]);
        const isShowDayFormat = this.showDayFormat(hoursInterval);
        const localTime = moment.utc(value).local();
        const month = localTime.format('MMM').slice(0, 3);

        return [
            `${localTime.format('D')} ${month}` + (isShowDayFormat ? '' : `, `),
            isShowDayFormat ? undefined : localTime.format('HH:mm'),
        ];
    }

    private getHourByRange(startDate: string, finishDate: string): number {
        const finish = moment(finishDate);
        const start = moment(startDate);
        return Math.ceil(finish.diff(start, 'hour'));
    }

    private getStepIntervalMinutes(first: string, second: string): number {
        const init = moment(first);
        const next = moment(second);
        return next.diff(init, 'minutes');
    }

    private getRoundInterval(interval: number) {
        if (interval <= 2) {
            return interval;
        } else if (interval <= 4) {
            return 4;
        } else if (interval <= 6) {
            return 6;
        } else if (interval <= 12) {
            return 12;
        } else {
            return (Math.trunc(interval / 24) + 1) * 24;
        }
    }

    private clearDataAQI(): void {
        const { length } = this.dateArray;
        this.timeLineAQI = Array(length).fill(0);
        this.hasDataByIndex = Array(length).fill(true);
    }

    private getNowTimeByTypeInterval(
        stepDataInterval: number,
        datesHaveMicroseconds: string
    ): string {
        const now = new Date().getTime();
        const interval = stepDataInterval * 60 * 1000;
        const time = moment.utc(new Date(Math.ceil(now / interval) * interval - interval)).format();
        if (time.indexOf(datesHaveMicroseconds) === -1) {
            return time.replace('Z', '.000Z');
        }

        return time;
    }
}

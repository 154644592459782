import { CompareActions, Marker_model, StationForMapPage_model } from '@cityair/namespace';

export function actionsBeforeCompare(
    stationToAdd: StationForMapPage_model | Marker_model,
    comparedList: StationForMapPage_model[],
    isLimit: boolean,
    isCompareMode: boolean
): CompareActions {
    const haveControlPoint =
        !!comparedList.find((s) => s.type === 'geoPoint') || stationToAdd.type === 'geoPoint';

    const stationIsInCompared = comparedList.findIndex(
        (s) => s.id === stationToAdd.id && s.type === stationToAdd.type
    );

    return {
        clearBefore: haveControlPoint || !isCompareMode,
        add: !isLimit && stationIsInCompared === -1,
        delete: stationIsInCompared >= 0,
    };
}

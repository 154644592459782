import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { AdminPanelApi } from '@cityair/libs/common/api/adminPanel/api';
import { AdminDevice, MonitoringObject} from '@cityair/namespace';
import { isFalseNumber } from '@libs/common/utils/utils';
import { selectCurrentMo } from '@cityair/modules/core/store/selectors';
import { updateGroupInfo } from '@cityair/modules/core/store/actions';

// TODO: move to store?
@Injectable({
    providedIn: 'root',
})
export default class PostActions {
    constructor(
        public adminPanelApi: AdminPanelApi,
        private store: Store,
    ) {}

    saveChangeParamMo(selectedMo: MonitoringObject) {
        const isValidGeo =
            !isFalseNumber(selectedMo.geoLatitude) && !isFalseNumber(selectedMo.geoLongitude); // проверко на number

        if (!isValidGeo) {
            return;
        }

        this.adminPanelApi.editMo(selectedMo).then(() => {
            this.store.dispatch(updateGroupInfo());
        });
    }

    deleteMo = async () => {
        const mo = await firstValueFrom(this.store.select(selectCurrentMo));

        await this.adminPanelApi.deleteMo(mo);

        this.store.dispatch(updateGroupInfo());
    };

    createMo = (newMo: MonitoringObject, devices: AdminDevice[]) => {
        this.adminPanelApi.createMoItem(newMo, devices).then(() => {
            this.store.dispatch(updateGroupInfo());
        });
    };
}

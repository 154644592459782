import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { NgChartsModule } from 'ng2-charts';
import 'chartjs-adapter-moment';

import { ButtonPlayModule } from './button-play/button-play.module';
import { TimeRunnerModule } from './time-runner/time-runner.module';

import { TimelinePanelComponent } from './timeline-panel.component';
import { ChartTimelineComponent } from './chart-timeline/chart-timeline.component';
import { TrackPanelComponent } from './track-panel/track-panel.component';
import { IsNumberPipe } from './pipes/is-number.pipe';
import { ExceedsPdkValuePipe } from './pipes/exceeds-pdk-value.pipe';

import * as fromStore from './store';
import { ChartCheckboxComponent } from './chart-checkbox/chart-checkbox.component';
import { ChartCheckboxButtonComponent } from './chart-checkbox-button/chart-checkbox-button.component';
import { ChartCheckboxAqiComponent } from './chart-checkbox-aqi/chart-checkbox-aqi.component';
import { AqiDetailsTooltipModule } from '../aqi-details-tooltip/aqi-details-tooltip.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { CrossButtonModule } from '../cross-button/cross-button.module';
import { InfoIconModule } from '../icons/components/info-icon/info-icon.module';
import { InputRadioSquareModule } from '../input-radio-square/input-radio-square.module';
import { PipesModule } from '../pipes/pipes.module';
import { LoadersModule } from '../spinner/loaders.module';
import { TooltipsModule } from '../tooltips/tooltips.module';
import { ValueCircleModule } from '../value-circle/value-circle.module';

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        CrossButtonModule,
        CheckboxModule,
        NgChartsModule,
        StoreModule.forFeature('timeline', fromStore.reducers),
        ButtonPlayModule,
        TimeRunnerModule,
        TooltipsModule,
        InfoIconModule,
        LoadersModule,
        ValueCircleModule,
        InputRadioSquareModule,
        AqiDetailsTooltipModule,
    ],
    exports: [TimelinePanelComponent],
    providers: [IsNumberPipe, ExceedsPdkValuePipe],
    declarations: [
        TimelinePanelComponent,
        ChartTimelineComponent,
        TrackPanelComponent,
        IsNumberPipe,
        ExceedsPdkValuePipe,
        ChartCheckboxComponent,
        ChartCheckboxButtonComponent,
        ChartCheckboxAqiComponent,
    ],
})
export class TimelinePanelModule {}

import {
    TEMP,
    HUM,
    PRES,
    PM25,
    CH2O,
    CO,
    CO2,
    H2S,
    NH3,
    NO,
    NO2,
    O3,
    PM10,
    SO2,
    SUM,
    TSP,
} from '@libs/common/consts/substance.consts';
import { AqiType } from '@libs/common/enums/aqi.type';
import { CHART_TYPE } from '@libs/common/enums/chart.type';

export const CHART_TOOLTIP_MAX_STRINGS = 5;

export const MEASUREMENTS_CONFIG = {
    // TODO delete after new chart
    AQI: {
        chartType: CHART_TYPE.bar,
        maxChartValue: 10,
        minChartValue: 0,
    },
    [AqiType.indoor]: {
        chartType: CHART_TYPE.bar,
        maxChartValue: 10,
        minChartValue: 0,
    },
    [AqiType.cityair]: {
        chartType: CHART_TYPE.bar,
        maxChartValue: 10,
        minChartValue: 0,
    },
    [AqiType.instant]: {
        chartType: CHART_TYPE.bar,
        maxChartValue: 10,
        minChartValue: 0,
    },
    [AqiType.epa]: {
        chartType: CHART_TYPE.bar,
        maxChartValue: 10,
        minChartValue: 0,
    },
    [AqiType.CAPI]: {
        chartType: CHART_TYPE.bar,
        maxChartValue: undefined,
        minChartValue: 0,
    },
    [AqiType.aqiIn]: {
        chartType: CHART_TYPE.bar,
        maxChartValue: undefined,
        minChartValue: 0,
    },
    [AqiType.aqiPm]: {
        chartType: CHART_TYPE.bar,
        maxChartValue: undefined,
        minChartValue: 0,
    },
    [PM25]: {
        minChartValue: 0,
    },
    [PM10]: {
        minChartValue: 0,
    },
    [CO]: {
        minChartValue: 0,
    },
    [CO2]: {
        minChartValue: 0,
    },
    [NO]: {
        minChartValue: 0,
    },
    [NO2]: {
        minChartValue: 0,
    },
    [NH3]: {
        minChartValue: 0,
    },
    [SO2]: {
        minChartValue: 0,
    },
    [O3]: {
        minChartValue: 0,
    },
    [H2S]: {
        minChartValue: 0,
    },
    [CH2O]: {
        minChartValue: 0,
    },
    [SUM]: {
        minChartValue: 0,
    },
    [TSP]: {
        minChartValue: 0,
    },
};

<div class="device_card">
    <div class="device_card__header">
        <div class="device_card__title" (click)="clickForStaticTags('device', serialNumber)">
            {{ serialNumber }}
        </div>
        <div *ngIf="isOnline" class="device_card__wrapper_alert_tag alert_tags-onBattery">
            <!--            <alert-tags-->
            <!--                    [typeTag]="getTypeOfPower()"-->
            <!--                    [text]="getTextOfPower()"-->
            <!--            ></alert-tags>-->
        </div>
        <div class="device_card__wrapper_alert_tag">
            <alert-tags
                [typeTag]="isOnline ? 'royalBlue' : 'gray'"
                [text]="
                    isOnline
                        ? TEXTS.NOTIFICATIONS.alertTag.online
                        : TEXTS.NOTIFICATIONS.alertTag.offline
                "
            ></alert-tags>
        </div>
        <div class="device_card__wrapper_kebab_menu">
            <kebab-menu (clickActions)="openActions($event)"></kebab-menu>
        </div>
    </div>
    <div class="device_card__body">
        <div class="device_card__sub_title">{{ TEXTS.ADMIN_PANEL.listOM }}</div>
        <div
            *ngIf="tagsMo.length === 0"
            class="device_card__not_list device_card__not_mo"
            [innerHTML]="TEXTS.LIST_DEVICES.equipmentNotAttached"
        ></div>
        <div *ngIf="tagsMo.length !== 0" class="device_card__wrapper_list scroll">
            <ng-container *ngFor="let tm of tagsMo">
                <div class="device_card__wrapper_items device_card__mo_list">
                    <static-tags
                        class="device_card__active_static_tag"
                        (click)="clickForStaticTags('MO', tm.id)"
                        [isActiveElement]="true"
                        [text]="tm.name"
                        [description]="null"
                        [isOffline]="false"
                        [typeTag]="'mo'"
                    ></static-tags>
                </div>
            </ng-container>
        </div>
        <!--        <div class="device_card__sub_title">{{TEXTS.LIST_DEVICES.relatedDevices}}</div>-->
        <!--        <div *ngIf="tagsDevice.length == 0"-->
        <!--             class="device_card__not_list device_card__not_device"-->
        <!--             [innerHTML]="TEXTS.LIST_DEVICES.noConnectedDevices"></div>-->
        <!--        <div *ngIf="tagsDevice.length != 0"-->
        <!--             class="device_card__wrapper_list scroll">-->
        <!--            <ng-container *ngFor="let td of tagsDevice">-->
        <!--                <div class="device_card__wrapper_items device_card__device_list">-->
        <!--                    <static-tags-->
        <!--                            [text]="td.serialNumber"-->
        <!--                            [description]="td.model"-->
        <!--                            [isOffline]="false"-->
        <!--                            [typeTag]="'device'"-->
        <!--                    ></static-tags>-->
        <!--                </div>-->
        <!--            </ng-container>-->
        <!--        </div>-->
    </div>
</div>

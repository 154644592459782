<div class="messages-status" *ngIf="messages.length">
    <div class="info-wrapper" *ngFor="let msg of messages">
        <div class="icon-wrapper">
            <div [className]="'icon-status ' + msg.status"></div>
        </div>
        <div class="content">
            <div [className]="'title-status ' + msg.status" *ngIf="msg.title">
                <span>{{ msg.title }}</span>
            </div>
            <div class="text" *ngIf="msg.text">
                <span>{{ msg.text }}</span>
            </div>
        </div>
        <div class="close-icon-wrapper" (click)="removeMsg(msg)">
            <div class="close-icon"></div>
        </div>
    </div>
</div>

<div class="top_right_panel">
    <ng-container *ngIf="(groupInfo$ | async)?.allowForecast">
        <!-- Exclude public forecast button when Forecast module is enabled -->
        <ca-layer-button
            class="layer_button"
            *ngIf="isCityMode$ | async"
            [type]="LayerButtonIconType.FORECAST"
            [active]="isActiveModelling"
            (clickOnIcon)="
                isActiveModelling ? disableModelling() : enableModelling()
            "
        >
            <ca-layer-button-item
                *ngFor="let opt of MEASURES_FOR_FORECAST"
                [label]="TEXTS.NAMES[opt]"
                [active]="(forecastControlService.props$ | async)?.measure === opt"
                (click)="
                    forecastControlService.updateProps({
                        measure: opt,
                        contours: false,
                        overlay: true
                    })
                "
            ></ca-layer-button-item>
        </ca-layer-button>
    </ng-container>

    <ng-container *ngIf="(moduleConfig$ | async).enableAqi">
        <ng-container
            *ngIf="canShowAqiIndicator(store.select(selectGlobalMeasurement) | async, isCityMode$ | async)"
        >
            <ng-container
                *ngIf="{
                    aqi:
                        store.select(
                            selectLocationValue(currentLocationId$ | async)
                        ) | async
                } as temp"
            >
                <cs-aqi-indicator
                    class="aqi-indicator"
                    [@inOutAnimation]
                    (clickOutside)="showAqiLegend = false"
                    (click)="showAqiLegend = !showAqiLegend"
                    [value]="temp.aqi"
                    [dateTime]="store.select(selectTime) | async"
                    [tzOffset]="tzOffset$ | async"
                    [mmt]="store.select(selectGlobalMeasurement) | async"
                ></cs-aqi-indicator>

                <ng-container *ngIf="showAqiLegend">
                    <!-- Show anything other than CityairAqi only in RU version -->
                    <ng-container *ngIf="isRU; else nonRU">
                        <ng-container
                            [ngSwitch]="store.select(selectGlobalMeasurement) | async"
                        >
                            <cs-aqi-legend
                                class="aqi-legend"
                                *ngSwitchCase="AqiType.cityair"
                                [aqi]="temp.aqi"
                            ></cs-aqi-legend>
                            <cs-instant-aqi-legend
                                class="aqi-legend"
                                *ngSwitchCase="AqiType.instant"
                                (openAqiFaqLink)="openDetailsLink()"
                                [aqi]="temp.aqi"
                            ></cs-instant-aqi-legend>
                            <capi-aqi-legend
                                class="aqi-legend"
                                *ngSwitchCase="AqiType.CAPI"
                                [aqi]="temp.aqi"
                            ></capi-aqi-legend>
                        </ng-container>
                    </ng-container>
                    <ng-template #nonRU>
                        <cs-aqi-legend class="aqi-legend" [aqi]="temp.aqi"></cs-aqi-legend>
                    </ng-template>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <calendar
        *ngIf="(moduleConfig$ | async).enableCalendar"
        [columns]="2"
        [monthsVisible]="2"
        (updateDateRange)="updateDateRange($event)"
    ></calendar>

    <logout (handleLogout)="authService.logOut()"></logout>
</div>

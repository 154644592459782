<div
    class="dropdown-button dropdown-button--{{ type }}"
    [class.dropdown-button--disabled]="disabled"
>
    <div class="dropdown-button__text">
        {{ text }}
    </div>
    <div class="dropdown-button__arrow">
        <ca-arrow
            type="action"
            size="small"
            [direction]="type === 'closed' ? 'down' : 'up'"
            [disabled]="disabled"
        ></ca-arrow>
    </div>
</div>

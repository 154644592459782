import { intervalUpdate } from '@cityair/modules/core/store/actions';
import { ForecastControlService } from '@cityair/modules/map/services/forecast-control.service';
import { PM25 } from '@libs/common/consts/substance.consts';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { getStndTimeBegin, getStndTimeEnd } from './config';

export const enableModelling = (forecastControlService: ForecastControlService, store: Store) => {
    const begin = moment().startOf('hour').subtract(24, 'hour').valueOf();
    const end = moment().add(36, 'hour').endOf('hour').valueOf();

    store.dispatch(
        intervalUpdate({
            begin,
            end,
        })
    );

    forecastControlService.enable();

    forecastControlService.updateProps({
        measure: PM25,
        overlay: true,
        contours: false,
    });
};

export const disableModelling = (forecastControlService: ForecastControlService, store: Store) => {
    forecastControlService.disable();

    store.dispatch(
        intervalUpdate({
            begin: getStndTimeBegin(),
            end: getStndTimeEnd(),
        })
    );
};

import * as moment from 'moment';
import {
    BehaviorSubject,
    Observable,
    Subscription,
    of,
    from,
    merge,
    EMPTY,
    lastValueFrom,
} from 'rxjs';
import {
    take,
    withLatestFrom,
    filter,
    map,
    distinctUntilChanged,
    exhaustMap,
    concatMap,
    switchMap,
} from 'rxjs/operators';
import { DomainConfig } from '@cityair/modules/map/components/mapbox/domain-tiles-player/domain-config';
import {
    DomainConfigType,
    DataType,
    TileType,
    IAuthorizeHelper,
} from '@cityair/modules/map/components/mapbox/domain-tiles-player/domain-config.type';
import { Substance } from '@cityair/modules/map/components/mapbox/domain-tiles-player/substance.enum';

type DateRange = {
    begin: number;
    end: number;
};

type PlayerOptions = Partial<DomainConfig> & { domain: DomainConfigType };

// transparent 1px
const EMPTY_IMAGE =
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export class DomainTilesPlayer {
    private _selectedSubstance = Substance.PM25;

    private _config: DomainConfig;

    private _imageUrl$ = new BehaviorSubject<string>('');

    private _imageUrlPreload$ = new BehaviorSubject<string>('');

    private imageUrlPreload$ = this._imageUrlPreload$.asObservable().pipe(filter((url) => !!url));

    private _subscriptions: Subscription[] = [];

    private dateRange: DateRange;

    imageUrl$ = this._imageUrl$.asObservable();

    onImageUpdate$: Observable<number>;

    onRangeUpdate$: Observable<DateRange>;

    id: string;

    authorizeHelper: IAuthorizeHelper;

    private withTokenEnsured = (r: Response) => {
        if (!r) return EMPTY;
        if (r.ok) {
            // Success
            return of(r.url);
        } else if (r.status === 401) {
            // Unauthorized
            return from(this.authorizeHelper.refreshToken()).pipe(map(() => r.url));
        } else if (r.status === 404) {
            // Not found
            return of(EMPTY_IMAGE);
        } else {
            // Ignore any other error
            return EMPTY;
        }
    };

    constructor(
        id: string,
        config: PlayerOptions,
        onImageUpdate$: Observable<number>,
        onRangeUpdate$: Observable<DateRange>,
        isEnabled$: Observable<boolean> = of(true),
        authorizeHelper?: IAuthorizeHelper
    ) {
        this.id = id;

        this.authorizeHelper = authorizeHelper;

        this.onImageUpdate$ = isEnabled$.pipe(
            filter((isEnabled) => isEnabled),
            switchMap(() => onImageUpdate$)
        );

        this.onRangeUpdate$ = onRangeUpdate$;

        this.createConfig(config);

        const onImageUpdateSub = this.onImageUpdate$
            .pipe(distinctUntilChanged())
            .subscribe((ts) => {
                this.updateImage(ts);
            });

        const onRangeUpdateSub = this.onRangeUpdate$.subscribe((range) => {
            this.dateRange = range;
            this.selectSubstance(this.selectedSubstance);
        });

        const readyImagesSub = this.imageUrlPreload$
            .pipe(
                exhaustMap((url) => this.loadImage(url)),
                concatMap(this.withTokenEnsured),
                withLatestFrom(this.imageUrlPreload$)
            )
            .subscribe(([_, url]) => {
                this._imageUrl$.next(url);
            });

        this._subscriptions = [onImageUpdateSub, onRangeUpdateSub, readyImagesSub];
    }

    private updateImage(ts: number) {
        const config = this._config;

        if (config) {
            const timeStep = config.timeStep;
            ts = Math.floor(ts / timeStep) * timeStep; // normalize according to the step size
            const url = this.getImageUrl(ts);
            this._imageUrl$.next(url);
        }
    }

    private async loadImage(url: string) {
        return fetch(
            url,
            this.authorizeHelper
                ? {
                      headers: this.authorizeHelper.getAuthHeader(),
                      credentials: 'include',
                  }
                : {}
        ).catch(() => console.log('rejected', url));
    }

    private getImageUrl(ts: number) {
        const config = this._config;
        const dateTime = moment.utc(ts).format(config.dateFormat);
        const url = config.getImagePath(
            this._selectedSubstance,
            DataType.Raster,
            TileType.DomainTiles,
            0
        );
        const imageUrl = `${url}/${dateTime}.png`;

        return imageUrl;
    }

    selectSubstance(substance: Substance) {
        const config = this._config;

        const domain = config?.domain;

        if (domain?.substances.includes(substance)) {
            if (this._selectedSubstance !== substance) {
                this._selectedSubstance = substance;
                this.onImageUpdate$.pipe(take(1)).subscribe((ts) => this.updateImage(ts));
            }
        } else {
            console.log(
                `DomainTilesPlayer: substance ${substance} is not configured for the selected location "${domain?.slug}"`
            );
        }
    }

    get selectedSubstance() {
        return this._selectedSubstance;
    }

    private createConfig(cfg: PlayerOptions) {
        this._config = new DomainConfig(cfg);
    }

    destroy() {
        this._config = null;

        this._subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });

        this._imageUrl$.next('');
        this._imageUrl$.complete();

        this.onImageUpdate$ = null;
        this.onRangeUpdate$ = null;

        this._subscriptions = [];
    }

    get config() {
        return this._config;
    }

    preloadImages(
        timeSequence: Set<number>,
        reportProgress: (percentage: number) => void = () => {}
    ) {
        const toLoad = [...timeSequence];

        const frames = toLoad.length;

        if (frames) {
            // TODO: assume token won't expire in the nearest future while preloading tiles
            const ts0 = toLoad[0];

            const url0 = this.getImageUrl(ts0);

            let count = 0;

            const doneLoading = from(this.loadImage(url0)).pipe(
                concatMap(this.withTokenEnsured),
                switchMap(() =>
                    merge(
                        ...toLoad.map((ts) => {
                            const url = this.getImageUrl(ts);

                            return this.loadImage(url).finally(() =>
                                reportProgress((++count / frames) * 100)
                            );
                        })
                    )
                ),
                map(() => {})
            );

            return lastValueFrom(doneLoading);
        } else {
            return Promise.resolve();
        }
    }

    clear() {
        this._imageUrl$.next(EMPTY_IMAGE);
    }
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { ALERT_COLORS } from '@cityair/config';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'capi-aqi-legend-mobile',
    templateUrl: './capi-aqi-legend-mobile.component.html',
    styleUrls: ['./capi-aqi-legend-mobile.component.less'],
})
export class CapiAqiLegendMobileComponent implements OnChanges {
    @Input() aqi: number;

    @Output() closeLegend = new EventEmitter<void>();

    TEXTS = TEXTS;
    ALERT_COLORS = ALERT_COLORS;
    TEXT = TEXTS.COMMON;

    currentAqiDangerLevel: number;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.aqi) {
            this.currentAqiDangerLevel = this.aqiToDangerLevel(this.aqi);
        }
    }

    aqiToDangerLevel(i: number) {
        if (i >= 0 && i <= 4) {
            return 0;
        } else if (i >= 5 && i <= 6) {
            return 1;
        } else if (i >= 7 && i <= 13) {
            return 2;
        } else if (i >= 14) {
            return 3;
        }
    }

    close() {
        this.closeLegend.emit();
    }
}

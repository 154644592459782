import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { AQI } from '@libs/common/consts/substance.consts';
import { AqiType } from '@libs/common/enums/aqi.type';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'chart-checkbox-button',
    templateUrl: './chart-checkbox-button.component.html',
    styleUrls: ['./chart-checkbox-button.component.less'],
})
export class ChartCheckboxButtonComponent {
    @Input() mmt: string;
    @Input() unit: string;
    @Input() aqiTooltip: TemplateRef<any>;
    @Input() isSelected: boolean;
    @Input() mmtWithTooltip: string[];
    @Output() selectMmt = new EventEmitter<void>();

    TEXTS = TEXTS;
    AqiType = AqiType;
    AQI = AQI;
}

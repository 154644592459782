import { LOAD_STATION_SMALL_INTERVAL } from '@cityair/libs/shared/utils/config';
import {
    DATA_INTERVAL_TYPES,
    IntervalEnum,
    MeasuresForTime,
    MeasuresInfo_model,
    OriginChartDataMeasure_model,
    OriginChartData_model,
} from '@cityair/namespace';
import { findMaxMinInForChart } from '@cityair/utils/utils';
import { AQI } from '@libs/common/consts/substance.consts';
import { AqiType } from '@libs/common/enums/aqi.type';
import { isFalseNumber } from '@libs/common/utils/utils';

export function createOriginChartData(
    _data: MeasuresForTime[],
    tzOffset = 0 /* min */,
    timeBegin = 0,
    timeEnd = 0,
    _interval: IntervalEnum = LOAD_STATION_SMALL_INTERVAL,
    measuresInfo?: MeasuresInfo_model
): OriginChartData_model {
    const toMilliseconds = 60000;

    const interval = DATA_INTERVAL_TYPES[_interval] * toMilliseconds; // minutes

    const measures = Array.from(
        new Set(_data.map((d) => Object.keys(d.values)).reduce((a, v) => [...a, ...v], []))
    );

    const sortedData = [..._data];
    sortedData.sort((a, b) => a.time - b.time);

    const data = createNullWithoutEmpty(_data, timeBegin, timeEnd, interval, {
        name: 'values',
        val: {},
    });

    const createItemWithTime =
        (time: number) =>
        (val: number): [number, number] =>
            [time + tzOffset * toMilliseconds, isFalseNumber(val) ? null : val];

    const origin: OriginChartData_model = {};

    data.forEach((arrEl) => {
        const createItem = createItemWithTime(arrEl.time);

        measures.forEach((name) => {
            if (!origin[name]) {
                origin[name] = new OriginChartDataMeasure_model();
            }

            origin[name].data.push(createItem(arrEl.values[name]));
        });
    });

    measures.forEach((name) => {
        const chartData = origin[name];
        const { min, max } = findMaxMinInForChart(chartData.data);

        chartData.min = min;
        chartData.max = max;

        const measureData = measuresInfo?.[name];

        if (measureData) {
            chartData.type = measureData.type;
            chartData.name = measureData.name;
            chartData.unit = measureData.unit;

            if (chartData.type === AQI || chartData.type === AqiType.instant) {
                chartData.min = 0;
                chartData.max = 10;
            }
        } else {
            chartData.type = name;
            chartData.name = name;
            if (measuresInfo) {
                console.error('measuresInfo не содержит описания этой меры, Id:', name);
            }
        }
    });

    return Object.values(origin).reduce(
        (a, v) => ({ ...a, [v.name]: v }),
        new OriginChartData_model()
    );
}

class CreateChartNull_model {
    time: number;
    [name: string]: any;
}

function createNullWithoutEmpty(
    data: CreateChartNull_model[],
    timeBegin,
    timeEnd,
    interval,
    emptyObj: { name: string; val: any }
): CreateChartNull_model[] {
    if (!timeBegin || !timeEnd) {
        return data;
    }

    const newData: CreateChartNull_model[] = [];
    let lastPoint = timeBegin;

    for (let i = 0; i < data.length; i++) {
        // для начала и серидины
        const currentPoit = data[i].time;

        if (currentPoit - lastPoint > interval * 1.1 /* погрешность */) {
            newData.push({
                time: lastPoint,
                [emptyObj.name]: emptyObj.val,
            });
            i--;
            lastPoint += interval;
        } else {
            newData.push(data[i]);
            lastPoint = data[i].time;
        }
    }

    // для конца
    while (timeEnd - lastPoint > interval * 1.1) {
        // коефициент если большой то не создасться точки до конца дня
        newData.push({
            time: lastPoint + interval,
            [emptyObj.name]: emptyObj.val,
        });
        lastPoint += interval;
    }

    return newData;
}

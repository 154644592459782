import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { selectStatusMessages } from '../../store/selectors';
import { Store } from '@ngrx/store';
import { statusMessage } from '@cityair/modules/reports/models';
import { removeStatusMessage } from '../../store/actions';
import { TIMEOUT_SHOW_ALERT_MSG } from '../../constant';

@Component({
    selector: 'report-alert',
    templateUrl: './report-alert.component.html',
    styleUrls: ['./report-alert.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportAlertComponent {
    public messages: statusMessage[] = [];

    constructor(private store: Store, private _changeDetectorRef: ChangeDetectorRef) {
        this.store.select(selectStatusMessages).subscribe((data) => {
            if (data) {
                this.messages = data;
                this._changeDetectorRef.markForCheck();
                setTimeout(() => {
                    this.clearData();
                }, TIMEOUT_SHOW_ALERT_MSG);
            }
        });
    }

    public removeMsg(msg: statusMessage) {
        this.store.dispatch(removeStatusMessage({ payload: msg }));
    }

    private clearData() {
        this.messages.forEach((msg) => this.removeMsg(msg));
    }
}

import { Component, OnDestroy } from '@angular/core';

import { isRU, TEXTS } from '@libs/common/texts/texts';
import { DistributionSummary, Marker_model } from '@cityair/namespace';
import {
    getLocationPromiseValue,
    selectCitiesMarker,
    selectGlobalMeasurement,
    selectIsCityMode,
    isCompareMode,
    selectCities,
    selectGroupId,
} from '@cityair/modules/core/store/selectors';
import { Store } from '@ngrx/store';
import { selectComparedItems } from '@cityair/modules/core/store/compared-list/compared-list.selectors';
import MapboxActions from '@cityair/modules/map/components/mapbox/mapboxActions';
import { getShiftMapCityCard } from '@cityair/config';
import { selectCityCard, setCityCard } from '@cityair/modules/core/store/citycard/citycard.feature';
import { clickOnCityMarker } from '@cityair/modules/core/store/actions';
import { map, Subject } from 'rxjs';
import {
    ANALYTIC_INDORE_DATA,
    AQI_IN_ANALYTICS_GROUP_IDS,
} from '@cityair/modules/analytics/constants';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'analytics-page',
    templateUrl: 'analytics-page.component.html',
})
export class AnalyticsPage implements OnDestroy {
    cityCard$ = this.store.select(selectCityCard);
    city$ = this.cityCard$.pipe(map((card) => card?.city ?? null));

    selectIsComparisonModeActive = isCompareMode;

    isCityMode$ = this.store.select(selectIsCityMode);
    comparedItems$ = this.store.select(selectComparedItems);
    locations$ = this.store.select(selectCities);

    textsAdminPanel = TEXTS.ADMIN_PANEL;
    textsAnalytics = TEXTS.ANALYTICS_PAGE;
    textsCityCard = TEXTS.CITY_CARD;
    isRU = isRU;
    testData: DistributionSummary;
    sortListStation: {
        props: any;
        title: string;
    }[];

    public ngDestroyed$ = new Subject<void>();

    constructor(public store: Store, private mapActions: MapboxActions) {
        this.store
            .select(selectGlobalMeasurement)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((mmt) => {
                this.sortListStation = [
                    {
                        props: (marker: Marker_model) => marker.name,
                        title: TEXTS.COMMON.location,
                    },
                    {
                        props: async (marker: Marker_model) =>
                            await getLocationPromiseValue(this.store, marker.id),
                        title: TEXTS.NAMES[mmt],
                    },
                ];
            });
        this.store
            .select(selectGroupId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((id) => {
                if (AQI_IN_ANALYTICS_GROUP_IDS.indexOf(id) >= 0) {
                    this.testData = ANALYTIC_INDORE_DATA;
                }
            });
    }

    clickFromApToCity = (marker: Marker_model) => {
        this.store.dispatch(clickOnCityMarker({ cityMarker: marker }));
        this.mapActions.centringOnMarker(
            marker.geometry.coordinates[1],
            marker.geometry.coordinates[0],
            true,
            getShiftMapCityCard()
        );
    };

    backToCityChange = () => this.store.dispatch(setCityCard(null));

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    selectCitiesMarker = selectCitiesMarker;

    searchQuery = '';
}

<div class="notifications__header notifications__settings">
    <ng-container [ngSwitch]="stateService.typeFilter">
        <settings-measurements
            *ngSwitchCase="filterTypes.MEASUREMENTS"
            [subscription]="stateService.currentSubscription"
        >
        </settings-measurements>
        <settings-service
            *ngSwitchCase="filterTypes.SERVICE"
            [subscription]="stateService.currentSubscription"
        >
        </settings-service>
    </ng-container>
</div>

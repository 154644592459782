import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';

import {
    selectGlobalMeasurement,
    selectIsCityMode,
    selectLocationValue,
} from '@cityair/modules/core/store/selectors';
import { intervalUpdate, openInstantAqiFaq } from '@cityair/modules/core/store/actions';
import { AqiType } from '@libs/common/enums/aqi.type';
import { isRU } from '@libs/common/texts/texts';
import { selectCurrentLocationId } from '@cityair/modules/core/store/current-city/current-city.feature';

@Component({
    selector: 'cityair-analytics-mobile-header',
    templateUrl: './analytics-mobile-header.component.html',
    styleUrls: ['./analytics-mobile-header.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsMobileHeaderComponent {
    public showAnalytic = false;
    selectGlobalMeasurement = selectGlobalMeasurement;
    selectLocationValue = selectLocationValue;

    isRU = isRU;
    AqiType = AqiType;

    showAqiLegend = false;

    isCityMode$ = this.store.select(selectIsCityMode);
    currentLocationId$ = this.store.select(selectCurrentLocationId);

    constructor(readonly store: Store) {}

    updateDateRange(data: { begin: number; end: number }) {
        this.store.dispatch(intervalUpdate(data));
    }

    openAqiFaqLink() {
        this.store.dispatch(openInstantAqiFaq());
    }

    canShowAqiIndicator = (mmt, isCityMode: boolean) =>
        isCityMode && Object.values(AqiType).includes(mmt);

    public toggleAnalyticPage() {
        this.showAnalytic = !this.showAnalytic;
    }
}

import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

import { TEXTS } from '@libs/common/texts/texts';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { RunConfig } from '@cityair/modules/plumes/services/run-config/models';
import { RunConfigService } from '../../services/run-config/run-config.service';
import { Observable } from 'rxjs';
import { Source } from '../../services/run/models';
import { selectActiveRunSources } from '../../store/selectors';
import { Store } from '@ngrx/store';

@Component({
    selector: 'plumes-sources-list',
    templateUrl: 'plumes-sources-list.component.html',
    styleUrls: ['plumes-sources-list.component.less'],
})
export class PlumesSourcesList {
    @Input() loadError: boolean;
    @Input() isOperator: boolean;
    @Output() cbOpenCalculationResultsEdit = new EventEmitter<RunConfig>();

    @ViewChild('actionsOutlet', { static: true }) actionsOutlet: TemplateRef<HTMLDivElement>;

    textsPlumes = TEXTS.PLUMES;
    textAction = TEXTS.COMMON.actions;
    errorListLoadText = TEXTS.FORECAST.errorListLoad;
    popupActive = false;
    popupPositionTop = 0;

    selectedCard: RunConfig = null;
    runConfig$ = this.runConfigService.entities$;
    loadingRunConfig$ = this.runConfigService.loading$;
    sources$: Observable<Source[]>;

    popupOpenerElement: HTMLElement;
    constructor(
        readonly popupProvider: OffPanelPopupService,
        readonly runConfigService: RunConfigService,
        private store: Store
    ) {
        this.sources$ = this.store.select(selectActiveRunSources);
    }

    openActionsPopup({ target, positionTop }, card: RunConfig) {
        this.popupOpenerElement = target;
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.actionsOutlet, () => (this.popupActive = false));
        this.popupActive = true;
        this.selectedCard = card;
        this.popupPositionTop = positionTop;
    }

    closeActionsPopup(e: Event) {
        if (this.popupActive && this.popupOpenerElement !== e.target) {
            this.popupProvider.setTemplate(null);
            this.popupActive = false;
            this.selectedCard = null;
        }
    }

    editRunConfiguration(e: Event, card: RunConfig) {
        this.closeActionsPopup(e);
        this.cbOpenCalculationResultsEdit.emit(card);
    }

    stopRuns(e: Event, card: RunConfig) {
        this.runConfigService
            .update({
                id: card.id,
                is_active: !card.is_active,
            })
            .subscribe((data) => {
                this.closeActionsPopup(e);
            });
    }
}

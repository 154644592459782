<div
    class="page-tabs"
    [class.page-tabs--full]="isFullWidth"
    [class.page-tabs--height]="isFullHeight"
>
    <div
        class="page-tabs__tab"
        *ngFor="let tab of tabs"
        [class.page-tabs__tab--inactive]="tab !== selectedTab"
        [class.page-tabs__tab--disable]="tab.isDisabled"
        (click)="selectTab(tab)"
        [attr.data-cy]="tab.type"
    >
        <div class="page-tabs__tab__title" [innerHTML]="tab.title"></div>
        <div [class.page-tabs__tab__underline]="tab === selectedTab"></div>
    </div>
</div>

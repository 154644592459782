<ca-layer-button
    class="layer_button"
    [type]="LayerButtonIconType.POST"
    [active]="isActive"
    caTooltip
    caTooltipPlacement="left-top"
    [caTooltipTemplate]="tooltipLayerButton"
    (clickOnIcon)="isActive = !isActive"
>
    <ca-layer-button-item
        *ngFor="let mmt of mmts; let i = index"
        [ngClass]="{
            'last-aqi-border': AQIs.includes(mmt) && !AQIs.includes(mmts[i + 1])
        }"
        [label]="TEXTS.NAMES[mmt]"
        [active]="activeMmt === mmt"
        (click)="click(mmt)"
    ></ca-layer-button-item>
</ca-layer-button>

<ng-template #tooltipLayerButton>
    <ca-tooltip class="layer-button__tooltip" [text]="TEXTS.mmtMapSelectorTooltip"></ca-tooltip>
</ng-template>

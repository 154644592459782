import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { TEXTS } from '@libs/common/texts/texts';
import { formatDayMonth } from '@cityair/config';

@Component({
    selector: 'report-date-range',
    templateUrl: './report-date-range.component.html',
    styleUrls: ['./report-date-range.component.less'],
})
export class ReportDateRangeComponent {
    @Input() timeBegin: number;
    @Input() timeEnd: number;
    @Input() descriptionText = '';
    @Input() numberOfMonths = 120;
    @Input() displayMonth = 1;
    @Input() labelText = TEXTS.DOWNLOAD_POPUP.period;
    @Input() offsetTz: boolean;
    @Output() timeChange = new EventEmitter<{ begin: number; end: number }>();
    showCalendar = false;
    showAttantion = false;
    tzOffset: number;

    public getIntervalText() {
        if (this.offsetTz) {
            this.tzOffset = moment().utcOffset();
        }

        if (this.timeBegin) {
            return (
                formatDayMonth(moment(this.timeBegin)) +
                ' - ' +
                formatDayMonth(moment(this.timeEnd)) +
                ' ' +
                moment(this.timeEnd).format('YYYY')
            );
        }

        return '';
    }

    public updateTime = (begin, end) => {
        this.timeBegin = begin;
        this.timeEnd = end;
        this.showCalendar = false;

        if (this.timeEnd - this.timeBegin > 3600000 * 24 * 14) {
            this.showAttantion = true;
        } else {
            this.showAttantion = false;
        }
        this.timeChange.emit({ begin, end });
    };

    close = () => (this.showCalendar = false);
}

import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';

import { MAX_PCF, MIN_PCF } from '@cityair/config';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { ConfigPage } from '@cityair/modules/config-menu/config.types';
import { Store } from '@ngrx/store';
import { selectRegionalCoefficient } from '@cityair/modules/core/store/selectors';
import { RegionalCoefficientService } from '@cityair/modules/config-menu/regional-coefficient.service';

@Component({
    selector: 'coefficient-view',
    templateUrl: 'coefficientView.html',
    styleUrls: ['coefficientView.less'],
})
export class CoefficientComponent implements OnInit, OnDestroy {
    @ViewChild('coeffPopup', { static: true }) coeffPopup: TemplateRef<HTMLDivElement>;
    @Output() selectPage = new EventEmitter<ConfigPage>();

    selectRegionalCoefficient = selectRegionalCoefficient;

    constructor(
        private popupProvider: OffPanelPopupService,
        public regionalCoefficient: RegionalCoefficientService,
        public store: Store
    ) {}

    TEXTS = TEXTS;

    min = MIN_PCF;
    max = MAX_PCF;
    showPopup = false;
    newCoef: number = null;

    clearCoef = () => {
        this.newCoef = null;
    };

    isVrongCoeff = (): boolean => {
        if (this.newCoef === null) {
            return false;
        }

        if (typeof this.newCoef !== 'number') {
            return true;
        }

        if (this.newCoef < MIN_PCF || this.newCoef > MAX_PCF) {
            return true;
        }

        return false;
    };

    ngOnInit() {
        this.popupProvider.setTemplate(this.coeffPopup);
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
    }

    back = () => this.selectPage.emit(ConfigPage.HOME);
}

<div class="top_right_panel">
    <ca-layer-button
        class="layer_button"
        [type]="LayerButtonIconType.FORECAST"
        [active]="isActiveForecast"
        (clickOnIcon)="toggleForecast()"
    >
        <ca-layer-button-item
            *ngFor="let opt of (store.select(selectSpeciesList) | async)"
            [label]="NAMES[opt]"
            [active]="(store.select(currentForecastMmt) | async) === opt"
            (click)="updatePropsMmt({
                measure: opt,
                contours: false,
                overlay: true
            })"
        ></ca-layer-button-item>
    </ca-layer-button>
    <forecast-calendar
        *ngIf="dateRange"
        [dateRange]="dateRange"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [tzOffset]="tzOffset$ | async"
        (updateDateRange)="updateDateRange($event)"
    ></forecast-calendar>

    <logout (handleLogout)="authService.logOut()"></logout>
</div>

<cs-top-right-elements-m
    *ngIf="(moduleConfig$ | async).enableTopElements"
></cs-top-right-elements-m>

<cityair-analytics-mobile-header></cityair-analytics-mobile-header>

<div class="timeline-temp-wrapper">
    <div
        class="timeline__wrapper timeline__wrapper-displayFlex"
        [class.timeline__wrapper--mobile]="true"
    >
        <div
            class="timeline__top"
            [class.timeline-panel]="store.select(selectIsSidebarOpen) | async"
            [class.timeline-panel--mobile]="true"
        >
            <timeline-panel
                [aqiDataProvider]="aqiDataProvider"
                [isCityMode]="store.select(selectIsCityMode) | async"
                [currentTime]="store.select(selectTime) | async"
                [dates]="store.select(selectTimelineDateTimes) | async"
                [features]="store.select(selectSourcesAsFeatures) | async"
                [initSelectMeasurement]="store.select(selectGlobalMeasurement) | async"
                [labelMode]="true"
                [getDigitsAfterDot]="getDigits(store.select(selectMeasureScheme) | async)"
                [showNow]="true"
                [isComparisonModeActive]="store.select(selectIsComparisonModeActive) | async"
                [pdk]="store.select(selectPdkForChart) | async"
                [measureScheme]="store.select(selectMeasureScheme) | async"
                (goToCity)="goToCity($event)"
                (changeTime)="store.dispatch(currentTimeUpdate({ time: $event }))"
            >
            </timeline-panel>
        </div>
    </div>
</div>

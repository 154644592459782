<div class="plumes_calc scroll">
    <div class="plumes_calc__date-filter" (clickOutside)="calendarIsOpened = false">
        <ca-dropdown-button
            [text]="dateRangeFilterText"
            [type]="calendarIsOpened ? 'opened' : 'closed'"
            (click)="calendarIsOpened = !calendarIsOpened"
        ></ca-dropdown-button>
        <div class="plumes_calc__calendar">
            <calendar-months
                [show]="calendarIsOpened"
                [timeBegin]="dateTimeBegin"
                [timeEnd]="dateTimeEnd"
                [tzOffset]="tzOffset"
                [applyCb]="updateDateRange"
                [monthsVisible]="1"
                [columns]="1"
                [numberOfMonths]="12"
            ></calendar-months>
        </div>
    </div>
    <div *ngIf="loadError; else resultTemplate">
        <div class="load-error-data">
            <span>{{ errorListLoadText }}</span>
        </div>
    </div>
    <ng-template #resultTemplate>
        <div *ngIf="loaded$ | async as loaded; else loadingTemp">
            <ng-container *ngIf="runs.length === 0; else runsContent">
                <div class="plumes_calc__plug_text">{{ textNoRunsAvailable }}</div>
                <div class="plumes_calc__svg"></div>
            </ng-container>
            <ng-template #runsContent>
                <div class="plumes_calc__content scroll" (scroll)="onScroll()" #scrollable>
                    <ng-container *ngFor="let run of runs">
                        <div class="plumes_calc__annotation" *ngIf="run.annotation">
                            {{ run.annotation }}
                        </div>
                        <div class="plumes_calc__card_wrapper" (click)="selectRun(run.item)">
                            <plumes-calculation-results-card
                                [isActive]="run.item.id === currentRunId"
                                [isLoading]="run.item.id === currentRunId && isLoadingRun"
                            >
                                <plumes-run-summary
                                    class="plumes_calc__wrapper_run_summary"
                                    [run]="run.item"
                                ></plumes-run-summary>
                            </plumes-calculation-results-card>
                        </div>
                    </ng-container>
                </div>
                <ca-scroll-top *ngIf="!isScrolledToTop" (click)="scrollTop()"></ca-scroll-top>
            </ng-template>
        </div>
        <ng-template #loadingTemp>
            <div class="wrapper-loading">
                <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
            </div>
        </ng-template>
    </ng-template>
</div>

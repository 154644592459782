import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';

import { AnalyticsPage } from './components/analytics-page/analytics-page.component';
import { CityInfo } from './components/city-info/city-info.component';
import { SearchInputModule } from '@libs/shared-ui/components/search-input/search-input.module';
import { AnalyticModule } from '@cityair/components/analytic-charts/analytic-main/analytic-main.module';
import { ButtonModule } from '@libs/shared-ui/components/button/button.module';
import { ButtonsSaveCancelModule } from '@libs/shared-ui/components/buttons-save-cancel/buttons-save-cancel.module';
import { StationCityListComponent } from '@cityair/modules/analytics/components/station-city-list/station-city-list.component';
import { LittleComponentsModule } from '@libs/shared-ui/components/little-components/little-components.module';
import { ValueCircleModule } from '@libs/shared-ui/components/value-circle/value-circle.module';
import { MobileHeaderModule } from '@cityair/components/mobile-header/mobile-header.module';
import { AnalyticsMapMobileComponent } from '@cityair/modules/analytics/components/analytics-map-mobile/analytics-map-mobile.component';
import { CityscreenComponentsModule } from '@cityair/components/cityscreen-components.module';
import { AnalyticsMobileHeaderComponent } from '@cityair/modules/analytics/components/analytics-mobile-header/analytics-mobile-header.component';
import { TimelinePanelModule } from '@libs/shared-ui/components/timeline-panel/timeline-panel.module';
import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        PipesModule,
        SearchInputModule,
        AnalyticModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        LittleComponentsModule,
        ValueCircleModule,
        CityscreenComponentsModule,
        TimelinePanelModule,
        CalendarModule,
        CrossButtonModule,
        MobileHeaderModule,
    ],
    exports: [AnalyticsPage, AnalyticsMapMobileComponent],
    declarations: [
        CityInfo,
        AnalyticsPage,
        StationCityListComponent,
        CityInfo,
        AnalyticsMapMobileComponent,
        AnalyticsMobileHeaderComponent,
    ],
})
export class AnalyticsModule {}

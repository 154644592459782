import { Component } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { WindowGlobalVars } from '@cityair/namespace';
import { Store } from '@ngrx/store';
import { selectGroupInfo } from '../../../core/store/selectors';
import { AuthService } from '@cityair/modules/login/services/auth/auth.service';

declare const window: WindowGlobalVars;

@Component({
    selector: 'indoor-logout',
    templateUrl: './indoor-logout.component.html',
    styleUrls: ['./indoor-logout.component.less'],
})
export class IndoorLogoutComponent {
    selectGroupInfo = selectGroupInfo;

    constructor(readonly authService: AuthService, public store: Store) {}

    getUser = () => (window.JS_CP_LOGIN ? window.JS_CP_LOGIN.substring(0, 2) : '');

    TEXTS = TEXTS;
    isOpenSelectLang = false;
}

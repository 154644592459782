import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, take } from 'rxjs/operators';

import { isRU, TEXTS } from '@libs/common/texts/texts';
import { AdminDevice, City_model, CITYAIR_STND_DEVICE, MonitoringObject } from '@cityair/namespace';
import { PostsAndDevicesFacade } from '../../posts-and-devices.facade';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import {
    canClickOnMarker,
    getMarkerState,
    selectGlobalMeasurement,
    selectGroupCities,
    selectGroupInfo,
    selectMos,
    selectMyDevices,
    selectPostValue,
    selectZone,
} from '@cityair/modules/core/store/selectors';
import { clickFromApToMo, openInstantAqiFaq, selectMo } from '@cityair/modules/core/store/actions';
import { getColorFromZone } from '@cityair/utils/utils';
import { copyObj } from '@libs/common/utils/utils';
import { NETWORK_PAGES } from '@cityair/modules/posts-and-devices/namespace';
import { AqiType } from '@libs/common/enums/aqi.type';
import { getComparedListObject } from '@cityair/modules/core/store/compared-list/compared-list.selectors';
import PostActions from "@cityair/modules/posts-and-devices/actions";

export class CardMo {
    id: number;
    name: string;
    city: string;
    serialNumber: string[];
    arrDeviceName: AdminDevice[];
    devicesId: number[];
    isConnectedDevices: boolean;
    isNoDataSources: boolean;
    lastConnectedDevice: AdminDevice;
}

export interface Devices {
    serialNumber: string[];
    arrDevicesObj: AdminDevice[];
    devicesId: number[];
    lastConnectedDevice: AdminDevice;
}

@Component({
    selector: 'posts-list',
    templateUrl: './posts-list.component.html',
    styleUrls: ['./posts-list.component.less', '../../posts-and-devices.common-styles.less'],
    animations: ANIMATION_OPACITY,
})
export class PostsListComponent implements OnInit, OnDestroy {
    pages = NETWORK_PAGES;
    cardMo: CardMo[] = [];
    TEXTS = TEXTS;
    AqiType = AqiType;
    isShowMenu = false;
    isRU = isRU;
    isShowNotDate = false;
    isShowResultPlaceholder = false;
    menuPositionTop = 0;
    moObj: MonitoringObject[] = [];
    path = `${NETWORK_PAGES.networks}/${NETWORK_PAGES.postsList}/`;
    idTargetCard = '';
    nameTargetCard = '';
    searchQuery = '';
    isShowDeletePopup = false;
    isConnectedDevices = false;
    citeis: City_model[] = [];
    private popupOpenerElement: HTMLElement;

    getComparedListObject = (id: number) => this.store.select(getComparedListObject(id));

    getMarkerState = (id: number) => this.store.select(getMarkerState(id));

    getColorFromZone = getColorFromZone;

    selectZone = selectZone;
    selectMyDevices = selectMyDevices;
    selectGroupInfo = selectGroupInfo;
    selectPostValue = selectPostValue;
    subscriptions = [];
    globalMmt: string;
    globalMmtName: string;
    @ViewChild('cardMenu', { static: true }) cardMenu: TemplateRef<HTMLDivElement>;

    constructor(
        public postsAndDevicesFacade: PostsAndDevicesFacade,
        public popupProvider: OffPanelPopupService,
        public actions: PostActions,
        public store: Store
    ) {
        const globalMmtSub = this.store.select(selectGlobalMeasurement).subscribe((mmt) => {
            this.globalMmt = mmt;
            this.globalMmtName = TEXTS.NAMES[mmt];
        });
        this.subscriptions.push(globalMmtSub);
    }

    ngOnInit() {
        this.getStore();
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getStore() {
        const subscription1 = this.store.select(selectMos).subscribe((mos) => {
            // TODO переделать нормально на селекторы
            this.moObj = copyObj(mos);
            this.formCardMo();
            if (this.moObj.length == 0) {
                this.isShowNotDate = true;
            } else {
                this.isShowNotDate = false;
            }
        });

        this.store
            .select(selectGroupCities)
            .pipe(
                filter((citeis) => !!citeis.length),
                take(1)
            )
            .subscribe((citeis) => {
                this.citeis = copyObj(citeis);
                this.formCardMo();
                this.isShowResultPlaceholder = true;
            });

        this.subscriptions.push(subscription1);
    }

    formCardMo() {
        this.cardMo = [];
        this.moObj.forEach((mo) => {
            this.cardMo.push({
                id: mo.id,
                name: mo.name,
                serialNumber: this.getDeviceAndChildDevice(mo).serialNumber,
                city: this.getLocationName(mo.locationId),
                arrDeviceName: this.getDeviceAndChildDevice(mo).arrDevicesObj,
                devicesId: this.getDeviceAndChildDevice(mo).devicesId,
                lastConnectedDevice: this.getDeviceAndChildDevice(mo).lastConnectedDevice,
                isConnectedDevices: this.getConnectedDevice(mo),
                isNoDataSources: !(this.getDeviceAndChildDevice(mo).arrDevicesObj.length > 0),
            });
        });
    }

    getConnectedDevice(mo) {
        let isNoDataSources = true;
        mo.devicesObj.forEach((d) => {
            this.store
                .select(selectMyDevices)
                .pipe(filter((smd) => !!smd?.length))
                .subscribe((smd) => {
                    if (smd.find((s) => s.id == d.id)) {
                        isNoDataSources = false;
                    }
                });
        });
        return isNoDataSources;
    }

    getDeviceAndChildDevice(mo: MonitoringObject): Devices {
        const arr: Devices = {
            serialNumber: [],
            arrDevicesObj: [],
            devicesId: [],
            lastConnectedDevice: null,
        };
        arr.lastConnectedDevice = mo.lastConnectedDevice;
        mo.devicesObj.forEach((d) => {
            arr.serialNumber.push(d.serialNumber);
            //     d.childDevices.forEach(c => arr.serialNumber.push(c.serialNumber))
            arr.arrDevicesObj.push(d);
            //     d.childDevices.forEach(c => arr.arrDevicesObj.push(c))
            arr.devicesId.push(d.id);
            //     d.childDevices.forEach(c => arr.devicesId.push(c.id))
        });
        arr.arrDevicesObj.map((ado) => {
            if (ado.model != CITYAIR_STND_DEVICE) {
                ado.offline = false;
            }
        });
        return arr;
    }
    showDetails(data: { type: string; id: number }) {
        this.store.dispatch(clickFromApToMo({ moObjId: data.id }));
        const page = data.type == 'MO' ? NETWORK_PAGES.postsList : NETWORK_PAGES.devicesList;
        this.postsAndDevicesFacade.openPage(
            NETWORK_PAGES.networks + '/' + page + '/' + NETWORK_PAGES.details + '/' + data.id
        );
    }

    getLocationName(locationId): string {
        return this.citeis.find((c) => c.locationId == locationId)?.name
            ? this.citeis.find((c) => c.locationId == locationId).name
            : '';
    }

    getRandomInt() {
        return Math.floor(Math.random() * 10);
    }

    openPage(target: any, pageName: string) {
        if (target != null) {
            this.closePopup(target);
        }
        this.postsAndDevicesFacade.openPage(pageName);
    }

    clickMenu({ target, positionTop }, TargetId: string) {
        this.idTargetCard = TargetId;
        const id = Number(TargetId);
        this.store.dispatch(selectMo({ id }));
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.cardMenu, () => (this.isShowMenu = false));
        this.popupOpenerElement = target;
        this.menuPositionTop =
            document.documentElement.clientHeight - 242 < positionTop
                ? document.documentElement.clientHeight - 242
                : positionTop;
        this.isShowMenu = true;
    }

    closeMenu(e: Event) {
        if (this.isShowMenu && this.popupOpenerElement !== e.target) {
            this.isShowMenu = false;
        }
    }

    closePopup(e: any) {
        if (e.target.id !== 'menuBtn') {
            this.closeMenu(e);
        }
    }

    showPopup(target, type) {
        this.closePopup(target);
        this.nameTargetCard = this.cardMo.find((ic) => ic.id == Number(this.idTargetCard)).name;
        this.isShowDeletePopup = true;
    }

    deleteAccept() {
        this.actions.deleteMo();
        this.isShowDeletePopup = false;
    }

    deleteCancel() {
        this.isShowDeletePopup = false;
    }

    openAqiFaqLink(aqiType: AqiType) {
        if (aqiType === AqiType.instant) {
            this.store.dispatch(openInstantAqiFaq());
        }
    }

    clickToMarker(cm): void {
        this.store
            .select(canClickOnMarker(cm.id))
            .pipe(first())
            .subscribe((canClick) => {
                if (canClick) {
                    this.store.dispatch(clickFromApToMo({ moObjId: cm.id }));
                }
            });
    }
}

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { TEXTS } from '@libs/common/texts/texts';
import { Token } from '@cityair/namespace';
import { TokensService } from '@cityair/modules/core/services/api/tokens';

@Component({
    selector: 'browser-tokens',
    templateUrl: 'browser-tokens.html',
    styleUrls: ['browser-tokens.less'],
})
export class BrowserTokensComponent implements OnInit {
    constructor(public tokensService: TokensService) {}

    text = TEXTS.CONFIG;

    clickStream$ = new Subject<number>();
    clickStreamCloseAll$ = new Subject<void>();

    async close(id: number) {
        await this.tokensService.closeToken(id);
        const index = this.tokensService
            .getToken()
            .browserTokens.findIndex((t) => t.tokenId === id);
        this.tokensService.getToken().browserTokens.splice(index, 1);
    }

    ngOnInit() {
        this.clickStream$.pipe(distinctUntilChanged()).subscribe(async (id: number) => {
            this.close(id);
        });

        this.clickStreamCloseAll$.pipe(throttleTime(2000)).subscribe(() => {
            this.tokensService.getToken().browserTokens.forEach(async (t: Token) => {
                if (!t.isActive) {
                    this.close(t.tokenId);
                }
            });
        });
    }

    closeToken = (id: number) => this.clickStream$.next(id);

    closeAllTokens = () => this.clickStreamCloseAll$.next();

    nothingClose = () =>
        !this.tokensService.getToken().browserTokens.filter((t) => !t.isActive).length;
}

import { Injectable, NgZone } from '@angular/core';

import { MapObject } from '@cityair/modules/map/components/mapbox/mapAPI';

import { GLOBAL_ZOOM_LEVEL, STND_CITY_ZOOM, STND_GLOBAL_MIN_ZOOM } from '@cityair/config';

import { isFalseNumber } from '@libs/common/utils/utils';

import {
    GroupExtConfigName,
    GroupFeaturesService,
    GroupMapSettings,
} from '@cityair/modules/core/services/group-features/group-features.service';
import { Store } from '@ngrx/store';
import { selectIsCityMode } from '@cityair/modules/core/store/selectors';
import { first } from 'rxjs';
import { Map } from 'mapbox-gl';
import { City_model } from '@cityair/namespace';

@Injectable({
    providedIn: 'root',
})
export default class MapboxActions {
    private mapObject: MapObject;

    preventZooming = false; // чтобы в момент центрования не отлавливался зум
    currentZoom: number = STND_CITY_ZOOM;

    constructor(
        private store: Store,
        private zone: NgZone,
        private groupFeaturesService: GroupFeaturesService
    ) {}

    setMapObject(mapObj: Map): void {
        this.mapObject = new MapObject(mapObj, this.zone);
    }

    async centringMap(city: City_model, isCityMode: boolean) {
        this.preventZooming = true;
        setTimeout(() => (this.preventZooming = false), 1000);

        const mapSettings = this.groupFeaturesService.getConfig(
            GroupExtConfigName.mapSettings
        ) as GroupMapSettings;
        const minZoom = (mapSettings?.zoom ?? STND_GLOBAL_MIN_ZOOM - 1) + 1; // probably need to exclude global zoom

        const zoom = isCityMode ? STND_CITY_ZOOM : minZoom;

        const newZoom = zoom - 1;

        this.currentZoom = newZoom;

        if (!isFalseNumber(city?.lat) && !isFalseNumber(city?.lng)) {
            const { lat, lng } = city;
            this.mapObject.centerTo({ lat, lng }, newZoom);
        } else {
            this.mapObject.zoomTo(newZoom);
        }
    }

    centringOnMarker = (
        lat: number,
        lng: number,
        zooming: boolean,
        shiftMap?: [number, number]
    ) => {
        this.store
            .select(selectIsCityMode)
            .pipe(first())
            .subscribe((isCityMode) => {
                const zoom = isCityMode ? STND_CITY_ZOOM : GLOBAL_ZOOM_LEVEL - 1;
                let newZoom: number;

                if (zooming && this.currentZoom < zoom) {
                    newZoom = zoom - 1;
                }

                this.gotoLocation(lat, lng, zooming, newZoom, shiftMap);
            });
    };

    private gotoLocation(
        lat: number,
        lng: number,
        zooming: boolean,
        zoomTo?: number,
        panTo?: [number, number]
    ) {
        if (zooming && this.currentZoom < zoomTo) {
            this.preventZooming = true;
            setTimeout(() => (this.preventZooming = false), 1000);
        }

        this.mapObject.moveTo({ lat, lng }, zoomTo, panTo?.map((v) => -v) as [number, number]);
    }

    getCenter = () => this.mapObject.getCenter();
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, take, pluck, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

import { TEXTS } from '@libs/common/texts/texts';
import { selectGroupInfo, selectGroupList } from '@cityair/modules/core/store/selectors';
import { FeedbackApiService } from '@cityair/modules/core/services/api/feedback-api';
import { FormErrors } from '@libs/common/models/formErrors';
import { AuthService } from '@cityair/modules/login/services/auth/auth.service';
import { ChangeGroupHelperService } from '@cityair/modules/core/services/change-group-helper.service';

enum MobileMenuViews {
    Default,
    FeedbackForm,
    FeedbackSuccess,
    FeedbackError,
}

@Component({
    selector: 'cityscreen-mobile-profile',
    templateUrl: './mobile-profile.component.html',
    styleUrls: ['./mobile-profile.component.less'],
})
export class MobileProfileComponent implements OnInit, OnDestroy {
    TEXTS = TEXTS;
    selectGroupList = selectGroupList;
    selectGroupInfo = selectGroupInfo;
    showMobileMenu = false;

    views = MobileMenuViews;
    currentView = MobileMenuViews.Default;

    textC: string;
    currentGroupId$: Observable<number>;
    subscriptions: Subscription[] = [];

    feedbackErrorMessage = {
        title: '',
        message: '',
    };

    constructor(
        public store: Store,
        readonly feedbackApiService: FeedbackApiService,
        readonly authService: AuthService,
        private changeGroupHelper: ChangeGroupHelperService
    ) {}

    ngOnInit(): void {
        this.currentGroupId$ = this.store.select(selectGroupInfo).pipe(
            filter((g) => !isNaN(g?.groupId)),
            pluck('groupId')
        );

        this.textC = `© ${moment().get('year')} `;

        const toastMsgSub = this.feedbackApiService.errorMessage$
            .pipe(filter((message) => !!message))
            .subscribe((message) => {
                this.feedbackForm.errors$.next(null);
                this.feedbackErrorMessage = {
                    title: TEXTS.COMMON.error,
                    message,
                };
                this.currentView = this.views.FeedbackError;
            });

        this.subscriptions.push(toastMsgSub);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    setLang(lang: string) {
        localStorage.setItem('lang', lang);
        location.reload();
    }

    submitFeedback(message: string) {
        this.currentGroupId$
            .pipe(
                take(1),
                switchMap((groupId) => this.feedbackApiService.sendFeedback(message, groupId))
            )
            .subscribe(() => {
                this.feedbackForm.success$.next();
                this.currentView = this.views.FeedbackSuccess;
            });
    }

    changeGroup(groupId: number) {
        this.changeGroupHelper.changeGroup(groupId);
    }

    feedbackForm = {
        success$: new Subject<void>(),
        errors$: new Subject<FormErrors>(),
    };
}

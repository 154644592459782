import { ErrorResult } from '@cityair/modules/login/services/harvester-api/models';
import {
    MoApiItemsRequest,
    MoApiItemsResponse,
    MoApiItemRequest,
    MoApiGroupsResponse,
    MoApiPacketsRequest,
    MoApiPacketsResponse,
    MoApiExportPacketsRequest,
} from '@cityair/modules/login/services/harvester-api/moApiModels';
import { DataProvider3 } from '@cityair/libs/common/api/dataProvider/DataProvider';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MoApiProvider {
    cpController = 'MoApi2';

    constructor(private readonly dataProvider: DataProvider3) {}

    addMoItem = (
        request: MoApiItemRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ): void => {
        this.dataProvider.execHttp(this.cpController, 'AddMoItem', request, execOk, execFail);
    };

    editMoItem = (
        request: MoApiItemRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ): void => {
        this.dataProvider.execHttp(this.cpController, 'EditMoItem', request, execOk, execFail);
    };

    deleteMoItem = (
        request: MoApiItemRequest,
        execOk: (response: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ): void => {
        this.dataProvider.execHttp(this.cpController, 'DeleteMoItem', request, execOk, execFail);
    };

    getMoPackets = (
        request: MoApiPacketsRequest,
        execOk: (responce: MoApiPacketsResponse) => void,
        execFail: (errRes: ErrorResult) => void = null
    ): void => {
        this.dataProvider.execHttpEx(
            this.cpController,
            'GetMoPackets',
            request,
            false,
            true,
            execOk,
            execFail
        );
    };

    exportMoPacketsToExcel = (
        request: MoApiExportPacketsRequest,
        execOk: (responce: string) => void,
        execFail: (errRes: ErrorResult) => void = null
    ): void => {
        this.dataProvider.execHttp(
            this.cpController,
            'ExportMoPacketsToExcel',
            request,
            execOk,
            execFail
        );
    };
}

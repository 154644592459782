import { createAction, props } from '@ngrx/store';
import {
    UserData,
    IReportKind,
    statusMessage,
    Reports,
    IReport,
} from '@cityair/modules/reports/models';
import { HttpErrorResponse } from '@angular/common/http';

export enum ReportActions {
    setUserData = '[ReportAction] setUserData',
    toggleModule = '[ReportAction] toggleModule',
    setCurrentKind = '[ReportAction] setCurrentKind',
    setCurrentKindId = '[ReportAction] setCurrentKindId',
    updateCurrentReportKind = '[ReportAction] updateCurrentReportKind',
    setKindNotFound = '[ReportAction] setKindNotFound',
    addReportError = '[ReportAction] addReportError',
    updateDateRange = '[ReportAction] updateDateRange',
    updateListByStatus = '[ReportAction] updateListByStatus',
    setLastReportsStatus = '[ReportAction] setLastReportsStatus',
    setAuthVangaError = '[ReportAction] setAuthVangaError',
    reloadData = '[ReportAction] reloadData',
    addStatusMessage = '[ReportAction] addStatusMessage',
    removeStatusMessage = '[ReportAction] removeStatusMessage',
    setSortingReportList = '[ReportAction] setSortingReportList',
    downloadReport = '[ReportAction] downloadReport',
    downloadSuccess = '[ReportAction] downloadSuccess',
    downloadError = '[ReportAction] downloadError',
    updateReportSuccess = '[ReportAction] updateReportSuccess',
    updateReportError = '[ReportAction] updateReportError',
}
export const toggleReportModule = createAction(
    ReportActions.toggleModule,
    props<{ payload: boolean }>()
);
export const setUserData = createAction(ReportActions.setUserData, props<UserData>());
export const setCurrentKind = createAction(ReportActions.setCurrentKind, props<IReportKind>());
export const setCurrentKindId = createAction(
    ReportActions.setCurrentKindId,
    props<{ id: number }>()
);
export const updateCurrentReportKind = createAction(
    ReportActions.updateCurrentReportKind,
    props<{ payload: IReportKind }>()
);
export const setKindNotFound = createAction(
    ReportActions.setKindNotFound,
    props<{ payload: boolean }>()
);
export const addReportError = createAction(
    ReportActions.addReportError,
    props<{ payload: HttpErrorResponse }>()
);
export const updateDateRange = createAction(
    ReportActions.updateDateRange,
    props<{ payload: { startDate: string; finishDate: string } }>()
);
export const updateListByStatus = createAction(ReportActions.updateListByStatus);
export const setLastReportsStatus = createAction(
    ReportActions.setLastReportsStatus,
    props<{
        payload: {
            [key in number]: statusMessage;
        };
    }>()
);
export const setAuthVangaError = createAction(
    ReportActions.setAuthVangaError,
    props<{ payload: boolean }>()
);
export const reloadData = createAction(ReportActions.reloadData, props<{ payload: boolean }>());
export const addStatusMessage = createAction(
    ReportActions.addStatusMessage,
    props<{ payload: statusMessage }>()
);
export const removeStatusMessage = createAction(
    ReportActions.removeStatusMessage,
    props<{ payload: statusMessage }>()
);
export const setSortingReportList = createAction(
    ReportActions.setSortingReportList,
    props<{ payload: string }>()
);
export const downloadReport = createAction(ReportActions.downloadReport, props<Reports>());
export const downloadSuccess = createAction(
    ReportActions.downloadSuccess,
    props<{ payload: any }>()
);
export const downloadError = createAction(ReportActions.downloadError, props<{ payload: any }>());
export const updateReportSuccess = createAction(
    ReportActions.updateReportSuccess,
    props<{ payload: Reports }>()
);
export const updateReportError = createAction(
    ReportActions.updateReportError,
    props<{ payload: { error: any; report: Reports } }>()
);

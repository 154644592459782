<div class="station_city_list__wrapper">
    <div class="station_city_list__sort_list_wrapper">
        <sort-list
            [properties]="sortListStation"
            [cbSortFun]="sortingOfStation"
            [sortingProps]="sortingStation"
            [sortingDirection]="sortingDirection"
        ></sort-list>
    </div>
    <div class="station_city_list__body scroll">
        <div
            [ngClass]="{
                'station_city_list station_city_list__container': true,
                'station_city_list__container-big': showCity
            }"
            *ngFor="
                let marker of markersData
                    | multipleSearchFns : searchFilters : filter
                    | asyncSorting : sortingStation : sortingDirection
                    | async
            "
            (click)="clickOnMarker(marker)"
        >
            <div class="station_city_list__block">
                <div class="station_city_list__title">{{ marker.name }}</div>
                <div class="station_city_list__sub_description">{{ getText(marker) }}</div>
                <div *ngIf="showCity" class="station_city_list__description">
                    {{ marker.city?.name || '' }}
                </div>
            </div>

            <div class="station_city_list__block station_city_list__block-flex">
                <value-circle
                    class="air_aqi__num"
                    [value]="store.select(selectLocationValue(marker.id)) | async"
                    [numberAfterDot]="1"
                    [color]="
                        getColorFromZone(
                            store.select(selectZone) | async,
                            store.select(selectLocationValue(marker.id)) | async
                        )
                    "
                    [selected]="isSelected(marker.id) | async"
                    [state]="markerState(marker.id) | async"
                ></value-circle>

                <div class="station_city_list__aqi_description" [innerHTML]="unit"></div>
            </div>
        </div>
    </div>
</div>

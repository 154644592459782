import { ValidatorFn, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

export type EmailsListData = {
    name: string;
    value: string;
}[];

export function listValidator(re: RegExp): ValidatorFn {
    return (control) => {
        const arr = control.value.split ? control.value.split(',') : control.value;

        const invalidFound = arr.some((val: string) => {
            val = val.trim();
            return val.length && !re.test(val);
        });

        return invalidFound
            ? {
                  listValidator: {
                      value: control.value,
                  },
              }
            : null;
    };
}

export function deduplicate(list: string[]) {
    return Array.from(new Set(list));
}

export function haveDifferentValues<T extends any[] | any>(a: T, b: T) {
    const aKeys = Object.keys(a);

    if (aKeys.length !== Object.keys(b).length) {
        return true;
    } else {
        return aKeys.some((k) =>
            a[k] instanceof Array ? haveDifferentValues(a[k], b[k]) : a[k] !== b[k]
        );
    }
}

export function mapEmails(emailsList: string[]): EmailsListData {
    return emailsList.map((value, i) => ({
        name: `email-${i}`,
        value,
    }));
}

export function stringToList(csv: string = '') {
    return csv
        .split(',')
        .map((v) => v.trim())
        .filter((v) => v);
}

export function updateFormControls(list: UntypedFormGroup, listData: EmailsListData) {
    listData.forEach(({ name, value }) => {
        if (list.contains(name)) {
            list.patchValue({
                [name]: value,
            });
        } else {
            list.addControl(name, new UntypedFormControl(value, Validators.email));
        }
    });

    Object.keys(list.value).forEach((k) => {
        if (!listData.find((v) => v.name === k)) {
            list.removeControl(k);
        }
    });
}

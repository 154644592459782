<div class="api_tokens scroll" (scroll)="onScroll()">
    <ca-list-header [text]="TEXTS.CONFIG.countTokens" [itemsCount]="countTokens">
        <button-add [text]="TEXTS.CONFIG.create" (action)="showPopup(null, 'create')"></button-add>
    </ca-list-header>

    <ca-card
        class="api_tokens__item"
        *ngFor="let token of tokensService.getToken()?.apiTokens"
        [title]="token.title"
        [statusText]="[TEXTS.CONFIG.active]"
        [actionsActive]="hoveredToken === selectedToken && isShowMenu"
        [actionsTooltipText]="TEXTS.COMMON.actions"
        (openActionsPopup)="clickMenu($event, token)"
        (mouseenter)="hoveredToken = token"
    >
        <div class="api_tokens__token_place">
            <div>{{showTokenValue[token.tokenId] || tokenPlaceholder}}</div>
            <div
                class="api_tokens__copy_token"
                caTooltip
                caTooltipPlacement="left"
                [caTooltipTemplate]="copyTooltip"
                (click)="copyToken(token.tokenId)"
            ></div>
            <ng-template #copyTooltip>
                <ca-tooltip [text]="TEXTS.CONFIG.copy"></ca-tooltip>
            </ng-template>
        </div>

        <div class="browser_tokens__item">
            <div class="browser_tokens__part">
                <div class="browser_tokens__name">{{TEXTS.CONFIG.browserTokenItems[0]}}</div>
                <div class="browser_tokens__value">{{token.lastDate}}</div>
            </div>
            <div class="browser_tokens__hr"></div>
            <div class="browser_tokens__part">
                <div class="browser_tokens__name">{{TEXTS.CONFIG.browserTokenItems[1]}}</div>
                <div class="browser_tokens__value">{{token.createDate}}</div>
            </div>
            <div class="browser_tokens__hr"></div>
            <div class="browser_tokens__part">
                <div class="browser_tokens__name">{{TEXTS.CONFIG.browserTokenItems[2]}}</div>
                <div class="browser_tokens__value">{{token.execCount}}</div>
            </div>
        </div>
    </ca-card>
</div>

<ng-template #tokenMenu>
    <card-actions-list
        *ngIf="isShowMenu"
        [position]="menuPositionTop"
        (clickOutside)="closePopup($event)"
    >
        <card-actions-item
            data-cy="action-rename"
            [text]="TEXTS.CONFIG.rename"
            (click)="showPopup($event, 'rename')"
        ></card-actions-item>
        <card-actions-item
            data-cy="action-close"
            type="danger"
            [text]="TEXTS.CONFIG.close"
            (click)="showPopup($event, 'close')"
        ></card-actions-item>
    </card-actions-list>
</ng-template>

<ng-template #popupMenu>
    <stnd-ask-popup2
        *ngIf="popup"
        [texts]="{
            title: configPopup[popup].title,
            accept: configPopup[popup].apply,
            body: popup === 'close' ? getDescription() : ''
        }"
        [disableAccept]="(popup === 'create' || popup === 'rename') && !newTitle"
        (cancel)="popup = ''"
        (accept)="applyPopup()"
    >
        <div *ngIf="popup === 'create' || popup === 'rename'">
            <div class="api_tokens__new_title">{{TEXTS.CONFIG.createDescription}}</div>
            <input
                class="api_tokens__new_title_input"
                type="text"
                [(ngModel)]="newTitle"
                (keyup.enter)="applyPopup()"
            />
        </div>
    </stnd-ask-popup2>
</ng-template>

<div class="wrapper-kind-report-list" [ngClass]="{ 'show-more': showAll }">
    <div class="fix-height" *ngIf="showAll"></div>
    <div class="list-template-reports" [ngClass]="{ 'show-more': showAll }">
        <div class="title-row">
            <div class="title">{{ titleText }}</div>
            <div class="close-wrapper" *ngIf="showAll">
                <cross-button
                    class="close-button"
                    size="large"
                    (click)="toggleShowMore()"
                ></cross-button>
            </div>
        </div>
        <div class="kinds-wrapper">
            <div
                class="loading-kind"
                *ngIf="loadingKind | async as loading; else reportsKindTemplate"
            >
                <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
            </div>
            <ng-template #reportsKindTemplate>
                <div *ngIf="reportsKind | async as reportKinds">
                    <div class="list" *ngIf="reportKinds.length; else noDataKinds">
                        <div class="templates">
                            <div class="template-report" *ngIf="showMarketing"
                                (click)="add(marketingReportKind)"
                            >
                                <div class="wrap-title">
                                    <div class="title-temp">{{isRu ? marketingReportKind?.name : marketingReportKind?.name_eng}}</div>
                                    <div class="title-desc">{{isRu ? marketingReportKind?.short_description : marketingReportKind?.short_description_eng}}</div>
                                </div>
                                <div class="create-button-wrap">
                                    <div class="template-button">
                                        <div class="button-report">{{isRu ? 'Как это работает?' : 'How it works?'}}</div>
                                    </div>
                                    <div class="icon-wrapper"></div>
                                </div>
                            </div>
                            <div class="template-report" *ngIf="showDownload"
                                (click)="add(downloadReportKind)"
                            >
                                <div class="wrap-title">
                                    <div class="title-temp">{{isRu ? downloadReportKind?.name : downloadReportKind?.name_eng}}</div>
                                    <div class="title-desc">{{isRu ? downloadReportKind?.short_description : downloadReportKind?.short_description_eng}}</div>
                                </div>
                                <div class="create-button-wrap">
                                    <div class="template-button">
                                        <div class="button-report">{{ buttonText }}</div>
                                    </div>
                                    <div class="icon-wrapper"></div>
                                </div>
                            </div>
                            <div
                                class="template-report"
                                *ngFor="
                                    let item of reportKinds | slice : 0 : displayCount;
                                    let i = index
                                "
                                (click)="add(item)"
                            >
                                <div class="wrap-title">
                                    <div class="title-temp">{{ isRu ? item.name : item.name_eng }}</div>
                                    <div class="title-desc">{{ isRu ? item.short_description : item.short_description_eng }}</div>
                                </div>
                                <div class="create-button-wrap">
                                    <div class="template-button">
                                        <div class="button-report">{{ buttonText }}</div>
                                    </div>
                                    <div class="icon-wrapper"></div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="view-more-wrap"
                            *ngIf="reportKinds.length > displayCount && !showAll"
                            (click)="toggleShowMore()"
                        >
                            <div class="icon_more"></div>
                        </div>
                    </div>
                    <div class="list-more" *ngIf="showAll">
                        <div class="grid">
                        <div class="templates">
                            <div
                                class="template-report"
                                *ngFor="
                                    let item of reportKinds | slice : displayCount : reportKinds.length;
                                    let i = index
                                "
                                (click)="add(item)"
                            >
                                <div class="wrap-title">
                                    <div class="title-temp">{{ isRu ? item.name : item.name_eng }}</div>
                                    <div class="title-desc">{{ isRu ? item.short_description : item.short_description_eng }}</div>
                                </div>
                                <div class="create-button-wrap">
                                    <div class="template-button">
                                        <div class="button-report">{{ buttonText }}</div>
                                    </div>
                                    <div class="icon-wrapper"></div>
                                </div>
                            </div>
                        </div>
                            </div>
                    </div>
                    <div class="grid">
  <div class="item"></div>
  <div class="item"></div>
  <div class="item"></div>
  <div class="item"></div>
  <div class="item"></div>
</div>
                    <ng-template #noDataKinds>
                        <div class="no-data-wrapper">
                            <search-result-placeholder></search-result-placeholder>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </div>
    </div>
</div>

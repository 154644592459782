//-------------------------------------------------------------------

import { CpUserItem } from '@cityair/modules/login/services/harvester-api/adminApiModels';

export enum DeviceSourceType {
    // Uniscan = 3,
    // SensorNode = 4,
    // AcademLab = 8,
    // MagicAirTablet = 15,
    ModuleG1 = 21,
    ModuleG2 = 22,
    Sintrol = 45,
    //    ModuleDust = 23,
    //    ModuleRef = 24,
    //    ModuleB150 = 25,
    //    OpenAq = 31
}

export enum PacketValueType {
    Temperature = 1,
    Pressure = 2,
    Humidity = 3,
    PM2 = 4,
    PM10 = 5,
    CO = 6,
    CO2 = 7,
    NO = 8,
    NO2 = 9,
    SO2 = 10,
    O3 = 11,
    H2S = 12,
    TSP = 13, // Пыль общая
    CH2O = 20,
    NH3 = 21,
}

export class GeoInfo {
    Latitude: number;
    Longitude: number;
}

export class DeviceSourceItem {
    SourceType: DeviceSourceType;
    Name: string;
    NameRu: string;
    IsExtension: boolean;

    // ui
    IsChecked: boolean;
}

export class PacketsValueTypeItem {
    ValueType: PacketValueType;
    TypeOrder: number;
    TypeModule: string;
    TypeName: string;
    TypeNameRu: string;
    Measurement: string;
    MeasurementRu: string;
    IsHidden: boolean;
}

export class DeviceCheckInfo {
    ModuleName: string;
    Status: string;
    ErrorNumber: number;
    ErrorInfo: string;
}

export class DeviceExtInfo {
    Ps220: boolean;
    BatOk: boolean;
    IMEI: string;
    IMSI: string;
    ICCID: string;
    SoftwareVersion: string;
    DataDeliveryPeriodSec?: number;
    ExtraValues: { [k: string]: string };
}

export class DeviceExaminationInfo {
    ExaminationBegin: Date;
    ExaminationEnd: Date;
}

export class DeviceGModel {
    // Id: number;
    DateBegin: Date;
    DateEnd: Date;
    GModuleParamType: number;
    GModuleParamArgs: string;

    Title: string;
}

export class DeviceItem {
    DeviceId: number;
    ParentDeviceId?: number;
    SourceType: DeviceSourceType;

    //------------------------------------------

    SerialNumber: string;
    DeviceName: string;
    Description: string;

    InternalName: string;
    InternalDescription: string;

    //------------------------------------------

    ExaminationInfo: DeviceExaminationInfo;
    ExtInfo: DeviceExtInfo;

    //------------------------------------------

    IsOnService: boolean;
    IsOffline: boolean;

    //------------------------------------------

    DeviceFirstDate?: Date;
    DeviceLastDate?: Date;
    DeviceLastGeo: GeoInfo;

    //------------------------------------------

    ChildDevices: DeviceItem[];
    ModuleIdsActual: number[];

    //------------------------------------------

    DeviceCheckInfos: DeviceCheckInfo[];

    //------------------------------------------

    GModuleParamType: number;
    GModuleParamArgs: string;

    ExtGModels: DeviceGModel[];
}

//-------------------------------------------------------------------

export class PacketValueItem {
    // Тип значения из фиксированого перчисления
    VT: PacketValueType;

    // Value - Само значение одинарной точности
    V: number;

    // DeviceId - Здесь возможен ID самого устройства, или подключенного расширения
    D: number;
}

export class ServiceDataItem {
    Geo: GeoInfo;
    Ps220?: boolean;
    BatLow?: boolean;
    GsmRssi?: number;
    DDW?: boolean;
    DoS: number[];
}

export class PacketItem {
    PacketId: number;

    SendDate: Date;
    RecvDate: Date;

    ServiceData: ServiceDataItem;
    Data: PacketValueItem[];
}

//-------------------------------------------------------------------
// MO Items
//-------------------------------------------------------------------

// Провайдер источника данных для МО [CityAir, OpenAQ, AirVisual, RosGidroMet]
// export class MoDataProviderItem {
//    DataProviderId: number;
//    Name: string;

//    // gui
//    IsChecked: boolean;
// }

// Страна
export class MoCountryItem {
    CountryId: number;
    Name: string;
    NameRu: string;
}

export class MoLocationItem {
    LocationId: number;
    ParentLocationId: number;
    LocationUrl: string;
    IsSmall: boolean;

    Name: string;
    NameRu: string;

    CountryId: number;
    SortRank: number;
    PublishOnMap: boolean;
    IsPublishAqiOnly: boolean;

    Latitude: number;
    Longitude: number;

    BounceNorth: number;
    BounceSouth: number;
    BounceEast: number;
    BounceWest: number;

    // UI Only
    IsChecked: boolean;
}

export class RegionCoefsItem {
    RegionCoefsId: number;
    Name: string;
    Description: string;

    // PhotometricCalibrationFactor
    Pcf: number;
    // SizeCalibrationFactor
    Scf: number;
}

//-------------------------------------------------------------------

export class MoDeviceLinkDesc {
    LinkId: number;
    DeviceId: number;
    MapBegin: Date;
    MapEnd: Date;
}

export class MoItem {
    MoId: number;
    Name: string;
    OwnerId: string;
    Description: string;
    DotItem: GeoInfo;

    // Провайдер источника данных для МО [CityAir, OpenAQ, AirVisual, RosGidroMet]
    DataProviderId: number;

    // Принадлежность к Локации
    LocationId: any;

    // Используется только для представления на карте
    PublishName: string;
    PublishNameRu: string;

    IsPublic: boolean;
    IsPublishAqiOnly: boolean;

    GmtOffsetSeconds: number;
    TimeZoneIana: string;

    IsOffline: boolean;
    ResetMoData: boolean;
    IsDeleted: boolean;

    // Показывать или нет TSP в данном МО (по умолчанию нет)
    IsShowTspFromDevice: boolean;

    UsePDKWorkZone: boolean;

    ExcludedDeviceValueTypes: PacketValueType[];
    ExcludedDeviceModuleTypes: DeviceSourceType[];

    DeviceLinks: MoDeviceLinkDesc[];

    RegionCoefsId: number;

    DeviceSourceType: DeviceSourceType;

    // exists only in TS for UI needs
    EditGroupId: number;
    IsGroupUserChecked: boolean;
}

//------------------------------------------

export class MoPacketValue {
    // Тип значения из фиксированого перчисления
    VT: PacketValueType;

    // Value - Само значение одинарной точности
    V: number;
}

// InstantAqi
export class VtAqiValue {
    Dominant: PacketValueType;
    ValueSource: number;
    Value10: number;
    Mark: string;
}

export class MoVtAqiValue {
    ValueType: PacketValueType;
    CityairAqi: number;
    EpaAqi: number;
    IsForecast: boolean;
}

export class MoPacket {
    PacketId: number;
    SendDate: Date;
    InstantAqi: VtAqiValue;
    VtAqi: MoVtAqiValue;
    Data: MoPacketValue[];
    Timestamp: number;
}

//------------------------------------------

export class ApiRequest {
    Token: string;
}

//------------------------------------------

export enum ErrorNumberType {
    NotSpecified = 0,
    LoginFailed = 101,
    TokenFailed = 102,
    ResetCodeFailed = 109,
    EmailValidation = 110,
    InfoException = 199,
}

export class WebResponse<T> {
    Result: T;
    Message: string;
    IsError: boolean;
    ErrorNumber: ErrorNumberType;
    ErrorMessage: string;
    ErrorMessageDetails: string;
}

export class ErrorResult {
    ErrorNumber: ErrorNumberType;
    ErrorMessage: string;

    getErrorText = (): string => {
        const errMap = {
            [ErrorNumberType.NotSpecified]: 'NotSpecified',
            [ErrorNumberType.LoginFailed]: 'LoginFailed',
            [ErrorNumberType.TokenFailed]: 'TokenFailed',
            [ErrorNumberType.ResetCodeFailed]: 'ResetCodeFailed',
            [ErrorNumberType.EmailValidation]: 'EmailValidation',
            [ErrorNumberType.InfoException]: 'InfoException',
        };

        return this.ErrorNumber !== ErrorNumberType.NotSpecified &&
            this.ErrorNumber !== ErrorNumberType.InfoException
            ? `[${errMap[this.ErrorNumber]}] ${this.ErrorMessage}`
            : this.ErrorMessage;
    };

    constructor(errorNumber: ErrorNumberType, errorMessage: string) {
        this.ErrorNumber = errorNumber;
        this.ErrorMessage = errorMessage;
    }
}

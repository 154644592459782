import { USER_KEY } from '@libs/common/consts/user-key.const';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';

declare const window: any;

const MEASURE_SCHEME = 'MEASURE_SCHEME';

export function testLocalStorage(): boolean {
    try {
        return window.sessionStorage && window.localStorage;
    } catch (e) {
        return false;
    }
}

export function getUserSavedData() {
    if (!testLocalStorage()) return;

    try {
        return JSON.parse(localStorage.getItem(USER_KEY));
    } catch (err) {
        // there is no saved user data
        return null;
    }
}

function get(key: string) {
    if (!testLocalStorage()) return;

    try {
        return localStorage.getItem(key);
    } catch (err) {
        return null;
    }
}

function set(key: string, value: string) {
    if (!testLocalStorage()) return;

    try {
        return localStorage.setItem(key, value);
    } catch (err) {
        return null;
    }
}

export const getSavedMeasureScheme = () => get(MEASURE_SCHEME);
export const setSavedMeasureScheme = (val: MeasureScheme) => set(MEASURE_SCHEME, val);
export const savedMeasureScheme = getSavedMeasureScheme() as MeasureScheme;

export const getSavedLang = () => get('lang');
export const setSavedLang = (val: string) => set('lang', val);
export const currentSavedLang = getSavedLang();

<div class="card">
    <div class="card__header">
        <div class="card__title ellipsis" [class.card__title--disabled]="!isActive" [title]="title">
            {{ title }}
        </div>
        <div class="card__status" [class.card__status--disabled]="!isActive">
            {{ isActive ? statusText[0] : statusText[1] }}
        </div>
        <div
            class="card__actions"
            *ngIf="hasActions; else actionsPlaceholder"
            #popupOpener
            caTooltip
            caTooltipPlacement="left"
            [caTooltipTemplate]="actionsTooltip"
            (click)="openActions()"
        ></div>
        <ng-template #actionsTooltip>
            <ca-tooltip *ngIf="!actionsActive" [text]="actionsTooltipText"></ca-tooltip>
        </ng-template>
        <ng-template #actionsPlaceholder>
            <div class="card__actions-placeholder"></div>
        </ng-template>
    </div>
    <div class="card__content">
        <ng-content></ng-content>
    </div>
    <div class="card__content-cover" *ngIf="!isActive"></div>
</div>

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { TEXTS } from '@libs/common/texts/texts';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import { selectGroupInfo, selectMapClickState } from '@cityair/modules/core/store/selectors';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { FORECAST_PAGES, ControlPointForecast } from '../../models';
import { ControlPointService } from '../../services/control-point.service';
import {
    selectControlPointById,
    selectControlPointError,
    selectNewCoordinates,
} from '../../store/selectors';
import {
    addControlPointError,
    setCoordinates,
    setEditControlPointId,
    setNewControlPoint,
} from '../../store/actions';
import { setMapClickState } from '@cityair/modules/core/store/actions';
import { filter } from 'rxjs/operators';
import { NEW_CONTROL_POINT_OBJ_TYPE, NUMBER_ROUND_COORDINATES } from '../../constants';

export interface PATH {
    name: string;
    path: string;
}

@Component({
    selector: 'cityscreen-create-control-point',
    templateUrl: './create-control-point.component.html',
    styleUrls: ['./create-control-point.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: ANIMATION_OPACITY,
})
export class CreateControlPointComponent implements OnInit, OnDestroy {
    public currentId: number;
    private groupId: number;
    public ControlPointCreate: UntypedFormGroup;
    public translateText = TEXTS.FORECAST;
    public cancelText = TEXTS.COMMON.cancel;
    public acceptText = TEXTS.LIST_USERS.add;
    public saveText = TEXTS.COMMON.save;
    public deleteText = TEXTS.COMMON.delete;
    public currentPoint: ControlPointForecast;
    public ngDestroyed$ = new Subject<void>();
    public loadingResponse = false;
    public locationOnTheMap = TEXTS.POSTS_AND_DEVICES.locationOnTheMap;
    public isEditMode = false;
    public isShowDeletePopup = false;
    public loading = true;
    public errorResponse: string;
    public lastKeyError: string[] = [];
    public navigationLink: PATH[] = [
        {
            name: this.translateText.controlPointsTitle,
            path: FORECAST_PAGES.forecast + '/' + FORECAST_PAGES.points,
        },
        {
            name: this.translateText.newControlPoint,
            path: null,
        },
    ];
    public infoText: string;
    @ViewChild('deleteControl', { static: true }) deleteControl: TemplateRef<HTMLDivElement>;
    constructor(
        private router: Router,
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private controlPointService: ControlPointService,
        public popupProvider: OffPanelPopupService,
        private store: Store,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.createForm();
        this.currentId = +this.route.snapshot.paramMap.get('id');
        if (
            this.router.url.indexOf(
                `/${FORECAST_PAGES.forecast}/${FORECAST_PAGES.points}/${FORECAST_PAGES.edit}`
            ) === 0
        ) {
            if (this.currentId) {
                this.store
                    .select(selectControlPointById(this.currentId))
                    .pipe(takeUntil(this.ngDestroyed$))
                    .subscribe((data) => {
                        if (data === null) {
                            this.back();
                        } else {
                            this.loading = false;
                            this.setCurrentPoint(data);
                            this.store.dispatch(setEditControlPointId({ id: data?.id }));
                        }
                        _changeDetectorRef.markForCheck();
                    });
                this.setEditMode();
            } else {
                this.back();
            }
        } else {
            this.loading = false;
        }

        this.initState();
    }

    ngOnInit(): void {
        if (this.isEditMode) {
            this.infoText = this.translateText.infoTextEdit;
        } else {
            this.infoText = this.translateText.infoTextCreate;
            this.store.dispatch(setMapClickState({ isAllow: true }));
        }
    }

    ngOnDestroy(): void {
        if (!this.isEditMode) {
            this.store.dispatch(setNewControlPoint({ payload: null }));
        } else {
            this.controlPointService.updateOneInCache(this.currentPoint);
            this.store.dispatch(setEditControlPointId({ id: null }));
        }
        this.store.dispatch(setCoordinates({ payload: null }));
        this.ngDestroyed$.next();
    }

    get name() {
        return this.ControlPointCreate.get('name');
    }

    get lat() {
        return this.ControlPointCreate.get('lat');
    }

    get lon() {
        return this.ControlPointCreate.get('lon');
    }

    public back() {
        this.router.navigate([`/${FORECAST_PAGES.forecast}/${FORECAST_PAGES.points}`]);
    }

    public onSubmit() {
        this.loadingResponse = true;
        const formData = this.ControlPointCreate.getRawValue();
        if (this.isEditMode && this.currentPoint) {
            this.controlPointService
                .update({
                    id: this.currentPoint.id,
                    name: formData.name,
                    lat: formData.lat,
                    lon: formData.lon,
                    group_id: this.groupId,
                })
                .subscribe((data) => {
                    this.loadingResponse = false;
                    setTimeout(() => {
                        this.back();
                    }, 500);
                });
        } else {
            this.controlPointService
                .add({
                    name: formData.name,
                    lat: formData.lat,
                    lon: formData.lon,
                    group_id: this.groupId,
                })
                .subscribe((data) => {
                    this.loadingResponse = false;
                    setTimeout(() => {
                        this.back();
                    }, 500);
                });
        }
    }

    public getError(field: string): string {
        this.clearError(field);
        if (
            this.ControlPointCreate &&
            this.ControlPointCreate.controls[field].invalid &&
            (this.ControlPointCreate.controls[field].dirty ||
                this.ControlPointCreate.controls[field].touched)
        ) {
            if (this.ControlPointCreate.controls[field].errors.required) {
                return field === 'name'
                    ? this.translateText.nameRequiredError
                    : this.translateText.errorRequired;
            }
            if (this.ControlPointCreate.controls[field].errors.max) {
                return (
                    this.translateText.maxError +
                    ' ' +
                    this.ControlPointCreate.controls[field].errors.max.max
                );
            }
            if (this.ControlPointCreate.controls[field].errors.min) {
                return (
                    this.translateText.minError +
                    ' ' +
                    this.ControlPointCreate.controls[field].errors.min.min
                );
            }
            if (this.ControlPointCreate.controls[field].errors.maxlength) {
                return this.translateText.maxLength(
                    this.ControlPointCreate.controls[field].errors.maxlength.requiredLength
                );
            }

            if (this.ControlPointCreate.controls[field].errors.incorrect) {
                return this.ControlPointCreate.controls[field].errors.message;
            }
        }

        return '';
    }

    showDeletePopup() {
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.deleteControl, () => (this.isShowDeletePopup = true));
        this.isShowDeletePopup = true;
    }

    deleteAccept() {
        this.isShowDeletePopup = false;
        this.controlPointService.delete(this.currentId).subscribe((data) => {
            this.back();
        });
    }

    deleteCancel() {
        this.isShowDeletePopup = false;
    }

    private initState() {
        this.store
            .select(selectGroupInfo)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((groupInfo) => (this.groupId = groupInfo?.groupId));
        this.store
            .select(selectControlPointError)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((value) => {
                if (value) {
                    const data = value.error?.error;
                    for (const key in data) {
                        if (data.hasOwnProperty(key) && this.ControlPointCreate.controls[key]) {
                            this.ControlPointCreate.controls[key].setErrors({
                                incorrect: true,
                                message: data[key].join(', '),
                            });
                            this.lastKeyError.push(key);
                        } else if (data.hasOwnProperty('detail')) {
                            // TODO set
                            this.errorResponse = data.detail;
                            this.loadingResponse = false;
                            setTimeout(() => {
                                this.errorResponse = null;
                                this.store.dispatch(addControlPointError({ payload: null }));
                                this._changeDetectorRef.markForCheck();
                            }, 2500);
                        }
                    }
                }
                this.loadingResponse = false;
                this._changeDetectorRef.markForCheck();
            });
        this.store
            .select(selectMapClickState)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((mapState) => !!mapState?.coordinates)
            )
            .subscribe((mapState) => {
                if (mapState?.coordinates) {
                    // create control points marker on the Map
                    const lat = parseFloat(mapState.coordinates.lat.toFixed(NUMBER_ROUND_COORDINATES));
                    const lon = parseFloat(mapState.coordinates.lon.toFixed(NUMBER_ROUND_COORDINATES));
                    this.store.dispatch(
                        setNewControlPoint({
                            payload: {
                                name: this.translateText.newControlPointName,
                                lat: lat,
                                lon: lon,
                                obj: NEW_CONTROL_POINT_OBJ_TYPE,
                                id: null,
                            },
                        })
                    );
                    this.setCoordinatesToForm({ lat: lat, lon:lon });
                    this.store.dispatch(setMapClickState({ isAllow: false }));
                    this._changeDetectorRef.markForCheck();
                }
            });
        this.store
            .select(selectNewCoordinates)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((coordinates) => !!coordinates)
            )
            .subscribe((coordinates) => {
                if (coordinates) {
                    this.setCoordinatesToForm(coordinates);
                    this._changeDetectorRef.markForCheck();
                }
            });
    }

    private createForm() {
        this.ControlPointCreate = this.fb.group({
            name: [null, [Validators.required, Validators.maxLength(60)]],
            lat: [null, [Validators.required, Validators.min(-90), Validators.max(90)]],
            lon: [null, [Validators.required, Validators.min(-180), Validators.max(180)]],
        });
    }

    private setCurrentPoint(data: ControlPointForecast): void {
        if (!data) {
            return;
        }
        this.loading = false;
        this.currentPoint = data;
        this.ControlPointCreate.patchValue({
            name: data.name,
            lat: data.lat,
            lon: data.lon,
        });
    }

    private setEditMode(): void {
        this.isEditMode = true;
        this.navigationLink = [
            {
                name: this.translateText.controlPointsTitle,
                path: FORECAST_PAGES.forecast + '/' + FORECAST_PAGES.points,
            },
            {
                name: this.translateText.editControlPoint,
                path: null,
            },
        ];
        this.acceptText = TEXTS.EDIT_STATION.edit;
    }

    private clearError(field: string) {
        this.lastKeyError.forEach((key) => {
            if (this.ControlPointCreate.controls[key] && field === key) {
                this.ControlPointCreate.get(key).updateValueAndValidity();
            }
        });
        this.lastKeyError = [];
    }

    private setCoordinatesToForm(coordinates) {
        if (!this.ControlPointCreate) return;
        this.ControlPointCreate.controls.lat.setValue(coordinates.lat);
        this.ControlPointCreate.controls.lon.setValue(coordinates.lon);
        this.ControlPointCreate.get('lat').markAsDirty();
        this.ControlPointCreate.get('lon').markAsDirty();
    }
}

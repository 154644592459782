<div class="post-create scroll">
    <div class="post-create__form scroll">
        <header-of-panel
            class="post-create__header-of-panel"
            [path]="navigationLink"
            (outputPath)="backToPrevPage($event)"
            [title]="TEXTS.POSTS_AND_DEVICES.newMO"
        ></header-of-panel>

        <form (ngSubmit)="onSubmit()" [formGroup]="PostCreate" novalidate>
            <div class="post-create__wrapper-content">
                <ca-input
                    class="post-create__input-wrapper"
                    type="text"
                    controlName="name"
                    [form]="PostCreate"
                    [valueForm]="name.value"
                    [label]="TEXTS.POSTS_AND_DEVICES.name"
                    [textError]="errorObj.name"
                ></ca-input>
                <ca-textarea
                    type="text"
                    controlName="description"
                    [form]="PostCreate"
                    [valueForm]="description.value"
                    [label]="TEXTS.POSTS_AND_DEVICES.description"
                    [textError]="errorObj.name"
                ></ca-textarea>
            </div>
            <section-line [text]="TEXTS.POSTS_AND_DEVICES.dataSources"></section-line>
            <div class="post-create__wrapper-content">
                <input-dropdown
                    [inputText]="selectedStation !== null ? selectedStation.serialNumber : ''"
                    [label]="TEXTS.POSTS_AND_DEVICES.device"
                    [(showDropdown)]="isShowDropdownForStations"
                    (clickOutside)="isShowDropdownForStations = false"
                >
                    <selectbox-radio
                        [listItems]="devicesListForCheckbox"
                        (listItemsChange)="
                            getSelectedStation($event); isShowDropdownForStations = false
                        "
                    ></selectbox-radio>
                </input-dropdown>
                <gray-background
                    [@maxHeightAnimation]="{
                        value: selectedStation.serialNumber !== '',
                        params: { numberMaxHeight: 300 }
                    }"
                    class="post-create__gray-background"
                >
                    <div class="post-create__information-column">
                        <information-column
                            [title]="TEXTS.EDIT_STATION.model"
                            [description]="selectedStation.model"
                        ></information-column>
                        <information-column
                            [title]="TEXTS.EDIT_STATION.versionH"
                            [description]="selectedStation.hardware"
                        ></information-column>
                        <information-column
                            [title]="TEXTS.EDIT_STATION.versionS"
                            [description]="selectedStation.soft"
                        ></information-column>
                    </div>
                    <div class="post-create__information-column">
                        <information-column
                            [title]="TEXTS.EDIT_STATION.startTimeStation"
                            [description]="selectedStation.startWork"
                        ></information-column>
                        <information-column
                            [title]="TEXTS.EDIT_STATION.lastTimeStation"
                            [description]="selectedStation.lastTime"
                        ></information-column>
                        <information-column
                            [title]="TEXTS.LIST_DEVICES.interval"
                            [description]="
                                selectedStation.intervalSec
                                    ? selectedStation.intervalSec / 60 + TEXTS.LIST_DEVICES.min
                                    : '--'
                            "
                        ></information-column>
                    </div>
                </gray-background>
            </div>
            <section-line [text]="TEXTS.POSTS_AND_DEVICES.locationOnTheMap"></section-line>
            <div class="post-create__wrapper-content">
                <div class="post-create__wrapper-coordinate">
                    <input-for-coordinate
                        type="text"
                        controlName="geoLatitude"
                        [form]="PostCreate"
                        [valueForm]="geoLatitude.value"
                        [label]="TEXTS.PLUMES.latitude"
                        [textError]="errorObj.name"
                        [positioning]="'left'"
                    ></input-for-coordinate>
                    <input-for-coordinate
                        type="text"
                        controlName="geoLongitude"
                        [form]="PostCreate"
                        [valueForm]="geoLongitude.value"
                        [label]="TEXTS.PLUMES.longitude"
                        [textError]="errorObj.name"
                        [positioning]="'right'"
                    ></input-for-coordinate>
                </div>
                <input-dropdown
                    class="post-create__input-dropdown"
                    [inputText]="selectedCity.label"
                    [label]="TEXTS.POSTS_AND_DEVICES.location"
                    [(showDropdown)]="isShowDropdownForLocation"
                    (clickOutside)="isShowDropdownForLocation = false"
                >
                    <selectbox-radio
                        [listItems]="getCities"
                        (listItemsChange)="
                            getSelectedCity($event); isShowDropdownForLocation = false
                        "
                    ></selectbox-radio>
                </input-dropdown>
            </div>
        </form>
    </div>
    <div class="post-create__btn">
        <btn-cancel (click)="openPage(navigationLink[0].path)">
            {{ TEXTS.COMMON.cancel }}
        </btn-cancel>
        <btn-accept
            [disabled]="!validationCreateMo()"
            [isLoading]="isLoader"
            (cbClick)="onSubmit()"
        >
            {{ TEXTS.STATION_BLOCK.add }}
        </btn-accept>
    </div>
</div>

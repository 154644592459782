import { Component, OnInit } from '@angular/core';
import {
    BENGALURU_INDOOR_MARKER,
    BENGALURU_INDOOR_MARKER_V2,
    BENGALURU_MARKER_STATION,
    GLOBAL_MAP_SETTING,
    GLOBAL_MAP_STYLE,
    GLOBAL_ZOOM_SHOW_STATION,
    ALERT_COLORS,
    MapSettings,
} from './constants';
import { Style, Map } from 'mapbox-gl';
import { Subject } from 'rxjs';
import { MapboxFacadeService } from '../../modules/map/components/mapbox/mapbox-facade.service';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';

@Component({
    selector: 'cityscreen-global-map',
    templateUrl: './global-map.component.html',
    styleUrls: ['./global-map.component.less'],
})
export class GlobalMapComponent implements OnInit {
    public onChangePosition$ = new Subject<void>();
    public map: Map;
    public isVisible = false;
    public mapSettings: MapSettings;
    public mapStyle: Style | string = GLOBAL_MAP_STYLE;
    public currentZoom = 2;
    public objs = BENGALURU_MARKER_STATION;
    public indoorMarker = BENGALURU_INDOOR_MARKER_V2; // BENGALURU_INDOOR_MARKER;
    public zoomShowStation = GLOBAL_ZOOM_SHOW_STATION;
    public currentPitch = 0;
    public timeIndex = 0;
    public colors = ALERT_COLORS;
    readonly pitch = 60;
    private flying = false;
    constructor(private router: Router, private mapboxFacadeService: MapboxFacadeService) {
        this.mapSettings = GLOBAL_MAP_SETTING;
    }

    ngOnInit(): void {}

    public mapboxLoad(map: Map) {
        this.map = map;
        this.mapboxFacadeService.setMap(map);
        this.mapStyle = GLOBAL_MAP_STYLE;
        this.mapboxFacadeService.applyCustomStyles();
        this.isVisible = true;
        this.onChangePosition$.pipe(debounceTime(100)).subscribe(() => {
            this.currentZoom = this.map.getZoom();
            this.currentPitch = this.map.getPitch();
            if (!this.flying) {
                if (this.currentZoom < 16) {
                    if (this.currentPitch) {
                        this.map.setPitch(0);
                    }
                } else {
                    if (this.currentPitch === 0) {
                        this.map.setPitch(this.pitch);
                    }
                }
            }
        });
    }

    public selectObj(marker) {
        let duration = 500;
        this.flying = true;
        if (marker.properties.obj == 'city') {
            duration = (this.zoomShowStation - this.currentZoom) * 400;
        }
        const options: any = {
            center: marker.geometry.coordinates,
            zoom: marker.properties.obj == 'city' ? 12 : 13,
            duration: duration,
            easing: (t: number) => t,
        };
        this.map.easeTo(options);
        if (marker.properties.obj == 'city') {
            setTimeout(() => {
                this.goToIndoor();
            }, 2100);
        }
        setTimeout(() => {
            this.flying = false;
        }, duration + 2100);
    }

    public selectIndoorMarker(marker) {
        this.router.navigate([`/${MAIN_PAGES.indoor}`]);
    }

    private goToIndoor() {
        const optionsIndoor: any = {
            center: this.indoorMarker.geometry.coordinates,
            zoom: 17,
            duration: 2000,
            easing: (t: number) => t,
        };
        this.map.setPitch(this.pitch);
        this.map.easeTo(optionsIndoor);
    }

    clicked(e) {
        if (e.lngLat) {
            // console.log(e.lngLat);
        }
    }
}

import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { TEXTS } from '@libs/common/texts/texts';
import { TabModel } from '@libs/common/types/tab-model';
import { LayerButtonIconType } from '@cityair/libs/shared/modules/overlay/components/layer-button/layer-button.component';
import { getStndTimeBegin, getStndTimeEnd } from '@cityair/libs/shared/utils/config';
import { intervalUpdate } from '@cityair/modules/core/store/actions';
import { VangaAuthService } from '@cityair/modules/core/services/vanga-auth/vanga-auth.service';
import { FORECAST_PAGES } from '../../models';
import { selectForecastMapSettings } from '../../store/selectors';
import { setAuthError, toggleLayerOnMap, toggleModule } from '../../store/actions';
import { ForecastsService } from '../../services/forecasts.service';
import { MapboxFacadeService } from '@cityair/modules/map/components/mapbox/mapbox-facade.service';
import { selectGroupInfo } from '@cityair/modules/core/store/selectors';
import { UpdaterService } from '../../services/updater.service';
import { correctStartIndex } from '@libs/shared-ui/components/timeline-panel/store/core.actions';

@Component({
    selector: 'cityscreen-forecast-main',
    templateUrl: './forecast-main.component.html',
    styleUrls: ['./forecast-main.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastMainComponent implements OnInit, OnDestroy {
    public translateText = TEXTS.FORECAST;
    public tabs: TabModel[] = [
        {
            isDisabled: false,
            type: 1,
            id: FORECAST_PAGES.points,
            title: this.translateText.controlPointsTitle,
        },
    ];

    selectedTab: TabModel = this.tabs.find((item) => item.id === FORECAST_PAGES.points);
    LayerButtonIconType = LayerButtonIconType;
    public ngDestroyed$ = new Subject<void>();
    public initState$ = new Subject<void>();
    constructor(
        public store: Store,
        private forecastsService: ForecastsService,
        private mapboxFacadeService: MapboxFacadeService,
        private vangaAuthService: VangaAuthService,
        private _updaterService: UpdaterService
    ) {
        this.initState();
    }

    ngOnInit(): void {
        this.store.dispatch(correctStartIndex({ payload: true }));
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
        this._updaterService.stop();
        // set init time, switch off module for effects
        const begin = getStndTimeBegin();
        const end = getStndTimeEnd();
        this.store.dispatch(toggleModule({ payload: false }));
        this.store.dispatch(
            intervalUpdate({
                begin,
                end,
            })
        );
        this.store.dispatch(correctStartIndex({ payload: false }));
        this.store.dispatch(toggleLayerOnMap({ payload: false }));
    }

    getActiveTab() {
        return this.tabs.find((item) => item.id === FORECAST_PAGES.points);
    }

    openPage(event) {}

    private initState(): void {
        this.store.dispatch(toggleModule({ payload: true }));
        let isValidToken = true;
        const token = this.vangaAuthService.getAccessToken();
        if (!token || token === 'null') {
            isValidToken = false;
        }

        this.store
            .select(selectGroupInfo)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                if (data && data.groupId) {
                    if (isValidToken) {
                        this.forecastsService.getWithQuery({
                            group_id: data.groupId.toString(),
                        });
                    } else {
                        this.store.dispatch(setAuthError({ payload: true }));
                    }
                }
            });

        // TODO два центрирования для форекастов, тут когда переключаем модули и в setMapConfig для первоначальной загрузки
        this.store.select(selectForecastMapSettings).subscribe((data) => {
            if (data) {
                setTimeout(
                    () =>
                        this.mapboxFacadeService.gotoLocation(
                            // @ts-ignore
                            data.center.lat,
                            // @ts-ignore
                            data.center.lng,
                            data.zoom
                        ),
                    500
                );
            }
        });
    }

    @HostListener('window:blur', ['$event'])
    onBlur(event): void {
        this._updaterService.stop();
    }

    @HostListener('window:focus', ['$event'])
    onFocus(event): void {
        this._updaterService.init();
    }
}

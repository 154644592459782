import * as moment from 'moment';
import { environment } from 'environments/environment';

const currentDate = moment();
export const INIT_DAYS_INTERVAL = 2;
export const START_DATE_FORECAST = currentDate.startOf('day').valueOf();
export const FINISH_DATE_FORECAST = currentDate
    .startOf('day')
    .add(INIT_DAYS_INTERVAL, 'days')
    .valueOf();

export const FORECAST_API_URL = environment.api_url + '/api/basic/v1';

export const GET_FORECAST_INTERVAL_TIME_BY_API = true;
export const REFRESH_INTERVAL_STATION = 60 * 60 * 1000; // 1 hour
export const NEW_CONTROL_POINT_OBJ_TYPE = 'new_control_point';
export const NUMBER_ROUND_COORDINATES = 6;

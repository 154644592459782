<div class="indoor__wrapper">
    <div class="indoor__analytic" [class.active]="analyticIsOpen">
        <ca-sidebar-toggle-button
            data-cy="indoor-analytic-toggle"
            [isActive]="analyticIsOpen"
            (toggle)="analyticIsOpen = !analyticIsOpen"
        ></ca-sidebar-toggle-button>

        <ng-container [ngSwitch]="store.select(indoorSelectors.analyticType) | async">
            <post-info
                *ngSwitchCase="analyticType.post"
                [postName]="(store.select(indoorSelectors.activePost) | async)?.name"
                [data]="store.select(indoorSelectors.postInfo) | async"
                [config]="comfortConditionSettings"
                (showPopup)="cbShowPopup()"
                (back)="store.dispatch(onIsEnabledChart({ payload: false }))"
            ></post-info>

            <indoor-settings
                *ngSwitchCase="analyticType.config"
                [data]="comfortConditionSettings"
                [points]="store.select(indoorSelectors.listPoints) | async"
                (closeConfig)="store.dispatch(changeAnalyticPage({ payload: analyticType.floor }))"
            ></indoor-settings>

            <stat-info
                *ngSwitchDefault
                [data]="store.select(indoorSelectors.statInfo) | async"
                [config]="comfortConditionSettings"
                (showPopup)="cbShowPopup()"
                (showConfig)="store.dispatch(changeAnalyticPage({ payload: analyticType.config }))"
            ></stat-info>
        </ng-container>
    </div>

    <div class="indoor__center">
        <div *ngIf="configMap" class="indoor__center_top--left-side">
            <tabs-view-mode
                [tabs]="tabs"
                [selectedTab]="getActiveTab()"
                (showTab)="openPage($event)"
            ></tabs-view-mode>
        </div>
        <div class="indoor__center_top">
            <div class="indoor__center_top--right-side">
                <btn-accept
                    *ngIf="!showMap"
                    class="indoor__download_btn"
                    data-cy="indoor_download_btn"
                    (click)="store.dispatch(indoorOpenDownloadPopup())"
                >
                    <i class="indoor__download_icon"></i>{{ TEXTS.EDIT_STATION.downloadXLSData }}
                </btn-accept>

                <indoor-calendar
                    [attr.data-cy]="'indoor-calendar'"
                    [timeBegin]="store.select(indoorSelectors.dateBegin) | async"
                    [timeEnd]="store.select(indoorSelectors.dateEnd) | async"
                    (timeRangeChange)="updateTimeRange($event)"
                ></indoor-calendar>

                <indoor-logout></indoor-logout>
            </div>
        </div>

        <ng-container *ngIf="!configMap || !showMap; else floorMap">
            <point-list
                class="indoor__points-list"
                [points]="store.select(indoorSelectors.listPoints) | async"
                (clickOnPoint)="store.dispatch(selectPoint({ id: $event }))"
                (showInfo)="cbShowPopup()"
            ></point-list>

            <div class="indoor__separator"></div>
        </ng-container>

        <ng-template #floorMap>
            <floor-map [configMap]="configMap"></floor-map>
        </ng-template>

        <div class="indoor__timeline">
            <div *ngIf="showMap && legend" class="indoor__color-legend">
                <color-legend
                    [unit]="legend.unit"
                    [measure]="legend.measure"
                    [colors]="legend.colors"
                    [values]="legend.values"
                    [min]="legend.min"
                    [isLimit]="legend.isLimit"
                ></color-legend>
            </div>

            <timeline-panel
                [aqiDataProvider]="aqiDataProvider"
                [isCityMode]="store.select(selectIsCityMode) | async"
                [currentTime]="store.select(indoorSelectors.timeMs) | async"
                [dates]="store.select(indoorSelectors.timeSequences) | async"
                [features]="
                    store.select(indoorSelectors.getActivePostWithMeasurementsAsFeatures) | async
                "
                [dateTime]="store.select(indoorSelectors.time) | async"
                [initSelectMeasurement]="
                    indoorNamesToGlobal[store.select(indoorSelectors.currentMmt) | async]
                "
                [showPlayButton]="false"
                [aqiName]="AqiType.indoor"
                [mmtInfoIcon]="{
                    name: AqiType.indoor,
                    cb: cbShowPopup
                }"
                [measureScheme]="store.select(selectMeasureScheme) | async"
                (changeTimeIndex)="store.dispatch(indoorUpdateTimeIndex({ index: $event }))"
            ></timeline-panel>
        </div>
    </div>

    <indoor-loading-indicator
        *ngIf="store.select(indoorSelectors.isPointsLoading) | async"
    ></indoor-loading-indicator>
    <div
        class="indoor__empty_posts"
        *ngIf="isEmptyPosts && (store.select(indoorSelectors.isPointsLoading) | async) === false"
    >
        <div class="logout-wrapper">
            <indoor-logout></indoor-logout>
        </div>
        <div class="empty-post-wrapper">
            <non-data-screen></non-data-screen>
            <div class="empty-post-desc">{{ TEXTS.INDOOR.emptyPosts }}</div>
        </div>
    </div>
    <indoor-faq *showDirective="showPopup" (closeModal)="showPopup = false"></indoor-faq>
</div>

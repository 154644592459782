import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Store } from '@ngrx/store';
import {
    ChartArea,
    ChartConfiguration,
    ChartDataset,
    ChartType,
    LinearScaleOptions,
    Scale,
} from 'chart.js';
import { draw } from 'patternomaly';
import { BaseChartDirective } from 'ng2-charts';
import * as moment from 'moment';

import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { AQI_WITH_DETAILS } from '@libs/common/consts/aqi-with-details.const';
import { TEXTS } from '@libs/common/texts/texts';
import { TimelineState } from '../store/index';
import { selectMainChartIsLoading, selectChartEnabled } from '../store/selectors/core.selectors';
import { onIsEnabledChart } from '../store/core.actions';
import { Feature } from '../models/core';
import { CHART_TOOLTIP_MAX_STRINGS, MEASUREMENTS_CONFIG } from './utils/config';
import {
    CITY_OBJ_TYPE,
    DEFAULT_METEO_VALUES_COLOR,
    LINE_DASH_STYLES,
    METEO_VALUES_COLORS,
    TICK_COLORS,
    TICK_LABEL_COLORS,
} from '../constants';
import { HelperService } from '../services/helper.service';
import { TicksHelper } from './utils/ticks-helper';
import {
    createElement,
    createTooltipIzaTr,
    createTooltipTr,
    getOrCreateTooltip,
    keyToLabel,
    labelToKey,
} from '../chart-timeline/utils/tooltip-helper';
import { AqiDataProviderType } from '../types/capi-data-provider.type';
import { measureZones, MeasureZones } from '@libs/common/helpers/measure-zones';
import { AQI, WDA } from '@libs/common/consts/substance.consts';
import { MEASUREMENTS_ORDER } from '@libs/common/consts/measurements-order.const';
import { AqiType } from '@libs/common/enums/aqi.type';
import { GroupChartConfig } from '@libs/common/models/group-chart-config.model';
import { PdkType } from '@libs/common/types/pdk-type';
import { lightenColor, sortMeasurements, isFalseNumber } from '@libs/common/utils/utils';
import { NO_DATA_COLOR } from '@libs/common/consts/no-data-color.const';
import { GASES } from '@libs/common/consts/mmt-with-pdk-sorted.conts';
import { GroupTooltipsMmt } from '@libs/common/types/group-tooltips-mmt';
import { DataQualityTimeline } from '@libs/common/models/dataQuality';

type ChartPoint = {
    x: string;
    y: number;
};

const AQIs = [AQI, ...Object.values(AqiType)];

export const ANIMATION_CHART_HEIGHT = [
    trigger('inOutAnimation', [
        transition(':leave', [
            style({ height: 180 }),
            animate('0.2s ease-in', style({ height: 0 })),
        ]),
    ]),
];

function getGradient(
    ctx: CanvasRenderingContext2D,
    chartArea: ChartArea,
    mmt: string,
    pinsZones: MeasureZones,
    data: number[]
) {
    const result = [...data];
    const min = Math.min(...result);
    const max = Math.max(...result);
    if (pinsZones) {
        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        const countEndPoints = pinsZones.getIndex(mmt, max);
        gradient.addColorStop(0, pinsZones.getColor(min, mmt));
        for (let k = 1; k <= countEndPoints - 2; k++) {
            gradient.addColorStop(k / countEndPoints, pinsZones.getColorByIndex(mmt, k + 1));
        }
        gradient.addColorStop(1, pinsZones.getColor(max, mmt));
        return gradient;
    }
}

function createLineChartData(
    data: ChartPoint[],
    seriesName: string,
    mmt: string,
    axisId: string,
    i: number
): ChartDataset<'line', ChartPoint[]> {
    return {
        label: keyToLabel(seriesName, mmt),
        type: 'line',
        data,
        borderColor: function (context) {
            if (!GASES.includes(mmt) && measureZones.getZone(mmt)) {
                const chart = context.chart;
                const valuesY = data.map((v) => v.y);
                const { ctx, chartArea } = chart;

                // This case happens on initial chart load
                if (!chartArea) {
                    return null;
                }
                return getGradient(ctx, chartArea, mmt, measureZones, valuesY);
            } else {
                return METEO_VALUES_COLORS[mmt] || DEFAULT_METEO_VALUES_COLOR;
            }
        },
        borderDash: LINE_DASH_STYLES[i],
        order: 1,
        yAxisID: axisId,
    };
}

function createBarChartData(
    data: ChartPoint[],
    seriesName: string,
    key: string,
    axisId: string
): ChartDataset<'bar', ChartPoint[]> {
    const backgroundColor = data.map((p) => measureZones.getColor(p.y, key));
    return {
        label: keyToLabel(seriesName, key),
        type: 'bar',
        borderRadius: 2,
        barThickness: 'flex',
        data,
        backgroundColor,
        hoverBackgroundColor: backgroundColor.map((c) => lightenColor(c)),
        order: 2,
        yAxisID: axisId,
    };
}

function withDataPaddings(data: ChartPoint[]) {
    if (!data?.length) {
        return [];
    }

    const first = moment(data[0].x);
    const last = moment(data[data.length - 1].x);
    let timeStep = (last.valueOf() - first.valueOf()) / (data.length - 1);
    const addDelta = (date: moment.Moment, d: number) => date.add(d, 'milliseconds').toISOString();
    if (data.length === 1) {
        return [...data];
    } else if (data.length < 8) {
        timeStep = (last.valueOf() - first.valueOf()) / (2 * data.length);
    } else if (data.length >= 8 && data.length < 25) {
        timeStep = (last.valueOf() - first.valueOf()) / (1.7 * (data.length + 1));
    }
    return [
        {
            x: addDelta(first, -timeStep),
            y: null,
        },
        ...data,
        {
            x: addDelta(last, timeStep),
            y: null,
        },
    ];
}

@Component({
    selector: 'ca-chart-timeline',
    templateUrl: './chart-timeline.component.html',
    styleUrls: ['./chart-timeline.component.less'],
    animations: [ANIMATION_CHART_HEIGHT],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartTimelineComponent implements OnChanges {
    @Input() aqiDataProvider: AqiDataProviderType | null = null;
    @Input() isCityMode = false;
    @Input() timeIndex: number;
    @Input() data: Feature[];
    @Input() isCompare: boolean;
    @Input() showCompare: boolean;
    @Input() aqiName: AqiType;
    @Input() showGridLines?: boolean;
    @Input() initSelectMeasurement?: string;
    @Input() labelMode: boolean;
    @Input() getDigitsAfterDot: (id: string) => number;
    @Input() pdk?: { type: PdkType; pdks: { [key in string]: number } };
    @Input() aqiTooltipTemplate: TemplateRef<any>;
    @Input() mmtInfoIcon?: {
        name: string;
        cb: () => void;
    };
    @Input() chartMinMax: GroupChartConfig;
    @Input() measureScheme: MeasureScheme;
    @Input() groupTooltip: GroupTooltipsMmt;
    @Input() hasDataByIndex: boolean[] = [];
    @Input() qualityDataMode: number;
    @Input() dataQuality: DataQualityTimeline[] = [];

    @Output() setPosition = new EventEmitter<number>();
    @Output() goToCity = new EventEmitter<string>();
    @Output() removeFromComparison = new EventEmitter<number>();
    @Output() sizeUpdate = new EventEmitter<{ left: number; width: number }>();
    @Output() setCompareMode = new EventEmitter<void>();

    TEXTS = TEXTS;
    public chartEnabled: boolean;
    public availableMeasurements: string[] = MEASUREMENTS_ORDER;
    public selectedMeasurements: string[] = [];
    public cityNameField: string;
    public isEmptyData = false;
    public isEmptyDataByMmt = {};
    public mouseDown = false;
    public startX: any;
    public scrollLeft: any;
    public mmtNameLongArray = ['TEMPERATURE', 'PRESSURE', 'HUMIDITY'];
    public mmtNamesLong = TEXTS.MMT_LONG_NAMES;
    public mmtWithTooltip = ['WDA', 'WVA', 'WVVA'];

    chartLoading$ = this.store.select(selectMainChartIsLoading);

    measureZones = measureZones;
    AQI = AQI;
    WDA = WDA;
    units: Record<string, string>;
    AQIs = AQIs;
    AqiType = AqiType;
    PdkType = PdkType;

    private xScale: any = {
        type: 'time',
        display: false,
        ticks: {
            display: false,
        },
        time: {
            tooltipFormat: 'DD MMM, HH:mm',
            parser: (value: string) => moment.utc(value).local().valueOf(),
        },
        grid: {
            display: false,
        },
    };

    constructor(
        private store: Store<TimelineState>,
        private helperService: HelperService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.initState();
        this.cityNameField = this.helperService.getCityNameProp();
    }

    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    hoverLinePosition = 0;
    hoverLineVisible = false;

    ngOnChanges(changes: SimpleChanges) {
        if (!this.data) {
            this.data = [];
        }

        if (changes.data && this.data) {
            this.chartData = [];
            this.availableMeasurements = this.getMeasurements(this.data);
            this.isEmptyDataByMmt = this.updateEmptyDataMmt(this.data);

            this.updateInitSelectMeasurement();

            this.isEmptyData = this.hasNoTimeseries(this.data) || !this.hasNonEmptyDataSeries();

            this.updateUnits();

            if (this.units) {
                const chartMinMax =
                    this.selectedMeasurements.length === 1 ? this.chartMinMax : null;
                const yAxes = this.createYAxisConfig();
                this.updateYAxes(yAxes, chartMinMax);
                this.createChartData(this.data);
            }
            setTimeout(() => {
                this.correctScroll();
            }, 500);
        }

        if (changes.initSelectMeasurement?.currentValue) {
            this.updateInitSelectMeasurement();
        }
    }

    private initState() {
        this.store.select(selectChartEnabled).subscribe((data) => {
            this.chartEnabled = data;
            this._changeDetectorRef.markForCheck();
        });
    }

    private updateInitSelectMeasurement() {
        if (!this.chartEnabled && this.initSelectMeasurement) {
            this.selectedMeasurements = [this.initSelectMeasurement];
        }

        if (
            this.availableMeasurements?.length &&
            !this.availableMeasurements.includes(this.selectedMeasurements[0])
        ) {
            this.selectedMeasurements = [this.availableMeasurements[0]];
        }
    }

    private updateUnits() {
        this.units = this.availableMeasurements.reduce(
            (acc, mmt) => ({
                ...acc,
                [mmt]: TEXTS.MEASURES_SCHEME[this.measureScheme][mmt],
            }),
            {}
        );
    }

    private correctScroll() {
        const currentEl = document.querySelector(
            '.mobile-measurements_items__item.selected_mmt'
        ) as HTMLElement;
        if (currentEl) {
            currentEl.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    }

    private createYAxisConfig() {
        return this.availableMeasurements.reduce((acc, mmt) => {
            const id = this.getAxisId(mmt);

            return !acc[id]
                ? {
                      ...acc,
                      [id]: {
                          id,
                          // display: isAQI,
                          position: 'right',
                          min: MEASUREMENTS_CONFIG[mmt]?.minChartValue,
                          max: MEASUREMENTS_CONFIG[mmt]?.maxChartValue,
                          ticks: {
                              mirror: true,
                              display: false,
                          },
                          grid: {
                              drawBorder: false,
                              color: '#e6e6e6',
                              tickLength: 0,
                              display: false,
                          },
                      },
                  }
                : acc;
        }, {} as ChartConfiguration['options']['scales']);
    }

    toggleMeasurementAqi(mmt: string) {
        if (this.selectedMeasurements.length === 1 && this.selectedMeasurements.indexOf(mmt) === 0)
            return;

        // deselect
        this.selectedMeasurements.forEach((m) => {
            if (m !== mmt && AQIs.includes(m)) {
                this.toggleMeasurement(m);
            }
        });

        this.toggleMeasurement(mmt);
    }

    toggleMeasurementWithCheck(mmt: string) {
        if (this.selectedMeasurements.length === 1 && this.selectedMeasurements.indexOf(mmt) === 0)
            return;

        this.toggleMeasurement(mmt);
    }

    private toggleMeasurement(mmt: string) {
        const toDisable = this.selectedMeasurements.includes(mmt);

        if (toDisable) {
            this.selectedMeasurements = this.selectedMeasurements.filter((m) => m !== mmt);
        } else {
            this.selectedMeasurements = this.selectedMeasurements
                .concat(mmt)
                .sort(sortMeasurements);
        }

        this.updateChart(mmt, toDisable);
        this.isEmptyData = !this.hasNonEmptyDataSeries();
    }

    toggleMeasurementOne(mmt: string) {
        this.isEmptyData = this.isEmptyDataByMmt[mmt];

        const datasets = this.chartData.filter(
            (d) => this.selectedMeasurements.indexOf(labelToKey(d.label)) >= 0
        );

        datasets.forEach((dataset) => {
            dataset.hidden = true;
        });

        this.selectedMeasurements = [mmt];
        this.updateChart(mmt, false);
    }

    closeChart() {
        this.store.dispatch(onIsEnabledChart({ payload: false }));
    }

    onCompare(): void {
        this.setCompareMode.emit();
    }

    getCurrentFeatureName(obj: Feature): string {
        return this.helperService.getCurrentFeatureName(obj);
    }

    getFeatureType(obj: Feature): string {
        return this.helperService.getCurrentFeatureType(obj);
    }

    featureIdentify(_: number, feature: Feature) {
        return feature.properties.uuid;
    }

    getValue(city: Feature, mmt: string) {
        const { timeseries } = city.properties;
        const data = timeseries?.[mmt] || null;
        const index = this.timeIndex;

        if (!data) {
            return '-';
        } else {
            return isFalseNumber(data[index])
                ? '-'
                : Number(data[index].toFixed(this.getDigitsAfterDot(mmt)));
        }
    }

    private updateChart(mmt: string, toDisable: boolean) {
        const datasets = this.chartData.filter((d) => d.label.endsWith(mmt));

        datasets.forEach((dataset) => {
            dataset.hidden = toDisable;
        });

        const chartMinMax = this.selectedMeasurements.length === 1 ? this.chartMinMax : null;

        if (this.chart) {
            this.updateYAxes(this.chart.options.scales, chartMinMax);
            this.chart.update();
        }
    }

    public hasScroll() {
        const slider = document.getElementById('data-scrollable');
        if (slider) {
            return slider.scrollWidth > slider.clientWidth;
        } else {
            return false;
        }
    }

    public onWheel(event: WheelEvent): void {
        const slider = document.getElementById('data-scrollable');
        if (slider) {
            slider.scrollLeft += event.deltaY;
        }
        event.preventDefault();
    }

    public startDragging(e, flag, el) {
        this.mouseDown = true;
        this.startX = e.pageX - el.offsetLeft;
        this.scrollLeft = el.scrollLeft;
    }

    public stopDragging() {
        this.mouseDown = false;
    }

    public moveEvent(e, el) {
        e.preventDefault();
        if (!this.mouseDown) {
            return;
        }
        const x = e.pageX - el.offsetLeft;
        const scroll = x - this.startX;
        el.scrollLeft = this.scrollLeft - scroll;
    }

    public goCity(station: Feature) {
        if (station.properties.obj === 'city' && station.properties.city_id) {
            this.goToCity.emit(station.properties.city_id);
        }
    }

    private getAxisId(mmt: string) {
        return this.units[mmt] ?? mmt;
    }

    private updateGridSettings(
        axis: ChartConfiguration['options']['scales']['prop'],
        axisSettings: GroupChartConfig['prop']
    ) {
        const { min, max, intervalMajor, intervalMinor } = axisSettings;

        if (!isNaN(min)) {
            axis.min = min;
        }

        if (!isNaN(max)) {
            axis.max = max;
        }

        const ticksHelper = new TicksHelper(intervalMajor, intervalMinor);

        if (!isNaN(intervalMajor)) {
            axis.grid.color = (context) =>
                ticksHelper.isMajor(context.tick.value) ? TICK_COLORS.major : TICK_COLORS.minor;

            axis.ticks.color = (context) =>
                ticksHelper.isMajor(context.tick.value)
                    ? TICK_LABEL_COLORS.major
                    : TICK_LABEL_COLORS.minor;

            (axis as LinearScaleOptions).ticks.autoSkip = false;
            (axis as LinearScaleOptions).ticks.stepSize = intervalMinor || intervalMajor;

            // ideally is: maxTicksLimit = Math.floor(height / ticks.fontSize) for the major ticks
            axis.ticks.maxTicksLimit = (max - min) / intervalMajor > 11 ? 11 : 21;
        }
    }

    private updateYAxes(
        scales: ChartConfiguration['options']['scales'],
        chartMinMax?: GroupChartConfig
    ) {
        const yAxes = Object.keys(this.createYAxisConfig()).reduce(
            (acc, k) =>
                k === 'x'
                    ? acc
                    : {
                          ...acc,
                          [k]: scales[k],
                      },
            {} as ChartConfiguration['options']['scales']
        );

        if (chartMinMax) {
            Object.values(yAxes).forEach((axis) => {
                const mmt = this.selectedMeasurements.find(
                    (mmt) => (axis as Scale).id === this.getAxisId(mmt)
                );
                const axisSettings = chartMinMax[mmt];

                if (axisSettings) {
                    this.updateGridSettings(axis, axisSettings);
                } else {
                    axis.ticks = {};
                    if (isFalseNumber(MEASUREMENTS_CONFIG[mmt]?.maxChartValue)) {
                        delete axis.max;
                    }
                    if (isFalseNumber(MEASUREMENTS_CONFIG[mmt]?.minChartValue)) {
                        delete axis.min;
                    }
                }
            });
        }

        Object.values(yAxes).forEach((axis) => {
            axis.display = false;
            axis.ticks.display = false;
            axis.grid.display = false;
        });

        if (this.selectedMeasurementsOfSameScale()) {
            // display axis with ticks if selected datasets have the same axis
            const mmt = this.selectedMeasurements[0];

            Object.keys(yAxes)
                .filter((id) => id === this.getAxisId(mmt))
                .forEach((id) => {
                    const axis = yAxes[id];

                    if (this.showGridLines) {
                        axis.display = true;
                        axis.ticks.display = true;
                        axis.grid.display = true;
                    }
                });
        } else {
            // otherwise display y-grid for the AQI but without ticks
            const axis = yAxes[this.getAxisId(AQI)];
            if (axis && this.showGridLines) {
                axis.display = true;
                axis.grid.display = true;
            }
        }

        // push updates to the chart component
        this.chartOptions = {
            ...this.chartOptions,
            scales: {
                x: this.xScale,
                ...yAxes,
            },
        };
    }

    private selectedMeasurementsOfSameScale() {
        const ids = new Set(this.selectedMeasurements.map((mmt) => this.getAxisId(mmt)));
        return ids.size === 1;
    }

    chartClick({ event }: any) {
        const activeElements = this.chart.chart.getElementsAtEventForMode(
            event,
            'index',
            { intersect: false },
            false
        );
        const element = activeElements[0];

        if (element) {
            this.moveRunnerToChartElement(element.index);
        }
    }

    private moveRunnerToChartElement(index: number) {
        const len = this.chartData[0]?.data?.length;

        if (index > 0 && index < len - 1) {
            this.setPosition.emit(index - 1);
        }
    }

    private externalTooltipHandler = async (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;

        // do not show empty tooltips
        if (!tooltip.dataPoints || tooltip.dataPoints.length === 0) {
            return;
        }

        const tooltipEl = getOrCreateTooltip(chart);
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }
        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map((b) => b.lines);
            bodyLines.length = CHART_TOOLTIP_MAX_STRINGS;

            document.body.querySelector('.chart_tooltip__title')?.remove();
            const tableHead = createElement('div', 'chart_tooltip__title');

            titleLines.forEach((title) => {
                const div = createElement('div');

                div.appendChild(document.createTextNode(title));
                tableHead.appendChild(div);
            });

            const tableBody = document.createElement('tbody');
            const allValues: string[] = [];
            bodyLines.forEach((body, i) => {
                const value = tooltip.dataPoints[i].parsed.y;
                const key = labelToKey(tooltip.dataPoints[i].dataset?.label);
                allValues.push(key);
                let color = measureZones.getColor(value, key);

                if (GASES.includes(key) || (color === NO_DATA_COLOR && METEO_VALUES_COLORS[key]))
                    color = METEO_VALUES_COLORS[key];

                const tr = createTooltipTr(color, body);
                tableBody.appendChild(tr);
            });

            if (allValues.length === 1 && AQI_WITH_DETAILS.includes(allValues[0] as AqiType)) {
                const timestamp = tooltip.dataPoints[0].parsed.x;

                if (!this.aqiDataProvider) {
                    throw new Error('capi data provider is not set!');
                }

                const aqiDetail = await this.aqiDataProvider(timestamp, allValues[0]);

                if (aqiDetail.val && aqiDetail.details) {
                    createTooltipIzaTr(aqiDetail, tableBody);
                }
            }

            const tableRoot = tooltipEl.querySelector('table');
            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.before(tableHead);
            tableRoot.appendChild(tableBody);
            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            if (tooltip.caretX < chart.width / 2) {
                tooltipEl.style.left = positionX + tooltip.caretX + tooltip.width / 2 + 25 + 'px';
            } else {
                tooltipEl.style.left = positionX + tooltip.caretX - tooltip.width / 2 - 25 + 'px';
            }
            tooltipEl.style.top = positionY - 20 + 'px';
            tooltipEl.style.width = 'max-content';
            tooltipEl.style.whiteSpace = 'nowrap';
            tooltipEl.style.font = tooltip.options.bodyFont.string;
            tooltipEl.style.padding =
                tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        }
    };

    private getMeasurements(data: Feature[]) {
        const result: string[] = [];

        data.forEach((item) => {
            const keys = Object.keys(item.properties.timeseries);
            keys.forEach((key) => {
                if (key !== 'date' && result.indexOf(key) === -1) {
                    result.push(key);
                }
            });
        });

        result.sort(sortMeasurements);

        return result;
    }

    public getCityName(city: Feature) {
        const ancestor = city.properties.ancestors?.filter((item) => item.obj === CITY_OBJ_TYPE);
        return ancestor?.[0]?.[this.cityNameField] || '';
    }

    chartType: ChartType = 'line';

    chartData: ChartDataset<ChartType, ChartPoint[]>[] = [];

    chartPlugins: ChartConfiguration['plugins'] = [
        {
            id: 'adjustScales',
            afterUpdate: (chart) => {
                const xScale = chart.scales.x;
                const data = this.chartData[0]?.data;
                const len = data?.length;

                if (len > 30) {
                    const val1 = data[1].x;
                    const valN = data[len - 2].x;
                    const x1 = xScale.getPixelForValue(moment(val1).valueOf());
                    const xN = xScale.getPixelForValue(moment(valN).valueOf());
                    const delta = (xN - x1) / (len - 1);

                    if (delta !== 0 && x1 > 0) {
                        this.sizeUpdate.emit({
                            left: x1 - delta / 2,
                            width: delta * len,
                        });
                    }
                }
            },
        },
        {
            id: 'adjustScalesOnDestroy',
            afterDestroy: () => {
                this.sizeUpdate.emit(null);
            },
        },
        {
            id: 'skippedArea',
            beforeDraw: (chart, args, pluginOptions) => {
                if (!this.qualityDataMode) return;
                const {
                    ctx,
                    data,
                    chartArea: { top, height },
                    scales: { x },
                } = chart;
                if (data.datasets.length) {
                    ctx.save();
                    data.datasets[0].data.map((datapoint, index) => {
                        if (
                            index !== 0 &&
                            index !== data.datasets[0].data.length - 1 &&
                            (this.dataQuality[index - 1]?.isCritical ||
                                !this.hasDataByIndex[index - 1])
                        ) {
                            const x1 = x.getPixelForValue(
                                moment(this.chartData[0]?.data[index].x).valueOf()
                            );
                            const x2 = x.getPixelForValue(
                                moment(this.chartData[0]?.data[index + 1].x).valueOf()
                            );
                            if (this.dataQuality[index - 1]?.isCritical) {
                                ctx.fillStyle = 'rgba(236, 18, 96, 0.1)';
                            } else {
                                ctx.fillStyle = draw(
                                    'diagonal-right-left',
                                    'rgba(255, 255, 255, 1)',
                                    'rgba(184, 191, 204 )',
                                    9
                                );
                            }

                            ctx.fillRect(x1 - (x2 - x1) / 2, top, x2 - x1, height);
                        }
                    });
                    ctx.restore();
                }
            },
        },
    ];

    chartOptions: ChartConfiguration['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 0,
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 20,
                bottom: 10,
            },
        },
        elements: {
            line: {
                fill: false,
                tension: 0,
                borderWidth: 1,
                borderJoinStyle: 'bevel',
            },
            point: {
                radius: 0,
                hoverRadius: 0,
                hitRadius: 10,
            },
        },
        onHover: (e, _, chart) => {
            const activeElements = chart.getElementsAtEventForMode(
                e.native,
                'index',
                { intersect: false },
                false
            );
            const element = activeElements[0];

            if (element) {
                const { datasetIndex, index } = element;
                const meta = chart.getDatasetMeta(datasetIndex);

                if (index > 0 && index < meta.data.length - 1) {
                    this.hoverLinePosition = meta.data[index].x + 10;
                    this.hoverLineVisible = true;
                    this._changeDetectorRef.detectChanges();
                }
            }
        },
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
                enabled: false,
                external: this.externalTooltipHandler,
                filter: ({ dataset, dataIndex }) =>
                    dataIndex > 0 && dataIndex < dataset.data.length - 1,
            },
        },
    };

    dashLinesMap: {
        [key: string]: number;
    } = {};

    private createChartData(data: Feature[]) {
        const chartData: ChartDataset<ChartType, ChartPoint[]>[] = [];

        this.dashLinesMap = data.reduce(
            (acc, v, i) => ({
                ...acc,
                [v.properties.uuid]: LINE_DASH_STYLES[i]?.join(' '),
            }),
            {}
        );

        // show feature name if comparison
        const showName = data.length > 1;
        this.availableMeasurements.forEach((key) => {
            const chartsForKey = data.map((f) => {
                const { timeseries } = f.properties;

                return (
                    (timeseries[key] as number[])
                        ?.map((y, i) => ({
                            // TODO: will be rounded on backend
                            y: !isFalseNumber(y)
                                ? Number(y.toFixed(this.getDigitsAfterDot(key)))
                                : null,
                            x: timeseries.date[i],
                        }))
                        .filter((point) => !!point.x) || []
                );
            });
            chartsForKey.forEach((chartForKey, i) => {
                const seriesName = showName ? data[i].properties[this.cityNameField] : '';
                const dataset = AQIs.includes(key)
                    ? createBarChartData(
                          withDataPaddings(chartForKey),
                          seriesName,
                          key,
                          this.getAxisId(key)
                      )
                    : createLineChartData(
                          withDataPaddings(chartForKey),
                          seriesName,
                          key,
                          this.getAxisId(key),
                          i
                      );

                chartData.push(dataset);

                if (!this.selectedMeasurements.includes(key)) {
                    dataset.hidden = true;
                }
            });
        });

        this.chartData = chartData;
    }

    private hasNoTimeseries(data: Feature[]): boolean {
        return data.filter((d) => d.properties.has_any_timeseries).length === 0;
    }

    private updateEmptyDataMmt(data: Feature[]) {
        return data.reduce((acc, item) => {
            const { timeseries } = item.properties;

            for (const key in timeseries) {
                if (key !== 'date') {
                    if (!acc.hasOwnProperty(key) || acc[key] !== false) {
                        acc[key] = !timeseries[key]?.some((v: number) => v !== null);
                    }
                }
            }

            return acc;
        }, {});
    }

    private hasNonEmptyDataSeries(): boolean {
        return this.selectedMeasurements.some((key) => !this.isEmptyDataByMmt[key]);
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';

import { UserRecord } from './components/user-record/user-record.component';
import { UsersList } from './components/users-list/users-list.component';
import { EditUser } from './components/edit-user/edit-user.component';
import { UsersPage } from './components/users-page/users-page.component';
import { SharedComponentsModule } from '@cityair/libs/shared/components/shared-components.module';
import { ButtonAddModule } from '@libs/shared-ui/components/button-add/button-add.module';
import { SearchInputModule } from '@libs/shared-ui/components/search-input/search-input.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { LittleComponentsModule } from '@libs/shared-ui/components/little-components/little-components.module';
import { ButtonModule } from '@libs/shared-ui/components/button/button.module';
import { ButtonsSaveCancelModule } from '@libs/shared-ui/components/buttons-save-cancel/buttons-save-cancel.module';
import { UsersService } from '@cityair/modules/users/users.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        ReactiveFormsModule,
        DirectivesModule,
        LittleComponentsModule,
        ScrollingModule,
        SharedComponentsModule,
        SearchInputModule,
        ButtonAddModule,
        LoadersModule,
        ButtonModule,
        ButtonsSaveCancelModule,
    ],
    declarations: [UserRecord, UsersList, EditUser, UsersPage],
    providers: [UsersService],
    exports: [UsersPage],
})
export class UsersModule {}

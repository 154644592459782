<div
    class="logout_cityscreen"
    data-cy="logout-1"
    (click)="isOpenSelectLang = !isOpenSelectLang"
    (clickOutside)="isOpenSelectLang = false"
>
    <div class="logout_cityscreen__name">{{ getUser() }}</div>
    <div
        [ngClass]="{
            logout_cityscreen__triangle: true,
            'logout_cityscreen__triangle-open': isOpenSelectLang
        }"
    ></div>

    <div
        data-cy="logout-2"
        [ngClass]="{
            logout_cityscreen__dropdown: true,
            'logout_cityscreen__dropdown-open': isOpenSelectLang
        }"
        (click)="logout()"
    >
        <div>{{ TEXTS.LOGIN_WINDOWS.logout }}</div>
    </div>
</div>

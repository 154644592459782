import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type ModelProps = {
    measure: string;
    contours: boolean;
    overlay: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class ForecastControlService {
    private _isEnabled$ = new BehaviorSubject<boolean>(false);
    isEnabled$ = this._isEnabled$.asObservable();

    private _props$ = new BehaviorSubject<ModelProps>(null);
    props$ = this._props$.asObservable();

    private mapMode = {
        contours: false,
        heatmap: false,
    };

    enable() {
        this._isEnabled$.next(true);
    }

    disable() {
        this._isEnabled$.next(false);
    }

    updateProps(props: ModelProps) {
        this._props$.next({
            measure: props.measure,
            contours: props.contours ?? this.mapMode.contours,
            overlay: props.overlay ?? this.mapMode.heatmap,
        });
    }
}

<div class="control-point" [class.active]="isActive">
    <div class="info">
        <div class="name">{{ item?.name }}</div>
    </div>
    <div class="value">
        <div class="value_number">
            {{ isFalseNumber(formattedValue) ? '-' : roundValue(formattedValue) }}
        </div>
        <div class="value_description" [innerHTML]="units | safeHtml"></div>
    </div>
    <kebab-menu (clickActions)="clickMenu($event)"></kebab-menu>
</div>

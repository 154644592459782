<div class="search" [formGroup]="form">
    <input
        class="search__input ellipsis"
        formControlName="textInput"
        [attr.placeholder]="textPlaceholderInner"
        (focus)="onFocus()"
        (blur)="onBlur()"
    />
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="search__loupe"
        [class.search__loupe--active]="focus"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.8957 11.0777L17.6232 15.8052C18.1259 16.3067 18.1259 17.1218 17.6232 17.6232C17.1218 18.1259 16.3079 18.1259 15.8052 17.6232L11.0777 12.8957C9.93853 13.6812 8.55897 14.1428 7.0714 14.1428C3.16542 14.1428 0 10.9774 0 7.0714C0 3.16542 3.16542 0 7.0714 0C10.9774 0 14.1428 3.16542 14.1428 7.0714C14.1428 8.55897 13.6812 9.93853 12.8957 11.0777ZM7.0714 1.92856C4.23127 1.92856 1.92856 4.23127 1.92856 7.0714C1.92856 9.91153 4.23127 12.2142 7.0714 12.2142C9.91153 12.2142 12.2142 9.91153 12.2142 7.0714C12.2142 4.23127 9.91153 1.92856 7.0714 1.92856Z"
        />
    </svg>
    <cross-button
        class="search__clear"
        [class.search__clear--active]="textInner"
        (click)="clear()"
    ></cross-button>
</div>

import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

import { TEXTS } from '@libs/common/texts/texts';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { RunConfig } from '@cityair/modules/plumes/services/run-config/models';
import { declOfNum } from '@cityair/utils/utils';
import { RunConfigService } from '../../services/run-config/run-config.service';
import { selectRunConfigError } from '../../store/selectors';
import { Subject, takeUntil } from 'rxjs';
import { setControlPointError } from '../../store/actions';
import { Store } from '@ngrx/store';
import { showInfo } from '@cityair/modules/core/store/actions';

export interface ErrorModel {
    name?: string;
    schedule_period?: string;
}

export interface DropdownSelect {
    id: number;
    name: number;
}

@Component({
    selector: 'plumes-calculation-results-edit',
    templateUrl: 'plumes-calculation-results-edit.component.html',
    styleUrls: ['plumes-calculation-results-edit.component.less'],
})
export class PlumesCalculationResultsEditComponent implements OnInit, OnDestroy {
    @Input() runConfig: RunConfig;
    @Output() cbCloseEditMode = new EventEmitter<void>();

    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    hours: number;

    questionsText = TEXTS.POPUP_THREE_QUESTIONS;
    textPlumes = TEXTS.PLUMES;
    textEditStation = TEXTS.EDIT_STATION;
    public translateText = TEXTS.FORECAST;
    dropdownSelectHourId: number[] = [];
    dropdownSelectHour: DropdownSelect[] = [];
    showConfirmationPopup = false;
    editCalculation: UntypedFormGroup;
    errorObj: ErrorModel = {};
    isLoading = false;
    public lastKeyError: string[] = [];
    public ngDestroyed$ = new Subject<void>();

    constructor(
        private fb: UntypedFormBuilder,
        private runConfigService: RunConfigService,
        private popupProvider: OffPanelPopupService,
        private store: Store
    ) {}

    ngOnInit() {
        this.hours = this.runConfig?.schedule_period / 60;
        this.store
            .select(selectRunConfigError)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((value) => {
                if (value) {
                    const data = value.error?.error;
                    for (const key in data) {
                        if (data.hasOwnProperty(key) && this.editCalculation?.controls[key]) {
                            this.editCalculation.controls[key].setErrors({
                                incorrect: true,
                                message: data[key].join(', '),
                            });
                            this.lastKeyError.push(key);
                        } else if (data.hasOwnProperty('detail')) {
                            this.store.dispatch(
                                showInfo({
                                    message: `${data.detail}. ${this.translateText.tryAgain}.`,
                                    positionX: 'left',
                                    positionY: 'bottom',
                                    iconClass: 'error',
                                    duration: 10000,
                                    showCloseIcon: true,
                                    size: 'lg',
                                })
                            );
                            this.isLoading = false;
                            setTimeout(() => {
                                this.store.dispatch(setControlPointError({ payload: null }));
                            }, 2500);
                        }
                    }
                }
                this.isLoading = false;
            });
        this.editCalculation = this.fb.group({
            name: [this.runConfig.name, [Validators.required, Validators.maxLength(60)]],
        });

        this.popupProvider.setTemplate(this.popupOutlet, () => {
            this.openPopup();
            return true;
        });

        for (let i = 1; i < 25; i++) {
            this.dropdownSelectHour.push({ id: i, name: i });
            this.dropdownSelectHourId.push(i);
        }
    }

    get name() {
        return this.editCalculation.get('name');
    }

    onSubmit = async () => {
        this.isLoading = true;
        const minutes = this.hours * 60;
        this.runConfigService
            .update({
                id: this.runConfig.id,
                name: this.editCalculation.value.name,
                schedule_period: minutes,
            })
            .subscribe((data) => {
                this.isLoading = false;
                this.backOnCalculationResults();
            });
    };

    openPopup() {
        this.showConfirmationPopup = true;
    }

    onClosePopup = () => {
        this.showConfirmationPopup = false;
    };

    onSavePopup = () => {
        this.showConfirmationPopup = false;
    };

    backOnCalculationResults = () => {
        this.cbCloseEditMode.emit();
    };

    selectHour = (id) => {
        this.dropdownSelectHour.map((d) => {
            if (d.id === id) {
                this.hours = d.name;
                this.editCalculation.get('name').markAsDirty();
            }
        });
    };

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
        this.ngDestroyed$.next();
    }

    declOfNum = declOfNum;

    public getError(field: string): string {
        if (
            this.editCalculation &&
            this.editCalculation.controls[field].invalid &&
            (this.editCalculation.controls[field].dirty ||
                this.editCalculation.controls[field].touched)
        ) {
            if (this.editCalculation.controls[field].errors.required) {
                return this.textPlumes.nameRequiredError;
            }
            if (this.editCalculation.controls[field].errors.maxlength) {
                return this.textPlumes.maxLength(
                    this.editCalculation.controls[field].errors.maxlength.requiredLength
                );
            }
        }

        return '';
    }
}

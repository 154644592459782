import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { AnalyticChartTitleModule } from '@cityair/components/analytic-charts/analytic-chart-title/analytic-chart-title.module';
import { DoughnutChartComponent } from './doughnut-chart.component';

@NgModule({
    imports: [CommonModule, NgChartsModule, AnalyticChartTitleModule],
    declarations: [DoughnutChartComponent],
    exports: [DoughnutChartComponent],
})
export class DoughnutChartModule {}

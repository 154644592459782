import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { AQI } from '@libs/common/consts/substance.consts';
import { AqiType } from '@libs/common/enums/aqi.type';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'chart-checkbox-aqi',
    templateUrl: './chart-checkbox-aqi.component.html',
    styleUrls: ['./chart-checkbox-aqi.component.less'],
})
export class ChartCheckboxAqiComponent {
    @Input() mmt: string;
    @Input() unit: string;
    @Input() aqiTooltip: TemplateRef<any>;
    @Input() isSelected: boolean;
    @Input() mmtInfoIcon?: {
        name: string;
        cb: () => void;
    };
    @Output() selectMmt = new EventEmitter<void>();
    @Output() toggleMmt = new EventEmitter<void>();

    TEXTS = TEXTS;
    AqiType = AqiType;
    AQI = AQI;
}

import { Component } from '@angular/core';

import { isRU, TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'ca-legal-notice',
    templateUrl: 'legal-notice.component.html',
    styleUrls: ['legal-notice.component.less'],
})
export class LegalNoticeComponent {
    TEXTS = TEXTS;
    currentLanguage = isRU ? 'ru' : 'en';
}

import { Component, Input } from '@angular/core';

import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import { TooltipsService } from '../../tooltips.service';

@Component({
    selector: 'ca-tooltip',
    templateUrl: 'tooltip.component.html',
    styleUrls: ['tooltip.component.less'],
    animations: ANIMATION_OPACITY,
})
export class TooltipComponent {
    @Input() text: string;
    @Input() pointerColor = '#6C7484';

    constructor(readonly tooltipsService: TooltipsService) {}
}

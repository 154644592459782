import { MeasureScheme } from '@libs/common/enums/measure-scheme';

export interface ControlPoint {
    name: string;
    lat: number;
    lon: number;
    id?: number;
    group_id?: number;
    obj?: string;
    timeline?: Timeline;
}

export interface Timeseries {
    T?: number[];
    P?: number[];
    RH?: number[];
    PM25?: number[];
    PM10?: number[];
    CO?: number[];
    NO2?: number[];
    SO2?: number[];
    O3?: number[];
    H2S?: number[];
    NH3?: number[];
    SUM?: number[];
}
export interface ControlPointMeasurements {
    control_point: number;
    obj: string;
    timeseries: Timeseries;
}
export interface ControlPointsResponse {
    meta: {
        dates: string[];
        height?: number;
        species_list: string[];
        start: string;
        end: string;
    };
    control_points_measurements: ControlPointMeasurements[];
}
export interface Timeline {
    date: string[];
    T?: number[];
    P?: number[];
    RH?: number[];
    PM25?: number[];
    PM10?: number[];
    CO?: number[];
    NO2?: number[];
    SO2?: number[];
    O3?: number[];
    H2S?: number[];
    NH3?: number[];
    SUM?: number[];
    obj: string;
    id: number;
}
type VangaSchemeType = 'default' | 'mpc_instantaneous' | 'c_mmhg_mg';
export function getVangaScheme(scheme: MeasureScheme): VangaSchemeType {
    switch (scheme) {
        case MeasureScheme.mpc:
            return 'mpc_instantaneous';
            break;
        case MeasureScheme.c_mmhg_mg:
            return 'c_mmhg_mg';
            break;
        default:
            return 'default';
            break;
    }
}

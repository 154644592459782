<div *ngIf="!editMode" class="plumes-page page-content__wrapper">
    <div class="admin_panel__fixed_block">
        <div class="plumes-page__title">
            <header-page [textObject]="{ titlePage: textsAdminPlumes }"></header-page>
        </div>
        <ca-page-tabs
            class="plumes-page__tabs"
            [tabs]="tabs"
            [selectedTab]="selectedTab"
            (showTab)="showSelectedTab($event)"
        ></ca-page-tabs>
    </div>
    <div class="plumes-page__container" [ngSwitch]="currentPage">
        <plumes-calculation-results
            *ngSwitchCase="plumesPages.CALCULATION_RESULTS"
            [runs]="runs"
            [loadError]="loadError"
            [plumesVisible]="showPlumesLayer$ | async"
        ></plumes-calculation-results>
        <plumes-sources-list
            *ngSwitchCase="plumesPages.SOURCES"
            [loadError]="loadError"
            [isOperator]="isOperator"
            (cbOpenCalculationResultsEdit)="openCalculationResultsEdit($event)"
        ></plumes-sources-list>
        <plumes-control-points
            *ngSwitchCase="plumesPages.CONTROL_POINTS"
            [currentMeasure]="currentMeasure"
            [loadError]="loadError"
            [runs]="runs"
            [(controlPointForEdit)]="controlPointForEdit"
            (cbOpenAddCheckpoint)="openAddCheckpoint()"
            (cbOpenEditCheckpoint)="openEditCheckpoint()"
            [openChartControlPoint]="openChartControlPoint"
            [centringOnMarker]="mapboxActions.centringOnMarker"
        ></plumes-control-points>
    </div>
</div>

<div *ngIf="editMode">
    <div [ngSwitch]="currentEditPage">
        <div *ngSwitchCase="plumesEditPages.ADD_CHECKPIONT">
            <edit-control-point
                (cbCloseEditMode)="closeEditMode()"
                [controlPointForEdit]="controlPointForEdit"
                [title]="titleEditPage"
            ></edit-control-point>
        </div>
        <div *ngSwitchCase="plumesEditPages.EDIT_CALCULATION">
            <plumes-calculation-results-edit
                [runConfig]="currentEditRunConfig"
                (cbCloseEditMode)="closeEditMode()"
            ></plumes-calculation-results-edit>
        </div>
    </div>
</div>
<div class="loading-wrapper" *ngIf="isLoadingData">
    <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
</div>

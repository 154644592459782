import { Component, Input } from '@angular/core';

@Component({
    selector: 'ca-language-item-m',
    templateUrl: 'language-item-m.component.html',
    styleUrls: ['language-item-m.component.less'],
})
export class LanguageItemMobileComponent {
    @Input() code: string;

    @Input() name: string;

    @Input() showFlag = true;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { MapboxMapComponent } from '@cityair/modules/map/components/mapbox/mapbox.component';
import { MapProvidersService } from '@cityair/modules/map/services/map-providers.service';
import { BoundaryMarkerComponent } from '@cityair/modules/map/components/boundary-marker/boundary-marker.component';
import { DomainBorderComponent } from '@cityair/modules/map/components/domain-border/domain-border.component';
import { PlumeSourceMarkerModule } from '@cityair/modules/map/components/plume-source-marker/plume-source-marker.module';
import { ControlPointMarkerModule } from '@cityair/modules/map/components/control-point-marker/control-point-marker.module';
import { CityPinModule } from '@cityair/components/city-pin/city-pin.module';
import { PostPinModule } from '@cityair/components/post-pin/post-pin.module';
import { ControlPointPinModule } from '@cityair/components/control-point-pin/control-point-pin.module';

const MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1IjoiY2l0eWFpciIsImEiOiJjazk4MWhja3IwNHZiM25waDJkeWhheHQ0In0.2cWaHj0iCYNPagxl9GBTuA';

@NgModule({
    imports: [
        CommonModule,
        NgxMapboxGLModule.withConfig({
            accessToken: MAPBOX_ACCESS_TOKEN,
        }),
        PipesModule,
        TooltipsModule,
        CityPinModule,
        PostPinModule,
        ControlPointPinModule,
        PlumeSourceMarkerModule,
        ControlPointMarkerModule,
    ],
    exports: [MapboxMapComponent],
    providers: [MapProvidersService],
    declarations: [MapboxMapComponent, BoundaryMarkerComponent, DomainBorderComponent],
})
export class MapModule {}

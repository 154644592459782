<div class="global-map-wrap">
    <mgl-map
        class="mapbox-map"
        movingMethod="jumpTo"
        [style]="mapStyle"
        [style.visibility]="isVisible ? 'visible' : 'hidden'"
        [zoom]="[mapSettings?.zoom]"
        [minZoom]="mapSettings?.minZoom"
        [center]="mapSettings?.center"
        [dragRotate]="false"
        [renderWorldCopies]="true"
        [attributionControl]="false"
        (mapClick)="clicked($event)"
        (mapLoad)="mapboxLoad($event)"
        (zoomEvt)="onChangePosition$.next()"
    >
        <mgl-control
            mglAttribution
            [compact]="true"
            [customAttribution]="'© CityAir'"
        ></mgl-control>
        <ng-container>
            <mgl-marker
                *ngFor="let marker of objs"
                [className]="
                    [
                        'mapboxgl-marker',
                        'mapboxgl-marker-anchor-center',
                        'map__mapbox-marker global',
                        marker.properties.obj === 'city' ? 'map__mapbox-marker global-city' : '',
                        marker.is_selected ? 'map__mapbox-marker selected' : ''
                    ].join(' ')
                "
                [lngLat]="marker.geometry.coordinates"
            >
                <div *ngIf="marker.properties.obj === 'city' && currentZoom <= zoomShowStation">
                    <city-pin
                        [city]="marker.properties.name"
                        [value]="marker.properties.timeseries.AQI[0]"
                        [selected]="marker.is_selected"
                        [color]="marker.getColor(marker.properties.timeseries.AQI[0], colors)"
                        (click)="selectObj(marker)"
                    ></city-pin>
                </div>
                <div *ngIf="marker.properties.obj === 'source' && currentZoom > zoomShowStation">
                    <marker-icon
                        [aqi]="marker.properties.timeseries.AQI[0]"
                        [canNotify]="false"
                        [canSelected]="false"
                        [selected]="marker.is_selected"
                        (click)="selectObj(marker)"
                        [opacity]="1"
                    ></marker-icon>
                </div>
            </mgl-marker>
            <mgl-marker
                *ngIf="indoorMarker.properties.obj === 'indoor' && currentZoom > zoomShowStation"
                [lngLat]="indoorMarker.geometry.coordinates"
            >
                <indoor-marker
                    (click)="selectIndoorMarker(indoorMarker)"
                    [name]="indoorMarker.properties.name"
                    [selected]="indoorMarker.is_selected"
                ></indoor-marker>
            </mgl-marker>
        </ng-container>
    </mgl-map>
</div>

<div class="timeline-player">
    <div class="timeline-container__wrapper">
        <div class="timeline-container">
            <ca-chart-timeline
                [aqiDataProvider]="aqiDataProvider"
                [isCityMode]="isCityMode"
                [aqiName]="aqiName"
                [timeIndex]="timeIndex"
                [data]="pointSeriesData"
                [showCompare]="comparisonEnabled"
                [showGridLines]="true"
                [labelMode]="labelMode"
                [getDigitsAfterDot]="getDigitsAfterDot"
                [initSelectMeasurement]="initSelectMeasurement"
                [pdk]="pdk"
                [aqiTooltipTemplate]="aqiTooltipTemplate"
                [isCompare]="isComparisonModeActive"
                [mmtInfoIcon]="mmtInfoIcon"
                [chartMinMax]="chartMinMax"
                [measureScheme]="measureScheme"
                [groupTooltip]="groupTooltip"
                [hasDataByIndex]="hasDataByIndex"
                [qualityDataMode]="qualityDataMode"
                [dataQuality]="dataQualityTimeline"
                (removeFromComparison)="removeFromComparison.emit($event)"
                (setPosition)="onTrackClick($event)"
                (goToCity)="goCity($event)"
                (sizeUpdate)="charSize$.next($event)"
                (setCompareMode)="setCompareMode.emit()"
            >
                <!-- Project legacy buttons -->
                <ng-content></ng-content>
            </ca-chart-timeline>
            <div class="panel-with-chart">
                <div
                    class="panel-wrapper"
                    [style.width.px]="(charSizeSub$ | async)?.width"
                    [style.marginLeft.px]="(charSizeSub$ | async)?.left"
                >
                    <ca-track-panel
                        #timelineTrack
                        [date]="timeLineDate"
                        [AQI]="timeLineAQI"
                        [index]="timeIndex"
                        [hasDataByIndex]="hasDataByIndex"
                        [chartEnabled]="getChartEnabled()"
                        [showNow]="showNow"
                        [qualityDataMode]="qualityDataMode"
                        [dataQuality]="dataQualityTimeline"
                        [sidebarIsOpened]="sidebarIsOpened"
                        (setPosition)="onTrackClick($event)"
                        (showQualityDataInfo)="setShowQualityDataInfo($event)"
                    ></ca-track-panel>

                    <ca-time-runner
                        class="timeline-player__runner"
                        #timelineRunner
                        [time]="currentTimeStrs"
                        [isLastTime]="showGoTOEnd()"
                        [class.timeline-player__runner--extended]="getChartEnabled()"
                        [style.visibility]="positionPercent >= 0 ? 'visible' : 'hidden'"
                        [style.left.%]="positionPercent"
                        [flagDirection]="flagDirection"
                        [chartEnabled]="getChartEnabled()"
                        (goToEnd)="goToEndTime()"
                        (mousedown)="dragStart($event)"
                        (touchstart)="dragStart($event)"
                    ></ca-time-runner>

                    <ca-button-play
                        class="timeline-player__play-button"
                        *ngIf="showPlayButton && playerState$ | async as playerState"
                        [state]="playerState.playing ? 'pause' : 'play'"
                        [loading]="playerState.loading"
                        [loadingProgress]="playerState.progress"
                        (click)="playPause(playerState.playing, playerState.loading)"
                    ></ca-button-play>
                </div>
            </div>
        </div>
    </div>
</div>

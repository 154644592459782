import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    TemplateRef,
} from '@angular/core';
import { NgLocalization } from '@angular/common';
import { REPORT_STATUS, Reports } from '../../../models';
import { OffPanelPopupService } from '../../../../core/services/off-panel-popup.service';
import { STATUS_COLOR } from '../../../constant';
import { TEXTS, LANGUAGE } from '@libs/common/texts/texts';
const MAX_VISIBLE_POST = 4;

@Component({
    selector: 'report-detail',
    templateUrl: './report-detail.component.html',
    styleUrls: ['./report-detail.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportDetailComponent {
    @Input() report: Reports;
    @Input() posts: { [key: number]: string };

    @Output() download = new EventEmitter<Reports>();
    @Output() clickMenu = new EventEmitter<{ target; positionTop; positionLeft; report }>();
    @Output() editComment = new EventEmitter<Reports>();
    @Output() deleteComment = new EventEmitter<Reports>();

    readonly STATUS = REPORT_STATUS;
    public translateText = TEXTS.REPORTS;
    public postsMonitoringText = TEXTS.POSTS_AND_DEVICES.postsMonitoring;
    public notifyText = TEXTS.NOTIFICATIONS;
    public isLoadingRename = false;
    public showDetail = false;
    public maxVisiblePost = MAX_VISIBLE_POST;
    public hideMorePost = false;
    public isShowComment = false;
    public commentPositionTop = 0;
    public commentPositionLeft = 0;
    public colors = STATUS_COLOR;

    @ViewChild('cardMenu', { static: true }) cardMenu: TemplateRef<HTMLDivElement>;
    @ViewChild('commentTooltip', { static: true }) tooltip: TemplateRef<HTMLDivElement>;
    @ViewChild('comment', { static: true }) commentEl: ElementRef<HTMLDivElement>;

    constructor(
        private ngLocalization: NgLocalization,
        public popupProvider: OffPanelPopupService
    ) {}

    public menuClick({ target, positionTop, positionLeft }) {
        this.clickMenu.emit({
            target: target,
            positionTop: positionTop,
            positionLeft: positionLeft,
            report: this.report,
        });
    }

    public downloadReport(e: Event) {
        e.stopPropagation();
        if (this.report.isDisableDownload) {
            return;
        }
        this.download.emit(this.report);
    }

    public showAllPosts() {
        this.hideMorePost = true;
        this.maxVisiblePost = this.report.params.postIds.length;
    }

    public hidePosts() {
        this.hideMorePost = false;
        this.maxVisiblePost = MAX_VISIBLE_POST;
    }

    public getMoreText(num: number): string {
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        return `${this.translateText.morePostText(num)}${this.notifyText.post[category]}`;
    }

    public showComment(e: Event) {
        e.stopPropagation();
        if (this.isShowComment) {
            this.popupProvider.clear();
            this.isShowComment = false;
        } else {
            this.isShowComment = true;
            const { nativeElement: target } = this.commentEl;
            this.commentPositionTop =
                target.getBoundingClientRect().top - target.offsetTop + window.scrollY;
            this.commentPositionLeft = target.getBoundingClientRect().left + 30;
            this.popupProvider.confirm(() => {});
            this.popupProvider.setTemplate(this.tooltip);
        }
    }

    public editReportFromComment(e: Event): void {
        e.stopPropagation();
        this.popupProvider.clear();
        this.editComment.emit(this.report);
    }

    public deleteComments(e: Event): void {
        e.stopPropagation();
        this.popupProvider.clear();
        this.deleteComment.emit(this.report);
    }
}

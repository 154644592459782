import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TabModel } from '@libs/common/types/tab-model';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { PostInfo } from '@cityair/modules/indoor/services/api';
import { ChartDataset } from 'chart.js';
import { getIndoorColor, indoorZones, MMT_INDOOR } from '@cityair/modules/indoor/config';
import * as moment from 'moment';
import { ComfortConditionSettings } from '@cityair/modules/core/services/group-features/group-features.service';
import { TEXTS } from '@libs/common/texts/texts';
import { isFalseNumber } from '@libs/common/utils/utils';

@Component({
    selector: 'post-info',
    templateUrl: './post-info.component.html',
    styleUrls: ['./post-info.component.less'],
})
export class PostInfoComponent implements OnChanges {
    @Input() postName: string;
    @Input() data: PostInfo;
    @Input() config: ComfortConditionSettings;
    @Output() showPopup = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();
    // @Output() showConfig = new EventEmitter<void>();

    TEXTS = TEXTS;
    MMT_INDOOR = MMT_INDOOR;

    toDay = moment().format('DD MMMM');

    getIndoorColor = getIndoorColor;
    indoorZones = indoorZones;
    MeasureScheme = MeasureScheme;

    percentData: ChartDataset<'line', number[]>[];
    dailyData: ChartDataset<'bar', number[]>[];
    aqiWeek: ChartDataset<'bar', number[]>[];
    aqiMonth: ChartDataset<'bar', number[]>[];
    dailyLabels: Date[];
    hoursWeek: number[];
    hoursMonth: number[];

    showComfortPopup = false;
    showSafetyPopup = false;
    isEmptyData = false;

    tabs = [
        {
            title: 'Last week',
            type: 1,
        },
        {
            title: 'Last month',
            type: 2,
        },
    ];

    selectedTab: TabModel = this.tabs[0];

    showSelectedTab(tab: TabModel) {
        this.selectedTab = tab;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data?.currentValue) {
            this.isEmptyData = this.checkIsEmpty(changes.data.currentValue);
            if (!this.isEmptyData) {
                const _filtered = this.data.PercentInDays.filter(
                    (d) => !isFalseNumber(d.Percent) || !isFalseNumber(d.IndoorAqi)
                );
                _filtered.sort((a, b) => {
                    const _a = new Date(a.Day).getTime();
                    const _b = new Date(b.Day).getTime();

                    if (_a < _b) {
                        return -1;
                    }
                    if (_a > _b) {
                        return 1;
                    }
                    return 0;
                });

                this.percentData = [
                    { data: _filtered.map((a) => a.Percent).filter((v) => !isFalseNumber(v)) },
                ];
                this.dailyData = [
                    { data: _filtered.map((a) => a.IndoorAqi).filter((v) => !isFalseNumber(v)) },
                ];
                this.dailyLabels = _filtered.map((a) => a.Day);

                this.aqiWeek = [{ data: this.data.WeekStat.map((w) => w.IndoorAqi) }];
                this.hoursWeek = this.data.WeekStat.map((w) => w.WorkHour);

                this.aqiMonth = [{ data: this.data.MonthStat.map((w) => w.IndoorAqi) }];
                this.hoursMonth = this.data.MonthStat.map((w) => w.WorkHour);
            }
        }
    }

    private checkIsEmpty(data: PostInfo) {
        if (
            data &&
            data.MonthStat.length === 0 &&
            data.PercentInDays.length === 0 &&
            data.WeekStat.length === 0
        ) {
            return true;
        }
        return false;
    }
}

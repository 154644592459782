import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { LittleComponentsModule } from '@libs/shared-ui/components/little-components/little-components.module';
import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';

import { PopupConfirmComponent } from '@cityair/components/popups/components/popup-confirm/popup-confirm.component';
import { PopupContainerComponent } from '@cityair/components/popups/components/popup-container/popup-container.component';
import { DownloadPopupMoComponent } from '@cityair/components/popups/components/download-popup-mo/download-popup-mo.component';
import { ConfirmUnsavedPopupComponent } from '@cityair/components/popups/components/confirm-unsaved-popup/confirm-unsaved-popup.component';
import { PopupOutletComponent } from '@cityair/components/popups/components/popup-outlet/popup-outlet.component';
import { InputDropdownModule } from '@cityair/components/input-dropdown/input-dropdown.module';
import { SelectboxModule } from '@libs/shared-ui/components/selectbox/selectbox.module';
import { SelectboxRadioModule } from '@cityair/components/selectbox-radio/selectbox-radio.module';
import { InputDateRangeModule } from '@cityair/libs/common/calendar/input-date-range/input-date-range.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { TagsListModule } from '@libs/shared-ui/components/tags-list/tags-list.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { CheckboxModule } from '@libs/shared-ui/components/checkbox/checkbox.module';
import { ButtonModule } from '@libs/shared-ui/components/button/button.module';
import { ButtonsSaveCancelModule } from '@libs/shared-ui/components/buttons-save-cancel/buttons-save-cancel.module';

@NgModule({
    imports: [
        CommonModule,
        CalendarModule,
        DirectivesModule,
        LittleComponentsModule,
        InputDropdownModule,
        SelectboxModule,
        SelectboxRadioModule,
        InputDateRangeModule,
        LoadersModule,
        TagsListModule,
        SwitchItemModule,
        CheckboxModule,
        ButtonModule,
        ButtonsSaveCancelModule,
    ],
    exports: [
        PopupOutletComponent,
        PopupConfirmComponent,
        PopupContainerComponent,
        DownloadPopupMoComponent,
        ConfirmUnsavedPopupComponent,
    ],
    declarations: [
        PopupOutletComponent,
        PopupConfirmComponent,
        PopupContainerComponent,
        DownloadPopupMoComponent,
        ConfirmUnsavedPopupComponent,
    ],
})
export class PopupsModule {}

import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import type { CheckboxItem } from '@libs/common/types/checkbox-item';
import { Store } from '@ngrx/store';
import { selectMeasureScheme } from '@cityair/modules/core/store/selectors';
import { lastValueFrom, take } from 'rxjs';
import { ConfigPage } from '@cityair/modules/config-menu/config.types';
import { environment } from 'environments/environment';
import { TEMP } from '@libs/common/consts/substance.consts';
import { TEXTS, LANGUAGE } from '@libs/common/texts/texts';
import {
    currentSavedLang,
    setSavedMeasureScheme,
    setSavedLang,
} from '@libs/common/utils/local-storage';

@Component({
    selector: 'system-settings',
    templateUrl: 'system-settings.html',
    styleUrls: ['system-settings.less'],
})
export class SystemSettingsComponent implements OnDestroy {
    @Output() back = new EventEmitter();

    isAWS = environment.isAWS;
    TEMP = TEMP;
    MeasureScheme = MeasureScheme;
    selectMeasureScheme = selectMeasureScheme;
    backEmit = ConfigPage.HOME;

    TEXTS = TEXTS;

    _lang = currentSavedLang ? currentSavedLang : LANGUAGE;
    lang: string = this._lang;

    scheme: MeasureScheme = null;
    subscription1;

    constructor(public store: Store) {
        this.subscription1 = store
            .select(selectMeasureScheme)
            .subscribe((scheme) => (this.scheme = scheme));
    }

    tabs = [
        {
            title: TEXTS.mgM,
            type: 1,
            scheme: MeasureScheme.c_mmhg_mg,
        },
        {
            title: TEXTS.ugM,
            type: 2,
            scheme: MeasureScheme.default,
        },
        {
            title: TEXTS.shareOfMPC,
            type: 3,
            scheme: MeasureScheme.mpc,
        },
    ];

    tabsAWS = [
        {
            title: TEXTS.mgM,
            type: 1,
            scheme: MeasureScheme.c_mmhg_mg,
        },
        {
            title: TEXTS.ugM,
            type: 2,
            scheme: MeasureScheme.default,
        },
    ];

    findSelected = () => this.tabs.find((t) => t.scheme === this.scheme);

    findSelectedAWS = () => {
        let _scheme = this.scheme;

        if (_scheme === MeasureScheme.usa_default) _scheme = MeasureScheme.default;

        return this.tabsAWS.find((t) => t.scheme === _scheme);
    };

    onSelectScheme(tab) {
        this.scheme = tab.scheme;
    }

    selectFahrenheit = () => {
        if (this.scheme === MeasureScheme.usa_default) return (this.scheme = MeasureScheme.default);

        if (this.scheme === MeasureScheme.default) return (this.scheme = MeasureScheme.usa_default);
    };

    // ---------------------------

    labelLanguage = TEXTS.CONFIG.language;
    inputTextLanguage = this.lang === 'ru' ? TEXTS.LOGIN_WINDOWS.ru : TEXTS.LOGIN_WINDOWS.en;
    LanguageItems: CheckboxItem[] = [
        {
            id: 0,
            label: TEXTS.LOGIN_WINDOWS.ru,
            selected: this.lang === 'ru',
            icon: 'ru',
        },
        {
            id: 1,
            label: TEXTS.LOGIN_WINDOWS.en,
            selected: this.lang === 'en',
            icon: 'en',
        },
    ];
    showDropdownLanguage = false;

    updateLanguage(listItems: CheckboxItem[]) {
        const _lang = listItems.find((item) => item.selected).label;
        this.inputTextLanguage = _lang;
        if (_lang === TEXTS.LOGIN_WINDOWS.ru) this.lang = 'ru';

        if (_lang === TEXTS.LOGIN_WINDOWS.en) this.lang = 'en';

        this.showDropdownLanguage = false;
    }

    // labelTimeZone = TEXTS.CONFIG.timeZone;
    // inputTextTimeZone = '';
    // showDropdownTimeZone = false;
    //
    // updateTimeZone(listItems: CheckboxItem[]) {
    //     this.inputTextTimeZone = listItems.find(item => item.selected).label;
    //     this.showDropdownTimeZone = false;
    // }

    saveSystemSettings = async () => {
        let needReload = false;
        const _scheme = await lastValueFrom(this.store.select(selectMeasureScheme).pipe(take(1)));

        if (_scheme !== this.scheme) {
            needReload = true;
            setSavedMeasureScheme(this.scheme);
        }

        if (currentSavedLang !== this.lang) {
            needReload = true;
            setSavedLang(this.lang);
        }

        if (needReload) location.reload();
    };

    ngOnDestroy() {
        this.subscription1?.unsubscribe();
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { LittleComponentsModule } from '@libs/shared-ui/components/little-components/little-components.module';

import { ConfigPanelComponent } from './config.component';
import { ConfigMenuComponent } from './menu/config-menu.component';
import { CoefficientComponent } from './coefficient/coefficient.component';
import { ConfigTitleComponent } from './title/config-title.component';
import { BrowserTokensComponent } from './browser-tokens/browser-tokens.component';
import { ApiTokensComponent } from './api-tokens/api-tokens.component';
import { SystemSettingsComponent } from './system-settings/system-settings.component';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { CardModule } from '@cityair/components/card/card.module';
import { SharedComponentsModule } from '@cityair/libs/shared/components/shared-components.module';
import { ButtonAddModule } from '@libs/shared-ui/components/button-add/button-add.module';
import { CardActionsModule } from '@cityair/components/card-actions/card-actions.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { InputDropdownModule } from '@cityair/components/input-dropdown/input-dropdown.module';
import { SelectboxRadioLangModule } from '@cityair/components/selectbox-radio-lang/selectbox-radio-lang.module';
import { BtnAcceptModule } from '@libs/shared-ui/components/btn-accept/btn-accept.module';
import { BtnCancelModule } from '@libs/shared-ui/components/btn-cancel/btn-cancel.module';
import { CheckboxModule } from '@libs/shared-ui/components/checkbox/checkbox.module';
import { TokensService } from '@cityair/modules/core/services/api/tokens';
import { RegionalCoefficientService } from '@cityair/modules/config-menu/regional-coefficient.service';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        PipesModule,
        DirectivesModule,
        LittleComponentsModule,
        TooltipsModule,
        CardModule,
        SharedComponentsModule,
        ButtonAddModule,
        CardActionsModule,
        SwitchItemModule,
        InputDropdownModule,
        SelectboxRadioLangModule,
        BtnAcceptModule,
        BtnCancelModule,
        CheckboxModule,
    ],
    providers: [TokensService, RegionalCoefficientService],
    exports: [ConfigPanelComponent],
    declarations: [
        ConfigMenuComponent,
        CoefficientComponent,
        ConfigPanelComponent,
        ConfigTitleComponent,
        BrowserTokensComponent,
        SystemSettingsComponent,
        ApiTokensComponent,
    ],
})
export class ConfigModule {}

import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { EntitySelectorsFactory, QueryParams } from '@ngrx/data';

import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import {
    selectGroupId,
    selectGroupInfo,
    selectLoadingTimeline,
    selectMeasureScheme,
    selectMeasuresZones,
} from '@cityair/modules/core/store/selectors';
import { getIntervalByRun, Station } from '../services/station/models';
import {
    CONTROL_POINT_ENTITY_STORE_KEY,
    FINISH_DATE_GET_RUNS,
    NEW_CONTROL_POINT_OBJ_TYPE,
    PLUMES_FEATURE_KEY,
    RUN_CONFIG_ENTITY_STORE_KEY,
    RUN_ENTITY_STORE_KEY,
    STATION_ENTITY_STORE_KEY,
} from '../constants';
import { PlumesState } from './reducers';
import { ControlPoint, getVangaScheme } from '../services/control-point/models';
import { RunPlume } from '../services/run/models';
import * as moment from 'moment';

import { pipe } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { isFalseNumber } from '@libs/common/utils/utils';
import { ColorZone } from '@libs/common/types/color-zone';
import { TilesUpdateParams } from '@cityair/modules/plumes/services/plumes-tiles-player/plumes-tiles-player';
// Station
export const stationSelectors = new EntitySelectorsFactory().create<Station>(
    STATION_ENTITY_STORE_KEY
);
export const getStations = createSelector(stationSelectors.selectEntities, (entities) => entities);
export const stationLoaded = createSelector(stationSelectors.selectLoaded, (entities) => entities);
export const getStationsValues = (post: Station) =>
    createSelector(currentPlumesMmt, selectCurrentIndexPlumes, (mmt, index) => {
        if (!isFalseNumber(index) && mmt && post) {
            const arr = post.data?.measurements?.[mmt]?.values;
            return arr ? arr[index] : null;
        }
    });
export const controlPointSelectors = new EntitySelectorsFactory().create<ControlPoint>(
    CONTROL_POINT_ENTITY_STORE_KEY
);

export const getControlPoints = createSelector(
    controlPointSelectors.selectEntityMap,
    (entities) => entities
);

export const selectControlPoints = createSelector(
    controlPointSelectors.selectEntities,
    (entities) => entities
);

export const loadedControlPoints = createSelector(
    controlPointSelectors.selectLoaded,
    (value) => value
);

export const selectControlPointById = (id: number) =>
    createSelector(getControlPoints, loadedControlPoints, (entities, isLoaded) => {
        if (isLoaded) {
            return entities[id] ? entities[id] : null;
        }
    });

export const runSelectors = new EntitySelectorsFactory().create<RunPlume>(RUN_ENTITY_STORE_KEY);
export const selectRunConfig = new EntitySelectorsFactory().create<RunPlume>(
    RUN_CONFIG_ENTITY_STORE_KEY
);

export const selectRunsConfigAll = createSelector(
    selectRunConfig.selectEntities,
    (entities) => entities
);

export const getRuns = createSelector(runSelectors.selectEntities, (entities) => entities);

// State
export const selectPlumesState = createFeatureSelector<PlumesState>(PLUMES_FEATURE_KEY);

export const selectDatesPlumes = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.dates
);

export const selectCurrentIndexPlumes = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.currentTimeIndex
);

export const selectPlumeTime = createSelector(
    selectDatesPlumes,
    selectCurrentIndexPlumes,
    (dates, index) => new Date(dates[index]).getTime()
);
export const selectPlumesTimeRangeWithoutDistinct = createSelector(selectDatesPlumes, (dates) => ({
    begin: new Date(dates[0]).getTime(),
    end: new Date(dates[dates.length - 1]).getTime(),
}));

export const selectPlumesTimeRange = pipe(
    select(selectPlumesTimeRangeWithoutDistinct),
    distinctUntilChanged(
        (prev, current) => prev.begin === current.begin && prev.end === current.end
    )
);

export const currentPlumesMmt = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.currentMmt
);

export const selectPlumesZone = createSelector(
    selectMeasureScheme,
    selectMeasuresZones,
    currentPlumesMmt,
    (scheme, zones, currentMmt) => zones[scheme][currentMmt] as ColorZone
);
export const selectPlumesSchema = createSelector(
    selectMeasureScheme,
    currentPlumesMmt,
    (scheme, currentMmt) => ({
        scheme: scheme,
        mmt: currentMmt,
    })
);
export const selectPlumesSchemaZones = createSelector(
    selectMeasureScheme,
    currentPlumesMmt,
    selectMeasuresZones,
    (scheme, currentMmt, zones) => {
        if (zones && scheme && currentMmt && zones[scheme][currentMmt]) {
            return {
                scheme: scheme,
                mmt: currentMmt,
                zone: zones[scheme][currentMmt] as ColorZone,
            };
        }
        return null;
    }
);
export const isActivePlumes = createSelector(
    selectPlumesState,
    (state: PlumesState) => state?.isActiveModule
);

export const showLayerOnMap = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.showLayerOnMap
);

export const selectControlPointPlumesError = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.controlPointsError
);
export const selectRunConfigError = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.runConfigError
);
export const getControlPointValues = (point: ControlPoint) =>
    createSelector(currentPlumesMmt, selectCurrentIndexPlumes, (mmt, index) => {
        if (!isFalseNumber(index) && mmt && point) {
            const arr = point.timeline?.[mmt];
            return arr ? arr[index] : null;
        }
    });
export const selectActiveStation = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.activeStation
);
export const selectMapNavigation = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.disabledMapNavigation
);
export const selectActiveRun = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.activeRun
);
export const selectActiveRunDates = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.activeRunDates
);
export const selectActiveControlPoint = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.activeControlPoint
);
export const selectActiveControlPointAsList = createSelector(selectActiveControlPoint, (active) =>
    active ? [active.id] : []
);
export const selectActiveStationAsList = createSelector(selectActiveStation, (active) =>
    active ? [active.id] : []
);

export const selectActiveRunSources = createSelector(selectActiveRun, (run) => {
    if (run) {
        return run.sources_snapshot;
    }
});
export const selectDateRangeRuns = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.dateRangeRuns
);

export const selectControlPointReports = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.controlPointReports
);
export const selectEditControlPoint = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.editControlPoint
);
export const selectNewCoordinates = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.coordinates
);
export const selectChartDataLoading = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.isLoadingChart || state.isLoadingRun
);
export const selectPlumesHeights = createSelector(
    selectActiveRun,
    (activeRun) => activeRun?.heights
);
export const selectPlumesCurrentHeight = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.currentHeight
);
export const selectPlumesSpeciesList = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.speciesList
);
export const selectChartData = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.chartData
);
export const selectControlPointsLoading = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.isLoadingControlPoint
);
export const selectRunLoading = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.isLoadingRun
);
export const selectIsShowFullStationData = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.showFullChartDataStation
);
export const selectIsDisableWindButton = createSelector(
    selectPlumesState,
    (state: PlumesState) => state.disableWindButton
);
export const selectLoadingAllData = createSelector(
    selectControlPointsLoading,
    selectRunLoading,
    (isControlPointLoading, runLoading) => isControlPointLoading || runLoading
);
export const selectPlumeTilesParams = createSelector(
    selectDatesPlumes,
    selectCurrentIndexPlumes,
    selectPlumesCurrentHeight,
    currentPlumesMmt,
    (dates, index, height, mmt) => {
        if (dates && dates[index] && height && mmt) {
            const params: TilesUpdateParams = {
                ts: new Date(dates[index]).getTime(),
                height: height,
                substance: mmt,
            };
            return params;
        }
        return null;
    }
);
export const isDraggableControlPoint = (point: ControlPoint) =>
    createSelector(
        selectEditControlPoint,
        selectControlPointReports,
        (controlPoint, controlPoints) => {
            if (controlPoint && point) {
                return controlPoint.id === point.id;
            }
            const newControlPoint = controlPoints.find(
                (item) => item.obj === NEW_CONTROL_POINT_OBJ_TYPE
            );
            if (newControlPoint && point.id === null) {
                return true;
            }
            return false;
        }
    );
export const selectActiveRunTime = createSelector(selectActiveRun, (activeRun) => {
    if (activeRun) {
        return {
            begin: moment(activeRun.evaluation_time)
                .add(activeRun.step_minutes, 'minutes')
                .valueOf(),
            end: moment(activeRun.evaluation_time)
                .add(activeRun.duration_minutes, 'minutes')
                .valueOf(),
        };
    }
});
export const selectCentringRun = createSelector(
    selectActiveRun,
    selectMapNavigation,
    selectLoadingTimeline,
    (activeRun, isDisabledNavigation, loadingTimeline) => {
        if (activeRun && !isDisabledNavigation && !loadingTimeline) {
            const [lng, lat] = activeRun.domain?.centroid?.coordinates;
            return {
                lat,
                lng,
                zoom: activeRun.domain?.zoom,
            };
        }
    }
);
export const selectChartLabelMode = createSelector(
    selectActiveStation,
    selectIsShowFullStationData,
    (station, fullDataConfig) => (station && fullDataConfig ? false : true)
);
export const getParams = createSelector(
    selectGroupId,
    selectActiveRun,
    selectMeasureScheme,
    (groupId, activeRun, scheme) => {
        if (groupId && activeRun) {
            const begin = moment(activeRun.evaluation_time)
                .add(activeRun.step_minutes, 'minutes')
                .valueOf();
            const end = moment(activeRun.evaluation_time)
                .add(activeRun.duration_minutes, 'minutes')
                .valueOf();
            const params: QueryParams = {
                group_id: groupId.toString(),
                date__gt: new Date(begin).toISOString(),
                date__lt: new Date(end).toISOString(),
                interval: getIntervalByRun(activeRun.step_minutes),
                measure_scheme: scheme !== MeasureScheme.mpc ? scheme : MeasureScheme.default,
                concentration_in_mpc: (scheme === MeasureScheme.mpc).toString(),
                obj_filter: 'outdoor_post',
            };

            return params;
        }
        return null;
    }
);

export const getParamsStation = createSelector(
    getParams,
    currentPlumesMmt,
    (params, currentMmt) => {
        if (params && currentMmt) {
            return Object.assign({}, params, { packet_type: currentMmt });
        }
        return null;
    }
);
export const getParamsStationById = createSelector(
    getParams,
    selectActiveStation,
    (params, activeStation) => {
        if (params && activeStation) {
            return {
                id: activeStation.id,
                params,
            };
        }
        return null;
    }
);
export const getParamsControlPoints = createSelector(
    selectGroupId,
    selectActiveRun,
    selectPlumesCurrentHeight,
    currentPlumesMmt,
    selectMeasureScheme,
    (groupId, activeRun, height, mmt, scheme) => {
        if (groupId && activeRun && height) {
            const params: QueryParams = {
                measure_scheme: getVangaScheme(scheme),
                h: height.toString(),
                species: mmt,
            };

            return { id: activeRun.id, params };
        }
        return null;
    }
);
export const selectRunsLoadError = createSelector(
    selectPlumesState,
    (state) => state.runsLoadError
);

export const getParamsRun = createSelector(
    selectGroupInfo,
    selectDateRangeRuns,
    (groupInfo, dateRange) => {
        if (groupInfo && groupInfo.groupId && dateRange) {
            const params: QueryParams = {
                group_id: groupInfo.groupId.toString(),
                evaluation_time__gte: new Date(dateRange.startDate).toISOString(),
                evaluation_time__lte: new Date(dateRange.finishDate).toISOString(),
                expand: 'domain',
            };
            return params;
        }

        return null;
    }
);
export const selectActivePointAfterUpdate = createSelector(
    selectControlPointReports,
    selectActiveControlPoint,
    (data, active) => {
        if (data?.length && active) {
            return data.find((item) => item.id === active.id);
        }

        return null;
    }
);
export const isWindLayerAvailable = createSelector(
    selectActiveRun,
    (activeRun) => activeRun?.wind_on
);
export const showWindOnMap = createSelector(selectPlumesState, (state) => state.showWindOnMap);
export const isWindShowOnMap = createSelector(
    showWindOnMap,
    selectActiveRun,
    isActivePlumes,
    selectGroupId,
    (isShow, activeRun, activeModule, groupId) => isShow && activeModule && activeRun?.wind_on
);
export const selectPlumeWindParams = createSelector(
    selectDatesPlumes,
    selectCurrentIndexPlumes,
    isWindShowOnMap,
    selectActiveRun,
    selectGroupId,
    selectPlumesCurrentHeight,
    selectPlumesHeights,
    (dates, index, isActive, run, groupId, currentHeight, heights) => {
        if (dates && dates[index] && isActive && run && groupId) {
            const hour = moment(dates[index]).utcOffset(0);

            if (hour.get('minutes') > 30) hour.add(1, 'hours');

            const splitDate = dates[index].split('T');
            const date = splitDate[0].replace(/-/g, '');

            if (run.wind_on) {
                const begin = moment(run.evaluation_time)
                    .add(run.step_minutes, 'minutes')
                    .valueOf();
                const end = moment(run.evaluation_time)
                    .add(run.duration_minutes, 'minutes')
                    .valueOf();
                const currentTime = moment(dates[index]).valueOf();

                if (
                    currentTime <= end &&
                    currentTime >= begin &&
                    run.wind_domain &&
                    currentHeight === heights[0]
                ) {
                    const filename = `${date}_${hour.format('HH')}0000`;
                    return {
                        url: `v1/r/${groupId}/plumes/${run.id}/raster/custom/${currentHeight}/wind/${filename}`,
                        bboxDomain: run.wind_domain,
                    };
                }
                return null;
            }
            return null;
        }

        return null;
    }
);
export const allowUpdateRuns = createSelector(
    selectDateRangeRuns,
    (dateRange) => dateRange.finishDate === FINISH_DATE_GET_RUNS
);
export const selectQualityDataTimeline = createSelector(
    selectPlumesState,
    (state: PlumesState) => state?.qualityDataTimeline
);

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartArea, ChartConfiguration, ChartDataset } from 'chart.js';
import { TEXTS } from '@libs/common/texts/texts';
import { BarChartData } from './namespace';
import * as moment from 'moment';
import { chartDateFormater } from '@cityair/modules/indoor/config';

function getGradient(ctx: CanvasRenderingContext2D, chartArea: ChartArea) {
    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, '#8663E1');
    gradient.addColorStop(0.6, '#8663E1');
    gradient.addColorStop(1, '#2CCE54');
    return gradient;
}

@Component({
    selector: 'percent-chart',
    templateUrl: './percent-chart.component.html',
    styleUrls: ['./percent-chart.component.less'],
})
export class PercentChartComponent implements OnInit, OnChanges {
    @Input() data: ChartDataset<'line', number[]>[];
    @Input() labels: Date[];

    TEXTS = TEXTS;

    chartOptions: BarChartData = null;

    constructor() {}

    ngOnInit(): void {
        if (this.data) {
            this.chartOptions = this.getOptionsByName(this.data);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data?.currentValue) {
            this.chartOptions = this.getOptionsByName(this.data);
        }
    }

    getOptionsByName(data: ChartDataset<'line', number[]>[]): BarChartData {
        return {
            chart: {
                datasets: data.map((d) => {
                    console.log();

                    return {
                        ...d,
                        radius: 0,
                        borderColor: function (context) {
                            const chart = context.chart;
                            const { ctx, chartArea } = chart;
                            // This case happens on initial chart load
                            if (!chartArea) {
                                return null;
                            }

                            return getGradient(ctx, chartArea);
                        },
                    };
                }),
                labels: this.labels,
                options: this.getChartOptions(),
                type: 'line',
                plugins: [],
            },
        };
    }

    private getChartOptions(): ChartConfiguration['options'] {
        const _chartDateFormat = chartDateFormater();

        return {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    ticks: {
                        color: '#95A0B3',
                        font: {
                            family: 'Inter',
                            weight: '500',
                            size: 11,
                        },
                        maxRotation: 0,
                        callback: (val) => _chartDateFormat(moment(this.labels[val])),
                    },
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                },
                y: {
                    ticks: {
                        color: '#95A0B3',
                        font: {
                            family: 'Inter',
                            weight: '600',
                            size: 10,
                        },
                        precision: 0,
                        callback: (val) => val + '%',
                    },
                    grid: {
                        display: true,
                        color: '#E6ECF2',
                        borderDash: [3],
                        drawBorder: false,
                    },
                },
            },
            plugins: {
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    intersect: false,
                    borderWidth: 1,
                    displayColors: false,
                    xAlign: 'center',
                    yAlign: 'top',
                    callbacks: {
                        title: (tooltipItems) => moment(tooltipItems[0].label).format('DD MMM'),
                        label: (tooltipItem) => `${tooltipItem.formattedValue}%`,
                    },
                },
            },
        };
    }
}

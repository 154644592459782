import { Component, EventEmitter, Input, NgZone, OnDestroy, Output } from '@angular/core';
import {
    AnyLayer,
    EventData,
    LngLatLike,
    Map,
    MapboxEvent,
    RasterSource,
    ResourceType,
    Style,
} from 'mapbox-gl';
import {
    combineLatestWith,
    EMPTY,
    firstValueFrom,
    from,
    fromEvent,
    iif,
    lastValueFrom,
    Observable,
    of,
    Subject,
} from 'rxjs';
import {
    distinctUntilChanged,
    distinctUntilKeyChanged,
    filter,
    finalize,
    first,
    map,
    pluck,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { CITY_ZOOM_SHOW_HEXAGON, RESIZE_TIMEOUT } from '@cityair/config';
import { MapCenterAndZoom, MapControlPins, MapPins, WindowGlobalVars } from '@cityair/namespace';
import {
    createBoundaries,
    createTimeSequence,
    createTimeSequencePlumes,
    detectTouchDevice,
    getColorFromZone,
} from '@cityair/utils/utils';
import { TEXTS } from '@libs/common/texts/texts';

import { environment } from 'environments/environment';
import { RunPlume, Source, windLayerParams } from '@cityair/modules/plumes/services/run/models';
import {
    DEFAULT_MAP_STYLE,
    ForecastConfig,
    GroupExtConfigName,
    GroupFeaturesService,
    GroupMapSettings,
    GroupTilePlayerSettings,
} from '@cityair/modules/core/services/group-features/group-features.service';
import { VangaAuthService } from '@cityair/modules/core/services/vanga-auth/vanga-auth.service';
import {
    mapLoaded,
    refreshVangaToken,
    setMapClickState,
} from '@cityair/modules/core/store/actions';
import {
    getCurrentGroup,
    getMarkerState,
    selectCitiesMarker,
    selectCurrentTime,
    selectGroupInfo,
    selectIsCityMode,
    selectMapClickState,
    selectMapLoaded,
    selectTimeRange,
    selectTzMinutesOffset,
    selectVangaTokenStatus,
} from '@cityair/modules/core/store/selectors';
import { TIMELINE_STEP } from '@cityair/libs/shared/utils/config';
import { ForecastControlService } from '@cityair/modules/map/services/forecast-control.service';
import {
    currentForecastMmt,
    isValidToken,
    selectForecastCurrentTime,
    selectForecasts,
    selectForecastTimeRange,
    showLayerOnMap as showForecastLayerOnMap,
} from '@cityair/modules/forecast/store/selectors';
import {
    isActivePlumes,
    isWindShowOnMap,
    selectActiveRunDates,
    selectActiveRunSources,
    selectPlumesTimeRange,
    selectPlumeTilesParams,
    selectPlumeWindParams,
    showLayerOnMap as showPlumesLayerOnMap,
} from '@cityair/modules/plumes/store/selectors';

import { DomainTilesPlayer } from './domain-tiles-player/domain-tiles-player';
import { Substance } from './domain-tiles-player/substance.enum';
import { IAuthorizeHelper } from './domain-tiles-player/domain-config.type';
import { DOMAINS_FORECASTS } from './domain-forecasts.settings';
import { MapboxFacadeService } from './mapbox-facade.service';
import { TilePlayer } from './tile-player';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { markerState } from '@libs/common/enums/marker-state.enum';
import { PM25, PM10, NO2, SO2 } from '@libs/common/consts/substance.consts';
import { selectPlayer } from '@libs/shared-ui/components/timeline-panel/store/selectors/core.selectors';
import {
    playerReady,
    playerSetManaged,
    playerSetProgress,
} from '@libs/shared-ui/components/timeline-panel/store/core.actions';
import { PlumesTilesPlayer } from '@cityair/modules/plumes/services/plumes-tiles-player/plumes-tiles-player';
import { loadWindData, WindVector } from './windVector';
import MapboxActions from './mapboxActions';
import { GroupInfo } from '@cityair/libs/common/api/adminPanel/dataTransformer';
import { selectCurrentCity } from '@cityair/modules/core/store/current-city/current-city.feature';
declare let window: WindowGlobalVars;

const EMPTY_MAP_STYLE = {
    version: 8,
    name: 'Empty',
    center: [0, 0],
    zoom: 0,
    sources: {},
    layers: [],
};

const SUBSTANCES_MAP: Record<string, Substance> = {
    [PM25]: Substance.PM25,
    [PM10]: Substance.PM10,
    [NO2]: Substance.NO2,
    [SO2]: Substance.SO2,
};

const PUBLIC_FORECASTS_BUCKET_URL = `${environment.tile_server_url}/v1/public/forecast`;
const RESTRICTED_BUCKET_URL = `${environment.tile_server_url}/v1/r`;

export type ExtraLayer = {
    id: string;
    source: RasterSource;
    accessToken?: string;
};

@Component({
    selector: 'mapbox-map',
    templateUrl: 'mapbox.component.html',
    styleUrls: ['mapbox.component.less'],
})
export class MapboxMapComponent implements OnDestroy {
    @Input() zoom?: number;
    @Input() center?: LngLatLike;

    @Input() postPins?: MapPins;
    @Input() cityPins?: MapPins;
    @Input() notificationSelectedPins?: MapPins;
    @Input() controlPointPins?: MapControlPins;

    @Input() groupFeaturesLayer?: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
    @Input() pinsAreaData?: Observable<GeoJSON.FeatureCollection<GeoJSON.LineString>>;

    @Output() mapDragEnd = new EventEmitter<MapCenterAndZoom>();
    @Output() zoomChanged = new EventEmitter<number>();

    isCityMode$ = this.store.select(selectIsCityMode);
    groupInfo$ = this.store.select(selectGroupInfo);

    getMarkerState = (id: number) => this.store.select(getMarkerState(id));

    onDestroy$ = new Subject<void>();

    isTouchDevice: boolean;

    mapSettings: GroupMapSettings = {};

    markerState = markerState;
    GroupExtConfigName = GroupExtConfigName;
    showMarkersArea = false;

    TEXTS = TEXTS;
    MAIN_PAGES = MAIN_PAGES;
    getColorFromZone = getColorFromZone;

    timeStep = TIMELINE_STEP;

    resizeFn: () => void;

    showMap = false;

    pinTooltipText = '';

    tilePlayers: {
        [layerId: string]: TilePlayer;
    } = {};

    runSources$: Observable<Source[]>;
    plumesAvailable: Observable<boolean>;

    showForecastLayer$: Observable<boolean>;
    showPlumesLayer$: Observable<boolean>;

    domainTilesPlayer: DomainTilesPlayer;
    domainTilesPlumesPlayer: DomainTilesPlayer;
    plumesTilesPlayer: PlumesTilesPlayer;
    domainTilesForecastPlayer: DomainTilesPlayer;
    private isAllowClick = false;

    availableExtraLayers: ExtraLayer[] = [];
    enabledExtraLayers: ExtraLayer[] = [];

    private resizeMapTimeout: NodeJS.Timer;
    public windVector;
    public windLayer: AnyLayer;
    public isWindShowOnMap: boolean;
    constructor(
        readonly forecastControlService: ForecastControlService,
        private mapboxFacadeService: MapboxFacadeService,
        private groupFeaturesService: GroupFeaturesService,
        private vangaAuthService: VangaAuthService,
        public store: Store,
        private ngZone: NgZone,
        readonly mapboxActions: MapboxActions
    ) {
        this.runSources$ = this.store.select(selectActiveRunSources);
        this.plumesAvailable = this.store.select(isActivePlumes);
        // catch resize event -> stop wind animation and update wind layer
        fromEvent(window, 'resize')
            .pipe(
                takeUntil(this.onDestroy$),
                filter(() => this.map && this.isWindShowOnMap && this.windVector),
                tap(() => this.windVector?.stopAnimation())
            )
            .subscribe((data) => {
                clearTimeout(this.resizeMapTimeout);
                this.resizeMapTimeout = setTimeout(() => this.updateWindLayer(), 100);
            });

        this.store
            .select(selectMapLoaded)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((v) => v),
                switchMap(() => this.store.select(selectGroupInfo)),
                filter((groupInfo) => !!groupInfo?.groupId),
                take(1)
            )
            .subscribe(() => {
                this.enableMap();
            });

        this.mapboxFacadeService.stylesReady$
            .pipe(
                takeUntil(this.onDestroy$),
                filter((isReady) => isReady)
            )
            .subscribe(async () => {
                await this.createTilePlayers();
                this.createPublicForecastImagePlayer();
                this.ngZone.run(() => {
                    this.showMap = true;
                });
            });

        this.isTouchDevice = detectTouchDevice();

        store
            .select(selectMapClickState)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((state) => (this.isAllowClick = state?.isAllow));
        store
            .select(isWindShowOnMap)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                this.isWindShowOnMap = data;
                if (this.map) {
                    const mapLayer = this.map?.getLayer('wind');
                    if (typeof mapLayer !== undefined && !data) {
                        this.map.removeLayer('wind');
                    } else if (data && this.windLayer) {
                        this.map.addLayer(this.windLayer, 'building');
                    }
                }
            });
        store
            .select(selectPlumeWindParams)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((v) => !!v),
                distinctUntilKeyChanged('url')
            )
            .subscribe((data) => this.getWindData(data));
        this.setupForecastTilePlayer();

        this.setupPlumesTilePlayer();
    }

    public mapDragEndHandler($event: MapboxEvent<MouseEvent | TouchEvent> & EventData) {
        this.store
            .select(selectIsCityMode)
            .pipe(first())
            .subscribe((isCityMode) => {
                this.mapDragEnd.emit({
                    center: $event.target.getCenter(),
                    zoom: $event.target.getZoom(),
                    isCityMode,
                });
            });
    }

    public moveStart() {
        this.windVector?.stopAnimation();
    }

    public moveEnd() {
        this.windVector?.startAnimation();
    }

    setupForecastTilePlayer() {
        this.store
            .select(selectPlayer)
            .pipe(
                takeUntil(this.onDestroy$),
                pluck('loading'),
                distinctUntilChanged(),
                switchMap((loading) => iif(() => loading, this.showForecastLayer$, EMPTY)),
                filter((showLayerOnMap) => showLayerOnMap),
                switchMap(() => this.store.pipe(selectForecastTimeRange, take(1)))
            )
            .subscribe(({ begin, end }) => {
                const timeSequence = createTimeSequence(begin, end);
                this.preloadLayerImagesNew(this.domainTilesForecastPlayer, timeSequence);
            });

        this.showForecastLayer$ = this.store.select(showForecastLayerOnMap);

        this.showForecastLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                filter((isEnabled) => isEnabled),
                switchMap(() => this.mapboxFacadeService.stylesReady$),
                filter((isReady) => isReady),
                switchMap(() => this.store.select(selectForecasts)),
                filter((forecast) => forecast.length !== 0),
                switchMap(() => this.store.select(isValidToken)),
                filter((isValid) => isValid)
            )
            .subscribe(() => {
                this.ngZone.run(() => {
                    this.createForecastImagePlayer();
                    this.store.dispatch(playerSetManaged({ payload: true }));
                });
            });

        this.showForecastLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                distinctUntilChanged(),
                filter((isEnabled) => !isEnabled)
            )
            .subscribe(() => {
                this.store.dispatch(playerSetManaged({ payload: false }));
            });
    }

    setupPlumesTilePlayer() {
        this.showPlumesLayer$ = this.store.select(showPlumesLayerOnMap);

        this.store
            .select(selectPlayer)
            .pipe(
                takeUntil(this.onDestroy$),
                pluck('loading'),
                distinctUntilChanged(),
                switchMap((loading) => iif(() => loading, this.showPlumesLayer$, EMPTY)),
                filter((showLayerOnMap) => showLayerOnMap),
                switchMap(() => this.store.pipe(selectPlumesTimeRange, take(1)))
            )
            .subscribe(({ begin, end }) => {
                const timeSequence = createTimeSequencePlumes(begin, end);
                this.preloadLayerImagesNew(this.plumesTilesPlayer, timeSequence);
            });

        this.showPlumesLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                filter((isEnabled) => isEnabled),
                switchMap(() => this.mapboxFacadeService.stylesReady$),
                filter((isReady) => isReady),
                switchMap(() => this.store.select(selectActiveRunDates)),
                filter((run) => !!run),
                distinctUntilKeyChanged('id')
            )
            .subscribe((run) => {
                this.createPlumesPlayer(run);
                this.store.dispatch(playerSetManaged({ payload: true }));
            });

        this.showPlumesLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                distinctUntilChanged(),
                filter((isEnabled) => !isEnabled)
            )
            .subscribe(() => {
                this.store.dispatch(playerSetManaged({ payload: false }));
            });
    }

    async preloadLayerImagesNew(
        domainTilesPlayer: DomainTilesPlayer | PlumesTilesPlayer,
        timeSequence: Set<number>
    ) {
        await domainTilesPlayer.preloadImages(timeSequence, (percent: number) =>
            this.store.dispatch(playerSetProgress({ progress: percent / 100 }))
        );

        this.store.dispatch(playerReady());
    }

    enableMap() {
        this.clearMapLayers();
        this.setMapConfig();
    }

    isForecastLayerVisible(isCityMode: boolean, groupInfo: GroupInfo) {
        return groupInfo?.allowForecast && isCityMode && this.domainTilesPlayer;
    }

    tilesAuthorizerHelper: IAuthorizeHelper = {
        getAuthHeader: () => ({
            Authorization: `Bearer ${this.vangaAuthService.getAccessToken()}`,
        }),
        refreshToken: async () => {
            this.store.dispatch(refreshVangaToken());

            const vangaTokenIsReady = this.store.select(selectVangaTokenStatus).pipe(
                filter((isLoading) => !isLoading),
                take(1)
            );

            await lastValueFrom(vangaTokenIsReady);
        },
    };

    private createPublicForecastImagePlayer() {
        this.store
            .select(selectCurrentCity)
            .pipe(
                takeUntil(this.onDestroy$),
                map((city) => city?.locationId),
                finalize(() => this.domainTilesPlayer?.destroy()),
                distinctUntilChanged(),
                combineLatestWith(this.store.select(selectCitiesMarker)),
                map(([id, markers]) => {
                    const cityId = markers.find((city) => city.id === id)?.cityId?.toLowerCase();
                    return DOMAINS_FORECASTS[cityId];
                }),
                filter((domain) => !!domain),
                tap((domain) => {
                    this.domainTilesPlayer?.destroy();

                    this.domainTilesPlayer = new DomainTilesPlayer(
                        'forecast',
                        {
                            url: PUBLIC_FORECASTS_BUCKET_URL,
                            domain,
                        },
                        this.store.pipe(selectCurrentTime, pluck('current')),
                        this.store.pipe(selectTimeRange),
                        this.forecastControlService.isEnabled$
                    );
                }),
                switchMap(() => this.forecastControlService.props$),
                filter((props) => !!props),
                map((props) => SUBSTANCES_MAP[props.measure])
            )
            .subscribe((substance: Substance) => {
                this.domainTilesPlayer.selectSubstance(substance);
            });
    }

    private createForecastImagePlayer() {
        // TODO: should request available domains
        const config = this.groupFeaturesService.getConfig(
            GroupExtConfigName.forecastConfig
        ) as ForecastConfig;
        const locationId = config?.domain;

        if (locationId) {
            this.store
                .select(getCurrentGroup)
                .pipe(
                    map((currentGroup) => ({
                        currentGroup,
                        domain: DOMAINS_FORECASTS[locationId],
                    })),
                    tap((data) => {
                        this.domainTilesForecastPlayer?.destroy();

                        this.domainTilesForecastPlayer = new DomainTilesPlayer(
                            'forecast',
                            {
                                url: RESTRICTED_BUCKET_URL,
                                domain: {
                                    ...data.domain,
                                    slug: [data.currentGroup.id, 'forecast', data.domain.slug].join(
                                        '/'
                                    ),
                                },
                            },
                            this.store.pipe(
                                selectForecastCurrentTime,
                                filter((v) => !!v)
                            ),
                            this.store.pipe(selectTimeRange),
                            this.showForecastLayer$,
                            this.tilesAuthorizerHelper
                        );
                    }),
                    switchMap(() => this.store.select(currentForecastMmt)),
                    filter((mmt) => !!mmt)
                )
                .subscribe((substance: Substance) => {
                    this.domainTilesForecastPlayer.selectSubstance(substance);
                });
        }
    }

    private createPlumesPlayer(run: RunPlume) {
        this.store
            .select(getCurrentGroup)
            .pipe(first())
            .subscribe((currentGroup) => {
                this.plumesTilesPlayer?.destroy();
                this.plumesTilesPlayer = new PlumesTilesPlayer(
                    'plumes',
                    {
                        url: RESTRICTED_BUCKET_URL,
                        domain: {
                            slug: [currentGroup.id, 'plumes', run.id].join('/'),
                            substances: [],
                            coordinates: createBoundaries(run.domain.bbox),
                        },
                        timeStep: run?.step_minutes,
                    },
                    this.store.select(selectPlumeTilesParams).pipe(filter((params) => !!params)),
                    this.store.pipe(selectPlumesTimeRange),
                    this.showPlumesLayer$,
                    this.tilesAuthorizerHelper
                );
            });
    }

    private async _createTilePlayer(
        settings: GroupTilePlayerSettings,
        tzOverride?: number,
        timeDelayMs: number = 0
    ) {
        const regenerateTilePlayer = ({ begin, end, tzOffset }) => {
            const { layerId } = settings;

            this.tilePlayers[layerId]?.destroy();

            this.tilePlayers[layerId] = new TilePlayer(
                this.store.pipe(
                    selectCurrentTime,
                    pluck('current'),
                    map((ts) => ts - timeDelayMs)
                ),
                [begin, end].map((d) => new Date(d)),
                tzOffset,
                settings
            );
        };

        const getTimelineRange = async () => {
            const tzOffset =
                tzOverride ?? (await firstValueFrom(this.store.select(selectTzMinutesOffset)));

            const time = await firstValueFrom(this.store.pipe(selectTimeRange));

            return {
                begin: time.begin - timeDelayMs,
                end: time.end - timeDelayMs,
                tzOffset,
            };
        };

        let range = await getTimelineRange();

        regenerateTilePlayer(range);

        this.store.pipe(selectCurrentTime, takeUntil(this.onDestroy$)).subscribe(async () => {
            const timelineRange = await getTimelineRange();

            if (
                range.begin !== timelineRange.begin ||
                range.end !== timelineRange.end ||
                range.tzOffset !== timelineRange.tzOffset
            ) {
                range = timelineRange;
                regenerateTilePlayer(range);
            }
        });
    }

    private async createTilePlayers() {
        const tpSettings = this.groupFeaturesService.getConfig(
            GroupExtConfigName.tilePlayerSettings
        ) as GroupTilePlayerSettings;

        if (tpSettings) {
            tpSettings.layerId = 'default';
            await this._createTilePlayer(tpSettings);
        }
    }

    setMapConfig() {
        this.mapSettings = this.groupFeaturesService.getConfig(
            GroupExtConfigName.mapSettings
        ) as GroupMapSettings;

        const { tiles, tileSize, minzoom, maxzoom, accessToken } = this.mapSettings;

        if (tiles?.length && tiles[0].split('/{z}')[0]) {
            this.style = EMPTY_MAP_STYLE;

            const groupLayerId = 'group';

            this.addRasterLayer(
                groupLayerId,
                {
                    tiles,
                    tileSize,
                    minzoom,
                    maxzoom,
                },
                accessToken
            );

            this.toggleMapLayer(groupLayerId, true);
            this.mapboxFacadeService.skipCustomStyles();
        } else {
            this.style = this.mapSettings.style;

            if (this.mapSettings.style === DEFAULT_MAP_STYLE) {
                this.mapboxFacadeService.applyCustomStyles();
            } else {
                this.mapboxFacadeService.skipCustomStyles();
            }
        }
    }

    addRasterLayer(
        id: string,
        options?: {
            tiles?: string[];
            tileSize?: number;
            minzoom?: number;
            maxzoom?: number;
        },
        accessToken?: string
    ) {
        this.addMapLayer({
            id,
            accessToken,
            source: {
                ...(options || {}),
                type: 'raster',
                url: options.tiles?.[0].split('/').slice(0, 3).join('/') || '',
            },
        });
    }

    style: Style | string = EMPTY_MAP_STYLE;

    map: Map;

    private tileNotFoundHandler = (e: ErrorEvent & EventData) => {
        if (
            e.source &&
            e.source.type === 'image' &&
            e.source.url.startsWith(RESTRICTED_BUCKET_URL) &&
            e.source.url.indexOf('/raster/') !== -1
        ) {
            const rasterPlayers = {
                forecast: this.domainTilesPlayer,
                plumes: this.plumesTilesPlayer,
                forecastNew: this.domainTilesForecastPlayer,
            };

            const [id] = Object.entries(rasterPlayers).find(
                ([_, p]) => e.source.url.indexOf(p?.config.domain.slug) !== -1
            );

            rasterPlayers[id]?.clear();
        }
    };

    mapboxLoad(map: Map) {
        // TODO: can be moved to the effects
        this.mapboxFacadeService.setMap(map);
        this.mapboxActions.setMapObject(map);

        this.store.dispatch(mapLoaded());

        this.map = map;

        this.map.on('error', this.tileNotFoundHandler);

        // fix for iPad
        if (environment.is_mobile_app) {
            this.resizeFn = () => {
                setTimeout(() => map.resize(), RESIZE_TIMEOUT);
            };
            this.resizeFn();
            window.addEventListener('resize', this.resizeFn);
        }
    }

    ngOnDestroy() {
        if (this.resizeFn) {
            window.removeEventListener('resize', this.resizeFn);
        }

        Object.values(this.tilePlayers).map((tp) => tp?.destroy());
        this.map.off('error', this.tileNotFoundHandler);

        this.onDestroy$.complete();
    }

    authorizeTileRequest = (url: string, resourceType: ResourceType) => {
        const [layer] = this.enabledExtraLayers;

        if (resourceType === 'Image' && url.startsWith(RESTRICTED_BUCKET_URL)) {
            return {
                url,
                headers: {
                    Authorization: `Bearer ${this.vangaAuthService.getAccessToken()}`,
                },
            };
        } else if (
            layer?.accessToken &&
            resourceType === 'Tile' &&
            url.startsWith(layer.source.url)
        ) {
            return {
                url,
                headers: {
                    Authorization: `Bearer ${layer.accessToken}`,
                },
            };
        }
    };

    currentZoom: number;

    onZoom(zoom: number) {
        this.zoomChanged.emit(zoom);
        this.currentZoom = zoom;
        this.showMarkersArea = this.mapSettings.showMarkersArea && zoom > CITY_ZOOM_SHOW_HEXAGON;
    }

    isGreaterThanMinZoom(minzoom: number) {
        return !isNaN(minzoom) && this.currentZoom >= minzoom;
    }

    public clickedMap($event) {
        if (this.isAllowClick) {
            this.store.dispatch(
                setMapClickState({
                    isAllow: this.isAllowClick,
                    coordinates: {
                        lat: $event.lngLat.lat,
                        lon: $event.lngLat.lng,
                    },
                })
            );
        }
    }

    // map layers
    toggleMapLayer(layerId: string, isEnabled: boolean) {
        console.log('toggleMapLayer');
        const layer = isEnabled
            ? this.availableExtraLayers.find((layer) => layer.id === layerId)
            : null;
        this.enabledExtraLayers = layer ? [layer] : [];
    }

    addMapLayer(layer: ExtraLayer) {
        if (!this.availableExtraLayers.find((l) => l.id === layer.id)) {
            this.availableExtraLayers.push(layer);
        }
    }

    clearMapLayers() {
        this.enabledExtraLayers = [];
        this.availableExtraLayers = [];
    }

    private getWindData(params: windLayerParams) {
        const url = `${environment.tile_server_url}/${params.url}.png`;
        const token =
            params.url.indexOf('public') >= 0 ? null : this.vangaAuthService.getAccessToken();
        const loadImage$ = from(loadWindData(url, params.bboxDomain, [-20.0, 20.0], token));
        loadImage$.pipe(filter((v) => !!v)).subscribe((data) => {
            if (this.windVector) {
                this.windVector.setData(data);
            } else {
                this.createWindLayer(data);
                this.map?.addLayer(this.windLayer, 'building');
            }
        });
    }

    private createWindLayer(windData) {
        const _self = this;
        this.windLayer = {
            id: 'wind',
            type: 'custom',
            onAdd: function (map, gl) {
                _self.windVector = WindVector(gl, map);
                _self.windVector.setData(windData);
            },
            render: function (gl, matrix) {
                _self.windVector.draw();
            },
        };
    }

    private updateWindLayer() {
        const mapLayer = this.map.getLayer('wind');
        if (typeof mapLayer !== 'undefined') {
            this.map.removeLayer('wind');
        }
        this.map.addLayer(this.windLayer, 'building');
    }
}

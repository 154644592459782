import { Component } from '@angular/core';
import { Calendar } from './calendar';

@Component({
    selector: 'calendar-m',
    template: `
        <ng-container *ngIf="getTimeRange$ | async as dateRange">
            <div class="calendar_input__time_interval-m" (click)="toggleShow()">
                {{ dateRange | formatDateMobile: (tzOffset$ | async) }}
            </div>
            <calendar-months-mobile
                [show]="showCalendar"
                [timeBegin]="dateRange?.begin"
                [timeEnd]="dateRange?.end"
                [tzOffset]="tzOffset$ | async"
                [toggleShowCb]="toggleShow"
                [applyCb]="updateTime"
                [updateDays]="updateDays"
                [numberOfMonths]="12"
            ></calendar-months-mobile>
        </ng-container>
    `,
    styleUrls: ['calendar.less'],
})
export class Calendar_m extends Calendar {}
